import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {mensajeToast} from '@JVSoft/services/funciones-globales.service';

@Directive({
    // tslint:disable-next-line:directive-selector
  selector: '[generalFileUpload]'
})

export class FileUploadDirective {

    constructor() { }

    @Input() public fondoInicial = '';
    @Input() public fondoDragOver = '#8f8f8f';
    @Input() public controlFile: HTMLInputElement;

    @Input() public extensionesPermitidas: Array<string> = [];
    @Input() parteDeNombre: Array<string> = [];
    @Input() tamanoMaximoMB: number | null = null;
    @Input() parteDeNombreExclusivo: Array<string> = [];

    @Input() isDisabled = false;

    @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
    @Output() private filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();
    @HostBinding('style.background') private background = this.fondoInicial;

    static filtrarArchivos(files: File[], datAdic: { extensionesPermitidas: string[], parteDeNombre: string[], parteDeNombreExclusivo: string[], tamanoMaximoMB: number }, multiple = true) {
        const validFiles: File[] = [];
        const invalidFiles: File[] = [];

        const obtenerExtension = (nombre: string) => {
            const lastIndex = nombre.lastIndexOf('.');
            return lastIndex !== -1 ? nombre.slice(lastIndex + 1).toLowerCase() : '';
        };

        for (const file of files) {
            const ext = obtenerExtension(file.name);

            // Verificar tamaño del archivo
            const tamanoMB = file.size / (1024 * 1024); // Convertir de bytes a MB
            if (datAdic.tamanoMaximoMB && tamanoMB > datAdic.tamanoMaximoMB) {
                mensajeToast('error', 'Archivo no válido', 'El archivo <strong>' + file.name + '</strong> supera tamaño máximo permitido');
                invalidFiles.push(file);
                continue; // Saltar al próximo archivo si excede el tamaño máximo
            }

            if (datAdic.parteDeNombreExclusivo.some(parte => file.name.includes(parte))) {
                validFiles.push(file);
                if (!multiple) break;
            } else if (ext && datAdic.extensionesPermitidas.includes(ext) && datAdic.parteDeNombre.every(parte => file.name.includes(parte))) {
                validFiles.push(file);
                if (!multiple) break;
            } else {
                mensajeToast('error', 'Archivo no válido', 'El archivo <strong>' + file.name + '</strong> No es válido');
                invalidFiles.push(file);
            }
        }

        return {
            valid: validFiles,
            invalid: invalidFiles,
        };
    }

    static validarExtensionesV1(files: Array<File>, datAdic: { extensionesPermitidas: Array<string>, parteDeNombre: Array<string>, parteDeNombreExclusivo: Array<string> }, multiple = true) {
        const valid_files: Array<File> = [];
        const invalid_files: Array<File> = [];

        for (const file of files) {
            const ext = file.name.split('.').pop()?.toLowerCase();

            if (ext && datAdic.parteDeNombreExclusivo.some(parte => file.name.includes(parte))) {
                valid_files.push(file);
                if (!multiple) {
                    break;
                }
            } else if (ext && datAdic.extensionesPermitidas.includes(ext)) {
                console.log('INCLUYE', ext);
                if (ext || datAdic.parteDeNombre.some(parte => file.name.includes(parte))) {
                    valid_files.push(file);
                    if (!multiple) {
                        break;
                    }
                } else {
                    invalid_files.push(file);
                }
            } else {
                invalid_files.push(file);
            }
        }

        return {
            valid: valid_files,
            invalid: invalid_files,
        };
    }

    @HostListener('dragover', ['$event']) public onDragOver(evt){
        evt.preventDefault();
        evt.stopPropagation();
        if (!this.isDisabled) {
            this.background = this.fondoDragOver;
        }
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt){
        evt.preventDefault();
        evt.stopPropagation();
        if (!this.isDisabled) {
            this.background = this.fondoInicial;
        }
    }

    @HostListener('drop', ['$event'])
    public onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.isDisabled) {
            return false;
        }
        this.background = this.fondoInicial;
        const files = evt.dataTransfer.files;
        const ret = FileUploadDirective.filtrarArchivos(files, {
            tamanoMaximoMB: this.tamanoMaximoMB,
            extensionesPermitidas: this.extensionesPermitidas, parteDeNombre: this.parteDeNombre,
            parteDeNombreExclusivo: this.parteDeNombreExclusivo
        }, this.controlFile.multiple); // this.validarExtensiones(files, {extensionesPermitidas: this.extensionesPermitidas});

        this.filesChangeEmiter.emit(ret.valid);
        this.filesInvalidEmiter.emit(ret.invalid);
    }
}
