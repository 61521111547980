import {Injectable} from '@angular/core';
import {PdfService} from '../pdf.service';
import {ZeroFillPipe} from '@JVSoft/pipes/zero-fill.pipe';
import {DataEnListaPipe} from '@JVSoft/pipes/data-en-lista.pipe';
import {formatDate, formatNumber} from '@angular/common';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {nestGroupsBy, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {compra, kardex, movimientosProductos, pecosa, StaCat} from '@pdf/pdf-logistica';
import {reportePecosas, stockMovimientosProductos} from '@pdf/pdf-logistica/reportes';
import * as moment from 'moment/moment';
import {imprimirCelda} from '@JVSoft/services/pdf-make.global.service';

@Injectable({
    providedIn: 'root',
})
export class PdfLogisticaService extends PdfService {

    // A4 VERTICAL - 595 PUNTOS ANCHO

    // constructor(dialog: MatDialog, queryService: QueryService, dataServidor: { log: { pedidos_detalles_presentacion: null } }) {
    // 	super(dialog, queryService);
    // 	this.dataServidor = dataServidor;
    // }

    dataServidor = {
        log: {
            pedidos_detalles_presentacion: null,
        },
    };

    pedido(idsPedidos: string) {

        this.queryService.cargar(this, ['log.pedidos_detalles_presentacion'], {cCodigoCadena: idsPedidos})
            .then((valRetorno: object) => {

                const dataRecibido: any[] = valRetorno['log.pedidos_detalles_presentacion'];
                const cuerpoPdf = [];

                const dataGeneral = dataRecibido[0];

                const mDeAgua = {};

                if (dataGeneral.bPedidoAnuladoRegistro == '1' || dataGeneral.bPedidoCerrado != '1') {
                    mDeAgua['color'] = '#a80707';
                    mDeAgua['text'] = (dataGeneral.bPedidoAnuladoRegistro == '1' ? 'ANULADO' : (dataGeneral.bPedidoCerrado == '1' ? undefined : 'BORRADOR'));
                }

                this.reiniciarOpciones({
                    marcaDeAgua: mDeAgua,
                    margenesHoja: [50, 80, 50, 40],
                    tamanioFuenteExtra: 2,
                    defaultLeftCellHeaderTable: this.logotipoHeader(dataGeneral),
                    defaultRightCellHeaderTable: {
                        margin: [0, 10, 0, 0],
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    this.imprimirCelda(dataGeneral.iPedidoNumero, null, {
                                        fontSize: 9, bold: true, alignment: 'center',
                                    }),
                                    this.imprimirCelda(dataGeneral.dPedidoEmision, 'fec', {
                                        fontSize: 9, bold: true, alignment: 'center',
                                    }),
                                ],
                                [
                                    this.imprimirCelda('NRO SOL.', null, {fontSize: 7, alignment: 'center'}),
                                    this.imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                                ],
                            ],
                        },
                    },
                    defaultCenterCellHeaderTable: {
                        margin: [0, 15, 0, 0],
                        alignment: 'center',
                        stack: [
                            this.imprimirCelda('REQUERIMIENTO', null, {fontSize: 13, bold: true}),
                            this.imprimirCelda('Nº ' + dataGeneral.iPedidoNumero + ' - ' + dataGeneral.iPedidoYear, null, {
                                fontSize: 13,
                                bold: true,
                            }),
                        ],
                    },
                    footerTableLeftStackPrepen: [
                        'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                    ],
                });


                cuerpoPdf.push(
                    {
                        canvas: [
                            {
                                type: 'rect',
                                x: 0,
                                y: 0,
                                w: 495,
                                h: 65,
                                r: 5,
                                lineColor: 'black',
                                color: this.colorSombra,
                            },
                        ],
                    },
                    {
                        table: {
                            widths: [130, '*'],
                            body: [
                                [
                                    this.imprimirCelda('ESTABLECIMIENTO: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cEstablecimientoNombreLargo, null, {
                                        fontSize: 7, italics: true,
                                    }),
                                ],
                                [
                                    this.imprimirCelda('DEPENDENCIA: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cDepenNombre, null, {fontSize: 7, italics: true}),
                                ],
                                [
                                    this.imprimirCelda('SOLICITANTE: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cPersNombreLargo, null, {
                                        fontSize: 7, italics: true,
                                    }),
                                ],
                                [
                                    this.imprimirCelda('PERIODO: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cMonthNombre, null, {
                                        fontSize: 7, italics: true,
                                    }),
                                ],
                                [
                                    this.imprimirCelda('REFERENCIA: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cPedidoReferencia, null, {
                                        fontSize: 7, italics: true,
                                    }),
                                ],
                            ],
                        },
                        layout: 'noBorders',
                        absolutePosition: {x: 50, y: 82},
                    },
                );


                const celdasHeaderDetalle = [
                    [
                        this.imprimirCelda(dataGeneral.cEstablecimientoNombreLargo, null, {
                            colSpan: 3, style: 'thSmall',
                        }),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda('U/M', null, {rowSpan: 2, style: 'thSmall'}),
                        // this.imprimirCelda('T.C.', null, { rowSpan: 2, style: 'thSmall' }),
                        // this.imprimirCelda('ESP.\nGASTO', null, { rowSpan: 2, style: 'thSmall' }),
                        this.imprimirCelda('CANTIDAD', null, {rowSpan: 2, style: 'thSmall'}),
                        // this.imprimirCelda('VALOR REFERENCIAL', null, { colSpan: 2, style: 'thSmall' }),
                        // this.imprimirCelda(''),
                    ],
                    [
                        this.imprimirCelda('Nº', null, {style: 'thSmall'}),
                        this.imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                        this.imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall'}),
                        this.imprimirCelda(''),
                        // this.imprimirCelda(''),
                        // this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        // this.imprimirCelda('P.U.', null, { style: 'thSmall' }),
                        // this.imprimirCelda('SUB TOTAL', null, { style: 'thSmall' }),
                    ],
                ];
                const celdasBodyDetalle = [];

                dataRecibido.filter((dataProd, idxProd) => {

                    celdasBodyDetalle.push(
                        [
                            this.imprimirCelda(idxProd + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                            this.imprimirCelda(dataProd.cCodigoProducto, null, {style: 'tdSmall'}),
                            this.imprimirCelda(dataProd.cProductoNombreObs ?? dataProd.cProductoNombre, null, {style: 'tdSmall'}),
                            this.imprimirCelda(dataProd.cPresentacionNombre, null, {
                                style: 'tdSmall', alignment: 'center',
                            }),
                            // this.imprimirCelda(dataProd.cPresentacionNombre, null, { style: 'thSmall' }),
                            // this.imprimirCelda(dataProd.cPresentacionNombre, null, { style: 'thSmall' }),
                            this.imprimirCelda(dataProd.nPedidoDetCantidad, 'cant', {
                                style: 'tdSmall', alignment: 'right', format: '1.2-2'
                            }),
                            // this.imprimirCelda(dataProd.cPresentacionNombre, null, { style: 'thSmall' }),
                            // this.imprimirCelda(dataProd.cPresentacionNombre, null, { style: 'thSmall' }),
                        ],
                    );


                });
                const tablaHeaderPedido = {
                    margin: [0, 5, 0, 10],
                    table: {
                        // widths: ['*'],
                        widths: this.anchoCols(celdasHeaderDetalle, [2], [{idx: 4, valor: 45}]),
                        body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                    },
                };
                console.log(tablaHeaderPedido);
                cuerpoPdf.push(tablaHeaderPedido);
                // cuerpoPdf.push({
                // 	image: await this.getBase64ImageFromURL('http://archivos.jvsoft.pe/img/logos/1/logo-completo.svg'),
                // 	width: 200
                // });


                this.finalizarCreacionPdf(cuerpoPdf);
            });
    }

    compra(idsCompras: string) {
        if (this.datosUsuarioiEntId['iEntId'] == 1) {
            StaCat.compra(this, idsCompras);
        }
        else {
            compra(this, idsCompras);
        }
    }

    movimientosProductos(idsCompras: string) {
        movimientosProductos(this, idsCompras);
    }


    pecosa(idsCompras: string) {
        console.log(this.datosUsuarioiEntId['iEntId']);
        if (this.datosUsuarioiEntId['iEntId'] == 1) {
            StaCat.pecosa(this, idsCompras);
        }
        else {
            pecosa(this, idsCompras);
        }
    }
    kardex(dataRec) {
        kardex(this, dataRec);
    }

    cuadro_comparativo(idsRequest: string) {
        const proveedoresPorHoja = 4;
        this.queryService.cargar(this, ['log.cuadros_comparativos_presentacion'], {cCodigoCadena: idsRequest})
            .then((valRetorno: object) => {

                const dataRecibido: any[] = valRetorno['log.cuadros_comparativos_presentacion'];

                const dataGeneral = dataRecibido[0];
                dataRecibido.filter(dataIndiv => {
                    const cuerpoPdf = [];
                    const nProveedores = dataIndiv['proveedores'];

                    const mDeAgua = {};

                    if (dataIndiv.bCuaComAnuladoRegistro == '1' || dataIndiv.bCuaComCerrado != '1') {
                        mDeAgua['color'] = '#a80707';
                        mDeAgua['text'] = (dataIndiv.bCuaComAnuladoRegistro == '1' ? 'ANULADO' : (dataIndiv.bCuaComCerrado == '1' ? undefined : 'BORRADOR'));
                    }

                    const fechaEmision = moment(dataGeneral.dCuaComEmision, 'YYYY-MM-DD');

                    this.reiniciarOpciones({
                        marcaDeAgua: mDeAgua,
                        margenesHoja: [20, 80, 20, 110],
                        tamanioFuenteExtra: 1,
                        orientacion: 'landscape',
                        defaultLeftCellHeaderTable: this.logotipoHeader(dataGeneral),
                        defaultRightCellHeaderTable: {
                            margin: [30, 10, 10, 0],
                            table: {
                                widths: ['*', '*'],
                                body: [
                                    [
                                        this.imprimirCelda('Nº Cotización', null, {fontSize: 7, alignment: 'center'}),
                                        this.imprimirCelda('Fecha Salida', null, {fontSize: 7, alignment: 'center'}),
                                    ],
                                    [
                                        this.imprimirCelda(dataGeneral.cCotizaNumero, null, {
                                            fontSize: 9, bold: true, alignment: 'center',
                                        }),
                                        this.imprimirCelda(dataGeneral.dCuaComEmision, 'fec', {
                                            fontSize: 9, bold: true, alignment: 'center',
                                        }),
                                    ],
                                ],
                            },
                        },
                        defaultCenterCellHeaderTable: {
                            margin: [0, 10, 0, 0],
                            alignment: 'center',
                            stack: [
                                this.imprimirCelda('CUADRO COMPARATIVO', null, {fontSize: 13, bold: true}),
                                this.imprimirCelda('Nº ' + dataGeneral.iCuaComNumero + '-' + fechaEmision.year(), null, {
                                    fontSize: 12, bold: true,
                                }),
                            ],
                        },

                        footerTablePrepend: {
                            margin: [20, 0, 20, 10],
                            table: {
                                widths: ['*', 130, 130, 130],
                                body: [
                                    [
                                        // this.imprimirCelda('\n\n\n\n\n', null, { rowSpan: 2, style: 'tdSmall', alignment: 'center' }),
                                        {
                                            style: 'tdSmall',
                                            stack: [
                                                // this.imprimirCelda('ACTA DE OTORGAMIENTO DE BUENA PRO:', null, {bold: true}),
                                                this.imprimirCelda(dataGeneral.cCuaComObs ?? '', null, {italics: true}),
                                                // {
                                                // 	text: 'SE OTORGA LA BUENA PRO A XXXXXXXXXXXX CON XXX XXXXXXX CON UNA OFERTA DE XXXXXXXX',
                                                // 	italics: true
                                                // },
                                                // this.imprimirCelda(['FECHA: ', new Date()], 'fec', {alignment: 'right', bold: true}),
                                            ],
                                        },
                                        this.imprimirCelda('\n\n\n\n\n', null, {style: 'tdSmall', alignment: 'center'}),
                                        this.imprimirCelda('\n\n\n\n\n', null, {style: 'tdSmall', alignment: 'center'}),
                                        this.imprimirCelda('\n\n\n\n\n\n\n', null, {style: 'tdSmall', alignment: 'center'}),
                                    ],
                                ],
                            }
                            // absolutePosition: {x: 20, y: 0},
                        },
                        footerTableLeftStackPrepen: [
                            'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                        ],
                    });

                    while (nProveedores.length) {
                        const nProvAct = nProveedores.splice(0, proveedoresPorHoja);

                        const tablaEncabezado = [
                            [
                                {
                                    colSpan: 2,
                                    rowSpan: 4,
                                    border: [false, false, false, false],
                                    alignment: 'center',
                                    stack: [
                                        this.imprimirCelda('', null, {bold: true}),
                                    ],
                                },
                                {},
                                this.imprimirCelda('Razón Social', null, {
                                    colSpan: 2,
                                    border: [false, false, false, false],
                                    bold: true,
                                    alignment: 'right',
                                }),
                                {},
                            ],
                            [
                                {},
                                {},
                                this.imprimirCelda('RUC', null, {
                                    colSpan: 2,
                                    border: [false, false, false, false],
                                    bold: true,
                                    alignment: 'right',
                                }),
                                {},
                            ],
                            [
                                {},
                                {},
                                this.imprimirCelda('Dirección', null, {
                                    colSpan: 2,
                                    border: [false, false, false, false],
                                    bold: true,
                                    alignment: 'right',
                                }),
                                {},
                            ],
                            [
                                {},
                                {},
                                this.imprimirCelda('Dias para Entrega', null, {
                                    colSpan: 2,
                                    border: [false, false, false, false],
                                    bold: true,
                                    alignment: 'right',
                                }),
                                {},
                            ],
                            [
                                this.imprimirCelda('Nº', null, {style: 'thSmall'}),
                                this.imprimirCelda('Item', null, {style: 'thSmall'}),
                                this.imprimirCelda('Unidad', null, {style: 'thSmall'}),
                                this.imprimirCelda('Cant.', null, {style: 'thSmall'}),
                                // this.imprimirCelda('P/U', null, { style: 'thSmall' }),
                                // this.imprimirCelda('Total', null, { style: 'thSmall' }),
                                // this.imprimirCelda('VB', null, { style: 'thSmall' }),
                            ],
                        ];

                        nProvAct.filter((dProv) => {
                            tablaEncabezado[0].push(this.imprimirCelda(dProv.cPersProveedorNombreLargo, null, {
                                colSpan: 3,
                                bold: true,
                                style: 'tdSmall',
                            }), {}, {});
                            tablaEncabezado[1].push(this.imprimirCelda(dProv.cPersProveedorDocumento, null, {
                                colSpan: 3,
                                bold: true,
                                style: 'tdSmall',
                            }), {}, {});
                            tablaEncabezado[2].push(this.imprimirCelda(dProv.cPersProveedorDomicilio, null, {
                                colSpan: 3,
                                style: 'tdSmall',
                            }), {}, {});
                            tablaEncabezado[3].push(this.imprimirCelda(dProv.nCuaComProvPlazoEntrega, 'cant', {
                                colSpan: 3,
                                style: 'tdSmall',
                            }), {}, {});
                            tablaEncabezado[4].push(
                                this.imprimirCelda('P/U', null, {style: 'thSmall'}),
                                this.imprimirCelda('Total', null, {style: 'thSmall'}),
                                this.imprimirCelda('VB', null, {style: 'thSmall'}),
                            );
                        });

                        const detalleProd = [];

                        dataIndiv['productos'].filter((dProd, idxProd) => {
                            const detaleProdActual = [];
                            detaleProdActual.push(
                                this.imprimirCelda((idxProd + 1), 'cant', {alignment: 'center', style: 'tdSmall'}),
                                this.imprimirCelda(dProd.cProductoNombreObs ?? dProd.cProductoNombre, null, {style: 'tdSmall'}),
                                this.imprimirCelda(dProd.cPresentacionNombre, null, {style: 'tdSmall'}),
                                this.imprimirCelda(dProd.nPedidoDetCantidad, 'cant', {
                                    alignment: 'center', style: 'tdSmall',
                                }),
                            );
                            nProvAct.filter((dProv) => {
                                let objBuenaPro = {};
                                const prodSegunId = new DataEnListaPipe().transform(dProv['detalle'], 'iProductoId', dProd.iProductoId);
                                if (prodSegunId) {
                                    if (prodSegunId['bCuaComProvDetBuenaPro'] == 1) {
                                        objBuenaPro = {
                                            bold: true,
                                            color: '#4caf50',
                                            fontSize: 12,
                                        };
                                    }
                                    detaleProdActual.push(
                                        this.imprimirCelda(prodSegunId['nCuaComProvDetPrecioUnitario'], 'mon', {
                                            alignment: 'right',
                                            style: 'tdSmall',
                                        }),
                                        this.imprimirCelda(prodSegunId['nCuaComProvDetSubTotal'], 'mon', {
                                            alignment: 'right',
                                            style: 'tdSmall',
                                        }),
                                        this.imprimirCelda((prodSegunId['bCuaComProvDetBuenaPro'] == 1 ? '√' : 'x'), null, {
                                            ...{
                                                alignment: 'center',
                                                style: 'tdSmall',
                                            },
                                            ...objBuenaPro,
                                        }),
                                    );
                                }
                                else {
                                    detaleProdActual.push(
                                        this.imprimirCelda('', null, {style: 'tdSmall'}),
                                        this.imprimirCelda('', null, {style: 'tdSmall'}),
                                        this.imprimirCelda('', null, {style: 'tdSmall'}),
                                    );
                                }

                            });
                            detalleProd.push(detaleProdActual);

                        });
                        const filaTotales = [];

                        filaTotales.push(
                            this.imprimirCelda('', null, {
                                alignment: 'center', style: 'tdSmall', border: [false, false, false, false],
                            }),
                            this.imprimirCelda('', null, {
                                alignment: 'center', style: 'tdSmall', border: [false, false, false, false],
                            }),
                            this.imprimirCelda('', null, {
                                alignment: 'center', style: 'tdSmall', border: [false, false, false, false],
                            }),
                            this.imprimirCelda('', null, {
                                alignment: 'center', style: 'tdSmall', border: [false, false, false, false],
                            }),
                        );
                        nProvAct.filter((dProv) => {
                            console.log(dProv);
                            filaTotales.push(
                                this.imprimirCelda('', null, {
                                    alignment: 'center', style: 'tdSmall', border: [false, false, false, false],
                                }),
                                this.imprimirCelda(dProv['nCuaComProvValorItemTotal' ?? '-'], 'mon', {
                                    alignment: 'right', bold: true, style: 'tdSmall',
                                }),
                                this.imprimirCelda('', null, {
                                    alignment: 'center', style: 'tdSmall', border: [false, false, false, false],
                                }),
                            );
                        });
                        detalleProd.push(filaTotales);

                        cuerpoPdf.push(
                            {
                                margin: [0, 5, 0, 10],
                                table: {
                                    headerRows: tablaEncabezado.length,
                                    widths: this.anchoCols(tablaEncabezado, [1], [
                                        {idx: 4, valor: 60},
                                        {idx: 5, valor: 60},
                                        {idx: 6, valor: 10},

                                        {idx: 7, valor: 60},
                                        {idx: 8, valor: 60},
                                        {idx: 9, valor: 10},

                                        {idx: 10, valor: 60},
                                        {idx: 11, valor: 60},
                                        {idx: 12, valor: 10},
                                    ]),
                                    body: tablaEncabezado.concat(detalleProd),
                                },
                                // layout: 'noBorders',
                                // absolutePosition: {x: 50, y: 82}
                            },
                        );


                        /*cuerpoPdf.push({
                            table: {
                                margin: [0, 10, 0, 0],
                                dontBreakRows: true,
                                widths: ['*', 130, 130, 130],
                                body: [
                                    [
                                        // this.imprimirCelda('\n\n\n\n\n', null, { rowSpan: 2, style: 'tdSmall', alignment: 'center' }),
                                        {
                                            rowSpan: 2,
                                            style: 'tdSmall',
                                            stack: [
                                                // this.imprimirCelda('ACTA DE OTORGAMIENTO DE BUENA PRO:', null, {bold: true}),
                                                this.imprimirCelda(dataGeneral.cCuaComObs ?? '', null, {italics: true}),
                                                // {
                                                // 	text: 'SE OTORGA LA BUENA PRO A XXXXXXXXXXXX CON XXX XXXXXXX CON UNA OFERTA DE XXXXXXXX',
                                                // 	italics: true
                                                // },
                                                // this.imprimirCelda(['FECHA: ', new Date()], 'fec', {alignment: 'right', bold: true}),
                                            ],
                                        },
                                        this.imprimirCelda('\n\n\n\n\n', null, {style: 'tdSmall', alignment: 'center'}),
                                        this.imprimirCelda('\n\n\n\n\n', null, {style: 'tdSmall', alignment: 'center'}),
                                        this.imprimirCelda('\n\n\n\n\n', null, {style: 'tdSmall', alignment: 'center'}),
                                    ],
                                    [
                                        this.imprimirCelda('', null, {style: 'thSmall'}),
                                        this.imprimirCelda('', null, {style: 'thSmall'}),
                                        this.imprimirCelda('Responsable de Logística', null, {style: 'thSmall'}),
                                        this.imprimirCelda('Cotizador', null, {style: 'thSmall'}),
                                    ],
                                ],
                            },
                            // absolutePosition: {x: 20, y: 490},
                        });*/


                        // console.log(nProvAct.length, proveedoresPorHoja, nProvAct.length >= proveedoresPorHoja);

                        if (nProvAct.length >= proveedoresPorHoja) {
                            cuerpoPdf.push({
                                text: '',
                                pageBreak: 'before',
                            });
                        }

                    }


                    this.finalizarCreacionPdf(cuerpoPdf);
                });


            });
    }

    cotizacion(idsCotizaciones: string) {

        this.queryService.cargar(this, ['log.cotizaciones_detalles_presentacion'], {cCodigoCadena: idsCotizaciones})
            .then(async (valRetorno: object) => {

                const dataRecibido: any[] = valRetorno['log.cotizaciones_detalles_presentacion'];
                const cuerpoPdf = [];

                const dataGeneral = dataRecibido[0];

                const mDeAgua = {};
                if (dataGeneral.bCotizaAnuladoRegistro == '1') {
                    mDeAgua['color'] = '#a80707';
                    mDeAgua['text'] = 'ANULADO';
                }

                this.reiniciarOpciones({
                    marcaDeAgua: mDeAgua,
                    margenesHoja: [50, 80, 50, 40],
                    tamanioFuenteExtra: 1,
                    defaultLeftCellHeaderTable: this.logotipoHeader(dataGeneral),
                    defaultRightCellHeaderTable: {
                        margin: [0, 10, 0, 0],
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    this.imprimirCelda(dataGeneral.iCotizaNumero, null, {
                                        fontSize: 9, bold: true, alignment: 'center',
                                    }),
                                    this.imprimirCelda(dataGeneral.dCotizaEmision, 'fec', {
                                        fontSize: 9, bold: true, alignment: 'center',
                                    }),
                                ],
                                [
                                    this.imprimirCelda('NRO COT.', null, {fontSize: 7, alignment: 'center'}),
                                    this.imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                                ],
                            ],
                        },
                    },
                    defaultCenterCellHeaderTable: {
                        margin: [0, 40, 0, 0],
                        alignment: 'center',
                        stack: [
                            this.imprimirCelda('COTIZACIÓN Nº ' + dataGeneral.iCotizaNumero, null, {
                                fontSize: 13, bold: true,
                            }),
                        ],
                    },
                    footerTablePrepend: {

                    },
                    footerTableLeftStackPrepen: [
                        'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                    ],
                });

                // if (idxProd == 0) {

                cuerpoPdf.push(
                    {
                        canvas: [
                            {
                                type: 'rect',
                                x: 0,
                                y: 0,
                                w: 495,
                                h: 60,
                                r: 5,
                                lineColor: 'black',
                                color: this.colorSombra,
                            },
                        ],
                    },
                    {
                        table: {
                            widths: [78, 230, 35, '*'],
                            body: [
                                [
                                    this.imprimirCelda('REFERENCIA: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cCotizaReferencia, null, {
                                        fontSize: 7, italics: true,
                                    }),
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(''),
                                ],
                                [
                                    this.imprimirCelda('SEÑOR: ', null, {fontSize: 7, alignment: 'right', bold: true}),
                                    this.imprimirCelda('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ __ _ _ _ _ _ _ _ _ _ _ _ _ _ _', null, {
                                        fontSize: 7,
                                        italics: true,
                                    }),
                                    this.imprimirCelda('RUC'),
                                    this.imprimirCelda('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _', null, {
                                        fontSize: 7, italics: true,
                                    }),
                                ],
                                [
                                    this.imprimirCelda('DIRECCIÓN: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ __ _ _ _ _ _ _ _ _ _ _ _ _ _ _', null, {
                                        fontSize: 7,
                                        italics: true,
                                    }),
                                    this.imprimirCelda('TEL.'),
                                    this.imprimirCelda('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _', null, {
                                        fontSize: 7, italics: true,
                                    }),
                                ],
                                [
                                    this.imprimirCelda('OBSERVACIÓN: ', null, {
                                        fontSize: 7, alignment: 'right', bold: true,
                                    }),
                                    this.imprimirCelda(dataGeneral.cCotizaObs, null, {fontSize: 7, italics: true}),
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(''),
                                ],
                            ],
                        },
                        layout: 'noBorders',
                        absolutePosition: {x: 50, y: 82},
                    },
                );
                const tablaIndicacion = {
                    margin: [0, 3, 0, 0],
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                this.imprimirCelda('SÍRVASE A COTIZARNOS LOS ARTÍCULOS QUE SE DETALLAN EN EL PEDIDO: ', null, {
                                    fontSize: 7,
                                    alignment: 'left',
                                    bold: true,
                                }),
                            ],
                        ],
                    },
                    layout: 'noBorders',
                };
                cuerpoPdf.push(tablaIndicacion);


                const celdasHeaderDetalle = [
                    [
                        this.imprimirCelda(dataGeneral.cTipoProductoNombre, null, {colSpan: 9, style: 'thSmall'}),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda('PRECIOS', null, {colSpan: 2, style: 'thSmall'}),
                        this.imprimirCelda(''),
                    ],
                    [
                        this.imprimirCelda('Nº', null, {style: 'thSmall', colSpan: 2}),
                        this.imprimirCelda(''),
                        this.imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                        this.imprimirCelda('CANT.', null, {style: 'thSmall'}),
                        this.imprimirCelda('U/M', null, {style: 'thSmall'}),
                        this.imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall'}),
                        this.imprimirCelda('MARCA', null, {style: 'thSmall'}),
                        this.imprimirCelda('MODELO', null, {style: 'thSmall'}),
                        this.imprimirCelda('COLOR', null, {style: 'thSmall'}),
                        this.imprimirCelda('PRECIO\nUNITARIO', null, {style: 'thSmall'}),
                        this.imprimirCelda('SUBTOTAL', null, {style: 'thSmall'}),
                    ],
                ];
                const celdasBodyDetalle = [];

                const groups = nestGroupsBy(dataRecibido, [
                    'cEstablecimientoNombreLargo',
                    'cDepenNombre',
                ]);
                console.log(groups);
                for (const [key, value] of Object.entries(groups)) {
                    console.log(`${key}: ${value}`);
                    celdasBodyDetalle.push(
                        [
                            this.imprimirCelda(key, null, {style: 'thSmall', alignment: 'left', colSpan: 11}),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                        ],
                    );
                    for (const [key2, value2] of Object.entries(value)) {
                        celdasBodyDetalle.push(
                            [
                                this.imprimirCelda('', null, {style: 'thSmall'}),
                                this.imprimirCelda(key2, null, {style: 'thSmall', alignment: 'left', colSpan: 10}),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                            ],
                        );
                        value2.filter((dataProd, idxProd) => {

                            celdasBodyDetalle.push(
                                [
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(idxProd + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                                    this.imprimirCelda(dataProd.cCodigoProducto, null, {style: 'tdSmall'}),
                                    this.imprimirCelda(dataProd.nPedidoDetCantidad, 'number', {
                                        style: 'tdSmall', alignment: 'center',
                                    }),
                                    this.imprimirCelda(dataProd.cPresentacionNombre, null, {
                                        style: 'tdSmall', alignment: 'center',
                                    }),
                                    this.imprimirCelda(dataProd.cProductoNombreObs ?? dataProd.cProductoNombre, null, {style: 'tdSmall'}),
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(''),
                                    this.imprimirCelda(''),
                                ],
                            );

                        });
                        celdasBodyDetalle.push(
                            [
                                this.imprimirCelda('TOTAL:', null, {colSpan: 10, border: [false, true, true, false], alignment: 'right', bold: true}),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                            ],
                        );
                    }
                }

                const tablaHeaderCotiza = {
                    margin: [0, 5, 0, 10],
                    table: {
                        widths: [5, 'auto', 'auto', 'auto', 'auto', '*', 30, 30, 30, 45, 45],
                        // widths: this.anchoCols(celdasHeaderDetalle, [4]),
                        body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                    },
                };


                console.log(tablaHeaderCotiza);
                cuerpoPdf.push(tablaHeaderCotiza);
                // CUADRADO - MEJOR UBICADO

                const tablaDetails = {
                    margin: [0, 2, 0, 0],
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                this.imprimirCelda('CONDICIONES', null, {fontSize: 7, alignment: 'left', bold: true}),
                            ],
                            [
                                this.imprimirCelda('LA PRESENTE COTIZACIÓN ES A TODO COSTO (INCLUIDO IGV): ', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('LUGAR DE ENTREGA: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ FORMA DE PAGO: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('PLAZO DE ENTREGA: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ DIAS CALENDARIO A PARTIR DEL DÍA SIGUIENTE DE NOTIFICADA LA ORDEN DE COMPRA Y/O SERVICIO: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('Nº DE CTA CORRIENTE: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ Nº DE CCI_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ Nº DE CTA DETRACCIÓN _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('FECHA DE DEVOLUCIÓN DE LA PUBLICACIÓN: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('SIRVASE COLOCAR EL TIPO DE CAMBIO SI SU PRECIO ES EN DOLARES: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('SI POR CUALQUIER CAUSA NO ESTA EN CONDICIONES DE COTIZAR, SIRVA(N)SE UD(S) NO FIRMAR Y DEVOLVER ESTE DOCUMENTO.', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('SI ESTA EN CONDICIONES DE COTIZAR, SIRVA(N)SE UD(S) FIRMAR ESTE DOCUMENTO Y DEVOLVERLO EN "SOBRE CERRADO" CON SU PROPUESTA.', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('INDICAR LA RESPECTIVA MARCA Y/O PROCEDENCIA DEL PRODUCTO CASO CONTRARIO NO SE TOMARA EN CUENTA SU COTIZACIÓN.', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                            [
                                this.imprimirCelda('APARTIR DE LA FECHA SOLO SE RECIBIRAN LAS COTIZACIONES EN ESTE FORMATO.', null, {
                                    fontSize: 6,
                                    alignment: 'left',
                                    bold: false,
                                }),
                            ],
                        ],
                    },
                    layout: 'noBorders',
                };
                cuerpoPdf.push(tablaDetails);


                const tablaVistos = {
                    margin: [0, 60, 0, 0],
                    alignment: 'center',
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                this.imprimirCelda('_______________________________________\n FIRMA Y SELLO DEL COTIZADOR', null, {
                                    alignment: 'center',
                                    bold: false,
                                }),
                                this.imprimirCelda('_______________________________________\n FIRMA Y SELLO DEL PROVEEDOR', null, {
                                    alignment: 'center',
                                    bold: false,
                                }),
                            ],
                        ],
                    },
                    layout: 'noBorders',
                };
                cuerpoPdf.push(tablaVistos);

                // cuerpoPdf.push({
                // 	image: await this.getBase64ImageFromURL('http://archivos.jvsoft.pe/img/logos/1/logo-completo.svg'),
                // 	width: 200
                // });


                this.finalizarCreacionPdf(cuerpoPdf);
            });
    }

    internamientoBien(idsMovimientosProductos: string) {
        this.queryService.cargar(this, ['log.movimientos_productos_detalles'], {cCodigoCadena: idsMovimientosProductos})
            .then((valRetorno: object) => {
                console.log(valRetorno);
                const dataRecibido: any[] = valRetorno['log.movimientos_productos_detalles'];
                console.log(dataRecibido);
                console.log(typeof dataRecibido);
                Object.keys(dataRecibido).forEach((idxMovimientosProductos) => {

                    const cuerpoPdf = [];


                    // const dataGeneral = dataRecibido[0];
                    const dataGeneral = dataRecibido[idxMovimientosProductos][0];

                    const mDeAgua = {};
                    if (dataGeneral.bMovProdAnuladoRegistro == '1') {
                        mDeAgua['color'] = '#a80707';
                        mDeAgua['text'] = 'ANULADO';
                    }

                    this.reiniciarOpciones({
                        marcaDeAgua: mDeAgua,
                        margenesHoja: [50, 80, 50, 40],
                        tamanioFuenteExtra: 2,
                        defaultLeftCellHeaderTable: this.logotipoHeader(dataGeneral),
                        defaultRightCellHeaderTable: {
                            margin: [0, 10, 0, 0],
                            table: {
                                widths: ['*', '*'],
                                body: [
                                    [
                                        this.imprimirCelda(dataGeneral.iMovProdNumero, null, {
                                            fontSize: 9, bold: true, alignment: 'center',
                                        }),
                                        this.imprimirCelda(dataGeneral.dMovProdMovimiento, 'fec', {
                                            fontSize: 9,
                                            bold: true,
                                            alignment: 'center',
                                        }),
                                    ],
                                    [
                                        this.imprimirCelda('NRO MOV.', null, {fontSize: 7, alignment: 'center'}),
                                        this.imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                                    ],
                                ],
                            },
                        },
                        defaultCenterCellHeaderTable: {
                            margin: [0, 40, 0, 0],
                            alignment: 'center',
                            stack: [
                                this.imprimirCelda(dataGeneral.cTipoMovProdNombre + ' ' + dataGeneral.iMovProdNumero + ' - ' + dataGeneral.iMovProdYear, null, {
                                    fontSize: 13,
                                    bold: true,
                                }),
                            ],
                        },
                    });

                    // if (idxProd == 0) {

                    cuerpoPdf.push(
                        {
                            canvas: [
                                {
                                    type: 'rect',
                                    x: 0,
                                    y: 0,
                                    w: 495,
                                    h: 70,
                                    r: 5,
                                    lineColor: 'black',
                                    color: this.colorSombra,
                                },
                            ],
                        },
                        {
                            table: {
                                widths: [78, 180, 60, '*'],
                                body: [
                                    [
                                        this.imprimirCelda('REFERENCIA: ', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cMovProdReferencia, null, {
                                            fontSize: 7, italics: true,
                                        }),
                                        this.imprimirCelda('TIPO: ', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cMotMovProdNombre, null, {
                                            fontSize: 7, italics: true,
                                        }),
                                    ],
                                    [
                                        this.imprimirCelda('SEÑOR: ', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cPersNombreLargo, null, {
                                            fontSize: 7, italics: true,
                                        }),
                                        this.imprimirCelda('NRO. DOC.', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cPersDocumento, null, {
                                            fontSize: 7, italics: true,
                                        }),
                                    ],
                                    [
                                        this.imprimirCelda('ESTABLECIMIENTO: ', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cEstablecimientoNombreLargo, null, {
                                            fontSize: 7, italics: true,
                                        }),
                                        this.imprimirCelda('ALMACEN', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cAlmacenNombre, null, {
                                            fontSize: 7, italics: true,
                                        }),
                                    ],
                                    [
                                        this.imprimirCelda('OBSERVACIÓN: ', null, {
                                            fontSize: 7, alignment: 'right', bold: true,
                                        }),
                                        this.imprimirCelda(dataGeneral.cMovProdObs, null, {fontSize: 7, colSpan: 3, italics: true}),
                                        this.imprimirCelda(''),
                                        this.imprimirCelda(''),
                                    ],
                                ],
                            },
                            layout: 'noBorders',
                            absolutePosition: {x: 50, y: 82},
                        },
                    );

                    const celdasHeaderDetalle = [
                        [
                            this.imprimirCelda(dataGeneral.cTipoMovProdNombre, null, {colSpan: 5, style: 'thSmall'}),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda(''),
                            this.imprimirCelda('PRECIOS', null, {colSpan: 2, style: 'thSmall'}),
                            this.imprimirCelda(''),
                        ],
                        [
                            this.imprimirCelda('Nº', null, {style: 'thSmall'}),
                            this.imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                            this.imprimirCelda('CANT.', null, {style: 'thSmall'}),
                            this.imprimirCelda('U/M', null, {style: 'thSmall'}),
                            this.imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall'}),
                            this.imprimirCelda('P.U.', null, {style: 'thSmall'}),
                            this.imprimirCelda('SUB-TOTAL', null, {style: 'thSmall'}),
                        ],
                    ];
                    const celdasBodyDetalle = [];

                    dataRecibido[idxMovimientosProductos].filter((dataProd, idxProd) => {

                        celdasBodyDetalle.push(
                            [
                                this.imprimirCelda(idxProd + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                                this.imprimirCelda(dataProd.cCodigoProducto, null, {style: 'tdSmall'}),
                                this.imprimirCelda(dataProd.nMovProdDetCantidad, 'number', {
                                    style: 'tdSmall', alignment: 'center',
                                }),
                                this.imprimirCelda(dataProd.cPresentacionNombre, null, {
                                    style: 'tdSmall', alignment: 'center',
                                }),
                                this.imprimirCelda(dataProd.cProductoNombreObs ?? dataProd.cProductoNombre, null, {style: 'tdSmall'}),
                                this.imprimirCelda(dataProd.nMovProdDetPrecioUnitario, 'mon', {
                                    style: 'tdSmall', alignment: 'right',
                                }),
                                this.imprimirCelda(dataProd.nMovProdDetSubTotal, 'mon', {
                                    style: 'tdSmall', alignment: 'right',
                                }),
                            ],
                        );

                    });

                    celdasBodyDetalle.push(
                        [
                            {text: 'Total:', colSpan: 6, style: 'thSmall', alignment: 'right'},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {
                                text: this.imprimirCelda(dataGeneral.nMovProdTotal, 'mon', {
                                    style: 'tdSmall', alignment: 'right',
                                }),
                            },
                        ],
                    );

                    const tablaHeaderMovProd = {
                        margin: [0, 5, 0, 10],
                        table: {
                            widths: [25, 70, 25, 45, 158, 55, 55],
                            // widths: this.anchoCols(celdasHeaderDetalle, [4]),
                            body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                        },
                    };


                    console.log(tablaHeaderMovProd);
                    cuerpoPdf.push(tablaHeaderMovProd);


                    cuerpoPdf.push({
                        table: {
                            widths: ['*', '*', '*'],
                            body: [
                                [
                                    this.imprimirCelda('ENTREGADO POR:', null, {style: 'thSmall'}),
                                    this.imprimirCelda('LOGÍSTICA', null, {style: 'thSmall'}),
                                    this.imprimirCelda('ALMACEN', null, {style: 'thSmall'}),
                                ],
                                [
                                    this.imprimirCelda('\n\n\n\n' + 'Firma y Sello', null, {
                                        style: 'tdSmall', alignment: 'center',
                                    }),
                                    this.imprimirCelda('\n\n\n\n' + 'Firma y Sello', null, {
                                        style: 'tdSmall', alignment: 'center',
                                    }),
                                    this.imprimirCelda('\n\n\n\n' + 'Firma y Sello', null, {
                                        style: 'tdSmall', alignment: 'center',
                                    }),
                                ],
                            ],
                        },
                        absolutePosition: {x: 50, y: 720},
                    });

                    // cuerpoPdf.push({
                    // 	image: await this.getBase64ImageFromURL('http://archivos.jvsoft.pe/img/logos/1/logo-completo.svg'),
                    // 	width: 200
                    // });


                    this.finalizarCreacionPdf(cuerpoPdf);
                });
            });
    }

    reporteCompras(dataReq: object, retornarUrl = false, callbackUrl?: (dat) => void) {
        this.queryService.cargar(this, ['log.compras_reporte'], dataReq).then((valRetorno: object) => {
            console.log(valRetorno);
            const dataRecibido: any[] = valRetorno['log.compras_reporte'];
            console.log(dataRecibido);
            console.log(typeof dataRecibido);

            let dataGeneral;

            const cuerpoPdf = [];

            if (this.filtroBusquedaService.getValue('compras_reporte')) {
                cuerpoPdf.push(this.detalleFiltro(this.filtroBusquedaService.getValue('compras_reporte').datosFiltroConLabel));
            }


            const celdasHeaderDetalle = [
                [
                    this.imprimirCelda('DATOS DEL BIEN', null, {colSpan: 3, style: 'thSmall'}),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    // this.imprimirCelda('U/M', null, {rowSpan: 2, style: 'thSmall'}),
                    this.imprimirCelda('SOLICITANTE', null, {rowSpan: 2, style: 'thSmall'}),
                    this.imprimirCelda('ESTABLECIMIENTO.', null, {rowSpan: 2, style: 'thSmall'}),
                    this.imprimirCelda('DEPENDENCIA', null, {rowSpan: 2, style: 'thSmall'}),
                    this.imprimirCelda('CANT.', null, {rowSpan: 2, style: 'thSmall'}),
                    this.imprimirCelda('VALOR', null, {colSpan: 2, style: 'thSmall'}),
                    this.imprimirCelda(''),
                ],
                [
                    this.imprimirCelda('Nº', null, {style: 'thSmall'}),
                    this.imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                    this.imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall'}),
                    // this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda('P.U.', null, {style: 'thSmall'}),
                    this.imprimirCelda('SUB-TOTAL', null, {style: 'thSmall'}),
                ],
            ];
            const celdasBodyDetalle = [];


            let sumaGeneral = 0;
            Object.keys(dataRecibido).forEach((idxCompra) => {
                if (!dataGeneral) {
                    dataGeneral = dataRecibido[idxCompra][0];
                }
                const sumaObj = sumarObjetos(dataRecibido[idxCompra], [
                    'nCompraDetValorItemTotal',
                ]);
                let colorAnulado;
                dataRecibido[idxCompra].filter((dataProd, idxProd) => {
                    let bordeProd = [true, false, true, false];
                    if (idxProd == 0) {
                        const txtSeccion = [
                            dataProd.cTipoDocSigla,
                            ' - ',
                            dataProd.iCompraNumero,
                            ' - ',
                            formatDate(dataProd.dCompraEmision, 'dd/MM/yyyy', 'es-PE'),
                            ' - ',
                            'Proveedor:',
                            dataProd.cTipoIdentProveedorSigla,
                            ' - ',
                            dataProd.cPersProveedorDocumento,
                            ' - ',
                            dataProd.cPersProveedorNombreLargo,
                            ' - ',
                            dataProd.cCompraReferencia,
                        ];
                        if (dataProd.bCompraAnuladoRegistro == 1) {
                            txtSeccion.push(' ANULADO');
                            colorAnulado = '#780000';
                        }
                        celdasBodyDetalle.push(
                            [
                                this.imprimirCelda(txtSeccion.join(' '), null, {
                                    colSpan: 9, alignment: 'left', style: 'thSmall', color: colorAnulado,
                                }),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                // this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                                this.imprimirCelda(''),
                            ],
                        );
                    }
                    if (idxProd == (dataRecibido[idxCompra].length - 1)) {
                        bordeProd = [true, false, true, true];
                    }
                    celdasBodyDetalle.push(
                        [
                            this.imprimirCelda(idxProd + 1, 'number', {
                                border: bordeProd, style: 'tdSmall', alignment: 'center',
                            }),
                            this.imprimirCelda(dataProd.cCodigoProducto, null, {border: bordeProd, style: 'tdSmall'}),
                            this.imprimirCelda(
                                (dataProd.cProductoNombreObs ?? '') +
                                (dataProd.bCompraAnuladoRegistro == 1 ? (' ' + dataProd.cCompraAnuladoMotivo) : ''), null, {
                                    border: bordeProd, style: 'tdSmall', color: colorAnulado,
                                }),
                            // this.imprimirCelda(dataProd.cPresentacionNombre, null, {style: 'tdSmall'}),
                            this.imprimirCelda(dataProd.cPersSolicitanteNombreLargo, null, {
                                border: bordeProd, style: 'tdSmall', alignment: 'center',
                            }),
                            this.imprimirCelda(dataProd.cEstablecimientoNombreLargo, null, {
                                border: bordeProd, style: 'tdSmall', alignment: 'center',
                            }),
                            this.imprimirCelda(dataProd.cDepenNombre, null, {
                                border: bordeProd, style: 'tdSmall', alignment: 'center',
                            }),
                            this.imprimirCelda(dataProd.nCompraDetCantidad, 'number', {
                                border: bordeProd, style: 'tdSmall', alignment: 'center',
                            }),
                            this.imprimirCelda(dataProd.nCompraDetPrecioUnitario, 'mon', {
                                border: bordeProd, style: 'tdSmall', alignment: 'right',
                            }),
                            this.imprimirCelda(dataProd.nCompraDetValorItemTotal, 'mon', {
                                border: bordeProd, style: 'tdSmall', alignment: 'right',
                            }),
                        ],
                    );

                });
                celdasBodyDetalle.push(
                    [
                        this.imprimirCelda('TOTAL', null, {
                            colSpan: 8, alignment: 'right', style: 'thSmall', color: colorAnulado,
                        }),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        // this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        this.imprimirCelda(''),
                        imprimirCelda(sumaObj['nCompraDetValorItemTotal'], 'mon', {
                            bold: true, style: 'thSmall', alignment: 'right',
                        }),
                    ],
                );
                sumaGeneral += sumaObj['nCompraDetValorItemTotal'];
            });
            celdasBodyDetalle.push(
                [
                    this.imprimirCelda('TOTAL GENERAL', null, {colSpan: 8, alignment: 'right', style: 'thSmall'}),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    // this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                    imprimirCelda(sumaGeneral, 'mon', {bold: true, style: 'thSmall', alignment: 'right'}),
                ],
            );


            const tablaHeaderMovProd = {
                margin: [0, 5, 0, 10],
                table: {
                    // widths: [25, 70, 25, 45, 158, 55, 55],
                    headerRows: celdasHeaderDetalle.length,
                    widths: this.anchoCols(celdasHeaderDetalle, [2]),
                    body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                },
            };

            cuerpoPdf.push(tablaHeaderMovProd);


            // const dataGeneral = dataRecibido[0][0];
            this.reiniciarOpciones({
                margenesHoja: [50, 80, 50, 40],
                tamanioFuenteExtra: 1,
                // defaultSizeHeaderTable: ['auto', '*', 'auto'],
                defaultLeftCellHeaderTable: this.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    // margin: [0, 10, 5, 0],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                this.imprimirCelda('PAG.', null, {fontSize: 7, alignment: 'center'}),
                                this.imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                            ],
                            [
                                this.imprimirCelda('##pagina##/##total_paginas##', null, {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                                this.imprimirCelda(new Date(), 'fec', {
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable: {
                    margin: [0, 20, 0, 0],
                    alignment: 'center',
                    stack: [
                        this.imprimirCelda('REPORTE DETALLADO DE ÓRDENES\nCOMPRAS / SERVICIOS', null, {
                            fontSize: 13,
                            bold: true,
                        }),
                    ],
                },
            });

            if (retornarUrl) {
                return this.finalizarCreacionPdf(cuerpoPdf, 'otro', callbackUrl);
            }
            this.finalizarCreacionPdf(cuerpoPdf);
        });
    }

    listaRegularPasado(
        titulo: string,
        titulosContenido: ColumnaTabla<any>[],
        dataContenido,
    ) {
        const dataGeneral = new DataEnListaPipe().transform(this.datosUsuarioiEntId.entidades, 'iEntId', this.datosUsuarioiEntId.iEntId);

        const cuerpoPdf = [];

        this.reiniciarOpciones({
            margenesHoja: [50, 80, 50, 40],
            tamanioFuenteExtra: 2,
            defaultLeftCellHeaderTable: {
                margin: 0,
                alignment: 'center',
                stack: [
                    {image: 'logo_completo', width: 130},
                    this.imprimirCelda('RUC: ' + dataGeneral['cEntRuc'], null, {fontSize: 7}),
                    this.imprimirCelda(dataGeneral['cEntDomicilioCorto'], null, {fontSize: 5}),
                    this.imprimirCelda(dataGeneral['cEntTelefono'], null, {fontSize: 5}),
                    this.imprimirCelda(dataGeneral['cEntWeb'], null, {fontSize: 5}),
                ],
            },
            defaultCenterCellHeaderTable: {
                margin: [0, 30, 0, 0],
                alignment: 'center',
                stack: [
                    this.imprimirCelda(titulo, null, {fontSize: 13, bold: true}),
                ],
            },
        });

        const celdasHeaderDetalle = [];
        const tHead = [];

        tHead.push(
            this.imprimirCelda('Nº', null, {style: 'thSmall'}),
        );
        titulosContenido.forEach(dT => {
            if (dT.visible) {
                tHead.push(this.imprimirCelda(dT.label, null, {style: 'thSmall'}));
            }
        });

        celdasHeaderDetalle.push(tHead);
        const celdasBodyDetalle = [];

        dataContenido.filter((dataProd, idxProd) => {
            const tBody = [];

            tBody.push(
                this.imprimirCelda(idxProd + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
            );
            titulosContenido.forEach(dT => {
                if (dT.visible) {
                    let add;
                    switch (dT.type) {
                        case 'date':
                            add = this.imprimirCelda(dataProd[dT.property], 'fec', {style: 'tdSmall'});
                            break;
                        case 'number':
                            add = this.imprimirCelda(formatNumber(dataProd[dT.property], 'es-PE', (dT.format ? dT.format : '0.0-2')), null, {
                                style: 'tdSmall',
                                alignment: (dT.format ? 'right' : 'center'),
                            });
                            if (dT.zeroFill) {
                                add = this.imprimirCelda(new ZeroFillPipe().transform(dataProd[dT.property], dT.zeroFill), null, {
                                    style: 'tdSmall',
                                    alignment: 'right',
                                });
                            }
                            break;
                        default:
                            add = this.imprimirCelda(dataProd[dT.property], null, {style: 'tdSmall'});
                            break;
                    }

                    tBody.push(add);
                }
            });
            celdasBodyDetalle.push(tBody);
        });
        const tablaHeaderPedido = {
            margin: [0, 5, 0, 10],
            table: {
                // widths: ['*'],
                widths: this.anchoCols(celdasHeaderDetalle, [2]),
                body: celdasHeaderDetalle.concat(celdasBodyDetalle),
            },
        };

        cuerpoPdf.push(tablaHeaderPedido);

        this.finalizarCreacionPdf(cuerpoPdf);

    }

    reportesStockMovimientosProductosDetalles(dataRec, columnasTabla: ColumnaTabla<any>[]) {
        stockMovimientosProductos(this, dataRec, columnasTabla);
    }

    reportesStockProductos(dataRec, columnasTabla: ColumnaTabla<any>[]) {
        this.queryService.cargar(this, ['log.productos_stock_reporte'], {
            ...dataRec,
            ...{
                iPaginacion: 0,
            },
        }).then((valRetorno) => {
            const resultadosGenerales = valRetorno['log.productos_stock_reporte'];
            this.listaRegular({
                titulo: 'REPORTE\nSTOCK PRODUCTOS',
                nombreColeccion: 'productos_stock_reporte',
                titulos: columnasTabla,
                contenido: resultadosGenerales,
                orientacion: 'portrait',
                numeracion: true,
                idxResto: [3],
            }, {tamanioFuenteExtra: 1});
        });

    }

    reportePecosas(dataRec) {
        reportePecosas(this, dataRec);
    }

    reporteProductos(dataReq: object) {
        this.queryService.cargar(this, ['log.productos'], dataReq).then((valRetorno: object) => {
            console.log(valRetorno);
            const dataRecibido: any[] = valRetorno['log.productos'];
            console.log(dataRecibido);
            console.log(typeof dataRecibido);

            let dataGeneral;

            const cuerpoPdf = [];

            const celdasHeaderDetalle = [
                [
                    this.imprimirCelda('Nº', null, {style: 'thSmall'}),
                    this.imprimirCelda('CÓDIGO PRODUCTO', null, {style: 'thSmall'}),
                    this.imprimirCelda('NOMBRE PRODUCTO', null, {style: 'thSmall'}),
                    this.imprimirCelda('PRESENTACION', null, {style: 'thSmall'}),
                ],
            ];
            const celdasBodyDetalle = [];
            dataRecibido.filter((data, i) => {

                celdasBodyDetalle.push(
                    [
                        this.imprimirCelda(i + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                        this.imprimirCelda(data.cCodigoProducto, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        this.imprimirCelda(data.cProductoNombreObs ?? data.cProductoNombre, null, {style: 'tdSmall'}),
                        this.imprimirCelda(data.cPresentacionNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                    ],
                );

            });


            const tablaHeaderProd = {
                margin: [0, 5, 0, 10],
                table: {
                    // widths: [25, 70, 25, 45, 158, 55, 55],
                    headerRows: celdasHeaderDetalle.length,
                    widths: this.anchoCols(celdasHeaderDetalle, [2]),
                    body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                },
            };

            cuerpoPdf.push(tablaHeaderProd);


            // const dataGeneral = dataRecibido[0][0];
            this.reiniciarOpciones({
                margenesHoja: [50, 80, 50, 40],
                tamanioFuenteExtra: 1,
                // defaultSizeHeaderTable: ['auto', '*', 'auto'],
                defaultLeftCellHeaderTable: this.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    // margin: [0, 10, 5, 0],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                this.imprimirCelda('PAG.', null, {fontSize: 7, alignment: 'center'}),
                                this.imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                            ],
                            [
                                this.imprimirCelda('##pagina##/##total_paginas##', null, {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                                this.imprimirCelda(new Date(), 'fec', {
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable: {
                    margin: [0, 20, 0, 0],
                    alignment: 'center',
                    stack: [
                        this.imprimirCelda('REPORTE DE PRODUCTOS', null, {
                            fontSize: 13,
                            bold: true,
                        }),
                    ],
                },
            });

            this.finalizarCreacionPdf(cuerpoPdf);
        });
    }


}
