import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {stagger40ms} from '@vex/animations/stagger.animation';
import {TabSeccion} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';
import {getInitials} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla, OpcionSeleccionada, SeccionesBotonesMantenimiento} from '@JVSoft/interfaces/global';
import {UntilDestroy} from '@ngneat/until-destroy';
import {establecerEstilosTramites, getEstadoDocumento} from '@tramite/tramite.functions';

interface BotonEstado {
    id: number;
    label?: string;
    iniciales?: string;
    documento?: string;
    colorFondo?: string;
    colorTexto?: string;
    preIcon?: { id: number; icon?: string; classes?: string[] };
    postIcon?: { id: number; icon?: string; classes?: string[] };
    negrita?: boolean;
    tooltip?: string;
    cssClases?: {
        contenedor?: string,
        texto?: string,
    };
}

@UntilDestroy()
@Component({
    selector: 'general-tramite-tabla',
    templateUrl: './tramite-tabla.component.html',
    styleUrls: ['./tramite-tabla.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
    ],
})
export class TramiteTablaComponent implements OnInit {
    @Input() objThis;
    @Input() dataSource;
    @Input() dataSuscription;
    @Input() leyenda;
    @Input() botonesCRUD = '';
    @Input() exportarExcel = true;
    @Input() paginador = true;
    @Input() barraFiltro = false;
    @Input() asignarValoresFiltro = {};
    @Input() busquedaAutomatica = true;
    @Input() nombreColeccion = 'tramites';
    @Output() opcionSelecionada = new EventEmitter<OpcionSeleccionada>();
    @Output() resultados = new EventEmitter<any>();
    @Output() accionRecargar = new EventEmitter();
    @Output() dataFiltro = new EventEmitter();

    /*

    bTramMovCopia: "0"
cDespachoEmisorEnvioNombreCorto: "T.A.-MPV"
cDespachoEmisorEnvioNombreLargo: "MESA DE PARTES VIRTUAL"
cMovTramNombre: "PARA TRAMITAR"
cPersCredEmisoEnvioNombreLargo: "SALAS HUANCA ANTONIO"
cPersCredEmisorEnvioDocumento: "43177406"
cPersFirmaEmisorEnvioDocumento: "42294994"
cPersFirmaEmisorEnvioNombre: "LIÑAN CHURA JORGE FELIX"
cTipoMovNombre: "MOVIMIENTO INTERNO"
cTipoPrioNombre: "NORMAL"
cTipoTramNombre: "TRÁMITE INTERNO"
cTramAdjuntarArchivoAnexo: null
cTramAdjuntarArchivoExterno: null
cTramAdjuntarArchivoInterno: null
cTramAdjuntoFisico: null
cTramAsuntoDocumento: "prueba"
cTramContenido: null
cTramDocumentoReferencia: ""
cTramDocumentoTramite: "CARTA 10-2022-GRM/GRE-MOQ/UGEL \"MN\"/DIR/T.A.M.P.V."
cTramEmisorEnvioNombreLargo: "LIÑAN CHURA JORGE FELIX - MESA DE PARTES VIRTUAL"
cTramMovAdjuntoFisico: null
cTramMovCopia: "ORIGINAL"
cTramMovObsEnvio: null
cTramObservaciones: null
dtTramFechaHoraDocumento: "2022-01-10 22:28:21.737"
dtTramFechaHoraRegistro: "2022-01-10 17:28:41.990"
dtTramMovFechaHoraEnvio: "2022-01-10 18:04:39.273"
iCredEmisorEnvioId: "1"
iDespachoEmisorEnvioId: "51"
iMotTramId: "1"
iPageNumber: "1"
iPageSize: "25"
iPersCredEmisorEnvioId: "1"
iPersFirmaEmisorEnvioId: "2805"
iTipoMovId: "1"
iTipoPrioId: "1"
iTipoTramId: "1"
iTotalRegistros: "1"
iTramFolios: "1"
iTramId: "945"
iTramMovFolios: "1"
iTramMovId: "1038"
iTramMovPlazoResolverDias: "3"
iTramNumRegistroYear: "589"
iTramYearRegistro: "2022"
     */

    @Input() opcionesFiltro: TabSeccion[] = [
        {
            label: 'Día',
            params: [
                {control: 'date', campo: 'cFecha', val: new Date(), text: '', label: 'Día', flex: '100px'},
            ],
        },
        {
            label: 'Periodo',
            params: [
                {
                    control: 'select', change: 'iMonthId', campo: 'iYearId', campoId: 'iTramYearDocumento',
                    data: 'iTramYearDocumento_tramites', val: '*primero*', text: 'iTramYearDocumento', label: 'Año',
                    flex: '100px',
                },
                {
                    control: 'select', campo: 'iMonthId', campoId: 'iTramMonthDocumento',
                    data: 'iTramMonthDocumento_tramites', dataChangeReqParams: ['iYearId'], val: '',
                    text: 'cMonthNombre', label: 'Mes', flex: '100px',
                },
            ],
        },
        {
            label: 'Rango de Fechas',
            params: [
                {
                    control: 'date_range', campo: ['cFechaDesde', 'cFechaHasta'],
                    val: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()], text: '',
                    label: 'Periodo', flex: '200px',
                },
            ],
        },
        {
            label: 'Criterio de Búsqueda', params: [
                {
                    control: 'select', campo: 'iYearCriterio', campoId: 'iTramYearDocumento',
                    data: 'iTramYearDocumento_tramites', val: '*primero*', text: 'iTramYearDocumento', label: 'Año',
                    flex: '100px',
                },
                {
                    control: 'select', campo: 'iCritId', data: 'criterio_busqueda_tramites', val: '3',
                    text: 'cCritDescripcion', label: 'Criterio', flex: '150px',
                },
                {control: 'text', campo: 'cCritVariable', text: '', label: 'Texto', flex: '150px', keyUpEnter: true},
            ],
        },
    ];
    @Input() columnasTabla: ColumnaTabla<any>[] = [
        {
            label: 'Selección', property: 'iTramMovId', chkField: ['iTramId', 'iTramMovId'], chkFieldSeparador: '@', type: 'checkbox', sticky: true,
            headerCssClasses: ['p-0'],
        },
        {label: '#', property: 'numeracion_automatica', type: 'numeracion_automatica'},
        {label: 'T', labelLista: 'Tipo', property: 'tipoTramiteMovimientoDefault', type: 'estiloEstablecido'},
        /*{
         label: 'Registro2',
         property: 'fech_hora2',
         type: 'text_rows',
         text_rows: [
         { label: 'Reg. Único', value: 'iTramNumRegistroYear', textArray: ['iTramNumRegistroYear', '*-', 'iTramYearRegistro'], cssValueClasses: ['font-bold'], cssClasses: ['whitespace-nowrap'] },
         { label: 'Reg. Despacho', value: 'iTramNumRegistroDespachoYear', textArray: ['iTramNumRegistroDespachoYear', '*-', 'iTramYearRegistro'], cssValueClasses: ['font-bold'], cssClasses: ['whitespace-nowrap'] },
         // { label: 'Envi.', value: 'iTramYearRegistro', cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'], cssClasses: ['whitespace-nowrap'] },
         ],
         visible: true,
         sticky: true,
         cssClasses: ['font-bold', 'font-mono']
         },*/
        {
            label: 'Registro', property: 'iTramNumRegistroYear', type: 'text',
            textArray: ['iTramNumRegistroYear', '*-', 'iTramYearRegistro'], sticky: true,
            cssClasses: ['font-bold', 'font-mono', 'text-center', 'text-primary'],
        },
        {
            label: 'Correlativo Despacho', property: 'iTramNumRegistroDespachoYear', type: 'text',
            textArray: ['iTramNumRegistroDespachoYear', '*-', 'iTramYearRegistro'],
            cssClasses: ['font-bold', 'font-mono', 'text-center', 'text-indigo-900'],
        },
        {label: 'Fecha', property: 'dtTramFechaHoraRegistro', type: 'estiloEstablecido', propertyTemplate: 'campoFechaHora'},
        {label: 'Emisor', property: 'cDepenNombreEmisorDefault', type: 'estiloEstablecido',},
        {label: 'Documento', property: 'DocumentoFilasDefault', type: 'estiloEstablecido',},
        {label: 'Asunto', property: 'cTramAsuntoDocumentoDefault', type: 'estiloEstablecido',},
        {label: 'Destino', property: 'cTramReceptorNombreLargo', type: 'custom'},
        {label: 'Estado', property: 'cTramMovEstadoTramite', type: 'custom'},
    ];
    @Input() botonesPrincipal: SeccionesBotonesMantenimiento = {};

    botonesEstado: BotonEstado[] = [
        {id: 1, colorTexto: '#38393b', colorFondo: '#babbba', cssClases: {contenedor: 'bg-gray-200 text-gray-700'}, postIcon: {id: 1, icon: 'fa5sExclamationTriangle'}}, // '1|CREADO PARA TRAMITE|'
        {id: 2, colorTexto: '#38393b', colorFondo: '#ffb821', cssClases: {contenedor: 'bg-amber-400 text-gray-900'}}, // '2|CREADO PARA ARCHIVADO|'
        {id: 3, colorTexto: '#df0329', colorFondo: '#babbba', cssClases: {contenedor: 'bg-gray-200 text-red-700 font-bold'}, negrita: true}, // '3|CREADO PARA REFERENCIA|'
        {id: 4, colorTexto: '#fafafa', colorFondo: '#4daf50', cssClases: {contenedor: 'bg-green-700 text-green-100'}}, // '4|ENVIADO||'
        {id: 5, colorTexto: '#fafafa', colorFondo: '#2d356f', cssClases: {contenedor: 'bg-indigo-900 text-indigo-100'}}, // '5|FUE RECIBIDO||'
        {id: 6, colorTexto: '#fafafa', colorFondo: '#38393b', cssClases: {contenedor: 'bg-gray-700 text-gray-100'}}, // '6|RECIBIDO||'
        {id: 7, colorTexto: '#fafafa', colorFondo: '#4daf50', cssClases: {contenedor: 'bg-green-900 text-green-100 font-bold'}, negrita: true, preIcon: {id: 0, icon: 'twotoneCheck'}}, // '7|ATENDIDO|'
        {id: 8, colorTexto: '#38393b', colorFondo: '#ffb821', cssClases: {contenedor: 'bg-amber-400 text-gray-900 font-bold'}, negrita: true}, // '8|FUE ARCHIVADO||'
        {id: 9, colorTexto: '#38393b', colorFondo: '#ffb821', cssClases: {contenedor: 'bg-amber-700 text-gray-100'}}, // '9|ARCHIVADO||'
        {id: 10, colorTexto: '#fafafa', colorFondo: '#4daf50', cssClases: {contenedor: 'bg-green-700 text-green-100'}}, // '10|DERIVADO||'
    ];
    estadoSecundario = [
        {id: 1, icon: 'checkCircle', classes: ['text-green']}, // se tramito con
        {id: 2, icon: 'fa5sReply', classes: ['text-cyan']}, // se derivo con
    ];

    estilosBotonesEstado = {
        1: {label: 'creado', colorFondo: '#babbba', colorTexto: '#38393b'},
        2: {label: 'atendido', colorFondo: '#4daf50', colorTexto: '#fafafa', negrita: true, preIcon: 'twotoneCheck'},
        3: {label: 'enviado', colorFondo: '#4daf50', colorTexto: '#fafafa'},
        4: {label: 'fue_archivado', colorFondo: '#ffb821', colorTexto: '#38393b', negrita: true},
        5: {label: 'archivado', colorFondo: '#ffb821', colorTexto: '#38393b'},
        6: {label: 'fue_recibido', colorFondo: '#2d356f', colorTexto: '#fafafa'},
        7: {label: 'recibido', colorFondo: '#38393b', colorTexto: '#fafafa'},
        8: {label: 'pendiente', colorFondo: '#babbba', colorTexto: '#df0329', negrita: true},
    };


    fechaActual = new Date();

    estadoRecibido = {
        1: {label: '', icon: 'checkCircle', classes: ['text-green']}, // se tramito con
        2: {label: '', icon: 'fa5sReply', classes: ['text-cyan']}, // se derivo con
        // 3: {label: '', icon: 'shareCircle', classes: ['text-orange']}, //
    };
    filtroSemaforo = [
        {label: 'Todos', class: 'bg-transparent border-2 border-black', value: 0},
        {label: 'Rojo', class: 'text-red-500', value: 1},
        {label: 'Ambar', class: 'text-yellow-500', value: 2},
        {label: 'Verde', class: 'text-green-500', value: 3},
    ];

    opcionesFiltroBusquedaActual = {};


    condicionesClaseFila = (item) => {
        const listaClases = [];
        switch (item.iTramMovSemaforoPlazoResolver * 1) {
            case 1:
                listaClases.push('bg-semaforo-rojo');
                break;
            case 2:
                listaClases.push('bg-semaforo-ambar');
                break;
            case 3:
                listaClases.push('bg-semaforo-verde');
                break;
        }
        return listaClases;
    }


    constructor() {
        // this.getEstadoDocumento('1|CREADO PARA TRAMITE|');
        // this.getEstadoDocumento('8|CREADOfasfsdfsdfsdf PARA TRAMITE|otra gran cosa');
        this.columnasTabla = establecerEstilosTramites(this, this.columnasTabla);
    }

    ngOnInit(): void {
    }


    estiloBoton(row) {
        const dataEstado = getEstadoDocumento(row.cTramMovEstadoTramite);
        const objBotonEstado = this.botonesEstado.find(item => item.id == dataEstado.id);
        if (objBotonEstado) {
            const estadoSegundario = this.estadoSecundario.find(item2 => item2.id == dataEstado.idSecundario);
            objBotonEstado.postIcon = estadoSegundario || undefined;
            objBotonEstado.label = dataEstado.label;
            objBotonEstado.iniciales = getInitials(dataEstado.label);
            objBotonEstado.tooltip = dataEstado.doc;
        }
        return objBotonEstado;
    }


}
