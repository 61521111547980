import {ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, startWith} from 'rxjs/operators';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {checkRouterChildsData} from '@vex/utils/check-router-childs-data';
import {SidebarComponent} from '@vex/components/sidebar/sidebar.component';
import {QueryService} from '@servicios/query.service';
import {SessionService} from '../../session/services/session.service';
import {LayoutService} from '@vex/services/layout.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {stagger40ms} from '@vex/animations/stagger.animation';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, interval, mergeMap, Subscription} from 'rxjs';
import {EntidadService} from '@servicios/entidad.service';
import {PERFILES_USUARIO, UsuarioService} from '@servicios/usuario.service';
import {mensajesErrorFormControl, mensajeTimer} from '@JVSoft/services/funciones-globales.service';
import {FormValidators} from '@JVSoft/validators/form-validators';
import {NumericValueType, RxwebValidators} from '@rxweb/reactive-form-validators';
import {ConfigServiceMod} from "@vexMod/config/config.service";
import {NavigationServiceMod} from "@vexMod/services/navigation.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
import {ServidorService} from '@servicios/servidor.service';
import {getCambiarPwd, jwtTokenExpiracionFaltante, setCambiarPwd} from '@JVSoft/functions/local-storage';

@UntilDestroy()
@Component({
    selector: 'jvsoft-principal-layout',
    templateUrl: './principal-layout.component.html',
    styleUrls: ['./principal-layout.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
    ],
})
export class PrincipalLayoutComponent implements OnInit {
    mensajesErrorFormControl = mensajesErrorFormControl;

    dataServidor = {
        personas_contactos: null,
        seg: {
            credenciales_entidades: null,
        },
    };

    entidadesCredencial = null;

    frmRegistro: FormGroup;
    frmRegistroCodigo: FormGroup = this.fb.group({
        // cCodigoSeguridad: ['', [RxwebValidators.numeric({digitsInfo: '1.0-2', isFormat: true, allowDecimal: true})]],
        cCodigoSeguridad: [
            '',
            [
                RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false}),
            ],
        ],
    });

    inputType = 'password';
    visible = false;

    dialogRefSelEntidad: MatDialogRef<any, any>;
    dialogRefCambiarPWD: MatDialogRef<any, any>;

    routePathActual = new BehaviorSubject('');
    _routeUrlActual = new BehaviorSubject('');
    routeUrlActual$ = this._routeUrlActual.asObservable();

    sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
    isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
    isDesktop$ = this.layoutService.isDesktop$;

    toolbarShadowEnabled$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled)),
    );

    @ViewChild('configpanel', {static: true}) configpanel: SidebarComponent;
    @ViewChild('dialogCambiarPasswordObligatorio', {static: true}) dialogCambiarPasswordObligatorioRef: TemplateRef<any>;
    @ViewChild('dialogCambiarCodigoSeguridad', {static: true}) dialogCambiarCodigoSeguridadRef: TemplateRef<any>;
    @ViewChild('dialogSeleccionarEntidad', {static: true}) dialogSeleccionarEntidadRef: TemplateRef<any>;

    _tiempoRestante = new BehaviorSubject(9999999999);
    tiempoRestante$ = this._tiempoRestante.asObservable();

    tiempoExpiraSesion;
    btnCancelarCambioPWD = false;
    btnCancelarCambioCodigo = false;

    timer;
    suscripToken: Subscription;
    tokenRequest = false;

    private listaSuscripcionesRutas$: {[key: string]: Subscription} = {};
    private ejecutadoUnaVez = false;
    constructor(
        private queryService: QueryService,
        private sessionService: SessionService,
        // public funcionesGlobalesService: FuncionesGlobalesService,
        private fb: FormBuilder,
        private layoutService: LayoutService,
        private configService: ConfigServiceMod,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private navigationService: NavigationServiceMod,
        private entidadService: EntidadService,
        public servidorService: ServidorService,
        public usuarioService: UsuarioService,

        //agregados para compatibilizar iconos
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,

        @Inject(DOCUMENT) private document: Document,
    ) {
        
        this.router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                if (event.snapshot.routeConfig.path != '') {
                    this.routePathActual.next(event.snapshot.routeConfig.path);
                }
            }
            if (event instanceof NavigationEnd) {
                this._routeUrlActual.next(event.url);
            }
        });

        // NOTIFICACIONES ALMACEN


       this.routeUrlActual$.pipe(untilDestroyed(this)).subscribe(dat => {
           if (dat) {
               const dataSolicitud = {};
               if (dat.includes('/almacen/')) {
                   const perfilesPermitidos = [PERFILES_USUARIO.ALMACENERO];
                   if (!(this.usuarioService.verificarPerfil(perfilesPermitidos) || this.usuarioService.esEquipoJVSoft())) {
                       dataSolicitud['porCredencial'] = 1;
                   }
                   if (!this.listaSuscripcionesRutas$.almacen) {
                       this.cargarNotificacionesAlmacen(dataSolicitud).toPromise().then();
                       const minutosConsulta = 5;
                       this.listaSuscripcionesRutas$.almacen = interval(minutosConsulta * 60 * 1000).pipe(untilDestroyed(this))
                       .pipe(
                           mergeMap(() => this.cargarNotificacionesAlmacen(dataSolicitud))
                       )
                           .subscribe()
                       // .subscribe(data => console.log(data))
                   }
               }
               else {
                   if (this.listaSuscripcionesRutas$.almacen) {
                       this.listaSuscripcionesRutas$.almacen.unsubscribe();
                   }
               }
               // console.warn(dat);
           }
       });
    }

    ngOnInit() {
        this.configService.updateConfig({
            sidenav: {
                title: 'JVSOFT EIRL',
                user: {
                    visible: false,
                },
                search: {
                    visible: false,
                }
            },
            footer: {
                visible: false
            },
        });

        this.crearFormularios();


        this.layoutService.configpanelOpen$.pipe(
            untilDestroyed(this),
        ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());

        this.usuarioService.data$.pipe(untilDestroyed(this)).subscribe(data => {
            // console.warn('CARGA DESDE LAYOUT');
            this.frmRegistro.get('iPersId').setValue(data['iPersId']);
            if (data['segExpiraSesion']) {
                // this._tiempoRestante.next(data['segExpiraSesion'] * 1);
                this._tiempoRestante.next(jwtTokenExpiracionFaltante());
            }
            if (data['entidades'] && !data['iCredEntId'] && !this.dialogRefSelEntidad) {
                this.entidadesCredencial = data['entidades'];
                if (typeof data['entidades'] == 'object') {
                    this.entidadesCredencial = Object.values(data['entidades']);
                }

                // this.dialogRefSelEntidad = this.dialog.open(this.dialogSeleccionarEntidadRef, {
                //     closeOnNavigation: true,
                //     panelClass: 'dialogMantenimientoNuevo',
                //     disableClose: true,
                // });
            }
            if (data['mismo_password'] && !this.usuarioService._cambiarPassword.getValue()) {
                if (getCambiarPwd() == null){
                    setCambiarPwd(data['mismo_password']);
                    this.usuarioService._cambiarPassword.next(data['mismo_password']);
                }
            }

            this.armarMenuLateral();

            // if (data['accesos']) {
            //     const itemsNav = [];
            //
            //     itemsNav.push({
            //         type: 'link',
            //         label: 'Inicio',
            //         route: '/dashboard',
            //         icon: 'mat:layers'
            //     });
            //     if (this.route.snapshot.queryParams.mod) {
            //         data['accesos'] = data['accesos'].filter(acceso => acceso.cMenuEnlace.replace('/', '') == this.route.snapshot.queryParams.mod.replace('/', ''));
            //     }
            //     data['accesos'].filter(acceso => {
            //         itemsNav.push(this.organizarMenus(acceso));
            //     });
            //     if (data['entidadActual'] && data['entidadActual']['manuales']) {
            //         const datAA: any[] = data['entidadActual']['manuales'];
            //         const subManuales = [];
            //         datAA.forEach(manual => {
            //             subManuales.push({
            //                 type: 'link',
            //                 label: manual['cArchivoNombre'],
            //                 route: () => {
            //                     this.queryService.downloadFile({
            //                         f: manual['cArchivoRuta'].replace('storage/', ''),
            //                     }, true);
            //                 },
            //                 icon: 'roundBook'
            //             });
            //         });
            //
            //         if (subManuales.length > 0) {
            //             itemsNav.push({
            //                 type: 'subheading',
            //                 label: 'Manuales',
            //                 route: '/manuales',
            //                 children: subManuales,
            //                 icon: 'twotoneMenuBook'
            //             });
            //         }
            //     }
            //
            //     this.navigationService.items = itemsNav;
            //     this.routePathActual.subscribe(pathA => {
            //         if (pathA) {
            //             // const modulo = itemsNav.filter(item => (item.path.replace('/', '') == pathA || item.label == 'General'));
            //             const modulo = itemsNav;
            //             if (modulo.length > 0) {
            //                 let lstItems = [];
            //                 modulo.forEach(mod => {
            //                     lstItems = lstItems.concat(mod);
            //                 });
            //                 this.navigationService.subItems = lstItems;
            //                 this.navigationService._subMenus.next(lstItems);
            //             }
            //         }
            //
            //     });
            // }


            this.usuarioService.cambiarPassword$.pipe(untilDestroyed(this)).subscribe(vAct => {
                if (vAct && getCambiarPwd()) {
                    setCambiarPwd(false);
                    this.btnCancelarCambioPWD = true;
                    // this.usuarioService._cambiarPassword.next(false);
                    this.abrirCambiarPasswordDialog(data['iPersId']);
                }
            });


            this.usuarioService.cambiarCodigoSeguridad$.pipe(untilDestroyed(this)).subscribe(vAct => {
                if (vAct && !this.dialogRefCambiarPWD) {
                    this.btnCancelarCambioCodigo = true;
                    this.usuarioService._cambiarCodigoSeguridad.next(false);
                    this.abrirCambiarCodigoSeguridadDialog(data['iPersId']);
                }
            });
        });

        //
        // this.tiempoRestante$.pipe(untilDestroyed(this)).subscribe(segRestantes => {
        //
        // });
        this.tiempoRestante$.pipe(untilDestroyed(this)).subscribe(as => {
            this.tiempoExpiraSesion = this._tiempoRestante.getValue();
        });

        this.suscripToken = interval(1000).pipe(untilDestroyed(this)).subscribe(val => {
            // console.warn(this.tiempoExpiraSesion, val);
            // console.log(val, (val % 60), (val % 60) == 0);
            // const minVerificar = 10;
            // if (val > 0 && this.tiempoExpiraSesion > 90 && (val % (minVerificar * 60)) == 0) {
            //     this.sessionService.tiempoExpiraSesion().toPromise().then(msg => {
            //         this.usuarioService._data.next({...this.usuarioService._data.getValue(), ...{segExpiraSesion: msg}});
            //     });
            // }

            if (!this.tiempoExpiraSesion) {
                this.tiempoExpiraSesion = this._tiempoRestante.getValue();
            }
            else {
                this.tiempoExpiraSesion--;
            }
            const tInicial = this.tiempoExpiraSesion;
            if (!this.tokenRequest && tInicial < 90) {
                this.tokenRequest = true;

                mensajeTimer(
                    'warning',
                    'Finalizando Sesión',
                    'Las credenciales de acceso están por caducar. <br><b>¿Desea renovarlos?</b>',
                    ((tInicial - 60) * 1000),
                    false,
                    {
                        showCancelButton: true,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                    },
                ).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.sessionService.refreshToken().toPromise().then(() => {
                            return this.sessionService.isAuthenticated().toPromise();
                        }).then((response: any) => {
                            if (response) {
                                this.usuarioService._data.next(response);
                                this.tiempoExpiraSesion = null;
                            }
                        });
                        this.tokenRequest = false;
                    }
                    else {
                        this.finalizarSuscripcionToken();
                    }
                });
            }
            if (tInicial < 0) {
                this.finalizarSuscripcionToken();
            }
        });

    }

    armarMenuLateral() {
        let dataAccesos = this.usuarioService._accessos.getValue();
        if (dataAccesos) {
            let dataAccesoNuevo = dataAccesos;
            const itemsNav = [];

            itemsNav.push({
                type: 'link',
                label: 'Inicio',
                route: '/dashboard',
                icon: 'mat:layers'
            });
            if (this.entidadService.idEntidadFija) {
                dataAccesoNuevo = dataAccesos.filter(acceso => acceso.cMenuEnlace.replace('/', '') == this.route.snapshot.queryParams?.mod?.replace('/', ''));
            }
            dataAccesoNuevo.filter(acceso => {
                itemsNav.push(this.organizarMenus(acceso));
            });
            if (this.entidadService.entidadActual && this.entidadService.entidadActual['manuales']) {
                const datAA: any[] = this.entidadService.entidadActual['manuales'];
                const subManuales = [];
                datAA.forEach(manual => {
                    subManuales.push({
                        type: 'link',
                        label: manual['cArchivoNombre'],
                        route: () => {
                            this.queryService.downloadFile({
                                f: manual['cArchivoRuta'].replace('storage/', ''),
                            }, true);
                        },
                        icon: 'roundBook'
                    });
                });

                if (subManuales.length > 0) {
                    itemsNav.push({
                        type: 'subheading',
                        label: 'Manuales',
                        route: '/manuales',
                        children: subManuales,
                        icon: 'twotoneMenuBook'
                    });
                }
            }

            this.navigationService.items = itemsNav;
            this.routePathActual.subscribe(pathA => {
                if (pathA) {
                    // const modulo = itemsNav.filter(item => (item.path.replace('/', '') == pathA || item.label == 'General'));
                    const modulo = itemsNav;
                    if (modulo.length > 0) {
                        let lstItems = [];
                        modulo.forEach(mod => {
                            lstItems = lstItems.concat(mod);
                        });
                        this.navigationService.subItems = lstItems;
                        this.navigationService._subMenus.next(lstItems);
                    }
                }

            });
        }
    }

    finalizarSuscripcionToken() {
        this.dialog.openDialogs.forEach(dialog => {
            dialog.close();
        });
        this.suscripToken.unsubscribe();
        this.sessionService.logout().then(() => {
            this.servidorService.limpiarIntervalos();
        });
    }

    cargarNotificacionesAlmacen(dataSolicitud) {
        return this.queryService.getDataMethod('GET', 'log.compras_que_venceran', dataSolicitud).pipe(untilDestroyed(this))
    }

    abrirCambiarPasswordDialog(iPersId) {
        this.queryService.cargar(this, ['personas_contactos'], {iPersId}, false, 'grl').then(() => {
            let tCel;
            let eMail;
            this.dataServidor.personas_contactos.filter(cont => {
                switch (cont.iTipoConId) {
                    case '2':
                        if (!tCel || cont.bPersConPrincipal == 1) {
                            tCel = cont.cPersConNombre;
                        }
                        break;
                    case '3':
                        if (!eMail || cont.bPersConPrincipal == 1) {
                            eMail = cont.cPersConNombre;
                        }
                        break;
                }
            });
            this.frmRegistro.patchValue({
                cTelefonoMovil: tCel,
                cCorreoElectronico: eMail,
            });
            this.dialogRefCambiarPWD = this.dialog.open(this.dialogCambiarPasswordObligatorioRef, {
                panelClass: 'dialogMantenimientoNuevo',
                disableClose: true,
            });
        });
    }

    abrirCambiarCodigoSeguridadDialog(iPersId) {
        this.dialogRefCambiarPWD = this.dialog.open(this.dialogCambiarCodigoSeguridadRef, {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            width: '400px',
        });
    }


    organizarMenus(acceso, padre = null, pathPadre = null) {

        if (acceso['hijos']) {
            const children = [];
            acceso['hijos'].filter(hijo => {
                children.push(this.organizarMenus(hijo, acceso, pathPadre));
            });

            return {
                id: acceso['iAccesoId'],
                path: (padre ? padre['cMenuEnlace'] : '') + acceso['cMenuEnlace'],

                type: (acceso['cMenuControlador'] && acceso['cMenuControlador'] == 'subheading') ? 'subheading' : 'dropdown',
                label: acceso['cMenuNombrePersonalizado'] ?? acceso['cMenuNombre'],
                icon: acceso['cMenuIcono'],
                queryParams: this.route.snapshot.queryParams,
                children,
            };
        }
        return {
            id: acceso['iAccesoId'],
            path: (padre ? padre['cMenuEnlace'] : '') + acceso['cMenuEnlace'],
            component: acceso['cMenuControlador'],

            type: 'link',
            label: acceso['cMenuNombrePersonalizado'] ?? acceso['cMenuNombre'],
            icon: acceso['cMenuIcono'],
            queryParams: this.route.snapshot.queryParams,
            route: (padre ? padre['cMenuEnlace'] : '') + acceso['cMenuEnlace'],
        };
    }

    seleccionarEntidad(iEntId) {
        this.entidadService.setEntidadActualById(iEntId).then(() => {
            window.location.reload();
        });
    }


    crearFormularios() {
        this.frmRegistro = this.fb.group({
            iPersId: [null],

            cTelefonoMovil: ['', [Validators.required]],
            cCorreoElectronico: ['', [Validators.required, Validators.email]],

            new_password: [
                '', [
                    Validators.required,
                    Validators.minLength(8),
                    // FormValidators.patternValidator(/\d/, { hasNumber: true }),
                    FormValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
                    FormValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
                    // FormValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
                ],
            ],
            re_new_password: ['', Validators.required],
        }, {
            validator: FormValidators.passwordMatchValidator,
        });
    }

    async guardarContatosPassword() {
        if (await this.queryService.enviarFormulario('TelefonoMovilCorreoElectronico', this.frmRegistro, false, 'grl')) {
            this.usuarioService._cambiarPassword.next(false);
            this.dialogRefCambiarPWD.close();
        }
    }
    guardarCodigoSeguridad() {
        this.queryService.guardarDatos('cCredSecurityCode_credenciales', this.frmRegistroCodigo.value, 'seg').then((dataRet) => {
            if (dataRet) {
                console.log(dataRet);
            }
        });
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        }
        else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }
}
