import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {HttpClient, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {FileUploadDirective} from './file-upload.directive';
import {formatDate} from '@angular/common';
import {QueryGeneralService} from '../../services/query-general.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {convertirBytes, mensajeConfirmacion, mensajeToast} from '@JVSoft/services/funciones-globales.service';
import {SweetAlertResult} from 'sweetalert2';
import {environment} from '@ENV';
import {ProgressSpinnerService} from '@JVSoft/components/progress-spinner/progress-spinner.service';


@Component({
    selector: 'general-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor, Validator {

    @Input() temporal = false;
    @Input() permitirEliminar = true;
    @Input() permitirFirmar = false;
    @Input() readonly = false;
    @Input() multiple = true;
    @Input() cssContenedorAgregados;
    @Input() cssContenedorAgregadosLista;

    @Input() extensionesPermitidas: Array<string> = [];
    @Input() parteDeNombre: Array<string> = [];
    @Input() tamanoMaximoMB: number | null = null;
    @Input() parteDeNombreExclusivo: Array<string> = [];
    @Input() contieneEnNombre: Array<string> = [];

    @Output() resultado = new EventEmitter<any>();
    @Output() resultadoEliminado = new EventEmitter<any>();

    @Input() formControlName;


    fileList: any = [];
    fileListServ: any = [];
    invalidFiles: any = [];

    inputValue: any = {};
    isDisabled: boolean;

    token: any;
    ls = window.localStorage;
    httpOptions: any;

    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private queryGeneralService: QueryGeneralService,
        private overlayService: ProgressSpinnerService,
        // private fileUploadDirective: FileUploadDirective,
    ) {
        this.extensionesPermitidas = this.extensionesPermitidas.map(v => v.toLowerCase());

        window.addEventListener('signatureInit', (event) => {
            this.overlayService.show({
                mensaje: 'Firmando...'
            });
            console.log(event);
        })
        window.addEventListener('signatureOk', (event) => {
            this.overlayService.hide({
                mensaje: 'Firma Exitosa!',
                fondoStyle: 'rgba(56,100,40,0.7)',
            });
            // this.dialogActualRef.close('Fin Firma');
            console.log(event);
        })
        window.addEventListener('signatureCancel', (event) => {
            this.overlayService.hide({
                mensaje: 'Error: Hubo un error en el proceso',
                fondoStyle: 'rgba(231, 166, 166, 0.7)',
            });
        })
    }

    onChange = (_: any) => {};

    onTouch = () => {};

    onFilesChange(fileList: Array<File>) {
        // console.log(fileList);
        if (this.temporal) {
            fileList.forEach(file => {
                let fExist = false;
                this.fileList.forEach(fileExist => {
                    if (fileExist.file.name == file.name) {
                        fExist = true;
                    }
                });
                if (!fExist) {
                    this.fileList.push({file, desdeServidor: false, inProgress: false, progress: 0, servFile: null});
                    this.fileListServ.push(null);
                }
            });

            // this.uploadFilesTemporal();

        }
        else {
            this.fileList = fileList;
        }
        this.onInput(this.fileList);

    }

    onFileInvalids(fileList: Array<File>) {
        const archivosTxt = [];
        fileList.forEach(file => {
            archivosTxt.push(file.name);
        });
        this.invalidFiles = fileList;
        if (archivosTxt.length > 0) {
            this.toastr.error('Archivos no válidos: <br>' + archivosTxt.join('<br>'), 'Error:', {enableHtml: true});
            console.warn(this.invalidFiles);
        }
    }

    uploadFile(event) {
        const agregarLista = () => {
            const ret = FileUploadDirective.filtrarArchivos(event, {
                tamanoMaximoMB: this.tamanoMaximoMB,
                parteDeNombre: this.parteDeNombre,
                parteDeNombreExclusivo: this.parteDeNombreExclusivo,
                extensionesPermitidas: this.extensionesPermitidas,
            });
            this.onFilesChange(ret.valid);
        }

        if (this.multiple) {
            agregarLista();
        }
        else {
            if (this.fileList.length == 0) {
                agregarLista();
            }
        }
    }


    onInput(value: any) {
        // console.log(value);
        this.inputValue = value;
        this.onTouch();
        this.onChange(this.inputValue);
    }

    writeValue(value: any): void {
        this.fileList = [];
        // console.warn(value);

        if (value) {
            // console.log(value);
            for (let i = 0; i < 50; i++) {
                if (typeof value != 'object') {
                    value = JSON.parse(value);
                }
                else {
                    break;
                }
                // console.log(i, typeof value, value);
            }

            // console.log(typeof value);
            if (this.temporal) {
                value.forEach(f => {
                    // console.log(f);
                    if (f.nombre) {
                        this.fileList.push({
                            file: {name: f.nombre}, desdeServidor: true, inProgress: false, progress: 100, servFile: f,
                        });
                    }
                    else {
                        const l = f.split('/');
                        const archivoNombre = l[l.length - 1];

                        this.fileList.push({
                            file: {name: archivoNombre}, desdeServidor: true, inProgress: false, progress: 100, servFile: f,
                        });
                    }
                    this.fileListServ.push(f);
                });
            }
        }
        else {
            this.inputValue = this.fileList;
        }
    }

    // @ts-ignore
    validate({value}: FormControl) {
        const isNotValid = false;
        // if (this.temporal) {
        // 	isNotValid = true;
        // 	let estTodos = true;
        // 	for (const file of this.fileList) {
        // 		if (file.servFile == null) {
        // 			estTodos = false;
        // 		}
        // 	}
        // 	if (estTodos) {
        // 		isNotValid = false;
        // 	}
        // }
        // const isNotValid = this.answer !== Number(value);
        return isNotValid && {
            invalid: true,
        };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    convertirFormGroupAFormData(formGroup: FormGroup) {
        const formDataEnv: FormData = new FormData();

        Object.keys(formGroup.controls).forEach(key => {
            if (key == 'archivo') {
                const arch: File = formGroup.controls[key].value[0];
                formDataEnv.append(key, arch, arch.name);
            }
            else {
                formDataEnv.append(key, formGroup.controls[key].value);
            }
        });
        return formDataEnv;
    }

    bytesToOther(val) {
        const unidades = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let i;
        for (i = 0; val > 1000; i++) {
            val /= 1000;
        }
        return val.toFixed(1) + ' ' + unidades[i];

    }

    public uploadFilesPreForm(carpeta: string, anonimo = false): Promise<Awaited<SweetAlertResult| any[]>[]> {
        const l = [];
        this.fileList.forEach((file, idx) => {
            if (!this.fileListServ[idx]) {
                const formData = new FormData();
                formData.append('archivo', file.file);
                formData.append('carpeta', carpeta || 'temp/' + formatDate(new Date(), 'yyyy-MM-dd_HH', 'en'));
                file.inProgress = true;
                l.push(this.queryGeneralService.uploadFile(formData, anonimo).pipe(
                    map(event => {
                        // console.log(event);
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                file.progress = Math.round(event.loaded * 100 / event.total);

                                break;
                            case HttpEventType.Response:
                                if (event.status == 200) {
                                    // console.log('Finalidado', event.body);
                                    file.servFile = event.body;
                                    this.fileListServ[idx] = event.body;
                                    this.onChange(this.fileListServ);
                                } /*else {

								}*/

                            // return event;
                        }
                        // console.log(file, event);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        // console.log(error);
                        let customError;
                        if (typeof error.error == 'string' && error.error.includes('<b>Warning</b>')) {
                            const regex = /\{"(.+)}/gm;
                            let m;
                            while ((m = regex.exec(error.error)) !== null) {
                                // This is necessary to avoid infinite loops with zero-width matches
                                if (m.index === regex.lastIndex) {
                                    regex.lastIndex++;
                                }
                                if (m && m[0]) {
                                    const mensajeError = JSON.parse(m[0]);
                                    customError = mensajeError.message;
                                    // mensajeAlerta('error', 'Error', mensajeError.message);

                                }
                            }
                        }

                        this.toastr.error( (customError ?? error.message), 'Error');
                        file.inProgress = false;
                        file.progress = 0;
                        file.errorSubida = true;
                        // console.log(file);
                        if (file.data) {
                            return of(`${file.data.name} upload failed.`);
                        }
                        return of(`ERROR upload failed.`);
                    })).toPromise(),
                );
            }
        });
        return Promise.all(l).then((as) => {
            // console.log(as);
            const todosBien = this.fileList.map(item => !item.errorSubida).every(Boolean);
            if (!todosBien) {
                if (this.fileList.length != 1) {
                    return mensajeConfirmacion('warning', 'Archivos no cargados', 'Algunos archivos no se cargaron, desea continuar?');
                }
                return undefined;
            }
            // return this.fileList;
            return this.fileList;
        });
    }


    /**
     * Funcion para Subir Archivos en carpeta temporal
     */
    public uploadFilesTemporal() {
        // this.fileUpload.nativeElement.value = '';
        this.fileList.forEach((file, idx) => {
            if (!this.fileListServ[idx]) {
                this.uploadFileTemporal(file, idx);
            }
        });

        return of(this.fileList).toPromise();

    }

    uploadFileTemporal(file, idx) {

        const formData = new FormData();
        formData.append('archivo', file.file);
        formData.append('carpeta', 'temp/' + formatDate(new Date(), 'yyyy-MM-dd_HH', 'en'));
        file.inProgress = true;
        this.queryGeneralService.uploadFile(formData).pipe(
            map(event => {
                // console.log(event);
                /*
                console.log([
                    HttpEventType.UploadProgress,
                    HttpEventType.Response
                ]);
                */
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        file.progress = Math.round(event.loaded * 100 / event.total);
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                file.inProgress = false;
                return of(`${file.data.name} upload failed.`);
            })).subscribe((event: any) => {
            if (typeof (event) === 'object') {
                file.servFile = event.body;
                this.fileListServ[idx] = event.body;
                this.onChange(this.fileListServ);
            }
        });
    }

    eliminarItemTemporal(idx) {
        this.fileList.splice(idx, 1);
        this.fileListServ.splice(idx, 1);
        this.onChange(this.fileList);
    }

    eliminarItemServidor(idx) {
        if (this.permitirEliminar && this.fileList[idx].desdeServidor) {
            this.queryGeneralService.removeFile(
                {f: this.fileListServ[idx].replace('storage/', '')},
                false,
                'Se eliminará definitivamente este archivo. Esta acción no se puede deshacer.',
                'toastr',
            ).then(val => {
                this.fileList.splice(idx, 1);
                this.fileListServ.splice(idx, 1);
                this.onChange(this.fileListServ);
                this.resultadoEliminado.emit(this.fileListServ);
            });
        }
    }

    download(idx, file) {
        console.log(file);
        if (file && file.servFile && file.servFile.key) {
            this.queryGeneralService.downloadFile({
                porTipoAutomatico: 1,
                cArchivoKey: file.servFile.key,
            }, true);
        }
        else {
            // this.fileList.splice(idx, 1);
            // this.fileListServ.splice(idx, 1);
            // this.onChange(this.fileList);
            this.queryGeneralService.downloadFile({
                f: this.fileListServ[idx].replace('storage/', ''),
            }, true);
        }
    }
    eliminar(idx, file) {
        console.log(file);
        if (file && file.servFile && file.servFile.key) {
            mensajeConfirmacion('warning', 'Eliminar Archivo', 'Se eliminará definitivamente este archivo. Esta acción no se puede deshacer.').then(result => {
                if (result.isConfirmed) {
                    this.queryGeneralService.guardarDatos('mantenimiento#archivos', { id: file.servFile.id }, 'grl', 'toastr').then(val => {
                        if (val) {
                            this.fileList.splice(idx, 1);
                            this.fileListServ.splice(idx, 1);
                            this.onChange(this.fileListServ);
                            this.resultadoEliminado.emit(this.fileListServ);
                        }
                    });
                }
            });
            /*
            this.queryGeneralService.guardarDatos('mantenimiento#archivos', { iArchivoId: file.servFile.id }, 'grl', 'toastr').then()
            this.queryGeneralService.removeFile(
                {f: file.servFile.path.replace('storage/', '')},
                false,
                'Se eliminará definitivamente este archivo. Esta acción no se puede deshacer.',
                'toastr',
            ).then(val => {
                this.fileList.splice(idx, 1);
                this.fileListServ.splice(idx, 1);
                this.onChange(this.fileListServ);
                this.resultadoEliminado.emit(this.fileListServ);
            });
            */
        }
        else {
            this.fileList.splice(idx, 1);
            this.fileListServ.splice(idx, 1);
            this.onChange(this.fileList);
        }
    }

    mostrarDataArchivo(archivo) {
        console.log(archivo);
    }
    firmarDocumentos(file = null, tipo: 'firma' | 'vb' = 'firma') {
        if (environment['firmaPeru']) {
            const dFirma = environment['firmaPeru'];
            if (dFirma.urlFirma) {
                let archivo = file ?? '';
                const paramsFirma = {
                    "param_url": dFirma.urlFirma,
                    "param_token": 'tram|' + archivo.keyFirmaTramite + '@' + tipo,
                    "document_extension": "pdf",
                    // archivo: archivo,
                    // archivos: this.listaArchivos,
                    //
                    // urlFirma: generarLinkFirma(this.entidadService._entidadActual.getValue().iEntId, this.mensaje),
                };

                console.log(paramsFirma);
                const port = "48596";
                const param = btoa(JSON.stringify(paramsFirma));
                console.log(param);
                // @ts-ignore
                startSignature(port, param);
                // firma(port, param)
            }
            else {
                mensajeToast('error', 'Configuración Faltante', 'Faltan especificar datos de respuesta de firma. Contacte con soporte de TI.');
            }
        }
        else {
            mensajeToast('error', 'Configuración Faltante', 'Tiene que especificar configuración de Firma en variables de entorno. Contacte con soporte de TI');
        }
    }

    protected readonly convertirBytes = convertirBytes;
}
