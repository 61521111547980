import {ChangeDetectorRef, Component, Input, OnInit, Optional, QueryList, ViewChildren} from '@angular/core';
import {MatColumnDef, MatTable} from '@angular/material/table';
import {ColumnaTabla} from '@JVSoft/interfaces/global';

@Component({
	selector: 'general-mat-table-default-columns-defs',
	templateUrl: './mat-table-default-columns-defs.component.html',
	styleUrls: ['./mat-table-default-columns-defs.component.scss'],
	host: {
		class: 'simple-column cdk-visually-hidden',
		'[attr.ariaHidden]': 'true',
	}
})
export class MatTableDefaultColumnsDefsComponent implements OnInit {
	@Input() objThis;
	@Input() column: ColumnaTabla<any>;
	@Input() colDetalle: ColumnaTabla<any>[];

	@ViewChildren(MatColumnDef) columnDef: QueryList<MatColumnDef>;

	constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) { }

	ngOnInit() {
		if (this.table) {
			this.cdRef.detectChanges();
			console.log(this.columnDef);
			this.columnDef.forEach(refCol => {
				this.table.addColumnDef(refCol);
			});
		}
	}

	trackByProperty<T>(index: number, column: ColumnaTabla<T>) {
		return column.property;
	}

	verTexto(row, campo) {
		// console.log('CARGAR TEXTO');
		let strFinal = '';
		const arrFinal = [];
		campo.forEach(c => {
			// console.log(c);
			if (c.charAt(0) == '*') {
				arrFinal.push(c.replace('*', ''));
			} else {
				arrFinal.push(row[c]);
			}
		});
		strFinal = arrFinal.join(' ');
		return strFinal;
	}

}
