import * as CryptoJS from 'crypto-js';

// Clave de encriptación (debe tener 16, 24 o 32 bytes)
const secretKey = 'JVSoft@20605178350'; // Cambia esto por tu clave secreta

// Función para encriptar texto
export function encriptar(text: string): string {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
}

// Función para desencriptar texto
export function desencriptar(ciphertext: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}
