<ng-container *ngIf="formPrincipal.get('iPersId').value">
    <div @stagger class="flex items-center justify-between w-full" >
        <img @scaleIn [src]="servidorService.photoThumbURL + formPrincipal.get('iPersId').value + '?ancho=75&alto=75'" alt="Foto" class="avatar flex-grow-0 w-6 h-6" />
        <div @fadeInRight class="flex-grow truncate h-auto">
            <p class="body-2 m-0 leading-snug text-2xs font-bold">{{ formPrincipal.get('cPersNombreLargo').value }}</p>
            <p class="text-secondary text-2xs italic m-0 caption leading-none">{{formPrincipal.get('cTipoIdentSigla').value}} {{formPrincipal.get('cPersDocumento').value}}</p>
        </div>
        <button (click)="formPrincipal.reset(); value = null;" *ngIf="!readonly" color="primary" mat-icon-button type="button" class="flex-grow-0 w-6 h-6">
            <mat-icon svgIcon="mat:delete"></mat-icon>
        </button>
<!--        <div fxLayout="row" fxLayoutAlign="start center">
            <img @scaleIn [src]="dataGeneral.photoThumbURL + formPrincipal.get('iPersId').value + '?ancho=150&alto=150'" alt="Foto" class="avatar ltr:mr-3 rtl:ml-3" fxFlex="none"/>
            <div @fadeInRight fxFlex="auto">
                <h4 class="body-2 m-0 leading-snug">{{ formPrincipal.get('cPersNombreLargo').value }}</h4>
                <h5 class="text-secondary m-0 caption leading-none">{{formPrincipal.get('cTipoIdentSigla').value}} {{formPrincipal.get('cPersDocumento').value}}</h5>
            </div>
            <button (click)="formPrincipal.reset()" color="primary" mat-icon-button type="button">
                <mat-icon svgIcon="twotoneDelete"></mat-icon>
            </button>
        </div>-->
    </div>
</ng-container>

<ng-container [formGroup]="formPrincipal">
    <div class="flex items-center justify-between w-full" [class.hidden]="formPrincipal.get('iPersId').value">
        <!-- <mat-select *ngIf="seleccionarTipo" class="select" formControlName="tipoPersona" [ngStyle]="{ opacity: disabled ? 0.5 : 1 }" [disabled]="disabled">
             <mat-option value="">Todos</mat-option>
             <mat-option value="trabajadores">Trabajadores</mat-option>
             <mat-option value="individuos">Individuos</mat-option>
             <mat-option value="prestadores">Prestadores</mat-option>
             <mat-option value="proveedores">Proveedores</mat-option>
         </mat-select>
         <mat-divider *ngIf="seleccionarTipo" [vertical]="true" class="divider"></mat-divider>-->
        <input matInput type="text" formControlName="txtBuscar" [placeholder]="shouldLabelFloat ? placeholder : ''"
               class="flex-1"
               [hidden]="formPrincipal.get('iPersId').value"
               (keydown.enter)="buscarPersona(); $event.preventDefault()"
               [ngStyle]="{ opacity: disabled ? 0.5 : 1 }" autocomplete="off" name="buscar"
               [ngClass]="{ 'form-input border-2 mt-1 w-full text-2xs font-thin bg-white text-black': sinContenedor}"
        >
        <button matRipple type="button" (click)="abrirDialogRegistro()"
            class="boton-circular-gris"
            *ngIf="registro && !formPrincipal.get('iPersId').value"
        >
            <mat-icon svgIcon="mat:person_add" class="icon-xs"></mat-icon>
        </button>
        <button matRipple type="button" (click)="buscarPersona()"
                class="boton-circular-gris"
                *ngIf="!formPrincipal.get('iPersId').value"
        >
            <mat-icon svgIcon="mat:search" class="icon-xs"></mat-icon>
        </button>
    </div>
</ng-container>



<ng-template #dialogResultados>
    <div globalDialogFlotante btnCerrar [matDialogRefActual]="dialogRefSeleccion">
        <ng-container titulo>
            Resultados de Búsqueda
        </ng-container>
        <div cuerpo fxLayout="column">
            <div class="mt-1" fxLayout="column" fxLayoutGap="2px">
                <div @stagger fxLayout="column" fxLayoutGap="4px">
                    <ng-container *ngFor="let info of (dataServidorSuscripcion['grl.personas'] | async); let i = index">
                        <div fxLayout="row" fxLayoutAlign="start center"
                             class="cursor-pointer"
                             *ngIf="!idxTipoIdentidadNoPermitidos.includes(info.iTipoIdentId)"
                             (click)="seleccionarPersona(info);"
                        >
                            <img @scaleIn [src]="servidorService.photoThumbURL + info.iPersId + '?ancho=150&alto=150'" alt="Foto" class="avatar ltr:mr-3 rtl:ml-3" fxFlex="none"/>
                            <div @fadeInRight fxFlex="auto">
                                <h4 class="body-2 m-0 leading-snug">{{ info.cPersNombreLargo }}</h4>
                                <h5 class="text-secondary m-0 caption leading-none">{{info.cTipoIdentSigla}} {{info.cPersDocumento}}</h5>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div @stagger fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px" class="text-center" *ngIf="(dataServidorSuscripcion['grl.personas'] | async).length < 1">
                    NO SE ENCONTRARON RESULTADOS
                </div>
            </div>
        </div>
    </div>
</ng-template>
