<!--<pre>{{ opcionesNuevas | json }}</pre>-->
<!--<pre>{{ formularioFiltro | json }}</pre>-->
<!--<pre>{{ filtroResultante | json }}</pre>-->
<!--<pre>{{ filtrados | json }}</pre>-->
<!--<pre> {{ this.escribirFiltroResultante() | json }} </pre>-->

<div *ngIf="this.cargaCompleta" [ngClass]="containerClass" class="filtroBusqueda w-full">
    <div class="border-none p-0 flex flex-col">
        <div *ngIf="opcionesNuevas.length == 1" class="flex-1 flex items-end max-sm:flex-col flex-wrap gap-2">
            <ng-container *ngFor="let campoFiltro of opcionesNuevas[0].campos; let i = index">
                <ng-container [ngTemplateOutletContext]="{controlData: campoFiltro}" [ngTemplateOutlet]="controlesFormularioNuevo"></ng-container>
            </ng-container>
            <ng-container [ngTemplateOutlet]="botonFiltrar"></ng-container>
        </div>

        <div *ngIf="opcionesNuevas.length > 1" class="flex-1 flex flex-col flex-wrap gap-2 border">
            <mat-tab-group [(selectedIndex)]="formularioFiltro['opcion']" class="flex-1 flex flex-wrap" dynamicHeight mat-align-tabs="start">
                <mat-tab *ngFor="let item of opcionesNuevas; let indexOfelement=index;"
                    [aria-label]="(formularioFiltro['opcion'] == indexOfelement) ? 'primary' : ''"
                >
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="formularioFiltro['opcion'] == indexOfelement" class="icon-xs" svgIcon="mat:verified"></mat-icon>&nbsp;
                        {{ item.label }}
                    </ng-template>

                    <div class="mx-2 mt-0 pt-0 flex flex-col items-end sm:flex-row flex-wrap gap-2">
                        <ng-container *ngFor="let campoFiltro of item.campos; let i = index">
                            <ng-container [ngTemplateOutletContext]="{controlData: campoFiltro}" [ngTemplateOutlet]="controlesFormularioNuevo"></ng-container>
                        </ng-container>

                        <ng-container [ngTemplateOutlet]="botonFiltrar"></ng-container>
                    </div>


                </mat-tab>

            </mat-tab-group>
        </div>
    </div>
</div>

<ng-template #botonFiltrar>
    <button (click)="cambiarModelo(); filtrarData()" *ngIf="mostrarBotonFiltro"
        class="flex items-center justify-center bg-primary text-primary-contrast font-bold uppercase text-xs px-2 py-1 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        matRipple type="button"
    >
        <mat-icon class="icon-xs" svgIcon="mat:filter_alt"></mat-icon>&nbsp;
        <span>Filtrar</span>
    </button>
</ng-template>


<ng-template #controlesFormularioNuevo let-controlData="controlData">
    <ng-container [ngSwitch]="controlData.control">
        <ng-container *ngSwitchCase="'select'" [ngTemplateOutletContext]="{template: inputSelect}" [ngTemplateOutlet]="contenedorInput"></ng-container>
        <ng-container *ngSwitchCase="'autocomplete'" [ngTemplateOutletContext]="{template: inputAutocomplete}" [ngTemplateOutlet]="contenedorInput"></ng-container>
        <ng-container *ngSwitchCase="'date'" [ngTemplateOutletContext]="{template: inputDate}" [ngTemplateOutlet]="contenedorInput"></ng-container>
        <ng-container *ngSwitchCase="'date_range'" [ngTemplateOutletContext]="{template: inputDateRange}" [ngTemplateOutlet]="contenedorInput"></ng-container>
        <ng-container *ngFor="let control of ['text', 'number']">
            <ng-container *ngSwitchCase="control" [ngTemplateOutletContext]="{template: inputTextNumber}" [ngTemplateOutlet]="contenedorInput"></ng-container>
        </ng-container>
    </ng-container>


    <ng-template #contenedorInput let-template="template">
        <fieldset [class.flex-auto]="!controlData.cssClass" [class]="controlData.cssClass" class="appearance-none contenedorCampo relative">
            <legend>
                <label [for]="controlData.campo">{{ controlData.label }}</label>
            </legend>
            <ng-container [ngTemplateOutletContext]="{controlData: controlData}" [ngTemplateOutlet]="template"></ng-container>
        </fieldset>
    </ng-template>
</ng-template>

<ng-template #inputSelect let-controlData="controlData">
    <select (ngModelChange)="cambiarSeleccionNuevo(controlData); cambiarModelo();"
        *ngIf="controlData?.select; let controlSelect"
        [(ngModel)]="formularioFiltro[controlData.campo]"
        [disabled]="controlData.disabled" [id]="controlData.campo"
        [ngModelOptions]="{standalone: true}"
    >
        <option *ngIf="controlSelect?.textoCuandoNulo" value="">{{ controlSelect?.textoCuandoNulo }}</option>
        <ng-container *ngFor="let itemSelect of dataServidorSuscripcion[controlSelect.data] | async">
            <option *ngIf="controlSelect.optionInnerHtml; else sinInnerHTML" [class]="controlSelect?.cssClass?.option" [innerHTML]="controlSelect.optionInnerHtml(itemSelect)"
                [value]="campoValorEval(controlData, itemSelect)"
            ></option>
            <ng-template #sinInnerHTML>
                <option [class]="controlSelect?.cssClass?.option" [value]="campoValorEval(controlData, itemSelect)">
                    {{ campoTextEval(controlData, itemSelect) }}
                </option>
            </ng-template>
        </ng-container>
    </select>
</ng-template>
<ng-template #inputAutocomplete let-controlData="controlData">
    <ng-container *ngIf="controlData?.autocomplete; let controlAutocomplete">
        <div class="flex items-center formcontrol-container">
            <ng-container *ngIf="controlAutocomplete.controlType == 'textarea'; else controlAutocompleteInput">
                <textarea (ngModelChange)="cambiarNuevo(controlData, $event);"
                    [(ngModel)]="formularioFiltro[controlData.campo]"
                    [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}"
                    autocomplete="off" cdkAutosizeMaxRows="2"
                    cdkAutosizeMinRows="1" cdkTextareaAutosize matInput
                    placeholder="{{controlData.label}}" style="font-size: 80%">
                </textarea>
            </ng-container>
            <ng-template #controlAutocompleteInput>
                <input (ngModelChange)="cambiarNuevo(controlData, $event);"
                    [(ngModel)]="formularioFiltro[controlData.campo]"
                    [disabled]="controlData.disabled" [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}"
                    autocomplete="off" matInput
                    placeholder="{{controlData.label}}"
                >
            </ng-template>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="cambiarSeleccionNuevo(controlData);  cambiarModelo();" [autoActiveFirstOption]="true"
                [class]=controlAutocomplete.class
                [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: (controlAutocomplete.campoValor ?? controlData.campo), campoValue: controlAutocomplete.camposText, lista: (dataServidorSuscripcion[controlAutocomplete.data] | async)})"
                [panelWidth]="'auto'"
            >
                <mat-option *ngIf="isLoading" class="is-loading">Cargando...</mat-option>
                <mat-option *ngIf="controlAutocomplete?.textoCuandoNulo" value="">{{ controlAutocomplete?.textoCuandoNulo }}</mat-option>
                <ng-container *ngFor="let itemSelect of filtrados[controlData.campo]">
                    <mat-option *ngIf="controlAutocomplete.optionInnerHtml; else sinInnerHTML" [class]="controlAutocomplete?.cssClass?.option" [innerHTML]="controlAutocomplete.optionInnerHtml(itemSelect)"
                        [value]="campoValorEval(controlData, itemSelect)"
                    ></mat-option>
                    <ng-template #sinInnerHTML>
                        <mat-option [class]="controlAutocomplete?.cssClass?.option" [value]="campoValorEval(controlData, itemSelect)"
                        >{{ campoTextEval(controlData, itemSelect) }}
                        </mat-option>
                    </ng-template>
                </ng-container>
            </mat-autocomplete>

            <div class="flex items-center justify-start" matSuffix>
                <button (click)="formularioFiltro[controlData.campo] = ''; cambiarNuevo(controlData, ''); cambiarModelo(); $event.preventDefault();" class="boton-circular-gris"
                    matRipple
                    type="button"
                >
                    <mat-icon [svgIcon]="(formularioFiltro[controlData.campo] ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
                </button>
            </div>
        </div>
    </ng-container>

</ng-template>
<ng-template #inputTextNumber let-controlData="controlData">
    <input (keyup.enter)="$event.stopPropagation(); $event.preventDefault(); (controlData.keyUpEnter ? filtrarData() : false);"
        (ngModelChange)="cambiarModelo()"
        [(ngModel)]="formularioFiltro[controlData.campo]" [disabled]="controlData.disabled" [ngModelOptions]="{standalone: true}"
        [type]="controlData.control"
        autocomplete="off"
        placeholder="{{controlData.label}}"
    >
</ng-template>
<ng-template #inputDate let-controlData="controlData">
    <div class="flex items-center date-container">
        <input (keyup.enter)="$event.stopPropagation(); $event.preventDefault(); (controlData.keyUpEnter ? filtrarData() : false);"
            (ngModelChange)="cambiarModelo()"
            (dateChange)="cambiarSeleccionNuevo(controlData)"
            [(ngModel)]="formularioFiltro[controlData.campo]"
            [disabled]="controlData.disabled" [matDatepicker]="i" [ngModelOptions]="{standalone: true}"
            autocomplete="off"
            placeholder="{{controlData.label}}"
            type="text"
        >
        <mat-datepicker-toggle [for]="i" class="icon-xs" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #i></mat-datepicker>
    </div>
</ng-template>
<ng-template #inputDateRange let-controlData="controlData">
    <div class="flex items-center daterange-container">
        <mat-date-range-input [disabled]="controlData.disabled" [rangePicker]="i">
            <input (ngModelChange)="cambiarModelo()"
                [(ngModel)]="formularioFiltro[controlData.dateRange.campo[0]]" [ngModelOptions]="{standalone: true}" matStartDate
                placeholder="Fecha Inicial"
            >
            <input (ngModelChange)="cambiarSeleccionNuevo(controlData); cambiarModelo()"
                [(ngModel)]="formularioFiltro[controlData.dateRange.campo[1]]" [ngModelOptions]="{standalone: true}" matEndDate
                placeholder="Fecha Final"
            >
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="i" class="icon-xs"></mat-datepicker-toggle>
        <mat-date-range-picker #i></mat-date-range-picker>
    </div>
</ng-template>
<ng-template #controlesFormulario let-controlData="controlData">
    <ng-container [ngSwitch]="controlData.control">

        <mat-radio-group (ngModelChange)="cambiarModelo()" *ngSwitchCase="'option'" [(ngModel)]="formularioFiltro[controlData.campo]"
            [class.flex-initial]="!controlData.class"
            [class]="controlData.class" [disabled]="controlData.disabled" [ngModelOptions]="{standalone: true}"
            class="relative"
        >
            <mat-radio-button *ngFor="let itemSelect of controlData.data"
                [value]="(itemSelect[controlData.campoId ? controlData.campoId : controlData.campo])"
                class="mr-2"
            >{{ itemSelect[controlData.text] }}
            </mat-radio-button>
        </mat-radio-group>

        <mat-checkbox *ngSwitchCase="'checkbox'" [(ngModel)]="formularioFiltro[controlData.campo]" [class.flex-initial]="!controlData.class"
            [class]="controlData.class" [disabled]="controlData.disabled" [ngModelOptions]="{standalone: true}"
            class="relative"
        >
            {{ controlData.label }}
        </mat-checkbox>
    </ng-container>
</ng-template>
