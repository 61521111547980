import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedService {

    _dataRutaActual = new BehaviorSubject<ActivatedRoute>(null);
    dataRutaActual$ = this._dataRutaActual.asObservable();
    constructor(private route: ActivatedRoute, private router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this._dataRutaActual.next(this.getRouteData(this.route.root));
                // console.log(routeData); // Imprime los datos de la ruta actual en la consola
            });
    }

    getRouteData(route: ActivatedRoute): any {
        if (route.firstChild) {
            return this.getRouteData(route.firstChild);
        } else {
            return route.snapshot.data;
        }
    }
}
