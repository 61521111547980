<form globalDialogFlotante btnCerrar btnGuardar
    (ngSubmit)="opcMenu({item: undefined ,seccion: 'personas', tipo: 'guardar' })" [formGroup]="frmRegistro"
>
    <ng-container titulo>
        {{ (seleccionados.personas && seleccionados.personas.iPersId ? 'Editar' : 'Nuevo') }} ::<small>Persona</small>
    </ng-container>
<!--    <ng-container selectTitulo *ngIf="frmRegistro.get('iDespachoId').value">
        <div class="flex items-center justify-start text-xs">
            <mat-slide-toggle formControlName="iDespachoEstado"></mat-slide-toggle>
        </div>
    </ng-container>-->

    <div cuerpo class="w-full flex flex-col items-center sm:gap-1 text-xs">
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Tipo de Persona</mat-label>
                <mat-select formControlName="iTipoPersId">
                    <mat-option [value]="item.iTipoPersId" *ngFor="let item of dataServidor.grl.tipos_personas">
                        <span class="text-sm">{{ item.cTipoPersNombre }}</span>
                    </mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoPersId')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1" *ngIf="frmRegistro.get('iTipoPersId').value">
                <mat-label>Tipo de Identificacion</mat-label>
                <mat-select formControlName="iTipoIdentId">
                    <mat-option [value]="item.iTipoIdentId" *ngFor="let item of dataServidor.grl.tipos_personas_identificaciones">
                        <span class="text-sm">{{ item.cTipoIdentNombre }}</span>
                    </mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoIdentId')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1" *ngIf="frmRegistro.get('iTipoIdentId').value">
                <mat-label>Nº Documento</mat-label>
                <input formControlName="cPersDocumento" matInput autocomplete="off" [placeholder]="'Número de ' + (dataServidor.grl.tipos_personas_identificaciones | dataEnLista: 'iTipoIdentId': frmRegistro.get('iTipoIdentId').value)?.cTipoIdentSigla">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersDocumento')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2" *ngIf="frmRegistro.get('iTipoPersId').value == 1">
            <mat-form-field class="flex-1">
                <mat-label>Apellido Paterno</mat-label>
                <input formControlName="cPersPaterno" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersPaterno')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Apellido Materno</mat-label>
                <input formControlName="cPersMaterno" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersMaterno')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Nombres</mat-label>
                <input formControlName="cPersNombre" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersNombre')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2" *ngIf="frmRegistro.get('iTipoPersId').value == 1">
            <mat-form-field class="vex-flex-form-field">
                <mat-label>Sexo</mat-label>
                <mat-select formControlName="cPersSexo">
                    <mat-option [value]="item.value" *ngFor="let item of [{ value: 'M' }, { value: 'F' }]">{{ item.value }}</mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersSexo')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Fecha de Nacimiento</mat-label>
                <input matInput [matDatepicker]="dPersNacimiento" formControlName="dPersNacimiento" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="dPersNacimiento"></mat-datepicker-toggle>
                <mat-datepicker #dPersNacimiento disabled="false"></mat-datepicker>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('dPersNacimiento')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Estado Civil</mat-label>
                <mat-select formControlName="iTipoEstCivId">
                    <mat-option [value]="item.iTipoEstCivId" *ngFor="let item of dataServidor.grl.tipos_estados_civiles">{{ item.cTipoEstCivilNombre }}</mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoEstCivId')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2" *ngIf="frmRegistro.get('iTipoPersId').value == 2">
            <mat-form-field class="flex-1">
                <mat-label>Razon Social</mat-label>
                <input formControlName="cPersRazonSocialNombre" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersRazonSocialNombre')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Nombre Comercial</mat-label>
                <input formControlName="cPersRazonSocialCorto" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersRazonSocialCorto')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Sigla</mat-label>
                <input formControlName="cPersRazonSocialSigla" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersRazonSocialSigla')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Dirección</mat-label>
                <input formControlName="cPersDomicilio" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPersDomicilio')) }}</mat-error>
            </mat-form-field>
        </div>


<!--        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1" *ngIf="dataServidor.grl.dependencias">
                <mat-label>Dependencia</mat-label>
                <input matInput formControlName="iDepenId" [matAutocomplete]="iDepenId" autocomplete="off">
                <mat-autocomplete #iDepenId="matAutocomplete"
                    panelWidth="auto"
                    [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iDepenId', campoValue: ['cDepenNombre'], lista: dataServidor.grl.dependencias})"
                >
                    <mat-option *ngIf="isLoading" class="is-loading" disabled="">Cargando...</mat-option>
                    <mat-option *ngFor="let item of filtrados['grl.dependencias'] | async" [value]="item.iDepenId">
                        {{ item.cDepenNombre }}
                    </mat-option>
                </mat-autocomplete>
                <div matSuffix class="flex items-center justify-start">
                    <button matRipple type="button"
                        class="boton-circular-gris"
                        (click)="(frmRegistro.get('iDepenId').value ? frmRegistro.patchValue({iDepenId: ''}) : null); $event.preventDefault();"
                    >
                        <mat-icon [svgIcon]="(frmRegistro.get('iDepenId').value ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
                    </button>
                </div>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iDepenId')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Despacho Padre</mat-label>
                <input matInput formControlName="iDespachoDependeId" [matAutocomplete]="iDespachoDependeId" autocomplete="off">
                <mat-autocomplete #iDespachoDependeId="matAutocomplete"
                    panelWidth="auto"
                    [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iDespachoId', campoValue: ['cDespachoNombreLargo'], lista: dataServidor.despachos})"
                >
                    <mat-option value="" class="is-loading">Ninguno</mat-option>
                    <mat-option *ngFor="let item of filtrados['despachos'] | async" [value]="item.iDespachoId">
                        {{ item.cDespachoNombreLargo }}
                    </mat-option>
                </mat-autocomplete>
                <div matSuffix class="flex items-center justify-start">
                    <button matRipple type="button"
                        class="boton-circular-gris"
                        (click)="(frmRegistro.get('iDespachoDependeId').value ? frmRegistro.patchValue({iDespachoDependeId: ''}) : null); $event.preventDefault();"
                    >
                        <mat-icon [svgIcon]="(frmRegistro.get('iDespachoDependeId').value ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
                    </button>
                </div>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iDespachoDependeId')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Establecimiento</mat-label>
                <mat-select formControlName="iEstablecimientoId">
                    <mat-option [value]="item.iEstablecimientoId" *ngFor="let item of dataServidor.grl.establecimientos">
                        <span class="text-sm">{{ item.cEstablecimientoNombreLargo }}</span>
                    </mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iEstablecimientoId')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1" *ngIf="dataServidor.tipos_despachos">
                <mat-label>Tipo Despacho</mat-label>
                <mat-select formControlName="iTipoDespachoId">
                    <mat-option [value]="item.iTipoDespachoId" *ngFor="let item of dataServidor.tipos_despachos">{{ item.cTipoDespachoNombre }}</mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoDespachoId')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Nombre Largo</mat-label>
                <input formControlName="cDespachoNombreLargo" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cDespachoNombreLargo')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Nombre Corto</mat-label>
                <input formControlName="cDespachoNombreCorto" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cDespachoNombreCorto')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Sigla</mat-label>
                <input formControlName="cDespachoSigla" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cDespachoSigla')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Correo Electrónico</mat-label>
                <input type="email" formControlName="cDespachoCorreoCuenta" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cDespachoCorreoCuenta')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <mat-form-field class="flex-1" *ngIf="dataServidor.tipos_tramites">
                <mat-label>Tipo de Trámite</mat-label>
                <mat-select formControlName="iTipoTramId">
                    <mat-option [value]="item.iTipoTramId" *ngFor="let item of dataServidor.tipos_tramites">{{ item.cTipoTramNombre }}</mat-option>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoTramId')) }}</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
                <mat-label>Plazo de Resolución</mat-label>
                <input type="number" formControlName="iDespachoPlazoResolverDias" matInput autocomplete="off">
                <span matSuffix>&nbsp;día(s)</span>
                <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iDespachoPlazoResolverDias')) }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            <div class="flex-1">
                <mat-checkbox formControlName="bDespachoTrazabilidad">Trazabilidad</mat-checkbox>
            </div>
            <div class="flex-1">
                <mat-checkbox formControlName="bDespachoDerivar">Derivar</mat-checkbox>
            </div>
            <div class="flex-1">
                <mat-checkbox formControlName="bDespachoProveido">Proveido</mat-checkbox>
            </div>
        </div>-->
    </div>
</form>
