import {Component, OnInit} from '@angular/core';
import {QueryService} from '@servicios/query.service';
import {EntidadService} from '@servicios/entidad.service';
import {DataServidor} from '@JVSoft/interfaces/global';
import {BehaviorSubject} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UsuarioService} from '@servicios/usuario.service';
import {SessionService} from '../../../session/services/session.service';

@UntilDestroy()
@Component({
    selector: 'vex-modulos',
    templateUrl: './modulos.component.html',
    styleUrls: ['./modulos.component.scss']
})
export class ModulosComponent implements OnInit {
    dataServidorSuscripcion: DataServidor = {};

    _dataModulos = new BehaviorSubject(null);

    constructor(
        private queryService: QueryService,
        private entidadService: EntidadService,
        private usuarioService: UsuarioService,
        private sesionService: SessionService,
    ) {
    }

    ngOnInit() {
        this.queryService.cargar(this, ['seg.menus_modulos'], {iEntId: this.entidadService.entidadActual?.iEntId}, true).then(dRet => {
            if (dRet && dRet['seg.menus_modulos']) {
                // const dT = generarArbol(dRet['seg.menus_modulos'], 'iMenuId', 'iMenuPadreId');
                this._dataModulos.next(dRet['seg.menus_modulos']);
                // console.log(dT);
            }

            console.log(dRet);
        })
    }
    entrarModulo(itm) {
        return 1;
        if (this.usuarioService.dataUsuarioPersona && this.usuarioService._data.getValue()['iEntId'] != this.entidadService.entidadActual?.iEntId){
            console.warn('estaAutenticado');
            this.entidadService.setEntidadActualById(this.entidadService.entidadActual?.iEntId);
        }
    }
}
