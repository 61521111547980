import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  botonesCentralesAccion = {};

  _botonesCentralesAccion = new BehaviorSubject<any>({});
  botonesCentralesAccion$ = this._botonesCentralesAccion.asObservable();



  constructor() { }
}
