import {Injectable} from '@angular/core';
import {formatCurrency, formatDate, formatNumber} from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import {MatDialog} from '@angular/material/dialog';
import {PdfViewerComponent} from '../components/pdf-viewer/pdf-viewer.component';

import pdfFonts from '../assets/js/pdfMake/vfs_fonts';
import {timesNewRoman} from '@JVSoft/assets/js/pdfMake/fonts';

export interface IPdfData {
	titulo?: ITitulo;
	orientacion?: string;
	modal?: any;
	cuerpo?: object[];
}

export interface ITitulo {
	text?: string;
	size?: number;
}

interface ContenidoTablaCeldaPDF {
	label: string | string[];
	data?: string | string[];
	dataTotal?: string | string[];
	totalAutomatico?: string | string[];
	tituloTipo?: 'columnas' | 'cant' | 'mon' | 'fec' | 'fec_hora' | null;
	tituloAlign?: 'center' | 'left' | 'right' | 'justify' | null;
	cuerpoTipo?: 'columnas' | 'texto' | 'cant' | 'mon' | 'fec' | 'fec_hora' | null;
	cuerpoAlign?: 'center' | 'left' | 'right' | 'justify' | null;
	totalTipo?: 'columnas' | 'cant' | 'mon' | 'fec' | 'fec_hora' | null;
	totalAlign?: 'center' | 'left' | 'right' | 'justify' | null;
	lineaAncho?: number;
	caracterUnion?: string;
}

export interface ContenidoTablaFilaPDF {
	rowSpan?: number;
	colSpan?: number;
	filas?: ContenidoTablaCeldaPDF[];
}

export interface OpcionesPdfMake {
	tituloDialog?: string;
	tamanioHoja?: 'A4' | 'A5' | 'A3' | string | {width: number | string, height: number | string};
	margenesHoja?: number | number[];
	orientacion?: 'landscape' | 'portrait';
	marcaDeAgua?: object;
	tamanioFuenteExtra?: number;
	tamanioFuenteExtraFooter?: number;
	customDefTableHeader?: object;

    defaultStyle?: object;

	defaultSizeHeaderTable?: any[];
	defaultMarginHeaderTable?: number[];
	defaultLeftCellHeaderTable?: object;
	defaultCenterCellHeaderTable?: object;
	defaultRightCellHeaderTable?: object;

    sinMarcaEmpresa?: boolean;
	footerTablePrepend?: object;
	footerTableLeftStackPrepen?: any[];
    footerTableLeftStackAppend?: any[];
	footerTableAppend?: object;
	footerUsuario?: string;
	footerDocumento?: string;
	footerEntidad?: string;
	footerEntidadWeb?: string;
}
pdfMake.vfs = {...pdfFonts.pdfMake.vfs, ...timesNewRoman};
pdfMake.fonts = {
	Inconsolata: {
		normal: 'Inconsolata-Regular.ttf',
		bold: 'Inconsolata-Bold.ttf',
		italics: 'Inconsolata-Regular.ttf',
		bolditalics: 'Inconsolata-Bold.ttf'
	},
	Roboto: {
		normal: 'Roboto-Regular.ttf',
		bold: 'Roboto-Medium.ttf',
		italics: 'Roboto-Italic.ttf',
		bolditalics: 'Roboto-MediumItalic.ttf'
	},
    FreePixel: {
		normal: 'FreePixel.ttf',
		bold: 'FreePixel.ttf',
		italics: 'FreePixel.ttf',
		bolditalics: 'FreePixel.ttf',
	},
    TimesNewRoman: {
        normal: 'TimesNewRoman-regular.ttf',
        bold: 'TimesNewRoman-bold.ttf',
        italics: 'TimesNewRoman-italic.ttf',
        bolditalics: 'TimesNewRoman-bloditalic.ttf'
    },
};


@Injectable({
	providedIn: 'root'
})
export class PdfMakeGlobalService {

	constructor(
		public dialog: MatDialog,
	) {
		this.reiniciarOpciones();
	}
	public opcionesPDF: OpcionesPdfMake;
	public opcionesPDFInicial: OpcionesPdfMake = {};

	public imagenes = {};
	public colorSombra = '#d3d7ef';
	public colorSombraTexto = '#151414';

	reiniciarOpciones(opcionesDefault: OpcionesPdfMake = {}) {
		const marcaDeAgua = {text: '', opacity: 0.1};
		const defaultStyle = {fontSize: 8};
		this.opcionesPDF = {
			...{
				tamanioHoja: 'A4',
				margenesHoja: 60,
				orientacion: 'portrait',
				marcaDeAgua,
				tamanioFuenteExtraFooter: 0,
				tamanioFuenteExtra: 0,
				defaultMarginHeaderTable: [5, 5, 5, 0],
				defaultSizeHeaderTable: [110, '*', 110],
				defaultLeftCellHeaderTable: {},
				defaultRightCellHeaderTable: {},
				defaultCenterCellHeaderTable: {
					stack: [
						this.imprimirCelda('REPORTES', null, { fontSize: 14, bold: true }),
						this.imprimirCelda('Subtitulo', null, { fontSize: 13 }),
						this.imprimirCelda('Subtitulo', null, { fontSize: 11, bold: true }),
					]
				},
                defaultStyle: {
                    fontSize: 8,
                },
			},
			...this.opcionesPDFInicial,
			...opcionesDefault,
		};
		if (opcionesDefault.defaultStyle) {
			this.opcionesPDF.defaultStyle = {
                ...defaultStyle,
                ...opcionesDefault.defaultStyle,
			};
		}
		if (opcionesDefault.marcaDeAgua) {
			this.opcionesPDF.marcaDeAgua = {
                ...marcaDeAgua,
                ...opcionesDefault.marcaDeAgua,
			};
		}
		if (opcionesDefault.orientacion == 'landscape') {
			if (!opcionesDefault.defaultSizeHeaderTable) {
				this.opcionesPDF.defaultSizeHeaderTable = [200, '*', 200];
			}
		}
	}

	definicionCompleta(objData: IPdfData) {
		// console.log(pdfMake.fonts);
		objData = {
			...{
				titulo: {
					text: 'REPORTE',
					size: 13,
				},
				orientacion: 'portrait',
				modal: null,
				cuerpo: [],
			},
			...objData
		};
		const cuerpoPDF = [];

		if (objData.titulo.text != 'ninguno') {
			cuerpoPDF.push({
				text: objData.titulo.text || 'Reporte',
				fontSize: objData.titulo.size || 13,
				bold: true,
				alignment: 'center',
			});
		}

		objData.cuerpo.forEach(value => {
			cuerpoPDF.push(value);
		});

		const documentDefinition: object = this.generarDefinicion(cuerpoPDF);
		const actHeader = this.generarHeader();
		// @ts-ignore
		documentDefinition.header = (currentPage, pageCount, pageSize) => {
			const strHeadFinal = JSON.stringify(actHeader).replace('##pagina##', currentPage).replace('##total_paginas##', pageCount);
			const aTmp = JSON.parse(strHeadFinal);
			if (typeof this.opcionesPDF.tamanioHoja == 'object') {
				aTmp.pageSize = this.opcionesPDF.tamanioHoja;
			}
			return JSON.parse(strHeadFinal);
		};
		return documentDefinition;
	}

	generarPDF(documentDefinition, accion: 'download' | 'open' | 'print' | 'otro' | 'modal' = 'open', callback = null, nombreArchivo = 'SIGEUN Reporte.pdf') {
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		switch (accion) {
			case 'modal':
				pdfDocGenerator.getBlob((data) => {
					this.dialog.open(PdfViewerComponent, {
						width: '95vw',
						maxWidth: '98vw',
						height: '98%',
						panelClass: 'dialogMantenimientoNuevo',
						data: {src: URL.createObjectURL(data), titulo: this.opcionesPDF.tituloDialog ?? 'Reporte PDF'}
					});
				});
				return true;
				break;
			case 'otro':
				if (callback) {
					return pdfDocGenerator.getBlob(callback);
				} else {
					throw new Error('exportarArchivo  requiere el tercer argumento como callback (funcion)');
				}
				break;
			case 'download':
				pdfDocGenerator[accion](nombreArchivo);
				break;
			default:
				pdfDocGenerator[accion]();
				break;
		}
	}

	generarHeader(currentPage = null, pageCount = null, pageSize = null) {
		if (this.opcionesPDF.customDefTableHeader) {
			return this.opcionesPDF.customDefTableHeader;
		}
		return [
			{
				margin: this.opcionesPDF.defaultMarginHeaderTable,
				table: {
					widths: this.opcionesPDF.defaultSizeHeaderTable,
					headerRows: 0,
					body: [
						[
							this.opcionesPDF.defaultLeftCellHeaderTable,
							this.opcionesPDF.defaultCenterCellHeaderTable,
							this.opcionesPDF.defaultRightCellHeaderTable,
						],
					]
				},
				layout: {
					defaultBorder: false,
				}
			}
		];
	}

	generarDefinicion(cuerpoPDF) {
		return {
			footer: (pagActual, pagTotal) => {
				const dFoot = [];
				if (this.opcionesPDF.footerTablePrepend) {
					dFoot.push(this.opcionesPDF.footerTablePrepend);
				}
                let celdasFooterLeftStack = [
                    'Reporte impreso por ' + this.opcionesPDF.footerUsuario + ' (' + this.opcionesPDF.footerDocumento + ')',
                    {
                        text: this.opcionesPDF.footerEntidad ?? 'JVSoft (http://jvsoft.pe)',
                        link: this.opcionesPDF.footerEntidadWeb ?? 'http://jvsoft.pe',
                    },
                ];
                if (this.opcionesPDF.footerTableLeftStackPrepen) {
                    celdasFooterLeftStack = this.opcionesPDF.footerTableLeftStackPrepen.concat(celdasFooterLeftStack);
                }
                if (this.opcionesPDF.footerTableLeftStackAppend) {
                    celdasFooterLeftStack = celdasFooterLeftStack.concat(this.opcionesPDF.footerTableLeftStackAppend);
                }
				dFoot.push({
					style: 'table',
					margin: [10, 0, 10, 0],
					table: {
						widths: ['*', '*'],
						headerRows: 0,
						body: [
							[
								{
									border: [false, true, false, false],
									fontSize: 8 + this.opcionesPDF.tamanioFuenteExtraFooter,
									stack: celdasFooterLeftStack
								},
								{
									border: [false, true, false, false],
									// text: pagActual.toString() + '/' + pagTotal.toString(),
									stack: [
										// toTitleCase(this.sesionService.dataCredencial.getValue()?.cPersNombreCompleto) + ' (DNI ' + this.sesionService.dataCredencial.getValue()?.cPersDocumento + ')',
										pagActual.toString() + '/' + pagTotal.toString(),
										' ',
                                        (this.opcionesPDF.sinMarcaEmpresa ? '' : 'JVSoft EIRL - ') + formatDate(new Date(), 'dd/MM/yyy HH:mm', 'en'),
									],
									fontSize: 8 + this.opcionesPDF.tamanioFuenteExtraFooter,
									alignment: 'right'
								},
							],
						]
					},
					layout: {
						defaultBorder: false,
					}
				});
				if (this.opcionesPDF.footerTableAppend) {
					dFoot.push(this.opcionesPDF.footerTableAppend);
				}
				return dFoot ;
			},
			// header: (currentPage, pageCount, pageSize) => {
			// 	// you can apply any logic and return any valid pdfmake element
			//
			// 	return this.generarHeader(currentPage, pageCount, pageSize);
			// },
			content: cuerpoPDF,
			// content: [],
			pageSize: this.opcionesPDF.tamanioHoja,
			pageOrientation: this.opcionesPDF.orientacion,
			pageMargins: this.opcionesPDF.margenesHoja,
			// watermark: {text: this.opcionesPDF.marcaDeAgua, angle: (this.opcionesPDF.orientacion == 'landscape' ? -35 : -55), opacity: 0.1},
			watermark: this.opcionesPDF.marcaDeAgua,
			info: {
				title: (this.opcionesPDF.sinMarcaEmpresa ? 'Reportes' : 'Reportes - JVSoft EIRL - '),
				author: 'Antonio Salas',
				subject: 'Reportes',
				keywords: 'Reportes' + (this.opcionesPDF.sinMarcaEmpresa ? '' : ', JVSoft'),
			},

			defaultStyle: this.opcionesPDF.defaultStyle,

			styles: {
				derecha: {
					alignment: 'right',
				},
				cursiva: {
					italics: true
				},
				centrado: {
					alignment: 'center',
				},
				negrita: {
					bold: true,
				},
				th: {
					fontSize: 8 + this.opcionesPDF.tamanioFuenteExtra,
					fillColor: this.colorSombra,
					color: this.colorSombraTexto,
					alignment: 'center'
				},
				td: {
					fontSize: 8 + this.opcionesPDF.tamanioFuenteExtra,
					color: '#444444',
				},
				thSmall: {
					fontSize: 5 + this.opcionesPDF.tamanioFuenteExtra,
					alignment: 'center',
					bold: true,
					fillColor: this.colorSombra,
					color: this.colorSombraTexto,
				},
				thSmallSinFondo: {
					fontSize: 6 + this.opcionesPDF.tamanioFuenteExtra,
					alignment: 'center',
					bold: true,
				},
				thSmallSinBorde: {
					border: [true, false, true, false],
					fontSize: 6 + this.opcionesPDF.tamanioFuenteExtra,
					alignment: 'center',
					fillColor: this.colorSombra,
					color: this.colorSombraTexto,
				},
				thSmall5: {
					fontSize: 5 + this.opcionesPDF.tamanioFuenteExtra,
					alignment: 'center',
					fillColor: this.colorSombra,
					color: this.colorSombraTexto,
				},
				tdSmall: {
					fontSize: 5 + this.opcionesPDF.tamanioFuenteExtra,
					color: '#282727',
				},
				tdSmallFooter: {
					fontSize: 5 + this.opcionesPDF.tamanioFuenteExtra,
					fillColor: this.colorSombra,
					color: this.colorSombraTexto,
				},
			},
			images: this.imagenes,
		};
	}


	// FUNCIONES USADAS EN TODOS LO HIJOS
	anchoCols(titulos, idxResto = [], idxAnchoValor: {idx: number, valor: number | 'auto'}[] = []) {
		return anchoCols(titulos, idxResto, idxAnchoValor);
	}

	imprimirCelda(data, tipo: 'number' | 'columnas' | 'texto' | 'cant' | 'mon' | 'fec' | 'fec_hora' | 'hora' = 'texto', opcAdicionales = {}) {
		return imprimirCelda(data, tipo, opcAdicionales);
	}

	finalizarCreacionPdf(cuerpoPdf, accion: 'download' | 'open' | 'print' | 'otro' | 'modal' = 'modal', callback = null, nombreArchivo = 'SIGEUN Reporte.pdf') {
		const dataPdf: IPdfData = {
			titulo: {
				text: 'ninguno',
			},
			orientacion: 'landscape',
			cuerpo: cuerpoPdf,
		};
		const definicion = this.definicionCompleta(dataPdf);
		// console.log(JSON.stringify(definicion));
		console.log(definicion);
		this.generarPDF(definicion, accion, callback, nombreArchivo);
	}


	tablaSegunConfig(contenido, dataRegistros, idxResto = [], idxAnchoValor = [], filaTotales = true, separado = false) {
		const estiloDefaultTitulos: any = {
		};
		const secStackV2 = (tipo: 'titulo' | 'cuerpo' | 'totales', filas: ContenidoTablaCeldaPDF[], opcAdicionales = {}) => {
			const valRet = {
				stack: [],
			};
			filas.forEach(fila => {

				let filaIns;

				switch (tipo) {
					case 'titulo':
						if (fila.tituloTipo && fila.tituloTipo == 'columnas') {
							filaIns = {columns: fila.label, alignment: (fila.tituloAlign ? fila.tituloAlign : 'center'), style: 'thSmall'};
							console.log(filaIns);
						} else {
							if (fila.label == '**linea**') {
								filaIns = {
									canvas: [{
										type: 'line',
										x1: 0,
										y1: 0,
										x2: fila.lineaAncho ? fila.lineaAncho : 50,
										y2: 0,
										lineWidth: 1
									}]
								};
							} else if (fila.label == '**cargo**') {
								filaIns = this.imprimirCelda('CARGO', null, { style: 'thSmall' });
							} else if (fila.label == '**afp**') {
								filaIns = this.imprimirCelda('A.F.P. / C.U.S.P.P.', null, { style: 'thSmall' });
							} else {
								// const numberOfLineBreaks = (fila.label.match(/\n/g) || []).length;
								if (fila.label.indexOf('\n') == -1) {
									filaIns = this.imprimirCelda(fila.label, fila.tituloTipo ? fila.tituloTipo : null, {
										style: 'tdSmall',
										noWrap: true,
										alignment: (fila.tituloAlign ? fila.tituloAlign : 'center'),
									});
								} else {
									filaIns = this.imprimirCelda(fila.label, fila.tituloTipo ? fila.tituloTipo : null, {
										style: 'tdSmall',
										alignment: (fila.tituloAlign ? fila.tituloAlign : 'center'),
									});
								}
							}
						}
						break;
					case 'cuerpo':
					case 'totales':
						filaIns = fila;
						/*
						if (fila.cuerpoTipo && fila.cuerpoTipo == 'columnas') {
							filaIns = {columns: fila.dataDialog, alignment: (fila.cuerpoAlign ? fila.cuerpoAlign : 'left'), style: 'tdSmall'};
						} else {
							if (fila.label == '**linea**') {
								// filaIns = {canvas: [ { type: 'line', x1: 0, y1: 0, x2: fila.lineaAncho ? fila.lineaAncho : 50, y2: 0, lineWidth: 1 } ]};
								filaIns = fila;
							} else {
								// filaIns = this.imprimirCelda(fila.dataDialog, fila.cuerpoTipo ? fila.cuerpoTipo : null, (fila.cuerpoAlign ? fila.cuerpoAlign : 'left'));
								filaIns = fila;
							}
						}
						*/
						break;
				}

				valRet.stack.push(filaIns);
			});
			return {...valRet, ...opcAdicionales};
		};


		const filasTitulos = [];
		const filasCuerpo = [];
		const filasTotal = [];
		contenido.forEach((fila: ContenidoTablaFilaPDF[], idxFila) => {
			filasTitulos.push([]);
			filasCuerpo.push([]);
			filasTotal.push([]);

			fila.forEach((celda, idxCelda) => {
				if (celda.filas) {
					const opcAdic = {};
					opcAdic['style'] = ['thSmall'];
					if (celda.rowSpan) {
						opcAdic['rowSpan'] = celda.rowSpan;
					}
					if (celda.colSpan) {
						opcAdic['colSpan'] = celda.colSpan;
					}
					filasTitulos[idxFila].push(secStackV2('titulo', celda.filas, opcAdic));
					if (idxFila > 0) {
						if (filasCuerpo[0][idxCelda].filas) {
							filasCuerpo[0][idxCelda] = secStackV2('cuerpo', filasCuerpo[0][idxCelda].filas.merge(celda.filas));
							filasTotal[0][idxCelda] = secStackV2('totales', filasTotal[0][idxCelda].filas.merge(celda.filas));
						} else {
							filasCuerpo[0][idxCelda] = secStackV2('cuerpo', celda.filas);
							filasTotal[0][idxCelda] = secStackV2('totales', celda.filas);
						}
					} else {
						filasCuerpo[idxFila].push(secStackV2('cuerpo', celda.filas));
						filasTotal[idxFila].push(secStackV2('totales', celda.filas));
					}
				} else {
					filasTitulos[idxFila].push({});
					filasCuerpo[idxFila].push({});
					filasTotal[idxFila].push({});
				}
			});
		});

		// console.log(JSON.stringify(filasTitulos));

		const tituloTabla = JSON.parse(JSON.stringify(filasTitulos));
		const cuerpoTabla = [];
		const totalesTabla = [];

		const cuerpoPdf = [];

		let cuerpoPlanilla = [];

		cuerpoPlanilla = (filasTitulos);

		const sColumnasTotales = {};
		dataRegistros.filter((val, idx) => {
			const cuerpoDt = (valC, idxC, confCuerpo) => {
				const dPrestadorAdd = [];
				confCuerpo.forEach((celdaCuerpo, idxCeldaCuerpo) => {
					celdaCuerpo['stack'].forEach((filaCelda: ContenidoTablaCeldaPDF, idxFilaCelda) => {
						let dImpCelda;

						if (!sColumnasTotales[idxCeldaCuerpo]) {
							sColumnasTotales[idxCeldaCuerpo] = 0;
						}

						// @ts-ignore
						if (filaCelda.totalAutomatico) {
							// @ts-ignore
							sColumnasTotales[idxCeldaCuerpo] = (sColumnasTotales[idxCeldaCuerpo] + (valC[filaCelda.data] * 1));
						}

						if (filaCelda.data == '#') {
							dImpCelda = this.imprimirCelda((idxC + 1), 'cant', {style: 'tdSmall', alignment: 'center'});
						} else if (filaCelda['caracterUnion']) {
							const dMerge = [];

							if (filaCelda.data && typeof filaCelda.data !== 'string') {
								filaCelda.data.forEach(vA => {
									if (typeof vA == 'string' && vA.slice(0, 2) == '->') {
										dMerge.push(vA.replace('->', ''));
									} else if (typeof vA == 'object') {
										const dTipo = this.imprimirCelda(valC[vA!['dataDialog']], (vA!['cuerpoTipo'] ? vA!['cuerpoTipo'] : null));
										dMerge.push(dTipo['text']);
									} else {
										if (valC[vA]) {
											const dTipo = this.imprimirCelda(valC[vA], (filaCelda.cuerpoTipo ? filaCelda.cuerpoTipo : null));
											dMerge.push(dTipo['text']);
										}
									}
								});
							}

							dImpCelda = this.imprimirCelda(dMerge.join(filaCelda.data['caracterUnion']), null, { style: 'tdSmall', alignment: filaCelda.cuerpoAlign ? filaCelda.cuerpoAlign : 'left'});
						} else if (Array.isArray(filaCelda.data)) {
							const colConten = [];
							// @ts-ignore
							filaCelda.data.forEach((datCol: ContenidoTablaCeldaPDF) => {
								// @ts-ignore
								colConten.push({text: this.imprimirCelda((datCol.data ? valC[datCol.data] : ''), (datCol.cuerpoTipo ? datCol.cuerpoTipo : 'mon'), datCol.cuerpoAlign ? { alignment: datCol.cuerpoAlign } : { alignment: 'right' }  )});
							});
							dImpCelda = {columns: colConten};
						} else {
							if (filaCelda.label == '') {
								dImpCelda = this.imprimirCelda('', null);
							} else if (filaCelda.label == '**linea**') {
								dImpCelda = {
									canvas: [{
										type: 'line',
										x1: 0,
										y1: 0,
										x2: filaCelda.lineaAncho ? filaCelda.lineaAncho : 50,
										y2: 0,
										lineWidth: 0.3,
										lineColor: '#E8E8E8'
									}]
								};
							} else if (filaCelda.label == '**afp**') {
								let dRetD;
								dRetD = [valC.cRegimPensionNombre, valC.cPlanillaPrestadorCUSPP, (valC.nAFECTO_COMISION_MIXTA * 1 > 0 ? 'M' : '')].join(' / ');
								dImpCelda = this.imprimirCelda(dRetD);
							} else if (filaCelda.label == '**cargo**') {
								let dRetD = '';

								if (valC['iTipoPlanillaId'] == 7) {
									dRetD = [valC.cCondLabNombre, valC.cCategNombre, valC.cTipoDedicaNombre].join(' / ');
								} else if (valC['iTipoPlanillaId'] == 3) {
									dRetD = [valC.cCondLabNombre, valC.cCargNombre, valC.cCategNombre].join(' / ');
								} else {
									dRetD = valC.cCargNombre;
								}

								dImpCelda = this.imprimirCelda(dRetD, null, {alignment: 'center'});
							} else {
								if (filaCelda.data && filaCelda.data['caracterUnion']) {
									const dMerge = [];

									if (filaCelda.data['campos']) {
										filaCelda.data['campos'].forEach(vA => {
											dMerge.push(valC[vA]);
										});
									}

									dImpCelda = this.imprimirCelda(dMerge.join(filaCelda.data['caracterUnion']), null, {style: 'thSmall'});
								} else if (filaCelda.data && filaCelda.data.slice(0, 2) == '->') {
									// @ts-ignore
									dImpCelda = this.imprimirCelda(filaCelda.data.replace('->', ''));
								} else {
									// @ts-ignore
									dImpCelda = this.imprimirCelda((filaCelda.data ? valC[filaCelda.data] : ''), (filaCelda.cuerpoTipo ? filaCelda.cuerpoTipo : 'mon'), {alignment: filaCelda.cuerpoAlign ? filaCelda.cuerpoAlign : 'right'});
								}
							}
						}
						celdaCuerpo['stack'][idxFilaCelda] = dImpCelda;
					});

					dPrestadorAdd.push(celdaCuerpo);
				});
				return dPrestadorAdd;
			};
			cuerpoPlanilla.push(cuerpoDt(val, idx, JSON.parse(JSON.stringify(filasCuerpo[0]))));
		});

		console.log(sColumnasTotales);

		const dTotalAdd = [];
		filasTotal[0].forEach((celdaTotal, idxCeldaTotal) => {
			celdaTotal['stack'].forEach((filaCelda: ContenidoTablaCeldaPDF, idxFilaCelda) => {
				let dImpCelda;
				if (filaCelda.label == '') {
					dImpCelda = this.imprimirCelda('', null);
				} else {
					if (filaCelda.dataTotal) {
						// @ts-ignore
						dImpCelda = this.imprimirCelda((filaCelda.dataTotal ? dataGeneral[filaCelda.dataTotal] : ''), (filaCelda.cuerpoTipo ? filaCelda.cuerpoTipo : 'mon'), filaCelda.cuerpoAlign ? filaCelda.cuerpoAlign : 'right');
					} else if (filaCelda.totalAutomatico) {
						// @ts-ignore
						dImpCelda = this.imprimirCelda(sColumnasTotales[idxCeldaTotal], (filaCelda.cuerpoTipo ? filaCelda.cuerpoTipo : 'mon'), filaCelda.cuerpoAlign ? filaCelda.cuerpoAlign : 'right');
					} else {
						dImpCelda = this.imprimirCelda('', null);
					}

				}
				celdaTotal['stack'][idxFilaCelda] = dImpCelda;
				celdaTotal['style'] = ['tdSmallFooter'];
			});
			dTotalAdd.push(celdaTotal);


		});
		if (filaTotales) {
			totalesTabla.push( JSON.parse(JSON.stringify(dTotalAdd)) );
			cuerpoPlanilla.push(dTotalAdd);
		}

		const tablaFinal = {
			table: {
				headerRows: contenido.length,
				dontBreakRows: true,
				widths: this.anchoCols(cuerpoPlanilla, idxResto, idxAnchoValor),
				body: cuerpoPlanilla
			},
			layout: {
				paddingLeft: (i, node) => 1,
				paddingRight: (i, node) => 1,
				paddingTop: (i, node) => 1,
				paddingBottom: (i, node) => 1
			}
		};

		if (separado) {
			return {
				tituloTabla,
				cuerpoTabla,
				totalesTabla,

				tablaFinal,
			};
		}

		cuerpoPdf.push(tablaFinal);
		// console.log(JSON.stringify(cuerpoPdf));
		return cuerpoPdf;
	}

	getBase64ImageFromURL(url) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			// img.setAttribute('crossOrigin', 'anonymous');
			img.crossOrigin = 'anonymous';
			img.src = url + '?no-cache=1';
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);
				const dataURL = canvas.toDataURL('image/png');
				resolve(dataURL);
			};
			img.onerror = error => {
				console.log(error);
				reject(error);
			};
		});
	}

	async getBase64ImageFromUrl2(imageUrl) {
		const res = await fetch(imageUrl);
		const blob = await res.blob();

		if (blob.type == 'image/svg+xml') {
			return new Promise((resolve, reject) => {
				blob.text().then(val => resolve(val));
			});
		}

		console.log(blob.type);
		blob.text().then(val => console.log(val));
		console.log(blob.text());

		return new Promise((resolve, reject) => {
			const reader  = new FileReader();
			reader.addEventListener('load', () => {
				resolve(reader.result);
			}, false);

			reader.onerror = () => {
				return reject(this);
			};
			reader.readAsDataURL(blob);
		});
	}

    mmToPoints(mm) {
        const MILLIMETERS_IN_INCH = 25.4;
        const POINTS_IN_INCH = 72;

        const inches = mm / MILLIMETERS_IN_INCH;
        return inches * POINTS_IN_INCH;
    }
}

export function imprimirCelda(data, tipo: 'number' | 'columnas' | 'texto' | 'cant' | 'money' | 'mon' | 'fec' | 'fec_hora' | 'hora' = 'texto', opcAdicionales = {}) {
	let texto = data;
	switch (tipo) {
		case 'number':
			texto = data * 1;
			break;
		case 'cant':
            let fCantidad = '1.0-0';
            if (opcAdicionales && opcAdicionales['format']) {
                fCantidad = '1.2-2';
            }
			texto = formatNumber(data, 'es-PE', fCantidad);
			break;
		case 'money':
			texto = formatCurrency(data, 'es-PE', 'S/');
			break;
		case 'mon':
			texto = formatNumber(data, 'es-PE', '1.2-2');
			break;
		/*
	case 'columnas':
		texto = formatNumber(dataDialog, 'es-PE', '1.2-2');
		break;
		*/
		case 'fec':
			if (!opcAdicionales['alignment']) {
				opcAdicionales['alignment'] = 'center';
			}
			if (Array.isArray(data)) {
				if (data[1]) {
					texto = data[0] + formatDate(data[1], 'dd/MM/yyyy', 'es-PE');
				} else {
					texto = data[0];
				}
			} else {
				if (data) {
					texto = formatDate(data, 'dd/MM/yyyy', 'es-PE');
				}
			}
			break;
		case 'fec_hora':
			if (Array.isArray(data)) {
				if (data[1]) {
					texto = data[0] + formatDate(data[1], 'dd/MM/yyyy HH:mm', 'es-PE');
				} else {
					texto = data[0];
				}
			} else {
				if (data) {
					texto = formatDate(data, 'dd/MM/yyyy HH:mm', 'es-PE');
				}
			}
			break;
		case 'hora':
			if (!opcAdicionales['alignment']) {
				opcAdicionales['alignment'] = 'center';
			}
			if (Array.isArray(data)) {
				if (data[1]) {
					texto = data[0] + formatDate(data[1], 'HH:mm:ss', 'es-PE');
				} else {
					texto = data[0];
				}
			} else {
				if (data) {
					texto = formatDate(data, 'HH:mm:ss', 'es-PE');
				}
			}
			break;
	}
	opcAdicionales['text'] = texto ? texto : ' ';
	return opcAdicionales;
}

export function generarColSpan(data: any, colSpan: number, extraCols: any[] = []): any[] {
    const arrayData = Array(colSpan).fill({});
    arrayData[0] = { ...data, colSpan };
    if (extraCols.length) {
        arrayData.push(...extraCols);
    }
    return arrayData;
}

export function anchoCols(dataCant: any[] | number, idxResto: number[] | 'todos' = [], idxAnchoValor: { idx: number | 'todos', valor: number | 'auto' }[] = []): ('auto' | '*' | number)[] {
	const retorno: ('auto' | '*' | number)[] = [];

	if (Array.isArray(dataCant)) {
		if (dataCant.length === 0) return [];
		dataCant[0].forEach((_, idx) => {
			retorno.push(Array.isArray(idxResto) && idxResto.includes(idx) ? '*' : 'auto');
		});
	} else {
		for (let idx = 0; idx < dataCant; idx++) {
			if (Array.isArray(idxResto)){
				retorno.push(idxResto.includes(idx) ? '*' : 'auto');
			}
			else {
				retorno.push('*');
			}
		}
	}



	idxAnchoValor.forEach(({ idx, valor }) => {
		if (idx == 'todos'){
			retorno.fill(valor);
		}
		else {
			retorno[idx] = valor;
		}
	});

	return retorno;
}

