import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from './toolbar.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {ToolbarUserModule} from './toolbar-user/toolbar-user.module';
import {ToolbarSearchModule} from './toolbar-search/toolbar-search.module';
import {NavigationModule} from '../navigation/navigation.module';
import {RouterModule} from '@angular/router';
import {ToolbarNotificationsModule} from "@vexMod/layout/toolbar/toolbar-notifications/toolbar-notifications.module";
import {NavigationItemModule} from "@vexMod/components/navigation-item/navigation-item.module";

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    ToolbarNotificationsModule,
    ToolbarUserModule,
    ToolbarSearchModule,

    NavigationModule,
    RouterModule,
    NavigationItemModule,
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
