<vex-page-layout mode="card">
    <vex-page-layout-header class="mb-10 flex h-14 items-center justify-between pb-8">
        <div class="flex-auto">
            <h1 class="m-0 title" #hearBreadcrumb></h1>
            <!--			<vex-breadcrumbs [crumbs]="(breadCrumbService.breadCrumb$ | async)?.reverse()"></vex-breadcrumbs>-->
            <vex-mod-breadcrumbs (ultimoElemento)="hearBreadcrumb.innerText = $event"></vex-mod-breadcrumbs>
        </div>
        <ng-container #headerTemplateCentroDinamico ></ng-container>

        <div class="flex-none flex items-end justify-between h-full mr-1" *ngIf="cargaCompletaBotones">
            <button matRipple *ngFor="let boton of (botonesCentro$ | async)" [class]="boton.cssClass" class=""
            >
                <mat-icon *ngIf="boton.icon" class="icon-xs mr-1" [svgIcon]="boton.icon" ></mat-icon>
                <mat-checkbox color="primary" [(ngModel)]="principalService.botonesCentralesAccion[boton.modeloChk]" [ngModelOptions]="{standalone: true}" *ngIf="boton.modeloChk else noEsChk"
                    (ngModelChange)="principalService._botonesCentralesAccion.next(principalService.botonesCentralesAccion)"
                >
                    <span [class]="boton.cssLabel">{{ boton.text }}</span>
                </mat-checkbox>
                <ng-template #noEsChk>
                    <span [class]="boton.cssLabel">{{ boton.text }}</span>
                </ng-template>
            </button>

        </div>
        <div class="flex-auto flex items-end justify-between h-full" *ngIf="cargaCompleta">
            <jvs-filtro-busqueda
                nombreColeccion="filtro_principal"
                class="w-full grow flex flex-row"
                [objThis]="this"
                prefijoConsulta="grl"
                [mostrarBotonFiltro]="false"
                [opciones]="[{params: (parametros$ | async)}]"
                (dataFiltro)="cambioDataFiltroPorParametros($event)"
            ></jvs-filtro-busqueda>
        </div>

    </vex-page-layout-header>



	<vex-page-layout-content class="-mt-6 px-2 fullwidth">
        <ng-container #headerTemplateDinamico ></ng-container>

        <div  class="card mb-4 overflow-auto mat-elevation-z8 px-0">
            <router-outlet></router-outlet>
        </div>


	</vex-page-layout-content>


</vex-page-layout>

