import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {trackByValue} from '@vex/utils/track-by';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'vex-mod-breadcrumbs',
  template: `
    <div class="flex items-center" [ngClass]="cssClase" *ngIf="!soloUltimo else templateSoloUltimo">
      <vex-mod-breadcrumb>
        <a [routerLink]="['/']">
          <mat-icon svgIcon="mat:home" class="icon-sm"></mat-icon>
        </a>
      </vex-mod-breadcrumb>
      <ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
        <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
        <vex-mod-breadcrumb>
          <a [routerLink]="[]">{{ crumb }}</a>
        </vex-mod-breadcrumb>
      </ng-container>
    </div>
    <ng-template #templateSoloUltimo>
      <h2 class="" [ngClass]="cssClase" *ngFor="let crumb of crumbs; trackBy: trackByValue">{{ crumb }}</h2>
    </ng-template>
  `
})
export class BreadcrumbsComponent implements OnInit {
  _soloUltimo: boolean;
  get soloUltimo(): boolean { return this._soloUltimo; }
  @Input() set soloUltimo(val: boolean | '') { this._soloUltimo = val || true; }
  @Input() crumbs: string[] = [];
  @Input() cssClase: string = '';
  @Output() ultimoElemento = new EventEmitter<any>();

  trackByValue = trackByValue;

  terminado = false;

  crumbsPorRouter = [];

  private _tituloGenerado = new BehaviorSubject('');
  tituloGenerado$ = this._tituloGenerado.asObservable();

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute
  ) {
    this.router.navigateByUrl(this.router.url, { onSameUrlNavigation: 'reload' }).then();
    if (this.crumbs.length == 0) {
      let nTitulo;
      this.router.events.subscribe(event => {
        if (this.terminado) {
          this.crumbsPorRouter = [];
        }
        if (event instanceof ActivationEnd) {
          if (event.snapshot.routeConfig.path != '') {
            nTitulo = event.snapshot.routeConfig.data?.titulo || event.snapshot.routeConfig.path;
            nTitulo = nTitulo.replace('-', ' ');
            nTitulo = nTitulo.charAt(0).toUpperCase() + nTitulo.slice(1);
            this.crumbsPorRouter = this.crumbsPorRouter.concat([nTitulo.toString()]);
            if (!this._tituloGenerado.getValue()) {
              this._tituloGenerado.next(nTitulo.toString());
            }

          }
        }
        this.terminado = (event instanceof NavigationEnd);
        if (this.terminado) {
          this.ultimoElemento.next(this.crumbsPorRouter[0]);
          this.crumbs = this.crumbsPorRouter.reverse();
        }
      });
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnInit() {
    if (this.soloUltimo) {
      this.crumbs = this.crumbsPorRouter.reverse();
      this.ultimoElemento.next(this.crumbsPorRouter[0]);
      this.crumbs = [this.crumbs[0]];
      return 1;
    }
    if (this.crumbs.length == 0) {
      this.ultimoElemento.next(this.crumbsPorRouter[0]);
      this.crumbs = this.crumbsPorRouter.reverse();
    }
    else {
      this.ultimoElemento.next(this.crumbs[ this.crumbs.length - 1 ]);
    }
  }
}
