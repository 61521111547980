import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FuncionesGeneralesService} from '../../../../general/services/funciones-generales.service';
import {QueryService} from '@servicios/query.service';
import {OpcionSeleccionada} from '@JVSoft/interfaces/global';
import {cambiosChips, changeSelect, mensajeAlerta, mensajesErrorFormControl, mostrarValorEnBusqueda, objectPropertiesBoolean} from '@JVSoft/services/funciones-globales.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DespachoService} from '@servicios/despacho.service';

@UntilDestroy()
@Component({
  selector: 'vex-tramite-destinatarios-formulario',
  templateUrl: './tramite-destinatarios-formulario.component.html',
  styleUrls: ['./tramite-destinatarios-formulario.component.scss'],
})
export class TramiteDestinatariosFormularioComponent implements OnInit {
	cambiosChips = cambiosChips;
	mostrarValorEnBusqueda = mostrarValorEnBusqueda;
	mensajesErrorFormControl = mensajesErrorFormControl;
	readonly separatorKeysCodes = [ENTER, COMMA] as const;

	dataServidor = {
		tipos_prioridades: null,
		despachosparaenviar: null,
		tipos_personas: null,
		tipos_personas_identificaciones: null,
        grl: {
            personas_contactos: null,
        },
        tram: {
            correo_despachos: null,
        }
	};
	seleccionados = {
	};

	filtrados = {};

	dTipoPers;
	motivosTramiteAdicinal = [
		{id: 1, label: 'Para Enviar'},
		{id: 2, label: 'Para Responder'},
		{id: 3, label: 'Para Proveido'},
		{id: 4, label: 'Para Derivar'},
		{id: 5, label: 'Para Enviar y Archivar'},
	];
	mode: 'create' | 'update' | 'view' | 'derivar' = 'create';
	modo: 'enviar' | 'responder' | 'proveido' | 'derivar'  | 'derivar_masivo' | 'enviar_archivar' = 'enviar';
	frmRegistro: FormGroup;
	onSubmitReason = new EventEmitter();

	get editarAdmin() {
		if (this.datosDialogRecibidos && this.datosDialogRecibidos['editarAdmin']) {
			return true;
		}
		return false;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any = {formAccion: 'destinatarios', seccion: 'tramites_notificaciones'},
		public funcionesGeneralesService: FuncionesGeneralesService,
		public despachoService: DespachoService,
		private queryService: QueryService,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<TramiteDestinatariosFormularioComponent>,
		private fb: FormBuilder,
	) {
	}

	ngOnInit() {
		this.crearFormularios();
		console.warn(this.datosDialogRecibidos);




		this.frmRegistro.get('iTipoPersId').valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
			console.warn('T Pers ', val);
			if (!this.frmRegistro.get('iPersReceptorId').value) {
				this.frmRegistro.get('iTipoIdentId').reset();
			}
			this.dTipoPers = null;
			this.queryService.cargar(this, ['tipos_personas_identificaciones'], {
				iTipoPersId: val,
			}).then(() => {
				if (this.frmRegistro.get('iTipoIdentId').value) {
					this.frmRegistro.get('iTipoIdentId').setValue(this.frmRegistro.get('iTipoIdentId').value);
				}
				if (this.dataServidor.tipos_personas_identificaciones && this.dataServidor.tipos_personas_identificaciones.length == 1) {
					this.frmRegistro.get('iTipoIdentId').setValue(this.dataServidor.tipos_personas_identificaciones[0].iTipoIdentId);
				}
			});
		});
		this.frmRegistro.get('iTipoIdentId').valueChanges.pipe(untilDestroyed(this)).subscribe(nVal => {
			this.dTipoPers = dataEnLista(this.dataServidor.tipos_personas_identificaciones, 'iTipoIdentId', nVal);
			console.warn(this.dTipoPers);
			if (this.dTipoPers && !this.frmRegistro.get('iPersReceptorId').value) {
				this.frmRegistro.get('iPersReceptorId').reset();
			}
		});
		this.frmRegistro.get('enviarCorreo').valueChanges.pipe(untilDestroyed(this)).subscribe(nVal => {
			if (nVal) {
                this.frmRegistro.get('email').setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]);
                this.queryService.cargar(this, ['grl.personas_contactos', 'tram.correo_despachos'], {iPersId: this.frmRegistro.get('iPersReceptorId').value}).then(dContactos => {
                    if (dContactos['tram.correo_despachos'] && dContactos['tram.correo_despachos'][0] && dContactos['tram.correo_despachos'][0]['cDespachoCorreoCuenta']) {
                        const dMostar = dContactos['grl.personas_contactos'].find(item => (item['iTipoConId'] == 3 && item['bPersConPrincipal'] == 1));
                        if (dMostar) {
                            this.frmRegistro.get('iPersConCorreoElectronicoId').setValue(dMostar['iPersConId']);
                            this.frmRegistro.get('email').setValue(dMostar['cPersConNombre'], { emitEvent: false });
                        }
                    }
                    else {
                        mensajeAlerta('error', 'Correo no configurado', 'No se ha configurado un correo para el despacho actual, por favor póngase en contacto con el administrador de sistema').then(() => {
                            this.frmRegistro.get('enviarCorreo').setValue(false, {emmitEvent: false});
                        });
                    }
                });
            }
            else {
                this.frmRegistro.get('email').setValidators([]);
                this.frmRegistro.get('iPersConCorreoElectronicoId').reset();
                this.frmRegistro.get('email').reset();
            }
            this.frmRegistro.controls['email'].updateValueAndValidity();
		});
		this.frmRegistro.get('email').valueChanges.pipe(untilDestroyed(this)).subscribe(nVal => {
			if (nVal && this.frmRegistro.get('iPersConCorreoElectronicoId').value) {
                this.frmRegistro.get('iPersConCorreoElectronicoId').reset();
            }
            else {
                if (this.dataServidor.grl.personas_contactos) {
                    const dMostar = this.dataServidor.grl.personas_contactos.find(item => (item['cPersConNombre'] == nVal));
                    if (dMostar) {
                        this.frmRegistro.get('iPersConCorreoElectronicoId').setValue(dMostar['iPersConId']);
                    }
                }
            }
		});

		if (this.datosDialogRecibidos && this.datosDialogRecibidos.tramite) {
			// this.parcharFrmRegistro(this.datosDialogRecibidos.tramite);
			objectPropertiesBoolean(this.datosDialogRecibidos.tramite, 'boolean');
			this.frmRegistro.patchValue({
				...this.datosDialogRecibidos.tramite,
				...{
				}
			});
            if (this.datosDialogRecibidos.tramite.cTramMovAdjuntoFisico) {
                this.frmRegistro.get('cTramMovAdjuntoFisico').setValue(
                    (Array.isArray(this.datosDialogRecibidos.tramite.cTramMovAdjuntoFisico) ? (this.datosDialogRecibidos.tramite?.cTramMovAdjuntoFisico) : JSON.parse(this.datosDialogRecibidos.tramite.cTramMovAdjuntoFisico ?? '[]'))
                );
            }
			if (this.frmRegistro.get('iTramMovId').value) {
				this.mode = 'update';
				this.frmRegistro.get('envio_externo').setValue(0); // al actualizar casi siempre es  interno
			}
			if (this.frmRegistro.get('iTramMovOrigenId').value || this.esDerivar() || this.esDerivacion()) {
				this.mode = 'derivar';
				this.frmRegistro.get('envio_externo').setValue(0); // al actualizar casi siempre es  interno
			}
			if (this.datosDialogRecibidos.tramite.iPersReceptorId){
				this.frmRegistro.get('iTipoPersId').setValue(this.datosDialogRecibidos.tramite.iTipoPersId);
				this.frmRegistro.get('iTipoIdentId').setValue(this.datosDialogRecibidos.tramite.iTipoIdentId);
				// this.frmRegistro.get('xxxx').setValue(this.datosDialogRecibidos.tramite.iPersReceptorId);
			}
		}
		if (this.datosDialogRecibidos && this.datosDialogRecibidos.motivoEnvio) {
			this.modo = this.datosDialogRecibidos.motivoEnvio;
		}
		if (this.datosDialogRecibidos && this.datosDialogRecibidos.tramitesMasivos) {
			this.modo = this.datosDialogRecibidos.motivoEnvio;
		}
		if (this.datosDialogRecibidos && this.datosDialogRecibidos.accionCrearTram) {
			this.modo = this.datosDialogRecibidos.accionCrearTram;
		}

		// if (this.datosDialogRecibidos) {
		// 	if (this.datosDialogRecibidos.destinatario) {
		// 		this.parcharFrmRegistro(this.datosDialogRecibidos.compra);
		// 		if (this.datosDialogRecibidos.compra.iCompraId) {
		// 			this.mode = (!this.datosDialogRecibidos.compra.cPersProveedorDocumento) ? 'update' : 'view';
		// 			this.queryService.cargar(this, ['acc.tributos'], { iCompraId: this.datosDialogRecibidos.compra.iCompraId }).then();
		// 			this.cambiarFormaPago();
		// 		}
		// 	}
		// 	if (this.datosDialogRecibidos.credenciales_compras) {
		// 		this.frmRegistro.patchValue(this.datosDialogRecibidos.compra);
		// 		if (this.datosDialogRecibidos.compra.iCompraId) {
		// 			this.mode = 'update';
		// 		}
		// 	}
		// } else {
		// 	this.datosDialogRecibidos = {};
		// }
		changeSelect(this, this.frmRegistro.get('iDespachoReceptorId'), 'despachosparaenviar', ['cDespachoNombreCorto', 'cDespachoNombreLargo']);
		this.cargaInicial();
		this.frmRegistro.get('envio_externo').valueChanges.subscribe(val => {
			if (val == 1) {
				this.frmRegistro.get('iMotTramMovId').setValue(5);
			} else {
				this.frmRegistro.get('iMotTramMovId').setValue(1);
			}
		});
		this.frmRegistro.get('bTramMovCopia').valueChanges.subscribe(val => {
			this.cargarDespachos();
		});
		this.frmRegistro.get('iDespachoReceptorId').valueChanges.subscribe(val => {
			if (val) {
				const existeEnLista = dataEnLista(this.dataServidor.despachosparaenviar, 'iDespachoId', val);
				if (existeEnLista) {
					this.frmRegistro.get('cDespachoReceptopNombre').setValue( existeEnLista.cDespachoNombreLargo.trim() );
				}
			}
		});
		this.frmRegistro.get('iMotTramMovId').valueChanges.subscribe(val => {
			if (val == 4) {
				// FALTA CONFIRMAR
				this.frmRegistro.get('iTramMovOrigenId').setValue(this.frmRegistro.get('iTramMovId').value);
			} else {
				this.frmRegistro.get('iTramMovOrigenId').reset();
			}
		});

	}

	isCreateMode() {return this.mode === 'create' || this.esDerivacion(); }
	isUpdateMode() {return this.mode === 'update' && !this.esDerivacion() }
	isViewMode() {return this.mode === 'view'; }
	esDerivacion() {return this.mode === 'derivar' || this.esDerivar(); }

	// esVer() { return this.motivoEnvio === 'ver'; }
	esEnviar() { return this.modo === 'enviar'; }
	esResponder() { return this.modo === 'responder'; }
	esProveido() { return this.modo === 'proveido'; }
	esDerivar() { return this.modo === 'derivar'; }
	esDerivarMasivo() { return this.modo === 'derivar_masivo'; }

	esEnvioExterno() {return this.frmRegistro.get('envio_externo').value == 1; }
	esEnvioMultiple() {return this.datosDialogRecibidos && this.datosDialogRecibidos.esEnvioMultiple; }

	crearFormularios() {
		this.frmRegistro = this.fb.group({
			envio_externo: [0],

			iTipoPersId: [''],
			iTipoIdentId: [''],
			iPersReceptorId: [''],
			cPersReceptorNombre: [''],

			iDespachoReceptorId: [''],
			cDespachoReceptopNombre: [''],

			cTramMovObsEnvio: [''], // E
			iTramMovPlazoResolverDias: [''], // E
			iTipoPrioId: [''], // E
			bTramMovCopia: [''], // E
			iTramMovFolios: [''], // E
			cTramMovAdjuntoFisico: [''], // E

			iMotTramMovId: [''],
			iTramMovOrigenId: [''],


            accion: [''], // Extra
            enviarCorreo: [false], // Extra
            iPersConCorreoElectronicoId: [''], // Extra
            email: ['', Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')], // Extra

			iTramMovId: [''],
		});
	}

	cargaInicial() {
		const solicitudes = [
			'tipos_prioridades',
			'tipos_personas',
		];
		this.queryService.cargar(this, solicitudes).then();
		this.cargarDespachos();
	}

	cargarDespachos() {
		console.warn(this.datosDialogRecibidos.tramite);
		const solicitudes = [
			'despachosparaenviar',
		];
		this.queryService.cargar(this, solicitudes, {
			iDespachoId: this.despachoService._despachoActual.getValue().iDespachoId,

			iTipoTramId: this.datosDialogRecibidos.tramite.iTipoTramId,
			iConcepId: this.datosDialogRecibidos.tramite.iConcepId,
			iTipoDocId: (this.esDerivacion() ? '' : this.datosDialogRecibidos.tramite.iTipoDocId),
			// iConcepId: this.frmRegistro.get('iConcepId').value,
			bTramMovCopia: this.frmRegistro.get('bTramMovCopia').value ? 1 : 0,
		}).then();
	}

	cambiarTipoEnvio(valor) {
		this.frmRegistro.get('envio_externo').setValue(valor);
		this.frmRegistro.get('enviarCorreo').reset();
		if (this.esEnvioExterno()) {
			this.frmRegistro.get('cTramMovObsEnvio').setValidators([Validators.required]);
		}
		else {
			this.frmRegistro.get('cTramMovObsEnvio').setValidators([]);
		}
		this.frmRegistro.get('cTramMovObsEnvio').updateValueAndValidity();
	}

	cambiarTipoPersona() {
		let ret = true;
		if (this.esEnvioExterno()) {
			this.queryService.cargar(this, [
				'tipos_personas_identificaciones',
			], {
				iTipoPersId: this.frmRegistro.get('iTipoPersId').value,
			}).then();
		}
		else {
			ret = false;
		}
		return ret;

	}

	opcMenu(v: OpcionSeleccionada) {
		if (this.frmRegistro.invalid) {
			return false;
		}
		if (this.esDerivacion()) {
			this.frmRegistro.get('accion').setValue('nuevo');
			// this.frmRegistro.get('iTramMovId').reset();
		}
		v.datosExtra = {
			frmRegistro: this.frmRegistro,
			dialog: this.dialogRef,
		};
		console.log(v);
		this.onSubmitReason.emit(v);
	}

	eliminarItemReceptores(value) {
		console.log(value);
		const nItems = this.frmRegistro.get('iDespachoReceptorId').value.filter((ele) => {
			console.log('e', ele);
			return ele != value;
		});
		this.frmRegistro.get('iDespachoReceptorId').setValue(nItems);
	}
}
