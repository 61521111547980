import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Notificacion, NotificacionesService} from '../general/services/notificaciones.service';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {TramiteSeguimientoComponent} from '@tramite/tramite-partials/tramite-seguimiento/tramite-seguimiento.component';
import {MatDialog} from '@angular/material/dialog';
import {DespachoService} from '@servicios/despacho.service';
import {formatDate} from '@angular/common';
import {mensajeConfirmacion} from '@JVSoft/services/funciones-globales.service';
import {PERFILES_USUARIO, UsuarioService} from '@servicios/usuario.service';
import {QueryService} from '@servicios/query.service';

@Injectable()
export class NotificacionesInterceptor implements HttpInterceptor {

	solicitandoNotificacion = false;

	constructor(
		public despachoService: DespachoService,
		public notificacionesService: NotificacionesService,
		private usuarioService: UsuarioService,
		private queryService: QueryService,
	) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.solicitandoNotificacion && (req.method == 'GET') && req.url.includes('/tram/') && !req.url.includes('tramites_notificaciones')) {
			req = req.clone({
				url: req.url + '&tramites_notificaciones',
			});
            if (this.despachoService._despachoActual.getValue()) {
                req = this.agregarParametro(req, this.despachoService._despachoActual.getValue().iDespachoId, 'iDespachoNotificadoId');
            }
			this.solicitandoNotificacion = true;
		}
		if (!this.solicitandoNotificacion && (req.method == 'GET')) {
			if (req.url.includes('log.compras_que_venceran')){
				this.solicitandoNotificacion = true;
			}
		}

		/*
		 const httpRequest = new HttpRequest(<any>req.method,'abcd.api.com/search');
		 req = Object.assign(req, httpRequest);
		 if(req.url == 'https://abcd.azure.net/api/v1/getPendinList') {
		 // return Observable.empty();
		 console.log('hello')
		 }
		 */

		let dupReq = req.clone({
			// headers: req.headers.set('Consumer-Secret', 'some sample key')
		});

		// dupReq = this.agregarParametro(dupReq, 'indice', 'random_key_added');
		dupReq = this.cambiarFormatosFecha(dupReq);

		return next.handle(dupReq).pipe(
			tap(evt => {
				if (evt instanceof HttpResponse) {
					// if (evt.url.indexOf('recursos-humanos/asistencia') !== -1 && evt.body) {
					//
					// 	// console.log(evt.body);
					// 	if (evt.body.Cantidad_papeletas_PorAutorizar) {
					// 		this.dataService.mensajes.papeletas.porAprobar.next(evt.body.Cantidad_papeletas_PorAutorizar);
					// 		this.dataService.setOption('badges', {
					// 			papeletas_por_aprobar: evt.body.Cantidad_papeletas_PorAutorizar.jefe_inmediato + evt.body.Cantidad_papeletas_PorAutorizar.jefe_rrhh
					// 		});
					// 	}
					// 	if (evt.body.cantidad_papeletas_sinretorno) {
					// 		this.dataService.mensajes.papeletas.sinRetorno.next(evt.body.cantidad_papeletas_sinretorno);
					// 	}
					// }
					if (evt.url.indexOf('/tram/') !== -1 && evt.body && this.solicitandoNotificacion) {
						if (evt.body.tramites_notificaciones) {
							const nNotif: any[] = evt.body.tramites_notificaciones;
							const dOtro: Notificacion[] = [];
							nNotif.forEach(notif => {

								dOtro.push({
									id: notif.cTramDocumentoTramite,
									titulo: notif.cTramDocumentoTramite,
									subTitulo: 'Notifica: ' + notif.cDespachoNotificaNombreLargo,
									icono: 'twotoneInbox',
									classIcono: 'text-amber',
									fecha: notif.dtTramNotificaFechaHoraNotificado,
									descripcion: notif.cTramNotificaAsunto,
									datosExtra: { tramites_notificaciones: notif },
									// link: '/tramite/por-recibir'
									onClick(notificacion: any, dialog: MatDialog) {
										dialog.open(TramiteSeguimientoComponent, {
											panelClass: 'dialogMantenimientoNuevo',
											data: {
												tramite: notificacion.datosExtra.tramites_notificaciones,
											}
										});
									},
								});
								// if (! this.notificacionesService._notificaciones.getValue().find(item => item.id == notif.cTramDocumentoTramite)) {
								// }



							});
							// this.notificacionesService._notificaciones.next(this.notificacionesService._notificaciones.getValue().concat(dOtro));
							this.notificacionesService._notificaciones.next(dOtro);
							// this.notificacionesService.
						}
						this.solicitandoNotificacion = false;
					}
					if (req.url.includes('log.compras_que_venceran') && evt.body && this.solicitandoNotificacion) {
						if (evt.body['log.compras_que_venceran']) {
							const nNotif: any[] = evt.body['log.compras_que_venceran'];
							const dOtro: Notificacion[] = [];
							nNotif.forEach(notif => {

								dOtro.push({
									id: notif.iCompraId,
									titulo: [notif.cTipoDocSigla, notif.iCompraNumero, notif.iCompraYear].join(' '),
									subTitulo: 'Proveedor: ' + notif.cPersProveedorNombreLargo,
									icono: 'twotoneInbox',
									classIcono: 'text-amber',
									fecha: notif.dtCompraNotificacion,
									descripcion: '',
									datosExtra: { 'data': notif },
									innerHTML(notificacion): string {
										const datNotif = notificacion.datosExtra['data'];
										const strFNotif = formatDate(datNotif.dtCompraNotificacion, 'dd/MM/yyyy HH:mm', 'es-PE')
										const strFVenc = formatDate(datNotif.dCompraVencimiento, 'dd/MM/yyyy', 'es-PE')
										return `Fecha Notif: ${ strFNotif } <br> Fecha Venc: ${ strFVenc } <br> Vencimiento en: ${datNotif.iCampraVencimientoEnDias} días`;
									},
									onClick: (notificacion: any, dialog: MatDialog) => {
										const perfilesPermitidos = [PERFILES_USUARIO.ALMACENERO];

										if (!(this.usuarioService.verificarPerfil(perfilesPermitidos) || this.usuarioService.esEquipoJVSoft())) {
											return 1;
										}
										const datNotif = notificacion.datosExtra['data'];
										mensajeConfirmacion('warning', 'Dar Conformidad', '¿Desea Dar Conformidad a la ' + [notif.cTipoDocSigla, notif.iCompraNumero, notif.iCompraYear].join(' ') + '?', {
											input: 'textarea',
											inputPlaceholder: 'Escriba el motivo de la conformidad. (opcional)',
											confirmButtonText: 'Dar Conformidad',
										}).then(dMsg => {
											if (dMsg.isConfirmed) {
												console.log('Ha confirmado');
												this.queryService.guardarDatosAsync('UPD_conformidad_compras', {
													iCompraId: datNotif.iCompraId,
													cCompraConformidadMotivo: dMsg.value,
												}, 'log').then((dataGuardado) => {

												});
											}
										});
										/*
										Por vencer en dias: iCampraVencimientoEnDias
										 */

										/*
										dialog.open(TramiteSeguimientoComponent, {
											panelClass: 'dialogMantenimientoNuevo',
											data: {
												tramite: notificacion.datosExtra.tramites_notificaciones,
											}
										});
										*/
									},
								});

							});
							// this.notificacionesService._notificaciones.next(this.notificacionesService._notificaciones.getValue().concat(dOtro));
							this.notificacionesService._notificaciones.next(dOtro);
							// this.notificacionesService.
						}
						this.solicitandoNotificacion = false;
					}
				}
			})
		);
	}

	agregarParametro(req: HttpRequest<any>, tokenToAdd, tokenName) {
		if (req.method.toLowerCase() === 'post') {
			if (req.body instanceof FormData) {
				req = req.clone({
					body: req.body.append(tokenName, tokenToAdd)
				});
			}
			else {
				const foo = {};
				foo[tokenName] = tokenToAdd;
				req = req.clone({
					body: {...req.body, ...foo}
				});
			}
		}
		if (req.method.toLowerCase() === 'get') {
			req = req.clone({
				params: req.params.set(tokenName, tokenToAdd)
			});
		}
		return req;
	}

	cambiarFormatosFecha(req: HttpRequest<any>, tokenToAdd = null, tokenName = null) {
		if (req.method.toLowerCase() === 'post') {
			if (req.body instanceof FormData) {
				req = req.clone({
					body: req.body.append(tokenName, tokenToAdd)
				});
			}
			else {
				const foo = {};
				foo[tokenName] = tokenToAdd;
				req = req.clone({
					body: {...req.body, ...foo}
				});
			}
		}
		if (req.method.toLowerCase() === 'get') {
			let target: HttpParams = new HttpParams();
			req.params.keys().forEach((id) => {
				let addVal = req.params.get(id);
				// @ts-ignore
				if (addVal instanceof Date) {
					addVal = moment(addVal).toISOString();
				}
				target = target.append(id, addVal);
			});
			req = req.clone({
				params: target,
			});
		}
		return req;
	}
}
