import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {delLocalStorage, setJwtTokenData} from '@JVSoft/functions/local-storage';
import {lastValueFrom} from 'rxjs';
import {ConfiguracionWebService} from '@servicios/configuracion-web.service';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	baseUrl;
	constructor(
		public http: HttpClient,
		private router: Router,
		private configuracionWebService: ConfiguracionWebService,
	) {
		this.baseUrl = this.configuracionWebService.CONST_BACKEND_API;
		// this.baseUrl = environment.backendApi;
	}

	login(data = {cCredUsuario: '', password: ''}) {
		return lastValueFrom(this.http.post(`${this.baseUrl}/auth/login`, data));
	}

	isAuthenticated() {
		return this.http.get(`${this.baseUrl}/auth/user`);
	}

	logout() {
		return this.http.post(`${this.baseUrl}/auth/logout`, {}).toPromise().then(r => {
            delLocalStorage();
			this.router.navigate(['/sesion/login']);
		});
	}

	refreshToken(){
		return this.http.post(`${this.baseUrl}/auth/refresh`, {}).pipe(map(
			dataUser => {
                setJwtTokenData(JSON.stringify(dataUser));
				// localStorage.setItem('JVSoftTkn', JSON.stringify(dataUser));
			}
		));
	}

    tiempoExpiraSesion() {
        return this.http.get(`${this.baseUrl}/auth/_e_t`);
    }

	solicitarLinkRecuperacion(data = {}){
		return this.http.get(`${this.baseUrl}/seg/anonimo/dataexistente/credenciales`, {params: data});
	}


	solicitarCambioContrasena(data) {
		return this.http.post(`${this.baseUrl}/seg/anonimo/guardar/CambiarClave_credenciales`, data);
	}

}
