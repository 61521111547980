import {Component, OnInit} from '@angular/core';
import {NavigationServiceMod} from "@vexMod/services/navigation.service";

@Component({
  selector: 'vex-mod-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items = this.navigationService.items;

  constructor(private navigationService: NavigationServiceMod) { }

  ngOnInit() {
  }
}
