import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DateTime} from 'luxon';
import {MatDialog} from '@angular/material/dialog';


export interface Notificacion {
	id: string;
	titulo: string;
	subTitulo?: string;
	icono?: string;
	classIcono?: string | string[];
	descripcion?: string;
	fecha?: string | DateTime;
	leido?: boolean;
	link?: any;
	datosExtra?: any;
	onClick?(notificacion: any, dialog: MatDialog): void;
	innerHTML?(notificacion): string;
}

export interface Notificaciones {
	modulo: string;
	items: Notificacion[];
}
@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
	notificacionesActivas = [];
	_notificaciones = new BehaviorSubject<Notificacion[]>([]);
	notificaciones$ = this._notificaciones.asObservable();

	constructor(
	) {
		// this.addNotificacion({
		// 	id: 'algo',
		// 	titulo: 'ESTOY Vivito',
		// 	// descripcion: 'Algo que me gustaria poner como contenido de lo que sea',
		// 	icono: 'roundSearch',
		// 	// fecha: new Date('2010-02-10 08:30:00.133').toISOString(),
		// 	fecha: '2010-02-10 08:30:00',
		// 	link: 'www.google.com.pe'
		// });
	}
	addNotificacion(notificacion: Notificacion) {
		this.notificacionesActivas.push(notificacion);
		this._notificaciones.next(this.notificacionesActivas);
		// const a = new DateTime();
		// this._notificaciones.next( this._notificaciones.getValue().concat([notificacion]) );
	}
}
