import {Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ProgressSpinnerComponent} from '@JVSoft/components/progress-spinner/progress-spinner.component';
import {BehaviorSubject} from 'rxjs';

export interface ProgressSpinnerConfig {
    fondoStyle?: string;
    mensaje?: string;
    icono?: string;
}
@Injectable({
    providedIn: 'root'
})
export class ProgressSpinnerService {
    private overlayRef: OverlayRef = this.createOverlay();
    _mensaje = new BehaviorSubject('');
    mensaje$ = this._mensaje.asObservable();
    _config = new BehaviorSubject({});
    config$ = this._config.asObservable();

    overlayActivo = false;


    get configActual(): ProgressSpinnerConfig {
        return this._config.getValue();
    }
    set configActual(data: ProgressSpinnerConfig) {
        this._config.next(data);
    }

    constructor(private overlay: Overlay) { }

    private createOverlay(): OverlayRef {
        return this.overlay.create({
            hasBackdrop: true,
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
        });
    }

    show(config?: ProgressSpinnerConfig): void {
        this.configActual = config;
        if (!this.overlayActivo){
            this.overlayRef.attach(new ComponentPortal(ProgressSpinnerComponent));
            this.overlayActivo = true;
        }
    }

    hide(config?: ProgressSpinnerConfig, timeout: number = 1): void {
        this.configActual = config;
        setTimeout(() => {
            this.overlayRef.detach();
            this.overlayActivo = false;
        }, timeout * 1000); // Ajusta según sea necesario

    }
}
