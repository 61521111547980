import {zeroFill} from '@JVSoft/pipes/zero-fill.pipe';
import {PdfAlmacenService} from '@pdf/pdf-almacen/index';
import {imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {groupBy, isNumberRound, operacionesHoras, ordenarPorPropiedad, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {formatDate} from '@angular/common';


export function vale(objThis: PdfAlmacenService, dataRec) {
    objThis.queryService.cargar(objThis, ['log.vales_detalles_presentacion'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        const dataRecibido: any[] = valRetorno['log.vales_detalles_presentacion'];
        const cuerpoPdf = [];

        const dataGeneral = dataRecibido[0];


        objThis.reiniciarOpciones({
            margenesHoja: [0, 0, 0, 0],
            tamanioFuenteExtra: 3,
        });

        /*cuerpoPdf.push({
            canvas: [
                {
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 495,
                    h: 60,
                    r: 5,
                    lineColor: 'black',
                    // color: this.colorSombra,
                },
            ],
        });*/
        cuerpoPdf.push(
            {
                canvas: [
                    {type: "line", x1: 0, y1: 0, x2: 0, y2: 842, dash: {length: 5, space: 10}},
                ],
                absolutePosition: {x: 0, y: 0},
                alignment: 'center',
            },
            {
                canvas: [
                    {type: "line", x1: 0, y1: 421, x2: 594, y2: 421, dash: {length: 5, space: 10}},
                ],
                absolutePosition: {x: 0, y: 0},
                alignment: 'center',
            },
            /*{
                canvas: [

                    {type: "line", x1: 0, y1: 421, x2: 594, y2: 421},
                ],
                absolutePosition: {x: 0, y: 0},
                alignment: 'center',
            },*/
        );

        const tablaHeader = {
            // margin: [0, 0, 0, 0],
            table: {
                widths: ['*', 170],
                body: [
                    [
                        {
                            ...objThis.logotipoHeader(dataGeneral),
                            ...{alignment: 'left'},
                        },
                        // {
                        //     ...imprimirCelda('VALE \nCOMBUSTIBLE', 'texto', {alignment: 'center', fontSize: 10}),
                        // },
                        {
                            table: {
                                widths: ['*','*','*'],
                                body: [
                                    [
                                        imprimirCelda('Nº VALE', null, {alignment: 'center', style: 'thSmall', fontSize: 8}),
                                        imprimirCelda('Nº PEDIDO', null, {alignment: 'center', style: 'thSmall', fontSize: 8}),
                                        imprimirCelda('FECHA', null, {alignment: 'center', style: 'thSmall', fontSize: 8}),
                                    ],
                                    [
                                        imprimirCelda(zeroFill(dataGeneral['iValeNumero'],4), null, {
                                            alignment: 'center', style: 'tdSmall', fontSize: 8
                                        }),
                                        imprimirCelda(dataGeneral['iPedidoNumero'] ? zeroFill(dataGeneral['iPedidoNumero'],4): '-', null, {
                                            alignment: 'center', style: 'tdSmall', fontSize: 8
                                        }),
                                        imprimirCelda(dataGeneral['dValeEmision'], 'fec', {
                                            alignment: 'center', style: 'tdSmall', fontSize: 8
                                        }),
                                    ],
                                ]
                            }
                        }
                    ],
                ],
            },
            layout: 'noBorders',
        };

        let datosVehiculo = []

        if(dataGeneral['bMotValeUsoEnAlmacen'] == 1){
            // datosVehiculo = []
            datosVehiculo = [
                imprimirCelda('', null, {bold: true, style: 'tdSmall', border: [true,false,true,true], colSpan:3}),
            ]
        } else {
            datosVehiculo = [
                imprimirCelda('Vehículo', null, {bold: true, style: 'tdSmall', border: [true,false,false,true]}),
                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                imprimirCelda(dataGeneral['cVehiculoPlacaClase'] + '   Kilometraje : '+ (dataGeneral['nPedidoKilometraje'] ? isNumberRound(dataGeneral['nPedidoKilometraje'],0) : ''), null, {
                    style: 'tdSmall', border: [false,false,true,true],
                }),
            ]
        }

        const tablaDatosPrincipales = {
            table: {
                widths: ['auto', 'auto', '*'],
                body: [
                    [
                        imprimirCelda('VALE COMBUSTIBLE', null, {colSpan: 3, alignment: 'center', style: 'thSmall', fontSize:15}),
                        '',
                        '',
                    ],
                    [
                        imprimirCelda('Señores', null, {bold: true, style: 'tdSmall', border: [true,true,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                        imprimirCelda(dataGeneral['cPersProveedorNombreLargo'], null, {
                            style: 'tdSmall', border: [false,true,true,false]
                        }),
                    ],
                    [
                        imprimirCelda('Referencia', null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cCompraNumero'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    [
                        imprimirCelda('Usuario', null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cPersCreadoDocumento']+'-'+dataGeneral['cPersCreadoNombreLargo'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    [
                        imprimirCelda('Motivo', null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cMotValeNombre'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    [
                        imprimirCelda((dataGeneral['bMotValeUsoEnAlmacen'] == 1 ? 'Responsable':'Operador'), null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cPersOperadorDocumentoNombre'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    datosVehiculo
                ],
            },
            // layout: 'noBorders',
        };

        let camposDetalle: ColumnaTabla<any>[] = [
            // {label: 'Código Producto', property: 'cCodigoProducto', type: 'text', cssClasses: []},
            {label: 'Combustible', property: 'cProductoNombre', type: 'text'},
            {label: 'U.M.', property: 'cPresentacionNombre', type: 'text'},
            {label: 'Cantidad', property: 'nValeDetCantidad', type: 'text', transformar(row): any {
                return imprimirCelda(row.nValeDetCantidad, 'cant', {
                    style: 'tdSmall',
                    alignment: 'center',
                    fontSize:12,
                    bold:true
                });
            },},
        ];
        // isNumberRound(row.nValeDetCantidad,0)
        const tablaDetalleSeparado = objThis.generarDesdeMantenimiento({
            titulos: camposDetalle,
            contenido: dataRecibido,
            numeracion: true,
            idxResto: [1],
            margin: [0, 0, 0, 5],
            separado: true,
        });
        const tablaDetalleFinal = [
            {
                // margin: [0, 0, 0, 10],
                table: {
                    widths: objThis.anchoCols(tablaDetalleSeparado['titulos'], [1]),
                    body: tablaDetalleSeparado['titulos'].concat(tablaDetalleSeparado['cuerpo']),
                },
            },
            imprimirCelda([dataGeneral['cTareaCodigo'], dataGeneral['cTareaNombre']].join(' - '), null, {style: 'tdSmall'}),

        ];
        if (dataGeneral['cPedidoObs'] || dataGeneral['cValeObs']) {
            tablaDetalleFinal.push(
                imprimirCelda(['OBS', (dataGeneral['cValeObs'] == null) ? dataGeneral['cPedidoObs']: dataGeneral['cValeObs']].join(': '), null, {style: 'tdSmall'})
            );
        }

        if (dataGeneral['cCompraAlquilerNumero']) {
            tablaDetalleFinal.push(
                imprimirCelda(dataGeneral['cCompraAlquilerNumero'] ?? '', null, {style: 'tdSmall'})
            );
        }

        const generarTablaCompleta = (objPosicion: {x: number, y: number} = null) => {
            const cuerpoPdfIndividual = [];
            // cuerpoPdfIndividual.push()
            // cuerpoPdfIndividual.push();
            cuerpoPdfIndividual.push({
                margin: [0, 0, 0, 0],
                table: {
                    widths: ['*'],
                    // heights: [394],
                    body: [
                        [
                            {
                                margin: [5, 5, 5, 0],
                                table: {
                                    widths: [277],
                                    // heights: [394],
                                    body: [
                                        [tablaHeader,],
                                        [tablaDatosPrincipales,],
                                    ],
                                },
                                layout: 'noBorders',
                            }
                        ],
                        [
                            {
                                margin: [5, 0, 5, 0],
                                table: {
                                    widths: [277],
                                    heights: [176],
                                    body: [
                                        // [tablaHeader,],
                                        // [tablaDatosPrincipales,],
                                        [tablaDetalleFinal],
                                        // [tablaDatosAutorizacion],
                                        //relativePosition: {x: 0, y: 0 },
                                    ],
                                },
                                layout: 'noBorders',
                                // relativePosition: {x: 0, y: 0 },
                            }                        ],
                    ],
                },
                layout: 'noBorders',
                absolutePosition: objPosicion,
            });
            return JSON.parse(JSON.stringify(cuerpoPdfIndividual));
        }

        const generarTalbaAutorizacion = (nompreCopia, objPosicion: {x: number, y: number} = null) => {
            const cuerpoPdfIndividual = [];
            const tablaDatosAutorizacion = [
                {
                    table: {
                        widths: ['*', 129],
                        body: [
                            [
                                imprimirCelda(dataGeneral['cDepenNombre'], null, {style: 'tdSmall', border: [true,true,true,false], fontSize: 5}),
                                imprimirCelda(nompreCopia, null, {style: 'thSmall', fontSize: 10, border: [true,true,true,false]}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                },
                {
                    table: {
                        widths: [129, 129],
                        body: [
                            [
                                imprimirCelda('\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                            ],
                            [
                                imprimirCelda('Firma Encargado de Combustible MPMN', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('Firma Recibido por', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                            ],
                            [
                                imprimirCelda('\n\n\n', null, {style: 'tdSmall', fontSize: 10, colSpan:2}),
                                ''
                            ],
                            [
                                imprimirCelda('Firma y sello Usuario', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8 , colSpan:2}),
                                ''

                            ]
                        ],
                    },
                    // layout: 'noBorders',
                },
            ];
            cuerpoPdfIndividual.push({
                margin: [5, 0, 5, 0],
                table: {
                    widths: [277],
                    // heights: [394],
                    body: [
                        [tablaDatosAutorizacion],
                    ],
                },
                layout: 'noBorders',
                // absolutePosition: {x: 10, y: 310 },
                absolutePosition: objPosicion,
            },)
            return JSON.parse(JSON.stringify(cuerpoPdfIndividual));
        }
        const generarTablaAuditoria = (objPosicion: {x: number, y: number} = null) => {
            const cuerpoPdfIndividualAuditoria = [];
            const tablaDatosAuditoria = [
                {
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                imprimirCelda('Reporte impreso por ' + objThis.datosUsuarioiEntId['data_persona']['cPersNombreLargo'] + ' (' + objThis.datosUsuarioiEntId['data_persona']['cPersDocumento'] + ')', null, {style: 'tdSmall',fontSize: 6}),
                                imprimirCelda(formatDate(new Date(), 'dd/MM/yyy HH:mm', 'en'), null, {style: 'tdSmall',fontSize: 6}),
                            ],
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
            ];

            cuerpoPdfIndividualAuditoria.push({
                margin: [5, 0, 5, 0],
                table: {
                    widths: [400],
                    body: [
                        [tablaDatosAuditoria],
                    ],
                },
                layout: 'noBorders',
                absolutePosition: objPosicion,
            },)
            return JSON.parse(JSON.stringify(cuerpoPdfIndividualAuditoria));
        }
        cuerpoPdf.push(generarTablaCompleta({x: 5, y: 5 }));
        cuerpoPdf.push(generarTalbaAutorizacion('ORIGINAL', {x: 10, y: 270}));
        cuerpoPdf.push(generarTablaAuditoria({x: 10, y: 405}));

        cuerpoPdf.push(generarTablaCompleta({x: 300, y: 5 }));
        cuerpoPdf.push(generarTalbaAutorizacion('Copia - USUARIO', {x: 310, y: 270}));
        cuerpoPdf.push(generarTablaAuditoria({x: 310, y: 405}));

        cuerpoPdf.push(generarTablaCompleta({x: 300, y: 420 }));
        cuerpoPdf.push(generarTalbaAutorizacion('Copia - ALMACEN', {x: 10, y: 685}));
        cuerpoPdf.push(generarTablaAuditoria({x: 10, y: 820}));

        cuerpoPdf.push(generarTablaCompleta({x: 5, y: 420 }));
        cuerpoPdf.push(generarTalbaAutorizacion('Copia - GRIFO', {x: 310, y: 685}));
        cuerpoPdf.push(generarTablaAuditoria({x: 310, y: 820}));

/*
        cuerpoPdf.push({
            margin: [0, 0, 0, 0],
            table: {
                widths: ['*', '*'],
                // heights: [394],
                body: [
                    [
                        generarTablaCompleta('SOLICITANTE', {autorizacion: {x: 10, y: 310}}),
                        generarTablaCompleta('ADMINISTRACION', {autorizacion: {x: 310, y: 310}}),
                    ],
                    [
                        generarTablaCompleta('ABASTECIMIENTO', {autorizacion: {x: 10, y: 710}}),
                        generarTablaCompleta('ALMACEN', {autorizacion: {x: 310, y: 710}}),
                    ],
                ],
            },
            layout: 'noBorders',
        },)
        */

        objThis.finalizarCreacionPdf(cuerpoPdf);

    })
}

export function pedidoVale(objThis: PdfAlmacenService, dataRec) {
    objThis.queryService.cargar(objThis, ['log.pedidos_combustibles'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        const dataRecibido: any[] = valRetorno['log.pedidos_combustibles'];
        const cuerpoPdf = [];

        const dataGeneral = dataRecibido[0];

        const mDeAgua = {};

        if (dataGeneral.bPedidoAnuladoRegistro == '1' || dataGeneral.bPedidoCerrado != '1') {
            mDeAgua['color'] = '#a80707';
            mDeAgua['text'] = (dataGeneral.bPedidoAnuladoRegistro == '1' ? 'ANULADO' : (dataGeneral.bPedidoCerrado == '1' ? undefined : 'BORRADOR'));
        }


        objThis.reiniciarOpciones({
            marcaDeAgua: mDeAgua,
            margenesHoja: [0, 0, 0, 0],
            orientacion: 'landscape',
            tamanioHoja: 'A5',
            tamanioFuenteExtra: 3,
        });


        cuerpoPdf.push(
            {
                canvas: [
                    {type: "line", x1: 0, y1: 0, x2: 0, y2: 842, dash: {length: 5, space: 10}},
                ],
                absolutePosition: {x: 0, y: 0},
                alignment: 'center',
            },
        );

        const tablaHeader = {
            // margin: [0, 0, 0, 0],
            table: {
                widths: ['*', 170],
                body: [
                    [
                        {
                            ...objThis.logotipoHeader(dataGeneral),
                            ...{alignment: 'left'},
                        },
                        {
                            table: {
                                widths: ['*','*'],
                                body: [
                                    [
                                        imprimirCelda('Nº', null, {alignment: 'center', style: 'thSmall', fontSize: 8}),
                                        imprimirCelda('FECHA', null, {alignment: 'center', style: 'thSmall', fontSize: 8}),
                                    ],
                                    [
                                        imprimirCelda(zeroFill(dataGeneral['iPedidoNumero'],4), null, {
                                            alignment: 'center', style: 'tdSmall', fontSize: 8
                                        }),
                                        imprimirCelda(dataGeneral['dPedidoEmision'], 'fec', {
                                            alignment: 'center', style: 'tdSmall', fontSize: 8
                                        }),
                                    ],
                                ]
                            }
                        }
                    ],
                ],
            },
            layout: 'noBorders',
        };
        let datosVehiculo = []

        if(dataGeneral['bMotValeUsoEnAlmacen'] == 1){
            // datosVehiculo = []
            datosVehiculo = [
                imprimirCelda('', null, {bold: true, style: 'tdSmall', border: [true,false,true,true], colSpan:3}),
            ]
        } else {
            datosVehiculo = [
                imprimirCelda('Vehículo', null, {bold: true, style: 'tdSmall', border: [true,false,false,true]}),
                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                imprimirCelda(dataGeneral['cVehiculoPlacaClase'] + '   Kilometraje : '+ (dataGeneral['nPedidoKilometraje'] ? isNumberRound(dataGeneral['nPedidoKilometraje'],0) : ''), null, {
                    style: 'tdSmall', border: [false,false,true,true],
                }),
            ]
        }

        const tablaDatosPrincipales = {
            table: {
                widths: ['auto', 'auto', '*'],
                body: [
                    [
                        imprimirCelda('PEDIDO DE COMBUSTIBLE', null, {colSpan: 3, alignment: 'center', style: 'thSmall', fontSize:15}),
                        '',
                        '',
                    ],
                    [
                        imprimirCelda('Señores', null, {bold: true, style: 'tdSmall', border: [true,true,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                        imprimirCelda(dataGeneral['cPersProveedorNombreLargo'], null, {
                            style: 'tdSmall', border: [false,true,true,false]
                        }),
                    ],
                    [
                        imprimirCelda('Referencia', null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cCompraNumero'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    [
                        imprimirCelda('Usuario', null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cPersCreadoDocumento']+'-'+dataGeneral['cPersCreadoNombreLargo'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    [
                        imprimirCelda('Motivo', null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cMotValeNombre'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    [
                        imprimirCelda((dataGeneral['bMotValeUsoEnAlmacen'] == 1 ? 'Responsable':'Operador'), null, {bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                        imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                        imprimirCelda(dataGeneral['cPersOperadorDocumentoNombre'], null, {
                            style: 'tdSmall', border: [false,false,true,false],
                        }),
                    ],
                    datosVehiculo,
                ],
            },
            // layout: 'noBorders',
        };

        let camposDetalle: ColumnaTabla<any>[] = [
            // {label: 'Código Producto', property: 'cCodigoProducto', type: 'text', cssClasses: []},
            {label: 'Combustible', property: 'cProductoNombre', type: 'text'},
            {label: 'U.M.', property: 'cPresentacionNombre', type: 'text'},
            // {label: 'Cantidad', property: 'nPedidoDetCantidad', type: 'number', cssClasses: ['font-bold', 'text-center']},
            // {label: 'P.Unit', property: 'nParDiaVehValorItemUnitario', type: 'number', format: '0.2-2', cssClasses: ['text-right']},
            // {label: 'SubTotal', property: 'nPedidoDetSubTotal', type: 'number', format: '0.2-2', cssClasses: ['text-right']},
            {label: 'Pedido', property: 'nPedidoDetCantidad', type: 'number', cssClasses: ['text-center']},
            // {label: 'Atendido', property: 'nValeDetCantidadTotal', type: 'number', cssClasses: ['text-center']},
            // {label: 'Total '+dataGeneral['cTipoMonedaSimbolo'], property: 'nPedidoImporteTotal', type: 'number', format: '0.2-2', cssClasses: ['text-right']},
        ];

        const tablaDetalleSeparado = objThis.generarDesdeMantenimiento({
            titulos: camposDetalle,
            contenido: dataRecibido,
            numeracion: true,
            idxResto: [1],
            margin: [0, 0, 0, 5],
            separado: true,
        });
        const tablaDetalleFinal = [
            {
                // margin: [0, 0, 0, 10],
                table: {
                    widths: objThis.anchoCols(tablaDetalleSeparado['titulos'], [1]),
                    body: tablaDetalleSeparado['titulos'].concat(tablaDetalleSeparado['cuerpo']),
                },
            },
            imprimirCelda('\n' + [dataGeneral['cTareaCodigo'], dataGeneral['cTareaNombre']].join(' - '), null, {style: 'tdSmall'}),

        ];
        if (dataGeneral['cPedidoObs']) {
            tablaDetalleFinal.push(
                imprimirCelda('\n' + ['OBS', dataGeneral['cPedidoObs']].join(': '), null, {style: 'tdSmall'})
            );
        }
        if (dataGeneral['cCompraAlquilerNumero']) {
            tablaDetalleFinal.push(
                imprimirCelda('\n' + dataGeneral['cCompraAlquilerNumero'] ?? '', null, {style: 'tdSmall'})
            );
        }


        const generarTablaCompleta = (objPosicion: {x: number, y: number} = null) => {
            const cuerpoPdfIndividual = [];
            // cuerpoPdfIndividual.push()
            // cuerpoPdfIndividual.push();
            cuerpoPdfIndividual.push({
                margin: [0, 0, 0, 0],
                table: {
                    widths: ['*'],
                    // heights: [394],
                    body: [
                        [
                            {
                                margin: [5, 5, 5, 0],
                                table: {
                                    widths: [277],
                                    // heights: [394],
                                    body: [
                                        [tablaHeader,],
                                        [tablaDatosPrincipales,],
                                    ],
                                },
                                layout: 'noBorders',
                            }
                        ],
                        [
                            {
                                margin: [5, 0, 5, 0],
                                table: {
                                    widths: [277],
                                    heights: [176],
                                    body: [
                                        // [tablaHeader,],
                                        // [tablaDatosPrincipales,],
                                        [tablaDetalleFinal],
                                        // [tablaDatosAutorizacion],
                                        //relativePosition: {x: 0, y: 0 },
                                    ],
                                },
                                layout: 'noBorders',
                                // relativePosition: {x: 0, y: 0 },
                            }                        
                        ],
                    ],
                },
                layout: 'noBorders',
                absolutePosition: objPosicion,
            });
            return JSON.parse(JSON.stringify(cuerpoPdfIndividual));
        }

        const generarTalbaAutorizacion = (nompreCopia, objPosicion: {x: number, y: number} = null) => {
            const cuerpoPdfIndividual = [];
            const tablaDatosAutorizacion = [
                {
                    table: {
                        widths: ['*', 129],
                        body: [
                            [
                                imprimirCelda(dataGeneral['cDepenNombre'], null, {style: 'tdSmall', border: [true,true,true,false], fontSize: 5}),
                                imprimirCelda(nompreCopia, null, {style: 'thSmall', fontSize: 10, border: [true,true,true,false]}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                },
                {
                    table: {
                        widths: [129, 129],
                        body: [
                            [
                                imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                            ],
                            [
                                imprimirCelda('Firma\nSolicitante', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('V°B° Encargado de Combustible MPMN', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                },
            ];
            cuerpoPdfIndividual.push({
                margin: [5, 0, 5, 0],
                table: {
                    widths: [277],
                    // heights: [394],
                    body: [
                        [tablaDatosAutorizacion],
                    ],
                },
                layout: 'noBorders',
                // absolutePosition: {x: 10, y: 310 },
                absolutePosition: objPosicion,
            },)
            return JSON.parse(JSON.stringify(cuerpoPdfIndividual));
        }
        const generarTablaAuditoria = (objPosicion: {x: number, y: number} = null) => {
            const cuerpoPdfIndividualAuditoria = [];
            const tablaDatosAuditoria = [
                {
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                imprimirCelda('Reporte impreso por ' + objThis.datosUsuarioiEntId['data_persona']['cPersNombreLargo'] + ' (' + objThis.datosUsuarioiEntId['data_persona']['cPersDocumento'] + ')', null, {style: 'tdSmall',fontSize: 6}),
                                imprimirCelda(formatDate(new Date(), 'dd/MM/yyy HH:mm', 'en'), null, {style: 'tdSmall',fontSize: 6}),
                            ],
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
            ];

            cuerpoPdfIndividualAuditoria.push({
                margin: [5, 0, 5, 0],
                table: {
                    widths: [400],
                    body: [
                        [tablaDatosAuditoria],
                    ],
                },
                layout: 'noBorders',
                absolutePosition: objPosicion,
            },)
            return JSON.parse(JSON.stringify(cuerpoPdfIndividualAuditoria));
        }
        cuerpoPdf.push(generarTablaCompleta({x: 5, y: 5 }));
        cuerpoPdf.push(generarTalbaAutorizacion('SOLICITANTE', {x: 10, y: 290}));
        cuerpoPdf.push(generarTablaAuditoria({x: 10, y: 385}));

        cuerpoPdf.push(generarTablaCompleta({x: 305, y: 5 }));
        cuerpoPdf.push(generarTalbaAutorizacion('ALMACEN CENTRAL', {x: 310, y: 290}));
        cuerpoPdf.push(generarTablaAuditoria({x: 310, y: 385}));

        // cuerpoPdf.push(generarTablaCompleta({x: 305, y: 420 }));
        // cuerpoPdf.push(generarTalbaAutorizacion('ABASTECIMIENTO', {x: 10, y: 710}));
        //
        // cuerpoPdf.push(generarTablaCompleta({x: 5, y: 420 }));
        // cuerpoPdf.push(generarTalbaAutorizacion('ALMACEN', {x: 310, y: 710}));

        /*
                cuerpoPdf.push({
                    margin: [0, 0, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        // heights: [394],
                        body: [
                            [
                                generarTablaCompleta('SOLICITANTE', {autorizacion: {x: 10, y: 310}}),
                                generarTablaCompleta('ADMINISTRACION', {autorizacion: {x: 310, y: 310}}),
                            ],
                            [
                                generarTablaCompleta('ABASTECIMIENTO', {autorizacion: {x: 10, y: 710}}),
                                generarTablaCompleta('ALMACEN', {autorizacion: {x: 310, y: 710}}),
                            ],
                        ],
                    },
                    layout: 'noBorders',
                },)
                */

        objThis.finalizarCreacionPdf(cuerpoPdf);

    })
}
export function valorizacionVale(objThis: PdfAlmacenService, dataRec) {
    let dataRecibidoValesDetalles: any[];
    let dataRecibidoValesConsumo: any[];
    objThis.queryService.cargar(objThis, ['log.valorizaciones_vales_presentacion'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        const dataRecibidoCabecera: any[] = valRetorno['log.valorizaciones_vales_presentacion'];
        objThis.queryService.cargar(objThis, ['log.compras_combustibles'], {iValorizaValeId: dataRec['cCodigoCadena']}).then(async (valRetorno: object) => {
            dataRecibidoValesConsumo = valRetorno['log.compras_combustibles'];


            objThis.queryService.cargar(objThis, ['log.vales_detalles'], {iValorizaValeId: dataRec['cCodigoCadena']}).then(async (valRetorno: object) => {
                dataRecibidoValesDetalles = valRetorno['log.vales_detalles'];
                const cuerpoPdf = [];
        
                const dataGeneral = dataRecibidoCabecera[0];
        
        
                objThis.reiniciarOpciones({
                    // marcaDeAgua: mDeAgua,
                    orientacion: 'portrait',
                    margenesHoja: [50, 80, 50, 40],
                    tamanioFuenteExtra: 1, //aumentar +1 para crecer el tamaño de la fuente en tabla  Th y td
                    defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                    defaultRightCellHeaderTable: {
                        margin: [0, 10, 0, 0],
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    imprimirCelda('Nº', null, {alignment: 'center', style: 'thSmall'}),
                                    imprimirCelda('FECHA', null, {alignment: 'center', style: 'thSmall'}),

                                ],
                                [
                                    imprimirCelda(zeroFill(dataGeneral['iValorizaValeNumero'],4), null, {
                                        fontSize: 9, bold: true, alignment: 'center',
                                    }),
                                    imprimirCelda(dataGeneral['dValorizaValeEmision'], 'fec', {
                                        fontSize: 9, bold: true, alignment: 'center',
                                    }),
                                ],
                            ],
                        },
                    },
                    defaultCenterCellHeaderTable : {
                        stack: [
                            imprimirCelda('VALORIZACIÓN DE VALE DE COMBUSTIBLE', null, {
                                fontSize: 15, bold: true,
                            }),                        
    
                            imprimirCelda(zeroFill(dataGeneral['iValorizaValeNumero'],4), null, {
                                fontSize: 13,
                                bold: true,
                            }),                        
                        ],
                        alignment: 'center'
                    }
                });
       
                cuerpoPdf.push(
                    {
                        margin: [25, 10, 25, 0],
                        alignment: 'left',
                        table: {
                            widths: [70, 'auto', '*'],
                            body: [
                                [
                                    imprimirCelda('VALORIZACIÓN DE VALE DE COMBUSTIBLE N°'+zeroFill(dataGeneral['iValorizaValeNumero'],4), null, {colSpan: 3, alignment: 'center', style: 'thSmall', fontSize:10}),
                                    '',
                                    '',
                                ],
                                [
                                    imprimirCelda('Emisión', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,true,false,false]}),
                                    imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                    imprimirCelda(dataGeneral['dValorizaValeEmision'], 'fec', {
                                        alignment: 'left', style: 'tdSmall', border: [false,true,true,false]
                                    }),
                                ],
                                [
                                    imprimirCelda('N° Valorización', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                    imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                    imprimirCelda(zeroFill(dataGeneral['iValorizaValeNumero'],4), null, {
                                        style: 'tdSmall', border: [false,false,true,false],
                                    }),
                                ],
                                [
                                    imprimirCelda('N° Orden', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                    imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                    imprimirCelda(dataGeneral['cCompraNumero'] + ' - [' + dataGeneral['iValorizaValeOrden'] +']', null, {
                                        style: 'tdSmall', border: [false,false,true,false],
                                    }),
                                ],
                                [
                                    imprimirCelda('Dependencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                    imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                    imprimirCelda(dataGeneral['cDepenNombre'], null, {
                                        style: 'tdSmall', border: [false,false,true,false],
                                    }),
                                ],
                                [
                                    imprimirCelda('Meta', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                    imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                    imprimirCelda(dataGeneral['cTareaCodigo']+' - '+dataGeneral['cTareaNombre'], null, {
                                        style: 'tdSmall', border: [false,false,true,false],
                                    }),
                                ],
                                [
                                    imprimirCelda('Proveedor', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,true]}),
                                    imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                    imprimirCelda(dataGeneral['cPersProveedorDocumento']+' - '+dataGeneral['cPersProveedorNombreLargo'], null, {
                                        style: 'tdSmall', border: [false,false,true,true],
                                    }),
                                ],
                            ],
                        },
                        // layout: 'noBorders',
                    }
                );
    
                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                const agrupado = groupBy(dataRecibidoValesDetalles, 'cProductoNombre');
                console.warn(agrupado);
    
                const camposPrincipal: ColumnaTabla<any>[] = [
                    { label: 'VALE', property: 'iValeNumero', type: 'text' },
                    { label: 'EMISIÓN', property: 'dValeEmision', type: 'date' },
                    { label: 'MOTIVO', property: 'cMotValeNombre', type: 'text', cssClasses: ['text-center'] },
                    { label: 'OPERADOR', property: 'cPersOperadorDocumento', type: 'text', transformar : (row)=>{
                        return row.cPersOperadorDocumento +' - '+ row.cPersOperadorNombre
                    } },
                    { label: 'VEHICULO', property: 'cClaseVehiculoNombre', type: 'text' },
                    { label: 'PLACA', property: 'cVehiculoPlaca', type: 'text' },
                    { label: 'CANTIDAD', property: 'nValeDetCantidad', type: 'number', format: '1.2-2' },
                    { label: 'P.U.', property: 'nParDiaVehValorItemUnitario', type: 'number' , format: '1.2-2'},
                    { label: 'SUB TOTAL', property: 'nValeDetSubTotal', type: 'number', format: '1.2-2' },
                ];
    
                let datosTabla = [];
                let titulosTabla;
    
                Object.keys(agrupado).forEach((seccion, idx) => {
    
                    const suma = sumarObjetos(agrupado[seccion], [
                        'nValeDetCantidad','nValeDetSubTotal',
                    ]);
    
                    const tablaGenerada = objThis.generarDesdeMantenimiento({
                        titulos: camposPrincipal,
                        contenido: agrupado[seccion],
                        numeracion: true,
                        // idxResto: [2],
                        // margin: [50, 30, 50, 40],
                        separado: true,
                    });
                    if(idx == 0) {
                        datosTabla = datosTabla.concat(tablaGenerada['titulos']);
                        titulosTabla = tablaGenerada['titulos'];
                    }
    
                    datosTabla = datosTabla.concat(tablaGenerada['cuerpo']).concat([
                            [
                                imprimirCelda(seccion, null, {border: [false, true, true, false], bold: true, style: 'tdSmall', alignment: 'right', colSpan: 7}),
                                {}, {}, {}, {}, {}, {},
                                imprimirCelda(suma['nValeDetCantidad'], 'cant', {bold: true, style: 'tdSmall',  alignment: 'right', format: '1.2-2'}),
                                imprimirCelda('', null, {border: [true, true, true, false], bold: true, style: 'tdSmall', alignment: 'right'}),
                                imprimirCelda(suma['nValeDetSubTotal'], 'cant', {bold: true, style: 'tdSmall',  alignment: 'right', format: '1.2-2'}),
                            ],
                            [
                                imprimirCelda('', null, {border: [false, false, false, false], style: 'tdSmall', colSpan: 10}),
                                {}, {}, {}, {}, {}, {}, {}, {}, {}
                            ]
                        ]);
                    
    
    
    
                });
    
                cuerpoPdf.push({
                    // margin: [0, 5, 0, 10],
                    margin: [25, 5, 25, 0],
                    table: {
                        dontBreakRows: true,
                        // headerRows: 2,
                        widths: objThis.anchoCols(titulosTabla, [3,4]),
                        body: datosTabla,
                    }
                });
                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
                cuerpoPdf.push(
                    {
                        margin: [25, 5, 25, 0],
                        alignment: 'center',
                        table: {
                            widths: ['*',40],
                            body: [
                                [
                                    imprimirCelda(dataGeneral['cValorizaTotal_Letra'], null, {alignment: 'center', bold: true, style: 'tdSmall', fontSize:10}),
                                    imprimirCelda(dataGeneral['nValorizaTotal'], 'cant', {bold: true, style: 'tdSmall',  alignment: 'right', format: '1.2-2'}),
                                ],
                            ],
                        },
                        // layout: 'noBorders',
                    }
                );
    
                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                const celdasHeaderDetallesConsumo = [
                    [
                        imprimirCelda('Nº', null, {style: 'thSmall'}),
                        imprimirCelda('Clasificador', null, {style: 'thSmall'}),
                        imprimirCelda('Descripción Bien', null, {style: 'thSmall'}),
                        imprimirCelda('U.M', null, {style: 'thSmall'}),
                        imprimirCelda('Cantidad', null, {style: 'thSmall'}),
                        imprimirCelda('Acumulado', null, {style: 'thSmall'}),
                        imprimirCelda('Actual', null, {style: 'thSmall'}),
                        imprimirCelda('Saldo', null, {style: 'thSmall'}),
                    ],
                ];
    
                const celdasBodyDetallesConsumo = [];
    
                dataRecibidoValesConsumo.filter((data, i) => {
                    celdasBodyDetallesConsumo.push(
                        [
                            imprimirCelda(i + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                            imprimirCelda(data.cEspeDetCodigo, 'texto', {style: 'tdSmall', alignment: 'center'}),
                            imprimirCelda(data.cCodigoProducto+' - '+data.cProductoNombre, 'texto', {style: 'tdSmall', alignment: 'left'}),
                            imprimirCelda(data.cPresentacionNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                            imprimirCelda(data.nCompraDetCantidad, 'cant', {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                            imprimirCelda(data.nAcumulado_valorizaciones, 'cant', {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                            imprimirCelda(data.nActual_valorizaciones, 'cant', {style: 'tdSmall', alignment: 'right', format: '1.2-2'}),
                            imprimirCelda(data.nSaldo_valorizaciones, 'cant', {style: 'tdSmall', alignment: 'right', format: '1.2-2'}),
                        ]
                    );
        
                });
    
                const tabla = {
                    margin: [25, 5, 25, 0],
                    alignment: 'center',
                    table: {
                        widths: [10, 40, 150, '*', '*', '*', '*', '*' ],
                        body: celdasHeaderDetallesConsumo.concat(celdasBodyDetallesConsumo)
                    }
                };
                cuerpoPdf.push(tabla);
    
                
        
                objThis.finalizarCreacionPdf(cuerpoPdf);
            });
        })

    })
}

export function reporteVales(objThis: PdfAlmacenService, dataRec, accion, callback) {
    objThis.queryService.cargar(objThis, ['log.vales_detalles_presentacion'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        const dataRecibido: any[] = valRetorno['log.vales_detalles_presentacion'];
        const cuerpoPdf = [];

        let resultadosGenerales = dataRecibido;

        resultadosGenerales = ordenarPorPropiedad(dataRecibido, 'iValeId');

        const agrupado = groupBy(resultadosGenerales, (obj) => {
            return obj.iValeId;
        });


        const camposPrincipal: ColumnaTabla<any>[] = dataRec.titulos.principal;
        const camposDetalle: ColumnaTabla<any>[] = dataRec.titulos.detalle;

        let listaItems = [];
        Object.keys(agrupado).forEach((itemsGrupo, idx) => {
            listaItems.push({
                ...agrupado[itemsGrupo][0],
                ...{
                    detalle: agrupado[itemsGrupo],
                }
            })
        });
        console.log(listaItems);
        objThis.listaRegular({
            titulo: 'VALES DE COMBUSTIBLE',
            titulos: camposPrincipal,
            contenido: listaItems,
            numeracion: true,
            idxResto: [7],
            detalle: [
                {
                    campo: 'detalle',
                    titulos: camposDetalle,

                    numeracion: true,
                    idxResto: [1],
                    label: {text: 'Vales', bold: true, fontSize: 10}
                }
            ],
            orientacion: 'portrait',

        },{},accion,callback)

    })

}

export function parteDiario(objThis: PdfAlmacenService, dataRec) {
    let dataRecibidoCabecera: any[]
    let dataRecibidoActividades: any[]
    let bAutorizaCorreccion: boolean = false
    objThis.queryService.cargar(objThis, ['log.partes_diarios_vehiculos','log.partes_diarios_vehiculos_actividades'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        bAutorizaCorreccion = dataRec['bAutorizaCorreccion']
        dataRecibidoCabecera = valRetorno['log.partes_diarios_vehiculos'];
        dataRecibidoActividades = valRetorno['log.partes_diarios_vehiculos_actividades'];

            const cuerpoPdf = [];
            const dataGeneral = dataRecibidoCabecera[0];
            const mDeAgua = {};
            if (dataGeneral.bParDiaVehCerrado != '1') {
                mDeAgua['color'] = '#a80707';
                mDeAgua['text'] = 'BORRADOR';
            }
            objThis.reiniciarOpciones({
                marcaDeAgua: mDeAgua,
                orientacion: 'landscape',
                margenesHoja: [5, 5, 5, 5],
                tamanioFuenteExtra: 1, //aumentar +1 para crecer el tamaño de la fuente en tabla  Th y td
               
            });

            cuerpoPdf.push(
                {
                    canvas: [
                        {type: "line", x1: 0, y1: 0, x2: 0, y2: 842, dash: {length: 5, space: 10}},
                    ],
                    absolutePosition: {x: 0, y: 0},
                    alignment: 'center',
                },
            );
            
            const tablaHeader = {
                // margin: [0, 0, 0, 0],
                table: {
                    widths: ['*', 170],
                    body: [
                        [
                            {
                                ...objThis.logotipoHeader(dataGeneral),
                                ...{alignment: 'left'},
                            },
                            {
                                table: {
                                    widths: ['*','*'],
                                    body: [
                                        [
                                            imprimirCelda('Nº', null, {alignment: 'center', style: 'thSmall'}),
                                            imprimirCelda('FECHA', null, {alignment: 'center', style: 'thSmall'}),
                                        ],
                                        [
                                            imprimirCelda(zeroFill(dataGeneral['iParDiaVehNumero'],4), null, {
                                                fontSize: 9, bold: true, alignment: 'center',
                                            }),
                                            imprimirCelda(dataGeneral['dParDiaVehEmision'], 'fec', {
                                                fontSize: 9, bold: true, alignment: 'center',
                                            }),
                                        ],
                                    ]
                                }
                            }
                        ],
                    ],
                },
                layout: 'noBorders',
            };

            let tituloParteDiario: string = 'PARTE DIARIO';
            if(bAutorizaCorreccion){
                tituloParteDiario = 'CORRECCIÓN DE PARTE DIARIO';
            }
            const tablaDatosPrincipales = {
                table: {
                    widths: ['auto', 'auto', '*'],
                    body: [
                        [
                            imprimirCelda(tituloParteDiario+' - '+zeroFill(dataGeneral['iParDiaVehNumero'],4), null, {colSpan: 3, alignment: 'center', style: 'thSmall', fontSize:15}),
                            '',
                            '',
                        ],
                        [
                            imprimirCelda('N° Orden', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                            imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                            imprimirCelda(dataGeneral['cCompraNumero'], null, {
                                style: 'tdSmall', border: [false,false,true,false],
                            }),
                        ],
                        [
                            imprimirCelda('Vehículo', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                            imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                            imprimirCelda(dataGeneral['cVehiculoPlaca'] + ' - ' + dataGeneral['cClaseVehiculoNombre'], null, {
                                style: 'tdSmall', border: [false,false,true,false],
                            }),
                        ],
                        [
                            imprimirCelda('Meta', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                            imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                            imprimirCelda(dataGeneral['cTareaCodigo'] + ' - ' + dataGeneral['cTareaNombre'], null, {
                                style: 'tdSmall', border: [false,false,true,false],
                            }),
                        ],
                        [
                            imprimirCelda('Operador', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,true]}),
                            imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                            imprimirCelda(dataGeneral['cPersOperadorDocumento'] + ' - ' + dataGeneral['cPersOperadorNombre'], null, {
                                style: 'tdSmall', border: [false,false,true,true],
                            }),
                        ],
                    ],
                },
                
                // layout: 'noBorders',
            };
            const obs = (dataGeneral['cParDiaVehObs'] == null) ? '': dataGeneral['cParDiaVehObs']
            
            const celdasBodyActividades = [];
            if(dataRecibidoActividades.length > 0){
                dataRecibidoActividades.filter((data, i) => {
                    celdasBodyActividades.push(
                        [
                            imprimirCelda(i + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                            imprimirCelda(data.cActParDiaVehNombre, 'texto', {style: 'tdSmall', alignment: 'left'}),
                            imprimirCelda(data.cParDiaVehActObs, 'texto', {style: 'tdSmall', alignment: 'left'}),
                        ]
                    );
        
                });
            } else {
                celdasBodyActividades.push(
                    [
                        imprimirCelda('-'),
                        imprimirCelda('-'),
                        imprimirCelda('-'),
                    ],
                )
            }
            const tablaDatosParteDiario = [
                {
                    margin: [0, 3, 0, 0],
                    alignment: 'center',
                    table: {
                        widths: [150,'*','*','*'],
                        body: [
                            [
                                imprimirCelda('Contador', 'texto', {colSpan:4, bold: true, style: 'thSmall'}),
                                '',
                                '',
                                '',
                            ],
                            [
                                imprimirCelda('Medida de Eficiencia', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Inicio', null, {alignment: 'center', bold: true, style: 'thSmall' }),
                                imprimirCelda('Fin', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Total', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                            ],
                            [
                                imprimirCelda(dataGeneral['cMedEfiVehNombre'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['nParDiaVehInicioEficiencia'] ? isNumberRound(dataGeneral['nParDiaVehInicioEficiencia'],2):0, null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda( dataGeneral['nParDiaVehFinEficiencia'] ? (isNumberRound(dataGeneral['nParDiaVehFinEficiencia'],2)) : 0, null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda((dataGeneral['nParDiaVehFinEficiencia'] - dataGeneral['nParDiaVehInicioEficiencia'] <= 0) ? '-': isNumberRound(dataGeneral['nParDiaVehFinEficiencia'] - dataGeneral['nParDiaVehInicioEficiencia'],2), null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                },
                {
                    margin: [0, 7, 0, 0],
                    alignment: 'center',
                    table: {
                        widths: [150,'*','*','*'],
                        body: [
                            [
                                imprimirCelda('Consumo', 'texto', {colSpan:4, bold: true, style: 'thSmall'}),
                                '',
                                '',
                                '',
                                // '',
                            ],
                            [
                                imprimirCelda(dataGeneral['bParDiaVehConVale'] == '1' ? 'Combustible':'Combustible (área usuaria)' , null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('U.M.', null, {alignment: 'center', bold: true, style: 'thSmall' }),
                                imprimirCelda('Cant.', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('N° Vale', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                // imprimirCelda('Fecha Consumo Vale', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                            ],
                            [
                                imprimirCelda(dataGeneral['cProductoNombre'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cPresentacionNombre'], null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda((dataGeneral['nParDiaVehCantidadCombustible']* 1 != 0) ? isNumberRound(dataGeneral['nParDiaVehCantidadCombustible'],2) : '', 'texto', {bold: true, style: 'tdSmall',  alignment: 'center', format: '1.2-2'}),
                                imprimirCelda(dataGeneral['bParDiaVehConVale'] == '1' ? dataGeneral['iValeNumero'] == null ? '' : dataGeneral['iValeYear']+'-'+zeroFill(dataGeneral['iValeNumero'],4) : 'ninguno', null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                // imprimirCelda(dataGeneral['dValeEmision'], 'fec', {bold: true, style: 'tdSmall',  alignment: 'center'}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                },
                {
                    margin: [0, 7, 0, 0],
                    alignment: 'center',
                    table: {
                        widths: ['*','*','*','*','*','*',60],
                        body: [
                            [
                                imprimirCelda('Horas de Labor', 'texto', {colSpan:7, bold: true, style: 'thSmall'}),
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                            ],
                            [
                                imprimirCelda('Mañana', null, {colSpan:3, alignment: 'center', bold: true, style: 'thSmall'}),
                                '',
                                '',
                                imprimirCelda('Tarde', null, {colSpan:3, alignment: 'center', bold: true, style: 'thSmall'}),
                                '',
                                '',
                                imprimirCelda('Acumulado', null, {rowSpan:2, alignment: 'center', bold: true, style: 'thSmall'}),
                            ],
                            [
                                imprimirCelda('Inicio', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Termino', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Total', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Inicio', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Termino', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Total', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                '',
                            ],
                            [
                                imprimirCelda(dataGeneral['cParDiaVehTomorrowIngreso'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cParDiaVehTomorrowSalida'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda( (dataGeneral['cParDiaVehTomorrowIngreso'] && dataGeneral['cParDiaVehTomorrowSalida'] )? (operacionesHoras(dataGeneral['cParDiaVehTomorrowIngreso'],dataGeneral['cParDiaVehTomorrowSalida'],'restar')) : '00:00', null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cParDiaVehLateIngreso'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cParDiaVehLateSalida'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda( (dataGeneral['cParDiaVehLateIngreso'] && dataGeneral['cParDiaVehLateSalida']) ? (operacionesHoras(dataGeneral['cParDiaVehLateIngreso'],dataGeneral['cParDiaVehLateSalida'],'restar')) : '00:00', null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(operacionesHoras((dataGeneral['cParDiaVehTomorrowIngreso'] && dataGeneral['cParDiaVehTomorrowSalida'] )? (operacionesHoras(dataGeneral['cParDiaVehTomorrowIngreso'],dataGeneral['cParDiaVehTomorrowSalida'],'restar')) : '00:00',(dataGeneral['cParDiaVehLateIngreso'] && dataGeneral['cParDiaVehLateSalida']) ? (operacionesHoras(dataGeneral['cParDiaVehLateIngreso'],dataGeneral['cParDiaVehLateSalida'],'restar')) : '00:00','sumar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                },
                {
                    margin: [0, 7, 0, 0],
                    alignment: 'center',
                    table: {
                        widths: [10,220,'*'],
                        body: [
                            // [
                            //     imprimirCelda('Actividades', 'texto', {colSpan:3, bold: true, style: 'thSmall'}),
                            // ],
                            [
                                imprimirCelda('Nº', null, {style: 'thSmall',border: [true, true, true, false]}),
                                imprimirCelda('Actividad', null, {style: 'thSmall',border: [true, true, true, false]}),
                                imprimirCelda('Observación', null, {style: 'thSmall',border: [true, true, true, false]}),
                            ],
                        ],
                    },
                },
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        widths: [10,220,'*'],
                        body: celdasBodyActividades
                    },
                },
                {
                    margin: [0, 6, 0, 0],
                    table: {
                        body: [
                            [
                                imprimirCelda('Obs. '+ obs, 'texto'),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                }
            ]
            
            const generarTablaCompleta = (objPosicion: {x: number, y: number} = null) => {
                const cuerpoPdfIndividual = [];
                // cuerpoPdfIndividual.push()
                // cuerpoPdfIndividual.push();
                cuerpoPdfIndividual.push({
                    margin: [0, 0, 0, 0],
                    table: {
                        widths: ['*'],
                        // heights: [394],
                        body: [
                            [
                                {
                                    margin: [5, 5, 5, 0],
                                    table: {
                                        widths: [400],
                                        // heights: [394],
                                        body: [
                                            [tablaHeader,],
                                            [tablaDatosPrincipales,],
                                            [tablaDatosParteDiario,],
                                        ],
                                    },
                                    layout: 'noBorders',
                                }
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    absolutePosition: objPosicion,
                });
                return JSON.parse(JSON.stringify(cuerpoPdfIndividual));
            }

            const generarTalbaAutorizacion = (nompreCopia, objPosicion: {x: number, y: number} = null) => {
                const cuerpoPdfIndividual = [];
                const tablaDatosAutorizacion = [
                    {
                        table: {
                            widths: ['*', 'auto'],
                            body: [
                                [
                                    imprimirCelda(dataGeneral['cDepenNombre'], null, {style: 'tdSmall', border: [true,true,true,false], fontSize: 5}),
                                    imprimirCelda(nompreCopia, null, {style: 'thSmall', border: [true,true,true,false], fontSize: 10}),
                                ],
                            ],
                        },
                        // layout: 'noBorders',
                    },
                    {
                        table: {
                            // widths: [190,190],
                            widths: [124,124,124],
                            body: [
                                [
                                    imprimirCelda('', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                    imprimirCelda('', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                    imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                ],
                                [
                                    imprimirCelda('', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                    imprimirCelda('', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                    imprimirCelda('V°B° Encargado de Combustible MPMN', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                ],
                                [
                                    imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                    imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                    imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                    
                                ],
                                [
                                    imprimirCelda('Firma - DNI \nOperador', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                    imprimirCelda('Firma \nResidente / Responsable', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                    imprimirCelda('Firma \nSupervisor', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                    
                                ],
                            ],
                        },
                        // layout: 'noBorders',
                    },
                ];
                const tablaDatosAutorizacionCorregido = [
                    {
                        table: {
                            widths: ['*', 'auto'],
                            body: [
                                [
                                    imprimirCelda(dataGeneral['cDepenNombre'], null, {style: 'tdSmall', fontSize: 5}),
                                    imprimirCelda(nompreCopia, null, {style: 'thSmall', fontSize: 10}),
                                ],
                            ],
                        },
                        // layout: 'noBorders',
                    },
                    {
                        table: {
                            widths: ['*','*','*'],
                            body: [
                                [
                                    imprimirCelda('Firma Operador', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                    imprimirCelda('Firma Residente / Responsable', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                    imprimirCelda('Firma Jefe Inmediato', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                ],
                                    [
                                    imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                    imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                    imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                ],
                            ],
                        },
                        // layout: 'noBorders',
                    },
                ];
                cuerpoPdfIndividual.push({
                    margin: [5, 0, 5, 0],
                    table: {
                        widths: [400],
                        // heights: [394],
                        body: [
                            [bAutorizaCorreccion? tablaDatosAutorizacionCorregido : tablaDatosAutorizacion],
                        ],
                    },
                    layout: 'noBorders',
                    // absolutePosition: {x: 10, y: 310 },
                    absolutePosition: objPosicion,
                },)
                return JSON.parse(JSON.stringify(cuerpoPdfIndividual));
            }
            // this.datosUsuarioiEntId = this.usuarioService._data.getValue()


            const generarTablaAuditoria = (objPosicion: {x: number, y: number} = null) => {
                const cuerpoPdfIndividualAuditoria = [];
                const tablaDatosAuditoria = [
                    {
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    imprimirCelda('Reporte impreso por ' + objThis.datosUsuarioiEntId['data_persona']['cPersNombreLargo'] + ' (' + objThis.datosUsuarioiEntId['data_persona']['cPersDocumento'] + ')', null, {style: 'tdSmall'}),
                                    imprimirCelda(formatDate(new Date(), 'dd/MM/yyy HH:mm', 'en'), null, {style: 'tdSmall'}),
                                ],
                            ],
                        },
                        layout: {
                            defaultBorder: false,
                        }
                    },
                ];

                cuerpoPdfIndividualAuditoria.push({
                    margin: [5, 0, 5, 0],
                    table: {
                        widths: [400],
                        body: [
                            [tablaDatosAuditoria],
                        ],
                    },
                    layout: 'noBorders',
                    absolutePosition: objPosicion,
                },)
                return JSON.parse(JSON.stringify(cuerpoPdfIndividualAuditoria));
            }
            cuerpoPdf.push(generarTablaCompleta({x: 5, y: 5 }));
            cuerpoPdf.push(generarTalbaAutorizacion('EMISOR', {x: 10, y: 400}));
            cuerpoPdf.push(generarTablaAuditoria({x: 10, y: 569}));

            cuerpoPdf.push(generarTablaCompleta({x: 425, y: 5 }));
            cuerpoPdf.push(generarTalbaAutorizacion('CONTROL ADM', {x: 430, y: 400}));
            cuerpoPdf.push(generarTablaAuditoria({x: 430, y: 569}));

            

            // cuerpoPdf.push({
            //     style: 'table',
            //     margin: [10, 0, 10, 0],
            //     table: {
            //         widths: ['*', '*'],
            //         headerRows: 0,
            //         body: [
            //             [
            //                 {
            //                     border: [false, true, false, false],
            //                     fontSize: 8,
            //                     stack: [
            //                         'Reporte impreso por ' + 'anthony' + ' (' + '04404058' + ')',
            //                         {
            //                             text:  'JVSoft (http://jvsoft.pe)',
            //                             link: 'http://jvsoft.pe',
            //                         },
            //                     ]
            //                 },
            //             ],
            //         ]
            //     },
            //     layout: {
            //         defaultBorder: false,
            //     }
            // });
            objThis.finalizarCreacionPdf(cuerpoPdf);

    })
}
export function parteDiario2(objThis: PdfAlmacenService, dataRec) {
    let dataRecibidoCabecera: any[]
    objThis.queryService.cargar(objThis, ['log.partes_diarios_vehiculos'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        dataRecibidoCabecera = valRetorno['log.partes_diarios_vehiculos'];

            const cuerpoPdf = [];
    
            const dataGeneral = dataRecibidoCabecera[0];
    
    
            objThis.reiniciarOpciones({
                // marcaDeAgua: mDeAgua,
                orientacion: 'landscape',
                margenesHoja: [50, 80, 50, 40],
                tamanioFuenteExtra: 1, //aumentar +1 para crecer el tamaño de la fuente en tabla  Th y td
                defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                imprimirCelda(zeroFill(dataGeneral['iParDiaVehNumero'],4), null, {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                                imprimirCelda(dataGeneral['dParDiaVehEmision'], 'fec', {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                            ],
                            [
                                imprimirCelda('NRO', null, {fontSize: 7, alignment: 'center'}),
                                imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable : {
                    stack: [
                        imprimirCelda('PARTE DIARIO DE TRABAJO', null, {
                            fontSize: 13, bold: true,
                        }),                        

                        imprimirCelda(zeroFill(dataGeneral['iParDiaVehNumero'],4), null, {
                            fontSize: 13,
                            bold: true,
                        }),                        
                    ],
                    alignment: 'center'
                }
            });
            cuerpoPdf.push(
                {
                    canvas: [
                        {type: "line", x1: 0, y1: 421, x2: 594, y2: 421, dash: {length: 5, space: 10}},
                    ],
                    absolutePosition: {x: 0, y: 0},
                    alignment: 'center',
                },
            );
            cuerpoPdf.push(
                {
                    margin: [25, 10, 25, 0],
                    alignment: 'left',
                    table: {
                        widths: [70, 'auto', '*'],
                        body: [
                            // [
                            //     imprimirCelda('Emisión', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,true,false,false], fontSize:10}),
                            //     imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                            //     imprimirCelda(dataGeneral['dParDiaVehEmision'], 'fec', {
                            //         alignment: 'left', style: 'tdSmall', border: [false,true,true,false]
                            //     }),
                            // ],
                            // [
                            //     imprimirCelda('N° Parte Diario', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                            //     imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                            //     imprimirCelda(zeroFill(dataGeneral['iParDiaVehNumero'],4), null, {
                            //         style: 'tdSmall', border: [false,false,true,false],
                            //     }),
                            // ],
                            [
                                imprimirCelda('N° Orden', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cCompraNumero'], null, {
                                    style: 'tdSmall', border: [false,false,true,false],
                                }),
                            ],
                            [
                                imprimirCelda('Vehículo', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cVehiculoPlaca'] + ' - ' + dataGeneral['cClaseVehiculoNombre'], null, {
                                    style: 'tdSmall', border: [false,false,true,false],
                                }),
                            ],
                            [
                                imprimirCelda('Meta', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cTareaCodigo'] + ' - ' + dataGeneral['cTareaNombre'], null, {
                                    style: 'tdSmall', border: [false,false,true,false],
                                }),
                            ],
                            [
                                imprimirCelda('Operador', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cPersOperadorDocumento'] + ' - ' + dataGeneral['cPersOperadorNombre'], null, {
                                    style: 'tdSmall', border: [false,false,true,false],
                                }),
                            ],
                        ],
                    },
                    layout: 'noBorders',
                }
            );
            cuerpoPdf.push(
                {
                    margin: [25, 3, 25, 0],
                    table: {
                        body: [
                            [
                                imprimirCelda('Contador', 'texto', {bold: true, fontSize:8}),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                },
                {
                    margin: [25, 0, 25, 0],
                    alignment: 'center',
                    table: {
                        widths: [150,'*','*','*'],
                        body: [
                            [
                                imprimirCelda('Medida de Eficiencia', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Inicio', null, {alignment: 'center', bold: true, style: 'thSmall' }),
                                imprimirCelda('Fin', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Total', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                            ],
                            [
                                imprimirCelda(dataGeneral['cMedEfiVehNombre'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(isNumberRound(dataGeneral['nParDiaVehInicioEficiencia'],2), null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda(isNumberRound(dataGeneral['nParDiaVehFinEficiencia'],2), null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda(isNumberRound(dataGeneral['nParDiaVehFinEficiencia'] - dataGeneral['nParDiaVehInicioEficiencia'],2), null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                }
            );

            cuerpoPdf.push(
                {
                    margin: [25, 3, 25, 0],
                    table: {
                        body: [
                            [
                                imprimirCelda('Consumo', 'texto', {bold: true, fontSize:8}),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                },
                {
                    margin: [25, 0, 25, 0],
                    alignment: 'center',
                    table: {
                        widths: [150,'*','*','*','*'],
                        body: [
                            [
                                imprimirCelda('Combustible', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('U.M.', null, {alignment: 'center', bold: true, style: 'thSmall' }),
                                imprimirCelda('Cant.', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('N° Vale', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Fecha Emisión Vale', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                            ],
                            [
                                imprimirCelda(dataGeneral['cProductoNombre'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cPresentacionNombre'], null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda(dataGeneral['nParDiaVehCantidadCombustible'], 'cant', {bold: true, style: 'tdSmall',  alignment: 'center', format: '1.2-2'}),
                                imprimirCelda(zeroFill(dataGeneral['iParDiaVehNumeroVale'],4), null, {bold: true, style: 'tdSmall',  alignment: 'center'}),
                                imprimirCelda(dataGeneral['dParDiaVehEmisionVale'], 'fec', {bold: true, style: 'tdSmall',  alignment: 'center'}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                }
            );
            cuerpoPdf.push(
                {
                    margin: [25, 3, 25, 0],
                    table: {
                        body: [
                            [
                                imprimirCelda('Horas de Labor', 'texto', {bold: true, fontSize:8}),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                },
                {
                    margin: [25, 0, 25, 0],
                    alignment: 'center',
                    table: {
                        widths: ['*','*','*','*','*','*',60],
                        body: [
                            [
                                imprimirCelda('Mañana', null, {colSpan:3, alignment: 'center', bold: true, style: 'thSmall'}),
                                '',
                                '',
                                imprimirCelda('Tarde', null, {colSpan:3, alignment: 'center', bold: true, style: 'thSmall'}),
                                '',
                                '',
                                imprimirCelda('Acumulado', null, {rowSpan:2, alignment: 'center', bold: true, style: 'thSmall'}),
                            ],
                            [
                                imprimirCelda('Inicio', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Termino', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Total', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Inicio', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Termino', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                imprimirCelda('Total', null, {alignment: 'center', bold: true, style: 'thSmall'}),
                                '',
                            ],
                            [
                                imprimirCelda(dataGeneral['cParDiaVehTomorrowIngreso'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cParDiaVehTomorrowSalida'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(operacionesHoras(dataGeneral['cParDiaVehTomorrowIngreso'],dataGeneral['cParDiaVehTomorrowSalida'],'restar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cParDiaVehLateIngreso'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cParDiaVehLateSalida'], null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(operacionesHoras(dataGeneral['cParDiaVehLateIngreso'],dataGeneral['cParDiaVehLateSalida'],'restar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                                imprimirCelda(operacionesHoras(operacionesHoras(dataGeneral['cParDiaVehLateIngreso'],dataGeneral['cParDiaVehLateSalida'],'restar'),operacionesHoras(dataGeneral['cParDiaVehTomorrowIngreso'],dataGeneral['cParDiaVehTomorrowSalida'],'restar'),'sumar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                }
            );

            const obs = (dataGeneral['cParDiaVehObs'] == null) ? '': dataGeneral['cParDiaVehObs']
            cuerpoPdf.push(
                {
                    margin: [25, 3, 25, 0],
                    table: {
                        body: [
                            [
                                imprimirCelda('Obs. '+ obs, 'texto'),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                }
            );

            cuerpoPdf.push(
                {
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                imprimirCelda('V.B. Operador', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('V.B. Residente', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('V.B. Supervisor', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                            ],
                            [
                                imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                    absolutePosition: {x: 75, y: 720 },
                },

            );
            objThis.finalizarCreacionPdf(cuerpoPdf);

    })
}
export function valorizacionParteDiario(objThis: PdfAlmacenService, dataRec, ef) {
    let dataRecibido: any[]
    objThis.queryService.cargar(objThis, ['log.valorizaciones_partes_diarios_vehiculos_detalles_presentacion'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        dataRecibido = valRetorno['log.valorizaciones_partes_diarios_vehiculos_detalles_presentacion'];

            const cuerpoPdf = [];
    
            const dataGeneral = dataRecibido[0];
    
    
            objThis.reiniciarOpciones({
                // marcaDeAgua: mDeAgua,
                orientacion: 'landscape',
                margenesHoja: [50, 80, 50, 40],
                tamanioFuenteExtra: 1, //aumentar +1 para crecer el tamaño de la fuente en tabla  Th y td
                defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                imprimirCelda('NRO', null, {alignment: 'center', style: 'thSmall'}),
                                imprimirCelda('FECHA', null, {alignment: 'center', style: 'thSmall'}),
                            ],

                            [
                                imprimirCelda(zeroFill(dataGeneral['iValorizaParDiaVehNumero'],4), null, {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                                imprimirCelda(dataGeneral['dValorizaParDiaVehEmision'], 'fec', {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable : {
                    stack: [
                        imprimirCelda('VALORIZACIÓN DE PARTE DIARIO', null, {
                            fontSize: 15, bold: true,
                        }),                        

                        imprimirCelda('Desde : '+ dataGeneral['dParDiaVehInicioEmision'] + '   Hasta : ' + dataGeneral['dParDiaVehFinEmision'], null, {
                            fontSize: 13,
                            bold: true,
                        }),                        
                    ],
                    alignment: 'center'
                }
            });
   
            cuerpoPdf.push(
                {
                    margin: [5, 10, 5, 0],
                    alignment: 'left',
                    table: {
                        widths: [70, 'auto', 200,70,10,130,70,'auto','*'],
                        body: [
                            [
                                imprimirCelda('VALORIZACIÓN DE PARTE DIARIO', 'texto', {colSpan:9, bold: true, style: 'thSmall', alignment:'center'}),
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                            ],
                            [
                                imprimirCelda('Vehículo', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cVehiculoPlaca'] + ' - ' + dataGeneral['cClaseVehiculoNombre'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('N° Orden', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(dataGeneral['cCompraNumero'] + ' - [' + dataGeneral['iValorizaParDiaVehOrden'] +']  |  SIAF:'+dataGeneral['iCompraYear']+'-'+dataGeneral['Expediente'] +' \n S/.'+isNumberRound(dataGeneral['nCompraValorTotal'],2), null, {
                                    style: 'tdSmall', border: [false,true,false,false],
                                }),
                                imprimirCelda('Cant. Partes Diarios', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(dataGeneral['iCantidad_partes_diarios_vehiculos'], 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,true,true,false], format: '1.2-2'
                                }),
                            ],
                            [
                                imprimirCelda('Establecimiento', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cEstablecimientoNombreCorto'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Rango de Fechas', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda('Desde : '+ dataGeneral['dParDiaVehInicioEmision'] + '   Hasta : ' + dataGeneral['dParDiaVehFinEmision'], null, {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,false,false]
                                }),
                                imprimirCelda('Promedio Eficiencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['nCompraDetEficienciaPromedioVehiculo'], 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,false], format: '1.2-2',
                                }),
                            ],
                            [
                                imprimirCelda('Dependencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cDepenNombre'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Tiempo Contratado', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(isNumberRound(dataGeneral['nCompraDetCantidadUsoVehiculo'],2)  + ' - '+ dataGeneral['cPresentacionCantidadEficienciaNombre'], 'texto', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,false,false]
                                }),
                                imprimirCelda('Eficiencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(ef.nCompraDetEficienciaPromedioVehiculoTotal, 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,false], format: '1.2-2'
                                }),
                            ],

                            [
                                imprimirCelda('Proveedor', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cPersProveedorDocumento']+' - '+dataGeneral['cPersProveedorNombreLargo'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Fecha de Notificación', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(formatDate(dataGeneral['dtCompraNotificacion'], 'yyy-MM-dd HH:mm', 'en'), null, {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Comsumo por día', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(ef.nCompraDetEficienciaPromedioVehiculoTotalDia, 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,false], format: '1.2-2'
                                }),
                            ],
                            [
                                imprimirCelda('Meta', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,true]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(dataGeneral['cTareaCodigo'] + ' - ' + dataGeneral['cTareaNombre'], null, {
                                    style: 'tdSmall', border: [false,false,false,true]
                                }),
                                imprimirCelda('Total Valorización', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(dataGeneral['nParDiaVehValorTotal'], 'cant', {
                                    alignment: 'left', format: '1.2-2', style: 'tdSmall', border: [false,false,false,true],
                                }),
                                imprimirCelda('Estado de Consumo', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(ef.cExcendenteTexto, 'texto', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,true], format: '1.2-2'
                                }),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                }
            );

            const celdasHeaderDetallesConsumo = [
                [
                    imprimirCelda('Nº', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    // imprimirCelda('N° Valorización', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Placa', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Vehículo', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('F.Parte Diario', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Contador', null, {colSpan: 4, style: 'thSmall'}),
                    '',
                    '',
                    '',
                    imprimirCelda('Día / Hora Trab.', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('P.U.', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Operador', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('consumo de combustible', null, {colSpan: 2, style: 'thSmall'}),
                    '',
                    imprimirCelda('N° Vale', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('N° Parte Diario', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                ],
                [
                    '',
                    // '',
                    '',
                    '',
                    '',
                    imprimirCelda('Med. Eficiencia', null, {style: 'thSmall'}),
                    imprimirCelda('Inicio', null, {style: 'thSmall'}),
                    imprimirCelda('Final', null, {style: 'thSmall'}),
                    imprimirCelda('Acumulado', null, {style: 'thSmall'}),
                    '',
                    '',
                    '',
                    imprimirCelda('Cant.', null, {style: 'thSmall'}),
                    imprimirCelda('Descripción.', null, {style: 'thSmall'}),
                    '',
                    '',
                ],
            ];

            const celdasBodyDetallesConsumo = [];

            dataRecibido.filter((data, i) => {
                const iValeNumero = data.iValeNumero ? data.iValeYear+'-'+zeroFill(data.iValeNumero,4) : '';
                celdasBodyDetallesConsumo.push(
                    [
                        imprimirCelda(i + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                        // imprimirCelda(zeroFill(data.iValorizaParDiaVehNumero,4), 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.cVehiculoPlaca, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.cClaseVehiculoNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.dParDiaVehEmision, 'fec', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.cMedEfiVehNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(isNumberRound(data.nParDiaVehInicioEficiencia,0), null, {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(isNumberRound(data.nParDiaVehFinEficiencia,0), null, {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(isNumberRound(data.nParDiaVehCantidadEficiencia,0), null, {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(dataGeneral['cPresentacionCantidadEficienciaNombre'], null, {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(data.nParDiaVehValorItemTotal, 'cant', {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(data.cPersOperadorNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.nParDiaVehCantidadCombustible, 'cant', {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(data.cProductoNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(iValeNumero, null, {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(zeroFill(data.iParDiaVehNumero,4), 'texto', {style: 'tdSmall', alignment: 'center'}),
                    ]
                );
    
            });
            const sumasTotales = sumarObjetos(dataRecibido, [
                'nParDiaVehCantidadEficiencia',
                'nParDiaVehCantidadCombustible',
            ]);
            const consumoPorDia = isNumberRound((sumasTotales.nParDiaVehCantidadCombustible / dataGeneral['iCantidad_partes_diarios_vehiculos']),2)
            const celdasFooterDetallesConsumo = [
                [
                    imprimirCelda('', null, {border: [false, true, true, false], colSpan: 7}),
                    // '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    imprimirCelda('Total', null, {style: 'thSmall'}),
                    imprimirCelda(isNumberRound(sumasTotales.nParDiaVehCantidadEficiencia,0), null, {style: 'tdSmall', bold: true}),
                    imprimirCelda(dataGeneral['iCantidad_partes_diarios_vehiculos'], null, {style: 'tdSmall'}),
                    imprimirCelda(dataGeneral['nParDiaVehValorTotal'], 'cant', {style: 'tdSmall', bold: true, format: '1.2-2'}),
                    imprimirCelda('', null, {border: [true, true, true, false]}),
                    imprimirCelda(sumasTotales.nParDiaVehCantidadCombustible, 'cant', {style: 'tdSmall', bold: true, format: '1.2-2'}),
                    imprimirCelda('CONSUMO X DIA\n'+ consumoPorDia, null, {style: 'tdSmall', bold: true, format: '1.2-2'}),
                    imprimirCelda('', null, {border: [true, true, false, false], colSpan: 2}),
                    '',
                ],
            ];

            const tabla = {
                margin: [5, 7, 5, 0],
                alignment: 'center',
                table: {
                    widths: [10, '*', '*', '*', '*', '*', '*', '*', '*', '*', 80, '*', 50, '*', '*'  ],
                    body: celdasHeaderDetallesConsumo.concat(celdasBodyDetallesConsumo).concat(celdasFooterDetallesConsumo)
                }
            };
            cuerpoPdf.push(tabla);

            const obs = (dataGeneral['cValorizaParDiaVehObs'] == null) ? '': dataGeneral['cValorizaParDiaVehObs']
            cuerpoPdf.push(
                {
                    margin: [25, 3, 25, 5],
                    table: {
                        body: [
                            [
                                imprimirCelda('Obs. '+ obs, 'texto'),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                }
            );
            cuerpoPdf.push(
                {
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                            ],
                            [
                                imprimirCelda('Firma Residente / Responsable', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('Firma Supervisor y/o Jefe Inmediato', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('V.B. Encargado Combustible MPMN', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                    //absolutePosition: {x: 75, y: 480 },
                },

            );
            objThis.finalizarCreacionPdf(cuerpoPdf);

    })
}
export function valorizacionParteDiarioMaquinaAmarrila(objThis: PdfAlmacenService, dataRec, ef) {
    let dataRecibido: any[]
    objThis.queryService.cargar(objThis, ['log.valorizaciones_partes_diarios_vehiculos_detalles_presentacion'], {cCodigoCadena: dataRec['cCodigoCadena']}).then(valRetorno => {
        dataRecibido = valRetorno['log.valorizaciones_partes_diarios_vehiculos_detalles_presentacion'];

            const cuerpoPdf = [];
    
            const dataGeneral = dataRecibido[0];
    
    
            objThis.reiniciarOpciones({
                // marcaDeAgua: mDeAgua,
                orientacion: 'landscape',
                margenesHoja: [50, 80, 50, 40],
                tamanioFuenteExtra: 1, //aumentar +1 para crecer el tamaño de la fuente en tabla  Th y td
                defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                imprimirCelda('NRO', null, {alignment: 'center', style: 'thSmall'}),
                                imprimirCelda('FECHA', null, {alignment: 'center', style: 'thSmall'}),
                            ],

                            [
                                imprimirCelda(zeroFill(dataGeneral['iValorizaParDiaVehNumero'],4), null, {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                                imprimirCelda(dataGeneral['dValorizaParDiaVehEmision'], 'fec', {
                                    fontSize: 9, bold: true, alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable : {
                    stack: [
                        imprimirCelda('VALORIZACIÓN DE PARTE DIARIO', null, {
                            fontSize: 15, bold: true,
                        }),                        

                        imprimirCelda('Desde : '+ dataGeneral['dParDiaVehInicioEmision'] + '   Hasta : ' + dataGeneral['dParDiaVehFinEmision'], null, {
                            fontSize: 13,
                            bold: true,
                        }),                        
                    ],
                    alignment: 'center'
                }
            });
   
            cuerpoPdf.push(
                {
                    margin: [5, 10, 5, 0],
                    alignment: 'left',
                    table: {
                        widths: [70, 'auto', 200,70,10,130,70,'auto','*'],
                        body: [
                            [
                                imprimirCelda('VALORIZACIÓN DE PARTE DIARIO', 'texto', {colSpan:9, bold: true, style: 'thSmall', alignment:'center'}),
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                            ],
                            [
                                imprimirCelda('Vehículo', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cVehiculoPlaca'] + ' - ' + dataGeneral['cClaseVehiculoNombre'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('N° Orden', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(dataGeneral['cCompraNumero'] + ' - [' + dataGeneral['iValorizaParDiaVehOrden'] +']  |  SIAF:'+dataGeneral['iCompraYear']+'-'+dataGeneral['Expediente'] +' \n S/.'+isNumberRound(dataGeneral['nCompraValorTotal'],2), null, {
                                    style: 'tdSmall', border: [false,true,false,false],
                                }),
                                imprimirCelda('Cant. Partes Diarios', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,true,false,false]}),
                                imprimirCelda(dataGeneral['iCantidad_partes_diarios_vehiculos'], 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,true,true,false], format: '1.2-2'
                                }),
                            ],
                            [
                                imprimirCelda('Establecimiento', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cEstablecimientoNombreCorto'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Rango de Fechas', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda('Desde : '+ dataGeneral['dParDiaVehInicioEmision'] + '   Hasta : ' + dataGeneral['dParDiaVehFinEmision'], null, {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,false,false]
                                }),
                                imprimirCelda('Promedio Eficiencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['nCompraDetEficienciaPromedioVehiculo'], 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,false], format: '1.2-2',
                                }),
                            ],
                            [
                                imprimirCelda('Dependencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cDepenNombre'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Tiempo Contratado', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(isNumberRound(dataGeneral['nCompraDetCantidadUsoVehiculo'],2)  + ' - '+ dataGeneral['cPresentacionCantidadEficienciaNombre'], 'texto', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,false,false]
                                }),
                                imprimirCelda('Eficiencia', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(ef.nCompraDetEficienciaPromedioVehiculoTotal, 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,false], format: '1.2-2'
                                }),
                            ],

                            [
                                imprimirCelda('Proveedor', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(dataGeneral['cPersProveedorDocumento']+' - '+dataGeneral['cPersProveedorNombreLargo'], null, {
                                    style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Fecha de Notificación', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(formatDate(dataGeneral['dtCompraNotificacion'], 'yyy-MM-dd HH:mm', 'en'), null, {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,false,false],
                                }),
                                imprimirCelda('Comsumo por día', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,false]}),
                                imprimirCelda(ef.nCompraDetEficienciaPromedioVehiculoTotalDia, 'cant', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,false], format: '1.2-2'
                                }),
                            ],
                            [
                                imprimirCelda('Meta', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [true,false,false,true]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(dataGeneral['cTareaCodigo'] + ' - ' + dataGeneral['cTareaNombre'], null, {
                                    style: 'tdSmall', border: [false,false,false,true]
                                }),
                                imprimirCelda('Total Valorización', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(dataGeneral['nParDiaVehValorTotal'], 'cant', {
                                    alignment: 'left', format: '1.2-2', style: 'tdSmall', border: [false,false,false,true],
                                }),
                                imprimirCelda('Estado de Consumo', null, {alignment: 'right', bold: true, style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(':', null, {style: 'tdSmall', border: [false,false,false,true]}),
                                imprimirCelda(ef.cExcendenteTexto, 'texto', {
                                    alignment: 'left', style: 'tdSmall', border: [false,false,true,true], format: '1.2-2'
                                }),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                }
            );

            const celdasHeaderDetallesConsumo = [
                [
                    imprimirCelda('Nº', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Placa / Serie', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Vehículo', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('F. Parte Diario', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('N° Parte Diario', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('Mañana', null, {colSpan: 3, style: 'thSmall'}),
                    '',
                    '',
                    imprimirCelda('Tarde', null, {colSpan: 3, style: 'thSmall'}),
                    '',
                    '',
                    imprimirCelda('Total Horas', null, {rowSpan: 2, style: 'thSmall'}),
                    imprimirCelda('H.M.', null, {rowSpan: 2, style: 'thSmall'}),
                    imprimirCelda('Día / Hora Trab.', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('P.U.', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                    imprimirCelda('consumo de combustible', null, {colSpan: 2, style: 'thSmall'}),
                    '',
                    imprimirCelda('Sub Total', null, {rowSpan: 2, style: 'thSmall', valign: 'middle'}),
                ],
                [
                    '',
                    '',
                    '',
                    '',
                    '',
                    imprimirCelda('Entrada', null, {style: 'thSmall'}),
                    imprimirCelda('Salida', null, {style: 'thSmall'}),
                    imprimirCelda('Sub Total', null, {style: 'thSmall'}),
                    imprimirCelda('Entrada', null, {style: 'thSmall'}),
                    imprimirCelda('Salida', null, {style: 'thSmall'}),
                    imprimirCelda('Sub Total', null, {style: 'thSmall'}),
                    '',
                    '',
                    '',
                    '',
                    imprimirCelda('Cant.', null, {style: 'thSmall'}),
                    imprimirCelda('Descripción.', null, {style: 'thSmall'}),
                    '',
                ],
            ];

            const celdasBodyDetallesConsumo = [];

            dataRecibido.filter((data, i) => {
                const iParDiaVehNumeroVale = data.iParDiaVehNumeroVale ? zeroFill(data.iParDiaVehNumeroVale,4) : '';
                celdasBodyDetallesConsumo.push(
                    [
                        imprimirCelda(i + 1, 'number', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.cVehiculoPlaca, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.cClaseVehiculoNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.dParDiaVehEmision, 'fec', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(zeroFill(data.iParDiaVehNumero,4), 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(data.cParDiaVehTomorrowIngreso, null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(data.cParDiaVehTomorrowSalida, null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(operacionesHoras(data.cParDiaVehTomorrowIngreso,data.cParDiaVehTomorrowSalida,'restar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(data.cParDiaVehLateIngreso, null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(data.cParDiaVehLateSalida, null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(operacionesHoras(data.cParDiaVehLateIngreso,data.cParDiaVehLateSalida,'restar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(operacionesHoras(operacionesHoras(data.cParDiaVehLateIngreso,data.cParDiaVehLateSalida,'restar'),operacionesHoras(data.cParDiaVehTomorrowIngreso,data.cParDiaVehTomorrowSalida,'restar'),'sumar'), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(isNumberRound(data.nParDiaVehCantidadHora,2), null, {alignment: 'center', bold: true, style: 'tdSmall'}),
                        imprimirCelda(dataGeneral['cPresentacionCantidadEficienciaNombre'], null, {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(isNumberRound(data.nParDiaVehValorItemUnitario, 2), null, {style: 'tdSmall', alignment: 'center', format: '1.4-4'}),
                        imprimirCelda(data.nParDiaVehCantidadCombustible, 'cant', {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                        imprimirCelda(data.cProductoNombre, 'texto', {style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(isNumberRound(data.nParDiaVehValorItemTotal,2), null, {style: 'tdSmall', alignment: 'center', format: '1.2-2'}),
                    ]
                );
    
            });
            const sumasTotales = sumarObjetos(dataRecibido, [
                'nParDiaVehCantidadEficiencia',
                'nParDiaVehCantidadCombustible',
                'nParDiaVehCantidadHora',
                'nParDiaVehValorItemTotal',
            ]);
            const consumoPorDia = isNumberRound((sumasTotales.nParDiaVehCantidadCombustible / dataGeneral['iCantidad_partes_diarios_vehiculos']),2)
            const celdasFooterDetallesConsumo = [
                [
                    imprimirCelda('', null, {border: [false, true, true, false], colSpan: 11}),
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    imprimirCelda('Total Horas', null, {style: 'thSmall'}),
                    imprimirCelda(isNumberRound(sumasTotales.nParDiaVehCantidadHora,2), null, {style: 'tdSmall', bold: true, format: '1.2-2'}),
                    imprimirCelda('', null, {border: [true, true, true, false]}),
                    imprimirCelda('Total Combustible', null, {style: 'thSmall'}),
                    imprimirCelda(sumasTotales.nParDiaVehCantidadCombustible, 'cant', {style: 'tdSmall', bold: true, format: '1.2-2'}),
                    imprimirCelda('Total', null, {style: 'thSmall'}),
                    imprimirCelda(isNumberRound(sumasTotales.nParDiaVehValorItemTotal,2), null, {style: 'tdSmall', bold: true, format: '1.2-2'}),
                ],
            ];

            const tabla = {
                margin: [5, 7, 5, 0],
                alignment: 'center',
                table: {
                    widths: [10, 23, 45, 33, 22, 22, 22, 22, 22,22, 22, 22, 22, 40, '*', '*', 50, 40  ],
                    body: celdasHeaderDetallesConsumo.concat(celdasBodyDetallesConsumo).concat(celdasFooterDetallesConsumo)
                }
            };
            cuerpoPdf.push(tabla);

            const obs = (dataGeneral['cValorizaParDiaVehObs'] == null) ? '': dataGeneral['cValorizaParDiaVehObs']
            cuerpoPdf.push(
                {
                    margin: [25, 3, 25, 5],
                    table: {
                        body: [
                            [
                                imprimirCelda('Obs. '+ obs, 'texto'),
                            ],
    
                        ]
                    },
                    layout: 'noBorders',
                }
            );
            cuerpoPdf.push(
                {
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                imprimirCelda('\n\n\n\n', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                                imprimirCelda('', null, {style: 'tdSmall', fontSize: 10}),
                            ],
                            [
                                imprimirCelda('Firma Residente / Responsable', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('Firma Supervisor y/o Jefe Inmediato', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                                imprimirCelda('V.B. Encargado Combustible MPMN', null, {style: 'tdSmall', bold: true, alignment: 'center', fontSize: 8}),
                            ],
                        ],
                    },
                    // layout: 'noBorders',
                    // absolutePosition: {x: 75, y: 480 },
                },

            );
            objThis.finalizarCreacionPdf(cuerpoPdf);

    })
}

export function kardex(objThis: PdfAlmacenService, dataRec) {
    console.log(dataRec);

    objThis.queryService.cargar(objThis, ['log.kardex_combustible_presentacion'], {iCompraDetId: dataRec['iCompraDetId']}).then(valRetorno => {
        const dataRecibido: any[] = valRetorno['log.kardex_combustible_presentacion'];

        const cuerpoPdf = [];

        const dataGeneral = dataRecibido[0];

        const dTitulos: ColumnaTabla<any>[] = [
            {label: '#', property: 'numeracion_automatica', type: 'custom', ocultarReporte: true},
            {label: 'Nº Vale', property: 'iValeNumero', type: 'text', cssClasses: ['text-center'], textArray: ['iValeYear', '*-', 'iValeNumero'],
                transformar:(item) => {
                    return item ? (item.iValeYear + ' - ' + zeroFill(item.iValeNumero,4)):'';
                } 
            },
            { label: 'Fecha', property: 'dValeEmision', type: 'date', cssClasses: ['font-bold'] },
            { label: 'Operador', property: 'cPersOperadorNombre', type: 'text',  },
            { label: 'Motivo', property: 'cMotValeNombre', type: 'text',  },
            { label: 'Vehículo', property: 'cVehiculoPlaca', type: 'text',  },
            {label: 'Órden', property: 'cCompraNumero', type: 'text', cssClasses: ['underline text-blue-600 hover:text-rose-900 visited:text-rose-700']},
            { label: 'U.M.', property: 'cPresentacionNombre', type: 'text', cssClasses: ['text-center'],},
            { label: 'Combustible', property: 'cProductoNombre', type: 'text',  },
            { label: 'Proveedor', property: 'cPersProveedorNombreLargo', type: 'text',  },
            // { label: 'Motivo', property: 'cMotValeNombre', type: 'text',  },

            { label: 'Saldo Anterior', property: 'nSaldoAnterior', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
            { label: 'Salida', property: 'nSalida', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
            { label: 'Saldo', property: 'nSaldoActual', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },

            // { label: 'Costo<br>Unitario', property: 'nParDiaVehValorItemUnitario', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },

            // { label: 'Valor<br>Unitario', property: 'nValorUnitario', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
            // { label: 'Valor<br>Total', property: 'nValorTotal', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        ];
        const dData = dataRecibido;


        objThis.reiniciarOpciones({
            margenesHoja: [50, 100, 50, 40],
            orientacion: 'landscape',
            tamanioFuenteExtra: 3,
            customDefTableHeader: objThis.headerTableEntidad([
                imprimirCelda('KARDEX DE PRODUCTO\n' + '[' + dataGeneral['cCodigoProducto'] + '] ' + dataGeneral['cProductoNombre'] + '\n(' + dataGeneral['cPresentacionNombre'] + ')', null, {fontSize: 13, bold: true}),
            ], [100, '*', 100], [0, 20, 0, 0]),
        });

        const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
            titulos: dTitulos,
            contenido: dData,
            numeracion: true,
            idxResto: [1],
            margin: [0, 5, 0, 5],
            separado: true,
        });



        const tablaTitulos = [
            [
                imprimirCelda('\nNº', null, {
                    style: 'thSmall', rowSpan: 2, alignment: 'center',
                }),
                imprimirCelda('\nFecha', null, {
                    style: 'thSmall', rowSpan: 2, alignment: 'center',
                }),
                imprimirCelda('\nDocumento', null, {
                    style: 'thSmall', rowSpan: 2, alignment: 'center',
                }),
                imprimirCelda('\nProveedor', null, {
                    style: 'thSmall', rowSpan: 2, alignment: 'center',
                }),
                imprimirCelda('Entradas', null, {
                    style: 'thSmall', colSpan: 3, alignment: 'center',
                }),
                {},
                {},
                imprimirCelda('Salidas', null, {
                    style: 'thSmall', colSpan: 3, alignment: 'center',
                }),
                {},
                {},
                imprimirCelda('Saldo Final', null, {
                    style: 'thSmall', colSpan: 3, alignment: 'center',
                }),
                {},
                {},
            ],
        ];

        cuerpoPdf.push(
            {
                margin: [0, 0, 0, 10],
                table: {
                    dontBreakRows: true,
                    // headerRows: 2,
                    widths: objThis.anchoCols(tablaGeneradaSoloTitulos['cuerpo'], [3,5],[{ idx:1, valor:60}]),
                    // widths: objThis.anchoCols(tablaTitulos, [3]),
                    // body: tablaTitulos.concat(tablaGeneradaSoloTitulos['titulos']).concat(tablaGeneradaSoloTitulos['cuerpo']),
                    body: tablaGeneradaSoloTitulos['titulos'].concat(tablaGeneradaSoloTitulos['cuerpo']),
                },
            },
            // imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true})
        );
        /*
        cuerpoPdf.push({
            columns: [
                {width: '*', text: ''},
                {
                    width: 'auto',
                    table: {
                        body: [
                            [
                                imprimirCelda('STOCK', null, {
                                    bold: true,
                                    style: 'thSmall',
                                    alignment: 'right',
                                    fontSize: 9,
                                }),
                                imprimirCelda(dataRec['nStock_productos'], 'cant', {
                                    bold: true,
                                    style: 'tdSmall',
                                    fontSize: 9,
                                })
                            ],
                            [
                                imprimirCelda('COSTO TOTAL', null, {
                                    bold: true,
                                    style: 'thSmall',
                                    alignment: 'right',
                                    fontSize: 9,
                                }),
                                imprimirCelda(dataRec['nCostoTotal'], 'mon', {
                                    bold: true,
                                    style: 'tdSmall',
                                    fontSize: 9,
                                })
                            ]
                        ],
                    },
                },
                {width: '*', text: ''},
            ],
        });
        */

        // cuerpoPdf.push(tablaGeneradaSoloTitulos);
        objThis.finalizarCreacionPdf(cuerpoPdf);

    });
    return 1;


}
