import {AfterViewInit, ContentChildren, Directive, ElementRef, HostListener, QueryList} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[next-tab]'
})
export class DesplazarPorTecladoDirective {
  self: any;
  next: any;
  prev: any;

  @HostListener("keydown.arrowup", ["$event"])
  onUp(event: KeyboardEvent) {
    console.log('UP', event);
    if (this.prev && this.prev.focus) {
      this.prev.focus();
      this.prev.select();
      event.preventDefault();
      return false;
    }
  }

  @HostListener("keydown.arrowdown", ["$event"])
  onDown(event: KeyboardEvent) {
    console.log('DOWN', event);
    if (this.next && this.next.focus) {
      console.log(this.next);
      this.next.focus();
      this.next.select();
      event.preventDefault();
      return false;
    }
  }

  constructor(private control: ElementRef) {
    this.self = control.nativeElement;
  }
}

@Directive({
  selector: "NgControl"
})
export class ControlDirective {
  self: any;
  next: any;
  prev: any;

  @HostListener("keydown.arrowup", ["$event"])
  onUp(event: KeyboardEvent) {
    if (this.prev && this.prev.focus) {
      this.prev.focus();
      this.prev.select();
      event.preventDefault();
      return false;
    }
  }

  @HostListener("keydown.arrowdown", ["$event"])
  onDown(event: KeyboardEvent) {
    if (this.next && this.next.focus) {
      this.next.focus();
      this.next.select();
      event.preventDefault();
      return false;
    }
  }

  constructor(private control: ElementRef,private ngControl:NgControl) {
    this.self = control.nativeElement;
  }
}


@Directive({
  selector: "[mannage-arrow]"
})
export class ManageArrowDirective implements AfterViewInit {
  @ContentChildren(DesplazarPorTecladoDirective) controls: QueryList<DesplazarPorTecladoDirective>;

  ngAfterViewInit() {
    const controls=this.controls.toArray()
    controls.forEach((x, index) => {
      x.prev = index ? controls[index - 1].self : null;
      x.next =
          index < controls.length - 1 ? controls[index + 1].self : null;
    });
    this.controls.changes.subscribe(ctrls => {
      const controls=ctrls.toArray();
      controls.forEach((x, index) => {
        x.prev = index ? controls[index - 1].self : null;
        x.next =
            index < controls.length - 1 ? controls[index + 1].self : null;
      });
    });
  }
}
