<div class="flex flex-col">
    <div *ngIf="opciones.length > 0" class="grow flex items-center justify-between">
        <ng-content select="[izquierdaFiltro]"></ng-content>
        <jvs-filtro-busqueda
                class="w-full flex-grow"
                [objThis]="objThis"
                [nombreColeccion]="nombreColeccion"
                *ngIf="opciones.length > 0"
                [defaultTab]="defaultTab"
                [prefijoConsulta]="prefijoConsulta"
                [opciones]="opciones"
                [busquedaAutomatica]="busquedaAutomatica"
                [mostrarBotonFiltro]="mostrarBotonFiltro"
                (resultados)="resultadosFILTRO($event);"
                (dataFiltro)="dataFiltroFILTRO($event)"
        >
        </jvs-filtro-busqueda>
        <ng-content select="[derechaFiltro]"></ng-content>
    </div>

    <div class="flex flex-col">
        <div class="flex-1 bg-app-bar flex items-center justify-between p-1" [formGroup]="frmFiltro">
            <!--        <div class="hidden flex-1 sm:flex items-center flex-wrap gap-1">-->
            <!--        </div>-->
            <div class="flex items-center flex-wrap mr-1 h-full" *ngIf="leyenda">
                <span class="font-bold" [class]="leyenda.class">{{ leyenda.text }}</span>
            </div>
            <div class="hidden flex-1 sm:flex items-center flex-wrap gap-1">
                <div *ngIf="!readOnly && listaMenuIzquierda.length > 0 " class=" flex items-center flex-wrap">
                    <ng-container *ngFor="let btn of listaMenuIzquierda; let idx = index;">
                        <ng-container *ngTemplateOutlet="botonesSuperiores; context:{btn: btn, idx: {inicio: idx, fin: listaMenuIzquierda.length}}"></ng-container>
                    </ng-container>
                </div>

                <div *ngIf="!readOnly && listaCRUD.length > 0" class=" flex items-center flex-wrap">
                    <ng-container *ngFor="let btn of listaCRUD; let idx = index;">
                        <ng-container *ngTemplateOutlet="botonesSuperiores; context:{btn: btn, idx: {inicio: idx, fin: listaCRUD.length}}"></ng-container>
                    </ng-container>
                </div>

                <div *ngIf="!readOnly && listaMenu.length > 0" class=" flex items-center flex-wrap">
                    <ng-container *ngFor="let btn of listaMenu; let idx = index;">
                        <ng-container *ngTemplateOutlet="botonesSuperiores; context:{btn: btn, idx: {inicio: idx, fin: listaMenu.length}}"></ng-container>
                    </ng-container>
                </div>

                <div *ngIf="!readOnly && listaMenuDerecha.length > 0" class="flex-auto flex items-center justify-end">
                    <ng-container *ngFor="let btn of listaMenuDerecha; let idx = index;">
                        <ng-container *ngTemplateOutlet="botonesSuperiores; context:{btn: btn, idx: {inicio: idx, fin: listaMenuDerecha.length}}"></ng-container>
                    </ng-container>
                </div>
                <ng-content select="[objetosMenuPegado]"></ng-content>
            </div>
            <div class="flex-none flex items-center justify-end border-l-2">
                <div class="flex-1 sm:flex-none flex items-center justify-end">
                    <ng-content select="[objetosMenu]"></ng-content>
                </div>
            </div>
            <div *ngIf="ctrlBusqueda" class="hidden flex-initial sm:flex items-center form-input  max-w-[150px] bg-card rounded-full border m-1 px-1 border-l-2"
            >
                <mat-icon svgIcon="mat:search" class="icon-xs"></mat-icon>
                <input formControlName="cCampoBusqueda"
                       class="text-xs px-1 py-1 border-0 outline-none w-full bg-transparent max-w-sm"
                       placeholder="Buscar..."
                       (keyup.enter)="ctrlBusqueda == 'query' ? cargarData() : false;"
                       type="search">
            </div>
            <div class="flex-none flex items-center justify-end border-l-2">
                <ng-content select="[botonesFiltro]"></ng-content>
                <button matRipple *ngIf="isRecargarUsed || ctrlBusqueda == 'query'"
                        class="flex items-center justify-center text-2xs leading-none rounded-full p-1 text-green-700"
                        matTooltip="Actualizar datos"
                        (click)="(ctrlBusqueda == 'query' ? cargarData() : emitirAccionRecargar())"
                        type="button">
                    <mat-icon svgIcon="mat:refresh" class="icon-xs"></mat-icon>

                </button>
                <button matRipple [matMenuTriggerFor]="columnFilterMenu" *ngIf="filtroCampos"
                        class="flex items-center justify-center text-2xs leading-none rounded-full p-1"
                        matTooltip="Columnas Filtro"
                        type="button">
                    <mat-icon svgIcon="mat:filter_list" class="icon-xs"></mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="listaMenuSecundario.length > 0" class="flex-1 bg-app-bar flex items-center justify-between p-1 border-t">
            <div class="hidden flex-1 sm:flex items-center flex-wrap gap-1">
                <div *ngIf="!readOnly && listaMenuSecundario.length > 0" class=" flex items-center flex-wrap">
                    <ng-container *ngFor="let btn of listaMenuSecundario; let idx = index;">
                        <ng-container *ngTemplateOutlet="botonesSuperiores; context:{btn: btn, idx: {inicio: idx, fin: listaMenuSecundario.length}}"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="grow flex flex-col items-stretch contenedor-tabla" [ngClass]="{'table-container overflow-auto': esTabla}">
            <ng-content select="[cuerpo]"></ng-content>
            <table [id]="'tabla_' + nombreColeccion" @stagger [dataSource]="dataSource" multiTemplateDataRows
                   [hidden]="!esTabla" matTableExporter #exporter="matTableExporter"
                [trackBy]="trackByFn.bind(this)"
                   class="flex-1 table-mantenimiento table-auto h-fit" mat-table matSort
                   #tablaMantenimiento
            >

                <!--<table [dataSource]="dataSource" class="table-mantenimiento table-auto h-auto w-full justify-center" mat-table matSort>-->


                <ng-content select="[tableDefinitions]"></ng-content>
                <ng-container matColumnDef="fotografia">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex" style="text-align: center; font-weight: bold;">
                        <img *ngIf="element.iPersId" [src]="servidorService.photoThumbURL + element.iPersId + '?ancho=150&alto=150'" alt="Foto" class="avatar ltr:mr-3 rtl:ml-3"/>
                    </td>
                    <td mat-footer-cell *matFooterCellDef class="uppercase"></td>
                </ng-container>
                <ng-container matColumnDef="numeracion_automatica">
                    <th mat-header-cell *matHeaderCellDef style="width: 16px !important">Nº</th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex" class="p-0 celda-numeracion-fila">
                        <div class="flex items-center justify-center font-bold numeracionFila">
                            <ng-container *ngIf="esTabla && paginador">
                                {{ ( this.paginator?.pageIndex == 0 ? i + 1 : 1 + i + this.paginator?.pageIndex * this.paginator?.pageSize ) }}
                            </ng-container>
                            <ng-container *ngIf="!paginador">
                                {{ (i +1) }}
                            </ng-container>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef class="uppercase"></td>
                </ng-container>
                <general-mat-table-default-columns-defs2 [objThis]="this" [colDetalle]="columnasTabla" [nombreColeccion]="nombreColeccion"></general-mat-table-default-columns-defs2>

                <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row class="title" matHeader
                ></tr>

                <ng-container *ngIf="!!filaExtraHeader">
                    <tr mat-row *matRowDef="let row; columns: ['filaExtraHeader']" class="student-detail-row"></tr>

                    <ng-container matColumnDef="filaExtraHeader">
                        <td mat-cell *matCellDef="let row; let i = dataIndex" [attr.colspan]="visibleColumns.length">

                            <div class="row m-0 student-element-detail" [@detailExpand]="(i == 0 && filaExtraHeader.esVisible && filaExtraHeader.esVisible()) ? 'expanded' : 'collapsed'"
                                [style.padding-right.px]="row.isExpanded ? 5 : 0"
                                [style.padding-left.px]="row.isExpanded ? 5 : 0"
                            >
                                <ng-container *ngIf="filaExtraHeader.template" [ngTemplateOutlet]="filaExtraHeader.template" [ngTemplateOutletContext]="{ row: row }"></ng-container>
                            </div>

                        </td>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="filaFooter">
                    <tr *matFooterRowDef="visibleColumns" mat-footer-row
                        [id]="nombreColeccion + '_filaFooter'"
                    ></tr>
                </ng-container>

                <!--	<tr
                        *matRowDef="let row; columns: visibleColumns;"
                        class="hover:bg-secondary trans-ease-out cursor-pointer"
                        mat-row></tr>-->
                <tr
                        [matRowKeyboardSelection]="tablaMantenimiento"
                        [rowModel]="row"
                        (seleccionarSiguiente)="opcMenu($event, {seccion: nombreColeccion, tipo: 'ver'}); setClickedRow($event.uniqid)"

                        *matRowDef="let row; columns: visibleColumns;"
                        (click)="opcMenu(row, {seccion: nombreColeccion, tipo: 'ver'}); setClickedRow(row.uniqid)"
                        (dblclick)="opcMenu(row, {seccion: nombreColeccion, tipo: 'seleccionar'}); dblclickItem.emit(row)"
                        (contextmenu)="(readOnly ? '' : abrirMenuContextual($event, row)); $event. preventDefault();"
                        [ngClass]="classFila(row)"
                        @fadeInUp
                        class="trans-ease-out cursor-pointer h-auto"
                        [id]="nombreColeccion + '_' + idTablaValor(row)"
                        [matTooltip]="row[campoAnuladoMensaje]"
                        matTooltipPosition="below"
                        matTooltipClass="bg-red-700 text-red-100 m-0"
                        [matTooltipDisabled]="!campoAnuladoMensaje || !row[campoAnuladoMensaje]"
                        [class.regAnulado]="row[campoAnulado] == 1"
                        mat-row></tr>
                <ng-container *ngIf="filaExtraTemplate">
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let row" [attr.colspan]="visibleColumns.length">

                            <div class="row m-0 student-element-detail" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'"
                                 [style.padding-right.px]="row.isExpanded ? 5 : 0"
                                 [style.padding-left.px]="row.isExpanded ? 5 : 0"
                            >
                                <ng-container [ngTemplateOutlet]="filaExtraTemplate" [ngTemplateOutletContext]="{ row: row }"></ng-container>
                            </div>

                        </td>
                    </ng-container>
                </ng-container>
            </table>
            <div *ngIf="esTabla && (noData() | async)" class="flex-1 text-center text-secondary font-medium">
                No se encontraron datos
            </div>
        </div>
        <div class="flex-1 bg-app-bar flex flex-col sm:flex-row items-start justify-between p-1">
            <div class="flex-1 flex items-start flex-wrap gap-1">
                <div *ngIf="!readOnly && listaMenuInferior.length > 0" class=" flex items-center flex-wrap">
                    <ng-container *ngFor="let btn of listaMenuInferior; let idx = index;">
                        <ng-container *ngTemplateOutlet="botonesSuperiores; context:{btn: btn, idx: {inicio: idx, fin: listaMenuInferior.length}}"></ng-container>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="esTabla && paginador" class="flex-1 sm:flex-none flex items-center justify-end">
                <mat-paginator [pageSizeOptions]="paginacion.pageSizeOptions" [pageIndex]="paginacion.pageIndex - 1" [length]="paginacion.pageLength" [pageSize]="paginacion.pageSize" (page)="paginacion.pageCurrent = $event; emitirResultados();" ></mat-paginator>
            </div>
        </div>
    </div>

</div>



<!-- SECCION DE TEMPLATES O MENUS -->


<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <ng-container *ngFor="let column of columnasTabla">
        <button (click)="toggleColumnVisibility(column, $event)" *ngIf="!column.noMostrar || !column.noMostrar()"
                class="checkbox-item mat-menu-item">
            <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" [ngModelOptions]="{standalone: true}" color="primary">
                <span [innerHTML]="(column.labelLista ?? column.label).replace('<br>', ' ')"></span>
            </mat-checkbox>
        </button>
    </ng-container>
</mat-menu>






<ng-template #botonesSuperiores let-btn="btn" let-idx="idx">
    <ng-container *ngTemplateOutlet="botonesContextual; context:{btn: btn, item: objThis['seleccionados'][nombreColeccion], barraSuperior: true, idx: idx}"></ng-container>
</ng-template>

<ng-template #botonesContextual let-btn="btn" let-item="item" let-barraSuperior="barraSuperior" let-idx="idx">

    <ng-container *ngIf="barraSuperior; else noBarraSuperior">

        <!--		<button mat-button style="min-width: unset; border: 1px !important;" class="uppercase border border-gray rounded-none h-full px-1 disabled:opacity-50 disabled:text-secondary disabled:bg-transparent">-->
        <!--			<mat-icon *ngIf="btn.icono" [svgIcon]="btn.icono" size="15px" class="icon-xs"></mat-icon>-->
        <!--		</button>-->
        <ng-container *ngIf="btn.subItems && btn.subItems.length > 0">
            <button matRipple [matRippleUnbounded]="false" class="flex items-center justify-between uppercase text-2xs leading-none rounded-none px-2 py-1 bg-opacity-95 hover:bg-opacity-100 disabled:opacity-50 disabled:text-secondary disabled:!bg-gray-300 dark:disabled:!bg-gray-500 mat-elevation-z2" type="button"
                    [class.rounded-l]="idx.inicio == 0"
                    [class.rounded-r]="idx.inicio == (idx.fin - 1)"
                    *ngIf="(!btn.esVisible || btn.esVisible(item)) && subItemsActivos(btn, item)"
                    [class]="(btn.class?btn.class:'')"

                    (click)="opcMenu(item, btn)"
                    [style.min-width]="(btn.soloIcono ? 'unset' : '')"
                    [matTooltip]="btn.tooltip || (btn.label ? btn.label : ( (derechosActuales && derechosActuales[btn.cDerechoCodigo ?? btn.tipo]) ? derechosActuales[btn.cDerechoCodigo ?? btn.tipo]['cDerechoNombre'] : funcionesGlobalesService.capitalizarTexto(btn.tipo))) "
                    [matMenuTriggerFor]="menuOtrosBarra.menu"

                    [matBadge]="btn.badge"
                    matBadgeSize="small"
            >
                <mat-icon class=" icon-xs" *ngIf="btn.icono" [svgIcon]="btn.icono" ></mat-icon>
                <span class="whitespace-nowrap" *ngIf="!btn.soloIcono">{{ btn.label ? btn.label : ( (derechosActuales && derechosActuales[btn.cDerechoCodigo ?? btn.tipo]) ? derechosActuales[btn.cDerechoCodigo ?? btn.tipo]['cDerechoNombre'] : funcionesGlobalesService.capitalizarTexto(btn.tipo)) }}</span>
                <mat-icon class="icon-xs" svgIcon="fa5sCaretDown" ></mat-icon>
            </button>

            <general-tabla-mantenimiento-nuevo-menu #menuOtrosBarra
                                                         [objThis]="objThis"
                                                         [nombreColeccion]="nombreColeccion"
                                                         [item]="item"
                                                         [derechosActuales]="derechosActuales"
                                                         [subItems]="btn.subItems"
                                                         [botonDisabled]="botonDisabled"
                                                         (opcionSelecionada)="opcMenu($event.item, $event.btn)"
                                                         [botonTemplate]="botonesContextual"
            >
            </general-tabla-mantenimiento-nuevo-menu>
        </ng-container>
        <ng-container *ngIf="!btn.subItems || btn.subItems.length == 0">
            <button class="flex items-center justify-between uppercase text-2xs leading-none rounded-none px-2 py-1 bg-opacity-95 hover:bg-opacity-100 disabled:opacity-50 disabled:text-secondary disabled:!bg-gray-300 dark:disabled:!bg-gray-500 mat-elevation-z2" type="button"
                    [class.rounded-l]="idx.inicio == 0"
                    [class.rounded-r]="idx.inicio == (idx.fin - 1)"
                    *ngIf="!btn.esVisible || btn.esVisible(item)"
                    [class]="(btn.class?btn.class:'text-secondary')"
                    [ngClass]="{'text-secondary italic': !ignorarDerechos && !btn.esIndependiente && (!btn.ignorarDerecho && (!derechosActuales || !derechosActuales[btn.cDerechoCodigo ?? btn.tipo])) && !(['nuevo', 'editar', 'eliminar'].includes(btn.tipo))}"
                    [disabled]="!btn.esIndependiente && botonDisabled(btn, item)"
                    (click)="opcMenu(item, btn)"
                    [style.min-width]="(btn.soloIcono ? 'unset' : '')"
                    [matTooltip]="btn.tooltip || (btn.label ? btn.label : ( (derechosActuales && derechosActuales[btn.cDerechoCodigo ?? btn.tipo]) ? derechosActuales[btn.cDerechoCodigo ?? btn.tipo]['cDerechoNombre'] : funcionesGlobalesService.capitalizarTexto(btn.tipo))) "

                    [matBadge]="btn.badge"
                    matBadgeSize="small"
            >
                <mat-icon class=" icon-xs" *ngIf="btn.icono" [svgIcon]="btn.icono"></mat-icon>
                <span class="whitespace-nowrap" *ngIf="!btn.soloIcono">{{ btn.label ? btn.label : ( (derechosActuales && derechosActuales[btn.cDerechoCodigo ?? btn.tipo]) ? derechosActuales[btn.cDerechoCodigo ?? btn.tipo]['cDerechoNombre'] : funcionesGlobalesService.capitalizarTexto(btn.tipo)) }}</span>
            </button>

        </ng-container>

    </ng-container>
    <ng-template #noBarraSuperior>

        <ng-container *ngIf="btn.subItems && btn.subItems.length > 0">
            <button class="flex items-center justify-between uppercase w-full rounded-none px-2 disabled:opacity-50 disabled:text-secondary disabled:bg-transparent" mat-menu-item type="button"
                    *ngIf="!btn.noContextual && (!btn.esVisible || btn.esVisible(item))"
                    [class]="(btn.class?btn.class.replace('text-white', '').replace('bg', 'text'):'text-secondary')"
                    [ngClass]="{'text-secondary italic': !ignorarDerechos && !btn.esIndependiente && (!btn.ignorarDerecho && (!derechosActuales || !derechosActuales[btn.cDerechoCodigo ?? btn.tipo]))}"
                    [disabled]="!btn.esIndependiente && botonDisabled(btn, item)"
                    (click)="opcMenu(item, btn); $event.stopPropagation();"
                    [matMenuTriggerFor]="menuOtrosBarra.menu"
            >
                <div class="flex w-full items-center justify-between uppercase text-2xs"
                >
                    <mat-icon *ngIf="btn.icono" [svgIcon]="btn.icono"
                              class="flex-none icon-xs"
                    ></mat-icon>
                    <span class="grow text-2xs">{{ btn.label ? btn.label : ( (derechosActuales && derechosActuales[btn.cDerechoCodigo ?? btn.tipo]) ? derechosActuales[btn.cDerechoCodigo ?? btn.tipo]['cDerechoNombre'] : funcionesGlobalesService.capitalizarTexto(btn.tipo)) }}</span>
                    <mat-icon class="flex-none icon-xs" svgIcon="fa5sCaretRight"></mat-icon>
                </div>
            </button>

            <general-tabla-mantenimiento-nuevo-menu #menuOtrosBarra
                                                         [objThis]="objThis"
                                                         [nombreColeccion]="nombreColeccion"
                                                         [item]="item"
                                                         [derechosActuales]="derechosActuales"
                                                         [subItems]="btn.subItems"
                                                         [botonDisabled]="botonDisabled"
                                                         (opcionSelecionada)="opcMenu($event.item, $event.btn)"
                                                         [botonTemplate]="botonesContextual"
            >
            </general-tabla-mantenimiento-nuevo-menu>
        </ng-container>
        <ng-container *ngIf="!btn.subItems || btn.subItems.length == 0">
            <ng-container *ngIf="btn.tipo == '-#SEPARADOR#-'; else itemsNormales">
                <mat-divider></mat-divider>
            </ng-container>
            <ng-template #itemsNormales>
                <button class="flex items-center justify-between uppercase w-full rounded-none px-2 disabled:opacity-50 disabled:text-secondary disabled:bg-transparent" mat-menu-item type="button"
                        *ngIf="!btn.noContextual && (!btn.esVisible || btn.esVisible(item))"
                        [class]="(btn.class?btn.class.replace('text-white', '').replace('bg', 'text'):'text-secondary')"
                        [ngClass]="{'text-secondary italic': !ignorarDerechos && !btn.esIndependiente && (!btn.ignorarDerecho && (!derechosActuales || !derechosActuales[btn.cDerechoCodigo ?? btn.tipo]))}"
                        [disabled]="!btn.esIndependiente && botonDisabled(btn, item)"
                        (click)="opcMenu(item, btn)"
                >
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                    >
                        <mat-icon *ngIf="btn.icono" [svgIcon]="btn.icono" class="flex-none icon-xs"
                        ></mat-icon>
                        <span class="grow text-2xs">{{ btn.label ? btn.label : ( (derechosActuales && derechosActuales[btn.cDerechoCodigo ?? btn.tipo]) ? derechosActuales[btn.cDerechoCodigo ?? btn.tipo]['cDerechoNombre'] : funcionesGlobalesService.capitalizarTexto(btn.tipo)) }}</span>
                    </div>
                </button>
            </ng-template>

        </ng-container>

    </ng-template>

</ng-template>

<ng-template #userMenu let-item="item">
    <div class="mat-menu bg-white rounded mat-elevation-z8 shadow botonesContextual">
        <ng-container *ngFor="let btn of listaMenuCompleto">
            <ng-container *ngTemplateOutlet="botonesContextual; context:{btn: btn, item: objThis['seleccionados'][nombreColeccion], barraSuperior: false}"></ng-container>
        </ng-container>
    </div>
</ng-template>

<!--<global-menu #menuContextualComponent [listaMenu]="listaMenuContextual ?? []"></global-menu>-->
