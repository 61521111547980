import {AfterViewInit, Component, ElementRef, EventEmitter, Host, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, SkipSelf, ViewChild} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlContainer, ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import {DataServidor} from '@JVSoft/interfaces/global';
import {Subject} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {MatDialog} from '@angular/material/dialog';
import {ErrorStateMatcher} from '@angular/material/core';
import {QueryGeneralService} from '../../services/query-general.service';
import {take} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'general-mat-select-year',
    templateUrl: './mat-select-year.component.html',
    styleUrls: ['./mat-select-year.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MatSelectYearComponent,
        },
    ],
})
export class MatSelectYearComponent implements OnInit, AfterViewInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {

    configPrefijos = [
        {prefijo: 'grl', reqPath: 'grl.years', campoId: 'iYearId', campoValue: 'iYearId'},
        {prefijo: 'log', reqPath: 'log.years_pedidos', campoId: 'iPedidoYear', campoValue: 'iPedidoYear'},
    ]
    _prefijo: 'grl' | 'log' = 'grl';

    get prefijo(): 'grl' | 'log' {
        return this._prefijo;
    }

    @Input() set prefijo(val: 'grl' | 'log') {
        this._prefijo = val;
    }
    get prefijoData() {
        return  this.configPrefijos.find(item => item.prefijo == this.prefijo);
    }

    get sinContenedor(): boolean {
        return this._sinContenedor;
    }

    @Input() set sinContenedor(val: boolean) {
        this._sinContenedor = val || true;
    }

    @Input()
    set value(value: any) {
        // this.formPrincipal.patchValue(value);
        this._value = value;
        this.stateChanges.next();
    }

    get value() {
        return this._value;
        // return this.formPrincipal.value;
    }

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    get placeholder() {
        return this._placeholder;
    }

    get empty(): boolean {
        return !this._value && !this.formPrincipal.get(this.idSeleccion).value;
    }

    @HostBinding()
    get shouldLabelFloat(): boolean {
        // return false;
        // return this.seleccionarTipo;
        return this.focused || !this.empty || this.formPrincipal.get('iYearId').value;
    }

    get errorState(): boolean {
        return this.errorStateMatcher.isErrorState(this.ngControl?.control, null);
    }
    constructor(
        private focusMonitor: FocusMonitor,
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        @Optional() @Self() public ngControl: NgControl,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private errorStateMatcher: ErrorStateMatcher,
        private queryGeneralService: QueryGeneralService,
    ) {
        if (ngControl != null) {
            ngControl.valueAccessor = this;
        }
        if (this.controlContainer) {
            console.log(controlContainer);
        }
        else {
            console.warn('Can\'t find parent FormGroup directive');
        }

        this.cargaInicial();
    }

    static nextId = 0;
    @Input() readonly = false;
    @Input() idSeleccion: string;

    @Input() set formControlName(val: string) {
        this.idSeleccion = val;
    }


    private _sinContenedor: boolean;

    @Output() resultados = new EventEmitter<any>();

    dataServidorSuscripcion: DataServidor = {};

    @ViewChild(MatSelect, {read: ElementRef, static: true})
    input: ElementRef;

    private _value: any;

    private _placeholder: string;

    focused: boolean;

    @HostBinding()
    readonly id = `general-mat-select-year-id-${MatSelectYearComponent.nextId++}`;

    @HostBinding('attr.aria-describedby')
    userAriaDescribedBy = '';

    @Input()
    required: boolean;
    @Input()
    disabled: boolean;

    controlType = 'general-mat-select-year';
    stateChanges = new Subject<void>();

    onChange: (value: any) => {};
    onTouched: () => {};

    formPrincipal: FormGroup = this.fb.group({
        iYearId: [''],
        iYearId2: [''],
    });

    readonly autofilled: boolean;

    // readonly stateChanges = new Subject<void>();
    //
    // readonly autofilled: boolean;
    //
    //
    // readonly ngControl: NgControl | AbstractControlDirective | null = null;
    //
    //

    ngOnInit(): void {
        this.focusMonitor.monitor(this.input).subscribe((focused) => {
            this.focused = !!focused;
            this.stateChanges.next();
        });
        this.focusMonitor.monitor(this.input).pipe(take(1)).subscribe((focused) => {
            this.onTouched();
        });
        this.formPrincipal.valueChanges.subscribe((value) => {
            console.log(value);
            if (value && value[this.idSeleccion]) {
                this.onChange(value[this.idSeleccion]);
            }
            else {
                if (value[this.idSeleccion] === null) {
                    this.onChange('');
                }
            }
        });
        console.log(this._value);
    }

    ngAfterViewInit() {
        if (this.sinContenedor) {
            this.input.nativeElement.classList.remove('mat-input-element');
        }
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.input);
        this.stateChanges.complete();
    }

    onContainerClick(event: MouseEvent): void {
        this.focusMonitor.focusVia(this.input, 'program');
    }

    setDescribedByIds(ids: string[]): void {
        this.userAriaDescribedBy = ids.join(' ');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        console.log(obj);
        this.value = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.disabled) {
            this.formPrincipal.disable();
        }
        else {
            this.formPrincipal.enable();
        }
        this.stateChanges.next();
    }


    cargaInicial() {
        let reqList = [];
        console.log(this.prefijoData);
        if (this.prefijoData) {
            reqList.push(this.prefijoData.reqPath);
        }
        else {
            console.error('PREFIJO INCORRECTO PARA ESTE TIPO DE CAMPO YEAR');
        }
        this.queryGeneralService.cargar(this, reqList).then((dR) => {
            if (dR && dR[this.prefijoData.reqPath] && dR[this.prefijoData.reqPath][0]) {
                this.formPrincipal.get(this.prefijoData.campoId).setValue(dR[this.prefijoData.reqPath][0][this.prefijoData.campoId] + '');
            }
        });
    }

}
