import {Component, EventEmitter, Inject, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {ColumnaTabla, OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {QueryService} from '@servicios/query.service';
import {FormulariosGlobalesService} from '@JVSoft/services/formularios-globales.service';
import {mensajeAlerta, mensajesErrorFormControl} from '@JVSoft/services/funciones-globales.service';
import {LogisticaMantenimientoProductosComponent} from '@logistica/logistica-mantenimiento/logistica-mantenimiento-productos/logistica-mantenimiento-productos.component';
import {TabSeccion} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';

@Component({
  selector: 'vex-logistica-partials-pedidos-detalles',
  templateUrl: './logistica-partials-pedidos-detalles.component.html',
  styleUrls: ['./logistica-partials-pedidos-detalles.component.scss']
})
export class LogisticaPartialsPedidosDetallesComponent implements OnInit {
    mensajesErrorFormControl = mensajesErrorFormControl;

    dataServidor = {
        log: {
            pedidos: null,
            pedidos_detalles: null,

            movimientos_productos_detalles: null,

            movimientos_productos_detalles_pedidos: null,
        },
    };
    seleccionados = {
        pedidos: null,
        pedidos_detalles: null,
        productos: null,
        movimientos_productos_detalles_pedidos: null,
    };
    filtrados = {};

    columnasPrincipal: ColumnaTabla<any>[] = [
        // { label: 'Image', property: 'image', type: 'image', visible: true },
        {label: '#', property: 'numeracion_automatica', type: 'custom'},

        {label: 'Código Producto', property: 'cCodigoProducto', type: 'text', cssClasses: []},
        // {label: 'Producto', property: 'cProductoNombre', type: 'text', visible: true, cssClasses: ['font-bold']},
        // {label: 'Obs.', property: 'cPedidoDetObs', type: 'text', visible: false, cssClasses: []},
        {label: 'Nombre de Producto', property: 'cProductoNombreObs', type: 'text', cssClasses: []},
        {label: 'Presentación', property: 'cPresentacionNombre', type: 'text', cssClasses: []},
        {label: 'Cantidad', property: 'nPedidoDetCantidad', type: 'number', cssClasses: ['font-bold']},
    ];

    opcionesFiltroAlmacen: TabSeccion[] = [
        {
            label: 'Buscar por Documento',
            params: [
                { control: 'select', campo: 'iTipoDocId', data: 'log.tipos_documentos_ingresos_bienes', val: '*primero*', text: 'cTipoDocSigla', label: 'Tipo Doc.', flex: '100px' },
                { control: 'select', campo: 'iYearId', campoId: 'iMovProdYear', data: 'log.years_movimientos_productos', val: '*primero*', text: 'iMovProdYear', label: 'Año', flex: '100px', dataCuandoVacio: [{iMovProdYear: new Date().getFullYear()}], },
                { control: 'number', campo: 'iDocNumero', text: '', label: 'Número', flex: '150px', keyUpEnter: true },
            ]
        },
        {
            label: 'Buscar por Producto',
            params: [
                {
                    // CHANGE TEMPORAL HASTA DEFINIR
                    control: 'select', campo: 'tipoBusqueda', data: 'tipoBusqueda', change: true, customData: [
                        {tipoBusqueda: 'porOrigen', label: 'Por Origen'},
                        {tipoBusqueda: 'porProducto', label: 'Por Producto'},
                    ], val: 'porOrigen', text: 'label', label: 'Tipo', flex: '120px',
                },
                {
                    control: 'select', campo: 'ubicacionBusqueda', data: 'ubicacionBusqueda', customData: [
                        {ubicacionBusqueda: '~VAR~%', label: 'Empieza por: '},
                        {ubicacionBusqueda: '%~VAR~%', label: 'Contiene: '},
                        {ubicacionBusqueda: '%~VAR~', label: 'Termina con: '},
                    ], val: '%~VAR~%', text: 'label', label: 'Ubic. Texto', flex: '120px',
                },
                { control: 'text', campo: 'cProductoNombreObs', text: '', label: 'Nombre Producto', flex: '150px', focus: true, keyUpEnter: true, },
            ]
        },
        {
            label: 'Buscar por Rango de Fechas y Producto',
            params: [
                { control: 'date_range', campo: ['cFechaDesde', 'cFechaHasta'], val: new Date(), text: '', label: 'Periodo', flex: '250px' },
                {
                    control: 'select', campo: 'ubicacionBusqueda', data: 'ubicacionBusqueda', customData: [
                        {ubicacionBusqueda: '~VAR~%', label: 'Empieza por: '},
                        {ubicacionBusqueda: '%~VAR~%', label: 'Contiene: '},
                        {ubicacionBusqueda: '%~VAR~', label: 'Termina con: '},
                    ], val: '%~VAR~%', text: 'label', label: 'Ubic. Texto', flex: '120px',
                },
                { control: 'text', campo: 'cProductoNombreObs', text: '', label: 'Nombre Producto', flex: '150px', focus: true, keyUpEnter: true, },
            ]
        },
    ];
    columnasProductosAlmacen: ColumnaTabla<any>[] = [
        // { label: 'Image', property: 'image', type: 'image', visible: true },
        {label: '#', property: 'numeracion_automatica', type: 'custom'},

        {label: 'Código Producto', property: 'cCodigoProducto', type: 'text', cssClasses: []},
        // {label: 'Producto', property: 'cProductoNombre', type: 'text', visible: true, cssClasses: ['font-bold']},
        // {label: 'Obs.', property: 'cPedidoDetObs', type: 'text', visible: false, cssClasses: []},
        // {label: 'Nombre de Producto', property: 'cProductoNombreObs', type: 'text', cssClasses: []},
        {label: 'Producto', property: 'cProductoNombreObs', type: 'text', transformar(row): string {
                return row['cProductoNombreObs'] ?? row['cProductoNombre'];
            }},
        {label: 'Presentación', property: 'cPresentacionNombre', type: 'text', cssClasses: []},
        {label: 'Origen', property: 'cOrigenDocumento', type: 'text', cssClasses: [], noMostrar: (): boolean => this.esBusquedaPorProducto},
        {label: 'Almacen', property: 'cAlmacenNombre', type: 'text', cssClasses: [], noMostrar: (): boolean => this.esBusquedaPorProducto},
        // {label: 'Stock', property: 'nStock_Movimientos_Productos_Pedidos', type: 'number', cssClasses: ['font-bold']},
        {
            label: 'Stock', property: 'nStock_productos', type: 'text', cssClasses: ['text-right', 'text-blue-800', '!text-sm', 'font-bold'],
            transformar(row) {
                return (row['nStock_productos_pedidos'] ?? row['nStock_Movimientos_Productos_Pedidos']) * 1;
            },
            noMostrar: (): boolean => !this.esDeAbastecimiento,
        },
    ];

    opcionesFiltroBusquedaAlmacenActual = {};


    frmFiltro: FormGroup;
    frmRegistro: FormGroup;
    dialogRef: MatDialogRef<any>[] = [null, null, null];
    @ViewChild('dialogRegistro', {static: true}) dialogRegistroRef: TemplateRef<any>;
    @ViewChild('dlgBuscarProductosAlmacen', {static: true}) dlgBuscarProductosAlmacenRef: TemplateRef<any>;

    _dataPrincipal = new BehaviorSubject<any>(null);
    dataPrincipal$ = this._dataPrincipal.asObservable();
    _dataProductosAlmacen = new BehaviorSubject<any>(null);
    dataProductosAlmacen$ = this._dataProductosAlmacen.asObservable();

    botonesMantenimiento: TablaMantenimientoSeccionBotones;
    opcMenuEmitter = new EventEmitter();
    isLoading = false;

    esDeAbastecimiento = false;

    esBusquedaPorProducto = false;
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
        @Optional() public dialogActualRef: MatDialogRef<LogisticaPartialsPedidosDetallesComponent>,
        private dialog: MatDialog,
        private queryService: QueryService,
        private fb: FormBuilder,
        public formulariosGlobalesService: FormulariosGlobalesService,
    ) {
        this.crearFormularios();
        console.log(this.datosDialogRecibidos);
        if (this.datosDialogRecibidos) {
            if (this.datosDialogRecibidos.iPedidoId) {
                this.frmFiltro.reset({
                    iPedidoId: this.datosDialogRecibidos.iPedidoId,
                });
            }
            else {
                this.frmFiltro.reset();
            }
            this.esDeAbastecimiento = datosDialogRecibidos.esDeAbastecimiento ?? false;
        }
        else {
            this.frmFiltro.reset();
        }
        this.cargaInicial();
    }

    ngOnInit(): void {
        this.formulariosGlobalesService.crearFormularioSeleccion('movimientos_productos_detalles');
    }

    crearFormularios() {
        this.frmFiltro = this.fb.group({
            iPedidoId: [''],
        });
        // this.frmRegistro = this.fb.group({
        //     iPedidoId: [''],
        //     detalle: [''],
        // });

        this.frmRegistro = this.fb.group({
            iPedidoId: ['', Validators.required],
            iProductoId: ['', Validators.required],
            iProductoIdDesc: ['', Validators.required],
            iProductoIdDesc2: ['', Validators.required],
            cProductoNombre: [''],
            iMovProdDetId: [''],
            cPedidoDetObs: [''],
            nPedidoDetCantidad: ['', Validators.required],

            nStock_movimientos_productos_pedidos: [''],
            esPedidoAlmacen: [''],

            iPedidoDetId: [''],
        });
    }

    cargaInicial() {
        this.queryService.cargar(this, [
            'log.pedidos',
        ], this.frmFiltro.value).then(() => {
            if (this.dataServidor.log.pedidos) {
                this.seleccionados.pedidos = this.dataServidor.log.pedidos[0];
            }
        });
        this.cargarLista().then();
    }

    cargarLista() {
        const paramsRequest = {
            ...this.frmFiltro.value,
        };
        return this.queryService.cargar(this, ['log.pedidos_detalles'], paramsRequest).then(() => {
            this._dataPrincipal.next(this.dataServidor.log.pedidos_detalles);
        });
    }


    filtroResultadosAlmacen(event = {}) {
        this.opcionesFiltroBusquedaAlmacenActual = event;
        this.esBusquedaPorProducto = (this.opcionesFiltroBusquedaAlmacenActual['tipoBusqueda'] && this.opcionesFiltroBusquedaAlmacenActual['tipoBusqueda'] == 'porProducto');
        this.cargarListaAlmacen();
    }

    cargarListaAlmacen() {
        console.log('CARGANDO DE LAM');
        this._dataProductosAlmacen.next([]);
        return this.queryService.cargar(this, ['log.movimientos_productos_detalles_pedidos'], this.opcionesFiltroBusquedaAlmacenActual).then(() => {
            console.log('log.productos');
            this._dataProductosAlmacen.next(this.dataServidor.log.movimientos_productos_detalles_pedidos);
        });
        // this.queryService.cargar(this, ['log.productos'], paramsRequest).then(() => {
        // 	console.log('TERMINO', new Date());
        // });
    }

    esPedidoAlmacen() {
        return this.seleccionados.pedidos.iDestinoPedidoId == 2;
    }


    opcMenu(v: OpcionSeleccionada) {
        let accionEncontrada = true;
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            data: {},
            maxHeight: '98vh',
            // minHeight:'98vh',
        };
        switch (v.seccion) {
            case 'pedidos_detalles':
                // dialogOpc.minWidth = '500px';
                switch (v.tipo) {
                    case 'nuevo':
                        console.log(this.datosDialogRecibidos);
                        this.seleccionados.pedidos_detalles = null;
                        this.dataServidor.log.movimientos_productos_detalles = null;
                        this.frmRegistro.reset({
                            iPedidoId: this.seleccionados.pedidos.iPedidoId,
                        });

                        this.frmRegistro.get('iProductoIdDesc').enable();
                        this.frmRegistro.get('iProductoIdDesc2').enable();
                        this.frmRegistro.get('cPedidoDetObs').enable();
                        console.log(this.seleccionados.pedidos);
                        this.dialogRef[0] = this.dialog.open(this.dialogRegistroRef, dialogOpc);
                        const tipoB = this.esPedidoAlmacen() ? 'buscar_producto_almacen' : 'buscar_producto';
                        this.opcMenu({
                            ...v,
                            ...{
                                tipo: tipoB,
                            }
                        });
                        break;
                    case 'editar':
                        const cargarAbrir = (parche = {}) => {
                            this.frmRegistro.patchValue({
                                ...this.seleccionados.pedidos_detalles,
                                ...{
                                    nPedidoDetCantidad: this.seleccionados.pedidos_detalles.nPedidoDetCantidad * 1,
                                    iProductoIdDesc: this.seleccionados.pedidos_detalles.cProductoNombre,
                                    iProductoIdDesc2: this.seleccionados.pedidos_detalles.cPresentacionNombre,
                                },
                                ...parche,
                            });
                            this.seleccionados.productos = this.seleccionados.pedidos_detalles;
                            this.dialogRef[0] = this.dialog.open(this.dialogRegistroRef, dialogOpc);
                        };

                        if (this.esPedidoAlmacen()) {
                            this.frmRegistro.get('iProductoIdDesc').disable();
                            this.frmRegistro.get('iProductoIdDesc2').disable();
                            this.frmRegistro.get('cPedidoDetObs').disable();
                            cargarAbrir({
                                nStock_movimientos_productos_pedidos: this.seleccionados.pedidos_detalles.nStock_movimientos_productos_pedidos * 1,
                            });
                        }
                        else {
                            cargarAbrir();
                        }
                        // });
                        break;
                    case 'buscar_producto':
                        if (this.datosDialogRecibidos && !this.esPedidoAlmacen()) {
                            dialogOpc.data.esDialog = true;
                            dialogOpc.data.filtroBusquedaDefault = {
                                iTipoProductoId: { disabled: true, val: this.seleccionados.pedidos.iTipoProductoId }
                            };
                            dialogOpc.minWidth = '80vw';
                            this.dialog.open(LogisticaMantenimientoProductosComponent, dialogOpc).afterClosed().subscribe(data => {
                                console.log(data);
                                if (data) {
                                    this.seleccionados.productos = data;
                                    this.frmRegistro.patchValue({
                                        iProductoId: this.seleccionados.productos.iProductoId,
                                        iProductoIdDesc: this.seleccionados.productos.cProductoNombre,
                                        iProductoIdDesc2: this.seleccionados.productos.cPresentacionNombre,
                                    });
                                }
                            });
                        }
                        break;
                    case 'buscar_producto_almacen':
                        // this.esBusquedaPorProducto = true;
                        this._dataProductosAlmacen.next([]);
                        dialogOpc.minWidth = '80vw';
                        this.dialogRef[2] = this.dialog.open(this.dlgBuscarProductosAlmacenRef, dialogOpc);
                        break;
                    default:
                        const dEmitExtra = {};
                        if (v.tipo == 'guardar') {
                            if ((this.frmRegistro.get('nPedidoDetCantidad').value * 1) <= 0) {
                                mensajeAlerta('error', 'Datos No válidos', ' La cantidad del pedido debe ser superior a cero');
                                return false;
                            }
                            if (this.esPedidoAlmacen()) {
                                if ((this.frmRegistro.get('nPedidoDetCantidad').value * 1) > (this.frmRegistro.get('nStock_movimientos_productos_pedidos').value * 1)) {
                                    mensajeAlerta('error', 'Datos No válidos', ' La cantidad del pedido debe ser inferior o igual al stock actual');
                                    return false;
                                }
                                if (this.frmRegistro.get('iPedidoDetId').value) {
                                    dEmitExtra['strSeleccionado'] = 'pedidos_detalles';
                                    // return false;
                                }
                                this.frmRegistro.get('esPedidoAlmacen').setValue(1);
                            }

                        }
                        // this.frmRegistro.get('detalle').setValue(this.formulariosGlobalesService.generarLista('movimientos_productos_detalles', 'object'));
                        this.queryService.parteComunOpcionesMantenimiento2(this, v, this.frmRegistro, {
                            ...{
                                coleccion: 'log.pedidos_detalles',
                                idxTabla: 'iPedidoDetId',
                                strDataObservable: '_dataPrincipal',
                                prefix: 'log',
                                retornarRespuestaGuardadoEliminado: this.esBusquedaPorProducto || !this.esDeAbastecimiento,
                                dialogRef: this.dialogRef[0],
                            },
                            ...dEmitExtra,
                        }).then(dataRetornado => {
                            if (['guardar', 'eliminar'].includes(v.tipo)) {
                                if (this.dialogRef[0]) {
                                    this.dialogRef[0].close();
                                }
                                this.cargarLista().then();
                                this.opcMenu({...v, ...{seccion: 'pedidos', tipo: 'recargar'}});
                            }
                            /*console.log(dataRetornado);
                            if (Array.isArray(dataRetornado) && dataRetornado.length < 1) {
                                this.cargarLista().then();
                            }
                            else {
                                console.log(dataRetornado['data'])
                                console.log(dataRetornado['data']['iResult'])
                                if (dataRetornado['data'] && dataRetornado['data']['iResult']) {

                                }
                            }*/


                            console.log([dataRetornado, this.esBusquedaPorProducto]);

                        }).catch(error => { console.log(error); });
                        break;
                }
                break;
            case 'movimientos_productos_detalles_pedidos':
                switch (v.tipo) {
                    case 'seleccionar':
                        if (this.seleccionados.movimientos_productos_detalles_pedidos) {
                            this.seleccionados.productos = this.seleccionados.movimientos_productos_detalles_pedidos;
                            this.frmRegistro.patchValue({
                                iProductoId: this.seleccionados.productos.iProductoId,
                                iProductoIdDesc: this.seleccionados.productos.cProductoNombre,
                                iProductoIdDesc2: this.seleccionados.productos.cPresentacionNombre,
                                iMovProdDetId: this.seleccionados.productos.iMovProdDetId,
                                nStock_movimientos_productos_pedidos: this.seleccionados.productos[this.esBusquedaPorProducto ? 'nStock_productos_pedidos' : 'nStock_Movimientos_Productos_Pedidos'] * 1,
                                cPedidoDetObs: this.seleccionados.productos.cMovProdDetObs,
                            });
                            this.dialogRef[2].close();
                        }
                        break;
                    default:
                        // this.frmRegistro.get('detalle').setValue(this.formulariosGlobalesService.generarLista('movimientos_productos_detalles', 'object'));
                        this.queryService.parteComunOpcionesMantenimiento2(this, v, this.frmRegistro, {
                            coleccion: 'log.movimientos_productos_detalles_pedidos',
                            idxTabla: 'iMovProdDetId',
                            strDataObservable: '_dataProductosAlmacen',
                            prefix: 'log',
                            dialogRef: this.dialogRef[0],
                        }).then(dataRetornado => {

                        }).catch(error => { console.log(error); });
                        break;
                }
                break;
            default:
                accionEncontrada = false;
                break;
        }

        if (!accionEncontrada) {
            this.opcMenuEmitter.emit(v);
        }
    }

}
