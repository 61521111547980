<div class="w-full bg-gray-200 text-gray-700 rounded-full" [class.text-center]="textoCentrado">
	<div class="text-xxs font-medium text-center p-1 leading-none rounded-full"
		 [ngClass]="colorEstilo().class ? colorEstilo().class : 'bg-blue-600 text-blue-100'"
		 [style]="colorEstilo().style ? colorEstilo().style : ''"
		 [style.width]="(textoCentrado ? 100 : porcentaje) + '%'"
	>
        <span *ngIf="textoMostrar else defaultText;" class="whitespace-nowrap">{{ textoMostrar }}</span>
        <ng-template #defaultText>{{ porcentaje | number: formatoNumero }}%</ng-template>
    </div>
</div>
