import {groupBy, ordenarPorPropiedad, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';

export function consultaComprobantePago(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.Consulta_ExpedientesGirados'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.Consulta_ExpedientesGirados'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 80, 40, 40],
			// orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE COMPROBANTES DE PAGO', null, {fontSize: 13, bold: true}),
				objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{ label: 'Año', property: 'Ano_eje', type: 'text' },
			{ label: 'Nº SIAF', property: 'Expediente', type: 'text' },
			{ label: 'Documento', property: 'Nombre_doc_Girado', type: 'text' },
			{ label: 'Número', property: 'Numero_doc_Girado', type: 'number', transformarDirecto(row): any {
					return imprimirCelda(row['Numero_doc_Girado'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{ label: 'Fecha', property: 'fecha_doc_Girado', type: 'date' },
			{ label: 'Meta', property: 'Sec_func', type: 'text' },
			// { label: 'Meta22', property: 'cNombre_Sec_func', type: 'text' },
			{ label: 'Clasif.', property: 'Clasificador', type: 'text' },
			{ label: 'Monto', property: 'Monto', type: 'money' },
			{ label: 'Proveedor / Persona', property: 'Proveedor_Nombre', type: 'text' },
			{ label: 'Cuenta', property: 'Ano_cta_cte', type: 'text', transformar(row): string {
					return [row['Ano_cta_cte'], row['Banco'], row['Cta_cte']].join('-');
				} },
		];

		const agrupado = groupBy(resultadosGenerales, (obj) => {
			return obj.Sec_func + ' - ' + obj.cNombre_Sec_func;
		});
		console.log(agrupado);

		let pag = 0;
		Object.keys(agrupado).forEach((secGrupo) => {
			if (pag > 0) {
				cuerpoPdf.push({
					text: '',
					pageBreak: 'before',
				});
			}
			const tablaGenerada = objThis.generarDesdeMantenimiento({
				titulos: camposPrincipal,
				contenido: ordenarPorPropiedad(agrupado[secGrupo], 'Numero_doc_Girado'),
				numeracion: true,
				idxResto: [9],
				margin: [0, 0, 0, 5],
				separado: true,
			});

			cuerpoPdf.push({
				margin: [0, 5, 0, 10],
				table: {
					dontBreakRows: true,
					headerRows: 2,
					widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
					body: tablaGenerada['titulos'].concat([
						[
							imprimirCelda(secGrupo, null, {bold: true, fontSize: 9, style: 'thSmall', alignment: 'center', colSpan: 11}),
							{}, {}, {}, {}, {}, {}, {}, {}, {}, {},
						],
					]).concat(tablaGenerada['cuerpo']),
				}
			});
			pag++;
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}


export function consultaExpedientesColumnaTotalFase(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.Consulta_Expedientes_ColumnaTotalFase'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		let resultadosGenerales = valRetorno['siaf.Consulta_Expedientes_ColumnaTotalFase'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 80, 40, 40],
			orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				imprimirCelda('REPORTE DE EXPEDIENTES Y FASES DE GASTO', null, {fontSize: 13, bold: true}),
				imprimirCelda('', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{ label: 'Año', property: 'Ano_eje', type: 'text' },
			{ label: 'Nº SIAF', property: 'Expediente', type: 'text' },
			{ label: 'FF', property: 'Fuente_financ', type: 'text' },
			{ label: 'TR', property: 'Tipo_recurso', type: 'text' },
			{ label: 'Tipo Operación', property: 'cNombre_Tipo_Operacion', type: 'text' },
			{ label: 'Nombre Proveedor', property: 'Proveedor_Nombre', type: 'text' },
			{ label: 'Documento', property: 'Nombre_doc_Compromiso', type: 'text' },
			{ label: 'Número', property: 'Numero_doc_Compromiso', type: 'number', transformarDirecto(row): any {
					return imprimirCelda(row['Numero_doc_Compromiso'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{ label: 'Fecha', property: 'Fecha_doc_Compromiso', type: 'date' },
			{ label: 'Meta', property: 'Sec_func', type: 'text' },
			{ label: 'Compromiso', property: 'nCompromiso', type: 'money' },
			{ label: 'Devengado', property: 'nDevengado', type: 'money' },
			{ label: 'Girado', property: 'nGirado', type: 'money' },
			{ label: 'Pagado', property: 'nPagado', type: 'money' },
			{ label: 'Rendido', property: 'nRendido', type: 'money' },
		];

		const agrupado = groupBy(resultadosGenerales, (obj) => {
			return obj.Sec_func + ' - ' + obj.cNombre_Sec_func;
		});
		console.log(agrupado);

		let pag = 0;
		Object.keys(agrupado).forEach((secGrupo) => {
			if (pag > 0) {
				cuerpoPdf.push({
					text: '',
					pageBreak: 'before',
				});
			}
			const tablaGenerada = objThis.generarDesdeMantenimiento({
				titulos: camposPrincipal,
				contenido: ordenarPorPropiedad(agrupado[secGrupo], 'Expediente'),
				numeracion: true,
				idxResto: [9],
				margin: [0, 0, 0, 5],
				separado: true,
			});
			const sumaObj = sumarObjetos(agrupado[secGrupo], [
				'nCompromiso',
				'nDevengado',
				'nGirado',
				'nPagado',
				'nRendido',
			]);

			cuerpoPdf.push({
				margin: [0, 5, 0, 10],
				table: {
					dontBreakRows: true,
					headerRows: 1,
					widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
					body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
						[
							imprimirCelda('Total', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 11}),
							{}, {}, {}, {}, {}, {}, {}, {}, {}, {},
							imprimirCelda(sumaObj['nCompromiso'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nGirado'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nPagado'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nRendido'], 'money', {bold: true, style: 'thSmall'}),
						],
					]),
				}
			});
			pag++;
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}


export function consultaExpedientesGiradosDocumentoCompromiso(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.Consulta_ExpedientesGirados_DocumentoCompromiso'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		let resultadosGenerales = valRetorno['siaf.Consulta_ExpedientesGirados_DocumentoCompromiso'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 80, 40, 40],
			orientacion: 'landscape',
			tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				imprimirCelda('REPORTE DE COMPROBANTES DE PAGO Y DOCUMENTO COMPROMISO', null, {fontSize: 13, bold: true}),
				imprimirCelda('', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{ label: 'Año', property: 'Ano_eje', type: 'text' },
			{ label: 'Nº SIAF', property: 'Expediente', type: 'text' },
			{ label: 'Nº Certif..', property: 'cCertificado', type: 'text' },
			{ label: 'FF', property: 'Fuente_financ', type: 'text' },
			{ label: 'TR', property: 'Tipo_recurso', type: 'text' },
			{ label: 'Cod.', property: 'cCod_doc_Compromiso', type: 'text' },
			{ label: 'Compromiso', property: 'cClase_Compromiso', type: 'text' },
			{ label: 'Número', property: 'cNum_doc_Compromiso', type: 'text' },
			{ label: 'Meta', property: 'Sec_func', type: 'text' },
			{ label: 'Clasificador', property: 'Clasificador', type: 'text' },
			{ label: 'Monto', property: 'Monto', type: 'money' },
			{ label: 'Cod.', property: 'Cod_doc_Girado', type: 'text' },
			{ label: 'Documento', property: 'Nombre_doc_Girado', type: 'text' },
			{ label: 'Número', property: 'Numero_doc_Girado', type: 'number', transformarDirecto(row): any {
					return imprimirCelda(row['Numero_doc_Girado'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{ label: 'Fecha', property: 'fecha_doc_Girado', type: 'date' },
			{ label: 'RUC', property: 'Ruc', type: 'text' },
			{ label: 'Nombre Proveedor', property: 'Proveedor_Nombre', type: 'text' },
			{ label: 'T.G.', property: 'Tipo_giro', type: 'text' },
			{ label: 'Notas', property: 'Notas', type: 'text' },
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
			numeracion: true,
			idxResto: [19],
			margin: [0, 0, 0, 5],
			// separado: true,
		});

		cuerpoPdf.push(tablaGenerada);

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}
