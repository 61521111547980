import {Injectable} from '@angular/core';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {finalizarGuardadoMantenimiento, FuncionesGlobalesService, markFormGroupTouched, mensajeAlerta, mensajeConfirmacion, mensajesDeError, mensajeTimer, toFormData} from '@JVSoft/services/funciones-globales.service';
import {FormGroup} from '@angular/forms';
import {environment} from '@ENV';
import {BehaviorSubject, lastValueFrom, Observable, of} from 'rxjs';
import {SweetAlertOptions} from 'sweetalert2';
import FileSaver from 'file-saver';
import {MatDialogRef} from '@angular/material/dialog';
import {ColumnaTabla, OpcionSeleccionada} from '@JVSoft/interfaces/global';
import {getFormValidationErrors} from '@JVSoft/validators/form-validators';
import {ConfiguracionWebService} from '@servicios/configuracion-web.service';
import {QueryLoadingProgressBarService} from '@servicios/query-loading-progress-bar.service';

interface OpcionesDescargarExcelPorFiltro {
	tipoQuery: string;
	prefix?: string;
	generarDesdeProcedimiento?: boolean;
	titulo?: string;
	subTitulo?: string;
	columnasDatos?: ColumnaTabla<any>[];
	// columnasDatos?: {
	// 	label: string,
	// 	campo: string,
	// 	type?: string,
	// }[];
	dataQuery: any;
}

export interface ReqProveedorRemoto {
	tipo: 'sunat' | 'reniec';
	dni?: string;
	ruc?: string;
	dataExtra?: any;
}

@Injectable({
	providedIn: 'root'
})
export class QueryGeneralService {

	esFormData = false;

	baseUrl = null;
	backendUrl = null;
	prefijo = 'grl';

	httpOptions: any = {};

	constructor(
		public http: HttpClient,
		protected toastr: ToastrService,
		private funcionesGlobalesService: FuncionesGlobalesService,
		private configuracionWebService: ConfiguracionWebService,
		private queryLoadingProgressBarService: QueryLoadingProgressBarService,
	) {
		// this.baseUrl = this.configuracionWebService.apiData?.backendApi;
		this.baseUrl = this.configuracionWebService.CONST_BACKEND_API;
		this.backendUrl = this.configuracionWebService.apiData?.backend;
		// this.baseUrl = configuracionWebService;
		// this.backendUrl = environment.backend;
	}

	verificarUrlApi() {
		if (this.baseUrl == null) {
			this.toastr.error('Falta extender "QueryServiceGlobal" en tu QueryService y sobreescribir el constructor.', 'Falta baseURL', {disableTimeOut: true});
		}
	}


	/**
	 * Se usa para cargar datos desde el servidor, es requerido haber declarado un objeto publico llamado "dataServidor".
	 * @param objThis   Se tiene que poner 'this'
	 * @param reqArray  Array de cadenas de peticion, esto debe controlarse en el backend. Ejm. ('prestadores', 'grl.personas, etc)
	 * @param data      Data a enviar segun el metodo GET.
	 * @param anonimo   Especificar si es anonimo.
	 * @param prefix    debe especificarse el prefijo, inicialmente es 'grl'.
	 */
	cargar(objThis, reqArray: string[], data = {}, anonimo = false, prefix = this.prefijo) {
		if (reqArray.length <= 0) {
			return of( false ).toPromise();
		}


        if (objThis['dataServidorSuscripcion']) {
            reqArray.forEach(value => {
                if (!objThis['dataServidorSuscripcion'][value]) {
                    objThis['dataServidorSuscripcion'][value] = new BehaviorSubject<any>(null);
                }
                else {
                    objThis['dataServidorSuscripcion'][value].next(null);
                }
            });
        }

		const almacen = objThis['dataServidor'];
		/*
		// Generar ObjData Dentro de Data
		if (data['data']) {
			newData = {...newData, ...data['data']};
		}
		*/
		return this.getDataMethod('GET', reqArray.join('&'), data, anonimo, prefix).toPromise().then(d => {
			Object.keys(d).forEach((value) => {
				const parts = value.split('.');
				try {
                    if (objThis['dataServidorSuscripcion']) {
                        objThis['dataServidorSuscripcion'][value].next(d[value]);
                    }

					if (parts.length > 1) {
						almacen[parts[0]][parts[1]] = d[value];
					} else {
						if (d[value]) {
							almacen[value] = d[value];
						} else {
							almacen[value] = d;
						}
					}
				} catch (error) {
					// console.warn(error);
				}
			});
			return d;
		}).catch(err => {
			mensajesDeError(err);
		});
	}

    cargarSinDataServidor(objThis, reqArray: string[], data = {}, anonimo = false, prefix = this.prefijo) {

        return this.getDataMethod('GET', reqArray.join('&'), data, anonimo, prefix).toPromise().then(d => {
            return d;
        }).catch(err => {
            mensajesDeError(err);
        });
    }

	getDataMethod(method: 'GET' | 'POST', tipo: string, data = {}, anonimo = false, prefix = this.prefijo, headers = true) {
		Object.keys(data).forEach(key => {
			if (data[key] == null){
				data[key] = '';
			}
		});

		this.verificarUrlApi();
		tipo = tipo.replace('#', '/');
        this.httpOptions.responseType = undefined;
		if (method == 'GET') {
			this.httpOptions.params = data;
			return this.http.get(`${this.baseUrl}/${prefix}${(anonimo ? '/anonimo' : '')}/dataexistente/${tipo}`, this.httpOptions);
		}
		if (method == 'POST') {
			return this.http.post(`${this.baseUrl}/${prefix}${(anonimo ? '/anonimo' : '')}/dataexistente/${tipo}`, data);

		}
	}

	removeTempFile(data) {
		this.verificarUrlApi();
		if (this.esFormData) {
			data = toFormData(data);
		}
		return this.http.post(`${this.baseUrl}/grl/archivos/eliminar`, data, {
			reportProgress: true,
			observe: 'events',
		});
	}

	uploadFileControlador(tipo, data, prefix = this.prefijo, anonimo = false) {
		this.verificarUrlApi();
		tipo = tipo.replace('#', '/');
		if (this.esFormData) {
			data = toFormData(data);
		}
		return this.http.post(`${this.baseUrl}/${prefix}/${(anonimo ? 'anonimo/' : '')}guardar/${tipo}`, data, {
			reportProgress: true,
			observe: 'events',
		});
	}

	async enviarFormulario(tipo, frmTratar: FormGroup, reset = true, prefix = this.prefijo, anonimo = false, tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2') {

		if (frmTratar.invalid) {
			markFormGroupTouched(frmTratar);
			console.log(getFormValidationErrors(frmTratar));
			await mensajeAlerta(
				'error',
				'Error:',
				'Faltan datos en el formulario. por favor verifique',
				{
					confirmButtonText: 'Verificar',
				}
			);
			return false;
		} else {
			const retorno = await this.guardarDatosAsync(tipo, frmTratar.value, prefix, tipoMsg, anonimo);
			if (retorno) {
				if (reset) {
					frmTratar.reset();
				}
				return retorno;
			} else {
				return false;
			}

		}
	}

    guardarDatos(tipo, data, prefix = this.prefijo, tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2', anonimo = false) {
        return this.setData(tipo, data, prefix, anonimo).toPromise().then(dataRetorno => {
            const mensajeFinal = dataRetorno['msg'] ?? '';
            switch (tipoMsg) {
                case 'sweetalert2':
                    if (mensajeFinal.indexOf('Número: ') > 0) {
                        mensajeAlerta(
                            'success',
                            '¡Éxito!',
                            mensajeFinal,
                        );
                    } else {
                        mensajeTimer(
                            'success',
                            '¡Éxito!',
                            mensajeFinal,
                        );
                    }

                    break;
                case 'toastr':
                    this.funcionesGlobalesService.mensajeToast(
                        'success',
                        '¡Éxito!',
                        mensajeFinal,
                    );
                    break;
            }
            return dataRetorno;
        }).catch(err => {
            switch (tipoMsg) {
                case 'sweetalert2':
                    mensajeAlerta('error', 'Error:', err.error.message, {confirmButtonText: 'Verificar'});
                    break;
                case 'toastr':
                    this.funcionesGlobalesService.mensajeToast(
                        'error',
                        'Error:',
                        err.error.message,
                    );
                    break;
            }
        });
    }

	async guardarDatosAsync(tipo, data, prefix = this.prefijo, tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2', anonimo = false, sinBloqueo = false) {

		this.queryLoadingProgressBarService.barraActiva.next(!!!sinBloqueo);
		const dataRetorno = await this.setData(tipo, data, prefix, anonimo).toPromise().then(a => {
            return a;
        }).catch(err => {
			switch (tipoMsg) {
				case 'sweetalert2':
					mensajeAlerta('error', 'Error:', err.error.message, {confirmButtonText: 'Verificar'});
					break;
				case 'toastr':
					this.funcionesGlobalesService.mensajeToast(
						'error',
						'Error:',
						err.error.message,
					);
					break;
			}


		});

		if (dataRetorno) {
			const mensajeFinal = dataRetorno['msg'] ? dataRetorno['msg'] : '';
			switch (tipoMsg) {
				case 'sweetalert2':
					if (mensajeFinal.indexOf('Número: ') > 0) {
						await mensajeAlerta(
							'success',
							'¡Éxito!',
							mensajeFinal,
						);
					} else {
						await mensajeTimer(
							'success',
							'¡Éxito!',
							mensajeFinal,
						);
					}

					break;
				case 'toastr':
					await this.funcionesGlobalesService.mensajeToast(
						'success',
						'¡Éxito!',
						mensajeFinal,
					);
					break;
			}
			return dataRetorno;
		}
	}

	setData(tipo, data, prefix = this.prefijo, anonimo = false, sinBloqueo = false) {
		this.verificarUrlApi();
		tipo = tipo.replace('#', '/');
		this.queryLoadingProgressBarService.barraActiva.next(!!!sinBloqueo);
		if (this.esFormData) {
			data = toFormData(data);
			return this.http.post(`${this.baseUrl}/${prefix}/${(anonimo ? 'anonimo/' : '')}guardar/${tipo}`, data, {
				reportProgress: true,
				observe: 'events',
			});
		}
		return this.http.post(`${this.baseUrl}/${prefix}/${(anonimo ? 'anonimo/' : '')}guardar/${tipo}`, data);
	}
	async setDataSession(data) {
		return lastValueFrom(this.http.put(`${this.baseUrl}/guardar-seleccion`, data));
	}

	setDataCustomRoutePath(method: 'GET' | 'POST', apiRoutePath, data): Observable<any> {
		this.verificarUrlApi();

		if (method == 'GET') {
			this.httpOptions.params = data;
			return this.http.get(`${this.baseUrl}/${apiRoutePath}`, this.httpOptions);
		}
		if (method == 'POST') {
			return this.http.post(`${this.baseUrl}/${apiRoutePath}`, data);
		}
	}

	async eliminarDatosAsync(tipo, data, mensaje = null, prefix = this.prefijo, tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2', anonimo = false) {
		return await mensajeConfirmacion(
			'warning',
			'¿Está seguro?',
			(mensaje ? mensaje : 'Se eliminará el ítem titulo. Esta acción no se puede deshacer.'),
		).then(async (result) => {
			if (!result.dismiss) {
				return this.guardarDatosAsync(tipo, data, prefix, tipoMsg, anonimo);
			}
		});
	}

	// SECCION DE ARCHIVOS
	async removeFile(data: {f: string}, anonimo = false, mensaje = '', tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2', opciones?: SweetAlertOptions | Partial<IndividualConfig>) {
		return await mensajeConfirmacion(
			'warning',
			'¿Está seguro?',
			(mensaje ? mensaje : 'Se eliminará el ítem titulo. Esta acción no se puede deshacer.'),
		).then(async (result) => {
			if (result.isConfirmed) {
				return this.setDataCustomRoutePath('POST', `grl/archivos${(anonimo ? '/anonimo' : '')}/eliminar`, data)
				.toPromise()
					.catch(err => mensajesDeError(err))
					.then(async (dataRetorno) => {
						await this.responseMessages(dataRetorno, tipoMsg, opciones);
						return dataRetorno;
				});
			}
			else {
				return of(false);
			}
		});
	}

	uploadFile(data, anonimo = false) {
		this.verificarUrlApi();
		if (this.esFormData) {
			data = toFormData(data);
		}
		return this.http.post(`${this.baseUrl}/grl/archivos${(anonimo ? '/anonimo' : '')}/cargar`, data, {
			reportProgress: true,
			observe: 'events',
		});
	}

    uploadFile2(tipo, data, prefix = this.prefijo, tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2', anonimo = false) {
        this.verificarUrlApi();
        if (this.esFormData) {
            data = toFormData(data);
        }
        // http://localhost:8000/api/tre/importar-excel/cajas_chicas_documentos#debug
        return this.http.post(`${this.baseUrl}/${prefix}/importar-excel/${(anonimo ? 'anonimo/' : '')}${tipo}`, data, {
            reportProgress: true,
            observe: 'events',
        });
    }

	downloadFile(data: {f?: string, cArchivoKey?: string, disco?: string, name?: string, porTipoAutomatico?: any, type?: string, retornarUrl?: any}, anonimo = false) {
		const parametros = new URLSearchParams(data);
		let url = `${this.baseUrl}/grl/archivos${(anonimo ? '/anonimo' : '')}/descargar?${parametros}`;
		url = url.replace(this.configuracionWebService.CONST_BACKEND_API, this.configuracionWebService.apiData.backendApi)
        if (data.retornarUrl){
            return url;
        }
		window.open(url);
	}

    openApiPublicUrl(url: string, target: string = '_blank') {
        window.open(this.backendUrl + url, target);
    }
    openExternalUrl(url: string, target: string = '_blank') {
        window.open(url, target);
    }

	// FIN SECCION ARCHIVOS

	async responseMessages(dataRetorno, tipoMsg: 'sweetalert2' | 'toastr' = 'sweetalert2', opciones?: SweetAlertOptions | Partial<IndividualConfig> ) {
		const mensajeFinal = dataRetorno['msg'] ? dataRetorno['msg'] : '';
		if (tipoMsg == 'sweetalert2') {
			// let opcionesS = (opciones instanceof SweetAlertOptions) ?
			// @ts-ignore
			await mensajeTimer('success', '¡Éxito!', mensajeFinal, opciones);
		}
		else if (tipoMsg == 'toastr') {
			// @ts-ignore
			await this.funcionesGlobalesService.mensajeToast('success', '¡Éxito!', mensajeFinal, opciones);
		}
		else {
			console.warn(mensajeFinal);
		}
	}

	descargarExcel(tipo, data = {}, anonimo = true, prefix = this.prefijo, headers = true) {
		const queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
		const url = `${this.baseUrl}/${prefix}/excel/${tipo}?${queryString}`;

		this.http.get(url, { ...this.httpOptions, responseType: 'arraybuffer' }).subscribe({
			next: (response: ArrayBuffer) => {
				// Crear un Blob con el tipo MIME adecuado
				const blob = new Blob([response], { type: 'application/vnd.ms-excel' });

				// Crear un enlace para descargar el archivo
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);

				// Establecer el nombre de archivo para la descarga
				link.download = `${tipo}.xlsx`;

				// Añadir el enlace al documento y simular clic para iniciar la descarga
				document.body.appendChild(link);
				link.click();

				// Limpiar
				document.body.removeChild(link);
				window.URL.revokeObjectURL(link.href);
			},
			error: error => {
				return mensajesDeError(error)
			}
		});
	}
    descargarExcelPost(tipo, data = {}, anonimo = true, prefix = this.prefijo, headers = true) {
        const queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

        // const url = `${this.baseUrl}/${prefix}/excel/${tipo}?${queryString}`;
        const url = `${this.baseUrl}/${prefix}/excel/${tipo}`;
        // @ts-ignore
        // window.open(url);

        // observe: 'response'
        this.httpOptions.responseType = 'arraybuffer';
        // this.httpOptions.params = data;
        return this.http.post(url, data, this.httpOptions).subscribe(response => {
            this.downLoadFileStream(response, 'application/vnd.ms-excel', tipo);
        }, error => mensajesDeError(error));
    }
    descargarExcelPorFiltro(opciones: OpcionesDescargarExcelPorFiltro) {

		let url;
		if (opciones.generarDesdeProcedimiento) {
			opciones.dataQuery = {
				...opciones.dataQuery,
				...{
					generarDesdeProcedimiento: 1,
				},
			}
			url = `${this.baseUrl}/grl/excel/${opciones.tipoQuery}`;
		}
		else {
			url = `${this.baseUrl}/${(opciones.prefix ?? this.prefijo)}/excel/${opciones.tipoQuery}`;
		}
		let dataColEnviar;
		if (opciones.columnasDatos) {
			dataColEnviar = opciones.columnasDatos.filter(column => {
				if (column.reporte && column.reporte.ocultar) {
					return false;
				}
				return !['numeracion_automatica'].includes(column.type);
			}).map((col) => {
				return {
					campo: col.property,
					label: col.label,
					type: col.type ?? '',
				};
			});
		}



		opciones.dataQuery = {
			...opciones.dataQuery,
			...{
				titulo: opciones.titulo,
				subTitulo: opciones.subTitulo,
				columnasDatos: dataColEnviar,
			},
		}
        this.httpOptions.responseType = 'arraybuffer';
        // this.httpOptions.params = data;
        return this.http.post(url, opciones.dataQuery, this.httpOptions).subscribe(response => {
            this.downLoadFileStream(response, 'application/vnd.ms-excel', opciones.tipoQuery);
        }, error => mensajesDeError(error));
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFileStream(data: any, type: string, tipo = null) {
        const blob = new Blob([data], { type});
        FileSaver.saveAs(blob, tipo + '.xlsx');
        /*
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }
        */
    }

	getDataProveedorRemoto(tipo, dataExtra){
		if (!environment.production) {
			// dataExtra.db = 'ANTONIO';
		}
		dataExtra.tipo = tipo;
		this.httpOptions.params = dataExtra;
		// return this.http.get(`${this.baseUrl}/pide/${tipo}`, this.httpOptions);
		return this.getDataMethod('GET', 'proveedor_remoto', dataExtra, true, 'grl').toPromise()
            .then(d => {
                return d;
            }).catch(err => {
                console.log(err);
			mensajesDeError(err);
		});

		// const nwData = Object.keys(dataExtra).map(key => key + '=' + dataExtra[key]).join('&');
		// return this.http.get(`${this.baseUrl}/pide/${tipo}?${nwData}`, this.httpOptions);
	}

	async getDataProveedorRemotoV2(dataReq: ReqProveedorRemoto) {
		let dataEnviar = dataReq;
		if (dataEnviar.dataExtra) {
			dataEnviar = {
				...dataEnviar,
				...dataEnviar.dataExtra,
			}
			dataEnviar.dataExtra = '';
		}

		this.httpOptions.params = dataEnviar;
		return await lastValueFrom(this.getDataMethod('GET', 'proveedor_remoto', dataEnviar, true, 'grl')).then(dRet => {
			if (dRet && dRet['proveedor_remoto'] && dRet['proveedor_remoto']['data']) {
				return dRet['proveedor_remoto']['data'];
			}
			return null;
		}).catch(error => {
			mensajesDeError(error);
			return null;
		});
	}

	getTipoCambio(dataExtra){
        this.httpOptions.params = dataExtra;
        return this.getDataMethod('GET', 'tipo_de_cambio', dataExtra, true, 'grl').toPromise()
            .then(d => {
                return d['tipo_de_cambio'];
            }).catch(err => {
                console.log(err);
                mensajesDeError(err);
            });
    }

    parteComunOpcionesMantenimiento2(objThis, v: OpcionSeleccionada, frmRegistro: FormGroup, datosEmitir: {
        coleccion: string;
        idxTabla: string;
        bCampoActivo?: string;
        strSeleccionado?: string;
        strDataObservable?: string;
        prefix?: string;
        dialogRef?: MatDialogRef<any>;
        retornarRespuestaGuardadoEliminado?: boolean;

		mensajeEliminar?: string;

        cargar?: {
            data: object
        };

        fnCargarLista?(...args): void;
    }): Promise<any> {
        datosEmitir = {
            ...{
                prefix: this.prefijo,
                strSeleccionado: v.seccion,
            },
            ...datosEmitir
        };
        const accionGuardarEliminar = (dataRetornado) => {
            return finalizarGuardadoMantenimiento(objThis, dataRetornado, datosEmitir.coleccion, datosEmitir.idxTabla, datosEmitir.dialogRef)
                .then((dRetorno) => {
					console.warn(dRetorno);
                    if (dRetorno) {
                        let  nData = dRetorno;
                        if (datosEmitir.bCampoActivo) {
                            nData = dRetorno.filter(item => item[datosEmitir.bCampoActivo] != 0);
                        }
                        objThis[datosEmitir.strDataObservable].next(nData);
                    }
                    else {
                        if (datosEmitir.fnCargarLista) {
                            datosEmitir.fnCargarLista();
                        }
                    }
                    return dRetorno;
                }).catch(error => { console.log(error); });
        };
        if (datosEmitir.cargar) {
            return accionGuardarEliminar(datosEmitir.cargar.data);
        }
        let promesa;
        switch (v.tipo) {
            case 'ver':
                if (objThis.seleccionados[datosEmitir.strSeleccionado] == v.item) {
                    objThis.seleccionados[datosEmitir.strSeleccionado] = null;
                }
                else {
                    objThis.seleccionados[datosEmitir.strSeleccionado] = v.item;
                }
                break;
            case 'guardar':
                promesa = this.enviarFormulario('mantenimiento#' + (datosEmitir.strSeleccionado), frmRegistro, true, datosEmitir.prefix);
                if (datosEmitir.retornarRespuestaGuardadoEliminado) {
                    return promesa;
                }
                return promesa.then(dRet => {
                    return accionGuardarEliminar(dRet);
                });
            case 'eliminar':
                promesa = this.eliminarDatosAsync('mantenimiento#' + (datosEmitir.strSeleccionado), {id: objThis.seleccionados[datosEmitir.strSeleccionado][datosEmitir.idxTabla]}, datosEmitir.mensajeEliminar ?? null, datosEmitir.prefix)
                if (datosEmitir.retornarRespuestaGuardadoEliminado) {
                    return promesa;
                }
                return promesa.then(dRet => {
                    accionGuardarEliminar(dRet);
                });
        }
        return new Promise<any>((resolve, reject) => {
            reject(v);
        });
    }
}
