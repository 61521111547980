import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Self, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {FocusMonitor} from '@angular/cdk/a11y';
import {MatInput} from '@angular/material/input';
import {merge, Observable, Subject} from 'rxjs';
import {ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {filter, map, take} from 'rxjs/operators';
import {esNumero, mostrarValorEnBusqueda} from '@JVSoft/services/funciones-globales.service';
import {QueryGeneralService} from '../../services/query-general.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DataServidor} from '@JVSoft/interfaces/global';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {fadeInRight400ms} from '@vex/animations/fade-in-right.animation';
import {scaleIn400ms} from '@vex/animations/scale-in.animation';
import {stagger40ms} from '@vex/animations/stagger.animation';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'general-mat-buscar-tarea',
    templateUrl: './mat-buscar-tarea.component.html',
    styleUrls: ['./mat-buscar-tarea.component.scss'],
    animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MatBuscarTareaComponent,
        },
    ],
})
export class MatBuscarTareaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
    manejarKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
          event.preventDefault();
        }
      }
    dataServidorSuscripcion: DataServidor = {};
    dataServidor: {
        bud: {
            tareas: null;
        };
        buda: {
            tareas: 'TEXTO';
        };
        grl: {
            personas: null;
        };
    };
    filtrados = {};

    showPanel$: Observable<boolean>;
    private isPanelVisible$: Observable<boolean>;
    @ViewChild(MatInput, {read: ElementRef, static: true}) private txtFiltro: ElementRef;

    get incluirProveedorRemoto(): boolean {
        return this._incluirProveedorRemoto;
    }

    @Input() set incluirProveedorRemoto(val: boolean | '') {
        this._incluirProveedorRemoto = val || true;
    }

    get sinContenedor(): boolean {
        return this._sinContenedor;
    }

    @Input() set sinContenedor(val: boolean | '') {
        this._sinContenedor = val || true;
    }

    @Input()
    set value(value: any) {
        // this.formPrincipal.patchValue(value);
        this._value = value;
        this.stateChanges.next();
    }

    get value() {
        return this._value;
        // return this.formPrincipal.value;
    }

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    get placeholder() {
        return this._placeholder;
    }

    get empty(): boolean {
        return !this._value && !this.frmPrincipal.get('iTareaId').value;
        // return !this.value.iTareaId && !this.value.txtBuscar;
    }

    @HostBinding()
    get shouldLabelFloat(): boolean {
        return true;
        // return this.seleccionarTipo;
        return this.focused || !this.empty || this.frmPrincipal.get('txtBuscar').value;
    }

    get errorState(): boolean {
        return this.errorStateMatcher.isErrorState(this.ngControl?.control, null);
    }

    constructor(
        private focusMonitor: FocusMonitor,
        @Optional() @Self() public ngControl: NgControl,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private errorStateMatcher: ErrorStateMatcher,
        private queryGeneralService: QueryGeneralService,
    ) {
        if (ngControl != null) {
            ngControl.valueAccessor = this;
        }
        console.log('ENTRA AL CONSTRUCTOR ');
        this.cargaInicial();
    }

    static nextId = 0;
    @Input() seleccionarTipo = true;
    @Input() tipoPersona: 'trabajadores' | 'individuos' | 'prestadores' | 'proveedores' | number | null = 'individuos';
    @Input() iTipoIdentId: number;
    @Input() anonimo = false;
    @Input() readonly = false;
    // @Input() sinContenedor = false;
    @Input() idxTipoIdentidadNoPermitidos = [2];

    @Input() registro = false;
    @Input() dataRegistro = {};

    private _incluirProveedorRemoto: boolean;

    private _sinContenedor: boolean;

    @Output() resultados = new EventEmitter<any>();
    @Output() textoEscrito = new EventEmitter<any>();
    @Output() dataSeleccionado = new EventEmitter<any>();

    @ViewChild('dialogResultados', {static: true}) dialogResultadosRef: TemplateRef<any>;

    infoSeleccionado = null;

    dialogRefSeleccion: MatDialogRef<any>;
    dialogOpc: MatDialogConfig = {
        panelClass: 'dialogMantenimientoNuevo',
        disableClose: true,
        data: {},
    };
    @ViewChild(MatInput, {read: ElementRef, static: true})
    input: ElementRef;

    private _value: any;

    private _placeholder: string;

    focused: boolean;

    @HostBinding()
    readonly id = `general-mat-buscar-tarea-id-${MatBuscarTareaComponent.nextId++}`;

    @HostBinding('attr.aria-describedby')
    userAriaDescribedBy = '';

    @Input()
    required: boolean;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.frmPrincipal.disable() : this.frmPrincipal.enable();
        this.stateChanges.next();
    }

    private _disabled = false;

    controlType = 'general-mat-buscar-tarea';
    stateChanges = new Subject<void>();

    onChange: (value: any) => {};
    onTouched: () => {};

    frmPrincipal: FormGroup = this.fb.group({
        iYearId: [''],
        iTareaId: [''],

        tipoPersona: [''],
        txtBuscar: [''],

        cPersNombreLargo: [''],
        cTipoIdentSigla: [''],
        cPersDocumento: [''],
    });

    readonly autofilled: boolean;

    // readonly stateChanges = new Subject<void>();
    //
    // readonly autofilled: boolean;
    //
    //
    // readonly ngControl: NgControl | AbstractControlDirective | null = null;
    //
    //
    estaCargandoDatos = false;

    ngOnInit(): void {
        this.isPanelVisible$ = this.focusMonitor.monitor(this.txtFiltro).pipe(
            filter((focused) => !!focused),
            map(() => true)
        );
        this.showPanel$ = merge(this.isPanelVisible$);

        this.focusMonitor.monitor(this.input).subscribe((focused) => {
            this.focused = !!focused;
            this.stateChanges.next();
        });
        this.focusMonitor
            .monitor(this.input)
            .pipe(take(1))
            .subscribe((focused) => {
                this.onTouched();
            });


        this.frmPrincipal.valueChanges.subscribe((value) => {
            // console.log(value);
            if (value && value['iTareaId']) {
                this.onChange(value['iTareaId']);
            } else {
                if (value['iTareaId'] === null) {
                    this.onChange('');
                }
            }
            // this.onChange(value);
        });

        this.frmPrincipal.get('iYearId').valueChanges.subscribe(val => {
            if (val) {
                this.buscarTarea({iYearId: val});
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {}

    ngAfterViewInit() {
        if (this.sinContenedor) {
            this.input.nativeElement.classList.remove('mat-input-element');
        }
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.input);
        this.stateChanges.complete();
    }

    onContainerClick(event: MouseEvent): void {
        this.focusMonitor.focusVia(this.input, 'program');
    }

    setDescribedByIds(ids: string[]): void {
        this.userAriaDescribedBy = ids.join(' ');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        console.log('ENTRA AL ASIGNADOR ');
        this.value = obj;

        if (obj && esNumero(obj) && !this.frmPrincipal.get('iTareaId').value) {
            this.frmPrincipal.get('iTareaId').setValue(obj);
            this.buscarTarea({iTareaId: obj});
            // this.buscarPersona();
        }
    }

    buscarTarea(paramReq) {
        if (paramReq.iYearId) {
            this.frmPrincipal.get('iTareaId').reset();
            this.txtFiltro.nativeElement.value = '';
        }
        this.queryGeneralService.cargar(this, ['bud.tareas'], paramReq).then((dRec) => {
            if (dRec && dRec['bud.tareas'] && dRec['bud.tareas'][0]) {
                console.log('DESPUES DE LA CARGA');
                if (paramReq.iTareaId) {
                    // this.frmPrincipal.get('iTareaId').setValue(paramReq.iTareaId);
                    this.frmPrincipal.get('iYearId').setValue(dRec['bud.tareas'][0]['iYearId']);
                    setTimeout(() => {

                        this.seleccionarTarea(dRec['bud.tareas'][0]);
                    }, 1);


                }
            }
        });
    }

    cargaInicial() {
        this.queryGeneralService.cargar(this, ['grl.years']).then((dRet) => {
            if (dRet && dRet['grl.years'] && dRet['grl.years'][0]) {
                this.frmPrincipal.get('iYearId').setValue(dRet['grl.years'][0]['iYearId'] /*, {emitEvent: !this.frmPrincipal.get('iTareaId').value}*/);
            }
        });
    }

    cambiarYear(val) {
        console.log(val);
        this.buscarTarea({iYearId: val});
    }

    limpiarTarea() {
        this.frmPrincipal.patchValue({iTareaId: ''});
        this.txtFiltro.nativeElement.value = '';
        this.onChange('');
    }

    seleccionarTarea(item) {
        console.log(item);
        if (item) {
            this.frmPrincipal.get('iTareaId').setValue(item.iTareaId);
            this.txtFiltro.nativeElement.value = `[${item.cTareaCodigo}] ${item.cTareaNombre}`;
            this.txtFiltro.nativeElement.focus = false;
            // this.showPanel$
        }
    }

    protected readonly mostrarValorEnBusqueda = mostrarValorEnBusqueda;
}
