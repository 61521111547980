import {MatDateFormats, NativeDateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {Injectable} from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class GlobalDateAdapter extends NativeDateAdapter {

	format(date: Date, displayFormat): string {
		if (displayFormat === 'input') {

			return moment(date).format('DD/MM/YYYY');

			/*
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();

			// return `${day}/${month}/${year}`;
			return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
			*/
		}

		moment.locale('es-PE');
		// return date.toDateString();
		return moment(date).format('MMMM YYYY');
	}

	parse(value: any): Date | null {
		const date = moment(value, 'DD/MM/YYYY');
		return date.isValid() ? date.toDate() : null;
	}
}
export const APP_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: { month: 'long', year: 'numeric', day: 'numeric' },
	},
	display: {
		dateInput: 'input',
		monthYearLabel: { year: 'numeric', month: 'long'/*, day: 'numeric'*/ },
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric'},
		monthYearA11yLabel: { year: 'numeric', month: 'long' },
	}
};
