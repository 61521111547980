import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {registerLocaleData} from '@angular/common';
import localeEsPe from '@angular/common/locales/es-PE';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VexModule} from '@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {GeneralModule} from './general/general.module';
import {ToastrModule} from 'ngx-toastr';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {NotificacionesInterceptor} from './interceptors/notificaciones.interceptor';
import {FormDataInterceptor} from './interceptors/form-data.interceptor';
import {SessionInterceptor} from './session/incerceptors/session.interceptor';
import {LayoutsModule} from "./layouts/layouts.module";
import {VexModModule} from "@vexMod/vex-mod.module";
import {GetDataInterceptor} from './interceptors/get-data.interceptor';
import {ApiFallbackInterceptor} from './interceptors/api-fallback.interceptor';

registerLocaleData(localeEsPe, 'es');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // Vex
        VexModule,
        VexModModule,
        CustomLayoutModule,

        // JVSOFT
        JVSoftModule,
        GeneralModule,
        LayoutsModule,

        ToastrModule.forRoot(), // ToastrModule added
    ],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'} as MatFormFieldDefaultOptions},
        // {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: ApiFallbackInterceptor, multi: true },
        {provide: HTTP_INTERCEPTORS, useClass: GetDataInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: NotificacionesInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: FormDataInterceptor, multi: true},
        // {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'es'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
