<form globalDialogFlotante btnGuardar btnCerrar="CANCELAR" [cssClases]="{btnGuardar: 'text-primary-contrast'}" (ngSubmit)="save()" [formGroup]="frmRegistro">
	<ng-container titulo>
		<h4 *ngIf="frmRegistro.get('cProductoNombre').value"
			class="m-0"
			fxFlex="auto">{{ frmRegistro.get('cProductoNombre').value }}</h4>
		<h4 *ngIf="!frmRegistro.get('cProductoNombre').value"
			class="m-0"
			fxFlex="auto">Nuevo Producto</h4>
	</ng-container>
	<div cuerpo fxLayout="column">

		<div class="mt-2" fxLayout="column" fxLayoutGap="8px" *ngIf="isCreateMode()">
			<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px">
				<mat-form-field fxFlex="auto">
					<mat-label>Tipo de Producto</mat-label>
					<mat-select formControlName="iTipoProductoId">
						<mat-option [value]="item.iTipoProductoId" *ngFor="let item of dataServidor.log.tipos_productos">{{ item.cTipoProductoNombre }}</mat-option>
					</mat-select>
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoProductoId')) }}</mat-error>
				</mat-form-field>

                <mat-form-field fxFlex="auto" *ngIf="entidadService.esSector('privado') && dataServidor.log.categorias">
                    <mat-label>Categoría</mat-label>
                    <textarea matInput formControlName="iCategoriaId" [matAutocomplete]="iCategoriaId" class="text-xs font-thin" cdkTextareaAutosize cdkAutosizeMinRows="1" autocomplete="off"></textarea>
                    <mat-autocomplete #iCategoriaId="matAutocomplete"
                                      [panelWidth]="'auto'"
                                      [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iCategoriaId', campoValue: ['cCategoriaCodigo', 'cCategoriaNombre'], lista: dataServidor.log.categorias})"
                    >
                        <mat-option *ngFor="let item of filtrados['log.categorias'] | async" [value]="item.iCategoriaId">
                            <small><span class="text-red">[{{ item.cCategoriaCodigo }}]</span> {{ item.cCategoriaNombre }}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iCategoriaId')) }}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="auto" *ngIf="entidadService.esSector('publico') && dataServidor.log.grupos_bien_servicio">
                    <mat-label>Grupo</mat-label>
                    <textarea matInput formControlName="cGrupoCodigo" [matAutocomplete]="cGrupoCodigo" class="text-xs font-thin" cdkTextareaAutosize cdkAutosizeMinRows="1" autocomplete="off"></textarea>
                    <mat-autocomplete #cGrupoCodigo="matAutocomplete"
                                      [panelWidth]="'auto'"
                                      [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'cGrupoCodigo', campoValue: ['cGrupoCodigo', 'cGrupoNombre'], lista: dataServidor.log.grupos_bien_servicio})"
                    >
                        <mat-option *ngFor="let item of filtrados['log.grupos_bien_servicio'] | async" [value]="item.cGrupoCodigo">
                            <small><span class="text-red">[{{ item.cGrupoCodigo }}]</span> {{ item.cGrupoNombre }}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cGrupoCodigo')) }}</mat-error>
                </mat-form-field>
			</div>

            <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px" *ngIf="entidadService.esSector('publico')">
                <mat-form-field fxFlex="auto" *ngIf="dataServidor.log.clases_bien_servicio">
                    <mat-label>Clase</mat-label>
                    <textarea matInput formControlName="cClaseCodigo" [matAutocomplete]="cClaseCodigo" class="text-xs font-thin" cdkTextareaAutosize cdkAutosizeMinRows="1" autocomplete="off"></textarea>
                    <mat-autocomplete #cClaseCodigo="matAutocomplete"
                                      [panelWidth]="'auto'"
                                      [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'cClaseCodigo', campoValue: ['cClaseCodigo', 'cClaseNombre'], lista: dataServidor.log.clases_bien_servicio})"
                    >
                        <mat-option *ngFor="let item of filtrados['log.clases_bien_servicio'] | async" [value]="item.cClaseCodigo">
                            <small><span class="text-red">[{{ item.cClaseCodigo }}]</span> {{ item.cClaseNombre }}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cClaseCodigo')) }}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="auto" *ngIf="dataServidor.log.familias_bien_servicio">
                    <mat-label>Familia</mat-label>
                    <textarea matInput formControlName="cFamiliaCodigo" [matAutocomplete]="cFamiliaCodigo" class="text-xs font-thin" cdkTextareaAutosize cdkAutosizeMinRows="1" autocomplete="off"></textarea>
                    <mat-autocomplete #cFamiliaCodigo="matAutocomplete"
                                      [panelWidth]="'auto'"
                                      [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'cFamiliaCodigo', campoValue: ['cFamiliaCodigo', 'cFamiliaNombre'], lista: dataServidor.log.familias_bien_servicio})"
                    >
                        <mat-option *ngFor="let item of filtrados['log.familias_bien_servicio'] | async" [value]="item.cFamiliaCodigo">
                            <small><span class="text-red">[{{ item.cFamiliaCodigo }}]</span> {{ item.cFamiliaNombre }}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cFamiliaCodigo')) }}</mat-error>
                </mat-form-field>
            </div>

		</div>

		<div class="mt-2" fxLayout="column" fxLayoutGap="8px" *ngIf="isUpdateMode()">
			<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px">
				<mat-form-field fxFlex="auto">
					<mat-label>Tipo de Producto</mat-label>
					<input disabled matInput [value]="datosDialogRecibidos.producto.cTipoProductoNombre">
				</mat-form-field>

				<mat-form-field fxFlex="auto" *ngIf="entidadService.esSector('privado')">
					<mat-label>Categoría</mat-label>
					<input disabled matInput [value]="datosDialogRecibidos.producto.cCategoriaNombre">
				</mat-form-field>

				<mat-form-field fxFlex="auto" *ngIf="entidadService.esSector('publico')">
					<mat-label>Grupo</mat-label>
					<input disabled matInput [value]="datosDialogRecibidos.producto.cGrupoNombre">
				</mat-form-field>
			</div>
			<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px" *ngIf="entidadService.esSector('publico')">
				<mat-form-field fxFlex="auto">
					<mat-label>Clase</mat-label>
					<input disabled matInput [value]="datosDialogRecibidos.producto.cClaseNombre">
				</mat-form-field>

				<mat-form-field fxFlex="auto">
					<mat-label>Familia</mat-label>
					<input disabled matInput [value]="datosDialogRecibidos.producto.cFamiliaNombre">
				</mat-form-field>
			</div>

		</div>

		<div class="mt-2" fxLayout="column" fxLayoutGap="8px">

			<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px">
				<mat-form-field fxFlex="auto">
					<mat-label>Nombre Producto</mat-label>
					<input formControlName="cProductoNombre" matInput>
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cProductoNombre')) }}</mat-error>
				</mat-form-field>
				<mat-form-field fxFlex="auto" *ngIf="dataServidor.log.presentaciones">
					<mat-label>Presentacion</mat-label>
					<input matInput formControlName="iPresentacionCompraId" [matAutocomplete]="iPresentacionCompraId" autocomplete="off">
					<mat-autocomplete #iPresentacionCompraId="matAutocomplete"
									  panelWidth="auto"
									  [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iPresentacionId', campoValue: 'cPresentacionNombre', lista: dataServidor.log.presentaciones})"
					>
						<mat-option *ngFor="let item of filtrados['log.presentaciones'] | async" [value]="item.iPresentacionId">
							<small>{{ item.cPresentacionNombre }}</small>
						</mat-option>
					</mat-autocomplete>
					<mat-icon matSuffix svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iPresentacionCompraId')) }}</mat-error>
				</mat-form-field>

                <mat-form-field fxFlex="auto">
                    <mat-label>Costo</mat-label>
                    <input formControlName="nProductoCostoCompra" matInput type="number" autocomplete="off">
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('nProductoCostoCompra')) }}</mat-error>
                </mat-form-field>
			</div>

		</div>


	</div>

	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close type="button">CANCELAR</button>
		<button *ngIf="isCreateMode()" color="primary" mat-button type="submit">CREAR PRODUCTO</button>
		<button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">ACTUALIZAR PRODUCTO</button>
	</mat-dialog-actions>
</form>
