import {Component, Input, OnInit} from '@angular/core';
import {ordenarPorPropiedad} from '@JVSoft/services/funciones-globales.service';

export interface EstilosBarra {
	valorMaximo: number;
	class?: string;
	style?: string;
}

@Component({
	selector: 'jvsoft-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {

	@Input() objThis;
	@Input() porcentaje = 0;
	@Input() textoCentrado = false;

    private _textoMostrar: string;
    get textoMostrar(): string { return this._textoMostrar; }
	@Input() set textoMostrar(val: string) { this._textoMostrar = val; }

    private _formatoNumero: string;
    get formatoNumero(): string { return this._formatoNumero; }
	@Input() set formatoNumero(val: string) { this._formatoNumero = val ?? '1.0-0'; }

    private _coloresValor: EstilosBarra[];
    get coloresValor(): EstilosBarra[] { return this._coloresValor; }
    @Input() set coloresValor(val: EstilosBarra[]) {
        if (!val || !Array.isArray(val)) {
            this._coloresValor = [
                {valorMaximo: 1, class: 'p-0'},
                {valorMaximo: 40, class: 'bg-red-400 text-red-900'},
                // {valorMaximo: 35, class: 'bg-red-600 text-red-100'},
                {valorMaximo: 80, class: 'bg-yellow-600 text-yellow-100'},
                {valorMaximo: 100, class: 'bg-green-600 text-green-100'},
            ];
        }
        else {
            this._coloresValor = val;
        }
    }

	constructor() { }

	ngOnInit(): void {
	}

	colorEstilo(): {style: string, class: string} {
		let estiloFin = null;
		let claseFin = null;
		let encontro = false;

		this.coloresValor = ordenarPorPropiedad(this.coloresValor, 'valorMaximo');

		this.coloresValor.forEach(estilo => {
			if (!encontro && (this.porcentaje <= estilo.valorMaximo)) {
				// console.log(this.porcentaje, estilo, (this.porcentaje < estilo.valorMaximo));
				encontro = true;
				estiloFin = estilo.style;
				claseFin = estilo.class;
			}
		});
		return {
			style: estiloFin,
			class: claseFin,
		};
	}

}
