import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConfiguracionWebService} from '@servicios/configuracion-web.service';

@Injectable()
export class ApiFallbackInterceptor implements HttpInterceptor {

	constructor(
		private configuracionWebService: ConfiguracionWebService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// if (!!!this.configuracionWebService.apiData) {
		// 	if (!!environment['apiHierarchy'] && environment.apiHierarchy.length > 0) {
		// 		this.configuracionWebService.apiData = environment.apiHierarchy[0];
		// 	}
		// }
		if (request.url.startsWith(this.configuracionWebService.CONST_BACKEND_API)) {
			// if (getLocalStorage(localStorageKeys.apiDataKey)) {
			// 	console.log(desencriptar(getLocalStorage(localStorageKeys.apiDataKey)));
			// }
			request = request.clone({
				url: request.url.replace(this.configuracionWebService.CONST_BACKEND_API, this.configuracionWebService.apiData.backendApi)
			});
		}
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const urlApiAct = this.configuracionWebService.apiData.backendApi;
				if (error.status === 0 && request.url.startsWith(urlApiAct)) {
					this.configuracionWebService.setApiDataProximo();
					// Si la solicitud a la intranet falla, intentar la internet
					const fallbackRequest = request.clone({
						url: request.url.replace(urlApiAct, this.configuracionWebService.apiData.backendApi)
					});
					return next.handle(fallbackRequest);
				}
				/*if (error.status === 0 && request.url.startsWith(environment.backendApi)) {
					// Si la solicitud a la intranet falla, intentar la internet
					const fallbackRequest = request.clone({
						url: request.url.replace(environment.backendApi, environment.api.internet.backendApi)
					});
					return next.handle(fallbackRequest);
				}*/
				return throwError(error);
			})
		);
	}
}
