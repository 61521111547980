<ng-container *ngIf="sinTitulo else conTitulo">
    <div>
        <ng-container [ngTemplateOutlet]="parteComun"></ng-container>
    </div>
</ng-container>

<ng-template #conTitulo>
    <div cdkDrag class="block h-full" cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
        <div class="flex-auto flex items-center justify-between hover:cursor-move gap-1"
             [class]="cssClases['titulo']" [class.bg-primary]="!cssClases['titulo']"
             [class.text-primary-contrast]="!cssClases['titulo']"
             mat-dialog-title cdkDragHandle
        >

            <ng-content select="[tituloImagen]"></ng-content>
            <h5 class="flex-1 m-0">
                <ng-content select="[titulo]"></ng-content>
            </h5>
            <ng-content select="[selectTitulo]"></ng-content>

            <button mat-dialog-close *ngIf="(btnCerrar || btnCerrarBarra) && !matDialogRefActual"
                    class="flex items-center justify-between ml-2 h-6 w-6 bg-red-700 rounded-md text-white "
                    type="button"
            >
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
            <button matRipple *ngIf="(btnCerrar || btnCerrarBarra) && matDialogRefActual"
                    (click)="cerrarDialogo()"
                    class="flex items-center justify-center ml-2 h-6 w-6 bg-red-700 rounded-md text-white"
                    type="button"
            >
                <mat-icon svgIcon="mat:close" class="flex-none"></mat-icon>
            </button>
        </div>

        <mat-divider class="flex-auto bg-primary"></mat-divider>
        <ng-container [ngTemplateOutlet]="parteComun"></ng-container>
    </div>
</ng-template>

<ng-template #parteComun>

    <mat-dialog-content class="flex-1">
        <div class="contenedor-cuerpo py-2">
            <ng-content select="[cuerpo]"></ng-content>
        </div>

    </mat-dialog-content>
    <!--	<mat-divider class="bg-primary"></mat-divider>-->
    <mat-dialog-actions class="flex-auto flex items-center justify-between gap-1" *ngIf="!sinBarraDeAccion">
        <div class="flex items-start justify-start gap-1">
            <ng-content select="[pieIzquierda]"></ng-content>
        </div>
        <div class="flex items-center justify-center gap-1">
            <ng-content select="[pieCentro]"></ng-content>
        </div>
        <div class="flex items-end justify-end gap-1">
            <ng-content select="[pieDerecha]"></ng-content>
            <button matRipple *ngIf="btnGuardar && !cssClases['btnGuardar']" type="submit"
                    class="boton-dialog-flotante"
                    [ngClass]="btnGuardar.class" (click)="guardarDialogo()"
            >
                <mat-icon class="icon-xs" svgIcon="mat:save"></mat-icon>
                <span>{{ btnGuardar.label }}</span>
            </button>
            <button matRipple *ngIf="btnGuardar && cssClases['btnGuardar']" type="submit"
                    class="boton-dialog-flotante"
                    [ngClass]="btnGuardar.class" [class]="cssClases['btnGuardar']"
                    (click)="guardarDialogo()"
            >
                <mat-icon class="icon-xs" svgIcon="mat:save"></mat-icon>
                <span>{{ btnGuardar.label }}</span>
            </button>
            <button matRipple mat-dialog-close *ngIf="btnCerrar && !matDialogRefActual" type="button"
                    class="boton-dialog-flotante" [ngClass]="btnCerrar.class"
            >
                <mat-icon class="icon-xs" svgIcon="mat:close"></mat-icon>
                <span>{{ btnCerrar.label }}</span>
            </button>
            <button matRipple *ngIf="btnCerrar && matDialogRefActual" type="button"
                    class="boton-dialog-flotante"
                    [ngClass]="btnCerrar.class" (click)="cerrarDialogo()"
            >
                <mat-icon class="icon-xs" svgIcon="mat:close"></mat-icon>
                <span>{{ btnCerrar.label }}</span>
            </button>
        </div>
        <!--		<button color="primary" class="rounded px-2 py-1 font-medium text-xs" matRipple type="submit">GUARDAR</button>-->
    </mat-dialog-actions>

</ng-template>

