import {b64Decode} from '@JVSoft/services/funciones-globales.service';
import * as moment from 'moment';

export interface JwtTokenResponse {
    access_token: string;
    expires_in: number;
    token_type: string;
    user: any;
}

export interface DataSessionStorage {
    tokenStringKey?: string;
    changePasswordKey?: string;
    logoUrl?: string;
    logoLogin?: string;
    backgroundLogin?: string;
    favicon?: string;
    darkMode?: string;
    serverTimestamp?: string;
    apiDataKey?: string;
}

let dataSessionStorageKey: DataSessionStorage = {
    tokenStringKey: 'JVSoftTkn',
    changePasswordKey: 'chPwd',
    logoUrl: 'logo_url',
    logoLogin: 'login-logo',
    backgroundLogin: 'login-background',
    favicon: 'favicon',
    darkMode: 'darkMode',
    serverTimestamp: 'srvtmstp',
    apiDataKey: 'reqDt',
};
export let localStorageKeys = dataSessionStorageKey;

export function inicializarVariablesSessionStorage(datSesion: DataSessionStorage) {
    dataSessionStorageKey = datSesion;
}

export function setJwtTokenData(data: string) {
    localStorage.setItem(dataSessionStorageKey.tokenStringKey, data)
}
export function jwtTokenData(key: string = dataSessionStorageKey.tokenStringKey): JwtTokenResponse {
    const tokenObj = localStorage.getItem(key);
    try {
        return JSON.parse(tokenObj) as JwtTokenResponse;
    } catch (error) {
        return null;
    }
}

export function jwtToken(key: string = dataSessionStorageKey.tokenStringKey) {
    const varJwtTokenData = jwtTokenData(key);
    if (varJwtTokenData) {
        return varJwtTokenData.access_token;
    }
    return null;
}

export function jwtTokenExpiracion(key: string = dataSessionStorageKey.tokenStringKey) {
    const jwtStr = jwtToken(key);
    const arrayJwt = jwtStr.split('.');
    if (arrayJwt.length > 1) {
        return /*new Date*/(JSON.parse(b64Decode(arrayJwt[1]))['exp'] * 1000);
    }
    return null;
}

export function jwtTokenExpiracionFaltante(key: string = dataSessionStorageKey.tokenStringKey) {
    return Math.abs(moment().diff(jwtTokenExpiracion(), 'seconds'));
}

export function setCambiarPwd(accion: boolean = true) {
    localStorage.setItem(dataSessionStorageKey.changePasswordKey, (accion ? 1:0).toString())
}

export function getCambiarPwd() {
    const frmCambioPwd = localStorage.getItem(dataSessionStorageKey.changePasswordKey);
    if (!frmCambioPwd) {
        return null;
    }
    return frmCambioPwd == '1';
}

export function delLocalStorage(keyElim: string[] = [
    dataSessionStorageKey.tokenStringKey,
    dataSessionStorageKey.changePasswordKey,
    dataSessionStorageKey.apiDataKey,
]) {
    keyElim.forEach(key => {
        localStorage.removeItem(key);
    });
}

export function getLocalStorage(key: string) {
    return localStorage.getItem(key);
}
export function setLocalStorage(key: string, value = null) {
    localStorage.setItem(key, value.toString())
}
