import {groupBy, nestGroupsBy, ordenarPorPropiedad, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {DataEnviarPdfPresupuesto, PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';

export function expedienteSecuenciaDocumentoMetaFase(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.Expediente_Secuencia_Documento_Meta_Fase'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.Expediente_Secuencia_Documento_Meta_Fase'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 80, 40, 40],
			orientacion: 'landscape',
			tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EXPEDIENTES REGISTRADOS CON SUS FASES DEL SIAF', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'Año', property: 'Ano_eje', type: 'text'},
			{label: 'Nº SIAF', property: 'Expediente', type: 'text'},
			{label: 'C', property: 'Ciclo', type: 'text'},
			{label: 'F', property: 'Fase', type: 'text'},
			{label: 'T.O.', property: 'Tipo_operacion', type: 'text'},
			{label: 'Certificado', property: 'Certificado', type: 'text'},
			{label: 'Sec.', property: 'Certificado_secuencia', type: 'text'},
			{label: 'FF', property: 'Fuente_financ', type: 'text'},
			{label: 'TR', property: 'Tipo_recurso', type: 'text'},
			{label: 'Cod.', property: 'Cod_doc', type: 'text'},
			{label: 'Documento', property: 'Abreviatura_doc', type: 'text'},
			{label: 'Ser.', property: 'Serie_doc', type: 'text'},
			{
				label: 'Número', property: 'Num_doc', type: 'number', transformarDirecto(row): any {
					return imprimirCelda(row['Num_doc'], null, {
						bold: true,
						alignment: 'center',
						style: 'tdSmall',
						noWrap: true
					});
				}
			},
			{label: 'Fecha', property: 'fecha_doc', type: 'date'},
			{label: 'Cod.', property: 'Cod_doc_b', type: 'text'},
			{label: 'Documento b', property: 'Abreviatura_doc_b', type: 'text'},
			{label: 'Número b', property: 'Num_doc_b', type: 'text'},
			{label: 'Fecha b', property: 'fecha_doc_b', type: 'date'},
			{label: 'Meta', property: 'Sec_func', type: 'text'},
			{label: 'Clasificador', property: 'Clasificador', type: 'text'},
			{label: 'Monto', property: 'Monto', type: 'money'},
			{label: 'Proveedor / Persona', property: 'Proveedor_Nombre', type: 'text'},
			{label: 'Entrega', property: 'Fecha_entrega', type: 'date'},
			{label: 'Pago', property: 'Fecha_pago', type: 'date'},
		];

		const agrupadoMeta = groupBy(resultadosGenerales, (obj) => {
			return 'META PRESUPUESTAL: ' + obj.Sec_func + ' - ' + obj.cNombre_Sec_func;
		});

		let dataMeta = {
			Sec_func: '',
			cNombre_Sec_func: '',
			suma: '',
		};

		let pag = 0;
		let nuevoCuerpo = [];
		Object.keys(agrupadoMeta).forEach((secGrupo) => {
			if (pag == 0) {
				dataMeta = {
					Sec_func: agrupadoMeta[secGrupo][0]['Sec_func'],
					cNombre_Sec_func: agrupadoMeta[secGrupo][0]['cNombre_Sec_func'],
					suma: sumarObjetos(agrupadoMeta[secGrupo], ['Monto'])['Monto'],
				};
			} else if (pag > 0) {
				cuerpoPdf.push({
					text: '',
					pageBreak: 'before',
				});
			}
			nuevoCuerpo = nuevoCuerpo.concat([
				[
					imprimirCelda(secGrupo, null, {
						bold: true,
						fontSize: 8,
						style: 'thSmall',
						alignment: 'center',
						colSpan: 25
					}),
					{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
				]
			]);

			const agrupadoFF = groupBy(agrupadoMeta[secGrupo], (obj) => {
				return 'FUENTE FINANCIAMIENTO: ' + obj.Fuente_financ + ' - ' + obj.Nombre_FuenteFinanc;
			});

			let dataFF = {
				Fuente_financ: '',
				Nombre_FuenteFinanc: '',
				suma: '',
			};

			let pagFF = 0;
			Object.keys(agrupadoFF).forEach((secGrupoFF) => {
				console.warn(secGrupoFF);

				const tablaGenerada = objThis.generarDesdeMantenimiento({
					titulos: camposPrincipal,
					contenido: ordenarPorPropiedad(agrupadoFF[secGrupoFF], 'Numero_doc_Girado'),
					numeracion: true,
					idxResto: [23],
					margin: [0, 0, 0, 5],
					separado: true,
				});
				if (pagFF == 0) {
					dataFF = {
						Fuente_financ: agrupadoFF[secGrupoFF][0]['Fuente_financ'],
						Nombre_FuenteFinanc: agrupadoFF[secGrupoFF][0]['Nombre_FuenteFinanc'],
						suma: sumarObjetos(agrupadoFF[secGrupoFF], ['Monto'])['Monto'],
					};

				}

				nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['titulos']).concat([
					[
						imprimirCelda(secGrupoFF, null, {
							bold: true,
							fontSize: 7,
							style: 'thSmall',
							alignment: 'center',
							colSpan: 25
						}),
						{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
					]
				]).concat(tablaGenerada['cuerpo']).concat([
					[
						imprimirCelda(dataFF.Nombre_FuenteFinanc + ' - SUB TOTAL: ', null, {
							bold: true,
							fontSize: 7,
							style: 'thSmall',
							italics: true,
							alignment: 'right',
							colSpan: 21
						}),
						{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
						imprimirCelda(dataFF.suma, 'money', {
							bold: true,
							fontSize: 9,
							style: 'thSmall',
							italics: true,
							alignment: 'center'
						}),
						imprimirCelda(''),
						imprimirCelda(''),
						imprimirCelda(''),
					],
				]);
				pagFF++;
			});
			nuevoCuerpo = nuevoCuerpo.concat([
				[
					imprimirCelda(dataMeta.cNombre_Sec_func + ' - SUB TOTAL: ', null, {
						bold: true,
						fontSize: 8,
						style: 'thSmall',
						alignment: 'right',
						colSpan: 21
					}),
					{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
					imprimirCelda(dataMeta.suma, 'money', {
						bold: true,
						fontSize: 9,
						style: 'thSmall',
						alignment: 'center'
					}),
					imprimirCelda(''),
					imprimirCelda(''),
					imprimirCelda(''),
				],
			]);
			cuerpoPdf.push({
				margin: [0, 0, 0, 0],
				table: {
					dontBreakRows: true,
					// headerRows: 1,
					// headerRows: 3,
					widths: objThis.anchoCols([Object.keys(camposPrincipal).concat(['n'])], [23]),
					body: nuevoCuerpo,
				}
			});

			nuevoCuerpo = [];
			pag++;
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}

export function expedienteSecuenciaDocumentoMetaClasificadorMontoFases(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.Expediente_Secuencia_Documento_Meta_Clasificador_Monto_Fases'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.Expediente_Secuencia_Documento_Meta_Clasificador_Monto_Fases'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Act_proy');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 80, 40, 40],
			orientacion: 'landscape',
			tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EXPEDIENTES REGISTROS CON SUS FASES POR MES', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			// { label: 'Año', property: 'Ano_eje', type: 'text' },
			{label: 'Nº SIAF', property: 'Expediente', type: 'text'},
			{label: 'C', property: 'Ciclo', type: 'text'},
			{label: 'F', property: 'Fase', type: 'text'},
			{label: 'Secuencia', property: 'Secuencia', type: 'text'},
			{label: 'Correlativo', property: 'Correlativo', type: 'text'},
			{label: 'FF', property: 'Fuente_financ', type: 'text'},
			{label: 'TR', property: 'Tipo_recurso', type: 'text'},
			{label: 'Certificado', property: 'Certificado', type: 'text'},
			{label: 'Sec.', property: 'Certificado_secuencia', type: 'text'},
			{label: 'Cod.', property: 'Cod_doc', type: 'text'},
			{label: 'Documento', property: 'Abreviatura_doc', type: 'text'},
			{label: 'Ser.', property: 'Serie_doc', type: 'text'},
			{
				label: 'Número', property: 'Num_doc', type: 'number', transformarDirecto(row): any {
					return imprimirCelda(row['Num_doc'], null, {
						bold: true,
						alignment: 'center',
						style: 'tdSmall',
						noWrap: true
					});
				}
			},
			{label: 'Tipo Operación', property: 'Nombre_TipoOperacion', type: 'text'},
			{label: 'Nombre Proveedor', property: 'Proveedor_Nombre', type: 'text'},
			{label: 'Compromiso', property: 'nCompromiso', type: 'money'},
			{label: 'Devengado', property: 'nDevengado', type: 'money'},
			{label: 'Girado', property: 'nGirado', type: 'money'},
			{label: 'Pagado', property: 'nPagado', type: 'money'},
			{label: 'Pagado', property: 'nPagado', type: 'money'},
			{label: 'Glosa', property: 'Notas', type: 'text'},
		];


		const groups = nestGroupsBy(resultadosGenerales, [
			'Act_proy',
			'Sec_func',
			'Clasificador',
		]);
		console.log(groups);


		const agrupadoActProy = groupBy(resultadosGenerales, (obj) => {
			return obj.Act_proy;
		});

		let dataActProy = {
			Ano_eje: '',
			Act_proy: '',
			cNombre_Act_proy: '',
			nCompromiso: '',
			nDevengado: '',
			nGirado: '',
			nPagado: '',
		};

		let pag = 0;
		let nuevoCuerpo = [];
		Object.keys(agrupadoActProy).forEach((secActProy) => {
			dataActProy = {
				...{
					Ano_eje: agrupadoActProy[secActProy][0]['Ano_eje'],
					Act_proy: agrupadoActProy[secActProy][0]['Act_proy'],
					cNombre_Act_proy: agrupadoActProy[secActProy][0]['cNombre_Act_proy'],
				},
				...sumarObjetos(agrupadoActProy[secActProy], [
					'nCompromiso',
					'nDevengado',
					'nGirado',
					'nPagado',
				]),
			};

			if (pag > 0) {
				cuerpoPdf.push({
					text: '',
					pageBreak: 'before',
				});
			}
			nuevoCuerpo = nuevoCuerpo.concat([
				generarColSpan(imprimirCelda(dataActProy.Ano_eje, null, {
					bold: true,
					style: 'thSmall',
					alignment: 'left'
				}), 2).concat(
					generarColSpan(imprimirCelda(dataActProy.Act_proy, null, {
						bold: true,
						style: 'thSmall',
						alignment: 'left'
					}), 3),
					generarColSpan(imprimirCelda(dataActProy.cNombre_Act_proy, null, {
						bold: true,
						style: 'thSmall',
						alignment: 'left'
					}), 12),
					imprimirCelda(dataActProy.nCompromiso, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					imprimirCelda(dataActProy.nDevengado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					imprimirCelda(dataActProy.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					imprimirCelda(dataActProy.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right'}),
				),
			]);

			const agrupadoSecFunc = groupBy(agrupadoActProy[secActProy], (obj) => {
				return obj.Sec_func;
			});

			let dataSecFunc = {
				Sec_func: '',
				cNombre_Finalidad: '',
				nCompromiso: '',
				nDevengado: '',
				nGirado: '',
				nPagado: '',
			};

			let pagFF = 0;
			Object.keys(agrupadoSecFunc).forEach((secSecFunc) => {

				dataSecFunc = {
					...{
						Sec_func: agrupadoSecFunc[secSecFunc][0]['Sec_func'],
						cNombre_Finalidad: agrupadoSecFunc[secSecFunc][0]['cNombre_Finalidad'],
					},
					...sumarObjetos(agrupadoSecFunc[secSecFunc], [
						'nCompromiso',
						'nDevengado',
						'nGirado',
						'nPagado',
					]),
				};
				nuevoCuerpo = nuevoCuerpo.concat([
					generarColSpan(imprimirCelda('', null, {bold: true, style: 'thSmall'}), 5).concat(
						generarColSpan(imprimirCelda(dataSecFunc.Sec_func, null, {
							bold: true,
							style: 'thSmall',
							alignment: 'left'
						}), 2),
						generarColSpan(imprimirCelda(dataSecFunc.cNombre_Finalidad, null, {
							bold: true,
							style: 'thSmall',
							alignment: 'left'
						}), 10),
						imprimirCelda(dataSecFunc.nCompromiso, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataSecFunc.nDevengado, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataSecFunc.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(dataSecFunc.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right'}),
					),
				]);

				const agrupadoClasificador = groupBy(agrupadoSecFunc[secSecFunc], (obj) => {
					return obj.Clasificador;
				});

				let dataClasificador = {
					Clasificador: '',
					Nombre_Clasificador: '',
					nCompromiso: '',
					nDevengado: '',
					nGirado: '',
					nPagado: '',
				};

				let pagFF2 = 0;
				Object.keys(agrupadoClasificador).forEach((secClasificador) => {
					const tablaGenerada = objThis.generarDesdeMantenimiento({
						titulos: camposPrincipal,
						contenido: ordenarPorPropiedad(agrupadoClasificador[secClasificador], 'Expediente'),
						numeracion: true,
						idxResto: [23],
						margin: [0, 0, 0, 5],
						separado: true,
					});

					dataClasificador = {
						...{
							Clasificador: agrupadoClasificador[secClasificador][0]['Clasificador'],
							Nombre_Clasificador: agrupadoClasificador[secClasificador][0]['Nombre_Clasificador'],
						},
						...sumarObjetos(agrupadoClasificador[secClasificador], [
							'nCompromiso',
							'nDevengado',
							'nGirado',
							'nPagado',
						]),
					};

					nuevoCuerpo = nuevoCuerpo.concat([
						generarColSpan(imprimirCelda('', null, {bold: true, style: 'thSmall'}), 7).concat(
							generarColSpan(imprimirCelda(dataClasificador.Clasificador, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'left'
							}), 3),
							generarColSpan(imprimirCelda(dataClasificador.Nombre_Clasificador, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'left'
							}), 7),
							imprimirCelda(dataClasificador.nCompromiso, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataClasificador.nDevengado, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataClasificador.nGirado, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataClasificador.nPagado, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right'}),
						),
					]).concat(tablaGenerada['titulos']).concat(tablaGenerada['cuerpo']).concat([
						generarColSpan(imprimirCelda('', null, {fontSize: 3, border: [false, true, false, false]}), 22)
					]);

					pagFF2++;
				});

				nuevoCuerpo = nuevoCuerpo.concat([
					generarColSpan(imprimirCelda('', null, {border: [false, true, false, false]}), 22)
				]);


				pagFF++;
			});
			// console.log(nuevoCuerpo);
			// console.log(JSON.stringify(nuevoCuerpo));
			/*
			nuevoCuerpo = nuevoCuerpo.concat([
				[
					imprimirCelda(dataMeta.cNombre_Sec_func + ' - SUB TOTAL: ', null, {bold: true, fontSize: 8, style: 'thSmall', alignment: 'right', colSpan: 21}),
					{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
					imprimirCelda(dataMeta.suma, 'money', {bold: true, fontSize: 9, style: 'thSmall'}),
					imprimirCelda(''),
					imprimirCelda(''),
					imprimirCelda(''),
				],
			]);
			*/
			cuerpoPdf.push({
				margin: [0, 0, 0, 0],
				table: {
					dontBreakRows: true,
					// headerRows: 1,
					headerRows: 4,
					widths: objThis.anchoCols([Object.keys(camposPrincipal).concat(['n'])], [21]),
					body: nuevoCuerpo,
				}
			});

			nuevoCuerpo = [];
			pag++;
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}
