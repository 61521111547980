import {BotonMantenimiento, ColumnaTabla} from '@JVSoft/interfaces/global';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {formatDate} from '@angular/common';
import {establecerEstilosPorDefecto} from '../modulos.functions';
import {Archivo} from '@JVSoft/interfaces/archivo';
import {getDataArchivoFromPath} from '@JVSoft/services/funciones-globales.service';
import {Injectable} from '@angular/core';
import {EntidadService} from '@servicios/entidad.service';

interface ArchivosPorTipo {
    interno: Archivo[];
    externo: Archivo[];
    anexo: Archivo[];
}


@Injectable({
    providedIn: 'root'
})
export class TramiteService {
    get qrUrlPath() {
        return (this.entidadService.entidadActual['cEntUrlExterno'] ?? document.location.origin) + '/e/dR/' + this.entidadService.entidadActual['iEntId'] + '@';
    }
    constructor(
        private entidadService: EntidadService,
        // private configuracionWeb: ConfiguracionWeb,
    ) {
    }
}

function estilosPorDefectoTramites(objThis: any): ColumnaTabla<any>[] {
    return [
        {
            label: 'T', labelLista: 'Tipo', property: 'tipoTramiteMovimientoDefault', type: 'icons',
            iconsDef: {
                soloIcono: true,
                iconSize: '16px',
                items: [
                    {
                        icon: 'roundHomeWork', contentCss: ' text-cyan-700', toolTip: 'Interno',
                        //row.iTipoTramId || row.iTipoMovId
                        esVisible(row): boolean {
                            return (row['iTipoTramId'] ?? row['iTipoMovId']) == 1;
                        }
                    },
                    {
                        icon: 'roundPublic', contentCss: ' text-green-700', toolTip: 'Externo',
                        esVisible(row): boolean {
                            return (row['iTipoTramId'] ?? row['iTipoMovId']) == 2;
                        }
                    },
                    {
                        icon: 'roundCircle', contentCss: '',
                        esVisible(row): boolean {
                            const plazoResolver = (row.iTramMovSemaforoPlazoResolver * 1);
                            const filtroSemaforo = [
                                {label: 'Todos', class: 'bg-transparent border-2 border-black', value: 0},
                                {label: 'Rojo', class: 'text-red-500', value: 1},
                                {label: 'Ambar', class: 'text-yellow-500', value: 2},
                                {label: 'Verde', class: 'text-green-500', value: 3},
                            ];
                            this.contentCss = dataEnLista(filtroSemaforo, 'value', plazoResolver)?.class;
                            return [1, 2, 3].includes(plazoResolver);
                        }
                    },
                    {
                        icon: 'fa5sExclamationTriangle', contentCss: ' animate-pulse flex text-red-600',
                        toolTipField: 'cTipoPrioNombre',
                        esVisible(row): boolean {
                            return (row['iTipoPrioId']) == 2;
                        },
                        // esVisible(row): boolean { return true },
                    },
                    {
                        icon: 'fa5sFileSignature', contentCss: ' flex text-red-600',
                        toolTip: 'Firmado Digitalmente',
                        esVisible(row): boolean {
                            return (row['iTramCantidadFirmante']) > 0;
                        },
                        // esVisible(row): boolean { return true },
                    },
                ]
            },
            innerHTMLInvisible(row): any {
                return row['iTipoTramId'] ?? row['iTipoMovId'];
            }
        },
        {
            label: 'Emisor', property: 'cDepenNombreEmisorDefault', type: 'text',
            innerHTML(row): string {
                const tipoTram = (row['iTipoTramId'] ?? row['iTipoMovId']);
                let htmlFinal = '';
                htmlFinal += `<p class="text-2xs leading-tight">${row.cDespachoEmisorNombreLargo}</p>`;
                htmlFinal += '<small class="uppercase">'
                if (tipoTram == 1 && row.cPersFirmaEmisorNombre) {
                    htmlFinal += `<span>${row.cPersFirmaEmisorNombre}</span>`;
                }
                if (tipoTram == 2) {
                    // cPersEmisorNombreLargo
                    if (row.cPersEmisorNombre) {
                        htmlFinal += `<span>${row.cPersEmisorNombre}</span>`;
                    }
                    else if(row.cPersEmisorNombreLargo) {
                        htmlFinal += `<span>${row.cPersEmisorNombreLargo}</span>`;
                    }

                }
                htmlFinal += '</small>';
                return htmlFinal;
            }
        },
        {
            label: 'Documento', property: 'DocumentoFilasDefault', type: 'text',
            innerHTML(row): string {
                const armarData = (label, rowData, cssClases?: string, htmlInject?: { postDesc?(): any }) => {
                    let retHTML = '';
                    if (rowData && rowData != 'null') {
                        retHTML += `<p class="text-2xs leading-tight">`;
                        retHTML += `<span class="font-bold">${label}: </span>`;
                        retHTML += (htmlInject && htmlInject.postDesc) ? htmlInject.postDesc():'';
                        retHTML += `<span class="italic">${rowData}: </span>`;
                        retHTML += `</p>`;
                    }
                    return retHTML;
                }

                let htmlFinal = '';
                htmlFinal += armarData('Doc', row.cTramDocumentoTramite, null, {
                    postDesc: () => {
                        if (row.bTramMovCopia == 1) {
                            return `<span class="rounded ml-1 px-1 font-medium bg-red text-white" style="font-size: 70%;">Copia</span>`;
                        }
                        return '';
                    }
                });
                htmlFinal += armarData('Ref', row.cTramDocumentoReferencia);
                htmlFinal += armarData('Fec', row.dtTramFechaHoraDocumento ? formatDate(row.dtTramFechaHoraDocumento, 'dd/MM/yyyy HH:mm', 'en'):null);
                htmlFinal += armarData('Fol', row.iTramMovFolios ?? row.iTramFolios);
                const dataAdjuntosFisicos = row.cTramAdjuntoFisico ?? row.cTramMovAdjuntoFisico ?? '';
                // console.log('ADJUNTS FISICOS ', row.cTramDocumentoTramite, dataAdjuntosFisicos);
                htmlFinal += armarData('Adj', (row.cTramAdjuntoFisico ?? row.cTramMovAdjuntoFisico) ? JSON.parse(row.cTramAdjuntoFisico ?? row.cTramMovAdjuntoFisico ?? []).join(', '):null);
                htmlFinal += armarData('Acc', (row.iTipoDocId == 31 ? row.cTramMovObsEnvio:null));
                htmlFinal += armarData('Archiv', (row.cTramMovObsArchivado ? row.cTramMovObsArchivado + ' (' + formatDate(row.dtTramMovFechaHoraArchivado, 'dd/MM/yyyy HH:mm', 'en') + ')':null), 'text-yellow-600 test-xs');
                return htmlFinal;
            }
        },
        {
            label: 'Asunto', property: 'cTramAsuntoDocumentoDefault', type: 'text',
            cssClasses: ['text-indigo-800', 'italic', 'font-semibold'], transformar(row): any {
                return row?.cTramAsuntoDocumento;
            }
        },
        {
            label: 'Fecha', property: 'tipoFechaHora', type: 'date', format: 'dd/MM/yyyy HH:mm',
            cssClasses: ['font-mono', 'text-red-900'],
        },
    ];
}

export function establecerEstilosTramites(objThis: any, columnasTabla: ColumnaTabla<any>[]): ColumnaTabla<any>[] {
    columnasTabla = establecerEstilosPorDefecto(objThis, columnasTabla);
    const estilos: ColumnaTabla<any>[] = estilosPorDefectoTramites(objThis);

    return columnasTabla.map(col => {
        // const existe = dataEnLista(estilos, 'property', col.property);
        const existe = estilos.find(item => item.property == col.property);
        if (existe && col.type === 'estiloEstablecido') {
            if (existe.click) {
                const claseString = (Array.isArray(existe.textCss) ? existe.textCss.join(' ') : existe.textCss);
                const matches = claseString.match(/bg-(.[^\s]+)-[0-9]{3}/);
                if (matches) {
                    col.textCss = claseString + ` hover:text-red-100 hover:bg-red-600 `;
                }
            }
            return existe;
        }
        return col;
    });
}

export function agregarBotonesTiposDocumentosFiltro(reqAgregar) {
    const items: any[] = reqAgregar;
    const retListaBotones: BotonMantenimiento[] = [];
    items.forEach((item, idx) => {
        if (idx == 0) {
            retListaBotones.push(
                {tipo: 'agregar_tipos_documentos_a_filtro', label: 'Todos', class: 'bg-blue-900 text-white rounded-l-full rounded-r-full', sinCondicion: true, noContextual: true, datosExtra: {item: null}}
            );
        }
        retListaBotones.push(
            {
                tipo: 'agregar_tipos_documentos_a_filtro',
                label: item.cTipoDocNombre,
                tooltip: item.cTipoDocNombre,
                badge: item.iTramCantidadTramites,
                class: 'ml-1 bg-blue-900 text-white rounded-l-full rounded-r-full',
                noContextual: true,
                sinCondicion: true, datosExtra: {item}
            },
        );
    });
    return retListaBotones;
}
export function agregarBotonesDespachosFiltro(reqAgregar) {
    const items: any[] = reqAgregar;
    const retListaBotones: BotonMantenimiento[] = [];
    items.forEach((item, idx) => {
        if (idx == 0) {
            retListaBotones.push(
                {tipo: 'agregar_despacho_a_filtro', label: 'Todos', class: 'bg-blue-900 text-white rounded-l-full rounded-r-full', sinCondicion: true, noContextual: true, datosExtra: {item: null}}
            );
        }
        retListaBotones.push(
            {
                tipo: 'agregar_despacho_a_filtro',
                label: item.cDespachoNombreCorto,
                tooltip: item.cDespachoNombreLargo,
                badge: item.iDespachoCantidadTramites,
                class: 'ml-1 bg-blue-900 text-white rounded-l-full rounded-r-full',
                noContextual: true,
                sinCondicion: true, datosExtra: {item}
            },
        );
    });
    return retListaBotones;
}
export function getEstadoDocumento(cTramMovEstadoTramite): { id: number; label: string; idSecundario: string | number; doc: string; } {
    let dataRet = {
        id: 0,
        label: '',
        idSecundario: 0,
        doc: '',
    };
    if (cTramMovEstadoTramite) {
        const datosTodos = cTramMovEstadoTramite.split('|');
        if (datosTodos) {
            // @ts-ignore
            dataRet = {
                ...dataRet,
                ...{
                    id: datosTodos[0] ? datosTodos[0] * 1 : 0,
                    label: datosTodos[1] ? datosTodos[1] : '',
                    idSecundario: datosTodos[2] ? datosTodos[2] * 1 : 0,
                    doc: datosTodos[3] ? datosTodos[3] : '',
                }
            };
        }
    }


    return dataRet;
    // return false;
}
export function cargarArchivosTramite(objThis, iTramId: number, dataUsuario = null, customTramId = null): Promise<ArchivosPorTipo> {
    const convertirDataArchivo = (listaOrig: any[]): Archivo[] => {
        const lstAr: Archivo[] = [];
        listaOrig.forEach((item) => {
            const keyTramite = [
                customTramId ?? iTramId,
                item.cArchivoKey,
            ]
            if (dataUsuario) {
                keyTramite.push(dataUsuario['iCredSesionId']);
                keyTramite.push(dataUsuario['iPersId']);
            }
            const dAr = getDataArchivoFromPath(item.cArchivoRuta);
            lstAr.push({
                id: item.iArchivoId,
                key: [
                    // this.seleccionados.tramites.iTramId,
                    item.cArchivoKey,
                    // this.usuarioService._data.getValue()['iCredSesionId'],
                    // this.usuarioService._data.getValue()['iPersId'],
                ].join('@'),
                keyFirmaTramite: keyTramite.join('@'),
                nombre: item.cArchivoNombre ?? dAr.nombre,
                path: item.cArchivoRuta,
                extension: dAr.extension,
            });
        });
        return lstAr;
    };

    const lstArch: ArchivosPorTipo = { interno: [], externo: [], anexo: [] };

    return objThis.queryService.cargar(objThis, ['grl.tramites_archivos'], { iTramId: iTramId }).then((dataArch) => {
        if (dataArch) {
            lstArch.interno = convertirDataArchivo(dataArch['grl.tramites_archivos'].filter(itm => itm['cArchivoTramiteTipo'] == 'Interno'));
            lstArch.externo = convertirDataArchivo(dataArch['grl.tramites_archivos'].filter(itm => itm['cArchivoTramiteTipo'] == 'Externo'));
            lstArch.anexo = convertirDataArchivo(dataArch['grl.tramites_archivos'].filter(itm => itm['cArchivoTramiteTipo'] == 'Anexo'));
        }
        return lstArch;
    });

}
