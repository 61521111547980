<ng-content></ng-content>



<ng-container *ngFor="let column of colDetalle; trackBy: trackByProperty">

	<ng-container *ngIf="column.type === 'expandir'" [matColumnDef]="column.property">

		<th *matHeaderCellDef class="w-4" mat-header-cell> <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" class="w-4 text-center" mat-cell>


			<button *ngIf="column.click"
					class="leading-none text-sm m-1 border mat-elevation-z2 rounded-full text-primary-contrast bg-primary"
					style="padding: 1px;"
					matTooltip="Columnas Filtro"
					type="button"
					(click)="column.click(row); row.isExpanded = !row.isExpanded; $event.stopPropagation()"
					fxLayout="row" fxLayoutAlign="space-around center"
			>
				<mat-icon [svgIcon]="(row.isExpanded ? 'roundExpandLess' : 'roundExpandMore')" class="icon-sm"></mat-icon>
			</button>
			<button *ngIf="!column.click"
					class="leading-none text-sm m-1 border mat-elevation-z2 rounded-full text-secondary"
					style="padding: 1px;"
					matTooltip="Columnas Filtro"
					type="button"
					(click)="row.isExpanded = !row.isExpanded; $event.stopPropagation()"
					fxLayout="row" fxLayoutAlign="space-around center"
			>
				<mat-icon [svgIcon]="(row.isExpanded ? 'roundExpandLess' : 'roundExpandMore')" class="icon-sm"></mat-icon>
			</button>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'checkbox'" [matColumnDef]="column.property">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="w-4 text-center" mat-header-cell>
			<!--			{{ nombreColeccion }}-->
			<mat-checkbox class="chkSeleccionar" *ngIf="formulariosGlobalesService.frmSeleccionadosTipoFG(nombreColeccion)"
						  [checked]="formulariosGlobalesService.chkTodos[nombreColeccion]"
						  [indeterminate]="formulariosGlobalesService.algunosActivos(nombreColeccion)"
						  (change)="formulariosGlobalesService.establecerTodos(nombreColeccion, $event.checked)"
			>
			</mat-checkbox>
		</th>
		<td *matCellDef="let row" class="w-4 text-center" mat-cell>
			<ng-container *ngIf="formulariosGlobalesService.frmSeleccionadosTipoFG(nombreColeccion)"
						  [formGroup]="formulariosGlobalesService.frmSeleccionadosTipoFG(nombreColeccion)">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					[formControlName]="(column.chkField ? verTexto(row, column.chkField, (column.chkFieldSeparador ?? '')) : row[column.property])"
					(change)="formulariosGlobalesService.actualizarTodosCompletos(nombreColeccion)"
				></mat-checkbox>
			</ng-container>

		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'text' || column.type === 'estiloEstablecido'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase text-center" mat-header-cell mat-sort-header [disabled]="column.sort === false" >
            <span [innerHTML]="column.label ?? ''">{{ column.label }} </span>
			<span *ngIf="column.type == 'estiloEstablecido'" class="text-red-900 font-bold bg-white">CONFIGURAR ESTILO ESTABLECIDO</span>
        </th>
        <ng-container *ngIf="column.innerHTML; else sinInnerHTML">
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [innerHTML]="column.innerHTML(row) | noSanitize" (click)="accionClick(column, row, $event)"></td>
        </ng-container>
        <ng-template #sinInnerHTML>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <!--			<span *ngIf="column.property && !column.textArray">{{ row[column.property] }}</span>-->
                <!--			<span *ngIf="column.property && column.textArray">{{ verTexto(row, column.textArray) }}</span>-->
				<ng-container *ngIf="column.text && column.text.separador else campoTextNormal">
					<div [class]="column.text.cssContenedor">
						<ng-container *ngFor="let itm of ((column.transformar ? column.transformar(row) : (column.textArray ? verTexto(row, column.textArray) : row[column.property])) ?? '').split(column.text.separador)">
							<ng-template *ngIf="column.property" [ngTemplateOutlet]="tipoDatoString" [ngTemplateOutletContext]="{ fila: column, tipo: 'text', value: itm, class: column.textCss, row: row }"></ng-template>
						</ng-container>
					</div>
				</ng-container>
				<ng-template #campoTextNormal>
					<ng-template *ngIf="column.property" [ngTemplateOutlet]="tipoDatoString" [ngTemplateOutletContext]="{ fila: column, tipo: 'text', value: (column.transformar ? column.transformar(row) : (column.textArray ? verTexto(row, column.textArray) : row[column.property])), class: column.textCss, row: row }"></ng-template>
				</ng-template>
				<span *ngIf="column.type == 'estiloEstablecido'" class="text-red-900 font-bold">CONFIGURAR ESTILO ESTABLECIDO</span>
            </td>
        </ng-template>
        <th *matFooterCellDef [ngClass]="column.cssFooterClasses" style="height: unset !important;" mat-footer-cell [innerHTML]="column.transformarFooter ? column.transformarFooter() : ''">
        </th>

	</ng-container>
	<ng-container *ngIf="column.type === 'text_rows'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell mat-sort-header [disabled]="column.sort === false" >  <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<p *ngFor="let fila of column.text_rows" style="font: unset; letter-spacing: unset">
				<ng-container *ngIf="row[fila.value]">
					<ng-container *ngIf="fila.siCampoNulo && camposNulos(row, fila.siCampoNulo)">
						<span [ngClass]="fila.cssLabelClasses" *ngIf="fila.label">{{ fila.label }}:</span><br *ngIf="fila.salto">
						<ng-template [ngTemplateOutlet]="tipoDatoString" [ngTemplateOutletContext]="{ fila: fila, tipo: fila.valueType || 'text', value: (fila.textArray ? verTexto(row, fila.textArray) : row[fila.value]), format: fila.valueFormat, class: fila.cssValueClasses }"></ng-template>
					</ng-container>
					<ng-container *ngIf="!fila.siCampoNulo">
						<span [ngClass]="fila.cssLabelClasses" *ngIf="fila.label">{{ fila.label }}:</span><br *ngIf="fila.salto">
						<ng-template [ngTemplateOutlet]="tipoDatoString" [ngTemplateOutletContext]="{ fila: fila, tipo: fila.valueType || 'text', value: (fila.textArray ? verTexto(row, fila.textArray) : row[fila.value]), format: fila.valueFormat, class: fila.cssValueClasses }"></ng-template>
					</ng-container>
				</ng-container>
			</p>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'money'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell mat-sort-header [disabled]="column.sort === false" >  <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" class="text-right"
            [class.text-sky-700]="(row[column.property] * 1) >= 0" [class.text-red-700]="(row[column.property] * 1) < 0" mat-cell>
				<span *ngIf="column.property && row[column.property]">
					<span>{{ row[column.property] | currency: column.simboloMoneda ?? 'S/' }}</span>
				</span>
		</td>
        <td *matFooterCellDef class="text-right" [ngClass]="column.cssFooterClasses" mat-cell>
				<span *ngIf="column.transformarFooter"
                      [class.text-sky-700]="column.transformarFooter() >= 0" [class.text-red-700]="column.transformarFooter() < 0"
                >
					<span>{{ column.transformarFooter() | currency: column.simboloMoneda ?? 'S/' }}</span>
				</span>
        </td>
	</ng-container>
	<ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell mat-sort-header [disabled]="column.sort === false" >  <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
				<span *ngIf="column.property && row[column.property]" class="!text-indigo-900 font-semibold">
					<span *ngIf="column.zeroFill">{{ row[column.property] | zeroFill: column.zeroFill }}</span>
					<span *ngIf="!column.zeroFill">{{ row[column.property] | number: (column.format ? column.format : '1.0-2') }}</span>
				</span>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell mat-sort-header [disabled]="column.sort === false" >  <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell><span *ngIf="column.property">{{ row[column.property] | date: (column.format ? column.format : 'dd/MM/yyyy') }}</span></td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'icon'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell> <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<ng-container [ngSwitch]="row[column.property]">
				<ng-container *ngFor="let opc of column.opcSwitchIcon.casos">
					<mat-icon *ngSwitchCase="opc.case" [ngClass]="opc.cssClasses" style="height: unset; width: unset;" [svgIcon]="opc.icon"
                              [matTooltip]="opc.campoTooltip ? row[opc.campoTooltip] : (opc.tooltip ?? '')" [matTooltipDisabled]="!opc.tooltip && !opc.campoTooltip"
							  class="icon-sm"></mat-icon>
				</ng-container>
				<ng-container *ngIf="column.opcSwitchIcon.default">
					<div *ngSwitchDefault></div>
				</ng-container>
			</ng-container>
			<span class="cdk-visually-hidden">{{ row[column.property] }}</span>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>
	</ng-container>
	<ng-container *ngIf="column.type === 'icon_checkbox'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" [ngClass]="column.headerCssClasses" mat-header-cell> <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<mat-icon [svgIcon]="(row[column.property] == 1 ? 'checkboxChecked20Regular' : 'checkboxUnchecked20Regular' )" class="icon-sm"></mat-icon>
			<span class="cdk-visually-hidden">{{ row[column.property] }}</span>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'progress'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" [ngClass]="column.headerCssClasses" mat-header-cell> <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <jvsoft-progress-bar
                [textoCentrado]="column.progressbar.textoCentrado"
                [porcentaje]="(column.progressbar.porcentaje(row))"
                [coloresValor]="column.progressbar.coloresValor"
                [formatoNumero]="column.progressbar.formatoNumero"
                [textoMostrar]="column.progressbar.textoMostrar ? column.progressbar.textoMostrar(row) : undefined"
            ></jvsoft-progress-bar>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'buttons'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell>  <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>

			<div class="flex">
				<ng-container *ngFor="let field of column.fields">
					<a *ngIf="field.href && row[field.campo]"
					   [href]="field.href.pre + row[field.campo]"
					   [target]="field.href.target ? field.href.target : '_self'"
					   [matTooltip]="row[field.campo]"
					   class="w-6 h-6 btn-rounded-30 {{field.class}}"
					>
						<mat-icon [svgIcon]="field.icono"  class="icon-sm"></mat-icon>
					</a>
					<a *ngIf="!field.fnClick && !field.href && row[field.campo]"
					   (click)="$event.stopPropagation()"
					   [matTooltip]="row[field.campo]"
					   class="w-6 h-6  btn-rounded-30 {{field.class}}"
					>
						<mat-icon [svgIcon]="field.icono"  class="icon-sm"></mat-icon>
					</a>
					<a *ngIf="field.fnClick && row[field.campo]"
					   (click)="objThis[field.fnClick](row, field.fnClickData); $event.stopPropagation()"
					   [matTooltip]="row[field.campo]"
					   class="w-6 h-6  btn-rounded-30 {{field.class}}"
					>
						<mat-icon [svgIcon]="field.icono" class="icon-sm"></mat-icon>
					</a>
				</ng-container>
			</div>

		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'icons'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell>  <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div class="flex" [ngClass]="{ '-space-x-1': !column.iconsDef.soloIcono }"
            >
                <ng-container *ngFor="let icon of column.iconsDef.items">
                    <ng-container *ngIf="!column.iconsDef.soloIcono; else soloIcono">
                        <button matRipple type="button" *ngIf="icon.esVisible && icon.esVisible(row)"
                                (click)="icon.click ? icon.click(row) : false;"
                                class="flex items-center justify-center p-1 rounded border shadow-sm hover:shadow-lg"
                                [ngClass]="icon.contentCss"
                                [matTooltip]="icon.toolTip ?? row[icon.toolTipField]"
                                [matTooltipDisabled]="!icon.toolTip && !icon.toolTipField"
                        >
							<ng-container [ngTemplateOutlet]="iconoConTamanio" [ngTemplateOutletContext]="{icon: icon.icon, iconClass: icon.cssClass, size: (icon.iconSize ?? column.iconsDef.iconSize)}"></ng-container>
                        </button>
                    </ng-container>
                    <ng-template #soloIcono>
                        <div *ngIf="icon.esVisible && icon.esVisible(row)" [ngClass]="icon.contentCss"
							 class="flex items-center"
                              [matTooltip]="icon.toolTip ?? row[icon.toolTipField]"
                              [matTooltipDisabled]="!icon.toolTip && !icon.toolTipField"
                        >
							<ng-container [ngTemplateOutlet]="iconoConTamanio" [ngTemplateOutletContext]="{icon: icon.icon, iconClass: icon.cssClass, size: (icon.iconSize ?? column.iconsDef.iconSize)}"></ng-container>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
			<span class="cdk-visually-hidden" *ngIf="column.innerHTMLInvisible" [innerHTML]="column.innerHTMLInvisible(row)"></span>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>

	<ng-container *ngIf="column.type === 'estado'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef [ngClass]="column.cssClassesTH" class="uppercase" mat-header-cell> <span [innerHTML]="column.label ?? ''">{{ column.label }} </span></th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<ng-container *ngIf="column.estados" [ngSwitch]="row[column.property]">
				<ng-container *ngFor="let opc of column.estados">
					<div *ngSwitchCase="opc['estado']"
						 [ngClass]="opc['cssClasses']"
						 class="rounded px-1 flex justify-center flex-initial"
					>
						<mat-icon *ngIf="opc['icon']" class="icon-sm" [svgIcon]="opc['icon']"></mat-icon>
						<span>{{ (opc['text'] ?? opc['label'] ?? (row[column.property])) }}</span>
					</div>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="column.opcSwitchIcon" [ngSwitch]="row[column.property].charAt(0)">
				<ng-container *ngFor="let opc of column.opcSwitchIcon.casos">
					<div *ngSwitchCase="opc['case']"
						 [ngClass]="opc['cssClasses']"
						 class="rounded px-1"
					>
						<mat-icon *ngIf="opc['icon']" [ngClass]="opc['cssClasses']" class="icon-sm" [svgIcon]="opc['icon']"></mat-icon>
						{{ row[column.property].substring(2) }}
					</div>
				</ng-container>
				<ng-container *ngIf="column.opcSwitchIcon.default">
					<div *ngSwitchDefault></div>
				</ng-container>
			</ng-container>
		</td>
        <td *matFooterCellDef [ngClass]="column.cssFooterClasses" mat-footer-cell></td>

	</ng-container>

</ng-container>

<ng-template #tipoDatoString let-row="row" let-fila="fila" let-tipo="tipo" let-value="value" let-format="format" let-clase="class">
	<ng-container [ngSwitch]="tipo">
		<ng-container *ngSwitchCase="'text'"><span [ngClass]="clase" *ngIf="value" (click)="accionClick(fila, row, $event)">
            <span *ngIf="!fila.zeroFill">{{ value }}</span>
            <span *ngIf="fila.zeroFill">{{ value | zeroFill: fila.zeroFill }}</span>
        </span></ng-container>
		<ng-container *ngSwitchCase="'date'"><span [ngClass]="clase" *ngIf="value" (click)="accionClick(fila, row, $event)" >{{ value | date: (format || 'dd/MM/yyyy') }}</span></ng-container>
		<ng-container *ngSwitchCase="'number'"><span [ngClass]="clase" *ngIf="value" (click)="accionClick(fila, row, $event)" >{{ value | number: (format || '0.0-2') }}</span></ng-container>
		<ng-container *ngSwitchCase="'money'"><span [ngClass]="clase" *ngIf="value" (click)="accionClick(fila, row, $event)" >{{ value | currency: fila.simboloMoneda ?? 'S/' }}</span></ng-container>

		<!--		<span [ngClass]="class" *ngSwitchCase="'date'">{{ value | date: (format || 'dd/MM/yyyy') }}</span>-->
		<!--		<span [ngClass]="class" *ngSwitchCase="'number'">{{ value | number: (format || '0.0-2') }}</span>-->
	</ng-container>
</ng-template>

<ng-template #iconoConTamanio let-icon="icon" let-iconClass="iconClass" let-size="size">
	<mat-icon *ngIf="size" [svgIcon]="icon" [ngClass]="iconClass" [inline]="true"
			  [style.font-size]="size"
			  [style.height]="size"
			  [style.width]="size"
	></mat-icon>
	<mat-icon *ngIf="!size" [svgIcon]="icon" [ngClass]="iconClass"></mat-icon>
</ng-template>
