import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {FocusService} from './focus.service';

@Directive({
	selector: '[vexFocus], [nextFocusTst]'
})
export class FocusDirective implements OnInit {

	constructor(private el: ElementRef, private tabService: FocusService) {
	}

	private _nextControl: string;

	get nextControl() {
		return this._nextControl;
	}

	@Input('nextFocus')
	set nextControl(i: any) {
		console.log(i);
		this._nextControl = i;
	}

	@HostListener('keydown', ['$event'])
	onInput(e: any) {
		if (e.which === 13) {
			e.preventDefault();
			console.log('index', this.nextControl + 1);
			this.tabService.selectedInput.next(this.nextControl);
		}
	}

	ngOnInit() {
		this.tabService.selectedInput.subscribe((i) => {
			console.warn(this.nextControl, i, this.el);
			// console.warn(this.nextControl, this.el);
			if (i === this.nextControl) {
				console.warn(i, this.el);
				this.el.nativeElement.focus();
			}
		});
	}

/*	@Input() vexFocus: string;

	constructor(
		private elementRef: ElementRef,
		private focusService: FocusService) {
	}

	ngAfterViewInit() {
		this.focusService.focusSource.subscribe(res => {
			console.log(res);
			this.setFocus(res);
		});
	}

	setFocus(item: string) {
		console.warn('->', this.vexFocus, item);
		// I use strings to match the call with the input
		if (this.vexFocus === item) { // 'mySearchBox' === 'mySearchBox
			this.elementRef.nativeElement.focus();
			console.log(this.elementRef);
			// Do something to avoid the keypress event
		}
	}*/
}


