<div [ngClass]="containerClass">
	<div class="text-wrapper">
		<div class="text-center text-gray-700" (click)="fileInput.click()">
			Click o soltar archivos aquí
			<input hidden type="file" #fileInput multiple>
	</div>
</div>
<div role="group" class="dropzone general-mat-file-upload-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
	<input class="example-tel-input-element"
	       formControlName="area" size="3"
	       maxLength="3"
	       aria-label="Area code"
	       (input)="_handleInput(parts.controls.area, exchange)"
	       #area>
	<span class="example-tel-input-spacer">&ndash;</span>
	<input class="example-tel-input-element"
	       formControlName="exchange"
	       maxLength="3"
	       size="3"
	       aria-label="Exchange code"
	       (input)="_handleInput(parts.controls.exchange, subscriber)"
	       (keyup.backspace)="autoFocusPrev(parts.controls.exchange, area)"
	       #exchange>
	<span class="example-tel-input-spacer">&ndash;</span>
	<input class="example-tel-input-element"
	       formControlName="subscriber"
	       maxLength="4"
	       size="4"
	       aria-label="Subscriber number"
	       (input)="_handleInput(parts.controls.subscriber)"
	       (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)"
	       #subscriber>
</div>

