import {AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {MatColumnDef, MatFooterRow, MatRow, MatTable, MatTableDataSource} from '@angular/material/table';
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginator, MatPaginatorDefaultOptions} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableExporterDirective} from 'mat-table-exporter';
import {formatearFecha, FuncionesGlobalesService} from '@JVSoft/services/funciones-globales.service';
import {FormulariosGlobalesService} from '@JVSoft/services/formularios-globales.service';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {fromEvent, Observable, of, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TemplatePortal} from '@angular/cdk/portal';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {stagger40ms} from '@vex/animations/stagger.animation';
import {BotonMantenimiento, ColumnaTabla, OpcionSeleccionada, SeccionesBotonesMantenimiento} from '@JVSoft/interfaces/global';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UsuarioService} from '@servicios/usuario.service';
import {SharedService} from '@JVSoft/services/shared.service';
import {TableUtil} from '@JVSoft/classes/table-util';
import {ItemMenu} from "@JVSoft/components/menu/menu.component";
import {SelectionModel} from '@angular/cdk/collections';
import shortHash from 'shorthash2';
import {ServidorService} from '@servicios/servidor.service';
import {TabSeccion} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';
import {TablaMantenimientoService} from '@standalone/components/tabla-mantenimiento/tabla-mantenimiento.service';

export interface MantenimientoFilaExtraData {
    template?: TemplateRef<any>;
    esVisible?(item?: any): boolean;
}

/** Custom options to configure the form field's look and feel */
const formFieldDefaults: MatPaginatorDefaultOptions = {
    formFieldAppearance: 'outline'
    // appearance: 'legacy' // 'legacy' | 'standard' | 'fill' | 'outline'
};

/**
 * @deprecated Usar '<jvs-tabla-mantenimiento>' en su lugar.
 */
@UntilDestroy()
@Component({
    selector: 'general-tabla-mantenimiento-nuevo',
    templateUrl: './tabla-mantenimiento-nuevo.component.html',
    styleUrls: ['./tabla-mantenimiento-nuevo.component.scss'],
    host: {
        class: 'general-tabla-mantenimiento-nuevo',
    },
    animations: [
        fadeInUp400ms,
        stagger40ms,
        // fadeInUp0ms,
        // stagger0ms,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        {provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: formFieldDefaults}
    ]
})
export class TablaMantenimientoNuevoComponent<T> implements OnInit, AfterContentInit, OnChanges {
    static nextId = 0;
    @HostBinding()
    readonly id = `general-mat-buscar-persona-id-${TablaMantenimientoNuevoComponent.nextId++}`;
    templateBotonesComunes: BotonMantenimiento[] = this.tablaMantenimientoService.templateBotonesComunes;
    templateColumnasTabla: ColumnaTabla<any>[] = this.tablaMantenimientoService.templateColumnasTabla;

    @Input() dataSuscription: Observable<any>;
    @Input() objThis;
    @Input() nombreColeccion;
    @Input() ctrlBusqueda: 'query' | 'simple' | string | false = false;
    @Input() ctrlBusquedaValue = '';
    @Input() filtroCampos = true;
    @Input() botonRecargar = true;
    @Input() paginador = true;
    @Input() esTabla = true;
    @Input() readOnly: boolean | 'permitirSeleccion' = false;
    @Input() filaExtraHeader: MantenimientoFilaExtraData;
    @Input() filaFooter = false;
    @Input() botonesCRUD = 'CUD';
    // @Input() leyenda;
    private _leyenda: {text: string, class?: string};
    get leyenda(): {text: string, class?: string} { return this._leyenda; }
    @Input() set leyenda(val: {text: string, class?: string} | string) {
        if (typeof val == 'string') {
            this._leyenda = {text: val};
        }
        else {
            this._leyenda = val;
        }
    }
    @Output() dblclickItem = new EventEmitter();
    @Input() objBotonesCRUD: BotonMantenimiento[] = [
        {tipo: 'nuevo', sinCondicion: true},
        {tipo: 'editar'},
        {tipo: 'eliminar'},
    ];
    @Input() classSeleccionado = ['elemento-seleccionado', /*'!bg-primary-activo','text-primary-contrast', */ 'mat-elevation-z8', /*'dark:!bg-primary-dark-activo',*/ 'border-y-2 border-primary-contrast', 'font-bold', 'text-2xl'];
    // @Input() classAnulado = ['line-through', 'text-red', 'italic'];
    @Input() classAnulado = ['elemento-anulado', /*'line-through', */'text-red-900', 'bg-red-200'];
    @Input() campoAnulado = null;
    @Input() campoAnuladoMensaje = null;
    // @Input() idTabla: string[];
    private _idTabla: string[] = [];
    get idTabla(): string[] { return this._idTabla; }
    @Input() set idTabla(val: string[] | string) {
        if (Array.isArray(val)) {
            this._idTabla = val;
        }
        else {
            this._idTabla.push(val);
        }
    }
    idTablaValor(data): string {
        if (data) {
            if (this.idTabla.length < 1) {
                return shortHash(JSON.stringify({data, claseFinal: undefined}))
            }
            return this.idTabla.map(d => data[d]).join('-');
        }
        return '';
    }

    private _columnasTabla: ColumnaTabla<any>[];
    get columnasTabla(): ColumnaTabla<any>[] { return this._columnasTabla ?? []; }

    @Input() set columnasTabla(val: ColumnaTabla<any>[]) {
        const reemplazarConTemplate = (listaInicial: ColumnaTabla<any>[] = []) => {
            listaInicial.forEach((btnActual, idx) => {
                const btnEnTemplate = dataEnLista(this.templateColumnasTabla, 'property', btnActual.property);
                if (btnEnTemplate) {
                    listaInicial[idx] = {
                        ...btnEnTemplate,
                        ...btnActual
                    };
                }
            });
            return listaInicial;
        };
        this._columnasTabla = val ? reemplazarConTemplate(val) : [];
    }

    /** @deprecated usar '`botonesMenu.crud`' en su lugar */
    @Input() listaMenu: BotonMantenimiento[] = [];
    /** @deprecated usar '`botonesMenu.izquierda`' en su lugar */
    @Input() listaMenuIzquierda: BotonMantenimiento[] = [];
    /** @deprecated usar '`botonesMenu.derecha`' en su lugar */
    @Input() listaMenuDerecha: BotonMantenimiento[] = [];
    /** @deprecated usar '`botonesMenu.secundario`' en su lugar */
    @Input() listaMenuSecundario: BotonMantenimiento[] = [];
    /** @deprecated usar '`botonesMenu.inferior`' en su lugar */
    @Input() listaMenuInferior: BotonMantenimiento[] = [];
    // @Input() botonesMenu: BotonMantenimiento[] = [];
    private _botonesMenu: SeccionesBotonesMantenimiento;
    get botonesMenu(): SeccionesBotonesMantenimiento { return this._botonesMenu; }

    @Input() set botonesMenu(val: SeccionesBotonesMantenimiento) {
        const reemplazarConTemplate = (listaInicial: BotonMantenimiento[] = []) => {
            listaInicial.forEach((btnActual, idx) => {
                const btnEnTemplate = dataEnLista(this.templateBotonesComunes, 'tipo', btnActual.tipoTemplate ?? btnActual.tipo);
                if (btnEnTemplate) {
                    if (btnActual.tipoTemplate && !btnActual.label) {
                        btnEnTemplate.label = undefined;
                    }
                    listaInicial[idx] = {
                        ...btnEnTemplate,
                        ...btnActual
                    };
                }
                if (btnActual.subItems) {
                    btnActual.subItems = reemplazarConTemplate(btnActual.subItems);
                }
            });
            return listaInicial;
        };

        // this.objBotonesCRUD = reemplazarConTemplate(val.crud, ['nuevo', 'editar', 'eliminar']);
        this.objBotonesCRUD = this.templateBotonesComunes.filter(btnActual => ['nuevo', 'editar', 'eliminar'].includes(btnActual.tipo))
            .map(obj => {
                const valor = (val.crud ?? []).find(o => o.tipo === obj.tipo);
                if (valor) {
                    return {
                        ...obj,
                        ...valor,
                    };
                }
                return obj;
            });
        this.listaMenu = reemplazarConTemplate(val.principal);
        this.listaMenuIzquierda = reemplazarConTemplate(val.izquierda);
        this.listaMenuDerecha = reemplazarConTemplate(val.derecha);
        this.listaMenuSecundario = reemplazarConTemplate(val.secundario);
        this.listaMenuInferior = reemplazarConTemplate(val.inferior);
    }

    @Output() opcionSelecionada = new EventEmitter();
    @Output() opcBusqueda = new EventEmitter();
    @Output() accionRecargar = new EventEmitter();
    isRecargarUsed = false;

    @Input() filaExtraTemplate: TemplateRef<any>;


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // MIO
    @Input() exportarExcel = true;
    /** @deprecated usar '`dataSuscription`' en su lugar */
    @Input() data: any;
    @Input() pageSize = 25;
    @Input() pageSizeOptions = [5, 10, 25, 50, 100, 500, 1000];
    @Output() dataSourceChange: EventEmitter<any> = new EventEmitter();
    @Input() defaultTab = 0;

    // FILTRO BUSQUEDA - INTEGRAR
    @Input() criterioFiltro = '';
    @Input() asignarValores = {};
    @Input() prefijoConsulta = 'grl';
    @Input() opciones: TabSeccion[] = [];
    @Input() busquedaAutomatica = false;
    @Input() mostrarBotonFiltro = true;
    @Output() resultados = new EventEmitter<any>();
    @Output() resultadosConLabel = new EventEmitter<any>();
    @Output() dataFiltro = new EventEmitter<any>();
    /** @deprecated usar '`dataSuscription`' en su lugar */
    @Input() dataSource; // or whatever type of datasource you have

    // FIN FILTRO BUSQUEDA
    /** @deprecated NO USAR MAS */
    @Input() uniqidRowSelect = null;
    // AUTO SELECCION DE FILA AL INSERTA

    // this is where the magic happens:
    @ViewChild(MatTable, {static: true}) table: MatTable<T>;
    @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
    paginacion = {
        pageSize: 25,
        pageSizeOptions: null,
        pageCurrent: null,
        pageIndex: 0,
        pageLength: null,
    };

    // after the <ng-content> has been initialized, the column definitions are available.
    // All that's left is to add them to the table ourselves:
    ignorarDerechos = false;
    @Input() derechosActuales;
    frmFiltro: FormGroup;
    listaCRUD: BotonMantenimiento[] = [];
    @ViewChild('exporter') tableExporter: MatTableExporterDirective;
    @ViewChildren(MatRow, {read: ElementRef}) rows!: QueryList<ElementRef<HTMLTableRowElement>>;
    @ViewChild(MatFooterRow, {read: ElementRef}) rowFooter!: ElementRef<HTMLTableRowElement>;

    mouseEvent$: Subscription;
    overlayRef: OverlayRef | null;
    @ViewChild('userMenu') userMenu: TemplateRef<any>;
    selectedRow: number;
    setClickedRow: (index) => void;
    // FILTRO BUSQUEDA
    opcFiltroActual = {};
    get listaMenuCompleto(): BotonMantenimiento[] {
        let menuTotal: BotonMantenimiento[] = [];
        menuTotal = menuTotal.concat(this.listaMenuIzquierda);
        // if (menuTotal.length > 0) {
        //     menuTotal = menuTotal.concat({tipo: '-#SEPARADOR#-'});
        // }
        menuTotal = menuTotal.concat(this.listaCRUD);
        menuTotal = menuTotal.concat(this.listaMenu);
        menuTotal = menuTotal.concat(this.listaMenuDerecha);
        menuTotal = menuTotal.concat(this.listaMenuSecundario);
        menuTotal = menuTotal.concat(this.listaMenuInferior);
        // console.log(JSON.stringify(menuTotal));
        return menuTotal;
        const menuCompleto = this.listaMenuIzquierda
            .concat(this.listaCRUD)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenu)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenuDerecha)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenuSecundario)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenuInferior)
        ;
        console.log(JSON.stringify(menuCompleto));
        return menuTotal;
    }
    get listaMenuContextual(): ItemMenu[] {
        const armarEstructura = (item: BotonMantenimiento) => {
            const daRET: ItemMenu = {label: ( item.label ?? this.funcionesGlobalesService.capitalizarTexto(item.tipo)), icon: item.icono};
            if (item.subItems) {
                daRET.children = [];
                item.subItems.forEach(subItm => {
                    daRET.children.push(armarEstructura(subItm));
                });
            }
            return daRET;
        }
        // console.log(this.listaMenuCompleto);
        return this.listaMenuCompleto.map(itm => armarEstructura(itm));
    }
    constructor(
        private fb: FormBuilder,
        public funcionesGlobalesService: FuncionesGlobalesService,
        public formulariosGlobalesService: FormulariosGlobalesService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        public servidorService: ServidorService,
        private usuarioService: UsuarioService,
        private sharedService: SharedService,
        protected tablaMantenimientoService: TablaMantenimientoService,
    ) {

        this.frmFiltro = this.fb.group({
            cCampoBusqueda: [''],
        });

        this.setClickedRow = (index) => {
            this.selectedRow = index;
        };
    }

    get objVisibleColumns() {
        return this.columnasTabla.filter(column => {
            if (column.visible == undefined) {
                column.visible = true;
            }
            if (this.filaExtraTemplate) {
                column.sort = false;
            }
            if (column.esVisible != undefined) {
                column.visible = column.esVisible();
            }
            if (column.noMostrar != undefined) {
                column.visible = !column.noMostrar();
            }
            return column.visible;
        });
    }
    get visibleColumns() {
        return this.objVisibleColumns.map(column => column.property);
    }

    @Input() condicionesClaseFila = item => [];

    ngOnInit(): void {
        this.sharedService.dataRutaActual$.pipe(untilDestroyed(this)).subscribe(datRouteData => {
            if (datRouteData) {
                this.derechosActuales = datRouteData['derechos'];
                if (!this.ignorarDerechos) {
                    this.ignorarDerechos = datRouteData['ignorarDerechos'] ?? false;
                }
            }
        });
        this.paginacion.pageSize = this.pageSize;
        this.paginacion.pageSizeOptions = this.pageSizeOptions;
        this.actualizarCrud();
        if (this.columnasTabla.length > 0 && this.columnasTabla[0].type == 'checkbox') {
            this.formulariosGlobalesService.crearFormularioSeleccion(this.nombreColeccion);
        }
        this.agregarBotonExcel();
        if (this.dataSuscription) {
            this.dataSuscription.subscribe(res => {
                if (res) {
                    const seleccionado = this.objThis['seleccionados'][this.nombreColeccion];
                    if (seleccionado) {
                        if (this.idTabla && this.idTabla.length > 0) {
                            const arrayData = this.idTabla.map(d => seleccionado[d]);
                            // console.log(arrayData);
                            const busqueda = dataEnLista( res, this.idTabla, arrayData);

                            if (busqueda) {
                                console.log(busqueda);
                                this.objThis['seleccionados'][this.nombreColeccion] = busqueda;
                                // this.opcMenu(busqueda, {tipo: 'ver'});
                                // this.opcMenu(busqueda, {tipo: 'ver'});
                            }
                            // console.log(busqueda);
                        }
                        else {
                            const dataStr = res.find(itm => shortHash(JSON.stringify({...itm, claseFinal: undefined})) == shortHash(JSON.stringify({...seleccionado, claseFinal: undefined})));
                            if (dataStr) {
                                this.objThis['seleccionados'][this.nombreColeccion] = dataStr;
                            }
                            // const arrayData = {data, claseFinal: undefined}
                        }
                        // else {
                        //     this.objThis['seleccionados'][this.nombreColeccion] = null;
                        // }
                    }
                    this.setData(res);
                }
            });
        }
        // this.isRecargarUsed = this.accionRecargar.observers.length > 0;
        this.isRecargarUsed = this.accionRecargar.observed;
    }

    ngAfterContentInit() {
        this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
        if (this.ctrlBusquedaValue) {
            this.frmFiltro.get('cCampoBusqueda').setValue(this.ctrlBusquedaValue);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        if (this.uniqidRowSelect != null) {
            this.setClickedRow(this.uniqidRowSelect);
        }

        if (changes.botonesCRUD || changes.ctrlBusqueda) {
            // this.botonesCRUD = changes.botonesCRUD.currentValue;
            this.actualizarCrud();
        }
        if (changes.asignarValores) {
            // console.log(changes);
            this.asignarValores = changes.asignarValores.currentValue;
        }
        if (changes.data && changes.data.currentValue) {
            if (!this.dataSuscription) {
                this.setData(this.data);
            }
        }

        if (changes.botonesMenu) {
            this.agregarBotonExcel();
        }
    }

    trackByFn(index: number, item: { id: number; value: number }) {
        return this.nombreColeccion + '_' + this.idTablaValor(item);
    }
    agregarBotonExcel() {
        if (this.exportarExcel && this.esTabla) {
            const objBoton = dataEnLista(this.templateBotonesComunes, 'tipo', 'exportar_excel');
            const objBotonEnInferior = dataEnLista(this.listaMenuInferior, 'tipo', 'exportar_excel');

            if (!objBotonEnInferior && objBoton) {
                this.listaMenuInferior.push({
                    ...objBoton,
                    ...{
                        esIndependiente: true,
                    }
                });
            }
        }
    }

    estilizarGruposBotones(grupo: BotonMantenimiento[]) {
        if (grupo.length > 0) {
            grupo[0].class += ' rounded-l';
            grupo[grupo.length - 1].class += ' rounded-r';
        }
        return grupo;
    }

    actualizarCrud() {
        this.listaCRUD = [];

        const arrayCRUD = this.botonesCRUD.toUpperCase().split('');

        const genBoton = (tipo) => {
            objBoton = dataEnLista(this.templateBotonesComunes, 'tipo', tipo);
            if (objBoton) {
                const enOtr = dataEnLista(this.objBotonesCRUD, 'tipo', tipo);
                if (enOtr) {
                    objBoton = {...objBoton, ...enOtr};
                }
                this.listaCRUD.push(objBoton);
            }
        };

        let objBoton;
        if (arrayCRUD.includes('C')) {
            genBoton('nuevo');
        }
        if (arrayCRUD.includes('U')) {
            genBoton('editar');
        }
        if (arrayCRUD.includes('D')) {
            genBoton('eliminar');
        }

        if (this.ctrlBusqueda && this.ctrlBusqueda == 'simple') {
            this.frmFiltro.get('cCampoBusqueda').valueChanges.pipe(
                untilDestroyed(this),
            ).subscribe(value => this.onFilterChange(value));
        }
        if (this.botonesCRUD == '-'){
            this.listaCRUD = [];
        }
    }


    classFila(item) {
        let claseFinal = [];
        if (this.campoAnulado && (item[this.campoAnulado] == 1)) {
            claseFinal = claseFinal.concat(this.classAnulado);
        }
        if ((item['uniqid'] && item['uniqid'] == this.selectedRow) || (this.objThis['seleccionados'][this.nombreColeccion] == item)) {
            claseFinal = claseFinal.concat(this.classSeleccionado);
        }
        claseFinal = claseFinal.concat(this.condicionesClaseFila(item));
        item.claseFinal = claseFinal;
        return claseFinal;
    }

    subItemsActivos(btn, item){
        return btn.subItems.filter(sub => !sub.esVisible || sub.esVisible(item)).length > 0;
    }

    noData() {
        if (!this.dataSource) {
            return of(true);
        }
        // @ts-ignore
        return this.table.dataSource?.connect().pipe(map(data => data?.length === 0));
    }

    onFilterChange(value: string) {
        if (!this.dataSource) {
            return;
        }
        value = value.trim();
        value = value.toLowerCase();
        this.dataSource.filter = value;
    }

    toggleColumnVisibility(column, event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        column.visible = !column.visible;
    }

    cargarData(retorno = false) {

        if (this.ctrlBusqueda && this.ctrlBusqueda == 'simple') {
            if (!retorno) {
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }

            const aS = this.dataSource?.data;

            if (
                (!aS || !aS[0] || !aS[0]['iTotalRegistros'])
            ) {
                return false;
            }
        }
        const dPag = {
            iPaginacion: 1,
            iPageSize: this.paginator?.pageSize ? this.paginator?.pageSize : this.paginacion.pageCurrent?.pageSize,
            iPageNumber: (this.paginator?.pageIndex ? this.paginator?.pageIndex : 0) + 1,
        };
        if (this.ctrlBusqueda && this.ctrlBusqueda == 'query') {
            dPag['cCampoBusqueda'] = this.frmFiltro.get('cCampoBusqueda').value;
        }
        if (retorno) {
            return dPag;
        }
        this.resultados.emit(dPag);
        this.opcBusqueda.emit(dPag);
    }

    setData(data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sortingDataAccessor = (item, property) => {
            const dColumn: ColumnaTabla<any> = dataEnLista(this.columnasTabla, 'property', property);
            if (dColumn) {
                switch (dColumn.sortType ?? dColumn.type) {
                    case 'number':
                        return item[property] * 1;
                    case 'date':
                        return formatearFecha(item[property]);
                }
            }
            switch (property) {
                case 'fromDate':
                    return new Date(item.fromDate);
                default:
                    return item[property];
            }
        };
        // this.paginator.length = (data && data[0] && data[0]['iTotalRegistros']) ? data[0]['iTotalRegistros'] : data.length;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // this.dataSource.filter = this.filter;
        this.dataSourceChange.emit(this.dataSource);
        // this.dataSource.paginator.pageSize = this.paginacion.pageSize;
        if (data && data[0] && data[0]['iPageNumber']) {
            this.paginacion.pageIndex = (data && data[0] && data[0]['iPageNumber']) ? data[0]['iPageNumber'] : 0;
        }
        this.paginacion.pageLength = (data && data[0] && data[0]['iTotalRegistros']) ? data[0]['iTotalRegistros'] : data.length;
        if (data && data[0] && !data[0]['iTotalRegistros']) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.columnasTabla.length > 0 && this.columnasTabla[0].type == 'checkbox') {
            if (this.columnasTabla[0].chkField) {
                this.formulariosGlobalesService.agregarControles(this.nombreColeccion, data, this.columnasTabla[0].chkField, !this.columnasTabla[0].chkFieldPersistente, this.columnasTabla[0].chkFieldSeparador ?? '');
            }
            else {
                this.formulariosGlobalesService.agregarControles(this.nombreColeccion, data, this.columnasTabla[0].property, !this.columnasTabla[0].chkFieldPersistente);
            }
        }
    }

    botonDisabled(btn: BotonMantenimiento, item) {
        const noMostarLog = [
            'nuevo',
            'editar',
            'eliminar',
            'anular',
            'imprimir',
            // 'eliminar',
        ];
        // if (!noMostarLog.includes(btn.tipo)) {
        //     console.log('NO ES ', btn.tipo, btn);
        // }
        let disabled;
        if (!btn.sinCondicion) {
            if (btn.estaDeshabilitado) {
                disabled = btn.estaDeshabilitado(item);
            }
            else {
                disabled = !this.objThis['seleccionados'][this.nombreColeccion];
            }
        }
        if (!disabled && !btn.esIndependiente && !btn.ignorarDerecho && this.derechosActuales) {
            if (!this.usuarioService.esEquipoJVSoft()) {
                disabled = !(this.derechosActuales[btn.cDerechoCodigo ?? btn.tipo]);
            }
        }
        // if (!disabled && this.derechosActuales && this.derechosActuales[btn.tipo]) {
        //
        // }
        return disabled;
    }

    // FIN FILTRO BUSQUEDA INTEGRAR

    listaFinal() {
        const menuCompleto = this.listaMenuIzquierda
            .concat(this.listaCRUD)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenu)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenuDerecha)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenuSecundario)
            .concat({tipo: '-#SEPARADOR#-'})
            .concat(this.listaMenuInferior)
        ;
        console.log(menuCompleto);
        return menuCompleto;
    }

    scrollToIndex(id): void {
        if (id == 'footer') {
            this.rowFooter.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
        }
        id = this.nombreColeccion + '_' + id;
        // this.selectedRowId = id;
        const elem = this.rows.find(row => row.nativeElement.id === id.toString());

        elem?.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
    }

    opcMenu(item, objMenu: OpcionSeleccionada, modal = null, retorno = false) {
        console.log(objMenu);
        let salir = this.readOnly;
        if (salir && this.readOnly === 'permitirSeleccion' && ['ver', 'seleccionar'].includes(objMenu.tipo)) {
            salir = false;
        }
        if (salir) {
            return false;
        }

        let itmsSeleccionados;
        if (this.columnasTabla.length > 0 && this.columnasTabla[0].type == 'checkbox') {
            itmsSeleccionados = this.formulariosGlobalesService.generarLista(this.nombreColeccion);
        }
        // console.warn(objMenu);
        if (objMenu.tipo == 'exportar_excel' && this.exportarExcel) {
            /*
            TableUtil.exportArrayToExcel(this.dataSource.data.map(x => {
                const columnasNumeros = this.objVisibleColumns.filter(column => ['money', 'number'].includes(column.type));
                columnasNumeros.forEach(columna => {
                    x[columna.property] *= 1;
                });
                return x;
            }), 'TEST');
            */
            const dataGeneradoPorHtml = TableUtil.getWorkBook('tabla_' + this.nombreColeccion, []);

            let objetosExtraidos = this.dataSource.filteredData;

            if (itmsSeleccionados) {
                objetosExtraidos = objetosExtraidos.filter(objeto => {
                    const idRow = (this.columnasTabla[0].chkField) ?
                        this.generarIdSeleccion(objeto, this.columnasTabla[0].chkField, this.columnasTabla[0].chkFieldSeparador ?? '') :
                        objeto[this.columnasTabla[0].property];
                    return (itmsSeleccionados.split(',').includes(idRow.toString()));
                });
            }

            objetosExtraidos = objetosExtraidos.map((objeto, idx) =>
                this.objVisibleColumns.reduce((acumulador, columna) => {
                    // console.log(propiedad);
                    const nColumn = (columna.labelLista ?? columna.label).replace('<br>', ' ');
                    switch (columna.type) {
                        case 'date':
                            // acumulador[nColumn] = (new DatePipe('es-PE')).transform(objeto[columna.property], 'dd/MM/yyyy' /*'yyyy-MM-dd'*/);
                            // new Date(dateString.replace(/(\d{2}:\d{2}:\d{2}):\w{2}/, '$1'));
                            acumulador[nColumn] = new Date(objeto[columna.property].replace(/(\d{2}:\d{2}:\d{2}):\w{2}/, '$1'));
                            // acumulador[nColumn] = objeto[columna.property];
                            break;
                        case 'number':
                        case 'money':
                            acumulador[nColumn] = objeto[columna.property] * 1;
                            break;
                        default:
                            if (columna.property == 'numeracion_automatica') {
                                acumulador[nColumn] = idx + 1;
                            }
                            else {
                                if (columna.type == 'custom') {
                                    // dataGeneradoPorHtml.sheetToJson
                                    acumulador[nColumn] = dataGeneradoPorHtml.sheetToJson[idx][columna.label];
                                }
                                else {
                                    if(columna.innerHTML) {
                                        const convertirHtmlATexto = (html: string): string => {
                                            const elementoTemporal = document.createElement('div');
                                            elementoTemporal.innerHTML = html;
                                            return elementoTemporal.textContent || "";
                                        }
                                        acumulador[nColumn] = convertirHtmlATexto(columna.innerHTML(objeto));
                                    }
                                    else if(columna.transformar) {
                                        acumulador[nColumn] = columna.transformar(objeto);
                                    }
                                    else {
                                        acumulador[nColumn] = objeto[columna.property];
                                    }
                                }
                            }
                            break;
                    }
                    return acumulador;
                }, {} as Record<string, any>)
            );

            // TableUtil.exportTableToExcel('tabla_' + this.nombreColeccion);
            TableUtil.exportArrayToExcel(objetosExtraidos, this.nombreColeccion);

            // this.tableExporter.exportTable('xlsx', {fileName: 'JVSoftExport'});
        }
        console.log(itmsSeleccionados);
        // @ts-ignore
        const opAdic = {seccion: this.nombreColeccion, modal: (objMenu.modal ? objMenu.modal : modal)};
        if (item) {
            opAdic['item'] = item;
        }

        if (itmsSeleccionados) {
            opAdic['seleccionados'] = itmsSeleccionados;
        }
        objMenu = {...objMenu, ...opAdic};
        if (retorno) {
            return objMenu;
        }
        // console.warn(objMenu);
        this.opcionSelecionada.emit(objMenu);
    }

    generarIdSeleccion(dat, idObjLista, idCheckBoxSeparador = '') {
        if (Array.isArray(idObjLista)) {
            const idControlCheckBoxArray = [];
            let idControlCheckBox = '';
            idObjLista.forEach(prop => {
                idControlCheckBoxArray.push(dat[prop]);
                idControlCheckBox += dat[prop];
            });
            return idControlCheckBoxArray.join(idCheckBoxSeparador);
        }

        return dat[idObjLista];
    }

    resultadosFILTRO(evento) {
        if (!this.idTabla) {
            this.objThis['seleccionados'][this.nombreColeccion] = null;
        }
        if (this.opcFiltroActual != evento) {
            this.opcFiltroActual = evento;

            this.paginator?.firstPage();
        }
        this.emitirResultados(evento);
    }

    resultadosFILTROConLabel(evento) {
        this.resultadosConLabel.emit(evento);
    }

    emitirResultados(evento = null) {
        this.opcFiltroActual = {...this.opcFiltroActual, ...evento, ...this.cargarData(true)};
        // console.warn('resultadosFILTRO', this.opcFiltroActual);
        this.resultados.emit(this.opcFiltroActual);
    }

    dataFiltroFILTRO(evento) {
        // console.warn('dataFiltroFILTRO', evento);
        // this.dataFiltro.emit({...evento, ...this.cargarData(true)});
    }

    abrirMenuContextual({x, y}: MouseEvent, user) {
        // console.log(user);
        // this.opcMenu(user, {tipo: 'ver'});
        this.objThis['seleccionados'][this.nombreColeccion] = user;
        this.cerrarMenuContextual();
        const positionStrategy = this.overlay.position().flexibleConnectedTo({x, y}).withPositions([
            {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
            },
        ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });

        this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
            // $implicit: user
            item: user,
        }));

        this.mouseEvent$ = fromEvent<MouseEvent>(document, 'click').pipe(
            filter(event => {
                const clickTarget = event.target as HTMLElement;
                return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
            }),
            take(1),
        ).subscribe(() => this.cerrarMenuContextual());

    }

    cerrarMenuContextual() {
        this.mouseEvent$ && this.mouseEvent$.unsubscribe();
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    emitirAccionRecargar() {
        if (!this.idTabla) {
            this.objThis['seleccionados'][this.nombreColeccion] = null;
        }
        this.accionRecargar.emit(this.opcFiltroActual);
        // this.accionRecargar.emit({ ...this.opcFiltroActual, ...{seleccionado: this.objThis['seleccionados'][this.nombreColeccion]}});
    }

    public selection: SelectionModel<any>;
    keyUp(event) {
        console.log(event);
    }
}
