<!--<form globalDialogFlotante (ngSubmit)="opcMenu({item: undefined ,seccion: 'destinatarios', tipo: (isCreateMode() ? 'agregar_a_lista' : 'actualizar_envio') })" [formGroup]="frmRegistro">-->
<form globalDialogFlotante btnCerrar (ngSubmit)="opcMenu({item: undefined ,seccion: datosDialogRecibidos.seccion, tipo: datosDialogRecibidos.formAccion })" [formGroup]="frmRegistro">
	<ng-container titulo>
        <ng-container *ngIf="editarAdmin; else noEditAdmin">
            EDITANDO MOVIMIENTO
        </ng-container>
		<ng-template #noEditAdmin>
            {{ (isUpdateMode() ? 'Editar' : 'Nuevo') }} ::<small>Destinatario</small>
        </ng-template>
	</ng-container>
	<ng-container selectTitulo>
		<div class="flex items-center justify-start text-xs">
			<mat-checkbox formControlName="bTramMovCopia">COPIA</mat-checkbox>
		</div>
	</ng-container>

	<div cuerpo class="w-full flex flex-col items-center sm:gap-1 text-xs">
		<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1"
			*ngIf="isCreateMode() && !esDerivacion() && !esProveido()"
		>
			<ng-container *ngIf="this.datosDialogRecibidos.tramitesMasivos" [ngTemplateOutlet]="t_RegItems"></ng-container>
			<mat-tab-group class="flex-1 w-full" mat-stretch-tabs="true"
				*ngIf="!this.datosDialogRecibidos.tramitesMasivos"
				dynamicHeight
				[selectedIndex]="frmRegistro.get('envio_externo').value"
				(selectedIndexChange)="cambiarTipoEnvio($event)">
				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon svgIcon="roundHomeWork" class="icon-xs"></mat-icon> Interno
					</ng-template>
					<ng-container *ngTemplateOutlet="t_RegItems"></ng-container>
				</mat-tab>
				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon svgIcon="roundPublic" class="icon-xs"></mat-icon> Externo
					</ng-template>
					<ng-container *ngTemplateOutlet="t_RegItems"></ng-container>
				</mat-tab>
			</mat-tab-group>
		</div>

		<ng-container *ngIf="isUpdateMode() || esDerivacion() || esProveido()">
			<ng-container *ngTemplateOutlet="t_RegItems"></ng-container>
		</ng-container>
	</div>
	<ng-container pieDerecha>
		<button matRipple class="boton-dialog-flotante boton-dialog-flotante-blue"  type="submit"
			*ngIf="editarAdmin || (isCreateMode() && !esDerivacion())"
		>
			<mat-icon class="icon-xs" svgIcon="mat:save"></mat-icon>
			<span>GUARDAR</span>
		</button>

		<button matRipple class="boton-dialog-flotante boton-dialog-flotante-amber" type="submit" *ngIf="!editarAdmin && isUpdateMode()">
			<mat-icon class="icon-xs" svgIcon="mat:edit"></mat-icon>
			<span>ACTUALIZAR ENVÍO</span>
		</button>
		<button matRipple class="boton-dialog-flotante boton-dialog-flotante-green"  type="submit" *ngIf="esDerivacion()">
			<mat-icon class="icon-xs" svgIcon="mat:send"></mat-icon>
			<span>ENVIAR</span>
		</button>
	</ng-container>



	<ng-template #t_RegItems>
		<div class="flex-1 w-full flex flex-col items-center flex-wrap sm:gap-1">
			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1"
				*ngIf="isCreateMode() && !esEnvioExterno()"
			>
				<mat-form-field class="flex-1" *ngIf="esEnvioMultiple() && this.dataServidor.despachosparaenviar">
					<mat-label>Despachos</mat-label>
					<mat-select formControlName="iDespachoReceptorId" [multiple]="true">
						<mat-select-trigger>
							<div class="flex flex-row flex-wrap">
								<div *ngFor="let item of frmRegistro.get('iDespachoReceptorId').value"
									class="flex justify-center items-center m-1 px-2 py-1 border border-gray-400 rounded-full bg-gray-300 text-base text-gray-700 font-medium"
								>
									<div class="flex items-center justify-center max-w-full leading-none font-normal" style="font-size: 65%">
											<span class="flex-none" >
												{{ (this.dataServidor.despachosparaenviar | dataEnLista: 'iDespachoId':item)?.cDespachoNombreCorto.trim() }}
											</span>
										<button matRipple class="boton-circular-gris text-red-700" (click)="eliminarItemReceptores(item); $event.stopPropagation();">
											<mat-icon svgIcon="mat:close" class="icon-xs"></mat-icon>
										</button>
									</div>
								</div>
							</div>
						</mat-select-trigger>
						<mat-option class="sinCheck">
							<input #inputBuscarDependencia type="text" class="bold text-blue-700 border border-blue-700 w-full"
								(click)="$event.stopPropagation(); $event.preventDefault(); $event.stopImmediatePropagation();"
								placeholder="Buscar..." autocomplete="off" cdkFocusInitial>
						</mat-option>
						<mat-option [value]="item.iDespachoId" *ngFor="let item of this.dataServidor.despachosparaenviar | filtro: { texto: inputBuscarDependencia.value, campo: ['cDespachoNombreCorto', 'cDespachoNombreLargo'], mostrarObligado: { campo: 'iDespachoId', items: frmRegistro.get('iDespachoReceptorId').value}}">
							<small>{{ item.cDespachoNombreLargo.trim() }}</small>
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field class="flex-1" *ngIf="!esEnvioMultiple() && this.dataServidor.despachosparaenviar">
					<mat-label>Despacho</mat-label>
					<input matInput formControlName="iDespachoReceptorId" [matAutocomplete]="iDespachoReceptorId" autocomplete="off">
					<mat-autocomplete #iDespachoReceptorId="matAutocomplete"
						panelWidth="auto"
						[displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iDespachoId', campoValue: 'cDespachoNombreLargo', lista: this.dataServidor.despachosparaenviar})"
					>
						<mat-option *ngFor="let item of this.filtrados['despachosparaenviar'] | async" [value]="item.iDespachoId">
							<small>{{ item.cDespachoNombreLargo.trim() }}</small>
						</mat-option>
					</mat-autocomplete>

				</mat-form-field>
			</div>

			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1" *ngIf="esEnvioExterno()">
				<mat-form-field class="flex-1">
					<mat-label>Tipo Persona</mat-label>
					<mat-select formControlName="iTipoPersId" value="1">
						<mat-option [value]="item.iTipoPersId" *ngFor="let item of dataServidor.tipos_personas">{{ item.cTipoPersNombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="flex-1" *ngIf="dataServidor.tipos_personas_identificaciones && dataServidor.tipos_personas_identificaciones.length > 1">
					<mat-label>Tipo Identificación</mat-label>
					<mat-select formControlName="iTipoIdentId">
						<ng-container *ngFor="let item of dataServidor.tipos_personas_identificaciones">
							<mat-option [value]="item.iTipoIdentId">{{ item.cTipoIdentSigla }}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1" *ngIf="dTipoPers && frmRegistro.get('envio_externo').value == 1">
				<mat-form-field class="flex-1 w-full">
					<mat-label>Receptor</mat-label>
					<general-mat-buscar-persona formControlName="iPersReceptorId" [tipoPersona]="null"
						[iTipoIdentId]="dTipoPers?.iTipoIdentId"
						[incluirProveedorRemoto]="[1, 2].includes(dTipoPers?.iTipoIdentId * 1)"
						[placeholder]="dTipoPers?.cTipoIdentNombre"
						(textoEscrito)="frmRegistro.get('cPersReceptorNombre').setValue($event)"
					></general-mat-buscar-persona>
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iPersReceptorId')) }}</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1"
				*ngIf="esEnvioExterno() && frmRegistro.get('iPersReceptorId').value"
			>
				<mat-checkbox formControlName="enviarCorreo">Enviar a Correo Electrónico</mat-checkbox>
				<mat-form-field class="flex-1" *ngIf="frmRegistro.get('enviarCorreo').value">
					<mat-label>E-Mail</mat-label>
					<input type="email" formControlName="email" matInput autocomplete="off">
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('email')) }}</mat-error>
				</mat-form-field>

				<mat-form-field class="flex-1" *ngIf="frmRegistro.get('enviarCorreo').value">
					<mat-label>Folios</mat-label>
					<input type="number" formControlName="iTramMovFolios" matInput autocomplete="off">
				</mat-form-field>
			</div>
			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1"
				*ngIf="!frmRegistro.get('enviarCorreo').value && !esDerivarMasivo() "
			>
				<mat-form-field class="flex-1">
					<mat-label>Prioridad</mat-label>
					<mat-select formControlName="iTipoPrioId">
						<mat-option [value]="item.iTipoPrioId" *ngFor="let item of dataServidor.tipos_prioridades">{{ item.cTipoPrioNombre }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field class="flex-1">
					<mat-label>Plazo de Resolución</mat-label>
					<input type="number" formControlName="iTramMovPlazoResolverDias" matInput autocomplete="off">
					<span matSuffix>&nbsp;día(s)</span>
				</mat-form-field>
				<mat-form-field class="flex-1">
					<mat-label>Folios</mat-label>
					<input type="number" formControlName="iTramMovFolios" matInput autocomplete="off">
				</mat-form-field>
			</div>
			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1">
				<mat-form-field class="flex-1">
					<mat-label>{{ (esDerivacion() || esProveido()) ? 'Acción' : (esEnvioExterno() ? 'Sustento' : 'Observación') }}</mat-label>
					<textarea formControlName="cTramMovObsEnvio" class="font-mono text-xs" matInput cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoIdentId')) }}</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-1">
				<mat-form-field class="flex-1">
					<mat-label>Archivos Físicos</mat-label>
					<mat-chip-grid #chipGrid formControlName="cTramMovAdjuntoFisico" aria-label="Archivos Físicos">
						<mat-chip-row *ngFor="let item of frmRegistro.get('cTramMovAdjuntoFisico').value"
							class="text-2xs"
							(removed)="cambiosChips(frmRegistro.get('cTramMovAdjuntoFisico'), 'remove', item)"
						>
							{{item}}
							<button matChipRemove [attr.aria-label]="'eliminar ' + item">
								<mat-icon class="icon-xs" svgIcon="mat:cancel"></mat-icon>
							</button>
						</mat-chip-row>
						<input placeholder="Archivo..."
							[matChipInputFor]="chipGrid"
							[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							[matChipInputAddOnBlur]="true"
							(matChipInputTokenEnd)="cambiosChips(frmRegistro.get('cTramMovAdjuntoFisico'), 'add', $event.value); $event.chipInput.clear();"/>
					</mat-chip-grid>
				</mat-form-field>
			</div>
		</div>

	</ng-template>
</form>
