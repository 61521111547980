import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PrincipalComponent} from '../../modulos/principal/principal.component';
import {UsuarioService} from '@servicios/usuario.service';

@UntilDestroy()
@Component({
    selector: 'vex-select-layout',
    templateUrl: './select-layout.component.html',
    styleUrls: ['./select-layout.component.scss']
})
export class SelectLayoutComponent implements OnInit, OnDestroy {

    constructor(
        private pc: PrincipalComponent,
        private route: ActivatedRoute,
        private usuarioService: UsuarioService,
    ) {

        this.route.data.pipe(untilDestroyed(this)).subscribe(queryParams => {
            queryParams['parametrosFiltro'].filter(parametro => parametro['val'] == '*deUsuario*').forEach(parametro => {
                parametro.val = this.usuarioService._data.getValue()[parametro.campo] ?? '';
            });
            // console.log(queryParams['parametrosFiltro']);
            //'*deUsuario*'
            this.pc._parametros.next(queryParams['parametrosFiltro']);
            this.pc._headerTemplates.next(queryParams['headerTemplates']);
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.pc._parametros.next([]);
    }
}

