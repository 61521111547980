import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Self, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {FocusMonitor} from '@angular/cdk/a11y';
import {MatInput} from '@angular/material/input';
import {Subject} from 'rxjs';
import {ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {take} from 'rxjs/operators';
import {esNumero, mensajesDeError, verificarRUC} from '@JVSoft/services/funciones-globales.service';
import {QueryGeneralService} from '../../services/query-general.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DataServidor, OpcionSeleccionada} from '@JVSoft/interfaces/global';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {fadeInRight400ms} from '@vex/animations/fade-in-right.animation';
import {scaleIn400ms} from '@vex/animations/scale-in.animation';
import {stagger40ms} from '@vex/animations/stagger.animation';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {GeneralMantenimientoPersonasFormularioComponent} from '../../../modulos/general/general-mantenimiento/general-mantenimiento-personas/general-mantenimiento-personas-formulario/general-mantenimiento-personas-formulario.component';
import {ServidorService} from '@servicios/servidor.service';

@Component({
    selector: 'general-mat-buscar-persona',
    templateUrl: './mat-buscar-persona.component.html',
    styleUrls: ['./mat-buscar-persona.component.scss'],
    animations: [
        fadeInUp400ms,
        fadeInRight400ms,
        scaleIn400ms,
        stagger40ms
    ],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MatBuscarPersonaComponent,
        },
    ],
})
export class MatBuscarPersonaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
    get incluirProveedorRemoto(): boolean { return this._incluirProveedorRemoto; }
    @Input() set incluirProveedorRemoto(val: boolean | '') { this._incluirProveedorRemoto = val || true; }
    get sinContenedor(): boolean { return this._sinContenedor; }
    @Input() set sinContenedor(val: boolean | '') { this._sinContenedor = val || true; }

    @Input()
    set value(value: any) {
        this._value = value;
        this.stateChanges.next();
    }

    get value() {
        return this._value;
    }

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    get placeholder() {
        return this._placeholder;
    }

    get empty(): boolean {
        return !this._value && !this.formPrincipal.get('iPersId').value;
        // return !this.value.iPersId && !this.value.txtBuscar;
    }

    @HostBinding()
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty || this.formPrincipal.get('txtBuscar').value;
    }

    get errorState(): boolean {
        return this.errorStateMatcher.isErrorState(this.ngControl?.control, null);
    }

    constructor(
        private focusMonitor: FocusMonitor,
        @Optional() @Self() public ngControl: NgControl,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private errorStateMatcher: ErrorStateMatcher,
        private queryGeneralService: QueryGeneralService,
        public servidorService: ServidorService,
    ) {
        if (ngControl != null) {
            ngControl.valueAccessor = this;
        }
    }
    static nextId = 0;
    @Input() seleccionarTipo = true;
    @Input() tipoPersona: 'trabajadores' | 'individuos' | 'prestadores' | 'proveedores' | number | null = 'individuos' ;
    @Input() iTipoIdentId: number;
    @Input() anonimo = false;
    @Input() readonly = false;
    // @Input() sinContenedor = false;
    @Input() idxTipoIdentidadNoPermitidos = [2];

    @Input() registro = false;
    @Input() dataRegistro = {};

    private _incluirProveedorRemoto: boolean;

    private _sinContenedor: boolean;


    @Output() resultados = new EventEmitter<any>();
    @Output() textoEscrito = new EventEmitter<any>();
    @Output() dataSeleccionado = new EventEmitter<any>();

    @ViewChild('dialogResultados', {static: true}) dialogResultadosRef: TemplateRef<any>;

    dataServidor: {
        grl: {
            personas: null,
        },
    };
    dataServidorSuscripcion: DataServidor = {};

    infoSeleccionado = null;

    dialogRefSeleccion: MatDialogRef<any>;
    dialogOpc: MatDialogConfig = {
        panelClass: 'dialogMantenimientoNuevo',
        disableClose: true,
        data: {}
    };
    @ViewChild(MatInput, {read: ElementRef, static: true})
    input: ElementRef;

    private _value: any;

    private _placeholder: string;

    focused: boolean = false;

    @HostBinding()
    readonly id = `general-mat-buscar-persona-id-${MatBuscarPersonaComponent.nextId++}`;

    @HostBinding('attr.aria-describedby')
    userAriaDescribedBy = '';

    @Input()
    required: boolean;
    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.formPrincipal.disable() : this.formPrincipal.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    controlType = 'general-mat-buscar-persona';
    stateChanges = new Subject<void>();

    onChange: (value: any) => {};
    onTouched: () => {};

    formPrincipal: FormGroup = this.fb.group({
        tipoPersona: [''],
        txtBuscar: [''],

        iPersId: [''],
        cPersNombreLargo: [''],
        cTipoIdentSigla: [''],
        cPersDocumento: [''],
    });

    readonly autofilled: boolean;

    ngOnInit(): void {
        this.focusMonitor.monitor(this.input).subscribe((focused) => {
            this.focused = !!focused;
            this.stateChanges.next();
        });
        this.focusMonitor.monitor(this.input).pipe(take(1)).subscribe((focused) => {
            this.onTouched();
        });
        this.formPrincipal.valueChanges.subscribe((value) => {
            // console.log(value);
            if (value && value['iPersId']) {
                this.onChange(value['iPersId']);
            }
            else {
                if (value['iPersId'] === null) {
                    this.onChange('');
                }
            }
            // this.onChange(value);
        });
        console.log(this._value);
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.incluirProveedorRemoto) {
            this._incluirProveedorRemoto = ['', true].includes(changes.incluirProveedorRemoto.currentValue) ? true : changes.incluirProveedorRemoto.currentValue;
        }
        if (changes.registro) {
            this.registro = changes.registro.currentValue;
        }
        if (changes.dataRegistro) {
            this.dataRegistro = {
                ...this.dataRegistro,
                ...changes.dataRegistro.currentValue,
            };
        }
    }

    ngAfterViewInit() {
        if (this.sinContenedor) {
            this.input.nativeElement.classList.remove('mat-input-element');
        }
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.input);
        this.stateChanges.complete();
    }

    onContainerClick(event: MouseEvent): void {
        this.focusMonitor.focusVia(this.input, 'program');
    }

    setDescribedByIds(ids: string[]): void {
        this.userAriaDescribedBy = ids.join(' ');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        console.log(obj);
        this.value = obj;

        if (obj && esNumero(obj) && !this.formPrincipal.get('iPersId').value) {
            this.formPrincipal.get('iPersId').setValue(obj);
            this.buscarPersona();
        }
    }

    buscarPersona() {
        this.buscarLocal().then(bLocal => {
            if (bLocal && bLocal['grl.personas']) {
                if (bLocal['grl.personas'].length > 1) {
                    this.dialogRefSeleccion = this.dialog.open(this.dialogResultadosRef, this.dialogOpc);
                    return true;
                }
                else if (bLocal['grl.personas'].length == 1) {
                    this.focused = false;
                    this.seleccionarPersona(bLocal['grl.personas'][0]);
                    return true;
                }
            }
            return false;
        }).then((consiguio) => {
            if (!consiguio && this.incluirProveedorRemoto) {
                this.buscarRemoto().then(dRet => {
                    console.log(dRet);
                    if (dRet && dRet['proveedor_remoto'] && dRet['proveedor_remoto']['data']) {
                        this.focused = false;
                        this.seleccionarPersona(dRet['proveedor_remoto']['data']);
                    }

                }).catch(console.log);
            }
        });

    }
    buscarLocal() {
        const dEnv = {};
        if (esNumero(this.iTipoIdentId)) {
            dEnv['iTipoIdentId'] = this.iTipoIdentId;
        }
        if (esNumero(this.tipoPersona)) {
            dEnv['iTipoPersId'] = this.tipoPersona;
        }
        else {
            if (this.tipoPersona != null) {
                dEnv[this.tipoPersona] = 1;
            }
        }
        return this.queryGeneralService.cargar(this, ['grl.personas'], {
            ...this.formPrincipal.value,
            ...dEnv
        }, this.anonimo).then((ret) => {
            return ret;
        });
    }
    buscarRemoto() {
        return new Promise((resolve, reject) => {
            let tipoRemoto;
            let dataEnviar;
            const vConsulta = this.formPrincipal.get('txtBuscar').value;
            if (esNumero(vConsulta)) {
                switch (vConsulta.length) {
                    case 8:
                        tipoRemoto = 'reniec';
                        dataEnviar = {dni: vConsulta};
                        break;
                    case 11:
                        tipoRemoto = 'sunat';
                        if (!verificarRUC(vConsulta)) {
                            return false;
                        }
                        dataEnviar = {ruc: vConsulta};
                        break;
                }
            }

            if (tipoRemoto) {
                this.queryGeneralService.getDataProveedorRemoto(tipoRemoto, dataEnviar).then((a) => {
                    resolve(a);
                }).catch(err => {
                    mensajesDeError(err);
                    reject(err);
                });
            }
            else {
                reject('Datos no Validos');
            }
        });

    }
    seleccionarPersona(info) {
        console.log(this.ngControl);
        this.formPrincipal.patchValue({
            ...info
        });
        this.infoSeleccionado = info;
        this.textoEscrito.emit(info.cPersNombreLargo.trim());
        this.dataSeleccionado.emit(info);
        if (this.dialogRefSeleccion) {
            this.dialogRefSeleccion.close();
        }
    }


    abrirDialogRegistro() {
        this.dialogOpc.data.camposFijos = this.dataRegistro;

        const dialogPersona = this.dialog.open(GeneralMantenimientoPersonasFormularioComponent, this.dialogOpc);

        dialogPersona.componentInstance.opcMenuEmitter.subscribe((vRec: OpcionSeleccionada) => {

            console.log(vRec);
            if (vRec.datosExtra && vRec.datosExtra.persona) {
                this.seleccionarPersona(vRec.datosExtra.persona);
                dialogPersona.close();
            }
        });
    }

}
