import {groupBy, mensajeToast, ordenarPorPropiedad, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';

export function comprobantesPago(objThis: PdfPresupuestoService, dataReq: {cAno_eje: string, cExpediente: string, cCiclo: string, cFase: string, cSecuencia: string, cCorrelativo: string, }){
//     comprobantesPago(
//         dataReq: {
//         cAno_eje: string,
//             cExpediente: string,
//             cCiclo: string,
//             cFase: string,
//             cSecuencia: string,
//             cCorrelativo: string,
//     }
// )
//     {
        objThis.queryService.cargar(objThis, ['siaf.Expedientes_ComprobantesPago'], dataReq).then(res => {
            const cuerpoPdf = [];


            if (!res['siaf.Expedientes_ComprobantesPago'] || !res['siaf.Expedientes_ComprobantesPago'][0]) {
                mensajeToast('warning', 'Alerta', ' No existen datos para el registro actual');
                return false;
            }

            const dataGeneral = res['siaf.Expedientes_ComprobantesPago'][0];

            objThis.reiniciarOpciones({
                tamanioHoja: 'A5',
                margenesHoja: [20, 130, 20, 40],
                // orientacion: 'landscape',
                tamanioFuenteExtra: 1,
                customDefTableHeader: objThis.headerTableEntidad([
                    imprimirCelda('CONSTANCIA DE PAGO', null, {fontSize: 13, bold: true}),
                    imprimirCelda('UNIDAD EJECUTORA 001230 UNIVERSIDAD NACIONAL DE MOQUEGUA', null, {
                        fontSize: 9,
                        bold: true
                    }),
                ]),
            });

            const cabecera = [];


            cuerpoPdf.push(
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            table: {
                                widths: [230],
                                body: [
                                    [
                                        {text: 'DATOS GENERALES', style: 'thSmall'},
                                    ],
                                    [
                                        {
                                            table: {
                                                body: [
                                                    [
                                                        {text: 'RUC', style: 'tdSmall', bold: true},
                                                        {text: ':', style: 'tdSmall'},
                                                        {text: dataGeneral['Ruc_proveedor'], style: 'tdSmall'},
                                                    ],
                                                    [
                                                        {text: 'Nombre', style: 'tdSmall', bold: true},
                                                        {text: ':', style: 'tdSmall'},
                                                        {text: dataGeneral['Nombre_proveedor'], style: 'tdSmall'},
                                                    ],
                                                    [
                                                        {text: 'Documento', style: 'tdSmall', bold: true},
                                                        {text: ':', style: 'tdSmall'},
                                                        {text: dataGeneral['Abreviatura_doc_d'], style: 'tdSmall'},
                                                    ],
                                                    [
                                                        {text: 'Número', style: 'tdSmall', bold: true},
                                                        {text: ':', style: 'tdSmall'},
                                                        {
                                                            text: [dataGeneral['Serie_doc_d'], dataGeneral['Num_doc_d']].join('-'),
                                                            style: 'tdSmall'
                                                        },
                                                    ],
                                                    [
                                                        {text: 'Fecha', style: 'tdSmall', bold: true},
                                                        {text: ':', style: 'tdSmall'},
                                                        {text: dataGeneral['Fecha_doc_d'], style: 'tdSmall'},
                                                    ],
                                                ],
                                            },
                                            layout: 'noBorders',
                                        },
                                    ],
                                ],
                                alignment: 'center'
                            }
                        },
                        {width: '*', text: ''},
                    ]
                }
            );

            res['siaf.Expedientes_ComprobantesPago'].filter(dRow => {


                cuerpoPdf.push(
                    {
                        margin: [0, 5, 0, 0],
                        columns: [
                            {width: '*', text: ''},
                            {
                                width: 'auto',
                                table: {
                                    widths: [230],
                                    body: [
                                        [
                                            {
                                                text: 'DETALLE: TIPO DE GIRO ' + dRow['Nombre_tipo_giro'],
                                                style: 'thSmall'
                                            },
                                        ],
                                        [
                                            {
                                                table: {
                                                    body: [
                                                        [
                                                            {text: 'DOCUMENTO', style: 'tdSmall', bold: true},
                                                            {text: ':', style: 'tdSmall'},
                                                            {text: dRow['Abreviatura_doc_g'], style: 'tdSmall'},
                                                        ],
                                                        [
                                                            {text: 'NÚMERO', style: 'tdSmall', bold: true},
                                                            {text: ':', style: 'tdSmall'},
                                                            {text: dRow['Num_doc_g'], style: 'tdSmall'},
                                                        ],
                                                        [
                                                            {text: 'Documento', style: 'tdSmall', bold: true},
                                                            {text: ':', style: 'tdSmall'},
                                                            {text: dRow['Abreviatura_doc_b'], style: 'tdSmall'},
                                                        ],
                                                        [
                                                            {text: 'Número', style: 'tdSmall', bold: true},
                                                            {text: ':', style: 'tdSmall'},
                                                            {
                                                                text: [dRow['Cod_doc_b'], dRow['Num_doc_b']].join('-'),
                                                                style: 'tdSmall'
                                                            },
                                                        ],
                                                        [
                                                            {text: 'Moneda', style: 'tdSmall', bold: true},
                                                            {text: ':', style: 'tdSmall'},
                                                            {text: 'S/', style: 'tdSmall'},
                                                        ],
                                                        [
                                                            {text: 'Monto', style: 'tdSmall', bold: true},
                                                            {text: ':', style: 'tdSmall'},
                                                            {text: dRow['Monto_nacional'], style: 'tdSmall'},
                                                        ],
                                                        [
                                                            {
                                                                text: dRow['Notas'],
                                                                style: 'tdSmall',
                                                                alignment: 'justify',
                                                                italics: true,
                                                                colSpan: 3
                                                            },
                                                            {},
                                                            {},
                                                        ],
                                                    ],
                                                },
                                                layout: 'noBorders',
                                            },
                                        ],
                                    ],
                                    alignment: 'center'
                                }
                            },
                            {width: '*', text: ''},
                        ]
                    }
                );

            });


            objThis.finalizarCreacionPdf(cuerpoPdf);

        });
    // }
}

export function fasesExpediente(objThis: PdfPresupuestoService, dataReq: { cExpediente: string, cCiclo: string, cFase: string, cSecuencia: string, cCorrelativo: string}){
//     fasesExpediente(
//         dataReq: {
//         cAno_eje: string,
//             cExpediente: string,
//             cCiclo: string,
//             cFase: string,
//             cSecuencia: string,
//             cCorrelativo: string,
//     }
// ) {


        objThis.queryService.cargar(objThis, [
            'siaf.Secuencia_Documento_Fase',
            'siaf.Expediente_Nota',
            'siaf.Totales_Ciclo_Fase',
            'siaf.Expediente_Ingreso_Meta_Clasificador',
            'siaf.Expediente_Sec_func_Clasificador',
        ], {...dataReq, ...{agrupadoFase: 1}}).then((retDet) => {
            console.log(retDet);

            const dataPrincipal = retDet['siaf.Secuencia_Documento_Fase'];
            const dataNotasFases = retDet['siaf.Expediente_Nota'];
            const dataTotalesCicloFase = retDet['siaf.Totales_Ciclo_Fase'];
            const dataIngresoMetaClasificador = retDet['siaf.Expediente_Ingreso_Meta_Clasificador'];
            const dataSecFuncClasificador: any[] = retDet['siaf.Expediente_Sec_func_Clasificador'];

            console.log(dataSecFuncClasificador);
            const agrupado = groupBy(dataSecFuncClasificador, (obj) => obj.cDSCSec_Func);

            console.log(agrupado);

            dataSecFuncClasificador.filter(data => {
                data['detalle'] = agrupado[data['cDSCSec_Func']];
            });

            const dataGeneral = dataPrincipal[0];

            const cuerpoPdf = [];

            objThis.reiniciarOpciones({
                margenesHoja: [20, 150, 20, 40],
                orientacion: 'landscape',
                tamanioFuenteExtra: 1,
                customDefTableHeader: objThis.headerTableEntidad([
                    imprimirCelda('FASES DEL EXPEDIENTE', null, {fontSize: 13, bold: true}),
                    imprimirCelda('EXPEDIENTE Nº ' + [dataGeneral['Expediente'], dataGeneral['Ano_eje'], dataGeneral['Tipo_operacion'], dataGeneral['Nombre_TipoOperacion']].join(' - '), null, {fontSize: 11, bold: true}),
                    imprimirCelda('', null, {fontSize: 8, bold: true}),
                    {
                        table: {
                            widths: ['auto', '*'],
                            body: [
                                [
                                    imprimirCelda('Fuente de Financiamiento', null, {bold: true, alignment: 'left'}),
                                    imprimirCelda(dataGeneral['Nombre_FuenteFinanc'], null, {italics: true, alignment: 'left'}),
                                ],
                                [
                                    imprimirCelda('Tipo de Recurso', null, {bold: true, alignment: 'left'}),
                                    imprimirCelda(dataGeneral['Nombre_TipoRecurso'], null, {italics: true, alignment: 'left'}),
                                ],
                                [
                                    imprimirCelda('Certificado - Secuencia', null, {bold: true, alignment: 'left'}),
                                    imprimirCelda(dataGeneral['Compromiso_anual'], null, {italics: true, alignment: 'left'}),
                                ],
                            ],
                        },
                        layout: 'noBorders',
                    }
                ]),
            });

            const camposPrincipal: ColumnaTabla<any>[] = [
                { label: 'Ciclo', property: 'Ciclo', type: 'text' },
                { label: 'Fase', property: 'Nombre_Fase', type: 'text' },
                { label: 'Sec.', property: 'Secuencia', type: 'text' },
                { label: 'Cor.', property: 'Correlativo', type: 'text' },
                { label: 'F.F.', property: 'Fuente_financ', type: 'text' },
                { label: 'T.R.', property: 'Tipo_recurso', type: 'text' },
                { label: 'Código', property: 'Cod_doc', type: 'text' },
                { label: 'Documento', property: 'Abreviatura_doc', type: 'text' },
                { label: 'Serie', property: 'Serie_doc', type: 'text' },
                { label: 'Número', property: 'Num_doc', type: 'text' },
                { label: 'Monto', property: 'Monto', type: 'money', cssClasses: ['text-right'] },
                { label: 'C.B.', property: 'Cod_doc_b', type: 'text' },
                { label: 'Doc.B.', property: 'Abreviatura_doc_b', type: 'text' },
                { label: 'Num.B.', property: 'Num_doc_b', type: 'text' },
                { label: 'Fec.B.', property: 'fecha_doc_b', type: 'date' },
                { label: 'Nombre', property: 'Proveedor_Nombre', type: 'text' },
                { label: 'Entrega', property: 'Fecha_entrega', type: 'date' },
                { label: 'Pago', property: 'Fecha_pago', type: 'date' },
                { label: 'Proceso', property: 'Dia_proceso', type: 'text', transformar(row): string {
                        return [row['Dia_proceso'], row['Mes_proceso'], row['Ano_proceso']].join('/');
                    } },
                { label: 'Cta. Cte.', property: 'Dia_proceso', type: 'text', transformarDirecto(row): object {
                        const txtEnt = [row['Ano_cta_cte'], row['Banco'], row['Cta_cte']];
                        let txtImp = txtEnt.join(' - ');
                        if (txtEnt.join('') == '') {
                            txtImp = '';
                        }
                        return imprimirCelda(txtImp, null, {style: 'tdSmall', noWrap: true});
                    }  },
            ];

            cuerpoPdf.push(objThis.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: dataPrincipal,
                numeracion: true,
                idxResto: [8, 16],
                margin: [0, 0, 0, 5],
                // contenido: retDet['siaf.Secuencia_Documento_Fase']
            }));
            const lineas = [];
            Object.values(dataNotasFases).forEach((cFase) => {
                // @ts-ignore
                cFase.filter((nFase, idxFase) => {
                    if (idxFase == 0) {
                        lineas.push({text: 'Notas de Fase ' + nFase['Nombre_Fase'], bold: true, fontSize: 7});
                    }
                    lineas.push({text: '- ' + nFase['Nota'], italics: true, fontSize: 6});
                });

            });
            cuerpoPdf.push(lineas);



            const listaSumCicloColumnas: ColumnaTabla<any>[] = [
                { label: 'Ciclo', property: 'Nombre_Ciclo', type: 'text' },
                { label: 'Fase', property: 'Nombre_Fase', type: 'text' },
                { label: 'Monto', property: 'Monto', type: 'money', cssClasses: ['text-right'] },
                { label: 'Devolución', property: 'Devolucion', type: 'money', cssClasses: ['text-right'] },
            ];
            const listaClasificadorColumnas: ColumnaTabla<any>[] = [
                { label: 'Clasificador', property: 'Clasificador', type: 'text' },
                { label: 'Nombre Clasificador', property: 'Nombre_Clasificador', type: 'text' },
                { label: 'Monto', property: 'Monto', type: 'money', cssClasses: ['text-right'] },
            ];
            const listaSecuenciaFuncionalColumnas: ColumnaTabla<any>[] = [
                { label: 'Sec. Func.', property: 'Sec_func', type: 'text' },
                { label: 'Secuencia Funcional', property: 'cDSCSec_Func', type: 'text' },
                // { label: 'Nombre Secuencia Funcional', property: 'cNombre_Meta', type: 'text' },
                { label: 'Monto', property: 'nMonto', type: 'money', cssClasses: ['text-right'] },
            ];
            const listaSecuenciaFuncionalColumnasDetalle: ColumnaTabla<any>[] = [
                { label: 'Clasificador', property: 'Nombre_Clasificador', type: 'text', transformar(row): string {
                        return [row['Clasificador'], row['Nombre_Clasificador']].join(' ');
                    } },
                { label: 'Monto', property: 'nMonto', type: 'money', cssClasses: ['text-right'] },
            ];

            cuerpoPdf.push({
                table: {
                    margin: 0,
                    widths: ['auto', '*', '*'],
                    body: [
                        [
                            [
                                { text: ' ', bold: true, fontSize: 8, },
                                objThis.generarDesdeMantenimiento({
                                    titulos: listaSumCicloColumnas,
                                    contenido: dataTotalesCicloFase,
                                    idxResto: [1],
                                    margin: [0, 0, 0, 5],
                                }),
                            ],
                            [
                                { text: ['Ciclo: ' + dataReq.cCiclo, 'Fase:' + dataReq.cFase, 'Secuencia:' + dataReq.cSecuencia, 'Correlativo:' + dataReq.cCorrelativo].join(' - '), bold: true, fontSize: 8, alignment: 'center' },
                                objThis.generarDesdeMantenimiento({
                                    titulos: listaClasificadorColumnas,
                                    contenido: dataIngresoMetaClasificador,
                                    idxResto: [1],
                                    margin: [0, 0, 0, 5],
                                }),
                            ],
                            [
                                { text: ['Ciclo: ' + dataReq.cCiclo, 'Fase:' + dataReq.cFase, 'Secuencia:' + dataReq.cSecuencia, 'Correlativo:' + dataReq.cCorrelativo].join(' - '), bold: true, fontSize: 8, alignment: 'center' },
                                objThis.generarDesdeMantenimiento({
                                    titulos: listaSecuenciaFuncionalColumnas,
                                    contenido: dataSecFuncClasificador,
                                    idxResto: [1],
                                    margin: [0, 0, 0, 5],
                                    detalle: [
                                        {
                                            campo: 'detalle',
                                            titulos: listaSecuenciaFuncionalColumnasDetalle,
                                            sinTitulos: true,
                                            sinBordes: true,
                                            idxResto: [0],
                                            margin: [0, 0, 0, 0],
                                        },
                                    ]
                                }),
                            ],
                        ],
                    ],
                },
                layout: 'noBorders',
            });

            objThis.finalizarCreacionPdf(cuerpoPdf);
        });
    // }
}


export function ExpedienteDocumentoFase(objThis: PdfPresupuestoService, dataRec) {
    objThis.queryService.cargar(objThis, ['siaf.Secuencia_Documento_Fase'], {
        cCodigoCadena: dataRec.cCodigoCadena,
        cCiclo: dataRec.cCiclo,
        cFase: dataRec.cFase,
    }).then((valRetorno) => {
        console.log(valRetorno);
        let resultadosGenerales = valRetorno['siaf.Secuencia_Documento_Fase'];
        resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');

        objThis.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                imprimirCelda('REPORTE A - EXPEDIENTE MARCADOS CON TODAS SUS FASES', null, {fontSize: 13, bold: true}),
                imprimirCelda('', null, {fontSize: 8, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            {label: 'C', property: 'Ciclo', type: 'text'},
            {label: 'F', property: 'Fase', type: 'text'},
            {label: 'F.F', property: 'Fuente_financ', type: 'text'},
            {label: 'T.R', property: 'Tipo_recurso', type: 'text'},
            {label: 'COD', property: 'Cod_doc', type: 'text'},
            {label: 'DOCUMENTO', property: 'Abreviatura_doc', type: 'text'},
            {label: 'SERIE', property: 'Serie_doc', type: 'text'},
            {label: 'NÚMERO', property: 'Num_doc', type: 'text'},
            {label: 'FECHA.', property: 'fecha_doc', type: 'date'},
            {label: 'MONTO.', property: 'Monto', type: 'money'},
            {label: 'C.B.', property: 'Cod_doc_b', type: 'text'},
            {label: 'DOC.B.', property: 'Abreviatura_doc_b', type: 'text'},
            {label: 'NUM.B.', property: 'Num_doc_b', type: 'text'},
            {label: 'FECHA.B.', property: 'fecha_doc_b', type: 'date'},
            {label: 'PROVEEDOR/NOMBRE', property: 'Proveedor_Nombre', type: 'text'},
            {label: 'ENTREGA', property: 'Fecha_entrega', type: 'date'},
            {label: 'PAGO', property: 'Fecha_pago', type: 'date'},
            {label: 'PROCESO', property: 'Dia_proceso', type: 'text'}, // falta datos
            {label: 'CTA.CTE.', property: 'Ano_cta_cte', type: 'text'}, // falta datos
        ];

        const sumasTotales = sumarObjetos(resultadosGenerales, [
            'Monto',
        ]);

        const agrupadoExpediente = groupBy(resultadosGenerales, (obj) => {
            return obj.Expediente;
        });

        let dataExpediente = {
            Expediente: '',
            Ano_eje: '',
            Tipo_operacion: '',
            Nombre_TipoOperacion: '',
        };

        let pag = 0;
        let nuevoCuerpo = [];
        Object.keys(agrupadoExpediente).forEach((secExpediente) => {
            dataExpediente = {
                ...{
                    Expediente: agrupadoExpediente[secExpediente][0]['Expediente'],
                    Ano_eje: agrupadoExpediente[secExpediente][0]['Ano_eje'],
                    Tipo_operacion: agrupadoExpediente[secExpediente][0]['Tipo_operacion'],
                    Nombre_TipoOperacion: agrupadoExpediente[secExpediente][0]['Nombre_TipoOperacion'],
                },
            };


            const tablaGenerada = objThis.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: ordenarPorPropiedad(agrupadoExpediente[secExpediente], 'Expediente'),
                // numeracion: true,
                idxResto: [6],
                margin: [0, 0, 0, 5],
                separado: true,
            });

            if (nuevoCuerpo.length == 0) {
                nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['titulos']);
            }

            nuevoCuerpo = nuevoCuerpo.concat([

                generarColSpan(imprimirCelda('EXPEDIENTE N° ' + dataExpediente.Expediente + ' - ' + dataExpediente.Ano_eje + ' - ' + dataExpediente.Tipo_operacion
                    + ' - ' + dataExpediente.Nombre_TipoOperacion , null, {
                    bold: true,
                    fontSize: 8,
                    style: 'thSmall',
                    alignment: 'left'
                }), 19),
            ]).concat(tablaGenerada['cuerpo']);

            pag++;
        });

        nuevoCuerpo = nuevoCuerpo.concat([
            generarColSpan(imprimirCelda('TOTAL S/.', null, {
                bold: true,
                fontSize: 8,
                style: 'thSmall',
                alignment: 'right'
            }), 9).concat(
                imprimirCelda(sumasTotales.Monto, 'money', {
                    bold: true,
                    style: 'thSmall',
                    alignment: 'left'
                }),
                generarColSpan(imprimirCelda('', null, {
                    bold: true,
                    fontSize: 8,
                    style: 'thSmall',
                    alignment: 'right'
                }), 9),
            ),
        ]);

        cuerpoPdf.push({
            margin: [0, 0, 0, 0],
            table: {
                dontBreakRows: true,
                headerRows: 1,
                // headerRows: 4,
                widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 50}, {idx: 1, valor: 30}, {idx: 2, valor: 30}, ]),
                body: nuevoCuerpo,
            }
        });
        // console.log(JSON.stringify(cuerpoPdf));
        // console.log(cuerpoPdf);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}

export function ExpedienteDocumentoFaseCicloFaseB(objThis: PdfPresupuestoService, dataRec) {
    objThis.queryService.cargar(objThis, ['siaf.Secuencia_Documento_Fase'], {
        cCodigoCadena: dataRec.cCodigoCadena,
        cCiclo: dataRec.cCiclo,
        cFase: dataRec.cFase,
    }).then((valRetorno) => {
        console.log(valRetorno);
        let resultadosGenerales = valRetorno['siaf.Secuencia_Documento_Fase'];
        resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');

        objThis.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            // orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                imprimirCelda('REPORTE B - CONSULTA DE EXPEDIENTES', null, {fontSize: 13, bold: true}),
                imprimirCelda('', null, {fontSize: 8, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            { label: 'AÑO', property: 'Ano_eje', type: 'text' },
            { label: 'N° SIAF', property: 'Expediente', type: 'text' },
            { label: 'F.F.', property: 'Fuente_financ', type: 'text' },
            { label: 'T.R.', property: 'Tipo_recurso', type: 'text' },
            { label: 'COD.', property: 'Cod_doc', type: 'text' },
            { label: 'DOCUMENTO', property: 'Abreviatura_doc', type: 'text' },
            { label: 'SERIE', property: 'Serie_doc', type: 'text' },
            { label: 'NÚMERO', property: 'Num_doc', type: 'text' },
            { label: 'FECHA', property: 'fecha_doc', type: 'date' },
            { label: 'MONTO', property: 'Monto', type: 'money' },
            { label: 'PROVEEDOR/NOMBRE', property: 'Proveedor_Nombre', type: 'text' },
            { label: 'GLOSA', property: 'Notas', type: 'text' },
        ];
        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: camposPrincipal,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
            numeracion: true,
            idxResto: [12],
            margin: [0, 0, 0, 5],
            // separado: true,
        });

        cuerpoPdf.push(tablaGenerada);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}

export function comprobantesDePagoSiaf(objThis: PdfPresupuestoService, dataRec) {

    objThis.queryService.cargar(objThis, ['siaf.comprobantes_pago'], {iTareaId: dataRec.iTareaId}, false, 'bud').then((rec) => {
        const data = rec['siaf.comprobantes_pago'];
        const dataGeneral = data[0];

        const cuerpoPdf = [];

        objThis.reiniciarOpciones({
            margenesHoja: [50, 100, 50, 40],
            orientacion: 'landscape',
            tamanioFuenteExtra: 2,
            customDefTableHeader: objThis.headerTableEntidad([
                imprimirCelda('COMPROBANTES DE PAGO', null, {fontSize: 13, bold: true}),
                imprimirCelda( 'META: ' + dataGeneral.cNombre_Meta, null, {fontSize: 13, bold: true}),
            ]),
        });

        const titulosContenido: ColumnaTabla<any>[] = [
            {label: 'Nº SIAF', property: 'Expediente', type: 'text', cssClasses: ['text-center']},
            {label: 'Ciclo', property: 'Ciclo', type: 'text'},
            {label: 'Fase', property: 'Fase', type: 'text'},
            {label: 'RUC', property: 'Ruc', type: 'text'},
            {label: 'T. Giro', property: 'Abreviatura_tipo_giro', type: 'text'},
            {label: 'Cod. Documento', property: 'Cod_doc', type: 'text'},
            {label: 'Documento', property: 'Abreviatura_doc', type: 'text'},
            {label: 'Nº Documento', property: 'Num_doc', type: 'text'},
            {label: 'Fec. Documento', property: 'Fecha_doc', type: 'text'},
            {label: 'Proveedor', property: 'Proveedor_Nombre', type: 'text'},

            {label: 'COD.', property: 'Cod_doc_b', type: 'text'},
            {label: 'NRO C.P.', property: 'Num_doc_b', type: 'text'},
            {label: 'Fecha C.P.', property: 'Fecha_doc_b', type: 'text'},
            {label: 'NRO GIRO', property: 'Num_doc_chequecci', type: 'text'},

            {label: 'Monto', property: 'Monto', type: 'money'},
            // {label: 'Notas', property: 'Notas', type: 'text'},
            /*
            Cod_doc_b = COD.
Num_doc_b = NRO C.P.
Fecha_doc_b = FECHA C.P.
Num_doc_chequecci = NRO GIRO
             */
        ];

        cuerpoPdf.push(objThis.generarDesdeMantenimiento({
            titulos: titulosContenido,
            contenido: data,
        }));
        // cuerpoPdf.push(objThis.generarDesdeMantenimiento(titulosContenido, data));

        objThis.finalizarCreacionPdf(cuerpoPdf);

    });



}

