import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'zeroFill',
})
export class ZeroFillPipe implements PipeTransform {

	transform(value: unknown, digitos: number, ...args: unknown[]) {
		let s = value + '';
		while (s.length < digitos) {
			s = '0' + s;
		}
		return s;
	}

}
export function zeroFill(value: unknown, digitos: number, ...args: unknown[]) {
	return new ZeroFillPipe().transform(value, digitos, args);
}
