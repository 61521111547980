import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {SessionService} from '../session/services/session.service';
import {EntidadService} from '@servicios/entidad.service';
import {UsuarioService} from '@servicios/usuario.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {SeleccionarEntidadComponent} from '../general/components/standalone/seleccionar-entidad/seleccionar-entidad.component';
import {from, of} from 'rxjs';

export const authMenuModulosGuard: CanActivateFn = (route, state) => {
    console.log('Primera Eval');

    const router = inject(Router);
    const sessionService = inject(SessionService);
    const entidadService = inject(EntidadService);
    const usuarioService = inject(UsuarioService);
    const dialog = inject(MatDialog);

    // Check for existing entity with fixed ID and no module param
    if (entidadService.idEntidadFija && !route.queryParams?.mod) {
        return entidadService.getEntidadById(entidadService.idEntidadFija).pipe(
            map((isFound) => {
                if (isFound && isFound['entidades'] && isFound['entidades'][0]) {
                    entidadService._entidadActual.next(isFound['entidades'][0]);
                    router.navigate(['e', entidadService.entidadActual.cEntSlug], { skipLocationChange: true });
                    return true;
                } else {
                    router.navigate(['/sesion/login']);
                    return false;
                }
            }),
            catchError((error) => {
                console.error(error);
                router.navigate(['/sesion/login'], { queryParams: route.queryParams });
                return of(false);
            })
        );
    }

    // Authentication check
    return sessionService.isAuthenticated().pipe(
        switchMap((response) => {
            if (response['iCredId']) {
                if (usuarioService.dataUsuario && !usuarioService.dataUsuario['iCredEntId']) {
                    // Entity selection needed
                    if (entidadService.idEntidadFija) {
                        return from(entidadService.setEntidadActualById(entidadService.idEntidadFija))
                        .pipe(map(() => true));
                    } else {
                        console.warn('Entity selection required');
                        const dialogRef: MatDialogRef<SeleccionarEntidadComponent> = dialog.open(
                            SeleccionarEntidadComponent,
                            {
                                closeOnNavigation: true,
                                panelClass: 'dialogMantenimientoNuevo',
                                disableClose: true,
                            }
                        );

                        return dialogRef.afterClosed().pipe(
                            // Handle entity selection result
                            tap((result) => {
                                console.log('Entity selected:', result);
                                // Optionally handle specific entity selection result
                            })
                        );
                    }
                } else {
                    // User already has entity assigned
                    return of(true);
                }
            } else {
                router.navigate(['/sesion/login']);
                return of(false);
            }
        }),
        catchError((error) => {
            console.error(error);
            router.navigate(['/sesion/login'], { queryParams: route.queryParams });
            return of(false);
        })
    );
};
