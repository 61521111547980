import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';

@Pipe({
  name: 'formControlIsRequired'
})
export class FormControlIsRequiredPipe implements PipeTransform {

	transform(formControl: AbstractControl, ...args: unknown[]): any {
		if (!formControl) { return false; }
		return formControl.hasValidator(Validators.required);
	}

}
export function  formControlIsRequired(formControl: AbstractControl) {
    return new FormControlIsRequiredPipe().transform(formControl);
}
