import {Injectable} from '@angular/core';
import {PdfService} from '../pdf.service';
import {MatDialog} from '@angular/material/dialog';
import {QueryService} from '../query.service';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {dateDiffString, DateDiffStringPipe} from '@JVSoft/pipes/date-diff-string.pipe';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {UsuarioService} from '@servicios/usuario.service';
import {getEstadoDocumento, TramiteService} from '@tramite/tramite.functions';
import {EntidadService} from '@servicios/entidad.service';
import {FiltroBusquedaService} from '@standalone/components/filtro-busqueda/filtro-busqueda.service';

@Injectable({
    providedIn: 'root',
})
export class PdfTramiteService extends PdfService {

    constructor(
        dialog: MatDialog,
        queryService: QueryService,
        usuarioService: UsuarioService,
        entidadService: EntidadService,
        filtroBusquedaService: FiltroBusquedaService,
        public tramiteService: TramiteService,
    ) {
        super(dialog, queryService, usuarioService, entidadService, filtroBusquedaService);
    }

    ticketStickerTramite(datos: { iTramId?: any; dtTramFechaHoraRegistro?: any; cTramDocumentoTramite?: any; cTramAsunto?: any; cTramCodigoQR?: any; iTramYearRegistro?: any; iTramNumRegistroYear?: any; iTramFolios?: any } = null) {

        const cuerpoPdf = [];
        this.reiniciarOpciones({
            tamanioHoja: {width: this.mmToPoints(75), height: this.mmToPoints(50)},
            orientacion: 'landscape',
            margenesHoja: [10, 2, 10, 8],
            defaultRightCellHeaderTable: {},
            defaultCenterCellHeaderTable: {},
            defaultStyle: {
                // font: 'FreePixel',
                // font: 'Inconsolata',
            },
            footerTablePrepend: [
                // this.imprimirCelda(this.dataService.qrUrlPath + datos.cTramCodigoQR, null, {
                //     fontSize: 6, color: '#162464', italics: true, alignment: 'center',
                // }),
                this.imprimirCelda('Dirección: ' + (this.datosEntidadActual.cEntDomicilioCorto ?? this.datosEntidadActual.cEntDomicilioLargo), null, {
                    fontSize: 6, color: '#162464', italics: true, alignment: 'center',
                }),
            ]
        });

        // cuerpoPdf.push(this.logotipoHeader(null, {width: 60, fontSize: 4}));
        let fuenteEntidad = 14;
        const tituloEntidad = this.datosEntidadActual.cEntNombreCorto ?? this.datosEntidadActual.cEntNombreLargo;
        if (tituloEntidad.length > 22) {
            fuenteEntidad = 10;
        }
        else if (tituloEntidad.length > 32) {
            fuenteEntidad = 9;
        }

        let fuenteAsunto = 10;
        const asunto = datos.cTramAsunto.toUpperCase();
        if (asunto.length > 80) {
            fuenteAsunto = 9;
        }
        if (asunto.length > 100) {
            fuenteAsunto = 8;
        }
        if (asunto.length > 110) {
            fuenteAsunto = 7;
        }

        cuerpoPdf.push({
            style: 'tableExample',
            table: {
                widths: [60, '*'],
                body: [
                    [
                        {
                            colSpan: 2,
                            alignment: 'center',
                            stack: [
                                this.imprimirCelda(tituloEntidad, null, {
                                    fontSize: fuenteEntidad, color: '#7a0003', bold: true,
                                }),
                                // this.imprimirCelda('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', null, {
                                //     fontSize: 14, color: '#7a1003', italics: true,
                                // }),
                            ],
                        },
                        {},
                    ],
                    [
                        {qr: this.tramiteService.qrUrlPath + datos.cTramCodigoQR, fit: 80},
                        {
                            stack: [
                                this.imprimirCelda(`Reg. ${datos.iTramNumRegistroYear}-${datos.iTramYearRegistro}`, null, {
                                    fontSize: 14, color: '#7a0003', bold: true,
                                }),
                                this.imprimirCelda(['Fecha:', datos.dtTramFechaHoraRegistro], 'fec_hora', {
                                    fontSize: 8, color: '#162464',
                                }),
                                // this.imprimirCelda('', null, {fontSize: 4}),
                                this.imprimirCelda(datos.cTramDocumentoTramite.toUpperCase(), null, {
                                    fontSize: 8,
                                    bold: true,
                                }),
                                // this.imprimirCelda('', null, {fontSize: 4}),
                                this.imprimirCelda(`Folios: ${datos.iTramFolios}`, null, {
                                    fontSize: 8, color: '#7a0003', bold: true,
                                }),
                               /* this.imprimirCelda('', null, {fontSize: 2}),
                                this.imprimirCelda(`ASUNTO:`, null, {fontSize: 4, color: '#162464', italics: true}),
                                this.imprimirCelda(datos.cTramAsunto.toUpperCase(), null, {
                                    fontSize: 5, color: '#162464', italics: true,
                                }),*/
                            ],
                        },
                    ],
                    [
                        {
                            colSpan: 2,
                            height: 50,
                            stack: [
                                this.imprimirCelda('Asunto:' /*+ asunto.length*/, null, {
                                    fontSize: (fuenteAsunto - 1), color: '#162464', alignment: 'justify',
                                }),
                                this.imprimirCelda(asunto.substring(0, 190), null, {
                                    fontSize: fuenteAsunto, color: '#162464', alignment: 'justify',
                                }),
                            ],
                        },
                        {},
                    ],
                    // [
                    //     {
                    //         colSpan: 2,
                    //         stack: [
                    //             this.imprimirCelda(this.dataService.qrUrlPath + datos.cTramCodigoQR, null, {
                    //                 fontSize: 5, color: '#162464', italics: true,
                    //             }),
                    //         ],
                    //     },
                    //     {},
                    // ],
                ],
            },
            layout: {
                defaultBorder: false,
            },
        });
        /*
        cuerpoPdf.push({
            margin: [0, 5, 0, 0],
            alignment: 'center',
            stack: [
                this.imprimirCelda(`Reg. ${datos.iTramNumRegistroYear}-${datos.iTramYearRegistro}`, null, {
                    fontSize: 7, color: '#7a0003', bold: true,
                }),
                this.imprimirCelda(['Fecha:', datos.dtTramFechaHoraRegistro], 'fec_hora', {
                    fontSize: 5, color: '#162464',
                }),
                this.imprimirCelda(datos.cTramDocumentoTramite.toUpperCase(), null, {
                    fontSize: 4,
                    bold: true,
                    italics: true,
                }),
                this.imprimirCelda(`Folios: ${datos.iTramFolios}`, null, {
                    fontSize: 4,
                    bold: true,
                    italics: true,
                }),
                this.imprimirCelda('', null, {fontSize: 4}),
                {qr: this.dataService.qrUrlPath + datos.cTramCodigoQR, fit: '60'},
                this.imprimirCelda('', null, {fontSize: 4}),
                this.imprimirCelda(this.dataService.qrUrlPath + datos.cTramCodigoQR, null, {
                    fontSize: 4, color: '#162464', italics: true,
                }),
                this.imprimirCelda('', null, {fontSize: 2}),
                this.imprimirCelda(`ASUNTO:`, null, {fontSize: 4, color: '#162464', italics: true}),
                this.imprimirCelda(datos.cTramAsunto.toUpperCase(), null, {
                    fontSize: 5, color: '#162464', italics: true,
                }),
            ],
        });
        */

        this.finalizarCreacionPdf(cuerpoPdf, 'print');

    }

    generarCargoEnviados(idxSel) {
        console.warn(idxSel);
        this.queryService.cargar(this, ['tramites_movimientos'], {cCodigoCadena: idxSel}).then((valRetorno: object) => {

            const cuerpoPdf = [];

            const datosGenerales = valRetorno['tramites_movimientos'][0];

            console.log(datosGenerales);


            this.reiniciarOpciones({
                orientacion: 'landscape',
                margenesHoja: [30, 100, 30, 40],
                tamanioFuenteExtra: 2,

                customDefTableHeader: [
                    {
                        margin: [20, 20, 20, 0],
                        table: {
                            widths: [100, '*', 100],
                            headerRows: 0,
                            body: [
                                [

                                    {},
                                    {
                                        margin: [0, 40, 0, 0],
                                        alignment: 'center',
                                        stack: [
                                            this.imprimirCelda('DOCUMENTOS ENVIADOS', null, {fontSize: 12, bold: true}),
                                            this.imprimirCelda(datosGenerales.cDespachoEmisorNombreLargo || 'algo', null, {
                                                fontSize: 13,
                                                bold: true,
                                            }),
                                        ],
                                    },
                                    this.logotipoHeader(null, {width: 80}),
                                ],
                            ],
                        },
                        layout: {
                            defaultBorder: false,
                        },
                    },
                ],
            });

            const formatoTexto = (campo) => {
                return this.imprimirCelda(campo, null, {style: 'tdSmall'});
            };

            const tablaTitulos = [
                [
                    this.imprimirCelda('\nREG', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nCORRELATIVO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('FEC.\nREGISTRO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nREFERENCIA', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nDOCUMENTO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nREMITENTE', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nFOL.', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nASUNTO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('PROVEIDO\nDOC SALIDA', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nDESTINO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('FEC\nSALIDA', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nFIRMA', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nOBS', null, {style: 'thSmall', alignment: 'center'}),
                ],
            ];
            const tablaCuerpo = [];

            valRetorno['tramites_movimientos'].filter(registro => {
                const tAdd = [];
                tablaCuerpo.push([
                    {
                        noWrap: true,
                        alignment: 'center',
                        style: 'tdSmall',
                        stack: [
                            {text: ' '},
                            {text: registro.iTramNumRegistroYear, fontSize: 15, bold: true},
                            {text: registro.iTramYearRegistro},
                        ],
                    },
                    {
                        noWrap: true,
                        alignment: 'center',
                        style: 'tdSmall',
                        stack: [
                            {text: ' '},
                            {text: registro.iTramNumRegistroDespachoYear, fontSize: 15, bold: true},
                            {text: registro.iTramYearRegistro},
                        ],
                    },
                    // formatoTexto(registro.iTramNumRegistroDespachoYear + ' -' + registro.iTramYearRegistro),
                    // this.imprimirCelda([registro.iTramNumRegistroYear, registro.iTramYearRegistro].join(' - '), null, { noWrap: true, bold: true, style: 'tdSmall' }),
                    this.imprimirCelda(registro.dtTramFechaHoraRegistro, 'fec_hora', {style: 'tdSmall'}),
                    {
                        noWrap: true,
                        fontSize: 6,
                        stack: registro.cTramDocumentoReferencia.split(', '),
                    },
                    formatoTexto(registro.cTramDocumentoTramite),
                    formatoTexto(registro.cPersEmisorNombre),
                    this.imprimirCelda(registro.iTramFolios, 'cant', {style: 'tdSmall', alignment: 'center'}),
                    formatoTexto(registro.cTramAsuntoDocumento),
                    formatoTexto(registro.cTramMovObsEnvio),
                    formatoTexto(registro.cTramReceptorNombreLargo),
                    this.imprimirCelda('', null, {height: 50}),
                    this.imprimirCelda(''),
                    this.imprimirCelda(''),
                ]);
            });

            cuerpoPdf.push(
                {
                    margin: [0, 5, 0, 0],
                    table: {
                        headerRows: 1,
                        heights: (row) => {
                            return (row > 0) ? 40 : 0;
                        },
                        widths: this.anchoCols(tablaTitulos, [6], [
                            {idx: 9, valor: 50},
                            {idx: 10, valor: 60},
                            {idx: 11, valor: 60},
                        ]),
                        body: tablaTitulos.concat(tablaCuerpo),
                    },
                },
            );


            this.finalizarCreacionPdf(cuerpoPdf);

        });


    }

    generarCargoEnviadosPorDespacho(idxSel) {
        console.warn(idxSel);
        this.queryService.cargar(this, ['tramites_movimientos'], {cCodigoCadena: idxSel, agrupadoPorDespacho: 1})
            .then((valRetorno: object) => {

                const cuerpoPdf = [];


                this.reiniciarOpciones({
                    margenesHoja: [30, 100, 30, 40],
                    tamanioFuenteExtra: 2,

                    customDefTableHeader: [
                        {
                            margin: [20, 20, 20, 0],
                            table: {
                                widths: [100, '*', 100],
                                headerRows: 0,
                                body: [
                                    [

                                        {},
                                        {
                                            margin: [0, 40, 0, 0],
                                            alignment: 'center',
                                            stack: [
                                                this.imprimirCelda('DOCUMENTOS ENVIADOS', null, {
                                                    fontSize: 12, bold: true,
                                                }),
                                            ],
                                        },
                                        this.logotipoHeader(null, {width: 80}),
                                    ],
                                ],
                            },
                            layout: {
                                defaultBorder: false,
                            },
                        },
                    ],
                });

                Object.values(valRetorno['tramites_movimientos']).forEach((txtDespachoData: any[], idx) => {
                    const contenido2 = [
                        [
                            {
                                filas: [{label: 'NRO', data: '#', tituloAlign: 'center'}],
                            },
                            {
                                filas: [
                                    {
                                        label: 'REGISTRO', tituloAlign: 'center',
                                        data: ['iTramNumRegistroYear', 'iTramYearRegistro'], caracterUnion: '-',
                                        cuerpoTipo: 'text', cuerpoAlign: 'center',
                                    },
                                ],
                            },
                            {
                                filas: [
                                    {
                                        label: 'CORRELATIVO', tituloAlign: 'center',
                                        data: ['iTramNumRegistroDespachoYear', 'iTramYearRegistro'], caracterUnion: '-',
                                        cuerpoTipo: 'text', cuerpoAlign: 'center',
                                    },
                                ],
                            },
                            {
                                filas: [
                                    {
                                        label: 'FEC. REGISTRO', tituloAlign: 'center', data: 'dtTramFechaHoraRegistro',
                                        cuerpoTipo: 'fec_hora',
                                    },
                                ],
                            },
                            {
                                filas: [
                                    {
                                        label: 'FOL.', tituloAlign: 'center', data: 'iTramFolios', cuerpoTipo: 'cant',
                                    },
                                ],
                            },
                            {
                                filas: [{label: 'EMISOR', tituloAlign: 'center', data: 'cPersEmisorNombre'}],
                            },
                            {
                                filas: [{label: 'ASUNTO', tituloAlign: 'center', data: 'cTramAsuntoDocumento'}],
                            },
                        ],
                    ];

                    const datTablaGen = this.tablaSegunConfig(contenido2, txtDespachoData, [5], [], false);


                    const formatoTexto = (campo) => {
                        return this.imprimirCelda(campo, null, {style: 'tdSmall'});
                    };

                    const tablaTitulos = [
                        [
                            this.imprimirCelda(txtDespachoData[0].cDespachoEmisorNombreLargo + ' - ' + txtDespachoData[0].cTramReceptorNombreLargo, null, {
                                border: [
                                    false,
                                    false,
                                    false,
                                    true,
                                ], colSpan: 7, fontSize: 12, bold: true, alignment: 'center',
                            }),
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            this.imprimirCelda('REGISTRO', null, {style: 'thSmall', alignment: 'center'}),
                            this.imprimirCelda('CORRELATO', null, {style: 'thSmall', alignment: 'center'}),
                            this.imprimirCelda('FECHA', null, {style: 'thSmall', alignment: 'center'}),
                            this.imprimirCelda('DOCUMENTO', null, {style: 'thSmall', alignment: 'center'}),
                            this.imprimirCelda('FOL.', null, {style: 'thSmall', alignment: 'center'}),
                            this.imprimirCelda('EMISOR', null, {style: 'thSmall', alignment: 'center'}),
                            this.imprimirCelda('ASUNTO', null, {style: 'thSmall', alignment: 'center'}),
                        ],
                    ];
                    const tablaCuerpo = [];

                    txtDespachoData.filter((registro, idxReg) => {
                        tablaCuerpo.push([
                            {
                                noWrap: true,
                                alignment: 'center',
                                style: 'tdSmall',
                                stack: [
                                    // { text: ' ' },
                                    {text: registro.iTramNumRegistroYear, fontSize: 12, bold: true},
                                    {text: registro.iTramYearRegistro},
                                ],
                            },
                            {
                                noWrap: true,
                                alignment: 'center',
                                style: 'tdSmall',
                                stack: [
                                    // { text: ' ' },
                                    {text: registro.iTramNumRegistroDespachoYear, fontSize: 12, bold: true},
                                    {text: registro.iTramYearRegistro},
                                ],
                            },
                            this.imprimirCelda(registro.dtTramFechaHoraRegistro, 'fec_hora', {style: 'tdSmall'}),
                            formatoTexto(registro.cTramDocumentoTramite),
                            this.imprimirCelda(registro.iTramFolios, 'number', {style: 'tdSmall'}),
                            formatoTexto(registro.cPersEmisorNombre),
                            formatoTexto(registro.cTramAsuntoDocumento),
                        ]);
                    });

                    cuerpoPdf.push(
                        {
                            margin: [0, 5, 0, 0],
                            table: {
                                headerRows: 2,
                                heights: (row) => {
                                    return (row > 1) ? 40 : 0;
                                },
                                widths: this.anchoCols(tablaTitulos, [6]),
                                body: tablaTitulos.concat(tablaCuerpo),
                            },
                            pageBreak: (idx < (Object.keys(valRetorno['tramites_movimientos']).length - 1) ? 'after' : undefined),
                        },
                    );


                    // this.finalizarCreacionPdf(cuerpoPdf);

                });
                // return;

                this.finalizarCreacionPdf(cuerpoPdf);

            });


    }

    reporteSegunEstado(filtro, iEstadoTramite, depentenciaTitulo = '<DEP_TIT>', preTitulo = '<PRE_TITULO>') {
        console.warn(filtro);

        filtro = {
            ...{
                iEstadoTramite,
            },
            ...filtro,
        };
        this.queryService.cargar(this, ['tramites_enprocesos'], filtro).then((valRetorno: object) => {

            const cuerpoPdf = [];


            this.reiniciarOpciones({
                orientacion: 'landscape',
                margenesHoja: [30, 100, 30, 40],
                tamanioFuenteExtra: 2,

                customDefTableHeader: [
                    {
                        margin: [20, 20, 20, 0],
                        table: {
                            widths: [100, '*', 100],
                            headerRows: 0,
                            body: [
                                [

                                    {},
                                    {
                                        margin: [0, 40, 0, 0],
                                        alignment: 'center',
                                        stack: [
                                            this.imprimirCelda(preTitulo, null, {fontSize: 12, bold: true}),
                                            this.imprimirCelda(depentenciaTitulo, null, {
                                                fontSize: 13,
                                                bold: true,
                                            }),
                                        ],
                                    },
                                    this.logotipoHeader(null, {width: 80}),
                                ],
                            ],
                        },
                        layout: {
                            defaultBorder: false,
                        },
                    },
                ],
            });

            const formatoTexto = (campo) => {
                return this.imprimirCelda(campo, null, {style: 'tdSmall'});
            };

            const tablaTitulos = [
                [
                    this.imprimirCelda('\nREG', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('FEC.\nREGISTRO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nEMISOR', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nREFERENCIA', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nDOCUMENTO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nFOL', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nASUNTO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nRECEPTOR', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('ATENDIDO\nCON', null, {style: 'thSmall', alignment: 'center'}),
                ],
            ];
            const tablaCuerpo = [];

            valRetorno['tramites_enprocesos'].filter(registro => {
                const dataEstado = getEstadoDocumento(registro.cTramMovEstadoTramite);
                // const estadoSegundario = this.estadoSecundario.find(item2 => item2.id == dataEstado.idSecundario);
                tablaCuerpo.push([
                    {
                        noWrap: true,
                        alignment: 'center',
                        style: 'tdSmall',
                        stack: [
                            {text: registro.iTramNumRegistroYear, fontSize: 15, bold: true},
                            {text: registro.iTramYearRegistro},
                        ],
                    },
                    this.imprimirCelda(registro.dtTramFechaHoraRegistro, 'fec_hora', {style: 'tdSmall'}),
                    formatoTexto(registro.cDespachoEmisorEnvioNombreLargo),
                    {
                        noWrap: true,
                        fontSize: 6,
                        stack: registro.cTramDocumentoReferencia.split(', '),
                    },
                    formatoTexto(registro.cTramDocumentoTramite),
                    this.imprimirCelda(registro.iTramFolios, 'cant', {style: 'tdSmall', alignment: 'center'}),
                    formatoTexto(registro.cTramAsuntoDocumento),
                    formatoTexto(registro.cDespachoReceptorNombreLargo),
                    formatoTexto(dataEstado.doc),
                ]);
            });

            cuerpoPdf.push(
                {
                    margin: [0, 5, 0, 0],
                    table: {
                        headerRows: 1,
                        // heights: (row) => {
                        // 	return (row > 0) ? 40 : 0;
                        // },
                        widths: this.anchoCols(tablaTitulos, [5]),
                        body: tablaTitulos.concat(tablaCuerpo),
                    },
                },
            );


            this.finalizarCreacionPdf(cuerpoPdf);

        });


    }

    reporteEmitidos(filtro, depentenciaTitulo = '<DEP_TIT>', preTitulo = '<PRE_TITULO>', dataExtra: any = {}) {
        console.warn(filtro);

        filtro = {
            ...filtro,
            ...dataExtra,
        };


        this.reiniciarOpciones({
            orientacion: 'landscape',
            margenesHoja: [30, 100, 30, 40],
            tamanioFuenteExtra: 2,

            customDefTableHeader: [
                {
                    margin: [20, 20, 20, 0],
                    table: {
                        widths: [100, '*', 100],
                        headerRows: 0,
                        body: [
                            [

                                {},
                                {
                                    margin: [0, 40, 0, 0],
                                    alignment: 'center',
                                    stack: [
                                        this.imprimirCelda(preTitulo, null, {fontSize: 12, bold: true}),
                                        this.imprimirCelda(depentenciaTitulo, null, {
                                            fontSize: 13,
                                            bold: true,
                                        }),
                                    ],
                                },
                                this.logotipoHeader(null, {width: 80}),
                            ],
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    },
                },
            ],
        });

        const camposPrincipal: ColumnaTabla<any>[] = [
            {
                label: '\nREGISTRO', property: 'iTramNumRegistroYear', type: 'text', transformarDirecto(row): any {
                    return {
                        noWrap: true,
                        alignment: 'center',
                        style: 'tdSmall',
                        stack: [
                            {text: row.iTramNumRegistroYear, fontSize: 10, bold: true},
                            {text: row.iTramYearRegistro},
                        ],
                    };
                },
            },
            {
                label: 'FEC.\nREGISTRO', property: 'dtTramFechaHoraRegistro', type: 'date', format: 'dd/MM/yyyy HH:mm',
            },
            {label: '\nEMISOR', property: 'cDespachoEmisorEnvioNombreLargo', type: 'text'},
            {
                label: '\nREFERENCIA', property: 'cTramDocumentoReferencia', type: 'text', transformarDirecto(row): any {
                    return {
                        noWrap: true,
                        style: 'tdSmall',
                        stack: row.cTramDocumentoReferencia.split(', '),
                    };
                },
            },
            {label: '\nDOCUMENTO', property: 'cTramDocumentoTramite', type: 'text'},
            {label: '\nFOL', property: 'iTramFolios', type: 'number'},
            {label: '\nASUNTO', property: 'cTramAsuntoDocumento', type: 'text'},
            {label: '\nRECEPTOR', property: 'cDespachoReceptorNombreLargo', type: 'text'},
            {
                label: 'ATENDIDO\nCON', property: 'doc', type: 'text', transformarDirecto(row): any {
                    const dataEstado = getEstadoDocumento(row.cTramMovEstadoTramite);
                    return dataEstado.doc;
                },
            },
        ];

        const cuerpoPdf = [];

        if (filtro.dataSeleccionado) {
            console.log(filtro.dataSeleccionado);
            cuerpoPdf.push(this.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: filtro.dataSeleccionado,
                orientacion: 'landscape',
                // numeracion: true,
                idxResto: [5],
                margin: [0, 5, 0, 0],
            }));
            this.finalizarCreacionPdf(cuerpoPdf);
            return;
        }

        this.queryService.cargar(this, ['tramites'], filtro).then((valRetorno: object) => {
            cuerpoPdf.push(this.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: valRetorno['tramites'],
                orientacion: 'landscape',
                // numeracion: true,
                idxResto: [5],
                margin: [0, 5, 0, 0],
            }));
            /*
            const formatoTexto = (campo) => {
                return this.imprimirCelda(campo, null, {style: 'tdSmall'});
            };

            const tablaTitulos = [
                [
                    this.imprimirCelda('\nREG', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('FEC.\nREGISTRO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nEMISOR', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nREFERENCIA', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nDOCUMENTO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nFOL', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nASUNTO', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('\nRECEPTOR', null, {style: 'thSmall', alignment: 'center'}),
                    this.imprimirCelda('ATENDIDO\nCON', null, {style: 'thSmall', alignment: 'center'}),
                ],
            ];
            const tablaCuerpo = [];

            valRetorno['tramites'].filter(registro => {
                const dataEstado = getEstadoDocumento(registro.cTramMovEstadoTramite);
                tablaCuerpo.push([
                    {
                        noWrap: true,
                        alignment: 'center',
                        style: 'tdSmall',
                        stack: [
                            {text: registro.iTramNumRegistroYear, fontSize: 15, bold: true},
                            {text: registro.iTramYearRegistro},
                        ],
                    },
                    this.imprimirCelda(registro.dtTramFechaHoraRegistro, 'fec_hora', {style: 'tdSmall'}),
                    formatoTexto(registro.cDespachoEmisorEnvioNombreLargo),
                    {
                        noWrap: true,
                        fontSize: 6,
                        stack: registro.cTramDocumentoReferencia.split(', '),
                    },
                    formatoTexto(registro.cTramDocumentoTramite),
                    this.imprimirCelda(registro.iTramFolios, 'cant', {style: 'tdSmall', alignment: 'center'}),
                    formatoTexto(registro.cTramAsuntoDocumento),
                    formatoTexto(registro.cDespachoReceptorNombreLargo),
                    formatoTexto(dataEstado.doc),
                ]);
            });

            cuerpoPdf.push(
                {
                    margin: [0, 5, 0, 0],
                    table: {
                        headerRows: 1,
                        widths: this.anchoCols(tablaTitulos, [5]),
                        body: tablaTitulos.concat(tablaCuerpo),
                    },
                },
            );
*/

            this.finalizarCreacionPdf(cuerpoPdf);

        });


    }

    seguimiento(dataReferencias) {

        const cuerpoPdf = [];

        const tPrincipal = dataEnLista(dataReferencias, 'bTabTraRefPrincipal', true);


        this.reiniciarOpciones({
            orientacion: 'landscape',
            margenesHoja: [30, 170, 30, 40],
            tamanioFuenteExtra: 2,

            customDefTableHeader: [
                {
                    margin: [20, 20, 20, 0],
                    table: {
                        widths: [100, '*', 100],
                        headerRows: 0,
                        body: [
                            [

                                {},
                                {
                                    margin: [0, 40, 0, 0],
                                    alignment: 'center',
                                    stack: [
                                        this.imprimirCelda(this.datosEntidadActual?.cEntNombreLargo, null, {
                                            fontSize: 11, bold: true,
                                        }),
                                        this.imprimirCelda('SEGUIMIENTO DE TRÁMITE Nº ' + tPrincipal['iTramNumRegistroYear'] + ' - ' + tPrincipal['iTramYearRegistro'], null, {
                                            fontSize: 12,
                                            bold: true,
                                        }),
                                    ],
                                },
                                this.logotipoHeader(null, {width: 80}),
                            ],
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    },
                },
                {
                    margin: [50, 5, 50, 0],
                    table: {
                        widths: [100, '*', 10, 100, '*'],
                        headerRows: 0,
                        body: [
                            [
                                this.imprimirCelda('DOCUMENTO', null, {style: 'thSmall', bold: true}),
                                this.imprimirCelda(tPrincipal['cTramDocumentoTramite'], null, {
                                    style: 'tdSmall', bold: true,
                                }),
                                this.imprimirCelda('', null, {
                                    rowSpan: 3, border: [true, false, true, false], style: 'tdSmall', bold: true,
                                }),
                                this.imprimirCelda('REGISTRO', null, {style: 'thSmall', bold: true}),
                                this.imprimirCelda(tPrincipal['iTramNumRegistroYear'] + ' - ' + tPrincipal['iTramYearRegistro'], null, {
                                    style: 'tdSmall', bold: true,
                                }),
                            ],
                            [
                                this.imprimirCelda('REMITENTE', null, {style: 'thSmall', bold: true}),
                                this.imprimirCelda((tPrincipal['iTipoTramId'] == 1 ? tPrincipal['cPersFirmaEmisorNombre'] : (tPrincipal['iTipoTramId'] == 2 ? tPrincipal['cPersEmisorNombre'] : '')), null, {
                                    style: 'tdSmall', bold: true,
                                }),
                                {},
                                this.imprimirCelda('DESPACHO', null, {style: 'thSmall', bold: true}),
                                this.imprimirCelda(tPrincipal['cDespachoEmisorNombreLargo'] + (tPrincipal['iTipoTramId'] == 2 ? tPrincipal['cPersEmisorNombre'] : ''), null, {
                                    style: 'tdSmall', bold: true,
                                }),
                            ],
                            [
                                this.imprimirCelda('ASUNTO', null, {style: 'thSmall', bold: true}),
                                this.imprimirCelda(tPrincipal['cTramAsuntoDocumento'], null, {
                                    style: 'tdSmall', bold: true,
                                }),
                                {},
                                this.imprimirCelda('F. CREACION', null, {style: 'thSmall', bold: true}),
                                this.imprimirCelda(tPrincipal['dtTabTraRefTramiteBuscado'], 'fec_hora', {
                                    style: 'tdSmall', bold: true,
                                }),
                            ],
                        ],
                    },
                    layout: {
                        defaultBorder: true,
                    },
                },
            ],
        });

        const tablaTitulos = [
            [
                this.imprimirCelda('REG', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('TIPO', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('ESTADO', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('FECHA', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('MOVIMIENTOS', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('DOCUMENTO', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('ASUNTO', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('TRANSCURRIDO', null, {style: 'thSmall', alignment: 'center'}),
                this.imprimirCelda('TRANSCURRIDO\nORIGEN', null, {style: 'thSmall', alignment: 'center'}),
            ],
        ];

        const formatoTexto = (campo) => {
            return this.imprimirCelda(campo, null, {style: 'tdSmall'});
        };

        const tablaCuerpo = [];

        dataReferencias.filter(registro => {
            const dataEstado = getEstadoDocumento(registro.cTramMovEstadoTramite);
            // const estadoSegundario = this.estadoSecundario.find(item2 => item2.id == dataEstado.idSecundario);
            const fechas = [];
            const firmas = [];
            if (!registro.iTramMovId && registro.dtTramFechaHoraRegistro) {
                fechas.push(this.imprimirCelda('Crea.' + registro.dtTramFechaHoraRegistro, 'fec_hora'));
                firmas.push(this.imprimirCelda('Creado por' + registro.cDespachoEmisorNombreLargo));
            }
            if (registro.dtTramMovFechaHoraEnvio) {
                fechas.push(this.imprimirCelda('Envi.' + registro.dtTramMovFechaHoraEnvio, 'fec_hora'));
                firmas.push(this.imprimirCelda('Emisor' + registro.cDespachoEmisorEnvioNombreLargo));
            }
            if (registro.dtTramMovFechaHoraRecibido) {
                fechas.push(this.imprimirCelda('Envi.' + registro.dtTramMovFechaHoraEnvio, 'fec_hora'));
                firmas.push(this.imprimirCelda('Destino' + registro.cDespachoReceptorNombreLargo));
            }

            let tTranscurrido = {};
            if (registro.dtTramMovFechaHoraEnvio) {
                if (registro.dtTramMovFechaHoraRecibido) {
                    tTranscurrido = this.imprimirCelda(dateDiffString(registro.dtTramMovFechaHoraRecibido, registro.dtTramMovFechaHoraEnvio), null, {style: 'tdSmall'});
                }
                if (!registro.dtTramMovFechaHoraRecibido) {
                    tTranscurrido = this.imprimirCelda(dateDiffString(new Date(), registro.dtTramMovFechaHoraEnvio), null, {style: 'tdSmall'});
                }
            }

            let tTranscurridoOrigen = {};

            if (dataReferencias[0].dtTramMovFechaHoraEnvio) {
                if (registro.dtTramMovFechaHoraRecibido) {
                    tTranscurridoOrigen = this.imprimirCelda(new DateDiffStringPipe().transform(registro.dtTramMovFechaHoraRecibido, dataReferencias[0].dtTramMovFechaHoraEnvio), null, {style: 'tdSmall'});
                }
                else {
                    tTranscurridoOrigen = this.imprimirCelda(new DateDiffStringPipe().transform(new Date(), dataReferencias[0].dtTramMovFechaHoraEnvio), null, {style: 'tdSmall'});
                }
            }


            tablaCuerpo.push([
                {
                    noWrap: true,
                    alignment: 'center',
                    style: 'tdSmall',
                    stack: [
                        {text: registro.iTramNumRegistroYear, fontSize: 15, bold: true},
                        {text: registro.iTramYearRegistro},
                    ],
                },
                formatoTexto(registro.iTipoTramId == 1 ? 'INTERNO' : (registro.iTipoTramId == 2 ? 'EXTERNO' : '')),
                formatoTexto(dataEstado.label),
                {
                    noWrap: true,
                    alignment: 'center',
                    style: 'tdSmall',
                    stack: fechas,
                },
                {
                    alignment: 'center',
                    style: 'tdSmall',
                    stack: firmas,
                },
                formatoTexto(registro.cTramDocumentoTramite),
                formatoTexto(registro.cTramAsuntoDocumento),
                tTranscurrido,
                tTranscurridoOrigen,
            ]);
        });

        cuerpoPdf.push(
            {
                margin: [0, 5, 0, 0],
                table: {
                    headerRows: 1,
                    // heights: (row) => {
                    // 	return (row > 0) ? 40 : 0;
                    // },
                    widths: this.anchoCols(tablaTitulos, [5]),
                    body: tablaTitulos.concat(tablaCuerpo),
                },
            },
        );


        this.finalizarCreacionPdf(cuerpoPdf);
    }
}
