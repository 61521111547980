import {Component, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {stagger40ms} from '../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../@vex/animations/fade-in-up.animation';

@UntilDestroy()
@Component({
	selector: 'jvsoft-tramite-layout',
	templateUrl: './tramite-layout.component.html',
	styleUrls: ['./tramite-layout.component.scss'],
	animations: [
		fadeInUp400ms,
		stagger40ms
	],
})
export class TramiteLayoutComponent implements OnInit {

	constructor(
	) {
	}

	ngOnInit() {
	}
}
