import {groupBy, nestGroupsBy, ordenarArray, ordenarPorPropiedad, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {DataEnviarPdfPresupuesto, PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';

export function CVSMPFteFtoSecFuncA4(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.CVSMP_FteFto_Sec_Func'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.CVSMP_FteFto_Sec_Func'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_Func');
	 console.log (resultadosGenerales);
		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('4REPORTE DE CERTIFICACIÓN MENSUAL VS MARCO PRESUPUESTAL', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Del mes de Enero a Diciembre del ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 8, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'SEC', property: 'Sec_func', type: 'text'},
			{label: 'DESCRIPCIÓN', property: 'cNombre_Sec_func', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'ENERO', property: 'nCertificadoEnero', type: 'money'},
			{label: 'FEBRERO', property: 'nCertificadoFebrero', type: 'money'},
			{label: 'MARZO', property: 'nCertificadoMarzo', type: 'money'},
			{label: 'ABRIL.', property: 'nCertificadoAbril', type: 'money'},
			{label: 'MAYO', property: 'nCertificadoMayo', type: 'money'},
			{label: 'JUNIO', property: 'nCertificadoJunio', type: 'money'},
			{label: 'JULIO', property: 'nCertificadoJulio', type: 'money'},
			{label: 'AGOSTO', property: 'nCertificadoAgosto', type: 'money'},
			{label: 'SEPTIEMBRE', property: 'nCertificadoSeptiembre', type: 'money'},
			{label: 'OCTUBRE', property: 'nCertificadoOctubre', type: 'money'},
			{label: 'NOVIEMBRE.', property: 'nCertificadoNoviembre', type: 'money'},
			{label: 'DICIEMBRE', property: 'nCertificadoDiciembre', type: 'money'},
			{label: 'CERTIFICADO.', property: 'nCertificado', type: 'money'},
			{label: '%.', property: '', type: 'number', transformar(row): any {
					return (row.nCertificado * 100) / row.nPim;
				}},
			{label: 'SALDO.', property: '', type: 'money', transformar(row): any {
					return row.nPim - row.nCertificado;
				}},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
			numeracion: true,
			idxResto: [5],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificadoEnero',
			'nCertificadoFebrero',
			'nCertificadoMarzo',
			'nCertificadoAbril',
			'nCertificadoMayo',
			'nCertificadoJunio',
			'nCertificadoJulio',
			'nCertificadoAgosto',
			'nCertificadoSeptiembre',
			'nCertificadoOctubre',
			'nCertificadoNoviembre',
			'nCertificadoDiciembre',
			'nCertificado',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
						generarColSpan(imprimirCelda('TOTALES S/.', null, {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}), 3).concat(
					[
						imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoEnero'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoFebrero'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoMarzo'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoAbril'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoMayo'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoJunio'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoJulio'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoAgosto'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoSeptiembre'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoOctubre'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoNoviembre'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificadoDiciembre'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda('', null, {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nPim'] - sumaObj['nCertificado'], 'money', {bold: true, style: 'thSmall'}),
					],
						),
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function ECMVSMPSecFuncA4(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.ECMVSMP_SecFunc'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECMVSMP_SecFunc'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_Func');
		// console.log (dataEnviar);
		// console.log (resultadosGenerales);
		objThis.reiniciarOpciones({
			margenesHoja: [40, 120, 40, 40],
			orientacion: 'landscape',
            tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda(resultadosGenerales[0]['Nombre_Fase'], null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL MENSUAL VS MARCO PRESUPUESTAL POR SEC. FUNC.', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Del mes de Enero a Diciembre del ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 8, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'SEC', property: 'Sec_func', type: 'text'},
			{label: 'DESCRIPCIÓN', property: 'cNombre_Sec_func', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'ENERO', property: 'nEjecutadoEnero', type: 'money'},
			{label: 'FEBRERO', property: 'nEjecutadoFebrero', type: 'money'},
			{label: 'MARZO', property: 'nEjecutadoMarzo', type: 'money'},
			{label: 'ABRIL.', property: 'nEjecutadoAbril', type: 'money'},
			{label: 'MAYO', property: 'nEjecutadoMayo', type: 'money'},
			{label: 'JUNIO', property: 'nEjecutadoJunio', type: 'money'},
			{label: 'JULIO', property: 'nEjecutadoJulio', type: 'money'},
			{label: 'AGOSTO', property: 'nEjecutadoAgosto', type: 'money'},
			{label: 'SEPTIEMBRE', property: 'nEjecutadoSeptiembre', type: 'money'},
			{label: 'OCTUBRE', property: 'nEjecutadoOctubre', type: 'money'},
			{label: 'NOVIEMBRE.', property: 'nEjecutadoNoviembre', type: 'money'},
			{label: 'DICIEMBRE', property: 'nEjecutadoDiciembre', type: 'money'},
			{label: 'EJECUTADO.', property: '', type: 'money', transformar(row): any {
					const camposSuma = [
						'nEjecutadoEnero',
						'nEjecutadoFebrero',
						'nEjecutadoMarzo',
						'nEjecutadoAbril',
						'nEjecutadoMayo',
						'nEjecutadoJunio',
						'nEjecutadoJulio',
						'nEjecutadoAgosto',
						'nEjecutadoSeptiembre',
						'nEjecutadoOctubre',
						'nEjecutadoNoviembre',
						'nEjecutadoDiciembre',
					];
					let suma = 0;
					camposSuma.forEach(campo => {
						suma += (row[campo] * 1);
					});
					return suma;
				}},
			{label: '%.', property: '', type: 'money', transformar(row): any {
					const camposSuma = [
						'nEjecutadoEnero',
						'nEjecutadoFebrero',
						'nEjecutadoMarzo',
						'nEjecutadoAbril',
						'nEjecutadoMayo',
						'nEjecutadoJunio',
						'nEjecutadoJulio',
						'nEjecutadoAgosto',
						'nEjecutadoSeptiembre',
						'nEjecutadoOctubre',
						'nEjecutadoNoviembre',
						'nEjecutadoDiciembre',
					];
					let suma = 0;
					camposSuma.forEach(campo => {
						suma += (row[campo] * 1);
					});
					return (suma * 100) / row.nPim;
				}},
			{label: 'SALDO.', property: '', type: 'money', transformar(row): any {
					const camposSuma = [
						'nEjecutadoEnero',
						'nEjecutadoFebrero',
						'nEjecutadoMarzo',
						'nEjecutadoAbril',
						'nEjecutadoMayo',
						'nEjecutadoJunio',
						'nEjecutadoJulio',
						'nEjecutadoAgosto',
						'nEjecutadoSeptiembre',
						'nEjecutadoOctubre',
						'nEjecutadoNoviembre',
						'nEjecutadoDiciembre',
					];
					let suma = 0;
					camposSuma.forEach(campo => {
						suma += (row[campo] * 1);
					});
					return row.nPim - suma;
				}},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
			numeracion: true,
			idxResto: [5],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nEjecutadoEnero',
			'nEjecutadoFebrero',
			'nEjecutadoMarzo',
			'nEjecutadoAbril',
			'nEjecutadoMayo',
			'nEjecutadoJunio',
			'nEjecutadoJulio',
			'nEjecutadoAgosto',
			'nEjecutadoSeptiembre',
			'nEjecutadoOctubre',
			'nEjecutadoNoviembre',
			'nEjecutadoDiciembre',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([

					generarColSpan(imprimirCelda('TOTALES S/.', null, {
						bold: true,
						style: 'thSmall',
						alignment: 'right'
					}), 3).concat(
						[
							imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoEnero'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoFebrero'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoMarzo'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoAbril'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoMayo'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoJunio'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoJulio'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoAgosto'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoSeptiembre'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoOctubre'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoNoviembre'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj['nEjecutadoDiciembre'], 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda((sumaObj.nEjecutadoEnero + sumaObj.nEjecutadoFebrero + sumaObj.nEjecutadoMarzo + sumaObj.nEjecutadoAbril + sumaObj.nEjecutadoMayo
								+ sumaObj.nEjecutadoJunio + sumaObj.nEjecutadoJulio + sumaObj.nEjecutadoAgosto + sumaObj.nEjecutadoSeptiembre + sumaObj.nEjecutadoOctubre
								+ sumaObj.nEjecutadoNoviembre + sumaObj.nEjecutadoDiciembre
							), 'money', {bold: true, style: 'thSmall'}),
							imprimirCelda(((sumaObj.nEjecutadoEnero + sumaObj.nEjecutadoFebrero + sumaObj.nEjecutadoMarzo + sumaObj.nEjecutadoAbril + sumaObj.nEjecutadoMayo
								+ sumaObj.nEjecutadoJunio + sumaObj.nEjecutadoJulio + sumaObj.nEjecutadoAgosto + sumaObj.nEjecutadoSeptiembre + sumaObj.nEjecutadoOctubre
								+ sumaObj.nEjecutadoNoviembre + sumaObj.nEjecutadoDiciembre
							) * 100) / sumaObj.nPim, 'mon', {bold: true, style: 'thSmall'}),
							imprimirCelda(sumaObj.nPim - (sumaObj.nEjecutadoEnero + sumaObj.nEjecutadoFebrero + sumaObj.nEjecutadoMarzo + sumaObj.nEjecutadoAbril + sumaObj.nEjecutadoMayo
								+ sumaObj.nEjecutadoJunio + sumaObj.nEjecutadoJulio + sumaObj.nEjecutadoAgosto + sumaObj.nEjecutadoSeptiembre + sumaObj.nEjecutadoOctubre
								+ sumaObj.nEjecutadoNoviembre + sumaObj.nEjecutadoDiciembre
							), 'money', {bold: true, style: 'thSmall'}),

						],
					),
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function ECMVSMPClasificadorA4(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.ECMVSMP_Clasificador'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECMVSMP_Clasificador'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Fuente_Financ');
		// console.log (dataEnviar);
		// console.log (resultadosGenerales);
		objThis.reiniciarOpciones({
			margenesHoja: [40, 120, 40, 40],
			orientacion: 'landscape',
			tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda(resultadosGenerales[0]['Nombre_Fase'], null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL MENSUAL VS MARCO PRESUPUESTAL POR SEC. FUNC. Y CLASIFICADOR', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Del mes de Enero a Diciembre del ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 11, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'F.F.', property: 'Fuente_Financ', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(row['Fuente_Financ'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{label: 'FUN', property: 'Funcion', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(row['Funcion'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{label: 'META', property: 'Sec_func', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(row['Sec_func'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{label: 'CLASIF.', property: 'Clasificador', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(row['Clasificador'], null, {bold: true, alignment: 'center', style: 'tdSmall', noWrap: true});
				}},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: 'ENERO.', property: 'nEjecutadoEnero', type: 'money'},
			{label: 'FEBRERO.', property: 'nEjecutadoFebrero', type: 'money'},
			{label: 'MARZO.', property: 'nEjecutadoMarzo', type: 'money'},
			{label: 'ABRIL.', property: 'nEjecutadoAbril', type: 'money'},
			{label: 'MAYO', property: 'nEjecutadoMayo', type: 'money'},
			{label: 'JUNIO', property: 'nEjecutadoJunio', type: 'money'},
			{label: 'JULIO', property: 'nEjecutadoJulio', type: 'money'},
			{label: 'AGOSTO', property: 'nEjecutadoAgosto', type: 'money'},
			{label: 'SEPTIEMBRE', property: 'nEjecutadoSeptiembre', type: 'money'},
			{label: 'OCTUBRE', property: 'nEjecutadoOctubre', type: 'money'},
			{label: 'NOVIEMBRE.', property: 'nEjecutadoNoviembre', type: 'money'},
			{label: 'DICIEMBRE', property: 'nEjecutadoDiciembre', type: 'money'},
			{label: 'EJECUTADO.', property: '', type: 'money', transformar(row): any {
				const camposSuma = [
					'nEjecutadoEnero',
					'nEjecutadoFebrero',
					'nEjecutadoMarzo',
					'nEjecutadoAbril',
					'nEjecutadoMayo',
					'nEjecutadoJunio',
					'nEjecutadoJulio',
					'nEjecutadoAgosto',
					'nEjecutadoSeptiembre',
					'nEjecutadoOctubre',
					'nEjecutadoNoviembre',
					'nEjecutadoDiciembre',
				];
				let suma = 0;
				camposSuma.forEach(campo => {
					suma += (row[campo] * 1);
				});
				return suma;
				}},
			// {Sp_Siaf_SEL_ECMVSMP_ClasificadorXcCodigoCadena;1.nPim}-{@xnEjecutado}
			{label: 'SALDO.', property: '', type: 'money', transformar(row): any {
					const camposSuma = [
						'nEjecutadoEnero',
						'nEjecutadoFebrero',
						'nEjecutadoMarzo',
						'nEjecutadoAbril',
						'nEjecutadoMayo',
						'nEjecutadoJunio',
						'nEjecutadoJulio',
						'nEjecutadoAgosto',
						'nEjecutadoSeptiembre',
						'nEjecutadoOctubre',
						'nEjecutadoNoviembre',
						'nEjecutadoDiciembre',
					];
					let suma = 0;
					camposSuma.forEach(campo => {
						suma += (row[campo] * 1);
					});
					return row.nPim - suma;
				}},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Fuente_Financ'),
			// numeracion: true,
			idxResto: [5],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nEjecutadoEnero',
			'nEjecutadoFebrero',
			'nEjecutadoMarzo',
			'nEjecutadoAbril',
			'nEjecutadoMayo',
			'nEjecutadoJunio',
			'nEjecutadoJulio',
			'nEjecutadoAgosto',
			'nEjecutadoSeptiembre',
			'nEjecutadoOctubre',
			'nEjecutadoNoviembre',
			'nEjecutadoDiciembre',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3]),
				widths: objThis.anchoCols(tablaGenerada['cuerpo'], [2,3], [{idx: 0, valor: 10}, {idx: 1, valor: 15}, {idx: 4, valor: 50}, {idx: 5, valor: 50}, ]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([

					generarColSpan(imprimirCelda('TOTALES S/.', null, {
						bold: true,
						style: 'thSmall',
						alignment: 'right'
					}), 4).concat(
						[
							imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nCertificado'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoEnero'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoFebrero'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoMarzo'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoAbril'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoMayo'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoJunio'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoJulio'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoAgosto'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoSeptiembre'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoOctubre'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoNoviembre'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj['nEjecutadoDiciembre'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda((sumaObj.nEjecutadoEnero + sumaObj.nEjecutadoFebrero + sumaObj.nEjecutadoMarzo + sumaObj.nEjecutadoAbril + sumaObj.nEjecutadoMayo
								+ sumaObj.nEjecutadoJunio + sumaObj.nEjecutadoJulio + sumaObj.nEjecutadoAgosto + sumaObj.nEjecutadoSeptiembre + sumaObj.nEjecutadoOctubre
								+ sumaObj.nEjecutadoNoviembre + sumaObj.nEjecutadoDiciembre
							), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(sumaObj.nPim - (sumaObj.nEjecutadoEnero + sumaObj.nEjecutadoFebrero + sumaObj.nEjecutadoMarzo + sumaObj.nEjecutadoAbril + sumaObj.nEjecutadoMayo
								+ sumaObj.nEjecutadoJunio + sumaObj.nEjecutadoJulio + sumaObj.nEjecutadoAgosto + sumaObj.nEjecutadoSeptiembre + sumaObj.nEjecutadoOctubre
								+ sumaObj.nEjecutadoNoviembre + sumaObj.nEjecutadoDiciembre)
								, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						],
					),
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function CVSMPClasificadorA4(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.CertificadoVsMarcoPresupuestal'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.CertificadoVsMarcoPresupuestal'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Act_Proy');
		// console.log(resultadosGenerales);
		// console.log(dataEnviar);
		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			tamanioHoja: 'A3',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('CERTIFICADO VS MARCO PRESUPUESTAL - ' + resultadosGenerales[0]['Ano_eje'], null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Del mes de Enero a Diciembre', null, {fontSize: 7, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Sec_func',
			'Fuente_Financ',
			'cGeneCodigo',
		]);
		console.log(groups);
		// return false;

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'Sec Func', property: 'Clasificador', type: 'text'},
			{label: 'Func/Prog Func/SubProg', property: 'Nombre_Clasificador', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'Enero', property: 'nCertificadoEnero', type: 'money'},
			{label: 'Febrero', property: 'nCertificadoFebrero', type: 'money'},
			{label: 'Marzo', property: 'nCertificadoMarzo', type: 'money'},
			{label: 'Abril', property: 'nCertificadoAbril', type: 'money'},
			{label: 'Mayo', property: 'nCertificadoMayo', type: 'money'},
			{label: 'Junio', property: 'nCertificadoJunio', type: 'money'},
			{label: 'Julio', property: 'nCertificadoJulio', type: 'money'},
			{label: 'Agosto', property: 'nCertificadoAgosto', type: 'money'},
			{label: 'Septiembre', property: 'nCertificadoSeptiembre', type: 'money'},
			{label: 'Octubre', property: 'nCertificadoOctubre', type: 'money'},
			{label: 'Noviembre', property: 'nCertificadoNoviembre', type: 'money'},
			{label: 'Diciembre', property: 'nCertificadoDiciembre', type: 'money'},
			{label: 'Total Certificado', property: 'nCertificado', type: 'money'},
			{label: 'Saldo Certificado', property: '', type: 'money', transformar(row): any {
					return row.nPim - row.nCertificado;
				}},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificadoEnero',
			'nCertificadoFebrero',
			'nCertificadoMarzo',
			'nCertificadoAbril',
			'nCertificadoMayo',
			'nCertificadoJunio',
			'nCertificadoJulio',
			'nCertificadoAgosto',
			'nCertificadoSeptiembre',
			'nCertificadoOctubre',
			'nCertificadoNoviembre',
			'nCertificadoDiciembre',
			'nCertificado',
		]);


		const agrupadoSecfunc = groupBy(resultadosGenerales, (obj) => {
			return obj.Sec_func;
		});

		console.log(agrupadoSecfunc);
		let dataSecfunc = {
			Sec_func: '',
			Act_proy: '',
			Componente: '',
			Funcion: '',
			Programa: '',
			Sub_programa: '',
			Meta: '',
			Finalidad: '',
			cNombre_Sec_func: '',

			nPim: 0,
			nCertificadoEnero: '',
			nCertificadoFebrero: '',
			nCertificadoMarzo: '',
			nCertificadoAbril: '',
			nCertificadoMayo: '',
			nCertificadoJunio: '',
			nCertificadoJulio: '',
			nCertificadoAgosto: '',
			nCertificadoSeptiembre: '',
			nCertificadoOctubre: '',
			nCertificadoNoviembre: '',
			nCertificadoDiciembre: '',
			nCertificado: 0,
		};


		let pag = 0;
		let nuevoCuerpo = [];




		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoSecfunc).forEach((seSecfunc) => {
			dataSecfunc = {
				...{
					Sec_func: agrupadoSecfunc[seSecfunc][0]['Sec_func'],
					Act_proy: agrupadoSecfunc[seSecfunc][0]['Act_proy'],
					Componente: agrupadoSecfunc[seSecfunc][0]['Componente'],
					Funcion: agrupadoSecfunc[seSecfunc][0]['Funcion'],
					Programa: agrupadoSecfunc[seSecfunc][0]['Programa'],
					Sub_programa: agrupadoSecfunc[seSecfunc][0]['Sub_programa'],
					Meta: agrupadoSecfunc[seSecfunc][0]['Meta'],
					Finalidad: agrupadoSecfunc[seSecfunc][0]['Finalidad'],
					cNombre_Sec_func: agrupadoSecfunc[seSecfunc][0]['cNombre_Sec_func'],
				},
				...sumarObjetos(agrupadoSecfunc[seSecfunc], [
					'nPim',
					'nCertificadoEnero',
					'nCertificadoFebrero',
					'nCertificadoMarzo',
					'nCertificadoAbril',
					'nCertificadoMayo',
					'nCertificadoJunio',
					'nCertificadoJulio',
					'nCertificadoAgosto',
					'nCertificadoSeptiembre',
					'nCertificadoOctubre',
					'nCertificadoNoviembre',
					'nCertificadoDiciembre',
					'nCertificado',
				]),
			};

			const agrupadoFuenteFinanc = groupBy(agrupadoSecfunc[seSecfunc], (obj) => {
				return obj.Fuente_Financ;
			});

			let dataFuenteFinanc = {
				Fuente_Financ: '',
				Nombre_Fuente_Financ: '',
				nPim: 0,
				nCertificadoEnero: '',
				nCertificadoFebrero: '',
				nCertificadoMarzo: '',
				nCertificadoAbril: '',
				nCertificadoMayo: '',
				nCertificadoJunio: '',
				nCertificadoJulio: '',
				nCertificadoAgosto: '',
				nCertificadoSeptiembre: '',
				nCertificadoOctubre: '',
				nCertificadoNoviembre: '',
				nCertificadoDiciembre: '',
				nCertificado: 0,
			};

			let pag2 = 0;
			Object.keys(agrupadoFuenteFinanc).forEach((secFuenteFinanc) => {
				dataFuenteFinanc = {
					...{
						Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_Financ'],
						Nombre_Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_Fuente_Financ'],
					},
					...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
						'nPim',
						'nCertificadoEnero',
						'nCertificadoFebrero',
						'nCertificadoMarzo',
						'nCertificadoAbril',
						'nCertificadoMayo',
						'nCertificadoJunio',
						'nCertificadoJulio',
						'nCertificadoAgosto',
						'nCertificadoSeptiembre',
						'nCertificadoOctubre',
						'nCertificadoNoviembre',
						'nCertificadoDiciembre',
						'nCertificado',
					]),
				};

				if (pag2 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([
						generarColSpan(imprimirCelda(dataSecfunc.Sec_func, null, {
							bold: true,
							fontSize: 8,
							style: 'tdSmall', noWrap: true, color: '#b01113',
							alignment: 'center'
						}), 1).concat(
							imprimirCelda(dataSecfunc.Sec_func + '.' + dataSecfunc.Act_proy + dataSecfunc.Componente + ' - ' + dataSecfunc.Funcion + '.' + dataSecfunc.Programa
								+ '.' + dataSecfunc.Sub_programa + ' - ' + dataSecfunc.Meta + '.' + dataSecfunc.Finalidad + ' ' + dataSecfunc.cNombre_Sec_func, null, {
								bold: true,
								style: 'tdSmall', color: '#b01113',
								alignment: 'left',
								// rowSpan: tablaGenerada['cuerpo'].length + 1,
							}),
							imprimirCelda(dataSecfunc.nPim, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoEnero, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoFebrero, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoMarzo, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoAbril, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoMayo, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoJunio, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoJulio, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoAgosto, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoSeptiembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoOctubre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoNoviembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificadoDiciembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda(dataSecfunc.nCertificado, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
							imprimirCelda((dataSecfunc.nPim - dataSecfunc.nCertificado), 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#b01113', alignment: 'right'}),
						),

				]);
			}
				// agrupadoSecfunc[secSecfunc] = ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Fuente_Financ', true)
				const agrupadocGeneCodigo = groupBy(agrupadoFuenteFinanc[secFuenteFinanc], (obj) => {
					return obj.cGeneCodigo;
				});
				let datacGeneCodigo = {
					cGeneCodigo: '',
					cGeneNombre: '',
					nPim: 0,
					nCertificadoEnero: '',
					nCertificadoFebrero: '',
					nCertificadoMarzo: '',
					nCertificadoAbril: '',
					nCertificadoMayo: '',
					nCertificadoJunio: '',
					nCertificadoJulio: '',
					nCertificadoAgosto: '',
					nCertificadoSeptiembre: '',
					nCertificadoOctubre: '',
					nCertificadoNoviembre: '',
					nCertificadoDiciembre: '',
					nCertificado: 0,
				};

				let pag3 = 0;
				ordenarArray(Object.keys(agrupadocGeneCodigo), true).forEach((seccGeneCodigo) => {
					datacGeneCodigo = {
						...{
							cGeneCodigo: agrupadocGeneCodigo[seccGeneCodigo][0]['cGeneCodigo'],
							cGeneNombre: agrupadocGeneCodigo[seccGeneCodigo][0]['cGeneNombre'],
						},
						...sumarObjetos(agrupadocGeneCodigo[seccGeneCodigo], [
							'nPim',
							'nCertificadoEnero',
							'nCertificadoFebrero',
							'nCertificadoMarzo',
							'nCertificadoAbril',
							'nCertificadoMayo',
							'nCertificadoJunio',
							'nCertificadoJulio',
							'nCertificadoAgosto',
							'nCertificadoSeptiembre',
							'nCertificadoOctubre',
							'nCertificadoNoviembre',
							'nCertificadoDiciembre',
							'nCertificado',
						]),
					};

					const tablaGenerada = objThis.generarDesdeMantenimiento({
						titulos: camposPrincipal,
						contenido: ordenarPorPropiedad(agrupadocGeneCodigo[seccGeneCodigo], 'cGeneCodigo'),
						// numeracion: true,
						idxResto: [6],
						margin: [0, 0, 0, 5],
						separado: true,
					});


					if (pag3 == 0) {
						nuevoCuerpo = nuevoCuerpo.concat([
							generarColSpan(imprimirCelda(dataFuenteFinanc.Fuente_Financ, null, {
								bold: true,
								fontSize: 8,
								style: 'tdSmall', noWrap: true, color: '#0f5b18',
								alignment: 'right'
							}), 1).concat(
								imprimirCelda(dataFuenteFinanc.Nombre_Fuente_Financ, null, {
									bold: true,
									style: 'tdSmall', color: '#0f5b18',
									alignment: 'left',
									// rowSpan: tablaGenerada['cuerpo'].length + 1,
								}),

								imprimirCelda(dataFuenteFinanc.nPim, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoEnero, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoFebrero, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoMarzo, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoAbril, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoMayo, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoJunio, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoJulio, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoAgosto, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoSeptiembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoOctubre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoNoviembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificadoDiciembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda(dataFuenteFinanc.nCertificado, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
								imprimirCelda((dataFuenteFinanc.nPim - dataFuenteFinanc.nCertificado), 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#0f5b18', alignment: 'right'}),
							),

						]);
					}

					nuevoCuerpo = nuevoCuerpo.concat([

						generarColSpan(imprimirCelda(datacGeneCodigo.cGeneCodigo, 'texto', {
							bold: true,
							fontSize: 8,
							style: 'tdSmall', noWrap: true, color: '#1c28b0',
							alignment: 'center'
						}), 1).concat(
							imprimirCelda(datacGeneCodigo.cGeneNombre, 'texto', {
								bold: true,
								style: 'tdSmall', color: '#1c28b0',
								alignment: 'left'
							}),
						imprimirCelda(datacGeneCodigo.nPim, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoEnero, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoFebrero, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoMarzo, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoAbril, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoMayo, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoJunio, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoJulio, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoAgosto, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoSeptiembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoOctubre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoNoviembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificadoDiciembre, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda(datacGeneCodigo.nCertificado, 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						imprimirCelda((datacGeneCodigo.nPim - datacGeneCodigo.nCertificado), 'money', {bold: true, style: 'tdSmall', noWrap: true, color: '#1c28b0', alignment: 'right'}),
						),
					]);

					nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
					pag3++;
				});
				pag2++;
			});
			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				style: 'thSmall',
				alignment: 'right'
			}), 2).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoEnero, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoFebrero, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoMarzo, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoAbril, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoMayo, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoJunio, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoJulio, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoAgosto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoSeptiembre, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoOctubre, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoNoviembre, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificadoDiciembre, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda((sumasTotales.nPim - sumasTotales.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				// [{idx: 0, valor: 50}, {idx: 1, valor: 30}, {idx: 2, valor: 30}, ]
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [1], [{idx: 0, valor: 30}]),
				body: nuevoCuerpo,
			}
		});
		// console.log(JSON.stringify(cuerpoPdf));
		// console.log(cuerpoPdf);
		objThis.finalizarCreacionPdf(cuerpoPdf);
	 });
}
