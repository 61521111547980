import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'general-mensaje-alerta',
    templateUrl: './mensaje-alerta.component.html',
    styleUrls: ['./mensaje-alerta.component.scss'],
})
export class MensajeAlertaComponent implements OnInit {
    @Input() tipo: 'info' | 'error' | 'success' | 'warning' = 'info';
    @Input() textoTitulo;
    @Input() botonCerrar = false;
    @Input() botonAcepto = false;


    @Output() accionAcepto = new EventEmitter<any>();

    colorAlerta;
    icono;

    constructor() {
    }

    ngOnInit(): void {
        switch (this.tipo) {
            case 'info':
                this.colorAlerta = 'blue';
                this.icono = 'roundInfo';
                break;
            case 'error':
                this.colorAlerta = 'red';
                this.icono = 'roundError';
                break;
            case 'success':
                this.colorAlerta = 'green';
                this.icono = 'roundCheckCircle';
                break;
            case 'warning':
                this.colorAlerta = 'yellow';
                this.icono = 'roundWarning';
                break;
        }
    }

}
