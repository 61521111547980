<ng-content></ng-content>
<ng-content select="[contentConfig]"></ng-content>
<!--<ng-container [matColumnDef]="column.property">
	<th mat-header-cell *matHeaderCellDef mat-sort-header> Titulo </th>
	<td mat-cell *matCellDef="let data"> Contenido</td>
</ng-container>-->
<ng-container *ngFor="let column of colDetalle; trackBy: trackByProperty">

	<ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<span *ngIf="column.property && !column.textArray">{{ row[column.property] }}</span>
			<span *ngIf="column.property && column.textArray">{{ verTexto(row, column.textArray) }}</span>
		</td>

	</ng-container>
	<ng-container *ngIf="column.type === 'text_rows'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<p *ngFor="let fila of column.text_rows" [ngClass]="fila.cssClasses" style="font: unset; letter-spacing: unset">
					<span *ngIf="row[fila.value]">
						<span [ngClass]="fila.cssLabelClasses" *ngIf="fila.label">{{ fila.label }}:</span>&nbsp;<span [ngClass]="fila.cssValueClasses">{{ row[fila.value] }}</span>
					</span>
			</p>
		</td>

	</ng-container>
	<ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
				<span *ngIf="column.property && row[column.property]">
					<span *ngIf="column.zeroFill">{{ row[column.property] | zeroFill: column.zeroFill }}</span>
					<span *ngIf="!column.zeroFill">{{ row[column.property] | number: (column.format ? column.format : '0.0-2') }}</span>
				</span>
		</td>

	</ng-container>
	<ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell><span *ngIf="column.property">{{ row[column.property] | date: (column.format ? column.format : 'dd/MM/yyyy') }}</span></td>

	</ng-container>
	<ng-container *ngIf="column.type === 'icon'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell>{{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<ng-container [ngSwitch]="row[column.property]">
				<ng-container *ngFor="let opc of column.opcSwitchIcon.casos">
					<mat-icon *ngSwitchCase="opc.case" [ngClass]="opc.cssClasses" style="height: unset; width: unset;" [svgIcon]="opc.icon" size="18px"></mat-icon>
				</ng-container>
				<ng-container *ngIf="column.opcSwitchIcon.default">
					<div *ngSwitchDefault></div>
				</ng-container>
			</ng-container>
			<span class="cdk-visually-hidden">{{ row[column.property] }}</span>
		</td>

	</ng-container>
	<ng-container *ngIf="column.type === 'buttons'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>

			<div class="flex">
				<ng-container *ngFor="let field of column.fields">
					<a *ngIf="field.href && row[field.campo]"
					   [href]="field.href.pre + row[field.campo]"
					   [target]="field.href.target ? field.href.target : '_self'"
					   [matTooltip]="row[field.campo]"
					   class="w-6 h-6 leading-none flex items-center justify-center hover:bg-hover {{field.class}}"
					   mat-icon-button>
						<mat-icon [svgIcon]="field.icono" size="18px"></mat-icon>
					</a>
					<a *ngIf="!field.fnClick && !field.href && row[field.campo]"
					   (click)="$event.stopPropagation()"
					   [matTooltip]="row[field.campo]"
					   class="w-6 h-6 leading-none flex items-center justify-center hover:bg-hover {{field.class}}"
					   mat-icon-button>
						<mat-icon [svgIcon]="field.icono" size="18px"></mat-icon>
					</a>
					<a *ngIf="field.fnClick && row[field.campo]"
					   (click)="objThis[field.fnClick](row, field.fnClickData); $event.stopPropagation()"
					   [matTooltip]="row[field.campo]"
					   class="w-6 h-6 leading-none flex items-center justify-center hover:bg-hover {{field.class}}"
					   mat-icon-button>
						<mat-icon [svgIcon]="field.icono" size="18px"></mat-icon>
					</a>
				</ng-container>
			</div>

		</td>

	</ng-container>

	<ng-container *ngIf="column.type === 'estado'" [matColumnDef]="column.property" [stickyEnd]="column.stickyEnd" [sticky]="column.sticky">

		<th *matHeaderCellDef class="uppercase" mat-header-cell>{{ column.label }}</th>
		<td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
			<ng-container [ngSwitch]="row[column.property].charAt(0)">
				<ng-container *ngFor="let opc of column.opcSwitchIcon.casos">
					<div *ngSwitchCase="opc.case"
					     [ngClass]="opc.cssClasses"
					     class="rounded px-1 font-medium"
					     style="font-size: 80%;"
					     fxFlex="none">
						<mat-icon *ngIf="opc.icon" [ngClass]="opc.cssClasses" class="icon-xs" style="height: unset; width: unset;" [svgIcon]="opc.icon" size="18px"></mat-icon>
						{{ row[column.property].substring(2) }}
					</div>
				</ng-container>
				<ng-container *ngIf="column.opcSwitchIcon.default">
					<div *ngSwitchDefault></div>
				</ng-container>
			</ng-container>
		</td>

	</ng-container>

</ng-container>

