<ng-template #sidenavRef>
	<vex-mod-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-mod-sidenav>
</ng-template>

<ng-template #toolbarRef>
	<vex-mod-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
	             [mobileQuery]="!(isDesktop$ | async)"
	             class="vex-toolbar"></vex-mod-toolbar>
</ng-template>

<ng-template #footerRef>
	<vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
</ng-template>

<ng-template #quickpanelRef>
	<vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-mod-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef"></vex-mod-layout>

<!--<vex-config-panel-toggle (openConfig)="configpanel.open()"></vex-config-panel-toggle>-->

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
	<vex-config-panel></vex-config-panel>
</vex-sidebar>
<!-- END CONFIGPANEL -->


<!-- TEMPLATES JVSOFT -->
<ng-template #dialogSeleccionarEntidad>
	<div globalDialogFlotante sinTitulo sinBarraDeAccion>
		<div @stagger cuerpo class="p-1 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <div class="flex flex-col cursor-pointer max-w-sm p-1 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100"
                 *ngFor="let entidad of entidadesCredencial"
                 (click)="seleccionarEntidad(entidad?.iEntId)"
                 matRipple
            >
                <img [src]="servidorService.storageImageUrl + entidad.cEntLogoPrincipal" class="h-16">
                <div class="flex items-center justify-center text-secondary text-xs">
                    <mat-icon svgIcon="mat:link" class="icon-xs"></mat-icon>
                    <span>{{ entidad?.cEntWeb }}</span>
                </div>
            </div>
        </div>
	</div>
</ng-template>

<ng-template #dialogCambiarPasswordObligatorio>

	<form globalDialogFlotante btnCerrar="CANCELAR" btnGuardar (ngSubmit)="guardarContatosPassword()" [formGroup]="frmRegistro">
		<div titulo>Actualizar Contraseña</div>
		<div cuerpo fxLayout="column" fxLayoutGap="2px" class="pt-2">

			<mat-form-field fxFlex="grow">
				<mat-label>Teléfono Móvil</mat-label>
				<input cdkFocusInitial formControlName="cTelefonoMovil" matInput>
				<mat-icon svgIcon="mat:phone" class="mr-3" matPrefix></mat-icon>
				<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cTelefonoMovil')) }}</mat-error>
			</mat-form-field>

			<mat-form-field fxFlex="grow">
				<mat-label>Email</mat-label>
				<mat-icon svgIcon="mat:mail" class="mr-2" matPrefix></mat-icon>
				<input type="email" formControlName="cCorreoElectronico" matInput placeholder="Ex. usuario@jvsoft.pe" required autocomplete="off">
				<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cCorreoElectronico')) }}</mat-error>
			</mat-form-field>

			<mat-form-field fxFlex="grow">
				<mat-label>Contraseña</mat-label>
				<input [type]="inputType" formControlName="new_password" matInput required>
				<button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
					<mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
					<mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
				</button>
				<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('new_password')) }}</mat-error>
				<mat-hint>Clic en el ojo para alternar visibilidad</mat-hint>
			</mat-form-field>

			<mat-form-field fxFlex="grow">
				<mat-label>Contraseña (Confirmar)</mat-label>
				<input [type]="inputType" formControlName="re_new_password" matInput required>
				<button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
					<mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
					<mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
				</button>
				<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('re_new_password')) }}</mat-error>
				<mat-hint>Por favor repite la contraseña anterior</mat-hint>
			</mat-form-field>

		</div>
<!--		<ng-container pieDerecha class="pb-2" *ngIf="btnCancelarCambioPWD">-->
<!--			<button mat-button mat-dialog-close type="button" class="rounded px-2 font-medium text-xs border border-solid" fxLayout="row" fxLayoutAlign="space-around center" (click)="btnCancelarCambioPWD = false; usuarioService._cambiarPassword.next(false);">-->
<!--				<mat-icon class="icon-xs mr-1" svgIcon="icCancel" ></mat-icon>-->
<!--				<span>CANCELAR</span>-->
<!--			</button>-->
<!--		</ng-container>-->
	</form>

</ng-template>

<ng-template #dialogCambiarCodigoSeguridad>

	<form globalDialogFlotante btnCerrar="CANCELAR" btnGuardar (ngSubmit)="guardarCodigoSeguridad()" [formGroup]="frmRegistroCodigo">
		<ng-container titulo>Código de Seguridad</ng-container>
        <div cuerpo class="flex flex-col gap-0.5">
            <mat-form-field style="font-size: 32px !important; color: #13293d !important;">
                <mat-label>Código de Seguridad</mat-label>
                <input type="text" formControlName="cCodigoSeguridad" matInput autocomplete="off">
                <span matPrefix>{{ (usuarioService._data.getValue()['iCredId']) }}</span>
                <mat-error>{{ mensajesErrorFormControl(frmRegistroCodigo.get('cCodigoSeguridad')) }}</mat-error>
            </mat-form-field>
        </div>
	</form>

</ng-template>

