import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NavigationService} from "@vex/services/navigation.service";
import {NavigationItemMod} from "@vexMod/interfaces/navigation-item.interface";

@Injectable({
  providedIn: 'root'
})
export class NavigationServiceMod extends NavigationService{

  _subMenus = new BehaviorSubject<NavigationItemMod[]>([]);
  subMenus$ = this._subMenus.asObservable();

  subItems: NavigationItemMod[] = [];
}
