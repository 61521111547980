import {AfterContentInit, ContentChildren, Directive, ElementRef, QueryList} from '@angular/core';
import {MatLine, setLines} from '@angular/material/core';

@Directive({
	selector: 'mat-option[globalMultilineOption]',
	host: {
		class: 'multiline-option'
	},
	exportAs: 'multilineOption'
})
export class MultilineOptionDirective implements AfterContentInit {

	@ContentChildren(MatLine, {descendants: true, read: MatLine})
	public lines: QueryList<MatLine>;

	constructor(
		protected element: ElementRef<HTMLElement>,
		private hostElement: ElementRef,
	) { }

	public ngAfterContentInit(): void {
		setLines(this.lines, this.element);
		this.element.nativeElement.style.cssText = 'height: max-content !important';
	}

}
