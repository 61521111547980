<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-mod-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-mod-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">

    <div class="px-1">
      <vex-mod-toolbar-notifications></vex-mod-toolbar-notifications>
    </div>

      <div class="px-1">
          <vex-mod-toolbar-user></vex-mod-toolbar-user>
      </div>
      <div class="px-1">
          <button (click)="configService.isDark(colorScheme) ? configService.disableDarkMode() : configService.enableDarkMode()" mat-icon-button type="button"
                  *ngIf="colorScheme$ | async as colorScheme"
          >
              <mat-icon *ngIf="configService.isDark(colorScheme)" svgIcon="mat:light_mode"></mat-icon>
              <mat-icon *ngIf="!configService.isDark(colorScheme)" svgIcon="mat:dark_mode"></mat-icon>
          </button>
      </div>
  </div>
</div>

<vex-mod-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-mod-navigation>
