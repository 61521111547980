import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ToastrModule} from 'ngx-toastr';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material/core';
import {APP_DATE_FORMATS, GlobalDateAdapter} from './adapters/global-date-adapter';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ElementosDeshabilitadosDirective} from './directives/elementos-deshabilitados.directive';
import {PdfViewerComponent} from './components/pdf-viewer/pdf-viewer.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ZeroFillPipe} from './pipes/zero-fill.pipe';
import {FiltroPipe} from './pipes/filtro.pipe';
import {JsonParsePipe} from './pipes/json-parse.pipe';
import {DataEnListaPipe} from './pipes/data-en-lista.pipe';
import {FormFocusDirective} from './directives/form-focus.directive';
import {FocusDirective} from './directives/focus.directive';
import {TabEnterFocusDirective} from './directives/tab-enter-focus.directive';
import {NextFocusDirective} from './directives/next-focus.directive';
import {FormControlErrorDirective} from './directives/form-control-error.directive';
import {ValidationLabelDirective} from './directives/validation-label.directive';
import {DialogFlotanteComponent} from './components/dialog-flotante/dialog-flotante.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DateDiffStringPipe} from './pipes/date-diff-string.pipe';
import {MultilineOptionDirective} from './directives/multiline-option.directive';
import {MatIconModule} from '@angular/material/icon';
import {LucesNavidadComponent} from '@JVSoft/components/luces-navidad/luces-navidad.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {FormControlIsRequiredPipe} from './pipes/form-control-is-required.pipe';
import {AutofocusDirective} from './directives/autofocus.directive';
import {MenuComponent} from './components/menu/menu.component';
import {ControlDirective, DesplazarPorTecladoDirective, ManageArrowDirective} from './directives/desplazar-por-teclado.directive';
import {MatRowKeyboardSelectionDirective} from './directives/mat-row-keyboard-selection.directive';
import {TimeMaskDirective} from './directives/time-mask.directive';
import {NoSanitizePipe} from '@JVSoft/pipes/no-sanitize.pipe';
import {ProgressSpinnerComponent} from './components/progress-spinner/progress-spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
    declarations: [
        PdfViewerComponent,
        ElementosDeshabilitadosDirective,
        ZeroFillPipe,
        FiltroPipe,
        JsonParsePipe,
        DataEnListaPipe,
        FormFocusDirective,
        FocusDirective,
        TabEnterFocusDirective,
        NextFocusDirective,
        FormControlErrorDirective,
        ValidationLabelDirective,
        DialogFlotanteComponent,
        DateDiffStringPipe,
        MultilineOptionDirective,
        LucesNavidadComponent,
        ProgressBarComponent,
        FormControlIsRequiredPipe,
        AutofocusDirective,
        MenuComponent,
        DesplazarPorTecladoDirective,
        ControlDirective,
        ManageArrowDirective,
        MatRowKeyboardSelectionDirective,
        TimeMaskDirective,
        NoSanitizePipe,
        ProgressSpinnerComponent,
    ],
    exports: [
        ElementosDeshabilitadosDirective,
        ZeroFillPipe,
        FiltroPipe,
        JsonParsePipe,
        DataEnListaPipe,
        FormFocusDirective,
        FocusDirective,
        TabEnterFocusDirective,
        NextFocusDirective,
        FormControlErrorDirective,
        ValidationLabelDirective,
        DialogFlotanteComponent,
        DateDiffStringPipe,
        MultilineOptionDirective,
        LucesNavidadComponent,
        ProgressBarComponent,
        FormControlIsRequiredPipe,
        AutofocusDirective,
        MenuComponent,
        DesplazarPorTecladoDirective,
        MatRowKeyboardSelectionDirective,
        NoSanitizePipe,
        TimeMaskDirective,
        ProgressSpinnerComponent,
        PdfViewerComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        ToastrModule.forRoot(),
        MatMenuModule,
        MatButtonModule,
        MatListModule,
        MatProgressBarModule,
        MatDialogModule,
        DragDropModule,
        MatIconModule,
        MatRippleModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'es-PE'},
        {provide: MAT_DATE_LOCALE, useValue: 'es-PE'},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {provide: DateAdapter, useClass: GlobalDateAdapter},
    ],
})
export class JVSoftModule {
}
