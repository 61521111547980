import {twotone} from '@servicios/icons/google-material/twotone';
import {round} from '@servicios/icons/google-material/round';

/** @deprecated Variable restringida */
const reemplazos = {
    icPhone: twotone.twotonePhone,
    icMail: twotone.twotoneMail,
    icMap: twotone.twotoneMap,
    icSearch: twotone.twotoneSearch,
    icAdd: twotone.twotoneAdd,
    icFilterList: twotone.twotoneFilterList,
    icMoreVert: twotone.twotoneMoreVert,
    icVisibility: twotone.twotoneVisibility,
    icVisibilityOff: twotone.twotoneVisibilityOff,
    icListAlt: twotone.twotoneListAlt,
    icCheck: twotone.twotoneCheck,
    icCheckCircle: twotone.twotoneCheckCircle,
    icMenu: twotone.twotoneMenu,
    icDescription: twotone.twotoneDescription,
    icDoneAll: twotone.twotoneDoneAll,
    icMoreHoriz: twotone.twotoneMoreHoriz,
    icEdit: twotone.twotoneEdit,
    icDelete: twotone.twotoneDelete,
    icChevronRight: twotone.twotoneChevronRight,
    icSettings: twotone.twotoneSettings,
    icClose: twotone.twotoneClose,


    icExpandMore: round.roundExpandMore,
    roundEmail: round.roundMail,
    roundKeyboardArrowRight: round.roundKeyboardDoubleArrowRight,
    roundKeyboardArrowLeft: round.roundKeyboardDoubleArrowLeft,
}
export const iconsGoogleMaterial = {
    ...reemplazos,
    ...round,
    ...twotone,
};
