<div globalDialogFlotante btnCerrar *ngIf="datosDialogRecibidos">
	<ng-container titulo>
		Credenciales :: <small>{{ datosDialogRecibidos.titulo }}</small>
	</ng-container>
	<ng-container cuerpo>
<!--		<pre>AAAAAA{{ datosDialogRecibidos.data |json }}</pre>-->
		<general-tabla-mantenimiento-nuevo
			[objThis]="this"
			[botonesCRUD]="datosDialogRecibidos.botonesCrud || 'CUD'"
			[nombreColeccion]="datosDialogRecibidos.nombreColeccion"
			[data]="datosDialogRecibidos.data || []"
			ctrlBusqueda="simple"
			[filtroCampos]="true"
			[columnasTabla]="columnasCredenciales"

			(opcionSelecionada)="opcMenu($event)"
		>
		</general-tabla-mantenimiento-nuevo>
	</ng-container>
</div>

<ng-template #dialogRegCredenciales>
	<form globalDialogFlotante btnCerrar btnGuardar [matDialogRefActual]="dialogRefAdic[0]"
		  (ngSubmit)="opcMenu({item: undefined ,seccion: datosDialogRecibidos.nombreColeccion, tipo: 'guardar' })" [formGroup]="frmRegistro"
	>
		<ng-container titulo>
			{{ (seleccionados[this.datosDialogRecibidos.nombreColeccion] ? 'Editar' : 'Nuevo') }} ::<small>Credencial</small>
		</ng-container>
		<ng-container cuerpo>
			<div class="overflow-hidden" fxLayout="column" fxLayoutGap="6px">
				<mat-form-field fxFlex="auto" *ngIf="esPorCredenciales()">
					<mat-label>Usuario</mat-label>
					<input matInput formControlName="iCredId" [matAutocomplete]="iCredId" autocomplete="off">
					<mat-autocomplete #iCredId="matAutocomplete"
									  panelWidth="auto"
									  [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iCredId', campoValue: ['cPersNombreLargo'], lista: dataServidor.seg.credenciales_entidades})"
					>
						<mat-option *ngFor="let item of filtrados['seg.credenciales_entidades'] | async" [value]="item.iCredId">
							<small>{{ item.cPersNombreLargo }}</small>
						</mat-option>
					</mat-autocomplete>
					<div matSuffix
						 fxLayout="row wrap"
						 fxLayoutAlign="start center"
					>
						<button mat-icon-button
								*ngIf="frmRegistro.get('iCredId').value"
								fxLayout="row wrap"
								fxLayoutAlign="center center"
								(click)="frmRegistro.get('iCredId').reset(''); $event.preventDefault();"
						>
							<mat-icon svgIcon="icCancel" class="icon-xs"></mat-icon>
						</button>

						<mat-icon svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
					</div>
					<mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iCredId')) }}</mat-error>
				</mat-form-field>
				<general-fc-buscar-persona fxFlex="auto" label="Persona Credencial"
										   *ngIf="esPorPersonas()"
										   [placeholder]="'DNI'"
										   [tipoPersona]="1"
										   [incluirProveedorRemoto]="true"
										   formControlName="iPersId"
				></general-fc-buscar-persona>
			</div>
		</ng-container>
	</form>

</ng-template>
