import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {establecerQuitarRequired, mensajesErrorFormControl, mostrarValorEnBusqueda} from '@JVSoft/services/funciones-globales.service';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '@servicios/query.service';
import {FormulariosGlobalesService} from '@JVSoft/services/formularios-globales.service';
import {DataServidor, OpcionSeleccionada} from '@JVSoft/interfaces/global';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';

@Component({
    selector: 'vex-general-mantenimiento-personas-formulario',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        JVSoftModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
    ],
    templateUrl: './general-mantenimiento-personas-formulario.component.html',
    styleUrls: ['./general-mantenimiento-personas-formulario.component.scss']
})
export class GeneralMantenimientoPersonasFormularioComponent implements OnInit {
    protected readonly mostrarValorEnBusqueda = mostrarValorEnBusqueda;
    protected readonly mensajesErrorFormControl = mensajesErrorFormControl;

    dataServidorSuscripcion: DataServidor = {};
    opcMenuEmitter = new EventEmitter();

    dataServidor = {
        grl: {
            tipos_personas: null,
            tipos_personas_identificaciones: null,
            tipos_estados_civiles: null,
        },
    };
    seleccionados = {
        personas: null,
    };
    filtrados = {};

    frmRegistro: FormGroup;
    dialogRef: MatDialogRef<any>[] = [null, null, null];

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
        @Optional() private dialogActualRef: MatDialogRef<GeneralMantenimientoPersonasFormularioComponent>,
        private dialog: MatDialog,
        private queryService: QueryService,
        private fb: FormBuilder,
        public formulariosGlobalesService: FormulariosGlobalesService,
    ) {
        this.crearFormularios();
    }

    ngOnInit(): void {
        this.frmRegistro.get('iTipoPersId').valueChanges.subscribe(val => {
            if (val) {
                this.dataServidor.grl.tipos_personas_identificaciones = null;
                this.frmRegistro.get('iTipoIdentId').reset();
                this.queryService.cargar(this, [
                    'grl.tipos_personas_identificaciones',
                ], {iTipoPersId: val}).then();

                const camposPNatural = [
                    'cPersPaterno',
                    'cPersMaterno',
                    'cPersNombre',
                    'cPersSexo',
                    'dPersNacimiento',
                    'iTipoEstCivId',
                ];
                const camposPJuridica = [
                    'cPersRazonSocialNombre',
                    'cPersRazonSocialCorto',
                    'cPersRazonSocialSigla',
                ];

                if (val == 1) {
                    // PERSONA NATURAL
                    establecerQuitarRequired(this.frmRegistro, camposPNatural, camposPJuridica)
                }
                if (val == 2) {
                    // PERSONA JURIDICA
                    establecerQuitarRequired(this.frmRegistro, camposPJuridica, camposPNatural)
                }
            }
        })

        console.log(this.datosDialogRecibidos);
        this.cargaInicial().then(() => {
            if (this.datosDialogRecibidos) {
                if (this.datosDialogRecibidos.personas && this.datosDialogRecibidos.personas.iPersId) {
                    // this.seleccionados.personas = this.datosDialogRecibidos.personas;
                    this.cargarPersona(this.datosDialogRecibidos.personas.iPersId);
                }
                this.habilitarCamposFijos(false);
            }
        });
    }

    crearFormularios() {
        this.frmRegistro = this.fb.group({
            iTipoPersId: ['', [Validators.required]],
            iTipoIdentId: ['', [Validators.required]],

            cPersDocumento: ['', [Validators.required]],
            /*Persona Natural*/
            cPersPaterno: [''],
            cPersMaterno: [''],
            cPersNombre: [''],
            cPersSexo: [''],
            dPersNacimiento: [''],
            iTipoEstCivId: [''],
            cPersFotografia: [''],
            /*Persona Juridica*/
            cPersRazonSocialNombre: [''],
            cPersRazonSocialCorto: [''],
            cPersRazonSocialSigla: [''],
            /*Dirección*/
            cPersDomicilio: [''],
        });
    }
    cargaInicial() {
        return this.queryService.cargar(this, [
            'grl.tipos_personas',
            // 'grl.tipos_Identificaciones',
            'grl.tipos_estados_civiles',
        ]).then();
        // this.cargarLista();
    }

    cargarPersona(iPersId) {
        return this.queryService.cargar(this, [
            'grl.personas',
        ], {iPersId}).then(vRet => {
            if (vRet && vRet['grl.personas'] && vRet['grl.personas'].length > 0){
                this.seleccionados.personas = vRet['grl.personas'][0];
            }
        });
    }

    habilitarCamposFijos(accion) {
        if (this.datosDialogRecibidos.camposFijos) {
            console.log(this.dataServidor.grl.tipos_personas, this.datosDialogRecibidos.camposFijos['iTipoPersId']);
            const dTipoPersona = dataEnLista(this.dataServidor.grl.tipos_personas, 'iTipoPersId', this.datosDialogRecibidos.camposFijos['iTipoPersId']);
            console.log(dTipoPersona);
            if (!dTipoPersona) {
                return null;
            }
            this.frmRegistro.patchValue(this.datosDialogRecibidos.camposFijos);
            if (accion) {
                Object.keys(this.datosDialogRecibidos.camposFijos).forEach(campo => {
                    this.frmRegistro.get(campo).enable({emitEvent: false});
                })
            }
            else {
                Object.keys(this.datosDialogRecibidos.camposFijos).forEach(campo => {
                    this.frmRegistro.get(campo).disable({emitEvent: false});
                })
            }
        }
    }
    opcMenu(v: OpcionSeleccionada) {
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            data: {},
        };
        switch (v.seccion) {
            case 'personas':
                switch (v.tipo) {
                    case 'nuevo':
                    case 'editar':
                    default:
                        this.habilitarCamposFijos(true);
                        this.accionesExtras(v).then(() => {
                            // this.cargarLista();
                        });
                        this.habilitarCamposFijos(false);
                        break;
                }
                break;
        }
    }

    accionesExtras(v: OpcionSeleccionada, datosEmitir = {}) {
        return this.queryService.parteComunOpcionesMantenimiento2(this, v, this.frmRegistro, {
            ...{
                coleccion: 'grl.personas',
                idxTabla: 'iPersId',
                // strDataObservable: '_dataPrincipal',
                prefix: 'grl',
                dialogRef: this.dialogRef[0],
                retornarRespuestaGuardadoEliminado: true,
            },
            ...datosEmitir,
        }).then(dataRetornado => {
            console.log(dataRetornado);
            if (v.tipo == 'guardar' && dataRetornado) {
                if (dataRetornado['data'] && dataRetornado['data']['iResult'] == 1 && dataRetornado['data']['iPersId']) {
                    this.cargarPersona(dataRetornado['data']['iPersId']).then(() => {
                        this.opcMenuEmitter.emit({
                            ...v,
                            ...{
                                tipo: 'recargar',
                                datosExtra: {
                                    persona: this.seleccionados.personas,
                                    dataRetornado,
                                }
                            }
                        });
                    })
                }

            }
            // if (v.tipo != 'eliminar') {
            //     this.cargarLista();
            // }
            // v.seccion = 'salidas';
            // v.tipo = 'recargar';
            // this.opcMenu(v);
        }).catch(error => { console.log(error); });
    }

}
