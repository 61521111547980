import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '@servicios/query.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../../../session/services/session.service';
import {FuncionesGeneralesService} from '../../../general/services/funciones-generales.service';
import {PdfLogisticaService} from '@pdf/pdf-logistica.service';
import {BehaviorSubject} from 'rxjs';
import {ColumnaTabla, DataServidor, OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {PERFILES_USUARIO, UsuarioService} from '@servicios/usuario.service';
import {changeSelect, establecerQuitarRequired, mensajesErrorFormControl, mostrarValorEnBusqueda, objectPropertiesDate} from '@JVSoft/services/funciones-globales.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LogisticaPartialsPedidosDetallesComponent} from '@logistica/logistica-partials/logistica-partials-pedidos-detalles/logistica-partials-pedidos-detalles.component';
import {formControlIsRequired} from '@JVSoft/pipes/form-control-is-required.pipe';
import {establecerEstilosLogistica, estiloEstadoRegistro, estilosPorDefectoLogistica} from '@logistica/logistica.functions';
import {JVSoftRouteData} from '../../../interfaces/importantes';
import {ActivatedRoute} from '@angular/router';
import {PdfAlmacenService} from '@pdf/pdf-almacen';
import {TabSeccion} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';
import {FiltroBusquedaService} from '@standalone/components/filtro-busqueda/filtro-busqueda.service';

@UntilDestroy()
@Component({
    selector: 'vex-logistica-bandeja-pedidos',
    templateUrl: './logistica-bandeja-pedidos.component.html',
    styleUrls: ['./logistica-bandeja-pedidos.component.scss'],
    providers: [],
})
export class LogisticaBandejaPedidosComponent implements OnInit {
    @Input() esPedidoParaAlmacen = false;
    @Input() btnSeleccionar = false;
    @Input() btnMantenimiento: any = 'UD';

    @Output() exportarData = new EventEmitter();

    estilosPorDefectoDocumentos = estilosPorDefectoLogistica;

    mensajesErrorFormControl = mensajesErrorFormControl;
    mostrarValorEnBusqueda = mostrarValorEnBusqueda;

    dataServidorSuscripcion: DataServidor = {};
    dataServidor = {
        grl: {
            establecimientos_dependencias: null,
            years: null,
            months: null,
        },
        log: {
            years_pedidos: null,
            months_pedidos: null,
            pedidos: null,

            tipos_productos: null,
            establecimientos: null,

            motivos_movimientos_productos: null,
            destinos_clases_pedidos: null,
        },
        bud: {
            credenciales_dependencias: null,
            tareas: null,
        },
    };
    seleccionados = {
        pedidos: null,
    };
    filtrados = {};

    frmFiltro: FormGroup;
    frmRegistro: FormGroup;
    frmRegistroPecosa: FormGroup;
    frmRegistroDetalle: FormGroup;


    opcionesFiltro: TabSeccion[] = [
        {
            label: 'Filtros',
            id: '0',
            params: [
                {
                    control: 'select', campo: 'iDestinoPedidoId', change: 'iClasePedidoId', data: 'log.destinos_pedidos', dataNull: 'Todos', text: 'cDestinoPedidoNombre', label: 'Para', flex: '100px',
                },
                { control: 'select', campo: 'iClasePedidoId', data: 'log.clases_pedidos', val: '', dataChangeReqParams: ['iDestinoPedidoId'], text: 'cClasePedidoNombre', label: 'Clase Pedido', flex: '150px', dataNull: 'Todos' },
                {
                    control: 'select', campo: 'iTipoProductoId', data: 'log.tipos_productos', val: '', text: 'cTipoProductoNombre', label: 'Tipo', flex: '100px', dataNull: 'Todos',
                },
                {
                    control: 'select', change: 'iMonthId', campo: 'iYearId',
                    data: 'grl.years', val: '*primero*', text: 'iYearId', label: 'Año', flex: '100px',
                },
                {
                    control: 'select', campo: 'iMonthId', campoId: 'iPedidoMonth', data: 'log.months_pedidos',
                    val: (new Date().getMonth() + 1).toString(), dataChangeReqParams: ['iYearId'],
                    text: 'cMonthNombre', label: 'Mes', flex: '100px',
                },
                { control: 'select', campo: 'iEstablecimientoId', data: 'grl.establecimientos', val: '', text: 'cEstablecimientoNombreLargo', label: 'Establecimiento', dataNull: 'Todos', flex: '200px' },
                { control: 'select', campo: 'iDepenId', data: 'grl.dependencias', val: '', text: 'cDepenNombre', label: 'Dependencia', dataNull: 'Todos', flex: '200px' },
                { control: 'autocomplete', campo: 'iTareaId',  data: 'bud.tareas', val: '', dataReqParams: {cCampoBusqueda: '%'}, text: ['cTareaCodigo', 'cTareaNombre'], label: 'Tarea', dataNull: 'Todos' },
            ]
        },
        {
            label: 'Por Criterio',
            id: '1',
            params: [
                {
                    control: 'select', change: 'iMonthId', campo: 'iYearId',
                    data: 'grl.years', val: '*primero*', text: 'iYearId', label: 'Año', flex: '100px',
                },
                {
                    control: 'select', campo: 'iCritId', data: 'log.Criterio_Busqueda_pedidos', val: '*primero*',
                    text: 'cCritDescripcion', label: 'Criterio', flex: '150px',
                },
                {control: 'text', campo: 'cCritVariable', text: '', label: 'Texto', flex: '150px', keyUpEnter: true},
            ]
        },
    ];

    columnsBandejaPedidos: ColumnaTabla<any>[] = [
        {label: '#', property: 'numeracion_automatica', type: 'custom'},
        {
            label: 'L', labelLista: 'Localización', property: 'cTipoEstablecimientoNombre', type: 'icons',
            iconsDef: {
                soloIcono: true,
                items: [
                    {
                        icon: 'buildingMultiple24Regular', toolTipField: 'cTipoEstablecimientoNombre',
                        contentCss: ' text-blue-700 !ring-blue-700',
                        iconSize: '16px',
                        esVisible(row): boolean { return row['iTipoEstablemientoId'] == 1; }
                    },
                ]
            },
        },
        {
            label: 'CP', labelLista: 'Clase Pedido', property: 'cClasePedidoNombre', type: 'icons',
            iconsDef: {
                soloIcono: true,
                iconSize: '16px',
                items: [
                    {
                        icon: 'roundSchedule', toolTipField: 'cClasePedidoNombre',
                        contentCss: ' text-red-700 !ring-red-700',
                        esVisible(row): boolean {
                            switch (row['iClasePedidoId'] * 1) {
                                case 1:
                                    this.icon = 'roundSchedule';
                                    this.contentCss = ' text-cyan-700 !ring-cyan-700';
                                    break;
                                case 2:
                                    this.icon = 'roundCurrencyExchange';
                                    this.contentCss = ' text-red-700 !ring-red-700';
                                    break;
                                case 3:
                                    this.icon = 'roundUpdate';
                                    this.contentCss = ' text-orange-700 !ring-orange-700';
                                    break;
                                case 4:
                                    this.icon = 'roundAdminPanelSettings';
                                    this.contentCss = ' text-indigo-700 !ring-indigo-700';
                                    break;
                            }
                            return true;
                        }
                        // esVisible(row): boolean { return row['iClasePedidoId'] == 2; }
                    },
                ]
            },
        },
        /*{
            label: 'Lugar', property: 'iTipoEstablemientoId', type: 'icon', cssClasses: ['text-center'],
            opcSwitchIcon: {
                casos: [
                    {case: '1', icon: 'buildingMultiple24Regular', cssClasses: ['text-blue-700']},
                ], default: 1,
                    // { icon: 'homeCircleOutline', cssClasses: ['text-red-700']},
            },
        },*/
        {label: 'N° Pedido', property: 'iPedidoNumero', type: 'estiloEstablecido'},
        {label: 'EMISIÓN', property: 'dPedidoEmision', type: 'date'},
        {label: 'Tipo Producto', property: 'cTipoProductoNombre', type: 'text'},
        {label: 'Referencia', property: 'cPedidoReferencia', type: 'text'},
        {label: 'Periodo', property: 'cMonthNombre', type: 'text'},
        {label: 'Para', property: 'cDestinoPedidoNombre', type: 'text', textCss: ['text-center']},
        // {label: 'Órden', property: 'cCompraNumero', type: 'text', textCss: 'w-full text-blue-900 bg-blue-100 border border-blue-900 font-bold rounded-md text-center text-2xs px-1 whitespace-nowrap'},
        // {label: 'Cotización', property: 'cCotizaNumero', type: 'text', textCss: 'w-full text-green-900 bg-green-100 border border-green-900 font-bold rounded-md text-center text-2xs px-1 whitespace-nowrap'},
        // {label: 'Salida', property: 'cSalidaNumero', type: 'text', textCss: 'w-full text-red-900 bg-red-100 border border-red-900 font-bold rounded-md text-center text-2xs px-1 whitespace-nowrap'},
        {label: 'Atendido', property: 'docRef', type: 'custom'},
        {
            label: 'Documento Solicitante', property: 'cPersDocumento', type: 'text', visible: false,
            cssClasses: ['font-bold'],
        },
        estiloEstadoRegistro({campoCerrado: 'bPedidoCerrado', campoAnulado: 'bPedidoAnuladoRegistro'}),
        {
            label: 'A', labelLista: '¿Aceptado?', property: 'Aceptado', type: 'icons', cssClasses: ['text-center'],
            iconsDef: {
                soloIcono: true,
                iconSize: '16px',
                items: [
                    {
                        icon: 'roundCheckCircle', contentCss: ' text-green-700', toolTip: 'ACEPTADO',
                        esVisible(row): boolean { return row['bPedidoAceptado'] == 1; }
                    },
                ]
            },
        },
        {label: 'Nombre Solicitante', property: 'cPersNombreLargo', type: 'text'},
        {label: 'Establecimiento', property: 'cEstablecimientoNombreLargo', type: 'text'},
        {label: 'Dependencia', property: 'cDepenNombre', type: 'text'},
        {label: 'Tarea', property: 'cTareaNombre', type: 'text', textArray: ['cTareaCodigo', '*-', 'cTareaNombre'], visible: false},
        {label: 'Observaciones', property: 'cPedidoObs', type: 'text', visible: false},
        {label: 'Nº<br>Detalles', property: 'iCantidad_Detalles', type: 'number', cssClasses: ['text-center']},
    ];
    dialogRef: MatDialogRef<any>;
    @ViewChild('dialogRegistro', {static: true}) dialogRegistroRef: TemplateRef<any>;
    @ViewChild('dialogRegistroPecosa', {static: true}) dialogRegistroPecosaRef: TemplateRef<any>;
    @ViewChild('detallePieDerecha', {static: true}) detallePieDerechaRef: TemplateRef<any>;

    _dataPrincipal = new BehaviorSubject<any>(null);
    dataPrincipal$ = this._dataPrincipal.asObservable();

    esPorCredencial = false;

    opcionesFiltroBusquedaActual = {};
    botonesMantenimiento: TablaMantenimientoSeccionBotones;

    botonesMenu;

    isLoading;
    datosRuta: JVSoftRouteData;
    fechaSoloLectura = true;


    constructor(
        private dialog: MatDialog,
        private queryService: QueryService,
        private fb: FormBuilder,
        private usuarioService: UsuarioService,
        public funcionesGeneralesService: FuncionesGeneralesService,
        private sessionService: SessionService,
        private pdfLogisticaService: PdfLogisticaService,
        private pdfAlmacenService: PdfAlmacenService,
        private route: ActivatedRoute,
        private filtroBusquedaService: FiltroBusquedaService,
    ) {
        this.columnsBandejaPedidos = establecerEstilosLogistica(this, this.columnsBandejaPedidos);
        this.route.data.pipe(untilDestroyed(this)).subscribe(dat => {
            this.datosRuta = dat;
            console.log(dat);
            this.esPedidoParaAlmacen = dat['esPedidoParaAlmacen'];
            console.warn(this.esPedidoParaAlmacen);
        });
        this.crearFormularios();

    }

    ngOnInit(): void {
        this.crearBotones();

        const perfilesPermitidos = [PERFILES_USUARIO.ABASTECIMIENTO, PERFILES_USUARIO.ALMACENERO];

        if (this.usuarioService.verificarPerfil(perfilesPermitidos) || this.usuarioService.esEquipoJVSoft() || this.esPedidoParaAlmacen) {
            establecerQuitarRequired(this.frmRegistro, ['iEstablecimientoId', 'iDepenId', 'iPersId'], ['iCredDepenId']);
            this.queryService.cargar(this, [
                // 'grl.dependencias',
                'log.establecimientos',
            ], { usuarioActual: 1 }).then();
        }

        else {
            this.esPorCredencial = true;
            this.frmFiltro.get('iYearId').valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
                console.log(val);
                if (val) {
                    this.opcionesFiltroBusquedaActual = {
                        iYearId: val,
                    };
                }
            });
            this.opcionesFiltro = [];
            establecerQuitarRequired(this.frmRegistro, ['iCredDepenId'], ['iEstablecimientoId', 'iDepenId', 'iPersId']);
            this.queryService.cargar(this, [
                'bud.credenciales_dependencias',
                'log.years_pedidos',
            ], {usuarioActual: 1}).then((dRet) => {
                if (dRet['bud.credenciales_dependencias'] && dRet['bud.credenciales_dependencias'].length == 1) {
                    this.frmRegistro.get('iCredDepenId').setValue(dRet['bud.credenciales_dependencias'][0]['iCredDepenId']);
                }
                if (dRet['log.years_pedidos']) {
                    if (!dRet['log.years_pedidos'][0]) {
                        this.dataServidor.log.years_pedidos = [
                            {iPedidoYear: new Date().getFullYear()},
                        ];
                    }
                    // console.log(dRet['log.years_pedidos'][0].iPedidoYear);
                    this.frmFiltro.get('iYearId').setValue(new Date().getFullYear());
                    this.cargarLista().then();
                }
                // this.cargarLista();
            });
        }


        this.frmFiltro.get('iTipoMovProdId').valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
            if (val) {
                this.queryService.cargar(this, ['log.motivos_movimientos_productos'], {
                    iTipoMovProdId: val,
                }).then();
            }
        });


        this.cargaInicial();
        // changeSelect(this, this.frmRegistro.get('iCredId'), 'seg.credenciales_entidades', ['iCredId', 'cPersNombreLargo']);
        changeSelect(this, this.frmRegistro.get('iDepenId'), 'grl.establecimientos_dependencias', ['cDepenNombre']);
        this.frmRegistro.get('iEstablecimientoId').valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
            if (val) {
                this.frmRegistro.get('iDepenId').reset();
                this.queryService.cargar(this, [
                    'grl.establecimientos_dependencias',
                ], {iEstablecimientoId: val}).then();
            }
        });


        changeSelect(this, this.frmRegistro.get('iTareaId'), 'bud.tareas', ['cTareaCodigo', 'cTareaNombre']);
    }
    crearBotones(){
        this.botonesMantenimiento = {
            pedidos: {
                crud: [
                    {tipo: 'editar', estaDeshabilitado: (item: any): boolean => !item || (item && this.esPorCredencial && item.bPedidoAceptado == 1)},
                    {tipo: 'eliminar', estaDeshabilitado: (item: any): boolean => !item || (item && this.esPorCredencial && item.bPedidoAceptado == 1)},
                ],
                izquierda: [

                    {
                        icono: 'twotoneAdd', tipo: 'nuevo_pedido', label: 'Nuevo Pedido',
                        class: 'text-white !bg-green', esIndependiente: true,
                        subItems: [
                            {
                                icono: 'twotoneAdd', tipo: 'nuevo_pedido_compra', label: 'Pedido Abastecimiento',
                                sinCondicion: true,
                            },
                            {icono: 'twotoneAdd', tipo: 'nuevo_pedido_almacen', label: 'Pedido Almacén', sinCondicion: true},
                        ],
                        esVisible: (item: any): boolean => {
                            return !this.esPedidoParaAlmacen;
                        },
                    },
                    // {icono: 'twotoneAdd', tipo: 'nuevo_pedido_compra', label: 'Pedido Abastecimiento', class: 'text-white !bg-green', sinCondicion: true},
                    // {icono: 'twotoneAdd', tipo: 'nuevo_pedido_almacen', label: 'Pedido Almacén', class: 'text-white !bg-green', sinCondicion: true},
                    // {tipo: 'nuevo_pedido_compra', label: 'Pedido de Compra'},
                    // {tipo: 'nuevo_pedido_almacen', label: 'Pedido de Almacen'},
                ],
                principal: [
                    // {icono: 'twotoneListAlt', tipo: 'detalle', class: 'text-white bg-cyan'},

                    {
                        icono: 'fa5sLock', tipo: 'cerrar',
                        esVisible: (item: any): boolean => {
                            return item && item.bPedidoCerrado != 1 && (!this.esPorCredencial || item.bPedidoAceptado != 1);
                        },
                    },
                    {
                        icono: 'fa5sLockOpen', tipo: 'abrir',
                        esVisible: (item: any): boolean => {
                            return !this.esPorCredencial && item && (item.bPedidoCerrado == 1);
                        },
                    },
                    {
                        icono: 'icCancel', tipo: 'anular', ignorarDerecho: true,
                        esVisible: (item: any): boolean => {
                            return !this.esPorCredencial;
                        },
                        /*estaDeshabilitado(item: any): boolean {
                            return (!item || item.bPedidoCerrado == 1 || item.bPedidoAnuladoRegistro == 1);
                        },*/
                    },
                    {
                        icono: 'roundCheckCircle', tipo: 'aceptar_pedido',
                        esVisible: (item: any): boolean => {
                            return !this.esPorCredencial;
                        },
                        estaDeshabilitado(item: any): boolean {
                            return (!item || item.bPedidoCerrado == 0 || item.bPedidoAnuladoRegistro == 1);
                        },
                    },
                    {
                        icono: 'roundRequestQuote', tipo: 'generar_', label: 'Generar',
                        subItems: [
                            {
                                icono: 'roundRequestQuote', tipo: 'generar_cotizacion', label: 'Cotización',
                                esVisible: (item: any): boolean => {
                                    return !this.esPorCredencial && !this.esPedidoParaAlmacen;
                                },
                                estaDeshabilitado(item: any): boolean {
                                    return !item || item.iDestinoPedidoId != 1 ||  item.iClasePedidoId == 2;
                                },
                            },
                            {
                                icono: 'roundShoppingCart', tipo: 'generar_compra', label: 'Orden',
                                esVisible: (item: any): boolean => {
                                    return !this.esPorCredencial && !this.esPedidoParaAlmacen;
                                },
                                estaDeshabilitado(item: any): boolean {
                                    return !item || item.iDestinoPedidoId != 1;
                                },
                            },
                            {
                                icono: 'roundLocalShipping', tipo: 'generar_pecosa', label: 'PECOSA',
                                esVisible: (item: any): boolean => {
                                    return !this.esPorCredencial;
                                },
                                estaDeshabilitado(item: any): boolean {
                                    return !item || item.iDestinoPedidoId != 2 ||  item.iClasePedidoId == 2;
                                },
                            },
                        ],
                    },
                ],
                derecha: [
                    {tipo: 'imprimir'},
                ],
                inferior: [
                    // {tipo: 'exportar_pdf'},
                ],


            },
        };

        if (this.btnSeleccionar) {
            this.botonesMantenimiento.pedidos = {
                principal: [
                    {icono: 'roundFactCheck', tipo: 'seleccionar_pedidos', label: 'Seleccionar', ignorarDerecho: true},
                ]
            };
        }
        if (this.esPedidoParaAlmacen) {
            this.opcionesFiltro[0] = {
                label: 'Periodo',
                params: [
                    {
                        control: 'select', campo: 'iDestinoPedidoId',
                        data: 'log.destinos_pedidos', val: '', dataNull: 'Todos', text: 'cDestinoPedidoNombre',
                        label: 'Atender', flex: '100px',
                    },
                    {
                        control: 'select', change: 'iMonthId', campo: 'iYearId',
                        data: 'grl.years', val: '*primero*', text: 'iYearId', label: 'Año', flex: '100px',
                    },
                    {
                        control: 'select', campo: 'iMonthId', campoId: 'iPedidoMonth', data: 'log.months_pedidos',
                        val: (new Date().getMonth() + 1).toString(), dataChangeReqParams: ['iYearId'],
                        text: 'cMonthNombre', label: 'Mes', flex: '100px',
                    },
                    { control: 'select', campo: 'iEstablecimientoId', data: 'grl.establecimientos', val: '', text: 'cEstablecimientoNombreLargo', label: 'Establecimiento', dataNull: 'Todos', flex: '200px' },
                    { control: 'select', campo: 'iDepenId', data: 'grl.dependencias', val: '', text: 'cDepenNombre', label: 'Dependencia', dataNull: 'Todos', flex: '200px' },
                    { control: 'autocomplete', campo: 'iTareaId',  data: 'bud.tareas', val: '', dataReqParams: {cCampoBusqueda: '%'}, text: ['cTareaCodigo', 'cTareaNombre'], label: 'Tarea', dataNull: 'Todos' },
                ],
            };
        }
    }

    cargaInicial() {
        const solicitudes = [
            'grl.years',
            'grl.months',
            // 'log.establecimientos',
            'log.tipos_productos',
            'bud.tareas',
        ];
        // if (!this.deBandejaPedidos) {
        //     solicitudes.push('log.credenciales_pedidos');
        // }
        this.queryService.cargar(this, solicitudes, {usuarioActual: 1, cCampoBusqueda: '%'}).then();
        // this.cargarDetallePedido();
    }

    crearFormularios() {
        this.frmFiltro = this.fb.group({
            iYearId: [''],
            prueba: ['', Validators.required],
            iDestinoPedidoId: [''],

            iTipoMovProdId: [''],
        });
        this.frmRegistro = this.fb.group({
            iDestinoPedidoId: [''],
            iPersId: ['', [Validators.required]],
            iEstablecimientoId: [''],
            iDepenId: [''],

            iTareaId: [''],
            iYearId: [''],
            iMonthId: [''],

            iCredDepenId: ['', Validators.required],
            iClasePedidoId: ['', Validators.required],
            iTipoProductoId: [''],

            dPedidoEmision: [new Date(), Validators.required],
            cPedidoReferencia: [''],
            cPedidoObs: [''],

            esPorCredencial: [''],

            iPedidoId: [''],
        });
        this.frmRegistroPecosa = this.fb.group({
            dSalidaEmision: ['', [Validators.required]],
            iMotMovProdId: ['', [Validators.required]],
            iDepenId: [''],
            cSalidaObs: [''],

            iPedidoId: [''],
        });
    }

    buscarDatos(event) {
        this.opcionesFiltroBusquedaActual = event;
        if (event.iYearId) {
            this.frmFiltro.get('iYearId').setValue(event.iYearId);
        }
        if (event.iDestinoPedidoId) {
            this.frmFiltro.get('iDestinoPedidoId').setValue(event.iDestinoPedidoId);
        }
        this.cargarLista().then();
    }

    cargarLista() {
        const paramsRequest = {
            ...this.opcionesFiltroBusquedaActual,
            ...this.frmFiltro.value,
        };
        let strReq = 'log.pedidos';
        if (this.esPedidoParaAlmacen) {
            strReq = 'log.pedidos_bienes';
            paramsRequest['esPedidoParaAlmacen'] = 'true';
        }
        return this.queryService.cargar(this, [strReq], paramsRequest).then((dReq) => {
            if (dReq && dReq[strReq]) {
                this.dataServidor.log.pedidos = dReq[strReq];
            }
            this._dataPrincipal.next(this.dataServidor.log.pedidos);
        });
    }

    cargarDestinosClasesPedidos(iDestinoPedidoId, seleccionarPrimero = true) {
        if (seleccionarPrimero) {
            this.frmRegistro.get('iClasePedidoId').reset();
        }
        this.dataServidor.log.destinos_clases_pedidos = null;
        return this.queryService.cargar(this, ['log.destinos_clases_pedidos'], {iDestinoPedidoId, esPorCredencial: this.esPorCredencial ? 1 : ''}).then(dRet => {
            if (seleccionarPrimero && dRet && dRet['log.destinos_clases_pedidos'] && dRet['log.destinos_clases_pedidos'][0]) {
                this.frmRegistro.get('iClasePedidoId').setValue(dRet['log.destinos_clases_pedidos'][0]['iClasePedidoId']);
            }
        });
    }

    opcMenu(v: OpcionSeleccionada) {
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            data: {},
        };
        let iDestinoPedidoId;
        switch (v.seccion) {
            case 'pedidos':
                dialogOpc.minWidth = '600px';
                dialogOpc.data.deBandejaPedidos = true;
                switch (v.tipo) {
                    case 'nuevo_pedido_compra':
                        const dataFiltroActual = this.filtroBusquedaService.getValue('pedidos').datosFiltro;
                        iDestinoPedidoId = 1; // ABASTECIMIENTO
                        this.cargarDestinosClasesPedidos(iDestinoPedidoId).then(() => {
                            this.fechaSoloLectura = true;
                            this.frmRegistro.reset({
                                dPedidoEmision: new Date(),
                                iYearId: new Date().getFullYear().toString(),
                                iMonthId: (new Date().getMonth() + 1).toString(),
                                // iClasePedidoId: this.frmRegistro.get('iClasePedidoId').value,
                                iClasePedidoId: dataFiltroActual['iClasePedidoId'] ?? '',
                                iTipoProductoId: dataFiltroActual['iTipoProductoId'] ?? '',
                                iDestinoPedidoId,
                                esPorCredencial: this.esPorCredencial ? 1 : '',
                                // esDeAbastecimiento: !formControlIsRequired(this.frmRegistro.get('iCredDepenId')),
                            });
                            this.dialogRef = this.dialog.open(this.dialogRegistroRef, dialogOpc);
                        });
                        break;
                    case 'nuevo_pedido_almacen':
                        iDestinoPedidoId = 2; // ALMACEN
                        this.cargarDestinosClasesPedidos(iDestinoPedidoId).then(() => {
                            this.fechaSoloLectura = true;
                            this.frmRegistro.reset({
                                dPedidoEmision: new Date(),
                                iYearId: new Date().getFullYear().toString(),
                                iMonthId: (new Date().getMonth() + 1).toString(),
                                // iClasePedidoId: this.frmRegistro.get('iClasePedidoId').value,
                                iClasePedidoId: dataFiltroActual['iClasePedidoId'] ?? '',
                                iTipoProductoId: 1, // BIENES
                                iDestinoPedidoId,
                                esPorCredencial: this.esPorCredencial ? 1 : '',
                                // esDeAbastecimiento: !formControlIsRequired(this.frmRegistro.get('iCredDepenId')),
                            });
                            this.dialogRef = this.dialog.open(this.dialogRegistroRef, dialogOpc);
                        });
                        break;
                    case 'editar':
                        this.fechaSoloLectura = true;
                        dialogOpc.minWidth = '90vw';
                        dialogOpc.data = {
                            iPedidoId: this.seleccionados.pedidos.iPedidoId,
                            iDestinoPedidoId: this.seleccionados.pedidos.iDestinoPedidoId,
                            templateDetallePieDerecho: this.detallePieDerechaRef,
                            esDeAbastecimiento: !formControlIsRequired(this.frmRegistro.get('iCredDepenId')),
                        };
                        this.dialogRef = this.dialog.open(LogisticaPartialsPedidosDetallesComponent, dialogOpc);
                        this.dialogRef.componentInstance.opcMenuEmitter.pipe(untilDestroyed(this))
                            .subscribe((vRec: OpcionSeleccionada) => {
                                this.opcMenu(vRec);
                            });
                        break;
                    case 'editar_item':
                        if (this.dialogRef) {
                            this.dialogRef.close();
                        }
                        objectPropertiesDate(this.seleccionados.pedidos);
                        this.frmRegistro.patchValue({
                            ...this.seleccionados.pedidos,
                            ...{
                                esPorCredencial: this.esPorCredencial ? 1 : '',
                            },
                        });
                        this.cargarDestinosClasesPedidos(this.frmRegistro.get('iDestinoPedidoId').value, false).then(() => {
                            this.dialogRef = this.dialog.open(this.dialogRegistroRef, dialogOpc);
                        });
                        break;
                    case 'abrir':
                    case 'cerrar':
                        this.queryService.guardarDatosAsync('bPedidoCerrado_pedidos', {
                            iPedidoId: this.seleccionados.pedidos.iPedidoId,
                            bPedidoCerrado: this.seleccionados.pedidos.bPedidoCerrado == 1 ? 0 : 1,
                        }, 'log').then((dataGuardado) => {
                            this.accionesExtras(v, {
                                cargar: {
                                    data: dataGuardado,
                                },
                            });
                        });
                        break;
                    case 'aceptar_pedido':
                        this.queryService.guardarDatosAsync('UPD_aceptado_pedidos', {
                            iPedidoId: this.seleccionados.pedidos.iPedidoId,
                        }, 'log').then((dataGuardado) => {
                            this.accionesExtras(v, {
                                cargar: {
                                    data: dataGuardado,
                                },
                            });
                        });
                        break;
                    case 'anular':

                        this.usuarioService.habilitarAccion({
                            permiso: 'anular',
                            datosRuta: this.datosRuta,
                            titulo: 'Anular Pedido <strong>' + [this.seleccionados.pedidos.iPedidoNumero].join(' ') + '</strong>',
                            accion: 'Anular'
                        }).then(val => {
                            if (val.isConfirmed) {
                                this.queryService.guardarDatosAsync('anulado_pedidos', {
                                    iPedidoId: this.seleccionados.pedidos.iPedidoId,
                                    cPedidoAnuladoMotivo: val.value,
                                }, 'log').then((dataGuardado) => {
                                    this.accionesExtras(v, {
                                        cargar: {
                                            data: dataGuardado,
                                        },
                                    });
                                });
                            }
                        });
                        break;
                    case 'editar_fecha':
                        if (!this.fechaSoloLectura){
                            return 1;
                        }
                        this.usuarioService.habilitarAccion({
                            permiso: 'editar_fecha',
                            datosRuta: this.datosRuta,
                            titulo: 'Editar Fecha  de Pedido',
                            tipoMensaje: 'mensajeAlerta',
                            mostrarMensajeCofirmacion: false,
                            // accion: 'Anular'
                        }).then(val => {
                            if (val.isConfirmed) {
                                this.fechaSoloLectura = false;
                            }
                        });
                        break;
                    case 'generar_compra':
                        if (this.seleccionados.pedidos.bPedidoCerrado == 1) {
                            this.queryService.guardarDatosAsync('mantenimiento#compras', {iPedidoId: this.seleccionados.pedidos.iPedidoId}, 'log')
                                .then(() => {

                                    this.accionesExtras(v, {
                                        cargar: {
                                            data: {
                                                data: {
                                                    iPedidoId: this.seleccionados.pedidos.iPedidoId,
                                                },
                                            },
                                        },
                                    });
                                });
                        }
                        else {
                            // mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la compra').then();
                            this.funcionesGeneralesService.mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la compra');
                            // this.funcionesGeneralesService.mensajeTimer('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la compra', 3000);
                        }
                        break;
                    case 'generar_cotizacion':
                        if (this.seleccionados.pedidos.bPedidoCerrado == 1) {
                            this.queryService.guardarDatosAsync('mantenimiento#cotizaciones', {iPedidoId: this.seleccionados.pedidos.iPedidoId}, 'log')
                                .then(() => {

                                    this.accionesExtras(v, {
                                        cargar: {
                                            data: {
                                                data: {
                                                    iPedidoId: this.seleccionados.pedidos.iPedidoId,
                                                },
                                            },
                                        },
                                    });
                                });
                        }
                        else {
                            // mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la compra').then();
                            this.funcionesGeneralesService.mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la cotización');
                            // this.funcionesGeneralesService.mensajeTimer('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la cotización', 3000);
                        }
                        break;
                    case 'generar_pecosa':
                        if (this.seleccionados.pedidos.bPedidoCerrado == 1) {

                            this.frmFiltro.get('iTipoMovProdId').setValue(5);
                            this.frmRegistroPecosa.reset({
                                iPedidoId: this.seleccionados.pedidos.iPedidoId,
                                dSalidaEmision: new Date(),
                            });

                            this.dialogRef = this.dialog.open(this.dialogRegistroPecosaRef, dialogOpc);
                        }
                        else {
                            // mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la compra').then();
                            this.funcionesGeneralesService.mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la PECOSA');
                            // this.funcionesGeneralesService.mensajeTimer('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la cotización', 3000);
                        }
                        break;
                    case 'guardar_pecosa':
                        if (this.seleccionados.pedidos.bPedidoCerrado == 1) {
                            this.queryService.guardarDatosAsync('pecosa', this.frmRegistroPecosa.value, 'log')
                                .then((dataGuardado) => {
                                    if (dataGuardado) {
                                        this.accionesExtras(v, {
                                            cargar: {
                                                data: {
                                                    data: {
                                                        iPedidoId: this.seleccionados.pedidos.iPedidoId,
                                                    },
                                                },
                                            },
                                        });
                                    }
                                });
                        }
                        else {
                            // mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la compra').then();
                            this.funcionesGeneralesService.mensajeToast('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la PECOSA');
                            // this.funcionesGeneralesService.mensajeTimer('error', 'Pedido Abierto', 'Cierre el pedido antes de generar la cotización', 3000);
                        }
                        break;
                    case 'imprimir':
                        this.pdfLogisticaService.pedido(this.seleccionados.pedidos.iPedidoId);
                        break;
                    case 'recargar':
                        this.accionesExtras(v, {
                            dialogRef: undefined,
                            cargar: {
                                data: {
                                    data: {
                                        iPedidoId: this.seleccionados.pedidos.iPedidoId,
                                    },
                                },
                            },
                        });
                        break;
                    case 'seleccionar_pedidos':
                        this.exportarData.emit(v.item);
                        break;
                    default:
                        this.accionesExtras(v);
                        break;
                }
                break;
        }
    }

    accionesExtras(v: OpcionSeleccionada, datosEmitir = {}) {
        this.queryService.parteComunOpcionesMantenimiento2(this, v, this.frmRegistro, {
            ...{
                coleccion: 'log.pedidos',
                idxTabla: 'iPedidoId',
                strDataObservable: '_dataPrincipal',
                prefix: 'log',
                dialogRef: this.dialogRef,
            },
            ...datosEmitir,
        }).then(dataRetornado => {
            console.log(dataRetornado);
            if (dataRetornado && dataRetornado[0] && v && v.datosExtra && v.datosExtra.cargarDetalles) {
                this.opcMenu({seccion: 'pedidos', tipo: 'editar'});
            }
        }).catch(error => { console.log(error); });
    }

}
