import {AfterContentInit, ChangeDetectorRef, Component, Input, Optional, QueryList, ViewChildren} from '@angular/core';
import {MatColumnDef, MatTable} from '@angular/material/table';
import {FormulariosGlobalesService} from '@JVSoft/services/formularios-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';

@Component({
  selector: 'general-mat-table-default-columns-defs2',
  templateUrl: './mat-table-default-columns-defs2.component.html',
  styleUrls: ['./mat-table-default-columns-defs2.component.scss']
})
export class MatTableDefaultColumnsDefs2Component implements AfterContentInit {

	@Input() objThis;
	@Input() nombreColeccion;
	@Input() colDetalle: ColumnaTabla<any>[];

	@ViewChildren(MatColumnDef) columnDef: QueryList<MatColumnDef>;

	constructor(
		@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef,

		public formulariosGlobalesService: FormulariosGlobalesService,
	) { }

	ngAfterContentInit() {
		if (this.table) {
			this.cdRef.detectChanges();
			this.columnDef.forEach(refCol => {
				this.table.addColumnDef(refCol);
			});
		}
	}

	trackByProperty<T>(index: number, column: ColumnaTabla<T>) {
		return column.property;
	}

	verTexto(row, campo, glue= ' ') {
		if (typeof campo == 'string') {
			return row[campo];
		}
		// console.log('CARGAR TEXTO');
		let strFinal = '';
		const arrFinal = [];
		campo.forEach(c => {
			if (c.charAt(0) == '*') {
				arrFinal.push(c.replace('*', ''));
			} else {
				arrFinal.push((row[c] ?? '').toString().trim());
			}
		});
		strFinal = arrFinal.join(glue);
		return strFinal;
	}

	camposNulos(row, campos: string[]) {
		let rpta = false;
		campos.forEach(campo => {
			if (!rpta && !row[campo]) {
				rpta = true;
			}
		});
		return rpta;
	}

	accionClick(column, item, event) {
		if (column.click) {
			column.click(item);
			event.stopPropagation();
		}
		return false;
	}

}
