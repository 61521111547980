import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PrincipalComponent} from './principal/principal.component';
import {LogisticaBandejaPedidosComponent} from '@logistica/logistica-bandeja-pedidos/logistica-bandeja-pedidos.component';
import {DerechosResolverGuard} from '../general/guards/derechos-resolver-guard.service';
import {DashboardComponent} from './principal/dashboard/dashboard.component';
import {AccesosGuard} from '../guards/accesos.guard';
import {JVSoftRoutes} from '../interfaces/importantes';
import {PrincipalLayoutComponent} from "../layouts/principal-layout/principal-layout.component";
import {TramiteLayoutComponent} from "../layouts/tramite-layout/tramite-layout.component";

const routes: JVSoftRoutes = [
	// { path: '', redirectTo: '/tramite/bandeja', pathMatch: 'full'},
	{
		path: '',
		component: PrincipalLayoutComponent,
		data: {
			// iMenuId: 2008,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{
				path: 'dashboard',
				component: DashboardComponent,
			},
			{
				path: 'trabajador',
				children: [
					{ path: '', redirectTo: 'dashboard', pathMatch: 'full'},
					{
						path: 'pedidos',
						component: LogisticaBandejaPedidosComponent,
						data: {
							iMenuId: 2010,
						},
						resolve: { derechos: DerechosResolverGuard, }
					}
				]
			},
			/*{
				component: PrincipalComponent,
				path: 'logistica',
				loadChildren: () => import('./logistica/logistica.module').then(m => m.LogisticaModule),
			},
			{
				component: PrincipalComponent,
				path: 'mantenimiento',
				data: {
					iMenuId: 1,
				},
				resolve: { derechos: DerechosResolverGuard, },
				loadChildren: () => import('./mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule),
			},*/
		],
	},
	{
		path: 'general',
		component: PrincipalLayoutComponent,
		data: {
			// iMenuId: 2008,
		},
		canActivateChild: [AccesosGuard],
		// resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./general/general.module').then(m => m.GeneralModule),
			}
		],
	},
	{
		path: 'logistica',
		component: PrincipalLayoutComponent,
		data: {
			titulo: 'Logística',
			iMenuId: 8,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./logistica/logistica.module').then(m => m.LogisticaModule),
			}
		],
	},
	{
		path: 'tesoreria',
		component: PrincipalLayoutComponent,
		data: {
			titulo: 'Tesorería',
			// iMenuId: 8,
		},
        canActivateChild: [AccesosGuard],
        resolve: { derechos: DerechosResolverGuard, },
        children: [
            {
                component: PrincipalComponent,
                path: '',
                loadChildren: () => import('./tesoreria/tesoreria.module').then(m => m.TesoreriaModule),
            }
        ],
	},
	{
		path: 'asistencia',
		component: PrincipalLayoutComponent,
		data: {
			// iMenuId: 2008,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				path: '',
				loadChildren: () => import('./asistencia/asistencia.module').then(m => m.AsistenciaModule),
			}
		],
	},
	{
		path: 'recursos-humanos',
		component: PrincipalLayoutComponent,
		data: {
			titulo: 'RRHH',
			// iMenuId: 2008,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./recursos-humanos/recursos-humanos.module').then(m => m.RecursosHumanosModule),
			}
		],
	},
	{
		path: 'patrimonio',
		component: PrincipalLayoutComponent,
		data: {
			// iMenuId: 2008,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./patrimonio/patrimonio.module').then(m => m.PatrimonioModule),
			}
		],
	},
	{
		path: 'tramite',
		component: TramiteLayoutComponent,
		data: {
			titulo: 'Trámite',
			ignorarDerechos: true,
		},
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./tramite/tramite.module').then(m => m.TramiteModule),
			}
		],
	},
	{
		path: 'sisma',
		component: PrincipalLayoutComponent,
		data: {
			titulo: 'SISMA',
			iMenuId: 53,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./sisma/sisma.module').then(m => m.SismaModule),
			}
		],
	},
	{
		path: 'presupuesto',
		component: PrincipalLayoutComponent,
		data: {
			// titulo: 'SISMA',
			iMenuId: 92,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./presupuesto/presupuesto.module').then(m => m.PresupuestoModule),
			}
		],
	},
	{
		path: 'seguridad',
		component: PrincipalLayoutComponent,
		data: {
			// titulo: 'SISMA',
			// iMenuId: 92,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./seguridad/seguridad.module').then(m => m.SeguridadModule),
			}
		],
	},
	{
		path: 'almacen',
		component: PrincipalLayoutComponent,
		data: {
			// titulo: 'SISMA',
			// iMenuId: 92,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./almacen/almacen.module').then(m => m.AlmacenModule),
			}
		],
	},
	{
		path: 'catastro',
		component: PrincipalLayoutComponent,
		data: {
			// titulo: 'SISMA',
			// iMenuId: 92,
		},
		canActivateChild: [AccesosGuard],
		resolve: { derechos: DerechosResolverGuard, },
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./catastro/catastro.module').then(m => m.CatastroModule),
			}
		],
	},
	{
		path: 'caja',
		component: TramiteLayoutComponent,
		data: {
			ignorarDerechos: true,
		},
		children: [
			{
				component: PrincipalComponent,
				path: '',
				loadChildren: () => import('./caja/caja.module').then(m => m.CajaModule),
			}
		],
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulosRoutingModule { }
