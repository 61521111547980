import {Injectable} from '@angular/core';
import {PdfService} from '@servicios/pdf.service';
import {kardex, parteDiario, pedidoVale, reporteVales, vale, valorizacionParteDiario, valorizacionParteDiarioMaquinaAmarrila, valorizacionVale} from '@pdf/pdf-almacen/general';

export * from './general';
// export * as StaCat from './santa-catalina';
// export { SantaCatalina };

@Injectable({
    providedIn: 'root',
})
export class PdfAlmacenService extends PdfService {
    // A4 VERTICAL - 595 PUNTOS ANCHO

    vale(dataRec) {
        vale(this, dataRec);
    }
    pedidoVale(dataRec) {
        pedidoVale(this, dataRec);
    }
    valorizacionVale(dataRec) {
        valorizacionVale(this, dataRec);
    }
    parteDiario(dataRec) {
        parteDiario(this, dataRec);
    }
    valorizacionParteDiario(dataRec,eficiencia) {
        valorizacionParteDiario(this, dataRec, eficiencia);
    }
    valorizacionParteDiarioMaquinaAmarrila(dataRec,eficiencia) {
        valorizacionParteDiarioMaquinaAmarrila(this, dataRec, eficiencia);
    }
    reporteVales(dataRec) {
        reporteVales(this, dataRec,'modal',null);
    }
    reporteValesEmbebido(dataRec, callback = null) {
        reporteVales(this, dataRec, 'otro', callback);
    }
    kardex(dataRec) {
        kardex(this, dataRec);
    }


}
