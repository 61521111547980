import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {jwtToken} from '@JVSoft/functions/local-storage';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
        private router: Router
    ) { }

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const varJwtToken = jwtToken();

		if (varJwtToken) {
			req = req.clone({
				setHeaders: {
					authorization: `Bearer ${varJwtToken}`,
				},
			});
		}

		return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {

                if (err.status === 401) {
                    // this.router.navigateByUrl('/login');
                    // this.router.navigate(['/sesion/login']);
                }

                return throwError( err );

            })
        );
	}
}
