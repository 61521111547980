import {Injectable} from '@angular/core';
import {VexConfigMod} from '@vexMod/config/vex-config.interface';
import {BehaviorSubject} from 'rxjs';
import {environment} from '@ENV';
import {desencriptar, encriptar, getLocalStorage, localStorageKeys, setLocalStorage} from '@JVSoft/functions';
import {ApiData} from '../../environments/environment.template';


export interface ConfiguracionWeb {
  configuracion: VexConfigMod;
  estilo: 'default'|'dark'|'light';
  css: CSSConfig;
  images: ImagesConfig;
  impresion?: {
    configuracion?: ImpresionConfiguracion;
  };
  tramite?: TramiteConfig;
}
interface CSSConfig {
  '--color-primary': string;
  '--color-primary-contrast': string;

  '--sidenav-item-color-active'?: string;
  '--sidenav-item-icon-color-active'?: string;
  '--sidenav-item-dropdown-color-active'?: string;

  '--color-primary-table-selection'?: string;
  '--color-primary-contrast-table-selection'?: string;

  '--tabla-mantenimiento-seleccion-fondo'?: string;
  '--tabla-mantenimiento-seleccion-texto'?: string;

  '--color-primary-selected-tab'?: string;
  '--material-color-primary-contrast'?: string;
  [key: string]: string;
}
interface ImagesConfig {
  favicon: string;
  logoImpresion?: string;
  logoLogin?: string;
  backgroundLogin?: string;
}
interface ImpresionConfiguracion {
  footerEntidad?: string;
  footerEntidadWeb?: string;
  sinMarcaEmpresa?: boolean;
}
interface TramiteConfig {
  tipoAsuntoDefault: string | null;
  mesaPartes: {
    modoMantenimiento?: boolean;
    verificarPorEmail?: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionWebService {
  CONST_BACKEND_API = '#@#backendApi#@#';

  _apiData = new BehaviorSubject<ApiData>(null);
  get apiData(): ApiData {
    return this._apiData.getValue();
  };
  set apiData(val){
    this._apiData.next(val);
  }
  get lsApiKey() {
    if (getLocalStorage(localStorageKeys.apiDataKey)) {
      const keyApi = desencriptar(getLocalStorage(localStorageKeys.apiDataKey));
      console.warn(keyApi);
      return Object.keys(environment.api).find(item => item == keyApi);
    }
    return null;
  }

  _configuracion = new BehaviorSubject<ConfiguracionWeb>(null);
  configuracion$ = this._configuracion.asObservable();
  constructor() {
    if (!!!this.apiData) {
      this.setApiDataProximo();
    }
  }

  set configuracion(val: ConfiguracionWeb) {
    this._configuracion.next(val);
  }
  get configuracion(): ConfiguracionWeb {
    return this._configuracion.getValue();
  }

  setApiDataProximo() {
    if (environment.api) {
      const listaKeys = Object.keys(environment.api);
      let strKey;
      const idxAct = Object.values(environment.api).findIndex(api => api.backendApi == this.apiData?.backendApi);
      if (idxAct < 0){
        if (this.lsApiKey) {
          this.apiData = environment.api[this.lsApiKey];
        }
        else {
          strKey = listaKeys[0];
          this.apiData = environment.api[strKey];
        }
      }
      else {
        strKey = listaKeys[idxAct + 1];
        if (environment.api[strKey]){
          this.apiData = environment.api[strKey];
          setLocalStorage(localStorageKeys.apiDataKey, encriptar(strKey));
        }
      }
    }
  }
}
