import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class FormulariosGlobalesService {

	public modelosChk = {};
	public nombreColeccion = '';

	public chkTodos = {};
	public frmSeleccionados: FormGroup;

	public get frmSeleccionadosControl() {
		return this.frmSeleccionados.controls;
	}
	public frmSeleccionadosTipoFG(tipo) {
		return this.frmSeleccionadosControl[tipo] as FormGroup;
	}
	public frmSeleccionadosTipoFGControl(tipo) {
		if (!this.frmSeleccionadosControl[tipo]) {
			return false;
		}
		// @ts-ignore
		return this.frmSeleccionadosControl[tipo].controls;
	}

	constructor(
		private formBuilder: FormBuilder,
	) {
		this.frmSeleccionados = this.formBuilder.group({});
	}

	frmSeleccionNivelesControl(tipo, niveles: string[]) {
		let retorno = this.frmSeleccionadosControl;
		niveles.forEach((val, idx) => {
			retorno = retorno.controls[val];
		});
		return retorno;
	}

	crearFormularioSeleccion(tipo) {
		this.chkTodos[tipo] = false;
		this.frmSeleccionados.addControl(tipo, new FormGroup({}));
	}

	generarIdSeleccion(dat, idObjLista, idCheckBoxSeparador = '') {
		if (Array.isArray(idObjLista)) {
			const idControlCheckBoxArray = [];
			let idControlCheckBox = '';
			idObjLista.forEach(prop => {
				idControlCheckBoxArray.push(dat[prop]);
				idControlCheckBox += dat[prop];
			});
			return idControlCheckBoxArray.join(idCheckBoxSeparador);
		}

		return dat[idObjLista];
	}

	agregarControles(tipo, objLista, idObjLista, limpiar = true, idCheckBoxSeparador = '') {
		if (limpiar) {
			this.frmSeleccionados.removeControl(tipo);
			this.crearFormularioSeleccion(tipo);
		}
		objLista.filter((dat, idx) => {
			const idSeleccion = this.generarIdSeleccion(dat, idObjLista, idCheckBoxSeparador);
			// @ts-ignore
			this.frmSeleccionadosControl[tipo].addControl(idSeleccion, new FormControl(false, []));
		});
	}
	eliminarControles(tipo) {
		this.frmSeleccionados.removeControl(tipo);
		this.crearFormularioSeleccion(tipo);
	}

	// CONTROLES PARA LISTA DE CHECKS
	actualizarTodosCompletos(tipo) {

		this.chkTodos[tipo] = Object.keys(this.frmSeleccionadosTipoFGControl(tipo)).every(key => {
			const abstractControl = this.frmSeleccionadosTipoFG(tipo).get(key);
			return abstractControl.value;
		});
	}
	cantidadActivos(tipo) {
		if (!this.frmSeleccionadosTipoFGControl(tipo)) {
			return 0;
		}
		return Object.keys(this.frmSeleccionadosTipoFGControl(tipo)).filter(key => {
			const abstractControl = this.frmSeleccionadosTipoFG(tipo).get(key);
			return abstractControl.value;
		}).length;
	}
	algunosActivos(tipo) {
		return this.cantidadActivos(tipo) > 0 && !this.chkTodos[tipo];
	}

	establecerTodos(tipo, activo: boolean, dataFiltrado: {dataSource: any, idControlSelec: string | string[], idControlSelecSeparador: string} | null = null) {
		const arrIdxFiltrado = [];
		if (dataFiltrado) {
			dataFiltrado.dataSource.filter(dat => {
				const idSeleccion = this.generarIdSeleccion(dat, dataFiltrado.idControlSelec, dataFiltrado.idControlSelecSeparador);
				arrIdxFiltrado.push(idSeleccion);
			});
		}
		this.chkTodos[tipo] = activo;
		Object.keys(this.frmSeleccionadosTipoFGControl(tipo)).filter(key => {
			const abstractControl = this.frmSeleccionadosTipoFG(tipo).get(key);
			if (dataFiltrado) {
				if (arrIdxFiltrado.includes(key)) {
					abstractControl.setValue(activo);
				}
			}
			else {
				abstractControl.setValue(activo);
			}
		});
	}
	generarLista(tipo, tipoRetorno: 'array'|'object'|null = null): string|object|any[] {
		const strLista = [];
		const objLista = {};
		Object.keys(this.frmSeleccionadosTipoFGControl(tipo)).filter(key => {
			const abstractControl = this.frmSeleccionadosTipoFG(tipo).get(key);
			if (abstractControl.value) {
				strLista.push(key);
				objLista[key] = abstractControl.value;
			}
		});
		if (tipoRetorno == 'array') {
			return strLista;
		}
		if (tipoRetorno == 'object') {
			return objLista;
		}
		return strLista.join(',');
	}


	// CON MODELO
	modelCrear(tipo) {
		this.nombreColeccion = tipo;
		this.modelosChk[this.nombreColeccion] = {};
	}

	modelAgregarControl(lista, idLista, limpiar = true) {
		if (limpiar) {
			this.modelCrear(this.nombreColeccion);
		}
		lista.filter(dat => {
			this.modelosChk[this.nombreColeccion][dat[idLista]] = false;
		});
	}

	modelVerificar(idx) {
		return this.modelosChk[this.nombreColeccion][idx];
	}

	modelEstablecer(idx, activo: boolean) {
		this.modelosChk[this.nombreColeccion][idx] = activo;
	}

	modelEstablecerTodos(activo: boolean) {
		this.chkTodos[this.nombreColeccion] = activo;
		this.modelosChk[this.nombreColeccion].filter( (val, idx) => {
			this.modelosChk[this.nombreColeccion][idx] = activo;
		});
	}
}

export class ModeloCheck {
	public modelosChk: { [key: string]: boolean | any } = {}; // Usar any para valores dinámicos

	// Agrega controles al objeto modelosChk
	agregarControles(lista: any[], idLista: string, limpiar = true, campoValor: string | null = null, tipo: string = 'text') {
		if (limpiar) {
			this.modelosChk = {};
		}
		lista.forEach(dat => {
			if(tipo == 'number'){
				this.modelosChk[dat[idLista]] = campoValor ? (dat[campoValor] * 1) : false;
			}else {
				this.modelosChk[dat[idLista]] = campoValor ? dat[campoValor] : false;
			}
		});
	}

	// Establece el estado de un índice
	setState(idx: string, state: boolean) {
		this.modelosChk[idx] = state;
	}

	// Obtiene el estado de un índice
	getState(idx: string): boolean | any {
		return this.modelosChk[idx];
	}

	// Establece el estado de todos los índices
	modelEstablecerTodos(activo: boolean) {
		Object.keys(this.modelosChk).forEach(idx => {
			this.modelosChk[idx] = activo;
		});
	}

	// Genera una lista basada en el estado de los índices
	generarLista(tipoRetorno: 'array' | 'object' | null = null): string | object | any[] {
		const strLista: string[] = [];
		const objLista: { [key: string]: boolean | any } = {};
		Object.keys(this.modelosChk).forEach(key => {
			if (this.modelosChk[key]) {
				strLista.push(key);
				objLista[key] = this.modelosChk[key];
			}
		});
		if (tipoRetorno === 'array') {
			return strLista;
		}
		if (tipoRetorno === 'object') {
			return objLista;
		}
		return strLista.join(',');
	}
	generarFormGroupFromModelosChk(tipo?): FormGroup {
		const formGroupObj: { [key: string]: FormControl } = {};
		let validadores = []
		if(tipo == 'number'){
			validadores = [
				Validators.required,
				Validators.min(1)
			]		
		} 
		Object.keys(this.modelosChk).forEach(key => {
			formGroupObj[key] = new FormControl(this.modelosChk[key],validadores);
		});
		return new FormGroup(formGroupObj);

		// return this.formBuilder.group(formGroupObj);
	}
}
