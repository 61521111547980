<form
	globalDialogFlotante (ngSubmit)="save()" [formGroup]="frmRegistro"
	btnCerrar="CANCELAR" [cssClases]="{btnGuardar: 'text-primary-contrast'}"
>

	<ng-container tituloImagen *ngIf="frmRegistro.get('cPersFotografia').value">
		<img [src]="servidorService.photoThumbURL + frmRegistro.get('iPersId').value + '?ancho=150&alto=150'" class="avatar mr-5">
	</ng-container>
	<ng-container titulo>
		<span *ngIf="frmRegistro.get('cPersNombre').value || frmRegistro.get('cPersPaterno').value"
			>{{ tituloElemento }} :: {{ frmRegistro.get('cPersNombre').value + ' ' + frmRegistro.get('cPersPaterno').value }}</span>
		<span *ngIf="!frmRegistro.get('cPersNombre').value && !frmRegistro.get('cPersPaterno').value"
			>Registro de Personas</span>
			<!-- >Registro de {{ tituloElemento }}</span> -->
	</ng-container>
	<ng-container selectTitulo>

		<button (click)="labelSelect.open()"
				class="h-6"
				fxLayoutAlign="space-around center"
				fxFlex="none"
				mat-button
				matTooltip="Año"
				type="button"

		>
			<mat-select #labelSelect="matSelect"
						formControlName="iTipoPersId"
						class="invisible w-0 h-0 text-sm"
						fxFlex="0 1 0px"
						(selectionChange)="cambiarTipoPersona()"
			>
				<mat-option *ngFor="let label of dataServidor.tipos_personas" [value]="label.iTipoPersId" style="height: 1.5rem !important; font-size: 0.75rem !important;">
					<div fxLayoutAlign="start center">
						<mat-icon *ngIf="label.iTipoPersId == 1" size="18px" svgIcon="icPeople"></mat-icon>
						<mat-icon *ngIf="label.iTipoPersId == 2" size="18px" svgIcon="buildingMultiple24Regular"></mat-icon>
						<span>{{ label.cTipoPersNombre }}</span>
					</div>
				</mat-option>
			</mat-select>
			<ng-container *ngIf="this.datosDialogRecibidos.persona?.bModificaTipoPersona">
				<div fxLayoutAlign="start center">
					<span class="font-bold">Tipo: </span>&nbsp;
					<span class="text-sm italic">{{ (dataServidor.tipos_personas | dataEnLista: 'iTipoPersId': frmRegistro.get('iTipoPersId').value)?.cTipoPersNombre }}</span>
					<mat-icon fxLayoutAlign="space-around center" size="20px" svgIcon="fa5sCaretDown"></mat-icon>
				</div>
			</ng-container>
		</button>




		<!--<button (click)="labelSelect.open()"
				class="h-9 w-9"
				color="primary"
				fxLayoutAlign="space-around center"
				fxFlex="none"
				mat-button
				matTooltip="Tipo Persona"
				type="button"

		>
			<mat-select #labelSelect="matSelect"
						formControlName="iTipoPersId"
						class="invisible w-0 h-0 text-sm"
						fxFlex="0 1 0px"
						(selectionChange)="cambiarTipoPersona()"
			>
				<mat-option *ngFor="let label of dataServidor.tipos_personas" [value]="label.iTipoPersId" style="height: 1.5rem !important; font-size: 0.75rem !important;">
					<div fxLayoutAlign="start center">
						<mat-icon *ngIf="label.iTipoPersId == 1" size="18px" svgIcon="icPeople"></mat-icon>
						<mat-icon *ngIf="label.iTipoPersId == 2" size="18px" svgIcon="buildingMultiple24Regular"></mat-icon>
						<span>{{ label.cTipoPersNombre }}</span>
					</div>

				</mat-option>
			</mat-select>
			<ng-container [ngSwitch]="frmRegistro.get('iTipoPersId').value">
				<small>
					<mat-icon *ngSwitchCase="'1'" fxLayoutAlign="space-around center" size="20px" svgIcon="icPeople"></mat-icon>aaa
					<mat-icon *ngSwitchCase="'2'" fxLayoutAlign="space-around center" size="20px" svgIcon="buildingMultiple24Regular"></mat-icon>
					<span *ngSwitchDefault></span>
				</small>
			</ng-container>
		</button>-->
	</ng-container>
	<div cuerpo fxLayout="column" class="pt-2">

        <!--
            <pre class="mt-2" fxLayout="column" fxLayoutGap="8px">
                {{ frmRegistro.value | json }}
            </pre>
        -->
        <!-- <fieldset  class="border p-2 mb-2"> -->
            <!-- <legend class="w-auto m-0">Información</legend> -->
            <div class="grid grid-cols-2 gap-2">
                <mat-form-field>
                    <mat-label>Tipo de Identificación</mat-label>
                    <mat-select formControlName="iTipoIdentId" (selectionChange)="cambiarTipoIdentificacion()">
                        <mat-option [value]="item.iTipoIdentId" *ngFor="let item of dataServidor.tipos_Identificaciones">{{ item.cTipoIdentSigla }}</mat-option>
                    </mat-select>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('iTipoIdentId')) }}</mat-error>
                </mat-form-field>
                <!-- <general-fc-buscar-persona fxFlex="auto" label="Persona" placeholder="DNI o Nombre" [tipoPersona]="1" formControlName="iPersId" [incluirProveedorRemoto]="true"></general-fc-buscar-persona> -->
                <mat-form-field>
					<mat-label>{{dataCompPersona.labelBusquedaPersona}}</mat-label>
					<general-mat-buscar-persona placeholder="{{dataCompPersona.placeholderBusquedaPersona}}"
						[iTipoIdentId]="dataCompPersona.tipoPersonaBusquedaPersona"
						[incluirProveedorRemoto]="true"
						(dataSeleccionado)="datosPersona($event)"
						formControlName="iPersId"
						*ngIf="iPersIdVisible"
					></general-mat-buscar-persona>
				</mat-form-field>
                <!-- <general-fc-buscar-persona label="{{dataCompPersona.labelBusquedaPersona}}" placeholder="{{dataCompPersona.placeholderBusquedaPersona}}"
                    [iTipoIdentId]="dataCompPersona.tipoPersonaBusquedaPersona"
                    [incluirProveedorRemoto]="true"
                    (dataSeleccionado)="datosPersona($event)"
                    formControlName="iPersId"
                    *ngIf="iPersIdVisible"
                ></general-fc-buscar-persona> -->

                <mat-form-field *ngIf="cPersDocumentoVisible">
                    <mat-label>Nº Documento</mat-label>
                    <input formControlName="cPersDocumento" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersDocumento')) }}</mat-error>
                </mat-form-field>

            </div>

            <div class="grid grid-cols-3 gap-2" *ngIf="frmRegistro.get('iTipoPersId').value == 1">
                <mat-form-field>
                    <mat-label>Nombres</mat-label>
                    <input cdkFocusInitial formControlName="cPersNombre" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersNombre')) }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Apellido Paterno</mat-label>
                    <input formControlName="cPersPaterno" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersPaterno')) }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Apellido Materno</mat-label>
                    <input formControlName="cPersMaterno" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersMaterno')) }}</mat-error>
                </mat-form-field>

				<mat-form-field>
					<mat-label>Fecha de Nacimiento</mat-label>
					<input matInput [matDatepicker]="dPersNacimiento" formControlName="dPersNacimiento" autocomplete="off">
					<mat-datepicker-toggle matSuffix [for]="dPersNacimiento"></mat-datepicker-toggle>
					<mat-datepicker #dPersNacimiento [disabled]="false"></mat-datepicker>
					<mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('dPersNacimiento')) }}</mat-error>
				</mat-form-field>

				<mat-form-field class="vex-flex-form-field">
					<mat-label>Sexo</mat-label>
					<mat-select formControlName="cPersSexo">
						<mat-option [value]="item.value" *ngFor="let item of [{ value: 'M' }, { value: 'F' }]">{{ item.value }}</mat-option>
					</mat-select>
					<mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersSexo')) }}</mat-error>
				</mat-form-field>

                <mat-form-field>
					<mat-label>Estado Civil</mat-label>
					<mat-select formControlName="iTipoEstCivId">
						<mat-option [value]="item.iTipoEstCivId" *ngFor="let item of dataServidor.tipos_estados_civiles">{{ item.cTipoEstCivilNombre }}</mat-option>
					</mat-select>
					<mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('iTipoEstCivId')) }}</mat-error>
				</mat-form-field>

            </div>

            <div class="grid grid-cols-4 gap-2" *ngIf="frmRegistro.get('iTipoPersId').value == 2">
                <mat-form-field class="col-span-2">
                    <mat-label>Razón Social</mat-label>
                    <input cdkFocusInitial formControlName="cPersRazonSocialNombre" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersRazonSocialNombre')) }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Nombre Comercial</mat-label>
                    <input formControlName="cPersRazonSocialCorto" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersRazonSocialCorto')) }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Sigla</mat-label>
                    <input formControlName="cPersRazonSocialSigla" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersRazonSocialSigla')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="grid grid-cols-1 gap-2">
                <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input formControlName="cPersDomicilio" matInput>
                    <mat-error>{{ funcionesGlobalesService.mensajesErrorFormControl(frmRegistro.get('cPersDomicilio')) }}</mat-error>
                </mat-form-field>
            </div>
        <!-- </fieldset> -->

	</div>


	<ng-container pieDerecha>
		<ng-container *ngIf="!datosDialogRecibidos.soloVer">
			<button *ngIf="isCreateMode()" matRipple class="boton-dialog-flotante " mat-button type="submit">
				<mat-icon svgIcon="mat:save" class="icon-xs mr-1"></mat-icon>
				<span>GUARDAR</span>
			</button>
			<button *ngIf="isUpdateMode()" type="submit" matRipple class="boton-dialog-flotante text-indigo-700">
				<mat-icon svgIcon="mat:edit" class="icon-xs mr-1"></mat-icon>
				<span>ACTUALIZAR</span>
			</button>
		</ng-container>
	</ng-container>

</form>


