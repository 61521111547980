import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from './sidenav.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {SidenavItemModule} from './sidenav-item/sidenav-item.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ScrollbarModule} from "@vex/components/scrollbar/scrollbar.module";


@NgModule({
  declarations: [SidenavComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        SidenavItemModule,
        ScrollbarModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatRippleModule,
        ScrollbarModule
    ],
  exports: [SidenavComponent]
})
export class SidenavModule {
}
