<vex-page-layout mode="card" style="overflow-y:auto; height: 100vh" >
    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div class="flex-1 w-full flex items-center justify-center gap-1">
            <div class="sidenav-toolbar flex-none flex items-center">
                <img [src]="imageUrl$ | async" class="h-16 select-none flex-none" alt="">
                <img [src]="imageTitle$ | async" class="title h-16 select-none flex-auto" alt="">
            </div>
<!--            <h2 class="flex-1 title mb-4">{{ externosService.pagTitulo$ | async }}</h2>-->
            <vex-mod-breadcrumbs class="flex-1 text-2xl" soloUltimo></vex-mod-breadcrumbs>
            <general-reloj-servidor class="flex-none flex flex-col items-end justify-center font-mono"
                *ngIf="externosService.mostrarReloj$ | async"
                classFecha="text-primary-contrast"
                classHora="text-primary-contrast font-bold text-3xl"
            ></general-reloj-servidor>
        </div>
    </vex-page-layout-header>

    <vex-page-layout-content>
        <div class="" >
            <div class="card p-6 mat-elevation-z8" [style]="estiloFondo()">
                <router-outlet></router-outlet>
            </div>
        </div>
    </vex-page-layout-content>

</vex-page-layout>


