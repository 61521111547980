import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ServidorService} from '@servicios/servidor.service';

import * as moment from 'moment';

@Injectable()
export class GetDataInterceptor implements HttpInterceptor {

    solicitando = false;

    constructor(
        private servidor: ServidorService,
    ) {
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let horaReq = localStorage.getItem('srvtmstp');
        let fServidor: Date;
        let fLocal: Date;
        if (horaReq) {
            const partesHoras = horaReq.split('|');
            const fRec = parseInt(partesHoras[0]);
            const fEquipo = parseInt(partesHoras[1]);
            fServidor = new Date(fRec);
            fLocal = new Date(fEquipo);
        }

        if (!this.solicitando && (req.method == 'GET')) {
            if (!req.url.includes('/auth/') && req.url.includes('/api/')) {
                if (!req.url.includes('grl.fecha_hora_servidor')) {
                    const dAttach = () => {
                        req = req.clone({
                            url: req.url + '&grl.fecha_hora_servidor',
                        });
                        this.solicitando = true;
                    }
                    if (!fServidor) {
                        dAttach();
                    }
                    else {
                        const difTiempos = moment().diff(fLocal, 'minutes');
                        if (difTiempos > 15) {
                            dAttach();
                        }
                        if (!this.servidor._fechaHoraSegundos.getValue()) {
                            this.servidor._fechaHoraSegundos.next(fServidor.getTime() + moment().diff(fLocal));
                        }
                    }
                }
                else {
                    this.solicitando = true;
                }
            }
        }


        let dupReq = req.clone({
            // headers: req.headers.set('Consumer-Secret', 'some sample key')
        });

        return next.handle(dupReq).pipe(
            tap(evt => {
                // console.log(evt);
                if (evt instanceof HttpResponse) {
                    if (evt.url.includes('grl.fecha_hora_servidor') && evt.body && this.solicitando) {
                        if (evt.body['grl.fecha_hora_servidor'] && evt.body['grl.fecha_hora_servidor'].db) {
                            const fRec = new Date(evt.body['grl.fecha_hora_servidor'].db);
                            const fEquipo = new Date();
                            const arrayTiempos = [
                                fRec.getTime(),
                                fEquipo.getTime()
                            ];
                            this.servidor._fechaHoraSegundos.next(fRec.getTime());
                            localStorage.setItem('srvtmstp', arrayTiempos.join('|'));
                            // console.warn(evt.body.fecha_hora_servidor);
                        }
                        this.solicitando = false;
                    }
                }
            })
        );
    }

    agregarParametro(req: HttpRequest<any>, tokenToAdd, tokenName) {
        if (req.method.toLowerCase() === 'post') {
            if (req.body instanceof FormData) {
                req = req.clone({
                    body: req.body.append(tokenName, tokenToAdd)
                });
            }
            else {
                const foo = {};
                foo[tokenName] = tokenToAdd;
                req = req.clone({
                    body: {...req.body, ...foo}
                });
            }
        }
        if (req.method.toLowerCase() === 'get') {
            req = req.clone({
                params: req.params.set(tokenName, tokenToAdd)
            });
        }
        return req;
    }

}
