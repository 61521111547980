import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusService {
	focusSource = new EventEmitter<string>();
	selectedInput: BehaviorSubject<string> = new BehaviorSubject<string>('');

	focus(string) {
		console.warn(string);
		this.focusSource.emit(string);
	}
}
