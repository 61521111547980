import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {QueryService} from './query.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {environment} from '@ENV';
import {DOCUMENT} from '@angular/common';
import {VexConfigMod} from '@vexMod/config/vex-config.interface';
import {ColorSchemeName} from '@vex/config/colorSchemeName';
import {ConfigServiceMod} from '@vexMod/config/config.service';
import {VexConfigName} from "@vex/config/config-name.model";
import {mergeDeep} from "@vex/utils/merge-deep";
import {ConfiguracionWeb, ConfiguracionWebService} from '@servicios/configuracion-web.service';
import {getLocalStorage} from '@JVSoft/functions/local-storage';


@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class EntidadService {
	favIconPng: HTMLLinkElement;
	_paginaPrincipal = new BehaviorSubject<any>(null);
	paginaPrincipal$ = this._paginaPrincipal.asObservable();
	_entidadActual = new BehaviorSubject<any>(null);
	entidadActual$ = this._entidadActual.asObservable();

	get entidadActual() {
		return this._entidadActual.getValue() ?? null;
	}

	get sinMarcaJvSoft() {
		return !!environment['sinMarcaJvSoft'];
	}
	get tieneFirmaPeru() {
		return !!environment['firmaPeru'];
	}
	public get idEntidadFija() {
		return environment['iEntId'];
	}

	public get logoApp() {
		return getLocalStorage('logo_url') || (this.sinMarcaJvSoft ? 'assets/img/demo/logo2.svg' : 'assets/img/demo/logo.svg');
	}
	public get loginLogo() {
		return getLocalStorage('login-logo') || this.logoApp;
	}
	public get loginBackground() {
		return getLocalStorage('login-background') || '';
	}

	_configWeb = new BehaviorSubject<ConfiguracionWeb>(null);
    configWeb$ = this._configWeb.asObservable();

	constructor(
		private queryService: QueryService,
		private configService: ConfigServiceMod,
		private configuracionWeb: ConfiguracionWebService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.favIconPng = document.querySelector('#favIconPng');
		this.entidadActual$.pipe(untilDestroyed(this)).subscribe(dataEntidad => {
			if (dataEntidad) {
				this._paginaPrincipal.next(dataEntidad['cEntPaginaInicial']);
				this.procesarEntidad(dataEntidad);
			}
		});
		this.configService.configMod$.subscribe(v => {
			this.configService.updateConfig(v);
		});
	}

    esSector(tipoSector: 'publico'|'privado') {
        let tSector;
        switch (tipoSector){
            case 'publico': tSector = 1; break;
            case 'privado': tSector = 2; break;
            default:
                return  false;
        }
        return this._entidadActual.getValue().iTipoSectorId == tSector;
    }

	getEntidadBySlug(slug): Observable<any> {
		return this.queryService.getDataMethod('GET', 'entidades', {cEntSlug: slug}, true);
	}
	getEntidadById(id): Observable<any> {
		return this.queryService.getDataMethod('GET', 'entidades', {iEntId: id}, true);
	}
	setEntidadActualById(iEntId: number) {
		return this.queryService.setDataSession({iEntId}).then();
	}

	procesarEntidad(dataEntidad) {
		this.establecerEstilos(dataEntidad);
        this._configWeb.next(dataEntidad['cEntConfigWeb']);
	}

	establecerEstilos(dataEntidad) {


		this.configService.updateConfig({
			sidenav: {
				title: 'JVSOFT EIRL',
				user: {
					visible: false,
				},
				search: {
					visible: false,
				}
			},
			footer: {
				visible: false
			},
		});
		const evaluarImagenesPath = (imagePath) => {
			if (imagePath && (imagePath.substring(0, 4) != 'http' && imagePath.substring(0, 2) != '//')) {
				return this.configuracionWeb.apiData.backend + imagePath;
				// return environment.backend + imagePath;
			}
			return imagePath;
		};

		if (dataEntidad['cEntLogoPrincipal']) {
			localStorage.setItem('logo_url', evaluarImagenesPath(dataEntidad['cEntLogoPrincipal']));
		}
		const configEntidad: ConfiguracionWeb = dataEntidad['cEntConfigWeb'];
		// @ts-ignore
		this.configuracionWeb.configuracion = configEntidad;
		if (configEntidad['images'] && configEntidad['images']['login-logo']) {
			localStorage.setItem('login-logo', evaluarImagenesPath(configEntidad['images']['login-logo']));
		}
		else {
			localStorage.removeItem('login-logo');
		}
		if (configEntidad['images'] && configEntidad['images']['login-background']) {
			localStorage.setItem('login-background', evaluarImagenesPath(configEntidad['images']['login-background']));
		}
		else {
			localStorage.removeItem('login-background');
		}
		if (configEntidad['images'] && configEntidad['images']['favicon']) {
			localStorage.setItem('favicon', evaluarImagenesPath(configEntidad['images']['favicon']));
			this.favIconPng.href = evaluarImagenesPath(configEntidad['images']['favicon']);
		}
		else {
			localStorage.removeItem('favicon');
		}
		let nuevaConfig: VexConfigMod = configEntidad['configuracion'];
		const config: VexConfigMod = configEntidad['configuracion'];
		if (config) {
			config.sidenav.imageUrl = evaluarImagenesPath(config.sidenav.imageUrl);
			config.sidenav.imageTitle = evaluarImagenesPath(config.sidenav.imageTitle);
			nuevaConfig = mergeDeep({...nuevaConfig, ...{id: VexConfigName.apollo}}, config);
		}

		if (localStorage.getItem('darkMode') == '1') {
			nuevaConfig = mergeDeep({...nuevaConfig}, {
				style: {
					colorScheme: ColorSchemeName.dark,
				}
			});
		}
		else {
			const style = configEntidad['estilo'];
			if (style) {
				nuevaConfig = mergeDeep({...nuevaConfig}, {
					style: {
						colorScheme: ColorSchemeName[`${style}`],
					}
				});
			}
			else {
				nuevaConfig = mergeDeep({...nuevaConfig}, {
					style: {
						colorScheme: ColorSchemeName.default,
					}
				});
			}
		}

		const css = configEntidad['css'];
		if (css) {
			let coloresWeb = {};
			let colorPrimario;
			let colorSecundario;
			Object.keys(css).forEach(val => {
				if (val.includes('--color-primary')) {
					// this.document.documentElement.style.setProperty(val, css[val].replace('rgb(', '').replace(')', ''));
					// this.document.body.style.setProperty(val, css[val].replace('rgb(', '').replace(')', ''));
					// this.document.body.style.setProperty(val + '-rgb', css[val]);
					this.document.documentElement.style.setProperty(val, css[val].replace('rgb(', '').replace(')', ''));
					this.document.documentElement.style.setProperty(val + '-rgb', css[val]);
					if (val == '--color-primary') {
						colorPrimario = css[val];
						coloresWeb['default'] = css[val];
						coloresWeb['light'] = css[val];
					}
					if (val == '--color-primary-contrast') {
						coloresWeb['contrast'] = css[val];
					}
				}
				else {
					// this.document.documentElement.style.setProperty(val, css[val]);
					this.document.body.style.setProperty(val, css[val]);
				}
			});

			nuevaConfig = mergeDeep({...nuevaConfig}, {
				style: {
					colors: {
						primary: coloresWeb
					},
				}
			});
		}

		this.configService.updateConfig(nuevaConfig);
	}


}
