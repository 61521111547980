<ng-container *ngIf="!datoRecibidoEsDialog" [ngTemplateOutlet]="cuerpoPrincipal"></ng-container>

<div *ngIf="datoRecibidoEsDialog">
	<div globalDialogFlotante btnCerrar>
		<ng-container titulo>
			<div class="flex items-center justify-start gap-2">
				<mat-icon class="flex-none icon-xs" svgIcon="roundFindInPage"></mat-icon>
				<div class="flex-auto">Seguimiento de Trámite</div>
			</div>
		</ng-container>
		<ng-container cuerpo>
			<ng-container [ngTemplateOutlet]="cuerpoPrincipal"></ng-container>
		</ng-container>
	</div>

</div>

<ng-template #cuerpoPrincipal>

	<div class="flex flex-col gap-2 overflow-hidden" *ngIf="tramitePrincipal">

		<table class="flex-1 w-full border-collapse text-xs">
			<tbody>
			<tr>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Documento</th>
				<td class="px-1 border border-primary text-primary">{{ tramitePrincipal['cTramDocumentoTramite'] }}</td>
				<td class=""></td>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Registro</th>
				<td class="px-1 border border-primary text-primary">{{ tramitePrincipal['iTramNumRegistroYear'] }} {{ tramitePrincipal['iTramYearRegistro'] }}</td>
			</tr>
			<tr>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Remitente</th>
				<td class="px-1 border border-primary text-primary">
					<span *ngIf="tramitePrincipal['iTipoTramId'] == 1">{{ tramitePrincipal['cPersFirmaEmisorNombre'] }}</span>
					<span *ngIf="tramitePrincipal['iTipoTramId'] == 2">{{ tramitePrincipal['cPersEmisorNombre'] }}</span>
				</td>
				<td class=""></td>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Despacho</th>
				<td class="px-1 border border-primary text-primary">
					<span>{{ tramitePrincipal['cDespachoEmisorNombreLargo'] }}</span>
					<span *ngIf="tramitePrincipal['iTipoTramId'] == 2"><br>{{ tramitePrincipal['cPersFirmaEmisorNombre'] }}</span>
				</td>
			</tr>
			<tr>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Asunto</th>
				<td class="px-1 border border-primary text-primary">{{ tramitePrincipal['cTramAsuntoDocumento'] }}</td>
				<td class=""></td>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">F. de Creación</th>
				<td class="px-1 border border-primary text-primary">{{ tramitePrincipal['dtTabTraRefTramiteBuscado'] | date: 'dd/MM/yyyy HH:mm' }}</td>
			</tr>
			</tbody>
		</table>

		<div class="flex-1 w-full bg-amber-100 rounded-lg p-4 mb-4" *ngIf="dataServidor.tram.tramites_avisos && dataServidor.tram.tramites_avisos.length > 0">
			<div class="flex flex-col items-start justify-center gap-2 w-full ml-3 text-sm text-amber-700">
				<p class="flex-none font-medium"><mat-icon svgIcon="roundWarning" class="icon-xs text-amber-700"></mat-icon> Aviso!</p>
				<div class="flex-1 w-full border-b-2" *ngFor="let datAviso of dataServidor.tram.tramites_avisos" >
					<blockquote class="font-light text-xs mt-2 mb-1 p-1">
						{{ datAviso.cTramAvisoObs }}
					</blockquote>
					<div class="italic text-right text-xxs">Notificado por {{ datAviso.cPersAvisoNombreLargo }} via email a la direccion {{ datAviso.cPersConCorreoElectronicoNombre }}.</div>
				</div>
			</div>
		</div>

		<div class="flex-1 w-full flex gap-2">
			<general-tramite-tabla
				class="w-full"
				nombreColeccion="tramites_referencias_seguimiento"
				[objThis]="this"
				[botonesPrincipal]="botonesMantenimiento.tramites_referencias_seguimiento"
				[dataSource]="dataServidor.tram.tramites_referencias_seguimiento"
				[columnasTabla]="columnasTabla"
				(opcionSelecionada)="opcMenu($event)"
				(accionRecargar)="cargaInicial()"
			>
			</general-tramite-tabla>
		</div>
		<table class="flex-1 w-full border-collapse text-xs">
			<tbody>
			<tr>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Última Dependencia</th>
				<td class="px-1 border border-primary text-primary">
					{{ ultimaDependencia['cDespachoReceptorNombreLargo'] }}
					<span *ngIf="!ultimaDependencia['dtTramMovFechaHoraRecibido']"><strong class="text-red-900">(no recibido)</strong></span>
				</td>
				<td class=""></td>
				<th class="px-1 uppercase border border-primary bg-primary text-primary-contrast">Tiempo Transcurrido</th>
				<td class="px-1 border border-primary text-primary">
					<span *ngIf="ultimaDependencia['dtTramMovFechaHoraRecibido']">{{ ultimaDependencia['dtTramMovFechaHoraRecibido'] | dateDiffString: primeraDependencia['dtTramMovFechaHoraEnvio'] }}</span>
					<span *ngIf="!ultimaDependencia['dtTramMovFechaHoraRecibido']" class="italic text-red-900"> hasta hoy, {{ fechaActual | dateDiffString: primeraDependencia['dtTramMovFechaHoraEnvio'] }}</span>

				</td>
			</tr>
			</tbody>
		</table>

	</div>
</ng-template>


<ng-template #dlgNotificaciones>
	<div globalDialogFlotante sinTitulo btnCerrar>
		<div cuerpo>
			<general-tabla-mantenimiento-nuevo
				*ngIf="dataServidor.tram.tramites_notificaciones_NoSubsanados && dataServidor.tram.tramites_notificaciones_NoSubsanados.length > 0"
				botonesCRUD=""
				[objThis]="this"
				[data]="dataServidor.tram.tramites_notificaciones_NoSubsanados"
				[columnasTabla]="columnasTablaNotificaciones"
				nombreColeccion="tramites_notificaciones_NoSubsanados"
			>
			</general-tabla-mantenimiento-nuevo>
		</div>
	</div>
</ng-template>
