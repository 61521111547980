<!--<div fxLayout="row wrap" fxLayoutAlign="space-around center">
    <div *ngFor="let icono of appIconsAll | keyvalue" fxFill="" fxFlex="200px">
        <mat-icon [svgIcon]="icono.key" class="icon-lg text-xl"></mat-icon>
        <br>
        {{ icono.key }}
    </div>
</div>-->


<div class="grid grid-cols-[repeat(auto-fill,minmax(8rem,1fr))] gap-x-6 gap-y-4 pb-16 pt-10 sm:pt-11 md:pt-12">
    <div class="group" *ngFor="let icono of appIconsAll | keyvalue">
        <div class="relative h-[8.5rem]">
            <button aria-controls="academic-cap" aria-expanded="false" aria-haspopup="true" aria-label="academic-cap"
                class="absolute inset-0 flex h-full w-full cursor-auto items-center justify-center rounded-xl text-slate-900 ring-1 ring-inset ring-slate-900/[0.08]"
                id="academic-cap-btn"
                type="button">
                <div class="transition-transform duration-500 ease-in-out">
                    <mat-icon [svgIcon]="icono.key" class="icon-2xl text-xl"></mat-icon>
                </div>
            </button>
<!--            <mat-icon [svgIcon]="icono.key" class="icon-lg text-xl"></mat-icon>-->
        </div>
        <div
            class="mt-3 h-10 text-center text-[0.8125rem] leading-5 text-slate-500 break-all group-focus-within:line-clamp-2 group-focus-within:whitespace-normal group-hover:line-clamp-2 group-hover:whitespace-normal"
            title="academic-cap">{{ icono.key }}
        </div>
    </div>
</div>
