import {ordenarPorPropiedad} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';


export function ExpedienteDocumento(objThis: PdfPresupuestoService, dataRec) {
    objThis.queryService.cargar(objThis, ['siaf.Expediente_Documento'], {
        cCodigoCadena: dataRec,
    }).then((valRetorno) => {
        console.log(valRetorno);
        let resultadosGenerales = valRetorno['siaf.Expediente_Documento'];
        resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');

        this.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            // orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                objThis.imprimirCelda('REPORTE DE EXPEDIENTE, CHEKE Y COMPROBANTE DE PAGO DE LOS REGISTROS MARCADOS', null, {fontSize: 13, bold: true}),
                objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            { label: 'AÑO', property: 'Ano_eje', type: 'text' },
            { label: 'EXPEDIENTE', property: 'Expediente', type: 'text' },
            { label: 'F.F', property: 'Fuente_financ', type: 'text' },
            { label: 'T.R', property: 'Tipo_recurso', type: 'text' },
            { label: 'T.O', property: 'Tipo_operacion', type: 'text' },
            { label: 'COD', property: 'Cod_doc', type: 'text' },
            { label: 'DOCUMENTO', property: 'Abreviatura_doc', type: 'text' },
            { label: 'NÚMERO', property: 'Num_doc', type: 'text' },
            { label: 'C', property: 'Conciliado', type: 'text' },
            { label: 'FECHA', property: 'Fecha_doc', type: 'date' },
            { label: 'NOMBRE', property: 'Nombre', type: 'text' },
            { label: 'MONTO', property: 'Monto', type: 'text' },
            { label: 'DOCUMENTO', property: 'Abreviatura_doc_O', type: 'text' },
            { label: 'NUMERO', property: 'Num_doc_O', type: 'text' },
            { label: 'FECHA', property: 'Fecha_doc_O', type: 'date' },
            { label: 'ENTREGA', property: 'Fecha_entrega', type: 'date' },
            { label: 'PAGADO', property: 'Fecha_pago', type: 'date' },

        ];
        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: camposPrincipal,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
            numeracion: true,
            idxResto: [5],
            margin: [0, 0, 0, 5],
            // separado: true,
        });

        cuerpoPdf.push(tablaGenerada);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}
export function ExpedienteSecuenciaFase(objThis: PdfPresupuestoService, dataRec) {
    objThis.queryService.cargar(objThis, ['siaf.Expediente_Secuencia_Fase'], {
        cCodigoCadena: dataRec,
    }).then((valRetorno) => {
        console.log(valRetorno);
        let resultadosGenerales = valRetorno['siaf.Expediente_Secuencia_Fase'];
        resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');

        objThis.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            // orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                objThis.imprimirCelda('REPORTE DE EXPEDIENTES MARCADOS POR NOMBRE / PROVEEDOR', null, {fontSize: 13, bold: true}),
                objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            { label: 'AÑO', property: 'Ano_eje', type: 'text' },
            { label: 'EXPEDIENTE', property: 'Expediente', type: 'text' },
            { label: 'F.F', property: 'Fuente_financ', type: 'text' },
            { label: 'T.R', property: 'Tipo_recurso', type: 'text' },
            { label: 'T.O', property: 'Tipo_operacion', type: 'text' },
            { label: 'COD', property: 'Cod_doc', type: 'text' },
            { label: 'DOCUMENTO', property: 'Abreviatura_doc', type: 'text' },
            { label: 'SERIE', property: 'Serie_doc', type: 'text' },
            { label: 'NÚMERO', property: 'Num_doc', type: 'text' },
            { label: 'FECHA', property: 'fecha_doc', type: 'date' },
            { label: 'MONTO', property: 'Monto', type: 'text' },
            { label: 'RUC', property: 'Ruc', type: 'text' },
            { label: 'NOMBRE', property: 'Nombre_persona', type: 'text' },
            { label: 'EE', property: 'Estado', type: 'text' },
            { label: 'ER', property: 'Estado_envio', type: 'text' },
            { label: 'TIPO DE OPERACION', property: 'Nombre_TipoOperacion', type: 'text' },

        ];
        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: camposPrincipal,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
            numeracion: true,
            idxResto: [16],
            margin: [0, 0, 0, 5],
            // separado: true,
        });

        cuerpoPdf.push(tablaGenerada);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}
export function ExpedienteDocumentoNota(objThis: PdfPresupuestoService, dataRec) {
    objThis.queryService.cargar(objThis, ['siaf.Expediente_Documento_Nota'], {
        cCodigoCadena: dataRec,
    }).then((valRetorno) => {
        console.log(valRetorno);
        let resultadosGenerales = valRetorno['siaf.Expediente_Documento_Nota'];
        resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');

        objThis.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            // orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                objThis.imprimirCelda('REPORTE DE LOS COMPROBANTES DE PAGO', null, {fontSize: 13, bold: true}),
                objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            { label: 'AÑO', property: 'Ano_eje', type: 'text' },
            { label: 'N° SIAF', property: 'Expediente', type: 'text' },
            { label: 'DOCUMENTO', property: 'Abreviatura_doc_O', type: 'text' },
            { label: 'NÚMERO', property: 'Numero_doc_O', type: 'text' },
            { label: 'FECHA', property: 'Fecha_doc_O', type: 'date' },
            { label: 'NOMBRE', property: 'Nombre', type: 'text' },
            { label: 'MONTO', property: 'Monto', type: 'text' },
            { label: 'GLOSA', property: 'Notas', type: 'text' },
        ];
        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: camposPrincipal,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
            numeracion: true,
            idxResto: [8],
            margin: [0, 0, 0, 5],
            // separado: true,
        });

        cuerpoPdf.push(tablaGenerada);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}
export function ExpedienteSecuenciaFaseNota(objThis: PdfPresupuestoService, dataRec) {
    objThis.queryService.cargar(objThis, ['siaf.Expediente_Secuencia_Fase'], {
        cCodigoCadena: dataRec,
    }).then((valRetorno) => {
        console.log(valRetorno);
        let resultadosGenerales = valRetorno['siaf.Expediente_Secuencia_Fase'];
        resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');

        objThis.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            // orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                objThis.imprimirCelda('REPORTE DE EXPEDIENTES MARCADOS POR NOMBRE / PROVEEDOR', null, {fontSize: 13, bold: true}),
                objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            { label: 'AÑO', property: 'Ano_eje', type: 'text' },
            { label: 'EXPEDIENTE', property: 'Expediente', type: 'text' },
            { label: 'DOCUMENTO', property: 'Abreviatura_doc_O', type: 'text' },
            { label: 'SERIE', property: 'Serie_doc', type: 'text' },
            { label: 'NÚMERO', property: 'Num_doc', type: 'text' },
            { label: 'FECHA', property: 'fecha_doc', type: 'date' },
            { label: 'MONTO', property: 'Monto', type: 'text' },
            { label: 'NOMBRE', property: 'Nombre_persona', type: 'text' },
            { label: 'GLOSA', property: 'Notas', type: 'text' },
        ];
        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: camposPrincipal,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
            numeracion: true,
            idxResto: [9],
            margin: [0, 0, 0, 5],
            // separado: true,
        });

        cuerpoPdf.push(tablaGenerada);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}
