import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formatDate} from '@angular/common';
import {ConfiguracionWebService} from '@servicios/configuracion-web.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ServidorService {
	_fechaHoraSegundos = new BehaviorSubject<number>(null);
	fechaHoraSegundos$ = this._fechaHoraSegundos.asObservable();

	get photoURL() {
		return this.configuracionWebService.apiData.backend + '/fotografia/';
	}
	get photoThumbURL() {
		return this.configuracionWebService.apiData.backend + '/fotoThumb/';
	}
	get storageImageUrl() {
		return this.configuracionWebService.apiData.backend + '';
	}

	get fechaActualDate() {
		if (!this._fechaHoraSegundos.getValue()) {
			return null
		}
		return new Date(this._fechaHoraSegundos.getValue());
	}
	get fechaActualDateTime() {
		if (!this.fechaActualDate) {
			return null
		}
        return formatDate(this.fechaActualDate, 'yyyy-MM-ddTHH:mm', 'en');
	}
	private readonly timerSubscription: Subscription;
	constructor(
		private configuracionWebService: ConfiguracionWebService,
	) {
		this.timerSubscription = interval(1000).subscribe((dat) => {
			if (this._fechaHoraSegundos.getValue()){
				const nSeg = this._fechaHoraSegundos.getValue() + 1000;
				this._fechaHoraSegundos.next(nSeg);
			}
		});
	}

	limpiarIntervalos(){
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
	}

}
