import {Component, Inject, Input, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'global-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PdfViewerComponent implements OnInit {
	@Input() esDialog = true;
	@Input() set src(val: any) {
		// this.datosDialogRecibidos.src = val;
		this.srcFinal = this.sanitizer.bypassSecurityTrustResourceUrl(val);
	};


	get datoRecibidoEsDialog() {
		if (this.datosRecibidos && this.datosRecibidos.esDialog == undefined) {
			return true;
		}
		return this.datosRecibidos?.esDialog;
	}

	datosRecibidos: {titulo?: any, src?: any, esDialog?: boolean} = {};
	srcFinal: SafeResourceUrl;
	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
		@Optional() private dialogRef: MatDialogRef<PdfViewerComponent>,
		private sanitizer: DomSanitizer,
	) {
		if (!dialogRef) {
			this.datosRecibidos.esDialog = false;
		}
	}

	ngOnInit(): void {
		// this.srcFinal = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
		if (this.datosDialogRecibidos?.src) {
			this.srcFinal = this.sanitizer.bypassSecurityTrustResourceUrl(this.datosDialogRecibidos.src);
		}
	}

}
