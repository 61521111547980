import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'jvs-luces-navidad, [jvsLucesNavidad]',
	templateUrl: './luces-navidad.component.html',
	styleUrls: ['./luces-navidad.component.scss']
})
export class LucesNavidadComponent implements OnInit {
	@Input() enProduccion = environment.production;
	// enProduccion = environment.production;
	mesActual = new Date().getMonth() + 1;

	constructor() {
	}

	ngOnInit(): void {
	}

}
