import {Injectable} from '@angular/core';

import productHunt from '@iconify/icons-fa-brands/product-hunt';


import icPeople from '@iconify/icons-ic/baseline-people';

import outlineAdminPanelSettings from '@iconify/icons-ic/outline-admin-panel-settings';
import buildingMultiple24Regular from '@iconify-icons/fluent/building-24-regular';
import imageEdit24Regular from '@iconify-icons/fluent/image-edit-24-regular';
import contactCard20Regular from '@iconify-icons/fluent/contact-card-20-regular';
import chevronLeft24Regular from '@iconify-icons/fluent/chevron-left-24-regular';
import chevronRight24Regular from '@iconify-icons/fluent/chevron-right-24-regular';
import chevronDown24Regular from '@iconify-icons/fluent/chevron-down-24-regular';
import copyArrowRight24Filled from '@iconify-icons/fluent/copy-arrow-right-24-filled';
import copy24Regular from '@iconify-icons/fluent/copy-24-regular';

import icSave from '@iconify/icons-ic/save';
import icCancel from '@iconify/icons-ic/cancel';
import icShopingCart from '@iconify/icons-ic/shopping-cart';
import icRemoveShopingCart from '@iconify/icons-ic/remove-shopping-cart';
import baselineFilterAlt from '@iconify/icons-ic/baseline-filter-alt';
import trayArrowUp from '@iconify-icons/mdi/tray-arrow-up';
import trayArrowDown from '@iconify-icons/mdi/tray-arrow-down';
import accountDetailsOutline from '@iconify-icons/mdi/account-details-outline';
import fastForward from '@iconify-icons/mdi/fast-forward';
import fastRewind from '@iconify-icons/mdi/fast-rewind';
import arrowForward from '@iconify-icons/mdi/arrow-forward';
import clipboardListOutline from '@iconify-icons/mdi/clipboard-list-outline';
import personArrowLeftOutline from '@iconify-icons/mdi/person-arrow-left-outline';
import truckDelivery from '@iconify-icons/mdi/truck-delivery';
import vectorArrangeAbove from '@iconify-icons/mdi/vector-arrange-above';
import accountMultipleCheck from '@iconify-icons/mdi/account-multiple-check';
import checkboxMultipleMarked from '@iconify-icons/mdi/checkbox-multiple-marked';
import fileChartOutline from '@iconify-icons/mdi/file-chart-outline';
import homeCircleOutline from '@iconify-icons/mdi/home-circle-outline';
import globeModel from '@iconify-icons/mdi/globe-model';
import checkCircle from '@iconify-icons/mdi/check-circle';
import replyCircle from '@iconify-icons/mdi/reply-circle';
import shareCircle from '@iconify-icons/mdi/share-circle';
import roundedPerson from '@iconify-icons/mdi/person';
import roundedPersonOff from '@iconify-icons/mdi/person-off';

import checkboxChecked20Regular from '@iconify-icons/fluent/checkbox-checked-20-regular';
import checkboxUnchecked20Regular from '@iconify-icons/fluent/checkbox-unchecked-20-regular';

import refresh from '@iconify-icons/mdi/refresh';
import icNumeric from '@iconify-icons/mdi/numeric';

// iconos rounded

export const appIcons = {
	baselineFilterAlt,
	icPeople,
	outlineAdminPanelSettings,
	buildingMultiple24Regular,
	imageEdit24Regular,
	contactCard20Regular,
	chevronLeft24Regular,
	chevronRight24Regular,
	chevronDown24Regular,
	copyArrowRight24Filled,
	copy24Regular,
	vectorArrangeAbove,
	accountMultipleCheck,
	checkboxMultipleMarked,
	productHunt,

	icShopingCart,
	icRemoveShopingCart,
	icSave,
	icCancel,
	fastForward,
	fastRewind,
	arrowForward,
	clipboardListOutline,
	personArrowLeftOutline,

	trayArrowDown,
	trayArrowUp,
	accountDetailsOutline,
	truckDelivery,
	fileChartOutline,
	homeCircleOutline,
	globeModel,
	checkCircle,
	replyCircle,
	shareCircle,
	roundedPerson,
	roundedPersonOff,

	checkboxChecked20Regular,
	checkboxUnchecked20Regular,
	refresh,
	icNumeric,

};

@Injectable({
	providedIn: 'root'
})
export class IconifyIconsService {

	constructor() { }
}
