import {Component, Inject, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {stagger40ms} from '@vex/animations/stagger.animation';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '@servicios/query.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FuncionesGeneralesService} from '../../../../general/services/funciones-generales.service';
import {TabSeccion} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';
import {FiltroBusquedaService} from '@standalone/components/filtro-busqueda/filtro-busqueda.service';
import {ProductoComponent} from '../../../../general/components/producto/producto.component';
import {BehaviorSubject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ColumnaTabla, OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {EntidadService} from '@servicios/entidad.service';
import {mensajesErrorFormControl} from '@JVSoft/services/funciones-globales.service';
import {PdfLogisticaService} from '@servicios/pdf/pdf-logistica.service';

@UntilDestroy()
@Component({
    selector: 'vex-logistica-mantenimiento-productos',
    templateUrl: './logistica-mantenimiento-productos.component.html',
    styleUrls: ['./logistica-mantenimiento-productos.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
    ],
})
export class LogisticaMantenimientoProductosComponent implements OnInit {
    mensajesErrorFormControl = mensajesErrorFormControl;
    dataServidor = {
        productos: null,
        log: {
            tipos_productos: null,
            productos: null,

            productos_tributos: null,
        },
        acc: {
            ambitos_tributos: null,
            tributos: null,
        }
    };

    seleccionados = {
        productos: null,
        productos_tributos: null,
    };

    paginacion = {
        pageSize: 25,
        pageSizeOptions: [5, 10, 25, 50, 100],
        pageCurrent: null,
    };

    opcionesFiltro: TabSeccion[] = [
        {
            label: 'Básico',
            params: [
                {
                    control: 'select', change: 'cGrupoCodigo', campo: 'iTipoProductoId', data: 'log.tipos_productos',
                    val: '1', text: 'cTipoProductoNombre', label: 'Tipo', flex: '100px',
                },
                {
                    control: 'select', campo: 'ubicacionBusqueda', data: 'ubicacionBusqueda', customData: [
                        {ubicacionBusqueda: '~VAR~%', label: 'Empieza por: '},
                        {ubicacionBusqueda: '%~VAR~%', label: 'Contiene: '},
                        {ubicacionBusqueda: '%~VAR~', label: 'Termina con: '},
                    ], val: '%~VAR~%', text: 'label', label: 'Ubic. Texto', flex: '120px',
                },
                {
                    control: 'text', campo: 'cProductoNombre', text: '', label: 'Nombre', focus: true, keyUpEnter: true,
                    flex: '150px',
                },
            ],
        },
        {
            label: 'Avanzado',
            params: [
            ],
        },
    ];

    columnasProductos: ColumnaTabla<any>[] = [
        // { label: 'Image', property: 'image', type: 'image' },

        {label: '#', property: 'numeracion_automatica', type: 'custom'},
        { label: 'iProductoId', property: 'iProductoId', type: 'text', visible: false },
        {label: 'Nombre Tipo', property: 'cTipoProductoNombre', type: 'text', visible: false},
        {label: 'Código Producto', property: 'cCodigoProducto', type: 'text', cssClasses: ['font-bold']},
        {label: 'Nombre Producto', property: 'cProductoNombre', type: 'text', cssClasses: ['font-bold']},
        {label: 'Presentación', property: 'cPresentacionNombre', type: 'text'},
        {label: 'Costo', property: 'nProductoCostoCompra', type: 'number', cssClasses: ['text-right']},
    ];
    columnasTributos: ColumnaTabla<any>[] = [
        // { label: 'Image', property: 'image', type: 'image' },

        {label: '#', property: 'numeracion_automatica', type: 'custom'},
        // { label: 'Código Tipo', property: 'cTipoCodigo', type: 'text',  },
        {label: 'Nombre', property: 'cTribNombre', type: 'text'},
        // {label: 'cTribLeyenda', property: 'cTribLeyenda', type: 'text', cssClasses: ['font-bold']},
        {label: 'Ámbito', property: 'cAmbTribNombre', type: 'text', cssClasses: ['font-bold']},
        {label: 'Tipo de Cálculo', property: 'cTipoCalTribNombre', type: 'text'},
        {label: 'Valor', property: 'nTribValor', type: 'number'},
        {label: 'Órden', property: 'iProdTribOrden', type: 'text'},
    ];

    opcionesFiltroBusquedaActual = {};

    frmFiltro: FormGroup;
    frmRegistro: FormGroup;
    frmRegistroTributos: FormGroup = this.fb.group({
        iProductoId: [''],
        iTribId: [''],
        iProdTribId: [''],
        iProdTribOrden: [''],
    });

    _dataPrincipal = new BehaviorSubject<any>(null);
    dataPrincipal$ = this._dataPrincipal.asObservable();

    _dataTributos = new BehaviorSubject<any>(null);
    dataTributos$ = this._dataTributos.asObservable();

    isLoading;

    dialogRef: MatDialogRef<any>[] = [null, null, null];
    @ViewChild('dialogRegistro', {static: true}) dialogRegistroRef: TemplateRef<any>;
    @ViewChild('dlgTributos', {static: true}) dlgTributosRef: TemplateRef<any>;
    @ViewChild('dlgRegistroProductosTributos', {static: true}) dlgRegistroProductosTributosRef: TemplateRef<any>;
    botonesMantenimiento: TablaMantenimientoSeccionBotones;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
        @Optional() private dialogRefMttoProductos: MatDialogRef<LogisticaMantenimientoProductosComponent>,
        private dialog: MatDialog,
        private queryService: QueryService,
        private fb: FormBuilder,
        public entidadService: EntidadService,
        public funcionesGeneralesService: FuncionesGeneralesService,
        private pdfLogisticaService: PdfLogisticaService,
    ) {
        if (entidadService.esSector('publico')) {
            this.opcionesFiltro[1].params.push(
                {
                    control: 'select', change: 'cGrupoCodigo', campo: 'iTipoProductoId', data: 'log.tipos_productos',
                    val: '1', text: 'cTipoProductoNombre', label: 'Tipo', flex: '10',
                },
                {
                    control: 'autocomplete', change: 'cClaseCodigo', dataChangeReqParams: ['iTipoProductoId'],
                    tipoControl: 'textarea', campo: 'cGrupoCodigo', campoId: 'cGrupoCodigo',
                    data: 'log.grupos_bien_servicio', val: '', text: ['cGrupoCodigo', 'cGrupoNombre'], label: 'Grupo',
                },
                {
                    control: 'autocomplete', change: 'cFamiliaCodigo',
                    dataChangeReqParams: ['iTipoProductoId', 'cGrupoCodigo'], tipoControl: 'textarea',
                    campo: 'cClaseCodigo', campoId: 'cClaseCodigo', data: 'log.clases_bien_servicio', val: '',
                    text: ['cClaseCodigo', 'cClaseNombre'], label: 'Clase',
                },
                {
                    control: 'autocomplete', dataChangeReqParams: ['iTipoProductoId', 'cGrupoCodigo', 'cClaseCodigo'],
                    tipoControl: 'textarea', campo: 'cFamiliaCodigo', campoId: 'cFamiliaCodigo',
                    data: 'log.familias_bien_servicio', val: '', text: ['cFamiliaCodigo', 'cFamiliaNombre'],
                    label: 'Familia',
                },
            );
            this.columnasProductos.push(
                {label: 'Código Grupo', property: 'cGrupoCodigo', type: 'text', visible: false},
                {label: 'Nombre Grupo', property: 'cGrupoNombre', type: 'text', visible: false},
                {label: 'Código Clase', property: 'cClaseCodigo', type: 'text', visible: false},
                {label: 'Nombre Clase', property: 'cClaseNombre', type: 'text', visible: false},
                {label: 'Código Familia', property: 'cFamiliaCodigo', type: 'text', visible: false},
                {label: 'Nombre Familia', property: 'cFamiliaNombre', type: 'text', visible: false},
            );
        }
        if (entidadService.esSector('privado')) {
            this.opcionesFiltro[1].params.push(
                {
                    control: 'autocomplete', change: true, campo: 'iCategoriaId', data: 'log.categorias', val: '',
                    text: ['cCategoriaCodigo', 'cCategoriaNombre'], label: 'Categoría',
                },
            );
            this.columnasProductos.push(
                {label: 'Código Categoría', property: 'cCategoriaCodigo', type: 'text', visible: false},
                {label: 'Nombre Categoría', property: 'cCategoriaNombre', type: 'text', visible: false},
            );
        }
    }

    ngOnInit(): void {
        this.botonesMantenimiento = {
            productos: {
                principal: [
                    {icono: 'roundFileCopy', tipo: 'copiar', label: 'Copiar'},
                    {icono: 'roundPaid', tipo: 'tributos', label: 'Tributos'},
                ],
                inferior: [
					{ icono: 'fileTypePdf', tipo: 'exportar_pdf', label: 'Exportar a PDF', sinCondicion: true  },
				],
            },
        };

        this.crearFormularios();
        // this.cargaInicial();
        if (!this.datosDialogRecibidos) {
            this.datosDialogRecibidos = {};
        }

        this.frmFiltro.get('iAmbTribId').valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
            if (val) {
                this.cargarProductosTibutos(val);
            }
        });
    }

    crearFormularios() {
        this.frmFiltro = this.fb.group({
            iAmbTribId: [''],
        });
    }

    filtroResultados(event = {}) {
        console.log(event, new Date());
        this.opcionesFiltroBusquedaActual = event;
        this.cargarLista();
    }

    cargarLista(ctrlValue = {}, borrarSeleccionado = true) {
        const paramsRequest = {...this.frmFiltro.value, ...this.opcionesFiltroBusquedaActual, ...ctrlValue};
        if (borrarSeleccionado) {
            this.seleccionados.productos = null;
        }
        return this.queryService.cargar(this, ['log.productos'], paramsRequest).then(() => {
            console.log('log.productos');
            this._dataPrincipal.next(this.dataServidor.log.productos);
        });
        // this.queryService.cargar(this, ['log.productos'], paramsRequest).then(() => {
        // 	console.log('TERMINO', new Date());
        // });
    }

    cargarProductosTibutos(iAmbTribId) {
        this.queryService.cargar(this, ['log.productos_tributos', 'acc.tributos'], {
            iProductoId: this.seleccionados.productos.iProductoId,
            iAmbTribId,
        }).then(() => {
            this._dataTributos.next(this.dataServidor.log.productos_tributos);
        });
    }

    cargaInicial() {
    }

    /*	cargarLista(ctrlValue = {}) {
            console.log(ctrlValue);
            this.seleccionados.productos = null;
            let objDataEnviar = this.frmFiltro.value;
            if (ctrlValue){
                this.opcionesFiltroBusquedaActual = ctrlValue;
                objDataEnviar = {
                    ...objDataEnviar,
                    ...ctrlValue
                };
            }
            if (this['paginacion'] && this['paginacion'].pageCurrent){
                objDataEnviar = {
                    ...objDataEnviar,
                    ...{
                        iPageNumber: this['paginacion'].pageCurrent.pageIndex + 1,
                        iPageSize: this['paginacion'].pageCurrent.pageSize,
                    }
                };
            }
            this.queryService.cargar(this, ['log.productos'], objDataEnviar).then();
        }*/
    cargarListaIndividual(iProductoId) {
        return this.queryService.getDataMethod('GET', 'log.productos', {iProductoId}).toPromise();
    }

    opcMenu(v: OpcionSeleccionada) {
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            data: {},
        };
        switch (v.seccion) {
            case 'productos':
                // dialogOpc.minWidth = '80vw';
                // dialogOpc.data.tipos_productos = this.dataServidor.log.tipos_productos;
                switch (v.tipo) {
                    case 'nuevo':
                        this.seleccionados.productos = null;
                        this.abrirDialog(dialogOpc, v);
                        break;
                    case 'copiar':
                        dialogOpc.data = {
                            producto: {
                                ...this.seleccionados.productos, ...{iProductoId: undefined},
                            },
                            mode: 'create',
                        };
                        // dialogOpc.data.producto = this.seleccionados.productos;
                        this.abrirDialog(dialogOpc, v);
                        break;
                    case 'editar':
                        dialogOpc.data.producto = this.seleccionados.productos;
                        this.abrirDialog(dialogOpc, v);
                        break;
                    case 'seleccionar':
                        this.seleccionados.productos = v.item;
                        this.enviarSeleccion();
                        break;
                    case 'tributos':
                        this.queryService.cargar(this, ['acc.ambitos_tributos']).then((dRet) => {
                            this.dialogRef[0] = this.dialog.open(this.dlgTributosRef, dialogOpc);
                            if (dRet['acc.ambitos_tributos'] && dRet['acc.ambitos_tributos'][0]) {
                                this.frmFiltro.get('iAmbTribId').setValue(dRet['acc.ambitos_tributos'][0]['iAmbTribId']);
                            }
                        });
                        break;
                    case 'exportar_pdf':
                        this.pdfLogisticaService.reporteProductos({...this.frmFiltro.value, ...this.opcionesFiltroBusquedaActual, ...{ reporte: 1, iPaginacion: 0 }});
                        break;
                    default:
                        this.queryService.parteComunOpcionesMantenimiento2(this, v, v.formGroup, {
                            coleccion: 'log.productos',
                            idxTabla: 'iProductoId',
                            strDataObservable: '_dataPrincipal',
                            prefix: 'log',
                            dialogRef: v.dialog,
                        }).then().catch(error => { console.log(error); });
                        break;
                }
                break;
            case 'productos_tributos':
                switch (v.tipo) {
                    case 'nuevo':
                        this.frmRegistroTributos.reset({
                            iProductoId: this.seleccionados.productos.iProductoId,
                        });
                        this.dialogRef[1] = this.dialog.open(this.dlgRegistroProductosTributosRef, dialogOpc);
                        break;
                    case 'editar':
                        this.frmRegistroTributos.patchValue(this.seleccionados.productos_tributos);
                        this.dialogRef[1] = this.dialog.open(this.dlgRegistroProductosTributosRef, dialogOpc);
                        break;
                    default:
                        this.queryService.parteComunOpcionesMantenimiento2(this, v, this.frmRegistroTributos, {
                            coleccion: 'log.productos_tributos',
                            idxTabla: 'iProdTribId',
                            strDataObservable: '_dataTributos',
                            prefix: 'log',
                            dialogRef: this.dialogRef[1],
                        }).then().catch(error => { console.log(error); });
                        break;
                }
                break;
        }
    }

    enviarSeleccion() {

        if (this.seleccionados.productos) {
            this.dialogRefMttoProductos.close(this.seleccionados.productos);
        }
        else {
            this.funcionesGeneralesService.mensajeToast('error', 'Debe Seleccionar un producto', 'No hay productos seleccionados.');
        }
    }

    abrirDialog(dialogOpc: MatDialogConfig, v) {
        const dialogAct = this.dialog.open(ProductoComponent, dialogOpc);
        dialogAct.componentInstance.onSubmitReason.subscribe(dat => {
            this.opcMenu({
                ...v,
                ...{
                    tipo: 'guardar',
                    formGroup: dat,
                    dialog: dialogAct,
                },
            });
            console.log(dat);
        });
    }
}
