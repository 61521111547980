import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {MatInput} from '@angular/material/input';

@Directive({
  selector: '[globalMatAutofocus]'
})
export class AutofocusDirective implements OnInit {

    @Input() globalMatAutofocus = false;
    @Input() autofocusSelectValue = false;

    constructor(
        private matInput: MatInput,
        private elRef: ElementRef<HTMLInputElement>,
    ) { }

    ngOnInit(): void {
        if (this.globalMatAutofocus) {
            setTimeout(() => {
                this.matInput.focus();

                if (this.autofocusSelectValue) {
                    // this.elRef.nativeElement.setSelectionRange(0, input.value.length);
                    this.elRef.nativeElement.setSelectionRange(0, this.matInput.value.length);
                }
            });
        }

    }

}
