import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '@servicios/query.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from '@angular/material/checkbox';
import {OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {mensajesErrorFormControl} from '@JVSoft/services/funciones-globales.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'general-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: {clickAction: 'check-indeterminate'} as MatCheckboxDefaultOptions,
        },
    ],
})
export class MenuComponent implements OnInit {
    mensajesErrorFormControl = mensajesErrorFormControl;

    @Input() objThis = this;
    @Input() checkbox = false;
    @Input() checkboxSeleccionados = [];
    @Input() menuContextual = undefined;
    @Input() botones: boolean = true;

    @Output() seleccionado = new EventEmitter<any>();
    @Output() listaCheck = new EventEmitter<any>();

    @Input() _dataDesdeCompPadre: any = {};

    dataServidor = {
        menus_agrupado: null,
        seg: {
            menus: null,
            perfiles_menus_derechos: null,
        },
    };
    seleccionados = {
        menus: null,
        perfiles_menus_derechos: null,
    };

    @ViewChild('dlgMenus', {static: true}) dlgMenusRef: TemplateRef<any>;


    frmRegistroDerechos: FormGroup;

    dialogRef: MatDialogRef<any>[] = [null, null, null];

    botonesMantenimiento: TablaMantenimientoSeccionBotones;
    frmRegistro: any;

    _dataMenus = new BehaviorSubject<any>(null);
    dataMenus$ = this._dataMenus.asObservable();

    constructor(
        private dialog: MatDialog,
        private fb: FormBuilder,
        private queryService: QueryService,
    ) {
    }

    ngOnInit(): void {
        this.crearFormularios();
        this.botonesMantenimiento = {
            menus: {
                principal: [
                    {
                        tipo: 'derechos', icono: 'outlineAdminPanelSettings', /*estaDeshabilitado(item: any): boolean { return !item || !item['iPerfMenuId'] || !item['iAccesoId']; }*/
                        esVisible:(): boolean => {
                            return this.botones;
                        },

                    },
                ],
            },
        };
        this.queryService.cargar(this, ['seg.menus'],{iEntId: this._dataDesdeCompPadre.iEntId}).then(() => {
            if (this.dataServidor.seg.menus) {
                this.dataServidor.seg.menus.filter(dat => {
                    const dataCredenciales = dataEnLista(this.checkboxSeleccionados, 'iMenuId', dat['iMenuId']);
                    if (dataCredenciales) {
                        const agregarCampo = (campo) => {
                            if (dataCredenciales[campo]) {
                                dat[campo] = dataCredenciales[campo];
                            }
                        };
                        agregarCampo('iPerfMenuId');
                        agregarCampo('iAccesoId');
                    }
                });
                this._dataMenus.next(this.dataServidor.seg.menus);
            }
        });
    }

    crearFormularios() {
        this.frmRegistroDerechos = this.fb.group({
            iPerfMenuId: ['', Validators.required],
            iDerechoId: ['', Validators.required],
        });
    }

    opcMenu(v: OpcionSeleccionada) {
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            maxHeight: '95vh',
            // maxWidth: '100vw',
            // minHeight:'98vh',
        };
        switch (v.seccion) {
            case 'menus':
                switch (v.tipo) {
                    case 'ver':
                        if (this.seleccionados.menus == v.item) {
                            this.seleccionados.menus = null;
                            this.seleccionado.emit('');
                        }
                        else {
                            this.seleccionados.menus = v.item;
                            this.seleccionado.emit(v.item);
                        }
                        break;
                    case 'derechos':
                    case 'menus_derechos':
                        let txtTitExtra = '';
                        if (this.seleccionados.menus?.cMenuNombrePersonalizado) {
                            txtTitExtra = ' [' + this.seleccionados.menus?.cMenuNombrePersonalizado + ']';
                        }
                        dialogOpc.data = {
                            titulo: this.seleccionados.menus.cMenuNombre + txtTitExtra,
                            menu: this.seleccionados.menus,
                        };
                        // this.dialog.open(DialogDerechosComponent, dialogOpc);
                        break;
                }
                break;
        }
    }

}
