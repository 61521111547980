import {mensajeAlerta, ordenarPorPropiedad} from '@JVSoft/services/funciones-globales.service';
import {imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {PdfLogisticaService} from '@pdf/pdf-logistica.service';
import {formatDate} from '@angular/common';

export function stockMovimientosProductos(objThis: PdfLogisticaService, dataRec, columnasTabla: ColumnaTabla<any>[]) {
    objThis.queryService.cargar(objThis, ['log.stock_movimientos_productos_detalles_reporte'], {
        ...dataRec,
        ...{
            iPaginacion: 0,
        }
    }).then((valRetorno) => {
        let resultadosGenerales = valRetorno['log.stock_movimientos_productos_detalles_reporte'];
        // resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Expediente');
        if (!resultadosGenerales || resultadosGenerales.length <= 0) {
            mensajeAlerta('error', 'Sin Datos', 'No existen datos que concuerden con los filtros seleccionados');
            return false;
        }

        objThis.reiniciarOpciones({
            margenesHoja: [40, 100, 40, 40],
            // orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: objThis.headerTableEntidad([
                imprimirCelda('REPORTE', null, {fontSize: 13, bold: true}),
                imprimirCelda('STOCK MOVIMIENTOS PRODUCTOS', null, {fontSize: 12, bold: true}),
            ]),
        });
        const cuerpoPdf = [];
        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: columnasTabla,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Expediente'),
            numeracion: true,
            idxResto: [3],
            margin: [0, 0, 0, 5],
            // separado: true,
        });

        cuerpoPdf.push(tablaGenerada);

        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}


export function reportePecosas(objThis: PdfLogisticaService, dataRec) {
    objThis.queryService.cargar(objThis, ['log.pecosas_reporte'], {
        ...dataRec,
        ...{
            iPaginacion: 0,
        }
    }).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.pecosas_reporte'];

        let dataGeneral;

        const cuerpoPdf = [];

        cuerpoPdf.push(objThis.detalleFiltro(objThis.filtroBusquedaService.getValue('pecosas_reporte').datosFiltroConLabel));

        const celdasHeaderDetalle = [
            [
                imprimirCelda('DATOS DEL BIEN', null, {colSpan: 3, style: 'thSmall'}),
                imprimirCelda(''),
                imprimirCelda(''),
                // imprimirCelda('U/M', null, {rowSpan: 2, style: 'thSmall'}),
                imprimirCelda('ESTABLECIMIENTO.', null, {rowSpan: 2, style: 'thSmall'}),
                imprimirCelda('DEPENDENCIA', null, {rowSpan: 2, style: 'thSmall'}),
                imprimirCelda('CANT.', null, {rowSpan: 2, style: 'thSmall'}),
                imprimirCelda('VALOR', null, {colSpan: 2, style: 'thSmall'}),
                imprimirCelda(''),
            ],
            [
                imprimirCelda('Nº', null, {style: 'thSmall'}),
                imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall'}),
                // imprimirCelda(''),
                imprimirCelda(''),
                imprimirCelda(''),
                imprimirCelda(''),
                imprimirCelda('P.U.', null, {style: 'thSmall'}),
                imprimirCelda('SUB-TOTAL', null, {style: 'thSmall'}),
            ],
        ];
        const celdasBodyDetalle = [];
        Object.keys(dataRecibido).forEach((idxSalida) => {
            if (!dataGeneral) {
                dataGeneral = dataRecibido[idxSalida][0];
            }

            let colorAnulado;
            dataRecibido[idxSalida].filter((dataProd, idxProd) => {
                let bordeProd = [true, false, true, false];
                if (idxProd == 0) {
                    const txtSeccion = [
                        dataProd.cTipoMovProdSigla,
                        dataProd.iSalidaNumero,
                        formatDate(dataProd.dSalidaEmision, 'dd/MM/yyyy', 'es-PE'),
                        'Receptor:',
                        dataProd.cPersNombreLargo + ' (' + dataProd.cCargoNombre + ') / ' + dataProd.cEstablecimientoNombreLargo + ' / ' + dataProd.cDepenNombre,
                    ];
                    if (dataProd.bSalidaAnuladoRegistro == 1) {
                        txtSeccion.push(' ANULADO');
                        colorAnulado = '#780000';
                    }
                    if (dataProd.iTareaId) {
                        txtSeccion.push('\nTarea: ' + [dataProd.cTareaCodigo, dataProd.cTareaNombre].join(' '));
                        // colorAnulado = '#780000';
                    }
                    celdasBodyDetalle.push(
                        [
                            imprimirCelda(txtSeccion.join(' '), null, {colSpan: 8, alignment: 'left', style: 'thSmall', color: colorAnulado}),
                            imprimirCelda(''),
                            // imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(''),
                        ],
                    );
                }
                if (idxProd == (dataRecibido[idxSalida].length - 1)) {
                    bordeProd = [true, false, true, true];
                }
                celdasBodyDetalle.push(
                    [
                        imprimirCelda(idxProd + 1, 'number', { border: bordeProd, style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(dataProd.cCodigoProducto, null, {border: bordeProd, style: 'tdSmall'}),
                        imprimirCelda(
                            (dataProd.cProductoNombreObs ?? '') +
                            (dataProd.bSalidaAnuladoRegistro == 1 ? (' ' + dataProd.cSalidaAnuladoMotivo) : ''), null, {border: bordeProd, style: 'tdSmall', color: colorAnulado}),
                        // imprimirCelda(dataProd.cPresentacionNombre, null, {style: 'tdSmall'}),
                        imprimirCelda(dataProd.cEstablecimientoNombreLargo, null, {border: bordeProd, style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(dataProd.cDepenNombre, null, {border: bordeProd, style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(dataProd.nSalidaDetCantidad, 'number', {border: bordeProd, style: 'tdSmall', alignment: 'center'}),
                        imprimirCelda(dataProd.nMovProdDetPrecioUnitario, 'mon', {border: bordeProd, style: 'tdSmall', alignment: 'right'}),
                        imprimirCelda(dataProd.nSalidaDetSubTotal, 'mon', {border: bordeProd, style: 'tdSmall', alignment: 'right'}),
                    ]
                );

            });
        });


        const tablaHeaderMovProd = {
            margin: [0, 5, 0, 10],
            table: {
                // widths: [25, 70, 25, 45, 158, 55, 55],
                headerRows: celdasHeaderDetalle.length,
                widths: objThis.anchoCols(celdasHeaderDetalle, [2]),
                body: celdasHeaderDetalle.concat(celdasBodyDetalle)
            }
        };

        cuerpoPdf.push(tablaHeaderMovProd);


        // const dataGeneral = dataRecibido[0][0];
        objThis.reiniciarOpciones({
            margenesHoja: [50, 80, 50, 40],
            tamanioFuenteExtra: 1,
            // defaultSizeHeaderTable: ['auto', '*', 'auto'],
            defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
            defaultRightCellHeaderTable: {
                // margin: [0, 10, 5, 0],
                table: {
                    widths: ['auto', '*'],
                    body: [
                        [
                            imprimirCelda('PAG.', null, {fontSize: 7, alignment: 'center'}),
                            imprimirCelda('FECHA', null, {fontSize: 7, alignment: 'center'}),
                        ],
                        [
                            imprimirCelda('##pagina##/##total_paginas##', null, {fontSize: 9, bold: true, alignment: 'center'}),
                            imprimirCelda(new Date(), 'fec', {
                                fontSize: 9,
                                bold: true,
                                alignment: 'center'
                            }),
                        ],
                    ]
                }
            },
            defaultCenterCellHeaderTable: {
                margin: [0, 20, 0, 0],
                alignment: 'center',
                stack: [
                    imprimirCelda('REPORTE DETALLADO DE PECOSAS', null, {
                        fontSize: 13,
                        bold: true
                    }),
                ]
            }
        });
        if (dataRec.callbackUrl) {
            return objThis.finalizarCreacionPdf(cuerpoPdf, 'otro', dataRec.callbackUrl);
        }
        objThis.finalizarCreacionPdf(cuerpoPdf);
    });
}
