
<general-tabla-mantenimiento-nuevo
    [opciones]="opcionesFiltro"
    (resultados)="buscarDatos($event);"
    [busquedaAutomatica]="true"

    [objThis]="this"
    [botonesCRUD]="btnMantenimiento"
    nombreColeccion="pedidos"
    campoAnulado="bPedidoAnuladoRegistro"
    campoAnuladoMensaje="cPedidoAnuladoMotivo"
    [botonesMenu]="botonesMantenimiento.pedidos"
    [dataSuscription]="dataPrincipal$"
    [columnasTabla]="columnsBandejaPedidos"
    (opcionSelecionada)="opcMenu($event)"

    (accionRecargar)="buscarDatos($event);"

>

<ng-container botonesFiltro *ngIf="esPorCredencial" [formGroup]="frmFiltro">
    <select formControlName="iYearId" class="border-primary border-solid border rounded mr-1 bg-white text-black">
        <option *ngFor="let item of dataServidor.log.years_pedidos" [value]="item.iPedidoYear">{{ item.iPedidoYear }}</option>
    </select>
</ng-container>
<ng-container tableDefinitions>

    <ng-container matColumnDef="docRef">
        <th *matHeaderCellDef class="uppercase" mat-header-cell>Atendido</th>
        <td *matCellDef="let row" mat-cell class="text-center">
            <ng-container [ngTemplateOutlet]="docAtendido" [ngTemplateOutletContext]="{row: row, columnaTabla: (estilosPorDefectoDocumentos(this, 'cCompraNumero'))}"></ng-container>
            <ng-container [ngTemplateOutlet]="docAtendido" [ngTemplateOutletContext]="{row: row, columnaTabla: (estilosPorDefectoDocumentos(this, 'cCotizaNumero'))}"></ng-container>
            <ng-container [ngTemplateOutlet]="docAtendido" [ngTemplateOutletContext]="{row: row, columnaTabla: (estilosPorDefectoDocumentos(this, 'cSalidaNumero'))}"></ng-container>

        </td>
    </ng-container>
</ng-container>
</general-tabla-mantenimiento-nuevo>


<ng-template #docAtendido let-columnaTabla="columnaTabla" let-row="row">
    <span *ngIf="columnaTabla && row[columnaTabla.property]"
            [class]="columnaTabla.textCss"
    >
        {{ row[columnaTabla.property] }}
    </span>
</ng-template>

<!--TEMPLATES-->
<ng-template #dialogRegistro>
    <form globalDialogFlotante btnGuardar btnCerrar="CANCELAR"
          (ngSubmit)="opcMenu({ seccion: 'pedidos', tipo: 'guardar', datosExtra: {cargarDetalles: true} })" [formGroup]="frmRegistro"
    >
        <ng-container titulo>
            {{ (frmRegistro.get('iPedidoId').value ? 'Editar' : 'Nuevo') }} ::<small>Pedido</small>
        </ng-container>

        <div cuerpo class="w-full flex flex-col items-center sm:gap-1 text-xs">
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Fecha de Emisión</mat-label>
                    <input matInput [matDatepicker]="dPedidoEmision" formControlName="dPedidoEmision" autocomplete="off" [readonly]="fechaSoloLectura" class="text-secondary"
                        (dblclick)="opcMenu({ seccion: 'pedidos', tipo: 'editar_fecha', datosExtra: {  } })"
                    >
                    <mat-datepicker-toggle matSuffix [for]="dPedidoEmision"></mat-datepicker-toggle>
                    <mat-datepicker #dPedidoEmision [disabled]="fechaSoloLectura" (dblclick)="opcMenu({ seccion: 'pedidos', tipo: 'editar_fecha', datosExtra: {  } })"></mat-datepicker>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('dPedidoEmision')) }}</mat-error>
                    <mat-hint class="whitespace-nowrap">* Doble click para editar (SUPERVISOR)</mat-hint>
                </mat-form-field>
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Clase Pedido</mat-label>
                    <mat-select formControlName="iClasePedidoId">
                        <mat-option [value]="item.iClasePedidoId" *ngFor="let item of dataServidor.log.destinos_clases_pedidos">{{ item.cClasePedidoNombre }}</mat-option>
                    </mat-select>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iClasePedidoId')) }}</mat-error>
                </mat-form-field>
                <mat-form-field class="flex-1 w-full" *ngIf="!(frmRegistro.get('iCredDepenId') | formControlIsRequired) && frmRegistro.get('iDestinoPedidoId').value == 1 && !frmRegistro.get('iPedidoId').value">
                    <mat-label>Tipo de Producto</mat-label>
                    <mat-select formControlName="iTipoProductoId">
                        <mat-option [value]="item.iTipoProductoId" *ngFor="let item of dataServidor.log.tipos_productos">{{ item.cTipoProductoNombre }}</mat-option>
                    </mat-select>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTipoProductoId')) }}</mat-error>
                </mat-form-field>
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Referencia</mat-label>
                    <input formControlName="cPedidoReferencia" autocomplete="off" matInput>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPedidoReferencia')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2" *ngIf="(frmRegistro.get('iCredDepenId') | formControlIsRequired) && dataServidor.bud.credenciales_dependencias">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Credencial</mat-label>
                    <mat-select formControlName="iCredDepenId" required panelClass="selectDetalles">
                        <mat-select-trigger>
                            <ng-container *ngTemplateOutlet="vistaOpcioniCredDepenId; context:{objMostrar: (dataServidor.bud.credenciales_dependencias | dataEnLista: 'iCredDepenId':frmRegistro.get('iCredDepenId').value)}"></ng-container>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of dataServidor.bud.credenciales_dependencias"
                            [value]="item.iCredDepenId"
                        >
                            <ng-container *ngTemplateOutlet="vistaOpcioniCredDepenId; context:{objMostrar: item}"></ng-container>
                        </mat-option>
                        <ng-template #vistaOpcioniCredDepenId let-objSeleccionado="objMostrar">
                            <div class="flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                                <div class="flex-1 w-full">
                                    <span class="text-red">Establecimiento: </span><i>{{objSeleccionado?.cEstablecimientoNombreLargo}}</i>
                                </div>
                                <div class="flex-1 w-full">
                                    <span class="text-red">Dependencia: </span><i>{{objSeleccionado?.cDepenNombre}}</i>
                                </div>
                            </div>
                        </ng-template>
                    </mat-select>
                    <mat-error>{{ funcionesGeneralesService.mensajesErrorFormControl(frmRegistro.get('iCredDepenId')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Solicitante</mat-label>
                    <general-mat-buscar-persona formControlName="iPersId" tipoPersona="trabajadores" incluirProveedorRemoto placeholder="Escriba en número de DNI, nombres o apellidos."></general-mat-buscar-persona>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iPersId')) }}</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 w-full">
                    <mat-label>Establecimiento</mat-label>
                    <mat-select formControlName="iEstablecimientoId">
                        <mat-option [value]="item.iEstablecimientoId" *ngFor="let item of dataServidor.log.establecimientos">
                            <span class="text-sm">{{ item.cEstablecimientoNombreLargo }}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iEstablecimientoId')) }}</mat-error>
                </mat-form-field>


                <mat-form-field class="flex-1 w-full" *ngIf="(dataServidorSuscripcion['grl.establecimientos_dependencias'] | async)">
                    <mat-label>Dependencia</mat-label>
                    <input matInput formControlName="iDepenId" [matAutocomplete]="iDepenId" autocomplete="off">
                    <mat-autocomplete #iDepenId="matAutocomplete"
                        panelWidth="auto"
                        [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iDepenId', campoValue: 'cDepenNombre', lista: (dataServidorSuscripcion['grl.establecimientos_dependencias'] | async)})"
                    >
                        <mat-option *ngFor="let item of filtrados['grl.establecimientos_dependencias'] | async" [value]="item.iDepenId">
                            <small>{{ item.cDepenNombre }}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <div matSuffix class="flex items-center justify-start">
                        <button matRipple type="button"
                            class="boton-circular-gris"
                            (click)="(frmRegistro.get('iDepenId').value ? frmRegistro.patchValue({iDepenId: ''}) : null); $event.preventDefault();"
                        >
                            <mat-icon [svgIcon]="(frmRegistro.get('iDepenId').value ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
                        </button>
                    </div>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iDepenId')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Año</mat-label>
                    <mat-select formControlName="iYearId">
                        <mat-option [value]="item.iYearId" *ngFor="let item of dataServidor.grl.years">
                            <span>{{ item.iYearId }}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iYearId')) }}</mat-error>
                </mat-form-field>
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Mes</mat-label>
                    <mat-select formControlName="iMonthId">
                        <mat-option [value]="item.iMonthId" *ngFor="let item of dataServidor.grl.months">
                            <span>{{ item.cMonthNombre }}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iMonthId')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2" *ngIf="!(frmRegistro.get('iCredDepenId') | formControlIsRequired)">

                <mat-form-field class="flex-1 w-full" *ngIf="(dataServidorSuscripcion['bud.tareas'] | async)">
                    <mat-label>Tarea</mat-label>
                    <textarea matInput formControlName="iTareaId" [matAutocomplete]="iTareaId" class="text-xs font-thin" cdkTextareaAutosize cdkAutosizeMinRows="1" autocomplete="off"></textarea>
                    <mat-autocomplete #iTareaId="matAutocomplete"
                        panelWidth="auto"
                        [displayWith]="mostrarValorEnBusqueda.bind(this,{campoId: 'iTareaId', campoValue: 'cTareaNombre', lista: (dataServidorSuscripcion['bud.tareas'] | async)})"
                    >
                        <mat-option [value]=""><small>Ninguno</small></mat-option>
                        <mat-option *ngFor="let item of filtrados['bud.tareas'] | async" [value]="item.iTareaId">
                            <small><span class="text-red-700">[{{ item.cTareaCodigo }}]</span>&nbsp;{{ item.cTareaNombre }}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <div matSuffix class="flex items-center justify-start">
                        <button matRipple type="button"
                            class="boton-circular-gris"
                            (click)="(frmRegistro.get('iTareaId').value ? frmRegistro.patchValue({iTareaId: ''}) : null); $event.preventDefault();"
                        >
                            <mat-icon [svgIcon]="(frmRegistro.get('iTareaId').value ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
                        </button>
                    </div>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iTareaId')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Observación</mat-label>
                    <textarea formControlName="cPedidoObs" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPedidoObs')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
            </div>
        </div>
    </form>
</ng-template>


<ng-template #detallePieDerecha let-item="item" let-dialog="dialog">
    <button matRipple class="boton-dialog-flotante text-amber-600 border-amber-600"
            *ngIf="seleccionados.pedidos.bPedidoCerrado != 1"
            (click)="opcMenu({ seccion: 'pedidos', tipo: 'editar_item', item, datosExtra: { dialog } })"
    >
        <mat-icon class="icon-xs mr-1" svgIcon="mat:edit" ></mat-icon>
        <span>EDITAR DATOS PEDIDO</span>
    </button>
</ng-template>

<ng-template #dialogRegistroPecosa>
    <form globalDialogFlotante btnGuardar btnCerrar="CANCELAR"
          (ngSubmit)="opcMenu({ seccion: 'pedidos', tipo: 'guardar_pecosa' })" [formGroup]="frmRegistroPecosa"
    >
        <ng-container titulo>Generar PECOSA</ng-container>


        <div cuerpo class="w-full flex flex-col items-center sm:gap-1 text-xs">
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Fecha</mat-label>
                    <input matInput [matDatepicker]="dSalidaEmision" formControlName="dSalidaEmision" autocomplete="off" readonly class="text-secondary">
                    <mat-datepicker-toggle matSuffix [for]="dSalidaEmision"></mat-datepicker-toggle>
                    <mat-datepicker #dSalidaEmision disabled="true"></mat-datepicker>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('dSalidaEmision')) }}</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 w-full">
                    <mat-label>Motivo Movimiento</mat-label>
                    <mat-select formControlName="iMotMovProdId">
                        <mat-option [value]="item.iMotMovProdId" *ngFor="let item of dataServidor.log.motivos_movimientos_productos">{{ item.cMotMovProdNombre }}</mat-option>
                    </mat-select>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iMotMovProdId')) }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Observación</mat-label>
                    <textarea formControlName="cSalidaObs" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cSalidaObs')) }}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>
