import {regular} from './regular';
import {solid} from './solid';

/** @deprecated Variable restringida */
const reemplazos = {
    icMapMarketAlt: solid.fa5sMapMarkedAlt,
    icMale: solid.fa5sMale,
    icFemale: solid.fa5sFemale,
    checkSquare: solid.fa5sCheckSquare,
    square: solid.fa5sSquare,
    caretDown: solid.fa5sCaretDown,
    fasCaretDown: solid.fa5sCaretDown,
    boxOpen: solid.fa5sBoxOpen,
    exchangeIcon: solid.fa5sExchangeAlt,
    lockIcon: solid.fa5sLock,
    lockOpen: solid.fa5sLockOpen,
    userTie: solid.fa5sUserTie,
    eyeIcon: solid.fa5sEye,
    fileIcon: solid.fa5sFile,
    fileAlt: solid.fa5sFileAlt,
    reply: solid.fa5sReply,
    share: solid.fa5sShare,
    exclamationTriangle: solid.fa5sExclamationTriangle,
    fasCaretRight: solid.fa5sCaretRight,
}
export const iconsFontAwesome = {
    ...reemplazos,
    ...regular,
    ...solid,
};
