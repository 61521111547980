<div globalDialogFlotante sinTitulo sinBarraDeAccion>
    <div @stagger cuerpo class="p-1 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2">
        <div class="flex flex-col cursor-pointer max-w-sm p-1 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100"
            *ngFor="let entidad of entidadesUsuario"
            (click)="seleccionarEntidad(entidad?.iEntId)"
            matRipple
        >
            <img [src]="servidorService.storageImageUrl + entidad.cEntLogoPrincipal" class="h-16"
                [alt]="entidad.cEntNombreLargo"
            >
            <div class="flex items-center justify-center text-secondary text-xs">
                <mat-icon svgIcon="mat:link" class="icon-xs"></mat-icon>
                <span>{{ entidad?.cEntWeb }}</span>
            </div>
        </div>
    </div>
</div>
