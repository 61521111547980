import {Component, EventEmitter, forwardRef, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryGeneralService} from '../../services/query-general.service';
import {fadeInUp400ms} from '../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../@vex/animations/stagger.animation';
import {scaleIn400ms} from '../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../@vex/animations/fade-in-right.animation';
import {PersonaComponent} from '../../components/persona/persona.component';
import {mensajeAlerta, mensajesDeError} from '@JVSoft/services/funciones-globales.service';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ServidorService} from '@servicios/servidor.service';

@Component({
	selector: 'general-fc-buscar-persona',
	templateUrl: './buscar-persona.component.html',
	styleUrls: ['./buscar-persona.component.scss'],
	animations: [
		fadeInUp400ms,
		fadeInRight400ms,
		scaleIn400ms,
		stagger40ms
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BuscarPersonaComponent),
			multi: true
		}
	],
})
export class BuscarPersonaComponent implements OnInit, OnChanges, ControlValueAccessor {
	@HostBinding('attr.id')
	externalId = '';

	@Input()
	set id(value: string) {
		this._ID = value;
		this.externalId = null;
	}

	get id() {
		return this._ID;
	}

	private _ID = '';

	@Input() size = '';
	@Input() btnLabel = '';
	@Input() label = '';
	@Input() placeholder = '';
	@Input() appearance: MatFormFieldAppearance = 'fill';
	@Input() incluirProveedorRemoto = false;
	@Input() idxTipoIdentidadNoPermitidos = [2];
	@Input() dbTest = '';
	@Input() tipoPersona: number | 'trabajadores' | 'proveedores' | 'individuos' = 1 ;
	@Input() iTipoIdentId: number;
	@Input() anonimo = false;
	@Input() dataRegistro = {};
	@Input() set disabled(val: boolean){
		this.isDisabled = val || false;
	}


	@Input() idx = '';

	@Input() formControlName;
	@Input() registro = false;
	@Input() registroPredeterminado = {};

	@Input() templateBotones: TemplateRef<any>;
	@Input() templateResultados: TemplateRef<any>;

	@Output() llamarLista = new EventEmitter<any>();
	@Output() resultados = new EventEmitter<any>();
	@Output() textoEscrito = new EventEmitter<any>();
	@Output() dataSeleccionado = new EventEmitter<any>();


	@Input() myLabel = '';

	@ViewChild('dialogResultados', {static: true}) dialogResultadosRef: TemplateRef<any>;

	frmBusqueda: FormGroup;
	frmRegistro: FormGroup;
	dataServidor = {
		personas: null,
		resPersonasReniec: null,
	};

	infoSeleccionado = null;
	inputValue = '';
	isDisabled: boolean = false;

    dialogRefSeleccion: MatDialogRef<any>;
	dialogOpc: MatDialogConfig = {
		panelClass: 'dialogMantenimientoNuevo',
		disableClose: true,
		data: {}
	};

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		public servidorService: ServidorService,
		public queryGeneral: QueryGeneralService,
	) {
        this.crearFormularios();
	}

	ngOnChanges(changes: SimpleChanges): void {
        this.frmBusqueda.reset();
        // throw new Error('Method not implemented.');
		//  console.log('entrada:' + this.tipoPersona);
		//  console.log('cambios:' + changes);
		 if (changes.placeholder) {
			this.placeholder = changes.placeholder.currentValue;
		}
		 if (changes.tipoPersona) {
			this.tipoPersona = changes.tipoPersona.currentValue;
		}
		 if (changes.iTipoIdentId) {
			this.registroPredeterminado = {
				...this.registroPredeterminado,
				...{
					iTipoIdentId: changes.iTipoIdentId.currentValue,
				},
			};
		}
		 if (changes.registroPredeterminado) {
			this.registroPredeterminado = {
				...this.registroPredeterminado,
				...changes.registroPredeterminado.currentValue,
			};
		}
		 if (changes.dataRegistro) {
			this.dataRegistro = {
				...this.dataRegistro,
				...changes.dataRegistro.currentValue,
			};
		}
/*		if (changes.tipoPersona) {
			this.registroPredeterminado = {
				...this.registroPredeterminado,
				...{
					iTipoIdentId: changes.tipoPersona.currentValue,
				},
			};
		}*/
    }

	get frmBusquedaControl() {
		return this.frmBusqueda.controls;
	}

	get frmRegistroControl() {
		return this.frmRegistro.controls;
	}

	onChange = (_: any) => {};

	onTouch = () => {};

	ngOnInit() {
		console.log('parametro:' + this.tipoPersona);
		this.crearFormularios();
		console.log(this.idxTipoIdentidadNoPermitidos);
		switch (this.tipoPersona) {
			case 1:
				this.registroPredeterminado = {
					iTipoPersId: 1,
					iTipoIdentId: 1,
				};
				break;
		}

		this.frmBusquedaControl.cCampoBusqueda.valueChanges.subscribe((vS) => {
			this.textoEscrito.emit(vS);
		});
	}

	crearFormularios() {
		this.frmBusqueda = this.formBuilder.group({
			iPersId: [''],
			cCampoBusqueda: [''],
			iTipoPersId: [this.tipoPersona],
		});
		this.frmRegistro = this.formBuilder.group({
			iPersId: [''],
			txtBuscar: [''],
			idTipo: [this.tipoPersona],
		});
	}

	onInput(value: string) {
		console.log('Input' + value);
		this.inputValue = value;
		this.onTouch();
		this.onChange(this.inputValue);
		// console.log('entrada');
	}

	writeValue(value: any): void {
		console.log('writeValue' + value);
		console.log(value);
		if (value) {
			this.frmBusqueda.reset({
				iPersId: value,
			});
			// this.dataServidor.resPersonas = null;
			if (!this.infoSeleccionado) {
				this.buscarPersonas().then(ret => {
					console.log(ret);

					this.seleccionarPersona(ret['personas'][0]);
					// this.dataServidor.resPersonas = ret;
				});
			}
			this.inputValue = value || '';
		} else {
			this.inputValue = '';
		}
		// console.log('write');
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	abrirModal(v) {
		this.dataServidor.personas = null;
		if (v.accion && v.accion == 'nuevo') {
			this.dataServidor.personas = [];
			// this.dialogRef = this.dialog.open(this.dialogResultadosRef, this.dialogOpc);
			// this.modalService.open(v.modal, {ariaLabelledBy: 'modal-basic-title', centered: true});
			const dialogOpc: MatDialogConfig = {
				panelClass: 'dialogMantenimientoNuevo',
				disableClose: true,
				data: {}
			};
			dialogOpc.data.persona = this.registroPredeterminado;
			this.abrirDialog(dialogOpc, v);
			return;
		}
		if (this.frmBusquedaControl.cCampoBusqueda.value) {
			if (this.incluirProveedorRemoto) {
				console.log(this.frmBusquedaControl.cCampoBusqueda.value);

				this.llenarDataServidor(false).then(() => {
					if (!isNaN(Number(this.frmBusquedaControl.cCampoBusqueda.value)) && this.dataServidor.personas && this.dataServidor.personas.length < 1) {
						console.log('BUSCANDO');
						// @ts-ignore
						this.registroPredeterminado.cPersDocumento = this.frmBusquedaControl.cCampoBusqueda.value;
						let tipo;
						let dataEnviar;
						switch (this.frmBusquedaControl.cCampoBusqueda.value.length) {
							case 8:
								tipo = 'reniec';
								dataEnviar = {dni: this.frmBusquedaControl.cCampoBusqueda.value};
								break;
							case 11:
								tipo = 'sunat';
                                if (!this.verificarRUC(this.frmBusquedaControl.cCampoBusqueda.value) ) {
                                    return false;
                                }
								dataEnviar = {ruc: this.frmBusquedaControl.cCampoBusqueda.value};
								break;
						}
						if (tipo) {
							this.buscarPersonasProveedorRemoto(tipo, dataEnviar).then((a) => {
								this.llenarDataServidor().then();
							}).catch(err => {
                                mensajesDeError(err);
                            });
						}
					}
					else {
						this.llenarDataServidor().then();
					}
				});

			} else {
				this.llenarDataServidor().then();
			}
		}

		event.preventDefault();
	}

	llenarDataServidor(abrirModal = true) {
		return this.buscarPersonas().then(ret => {
			this.resultados.emit(this.dataServidor.personas);

			if (this.dataServidor.personas.length < 1) {
				console.log(this.frmBusqueda.value);
			}
            else if (this.dataServidor.personas.length == 1) {
                this.seleccionarPersona(this.dataServidor.personas[0]);
                return false;
            }
			if (abrirModal) {
				this.dialogRefSeleccion = this.dialog.open(this.dialogResultadosRef, this.dialogOpc);
			}
		});
	}

	personaCreada(dataPersonaCreada, modal) {
		this.frmBusqueda.reset({
			iPersId: dataPersonaCreada.iPersId
		});
		this.buscarPersonas().then(ret => {
			this.seleccionarPersona(ret);
			modal.close();
		});
	}

	buscarPersonas() {
		const tipoReq = 'personas';
		const dEnv = {};
		switch (this.tipoPersona) {
			case 'trabajadores':
				dEnv['trabajadores'] = 1;
				break;
			case 'proveedores':
				dEnv['proveedores'] = 1;
				break;
			case 'individuos':
				dEnv['individuos'] = 1;
				break;
		}
		let dBusqueda = {
			...this.frmBusqueda.value,
			...dEnv
		};
		if (this.iTipoIdentId) {
			dBusqueda = {
				...dBusqueda,
				...{
					iTipoIdentId: this.iTipoIdentId,
					iTipoPersId: undefined
				}
			};
		}
		return this.queryGeneral.cargar(this, ['personas'], dBusqueda, this.anonimo).then((ret) => {
			return ret;
		});
	}

	buscarPersonasProveedorRemoto(tipo, dataExtra) {
		return this.queryGeneral.getDataProveedorRemoto(tipo, dataExtra);
	}


	seleccionarPersona(info) {
		this.onInput(info.iPersId);
		this.infoSeleccionado = info;
		this.frmBusquedaControl.cCampoBusqueda.setValue(info.cPersNombreLargo.trim());
		this.dataSeleccionado.emit(info);
		this.cerrarDialog();
	}

	cerrarDialog() {
		if (this.dialogRefSeleccion) {
			this.dialogRefSeleccion.close();
		}
	}

	limpiarControl() {
		this.frmBusqueda.reset();
		this.infoSeleccionado = null;
		// this.inputValue = null;
		// this.writeValue(null);
		this.onInput(null);
	}

	abrirDialog(dialogOpc: MatDialogConfig, v) {
		dialogOpc.data = {
			...dialogOpc.data,
			...this.dataRegistro,
		};

		const dialogPersona = this.dialog.open(PersonaComponent, dialogOpc);
		dialogPersona.componentInstance.onSubmitReason.subscribe(dat => {
			// this.opcMenu({
			// 	...v,
			// 	...{
			// 		tipo: 'guardar',
			// 		formGroup: dat,
			// 		dialog: dialogPersona,
			// 	}
			// });
			this.queryGeneral.enviarFormulario('mantenimiento#personas', dat, true, 'grl').then((dataGuardado) => {
				if (dataGuardado && dataGuardado['data'].iPersId) {
					this.queryGeneral.cargar(this, ['personas'], {iPersId: dataGuardado['data'].iPersId}, this.anonimo).then((dPers) => {
						dialogPersona.close();
						if (dPers['personas'][0]) {
							this.seleccionarPersona(dPers['personas'][0]);
						}
					});

				}
			});
			console.log(dat);
		});
	}

    verificarRUC(ruc: string) {
        const f = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        const rucArray = ruc.split('');
        const nArray = f.map((item, idx) => {
            return item * parseFloat(rucArray[idx]);
        });
        const suma = nArray.reduce((a, b) => a + b, 0);
        const residuo = suma % 11;
        const residuo2 = 11 - residuo;
        // @residuo=CONVERT(Integer,Right(CONVERT(VarChar,@residuo),1))
        const residuo3 = residuo2.toString().charAt(residuo2.toString().length - 1);
        const ultimoCaracter = ruc.toString().charAt(ruc.toString().length - 1);
        if (residuo3 == ultimoCaracter) {
            return true;
        }
        mensajeAlerta('error', 'Datos No válidos', ' El número de RUC no es válido');
        return false;
        /*
        CREATE FUNCTION grl.Fn_SEL_bValidarRUCXcNumeroRUC(@cNumeroRUC VARCHAR(11))
	RETURNS BIT
AS
BEGIN
	DECLARE @bValido BIT
	DECLARE @c1 int,@c2 int,@c3 int,@c4 int,@c5 int,@c6 int,@c7 int,@c8 int,@c9 int,@c10 int,@c11 int
	DECLARE @f1 int=5,@f2 int=4,@f3 int=3,@f4 int=2,@f5 int=7,@f6 int=6,@f7 int=5,@f8 int=4,@f9 int=3,@f10 int=2
	SET @c1=CONVERT(Integer,SUBSTRING(@cNumeroRUC,1,1))
	SET @c2=CONVERT(Integer,SUBSTRING(@cNumeroRUC,2,1))
	SET @c3=CONVERT(Integer,SUBSTRING(@cNumeroRUC,3,1))
	SET @c4=CONVERT(Integer,SUBSTRING(@cNumeroRUC,4,1))
	SET @c5=CONVERT(Integer,SUBSTRING(@cNumeroRUC,5,1))
	SET @c6=CONVERT(Integer,SUBSTRING(@cNumeroRUC,6,1))
	SET @c7=CONVERT(Integer,SUBSTRING(@cNumeroRUC,7,1))
	SET @c8=CONVERT(Integer,SUBSTRING(@cNumeroRUC,8,1))
	SET @c9=CONVERT(Integer,SUBSTRING(@cNumeroRUC,9,1))
	SET @c10=CONVERT(Integer,SUBSTRING(@cNumeroRUC,10,1))
	SET @c11=CONVERT(Integer,SUBSTRING(@cNumeroRUC,11,1))
  	SET @c1=@c1*@f1
	SET @c2=@c2*@f2
	SET @c3=@c3*@f3
	SET @c4=@c4*@f4
	SET @c5=@c5*@f5
	SET @c6=@c6*@f6
	SET @c7=@c7*@f7
	SET @c8=@c8*@f8
	SET @c9=@c9*@f9
	SET @c10=@c10*@f10
	DECLARE @suma int=0,@residuo int
	SET @suma=@c1+@c2+@c3+@c4+@c5+@c6+@c7+@c8+@c9+@c10
	SET @residuo=@suma % 11
	SET @residuo=11-@residuo
	SET @residuo=CONVERT(Integer,Right(CONVERT(VarChar,@residuo),1))
	IF @residuo=@c11
		BEGIN SET @bValido=1 END
	ELSE
		BEGIN SET @bValido=0 END
	RETURN @bValido
END
         */
    }
}
