import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl, ValidationErrors} from '@angular/forms';
import {Subscription} from 'rxjs';


@Directive({
	selector: '[globalValidationLabel]'
})
export class ValidationLabelDirective implements AfterViewInit {


	constructor(private elRef: ElementRef, private control: NgControl,) { }


	@Input('formControlName') formControlName: string;
	errorSpanId = '';
	statusChangeSubscription: Subscription;


	ngAfterViewInit(): void {
		this.errorSpanId = this.formControlName + '-error';
		this.statusChangeSubscription = this.control.statusChanges.subscribe(
			(status) => {
				console.log(status);
				if (status === 'INVALID') {
					this.showError();
				} else {
					this.removeError();
				}
			}
		);
	}


	@HostListener('blur', ['$event'])
	handleBlurEvent(event) {
		console.log('this.control.value => ', this.control.value);
		// This is needed to handle the case of clicking a required field and moving out.// Rest all are handled by status change subscriptionif (this.control.value === null || this.control.value === '') {
		if (this.control.errors) {
			this.showError();
		} else {
			this.removeError();
		}
	}



	private showError() {
		this.removeError();
		const valErrors: ValidationErrors = this.control.errors;
		const firstKey = Object.keys(valErrors)[0];
		const errorMsgKey = this.formControlName + '-' + firstKey;
		const errorMsg = 'ERROR APR'; // this.validationMsgService.getValidationMsg(errorMsgKey);
		const errSpan = '<span style="color:red;" id="' + this.errorSpanId + '">' + errorMsg + '</span>';
		this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
		this.elRef.nativeElement.classList.add('is-invalid');
	}


	private removeError(): void {
		const errorElement = document.getElementById(this.errorSpanId);
		if (errorElement) {
			this.elRef.nativeElement.classList.remove('is-invalid');
			errorElement.remove();
			console.log(errorElement);
		}
	}


}
