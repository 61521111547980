<mat-tree #tree [dataSource]="listaMuestraArbol" [treeControl]="treeControl">
	<mat-tree-node class="min-h-0" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [matTreeNodeToggleRecursive]="expandirRecursivo">
		<ng-container [ngTemplateOutlet]="liDat" [ngTemplateOutletContext]="{ node, nested: false }"></ng-container>
	</mat-tree-node>
	<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
		<div class="flex flex-col trans-ease-out cursor-pointer" matTreeNodeToggle [matTreeNodeToggleRecursive]="expandirRecursivo">
			<ng-container [ngTemplateOutlet]="liDat" [ngTemplateOutletContext]="{ node, nested: true }"></ng-container>
			<div class="pl-4" [class.tree-invisible]="!treeControl.isExpanded(node)">
				<ng-container matTreeNodeOutlet></ng-container>
			</div>
		</div>
	</mat-nested-tree-node>
</mat-tree>


<ng-template #liDat let-node="node" let-nested='nested'>
	<div class="flex items-center gap-0 text-sm trans-ease-out cursor-pointer p-0 w-full"
		(contextmenu)="this.menuContextual?.abrirMenuContextual($event, node); $event. preventDefault();"
		*ngIf="!node.esVisible || node.esVisible(node)"
		[ngClass]="classFila(node)"
		(click)="opcMenu({seccion: nombreColeccion, tipo: 'ver', item: node}); menuContextual?.cerrarMenuContextual();"
	>
		<mat-icon *ngIf="!nested"></mat-icon>
        <mat-icon *ngIf="nested" [svgIcon]="treeControl.isExpanded(node) ? 'mat:expand_more' : 'mat:chevron_right'"></mat-icon>
		<mat-checkbox *ngIf="checkbox"
					  color="primary"
					  [(ngModel)]="this.chkLista.modelosChk[node[campoId]]"
                      [ngModelOptions]="{standalone: true}"
					  (click)="$event.stopPropagation()"
					  (change)="cambiarCheck(node, $event.checked); emitirModeloCheck()"

					  [checked]="nested ? hijosActivos('checked', node) : false"
					  [indeterminate]="nested ? hijosActivos('indeterminate', node) : false"
		></mat-checkbox>
		<span *ngIf="!templateTxtData" class="w-full">{{ node[campoStr] }} {{ this.chkLista.modelosChk[node[campoId]] }}</span>
		<ng-container *ngIf="templateTxtData" [ngTemplateOutlet]="templateTxtData" [ngTemplateOutletContext]="{data: node, treeControl}"></ng-container>
	</div>
</ng-template>
