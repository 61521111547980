
<div class="filtroBusqueda w-full flex flex-col flex-wrap gap-2 border" *ngIf="listaTabs && listaTabs.length > 0">
    <mat-tab-group dynamicHeight mat-align-tabs="start" class="flex-1 flex flex-wrap" [(selectedIndex)]="indexTab"
        (selectedTabChange)="cambiarTab()" (focusChange)="accionPreviaCambiarTab()"
    >
        <ng-container *ngFor="let item of listaTabs; let indexOfelement=index;"
            [ngTemplateOutlet]="templateTab"
            [ngTemplateOutletContext]="{label: item.label, idx: indexOfelement}"
        ></ng-container>

        <ng-template #templateTab let-label="label" let-idx="idx">
            <mat-tab [aria-label]="(indexTab == idx) ? 'primary' : ''">
                <ng-template mat-tab-label>
                    <mat-icon *ngIf="(indexTab == idx)" svgIcon="mat:verified" class="icon-xs"></mat-icon>&nbsp;
                    {{ label }}
                </ng-template>
                <div class="mx-2 mt-0 pt-0 flex flex-col items-end sm:flex-row flex-wrap gap-2" *ngIf="mostrarLista">
                    <general-tabla-mantenimiento-nuevo
                        class="w-full"
                        botonesCRUD=""
                        *ngIf="tabCargado"
                        [objThis]="this"
                        ctrlBusqueda="simple"
                        [nombreColeccion]="itemListaTab.nombreColeccion"
                        [idTabla]="itemListaTab.idTabla"
                        [dataSuscription]="itemListaTab._data.asObservable()"
                        [columnasTabla]="itemListaTab.columnasTabla"

                        [exportarExcel]="false"
                    >

                        <ng-container tableDefinitions>
                            <ng-container matColumnDef="nPorcentaje_avance2">
                                <th mat-header-cell *matHeaderCellDef>% Avance</th>
                                <td mat-cell *matCellDef="let element">
                                    <jvsoft-progress-bar [porcentaje]="(element.nPorcentaje_avance * 1)" [coloresValor]="[
		{valorMaximo: 1, class: 'p-0'},
		{valorMaximo: 40, class: 'bg-red-400 text-red-900'},
		{valorMaximo: 80, class: 'bg-yellow-600 text-yellow-100'},
		{valorMaximo: 100, class: 'bg-green-600 text-green-100'},
	]"></jvsoft-progress-bar>
                                </td>
                            </ng-container>
                        </ng-container>
                    </general-tabla-mantenimiento-nuevo>

                    <!--
                    <general-tabla-mantenimiento-nuevo
                        class="w-full"
                        botonesCRUD=""
                        *ngIf="tabCargado"
                        [objThis]="objThis"
                        ctrlBusqueda="simple"
                        [nombreColeccion]="itemListaTab['nombreColeccion']"
                        [idTabla]="itemListaTab['idTabla']"
                        [dataSuscription]="itemListaTab._data.asObservable()"
                        [columnasTabla]="itemListaTab['columnasTabla']"

                        [botonesMenu]="botonesMantenimiento[itemListaTab['nombreColeccion']] ??  {}"

                        [filaExtraTemplate]="dlgExpedienteDetalleIncrustado"

                        (opcionSelecionada)="opcMenu($event)"

                        (accionRecargar)="cambiarTab(true);"
                        [exportarExcel]="false"
                    >
                    </general-tabla-mantenimiento-nuevo>
                    -->
                </div>
            </mat-tab>
        </ng-template>
    </mat-tab-group>
    <div class="mx-2 mt-0 pt-0 flex flex-col items-center justify-start sm:flex-row flex-wrap gap-2 h-[100px]" *ngIf="!mostrarLista || !tabCargado">
        <div class="text-center flex-auto" *ngIf="!mostrarLista">SELECCIONE UNA TAREA PARA VER SU DETALLE</div>
        <div class="text-center flex-auto" *ngIf="mostrarLista && !tabCargado">CARGANDO DATOS</div>
    </div>
</div>


<ng-template #dlgExpedienteDetalleIncrustado let-row="row">
    <div class="w-full" *ngIf="row['tabSeleccionado'] != undefined">
        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false" [(selectedIndex)]="row['tabSeleccionado']"
            (selectedTabChange)="row['mostrarDetalle'] = true" (focusChange)="row['mostrarDetalle'] = false"
        >

            <ng-container *ngFor="let item of itemListaTab.detalle; let indexOfelement=index;"
                [ngTemplateOutlet]="templateTab2"
                [ngTemplateOutletContext]="{label: item.label, idx: indexOfelement}"
            ></ng-container>
            <ng-template #templateTab2 let-label="label" let-idx="idx">

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="(row['tabSeleccionado'] == idx)" svgIcon="mat:verified" class="icon-xs"></mat-icon>&nbsp;
                        {{ label }}
                    </ng-template>
                    <general-tabla-mantenimiento-nuevo
                        *ngIf="row['mostrarDetalle']"
                        botonesCRUD=""
                        [objThis]="objThis"
                        [nombreColeccion]="itemListaTab.detalle[row['tabSeleccionado']].nombreColeccion"
                        [filtroCampos]="true"
                        [data]="row[itemListaTab.detalle[row['tabSeleccionado']].nombreColeccion]"
                        [columnasTabla]="itemListaTab.detalle[row['tabSeleccionado']].columnasTabla"
                        (opcionSelecionada)="opcMenu($event)"
                        [paginador]="false"

                        [filaExtraTemplate]="dlgExpedienteDetalleIncrustado"
                        [exportarExcel]="false"
                    >
                    </general-tabla-mantenimiento-nuevo>
                </mat-tab>

            </ng-template>
        </mat-tab-group>


    </div>
</ng-template>
