<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
	<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
		<button mat-icon-button disabled></button>
		<mat-checkbox class="checklist-leaf-node"
					  [checked]="checklistSelection.isSelected(node)"
					  (change)="checklistSelection.toggle(node);">{{node.item}}</mat-checkbox>
	</mat-tree-node>

	<!--<mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
		<button mat-icon-button disabled></button>
		<mat-form-field>
			<input matInput #itemValue placeholder="New item...">
		</mat-form-field>
		<button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
	</mat-tree-node>-->

	<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
		<button mat-icon-button matTreeNodeToggle
				[attr.aria-label]="'toggle ' + node.filename">
            <mat-icon [svgIcon]="treeControl.isExpanded(node) ? 'mat:expand_more' : 'mat:chevron_right'"></mat-icon>
		</button>
		<mat-checkbox [checked]="descendantsAllSelected(node)"
					  [indeterminate]="descendantsPartiallySelected(node)"
					  (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
<!--		<button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>-->
	</mat-tree-node>
</mat-tree>
