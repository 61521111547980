import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'dateDiffString',
	pure: true
})
export class DateDiffStringPipe implements PipeTransform {
	transform(startingDate: Date, endingDate: Date): any {
		let startDate = new Date(startingDate);
		// user not pass endingDate then set current date as end date.
		if (!endingDate) {
			endingDate = new Date();
		}
		let endDate = new Date(endingDate);
		// chack start date and end date and base on condication alter date.
		if (startDate > endDate) {
			const swap = startDate;
			startDate = endDate;
			endDate = swap;
		}

		const disgregado = this.separarMilisegundos(endDate.getTime() - startDate.getTime());
		// console.log(disgregado);
		const formatearATexto = (arrayToFill: string[], campo, texto, textoPlural = 's') => {
			if (campo > 0) {
				arrayToFill.push( campo + ' ' + texto + (campo > 1 ? textoPlural : '') );
			}
			return arrayToFill;
		};

		let arrDFinal = [];
		arrDFinal = formatearATexto(arrDFinal, disgregado.years, 'año');
		arrDFinal = formatearATexto(arrDFinal, disgregado.months, 'mes', 'es');
		arrDFinal = formatearATexto(arrDFinal, disgregado.weeks, 'semana');
		arrDFinal = formatearATexto(arrDFinal, disgregado.days, 'día');
		arrDFinal = formatearATexto(arrDFinal, disgregado.hours, 'hora');
		arrDFinal = formatearATexto(arrDFinal, disgregado.minutes, 'minuto');
		arrDFinal = formatearATexto(arrDFinal, disgregado.seconds, 'segundo');
		if (arrDFinal.length > 3) {
			arrDFinal.splice(3);
			arrDFinal.splice(-1, 0, 'y');
		} else {
			if (arrDFinal.length > 1) {
				arrDFinal.splice(-1, 0, 'y');
			}
		}
		return arrDFinal.join(' ');
	}

	separarMilisegundos(milisegundos): {
		years: number,
		months: number,
		weeks: number,
		days: number,
		hours: number,
		minutes: number,
		seconds: number,
	} {
		const todoEnSegundos = milisegundos / 1000;
		let secsUsed = 0;
		const years = Math.floor(todoEnSegundos / 31536000);
		if (years > 0) {
			secsUsed += (years * 31536000);
		}
		const months = Math.floor((todoEnSegundos - secsUsed) / 2628288);
		if (months > 0) {
			secsUsed += (months * 2628288);
		}
		const weeks = Math.floor((todoEnSegundos - secsUsed) / 604800);
		if (weeks > 0) {
			secsUsed += (weeks * 604800);
		}
		const days = Math.floor((todoEnSegundos - secsUsed) / 86400);
		if (days > 0) {
			secsUsed += (days * 86400);
		}
		const hours = Math.floor((todoEnSegundos - secsUsed) / 3600);
		if (hours > 0) {
			secsUsed += (hours * 3600);
		}
		const minutes = Math.floor((todoEnSegundos - secsUsed) / 60);
		if (minutes > 0) {
			secsUsed += (minutes * 60);
		}
		const seconds = Math.round(todoEnSegundos - secsUsed);

		return {
			years,
			months,
			weeks,
			days,
			hours,
			minutes,
			seconds,
		};
	}

/*
	transform(to: Date, from: Date, seconds = true, strict = false): string {
		if (typeof to == 'string') {
			to = new Date(to);
		}
		if (typeof from == 'string') {
			from = new Date(from);
		}
		console.log(typeof to, typeof from);
		console.log(to);
		console.log(from);

		return strict ? formatDistanceStrict(from, to, {unit: 'minute', locale: es}) : formatDistance(from, to, {includeSeconds: seconds, locale: es});
	}
	*/
}

export function dateDiffString(startingDate: Date, endingDate: Date){
	return new DateDiffStringPipe().transform(startingDate, endingDate);
}
