import { Pipe, PipeTransform } from '@angular/core';

interface OpcionesBusqueda {
	texto: string;
	longitudMinima?: number;
	campo?: string | string [];
	mostrarObligado?: MostrarObligado;
}
interface MostrarObligado {
	campo: string;
	items: any[];
}

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

	transform(items: any[], opcionesBusqueda: OpcionesBusqueda): any[] {
		if (!items) { return []; }
		if (!opcionesBusqueda.texto || opcionesBusqueda.texto.length < opcionesBusqueda.longitudMinima) { return items; }

		const normalize = (() => {
			const from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç';
			const to   = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc';
			const mapping = {};

			for (let i = 0, j = from.length; i < j; i++ ) {
				mapping[ from.charAt( i ) ] = to.charAt( i );
			}

			return ( str ) => {
				const ret = [];
				for ( let i = 0, j = str.length; i < j; i++ ) {
					const c = str.charAt( i );
					if ( mapping.hasOwnProperty( str.charAt( i ) ) ) {
						ret.push( mapping[ c ] );
					}
					else {
						ret.push( c );
					}
				}
				return ret.join( '' );
			};

		})();

		opcionesBusqueda.texto = normalize(opcionesBusqueda.texto.toLowerCase());

		return items.filter( code => {
			if (opcionesBusqueda.mostrarObligado) {
				if (opcionesBusqueda.mostrarObligado.items.includes(code[opcionesBusqueda.mostrarObligado.campo])) {
					return true;
				}
			}
			let cadenaData;
			if (Array.isArray(opcionesBusqueda.campo)) {
				let encontrado = false;
				opcionesBusqueda.campo.forEach(campo => {
				    if (code[campo]) {
                        cadenaData = normalize(code[campo].toLowerCase());
                        if (!encontrado) {
                            encontrado = cadenaData.includes(opcionesBusqueda.texto);
                        }
                    }
				});
				return encontrado;
			} else {
				cadenaData = normalize( code[opcionesBusqueda.campo].toLowerCase() );
				return cadenaData.includes(opcionesBusqueda.texto);
			}
		});

	}

}
