import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutModule} from '../../@vex/layout/layout.module';
import {TramiteLayoutComponent} from './tramite-layout/tramite-layout.component';
import {SidenavModule} from '@vexMod/layout/sidenav/sidenav.module';
import {FooterModule} from '../../@vex/layout/footer/footer.module';
import {ConfigPanelModule} from '../../@vex/components/config-panel/config-panel.module';
import {SidebarModule} from '../../@vex/components/sidebar/sidebar.module';
import {QuickpanelModule} from '../../@vex/layout/quickpanel/quickpanel.module';
import {ModulosRoutingModule} from '../modulos/modulos-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {PrincipalLayoutComponent} from './principal-layout/principal-layout.component';
import {ExternosLayoutComponent} from './externos-layout/externos-layout.component';
import {PageLayoutModule} from '@vex/components/page-layout/page-layout.module';
import {ProgressBarModule} from '@vex/components/progress-bar/progress-bar.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import {GeneralModule} from '../general/general.module';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {SelectLayoutComponent} from './select-layout/select-layout.component';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ToolbarModule} from "@vexMod/layout/toolbar/toolbar.module";
import {VexModModule} from "@vexMod/vex-mod.module";
import {BreadcrumbsModule} from '@vexMod/components/breadcrumbs/breadcrumbs.module';


@NgModule({
  declarations: [TramiteLayoutComponent, PrincipalLayoutComponent, ExternosLayoutComponent, SelectLayoutComponent],
    imports: [
        CommonModule,
        ModulosRoutingModule,
        LayoutModule,
        SidenavModule,
        ToolbarModule,
        FooterModule,
        QuickpanelModule,
        ConfigPanelModule,
        SidebarModule,
        ReactiveFormsModule,
        DragDropModule,
        FlexLayoutModule,
        MatDialogModule,
        MatButtonModule,
        MatDividerModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,
        PageLayoutModule,
        ProgressBarModule,
        MatSidenavModule,
        JVSoftModule,
        GeneralModule,
        BreadcrumbsModule,
        MatSelectModule,
        MatListModule,
        MatTooltipModule,
        MatAutocompleteModule,
        FormsModule,
        VexModModule,
        BreadcrumbsModule,
    ],
          })
export class LayoutsModule { }
