import {Injectable} from '@angular/core';
import {ConfigService} from '@vex/config/config.service';
import {BehaviorSubject} from 'rxjs';
import {configs} from '@vex/config/configs';
import {VexConfigMod} from '@vexMod/config/vex-config.interface';
import {ColorSchemeName} from '@vex/config/colorSchemeName';
import {VexConfigName} from "@vex/config/config-name.model";
import {DeepPartial} from "@vex/interfaces/deep-partial.type";
import {VexConfig} from "@vex/config/vex-config.interface";
import {mergeDeep} from "@vex/utils/merge-deep";

@Injectable({
  providedIn: 'root'
})
export class ConfigServiceMod extends ConfigService{

    configs: VexConfigMod[] = configs;

    private _configSubjectMod = new BehaviorSubject(this.configs.find(c => c.id === this.defaultConfig));
    configMod$ = this._configSubjectMod.asObservable();

    // setConfig(config: VexConfigName) {
    //     const settings = this.configs.find(c => c.id === config);
    //     this._configSubjectMod.next(settings);
    // }
    //
    // updateConfig(config: DeepPartial<VexConfigMod>) {
    //     this._configSubjectMod.next(mergeDeep({ ...this._configSubjectMod.getValue() }, config));
    // }

    enableDarkMode(): void {
        localStorage.setItem('darkMode', '1');
        this.updateConfig({
            style: {
                colorScheme: ColorSchemeName.dark
            }
        });
    }

    disableDarkMode(): void {
        localStorage.setItem('darkMode', '0');
        this.updateConfig({
            style: {
                colorScheme: ColorSchemeName.default
            }
        });
    }

    isDark(colorScheme: ColorSchemeName): boolean {
        return colorScheme === ColorSchemeName.dark;
    }
}
