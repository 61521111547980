<!--<div globalDialogFlotante btnCerrar class="fullScreen">
    <ng-container titulo>{{ datosDialogRecibidos?.titulo }}</ng-container>
&lt;!&ndash;    <ng-container cuerpo>
        ALGO
    </ng-container>&ndash;&gt;
    <embed cuerpo class="w-full h-full" *ngIf="srcFinal" [src]="srcFinal" >
</div>-->


<ng-container *ngIf="!datoRecibidoEsDialog" [ngTemplateOutlet]="cuerpoPrincipal"></ng-container>

<ng-container *ngIf="datoRecibidoEsDialog">
    <div globalDialogFlotante btnCerrar  class="fullScreen">
        <ng-container titulo>{{ datosDialogRecibidos?.titulo }}</ng-container>
        <embed cuerpo class="w-full h-full" *ngIf="srcFinal" [src]="srcFinal" >
    </div>

</ng-container>

<ng-template #cuerpoPrincipal>
    <embed class="w-full h-full" *ngIf="srcFinal" [src]="srcFinal" >
</ng-template>

<!--

<div mat-dialog-title class="flex items-center justify-between hover:cursor-move">
	<h5 class="flex-1 m-0" >{{ dataDialog?.titulo }}</h5>
    <button mat-dialog-close class="flex items-center justify-between ml-2 h-6 w-6 bg-red-700 rounded-md text-white "
        type="button"
    >
        <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
</div>
<mat-divider class="bg-primary"></mat-divider>
<mat-dialog-content>
	<embed *ngIf="srcFinal" [src]="srcFinal" >
</mat-dialog-content>

-->


