import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'jsonParse'
})
export class JsonParsePipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): any {
		if (!value) { return []; }
		try {
			return JSON.parse(value);
		} catch (e) {
			console.warn('La cadena "' + value + '" no es JSON');
			return [];
		}
	}

}
