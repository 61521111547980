import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
// import {QueryGlobalService} from './services/query-global.service';

// setup simple regex for white listed characters
const validCharacters = /[^\s\w,.:&\/()+%'`@-]/;

// create your class that extends the angular validator class
export class FormValidators extends Validators {

	/*    static validarDatoExiste(tabla: string, campo: string, queryGlobalService: QueryGlobalService): AsyncValidatorFn {
			return async (control: AbstractControl): Promise<{ [key: string]: any } | null> => {
				// if control value is not null and is a number
				if (control.value && control.value.length > 0) {
					const val = await queryGlobalService.getDataAsync('consulta_existencia', [tabla, campo, control.value], true, 'grl');
					// const val = await queryGlobalService.verificarSiExiste(tabla, campo, control.value);
					// console.log(val);

					// @ts-ignore
					return val.error ? { data_existe: val.msg } : null;
				}
				return null;
			};
		}*/

	// create a static method for your validation
	static validateCharacters(control: FormControl) {

		// first check if the control has a value
		if (control.value && control.value.length > 0) {

			// match the control value against the regular expression
			const matches = control.value.match(validCharacters);

			// if there are matches return an object, else return null.
			return matches && matches.length ? {invalid_characters: matches} : null;
		} else {
			return null;
		}
	}

	static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (!control.value) {
				// if control is empty return no error
				return null;
			}

			// test the value of the control against the regexp supplied
			const valid = regex.test(control.value);

			// if true, return no error (no error), else return error passed in the second parameter
			return valid ? null : error;
		};
	}

	static passwordMatchValidator(control: AbstractControl) {
		const password: string = control.get('new_password').value; // get password from our password form control
		const confirmPassword: string = control.get('re_new_password').value; // get password from our confirmPassword form control
		// compare is the password math
		if (password !== confirmPassword) {
			// if they don't match, set an error in our confirmPassword form control
			control.get('re_new_password').setErrors({NoPassswordMatch: true});
		}
	}

	static min(min: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const input = control.value;
			const isValid = input < min;
			if (isValid) {
				return {minValue: {min}};
			} else {
				return null;
			}
		};
	}

	static selectedValidator(opcList: any[], field: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			// if (!control.value) {
			// 	// if control is empty return no error
			// 	return null;
			// }

			const item = opcList.find(val => val[field] == control.value);
			return item ? null : {itemSelected: true};

		};
	}
}

export interface IFormError {
	control: string;
	error: string;
	value: any;
}

export function getFormValidationErrors(form: FormGroup) {
	const result = [];
	Object.keys(form.controls).forEach(key => {
		const formProperty = form.get(key);
		if (formProperty instanceof FormGroup) {
			result.push(...getFormValidationErrors(formProperty));
		}
		const controlErrors: ValidationErrors = formProperty.errors;
		if (controlErrors) {
			Object.keys(controlErrors).forEach(keyError => {
				result.push({
					control: key,
					error: keyError,
					value: controlErrors[keyError]
				});
			});
		}
	});

	return result;
}
