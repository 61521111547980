<ng-container *ngIf="readonly" [ngTemplateOutlet]="listaArchivos"></ng-container>

<input #controlFile hidden type="file" #fileInput (change)="uploadFile($event.target['files'])" [multiple]="multiple"
       [disabled]="isDisabled">

<div class="flex flex-col items-center dropzone h-full pb-1 px-1 shadow" generalFileUpload
     *ngIf="!readonly"
     [controlFile]="controlFile"
     (filesChangeEmiter)="onFilesChange($event)"
     (filesInvalidEmiter)="onFileInvalids($event)"
     [extensionesPermitidas]="isDisabled ? [] : extensionesPermitidas"
    [isDisabled]="isDisabled"
     [parteDeNombre]="parteDeNombre"
     [tamanoMaximoMB]="tamanoMaximoMB"
     [parteDeNombreExclusivo]="parteDeNombreExclusivo"
>
    <div class="flex flex-col items-center text-center text-gray-700" (click)="fileInput.click()" *ngIf="!isDisabled">
        <div>Click para seleccionar o arrastrar aqu</div>
        <div *ngIf="controlFile.multiple">MULTIPLE</div>
        <div *ngIf="!controlFile.multiple">SOLO UN ARCHIVO</div>
        <div *ngIf="tamanoMaximoMB">Tamaño Máximo {{ tamanoMaximoMB }}Mb</div>
        <!--		<small *ngIf="textoExtraContenedor" class="text-red-700 italic">Este campo es <strong>OPCIONAL</strong>.</small>-->
        <small *ngIf="parteDeNombreExclusivo.length > 0" class="italic">El nombre debe
            contener: {{parteDeNombreExclusivo.join(', ').toUpperCase()}}</small>
        <small *ngIf="parteDeNombreExclusivo.length == 0"
               class="italic">Permitidos: {{extensionesPermitidas.join(', ').toUpperCase()}}</small>
        <small class="text-red-700 italic h-auto">
            <ng-content select="[validator]"></ng-content>
        </small>
        <ng-content select="[mensajeExtra]"></ng-content>
    </div>
    <ng-container [ngTemplateOutlet]="listaArchivos"></ng-container>
    <div class="flex flex-col items-center text-gray-700 w-full max-h-[200px] overflow-y-auto" [ngClass]="cssContenedorAgregadosLista" *ngIf="!temporal">
        <mat-list dense>
            <h4 class="text-green-500 font-bold">Archivos Válidos</h4>
            <mat-list-item *ngFor="let file of fileList; let i = index">
                <mat-icon matListItemIcon svgIcon="mat:done" class="text-green-500 icon-xs"></mat-icon>
                <span matListItemTitle title="{{file?.name}}"
                      style="width: auto; white-space: nowrap; text-overflow: ellipsis;">{{file?.name}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <h4 class="text-red-500 font-bold">Archivos Inválidos</h4>
            <mat-list-item *ngFor="let file of invalidFiles; let i = index">
                <mat-icon matListItemIcon svgIcon="mat:cancel" class="text-red-500"></mat-icon>
                <span matListItemTitle title="{{file?.name}}"
                      style="width: auto; white-space: nowrap; text-overflow: ellipsis;">{{file?.name}}</span>
            </mat-list-item>
        </mat-list>
        <p class="text-center" *ngIf="invalidFiles.length > 0">
            <span class="text-uppercase font-bold text-red-500"><mat-icon svgIcon="roundWarning" class="icon-xs"></mat-icon>Atencion: </span>
            Solo se enviaran archivos
            <mat-icon svgIcon="mat:done" class="text-green-500 icon-xs"></mat-icon>
            válidos.
        </p>
    </div>
</div>


<ng-template #listaArchivos>

    <div class="flex-1 flex flex-col items-center gap-2 max-h-[200px] mt-2 w-full overflow-y-auto" [ngClass]="cssContenedorAgregados" *ngIf="temporal">


        <div class="flex flex-col gap-2 w-full">
            <ul class="divide-y divide-gray-200 w-full">
                <li *ngFor="let file of fileList; let i = index" class="pb-3 sm:pb-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <ng-container [ngSwitch]="file?.servFile?.extension || file?.extension">
                                <mat-icon *ngSwitchCase="'pdf'" class="text-pdf icon-xs" matListItemIcon svgIcon="fa5FilePdf"></mat-icon>
                                <mat-icon *ngSwitchCase="'xls'" class="text-excel icon-xs" matListItemIcon svgIcon="fa5FileExcel"></mat-icon>
                                <mat-icon *ngSwitchCase="'xlsx'" class="text-excel icon-xs" matListItemIcon svgIcon="fa5FileExcel"></mat-icon>
                                <mat-icon *ngSwitchDefault class="icon-xs" matListItemIcon svgIcon="fa5File"></mat-icon>
                            </ng-container>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-2xs font-medium leading-none truncate" (click)="mostrarDataArchivo(file)">
                                {{ file.servFile?.nombre || file?.file.name }}
                            </p>
                            <p *ngIf="file?.servFile" class="text-2xs font-medium leading-snug truncate">
                                <span class="text-yellow-500">En Servidor</span>
                            </p>
                            <p *ngIf="!file?.servFile" class="text-2xs font-medium leading-snug truncate">
                                <span class="">{{ convertirBytes(file?.file?.size) }}</span>
                            </p>
                            <mat-progress-bar matListItemLine [value]="file?.progress"
                                [mode]="file?.progress == 100 && !fileListServ[i] ? 'indeterminate' : 'determinate'"
                                [class.cargando]="file?.progress < 100" [class.incompleto]="file?.progress == 100 && !fileListServ[i]"
                                [class.completado]="file?.progress == 100 && fileListServ[i]">
                            </mat-progress-bar>
                        </div>
                        <div class="inline-flex gap-1 items-center text-base font-semibold text-gray-900 dark:text-white">
                            <button type="button" class="boton-circular boton-circular-blue border-0" matRipple
                                *ngIf="file?.servFile"
                                (click)="download(i, file)"
                                matTooltip="Descargar Documento">
                                <mat-icon class="icon-xs" svgIcon="roundDownload"></mat-icon>
                            </button>
                            <button type="button" class="boton-circular boton-circular-red border-0" matRipple
                                *ngIf="!readonly"
                                [disabled]="false"
                                [matMenuTriggerData]="{ idx: i, file: file }"
                                [matMenuTriggerFor]="menuBotonesAccion"
                                matTooltip="Opciones de archivo">
                                <mat-icon class="icon-2xs" svgIcon="twotoneMenu"></mat-icon>
                            </button>
                        </div>
                    </div>
                </li>

            </ul>
        </div>


    </div>

</ng-template>



<mat-menu #menuBotonesAccion="matMenu" xPosition="before" yPosition="below">
    <ng-template let-idx="idx" let-file="file" matMenuContent>
        <div class="flex flex-col gap-1 items-center text-base font-semibold text-gray-900 dark:text-white">
            <ng-container *ngIf="permitirFirmar && file.servFile">
                <button (click)="firmarDocumentos(file.servFile)" class="flex items-center justify-items-center uppercase w-full rounded-none px-2 " mat-menu-item
                >
                    <mat-icon class="flex-none flex items-center justify-center !text-red-700 !mr-1 icon-2xs" svgIcon="fa5sFileSignature"></mat-icon>
                    <span class="grow text-2xs">Firmar</span>
                </button>
                <button (click)="firmarDocumentos(file.servFile, 'vb')" class="flex items-center justify-items-center uppercase w-full rounded-none px-2 " mat-menu-item
                >
                    <mat-icon class="flex-none flex items-center justify-center !text-red-700 !mr-1 icon-2xs" svgIcon="roundVerifiedUser"></mat-icon>
                    <span class="grow text-2xs">Visto Bueno</span>
                </button>
            </ng-container>
<!--            <button (click)="download(idx, file)" class="flex items-center justify-items-center uppercase w-full rounded-none px-2 " mat-menu-item
            >
                <mat-icon class="flex-none flex items-center justify-center !text-blue-700 !mr-1 icon-2xs" svgIcon="roundCloudDownload"></mat-icon>
                <span class="grow text-2xs">Descargar</span>
            </button>-->
            <button *ngIf="permitirEliminar && !isDisabled" (click)="eliminar(idx, file)" class="flex items-center justify-items-center uppercase w-full rounded-none px-2 " mat-menu-item
            >
                <mat-icon class="flex-none flex items-center justify-center !text-red-700 !mr-1 icon-2xs" svgIcon="roundDelete"></mat-icon>
                <span class="grow text-2xs">Eliminar</span>
            </button>
        </div>
    </ng-template>
</mat-menu>

<ng-template #listaArchivos2>
    <div class="flex flex-col items-center text-gray-700 w-full max-h-[200px] overflow-y-auto" [ngClass]="cssContenedorAgregados" *ngIf="temporal">
        <mat-list dense class="flex-grow w-full">
            <mat-list-item *ngFor="let file of fileList; let i = index" class="flex-grow w-full">
                <mat-icon matListItemIcon
                         [svgIcon]="(file?.progress < 100 ? (file?.progress == 0 ? 'mat:upload' : 'mat:autorenew') : 'mat:done')"
                         [class.animate-spin]="file?.progress < 100 && file?.progress > 0"
                    class="icon-xs"

                ></mat-icon>
                <span matListItemTitle title="{{file?.file.name}}" class="text-xs">{{file?.file.name}}</span>
                <small matListItemLine style="font-size: 85% !important;">
                    <span *ngIf="file?.desdeServidor" class="text-yellow-500">En Servidoraaaa</span>
                    <span *ngIf="!file?.desdeServidor">{{(bytesToOther(file?.file.size))}}</span>
                </small>
                <small *ngIf="file?.errorSubida" matListItemLine style="font-size: 85% !important;">
                    <span class="text-red-700">
                            (<mat-icon class="icon-xs" svgIcon="mat:close"></mat-icon>Error al cargar archivo)
                        </span>
                </small>
                <mat-progress-bar matListItemLine [value]="file?.progress"
                                  [mode]="file?.progress == 100 && !fileListServ[i] ? 'indeterminate' : 'determinate'"
                                  [class.cargando]="file?.progress < 100"
                                  [class.incompleto]="file?.progress == 100 && !fileListServ[i]"
                                  [class.completado]="file?.progress == 100 && fileListServ[i]"
                ></mat-progress-bar>
                <div matListItemMeta>
                    <button matRipple *ngIf="file?.desdeServidor" class="boton-circular-gris" (click)="$event.preventDefault(); download(i, file)">
                        <mat-icon svgIcon="mat:download" class="icon-xs"></mat-icon>
                    </button>
                    <button matRipple *ngIf="!file?.progress && !fileListServ[i]" [disabled]="isDisabled"
                        class="boton-circular-gris text-red-700" (click)="eliminarItemTemporal(i); $event.preventDefault(); ">
                        <mat-icon svgIcon="mat:delete" class="icon-xs"></mat-icon>
                    </button>
                    <button matRipple
                        *ngIf="file?.progress == 100 && fileListServ[i] && (!file?.desdeServidor || permitirEliminar) && !readonly"
                        [disabled]="isDisabled" class="boton-circular-gris text-danger"
                        (click)="eliminarItemServidor(i);$event.preventDefault(); ">
                        <mat-icon svgIcon="mat:delete" class="icon-xs"></mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </div>
</ng-template>
