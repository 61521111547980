import {Component, OnInit} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {delayWhen, interval, Observable, of} from 'rxjs';
import {QueryLoadingProgressBarService} from '@servicios/query-loading-progress-bar.service';

@Component({
  selector: 'vex-mod-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  public barraActiva = true;

  value$: Observable<number> = this.loader.useRef('router').value$.pipe(
    delayWhen(value => value === 0 ? interval(200) : of(undefined))
  );

  constructor(
      public loader: LoadingBarService,
      public queryLoadingProgressBarService: QueryLoadingProgressBarService,
  ) { }

  ngOnInit() {
  }

}
