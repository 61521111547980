import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntidadService} from './entidad.service';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {mensajeAlerta, mensajeConfirmacion} from '@JVSoft/services/funciones-globales.service';
import {QueryService} from '@servicios/query.service';
import {JVSoftRouteData} from '../interfaces/importantes';
import {SweetAlertResult} from 'sweetalert2';
import {DespachoService} from '@servicios/despacho.service';
import {catchError, map} from 'rxjs/operators';
import {setCambiarPwd} from '@JVSoft/functions/local-storage';
import {ServidorService} from '@servicios/servidor.service';
import {Entidad} from '@JVSoft/models/entidad';

export const PERFILES_USUARIO = {
    EQUIPO_JVSOFT: 0,
    ADMINISTRADOR: 1,
    ABASTECIMIENTO: 2,
    ALMACENERO: 3,
    PATRIMONIO: 4,
    SOLICITANTE: 5,
    PROVEEDOR: 6,
    CONTRATO: 7,
    PLANILLERO: 8,
    ASISTENCIA: 9,
    PRESUPUESTO: 10,
    TESORERO: 11,
    CONTADOR: 12,
    DOCUMENTADOR: 13,
    VENTAS: 14,
    CLIENTES: 15,
    SEGURIDAD: 16,
    SUPERVISOR: 17,
};



export interface Persona {
    iPersId: number;
    iTipoPersId: number;
    iTipoIdentId: number;
    cPersDocumento: string;
    cPersPaterno: string;
    cPersMaterno: string;
    cPersNombre: string;
    cPersSexo: string;
    dPersNacimiento: string;
    iTipoEstCivId: number;
    iNacionId: number;
    cPersFotografia: string;
    cPersRazonSocialNombre: string;
    cPersRazonSocialCorto: string;
    cPersRazonSocialSigla: string;
    iPersRepresentanteLegalId: number;
    cPersDomicilio: string;
    iTipoSectorId: number;
    iPaisId: number;
    iDptoId: number;
    iPrvnId: number;
    iDsttId: number;
    iPersEstado: number;
    cPersCodigoVerificacion: string;
    iCredSesionId: number;
    cPersNombreLargo: string;
}

export interface Usuario {
    iCredId: number;
    iPersId: number;
    iCredEstado?: number;
    iCredSesionId: number;
    entidades: UsuarioEntidad[];
    accesos: UsuarioAccesos[];
    entidadActual: Entidad;
    despachos: any[];
    dependencias: any[];
    perfiles: Perfil[];
    mismo_password: boolean;
    persona: Persona;
    iCredDepenId: number;
    Sec_ejec: string;
    horaSesion: string;
    segExpiraSesion: number;
}

export interface Perfil {
    iPerfilId: number;
    cPerfilNombre: string;
}
export interface UsuarioEntidad extends Usuario, Entidad{
    iCredEntId: number;
}
export interface UsuarioAccesos extends UsuarioEntidad{
    iAccesoId: number;
    iModuloPadreId: number;
    cModuloPadreNombre: string;
    iModuloId: number;
    cModuloNombre: string;
    iMenuPadreId: number;
    cMenuPadreNombre: null;
    iMenuId: number;
    cMenuNombre: string;
    cMenuEnlace: string;
    cMenuControlador: string;
    iMenuOrden: number;
    cMenuIcono: string;
    bMenuDerecho: number;
    iCantidad_Derechos: number;
    iCredSesionId: number;
    derechos: [];
}


@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class UsuarioService {

    // _data = new BehaviorSubject(null);
    _data = new BehaviorSubject<UsuarioEntidad>(null);
    data$ = this._data.asObservable();
    _entidades = new BehaviorSubject([]);
    entidades$ = this._entidades.asObservable();

    _accessos = new BehaviorSubject([]);
    accesos$ = this._accessos.asObservable();

    _perfiles = new BehaviorSubject([]);
    perfiles$ = this._perfiles.asObservable();

    _cambiarPassword = new BehaviorSubject(false);
    cambiarPassword$ = this._cambiarPassword.asObservable();

    _cambiarCodigoSeguridad = new BehaviorSubject(false);
    cambiarCodigoSeguridad$ = this._cambiarCodigoSeguridad.asObservable();

    _dataUsuarioEnCache = new BehaviorSubject(false);
    dataUsuarioEnCache$ = this._dataUsuarioEnCache.asObservable();

    dataUsuarioEnCache;

    get photoThumbURL() {
        // return ''
        return this.servidorService.photoThumbURL + this.dataUsuarioPersona?.iPersId;
    }

    get dataUsuario(): UsuarioEntidad {
        if (this._data.getValue()) {
            return this._data.getValue();
        }
        return null;
    }
    get dataUsuarioPersona(): Persona {
        if (this.dataUsuario) {
            return this.dataUsuario['data_persona'];
        }
        return null;
    }

    constructor(
        private servidorService: ServidorService,
        private entidadService: EntidadService,
        private despachoService: DespachoService,
        private queryService: QueryService,
    ) {
        this.data$.pipe(untilDestroyed(this)).subscribe(data => {
            // console.log('RECARGANDO DATA');
            // @ts-ignore
            if (data && data.length != 0) {
                this._accessos.next(data['accesos']);
                this._entidades.next(data['entidades']);
                this._perfiles.next(data['perfiles']);
                this.entidadService._entidadActual.next(data['entidadActual']);
                this._dataUsuarioEnCache.next(data['dataUsuarioEnCache']);
                this.dataUsuarioEnCache = (data['dataUsuarioEnCache']);
                // this.entidadService._paginaPrincipal.next(response['entidadActual']['cEntPaginaInicial']);


                this.despachoService._despachos.next(data['despachos']);
                if (data['despachos'] && data['despachos'].length > 0 && data['iDespachoId']) {
                    if (data['iDespachoId'] != despachoService.despachoActualId) {
                        this.despachoService.setDespachoActualById(data['iDespachoId'], false);
                    }
                }
            }
        });
    }

    cambiarPassword(accion: boolean = true) {
        setCambiarPwd(true);
        this._cambiarPassword.next(true)
    }
    verificarPermisoMenu(iMenuId): any {
        return setTimeout(() => {
            return this.accesosJerarquicosLista(this._accessos.getValue(), 'iMenuId', iMenuId);
        }, 500);
    }
    verificarDerechosMenu(iMenuId): any {
        const va = this.verificarPermisoMenu(iMenuId);
        if (va) {
            console.log(va);
            return this.queryService.getDataMethod('GET', 'seg.accesos_derechos', {iAccesoId: va.iAccesoId}).pipe(
                map(dRet => {
                    return dRet['seg.accesos_derechos'];
                }),
                catchError(error => {
                    return of('No data');
                }),
            );
        }
        return false;
    }

    accesosJerarquicosLista(obj, label, value) {
        let r = false;
        if (obj) {
            obj.forEach(acceso => {
                if (r == false) {
                    if (acceso[label] == value) {
                        r = acceso;
                    }
                    else if (acceso['hijos']) {
                        r = this.accesosJerarquicosLista(acceso['hijos'], label, value);
                    }
                    else {
                        r = false;
                    }
                }
            });
        }
        return r;
    }

    verificarPerfil(iPerfilId: number | number[]): any {
        if (this._perfiles.getValue()) {
            if (Array.isArray(iPerfilId)) {
                const cantPerfiles = this._perfiles.getValue()
                    .filter(perfil => iPerfilId.includes(perfil.iPerfilId * 1));
                if (cantPerfiles.length > 0) {
                    return true;
                }
            }
            return dataEnLista(this._perfiles.getValue(), 'iPerfilId', iPerfilId);
        }
        return false;
    }

    esEquipoJVSoft() {
        return this.verificarPerfil(PERFILES_USUARIO.EQUIPO_JVSOFT);
    }
    habilitarAccion(params: {
        datosRuta: JVSoftRouteData,
        permiso: string,
        titulo?: string,
        accion?: string,
        tipoMensaje?: 'mensajeAlerta' | 'mensajeConfirmacion',
        mostrarMensajeCofirmacion?: boolean,
    }): Promise<SweetAlertResult<Awaited<any>>> {
        const accionEditarFecha = () => {
            if (params.mostrarMensajeCofirmacion === false) {
                return new Promise<SweetAlertResult>((resolve, reject) => {
                    resolve({
                        isConfirmed: true, isDenied: false, value: undefined,
                        isDismissed: false
                    });
                });
            }
            switch (params.tipoMensaje){
                case 'mensajeAlerta':
                    return mensajeAlerta('warning', params.titulo ?? '', 'Se dispone a ' + (params.accion ?? 'realizar una accion como SUPERVISOR con') + ' el registro seleccionado. <br> Especifique el motivo de anulación', {
                        confirmButtonText: 'Continuar',
                    });
                case 'mensajeConfirmacion':
                default:
                    return mensajeConfirmacion('warning', params.titulo ?? '', 'Se dispone a ' + (params.accion ?? 'realizar una accion como SUPERVISOR con') + ' el registro seleccionado. <br> Especifique el motivo', {
                        input: 'textarea',
                        confirmButtonText: 'Continuar',
                    });
            }

        };
        // Validar si tengo permiso
        if (params.datosRuta.derechos && params.datosRuta.derechos[params.permiso]) {
            return accionEditarFecha();
        }
        else {
           return this.verificarAccesoMenu(params.datosRuta['iMenuId'] ?? '', params.permiso).then((dR) => {
                return accionEditarFecha();
            }).catch(error => {
                if (typeof error == 'string') {
                    return mensajeAlerta('error', 'Permiso requerido', error, {
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Aceptar'
                    });
                }
                const a: SweetAlertResult = {
                    isConfirmed: false, isDenied: false, value: undefined,
                    isDismissed: true
                };
                return a;
                return mensajeAlerta('error', 'Error', error, {
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Aceptar'
                });
            });
        }
    }

    verificarAccesoMenu(iMenuId, derecho) {
        return new Promise<any>((resolve, reject) => {

            mensajeConfirmacion('info', 'Permiso Requerido', 'Para realizar esta accion se requieren autorización.<br>Ingrese código de seguridad de un USUARIO AUTORIZADO.', {
                input: 'password',
                confirmButtonText: 'Continuar',
            }).then(val => {
                if (val.isConfirmed) {
                    this.queryService.cargar(this, ['seg.credenciales'], {
                        cCodigoSeguridad: val.value,
                    }).then((datR) => {
                        if (datR['seg.credenciales'] && datR['seg.credenciales'][0]) {
                            this.queryService.cargar(this, ['seg.credenciales_entidades'], {
                                iCredId: datR['seg.credenciales'][0]['iCredId'],
                                iEntId: this.entidadService._entidadActual.getValue()['iEntId'],
                            }).then(datR2 => {
                                if (datR2['seg.credenciales_entidades'] && datR2['seg.credenciales_entidades'][0]) {
                                    this.queryService.cargar(this, ['seg.derechos'], {
                                        iCredEntId: datR2['seg.credenciales_entidades'][0]['iCredEntId'],
                                        iMenuId,
                                    }).then(datR3 => {
                                        const bDerecho = dataEnLista(datR3['seg.derechos'], 'cDerechoCodigo', derecho);
                                        if (bDerecho) {
                                            resolve(true);
                                        }
                                        else {
                                            reject('la credencial no tiene derecho a esta accion');
                                        }
                                    });
                                }
                                else {
                                    reject('No existe datos');
                                }
                            });
                        }
                        else {
                            reject('Credencial no encontrada');
                        }
                    });

                }
                else {
                    reject(false);
                }
            });
        });
    }
}
