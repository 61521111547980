import {Component, OnInit} from '@angular/core';
import {fadeInRight400ms} from '@vex/animations/fade-in-right.animation';
import {scaleIn400ms} from '@vex/animations/scale-in.animation';
import {UntilDestroy} from '@ngneat/until-destroy';
import {EntidadService} from '@servicios/entidad.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UsuarioService} from '@servicios/usuario.service';
import {ServidorService} from '@servicios/servidor.service';

@UntilDestroy()
@Component({
	selector: 'vex-dashboard',
	templateUrl: './dashboard.component.html',
	animations: [
		scaleIn400ms,
		fadeInRight400ms,
	],
})
export class DashboardComponent implements OnInit {



	constructor(
		public usuarioService: UsuarioService,
		public servidorService: ServidorService,
		public entidadService: EntidadService,
		private router: Router,
		private route: ActivatedRoute,
	) {
		console.warn(this.entidadService._paginaPrincipal.getValue());
		// if (route.pathFromRoot)
		if (this.entidadService._paginaPrincipal.getValue()) {
			this.router.navigateByUrl(this.entidadService._paginaPrincipal.getValue());
			entidadService._paginaPrincipal.next(null);
		}
		/*this.entidadService.paginaPrincipal$.subscribe(entidadActual => {
			console.warn(entidadActual);
			if (entidadActual) {
				// aOpc.unsubscribe();
				// this.router.navigate([entidadActual]);
				// this.router.navigateByUrl(entidadActuaal);
				entidadService._paginaPrincipal.next(null);
			}
		});*/
	}

	ngOnInit(): void {
		// if (this.entidadService._entidadActual.getValue() && this.entidadService._entidadActual.getValue().iEntId  && this.entidadService._paginaPrincipal.getValue()) {
		// 	this.router.navigateByUrl(this.entidadService._paginaPrincipal.getValue());
		// 	this.entidadService._paginaPrincipal.next(null);
		// }
		// setTimeout(() => {
		//
		// }, 5000);  // 5s
	}

}
