import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {MatMenu} from "@angular/material/menu";

export interface ItemMenu {
    label: string;
    icon?: string;
    children?: ItemMenu[];
}

@Component({
    selector: 'global-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
    @Input() title: string
    @Input() listaMenu: any[]
    @Output() action: EventEmitter<any> = new EventEmitter<string>()

    //we are going to get all "mat-menu" using viewChildren
    @ViewChildren(MatMenu) public matmenus: QueryList<MatMenu>
    menuItems: string[] = []
    yet: boolean = false;
    submenus: any[] = []

    ngOnInit() {
        this.createSubmenus(this.listaMenu, "s0", 1)
        this.reindex()
    }

    ngAfterViewInit() {
        //this avoid Angular give us errors, only when repaint the menu
        //we asign the [matMenutiggerFor]
        setTimeout(() => {
            this.yet = true
        })
    }

    //simply call to the output
    onClick(value: any) {
        this.action.emit(value);
    }

    //return the "mat-menu" in the index selected
    getMenu(index: number) {
        return index >= 0 && this.matmenus ? this.matmenus.find((x, i) => i == index) : null
    }

    reindex() {
        //asign the "index" of the menu item
        this.submenus.forEach(menu => {
            menu.forEach((x: any) => {
                if (x.subMenu != -1)
                    x.subMenu = this.menuItems.indexOf(x.action)
            })
        })
    }

    createSubmenus(menu: any[], prefix: string, count: number) {
        //add to the array menuItems the "prefix"
        this.menuItems.push(prefix)
        //add to submenu an object to create the submenu
        this.submenus.push(menu.map((x: any, index: number) => (
            {
                label: x.label,
                icon: x.icon,
                action: x.children === null || x.children === undefined ? x.action : prefix + index,
                subMenu: x.children === null || x.children === undefined ? -1 : 0
            }
        )))
        //if has children call the function for each child
        menu.forEach((x: any, index: number) => {
            if (x.children) {
                this.createSubmenus(x.children, prefix + index, count + 1)
            }
        })
    }
}
