import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '../../../services/query.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {changeSelect, mensajesErrorFormControl, mensajeToast, mostrarValorEnBusqueda} from '@JVSoft/services/funciones-globales.service';
import {EntidadService} from '@servicios/entidad.service';

@UntilDestroy()
@Component({
  selector: 'general-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {
    mensajesErrorFormControl = mensajesErrorFormControl;
    mostrarValorEnBusqueda = mostrarValorEnBusqueda;
	static id = 101;

	dataServidor = {
		log: {
			tipos_productos: null,
			grupos_bien_servicio: null,
			clases_bien_servicio: null,
			familias_bien_servicio: null,

            categorias: null,

			presentaciones: null,
		}
	};

	filtrados = {};

	frmRegistro: FormGroup;
	mode: 'create' | 'update' = 'create';
	onSubmitReason = new EventEmitter();



	constructor(
		@Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
		private queryService: QueryService,
		private dialogRef: MatDialogRef<ProductoComponent>,
		private fb: FormBuilder,
        public entidadService: EntidadService,
	) {
	}

	ngOnInit() {
		console.log(this.datosDialogRecibidos);
		this.crearFormularios();

        this.cargaInicial();
        if (this.entidadService.esSector('publico')){
            changeSelect(this, this.frmRegistro.get('cGrupoCodigo'), 'log.grupos_bien_servicio', ['cGrupoCodigo', 'cGrupoNombre']);
            changeSelect(this, this.frmRegistro.get('cClaseCodigo'), 'log.clases_bien_servicio', ['cClaseCodigo', 'cClaseNombre']);
            changeSelect(this, this.frmRegistro.get('cFamiliaCodigo'), 'log.familias_bien_servicio', ['cFamiliaCodigo', 'cFamiliaNombre']);
            this.frmRegistro.get('cGrupoCodigo').setValidators([Validators.required]);
            this.frmRegistro.get('cClaseCodigo').setValidators([Validators.required]);
            this.frmRegistro.get('cFamiliaCodigo').setValidators([Validators.required]);
            this.frmRegistro.get('iCategoriaId').setValidators([]);
        }
        if (this.entidadService.esSector('privado')){
            changeSelect(this, this.frmRegistro.get('iCategoriaId'), 'log.categorias', ['cCategoriaCodigo', 'cCategoriaNombre']);
            this.frmRegistro.get('iCategoriaId').setValidators([Validators.required]);
            this.frmRegistro.get('cGrupoCodigo').setValidators([]);
            this.frmRegistro.get('cClaseCodigo').setValidators([]);
            this.frmRegistro.get('cFamiliaCodigo').setValidators([]);
        }

        this.frmRegistro.get('cGrupoCodigo').updateValueAndValidity();
        this.frmRegistro.get('cClaseCodigo').updateValueAndValidity();
        this.frmRegistro.get('cFamiliaCodigo').updateValueAndValidity();
        this.frmRegistro.get('iCategoriaId').updateValueAndValidity();


        changeSelect(this, this.frmRegistro.get('iPresentacionCompraId'), 'log.presentaciones', 'cPresentacionNombre');

        this.frmRegistro.get('iTipoProductoId').valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            if (val) {
                if (this.entidadService.esSector('publico')){
                    console.log('es publ');
                    this.dataServidor.log.grupos_bien_servicio = null;
                    this.dataServidor.log.clases_bien_servicio = null;
                    this.dataServidor.log.familias_bien_servicio = null;

                    this.frmRegistro.patchValue({
                        cGrupoCodigo: null,
                        cClaseCodigo: null,
                        cFamiliaCodigo: null,
                    });
                    this.queryService.cargar(this, [ 'log.grupos_bien_servicio'], { iTipoProductoId: val }).then(() => {
                        // this.filtrados['log.grupos_bien_servicio'] = this.dataServidor.log.grupos_bien_servicio;
                    });
                }
                if (this.entidadService.esSector('privado')){
                    console.log('es priv');
                    this.dataServidor.log.categorias = null;

                    this.frmRegistro.patchValue({
                        iCategoriaId: null,
                    });
                    this.queryService.cargar(this, [ 'log.categorias'], { iTipoProductoId: val }).then();
                }

            }
        });
        this.frmRegistro.get('cGrupoCodigo').valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            if (val) {
                this.dataServidor.log.clases_bien_servicio = null;
                this.dataServidor.log.familias_bien_servicio = null;
                this.frmRegistro.patchValue({
                    cClaseCodigo: null,
                    cFamiliaCodigo: null,
                });
                this.queryService.cargar(this, [ 'log.clases_bien_servicio'], {
                    iTipoProductoId: this.frmRegistro.get('iTipoProductoId').value,
                    cGrupoCodigo: val,
                }).then();
            }
        });
        this.frmRegistro.get('cClaseCodigo').valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            if (val) {
                this.dataServidor.log.familias_bien_servicio = null;
                this.frmRegistro.patchValue({
                    cFamiliaCodigo: null,
                });
                this.queryService.cargar(this, [ 'log.familias_bien_servicio'], {
                    iTipoProductoId: this.frmRegistro.get('iTipoProductoId').value,
                    cGrupoCodigo: this.frmRegistro.get('cGrupoCodigo').value,
                    cClaseCodigo: val,
                }).then();
            }
        });

		if (this.datosDialogRecibidos) {
			if (this.datosDialogRecibidos.producto) {
				this.frmRegistro.patchValue({
                    ...this.datosDialogRecibidos.producto, ...{nProductoCostoCompra: this.datosDialogRecibidos.producto.nProductoCostoCompra * 1}
                });
				if (this.datosDialogRecibidos.producto.iProductoId) {
					this.mode = 'update';
				}
			}
			this.queryService.cargar(this, ['log.tipos_productos']).then(() => {
                /*switch (this.datosDialogRecibidos.mode) {
                    case 'clone':
                        this.frmRegistro.patchValue(this.datosDialogRecibidos.producto, {emitEvent: false});
                        this.cambiarTipoProducto().then(() => {
                            this.frmRegistro.patchValue(this.datosDialogRecibidos.producto, {emitEvent: false});
                            this.cambiarGrupo().then(() => {
                                this.frmRegistro.patchValue(this.datosDialogRecibidos.producto, {emitEvent: false});
                                this.cambiarClase().then();
                            });
                        });
                        setTimeout(() => {
                            this.cambiarGrupo();
                        }, 100);
                        break;
                }*/
                if (this.datosDialogRecibidos.mode) {
                    this.mode = this.datosDialogRecibidos.mode;
                }
            });
		} else {
			this.datosDialogRecibidos = {};
		}
	}

	crearFormularios() {
		this.frmRegistro = this.fb.group({
			iTipoProductoId: ['', Validators.required],
			cGrupoCodigo: [''],
			cClaseCodigo: [''],
			cFamiliaCodigo: [''],

            iCategoriaId: [''],

			cProductoNombre: ['', Validators.required],
			iPresentacionCompraId: ['', Validators.required],
            nProductoCostoCompra: [''],

			iProductoId: [''],
		});
	}

	cargaInicial() {
		const solicitudes = [
			'log.presentaciones',
		];
		this.queryService.cargar(this, solicitudes).then((valRet) => {
			// this.cambiarTipoPersona();
		});
	}

	cambiarTipoProducto(){
		this.dataServidor.log.grupos_bien_servicio = null;
		this.dataServidor.log.clases_bien_servicio = null;
		this.dataServidor.log.familias_bien_servicio = null;
		this.frmRegistro.patchValue({
			cGrupoCodigo: null,
			cClaseCodigo: null,
			cFamiliaCodigo: null,
		});
		return  this.queryService.cargar(this, [ 'log.grupos_bien_servicio'], { iTipoProductoId: this.frmRegistro.get('iTipoProductoId').value }).then();
	}

	cambiarGrupo(){
		this.dataServidor.log.clases_bien_servicio = null;
		this.dataServidor.log.familias_bien_servicio = null;
		this.frmRegistro.patchValue({
			cClaseCodigo: null,
			cFamiliaCodigo: null,
		});
		return this.queryService.cargar(this, [ 'log.clases_bien_servicio'], {
			iTipoProductoId: this.frmRegistro.get('iTipoProductoId').value,
			cGrupoCodigo: this.frmRegistro.get('cGrupoCodigo').value,
		}).then();
	}

	cambiarClase(){
		this.dataServidor.log.familias_bien_servicio = null;
		this.frmRegistro.patchValue({
			cFamiliaCodigo: null,
		});
		return this.queryService.cargar(this, [ 'log.familias_bien_servicio'], {
			iTipoProductoId: this.frmRegistro.get('iTipoProductoId').value,
			cGrupoCodigo: this.frmRegistro.get('cGrupoCodigo').value,
			cClaseCodigo: this.frmRegistro.get('cClaseCodigo').value,
		}).then();
	}

	establecerObligatorios() {

	}

	save() {
		if (this.frmRegistro.valid){
			this.onSubmitReason.emit(this.frmRegistro);
		} else {
			mensajeToast('error', 'Datos Faltantes', 'Por favor complete los campos faltantes.');
		}
	}

	isCreateMode() {
		return this.mode === 'create';
	}

	isUpdateMode() {
		return this.mode === 'update';
	}
}

