import {
    groupBy,
    nestGroupsBy,
    ordenarArray,
    ordenarPorPropiedad,
    sumarObjetos
} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {DataEnviarPdfPresupuesto, PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';


export function certificadoSecuenciaMetaFaseA4(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.Certificado_Secuencia_Meta_Fase'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.Certificado_Secuencia_Meta_Fase'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			// tamanioHoja: 'A3',
			// tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE SOLICITUDES DE CERTIFICACIONES REGISTRADAS', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 12, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Sec_func',
			'Fuente_financ',
		]);
		console.log(groups);
		// return false;

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'Año', property: 'Ano_eje', type: 'text'},
			{label: 'Certificado', property: 'Certificado', type: 'text'},
			{label: 'Sec.', property: 'Secuencia', type: 'text'},
			{label: 'E', property: 'Etapa', type: 'text'},
			{label: 'T.R.', property: 'Tipo_registro', type: 'text'},
			{label: 'Cod.', property: 'Cod_doc', type: 'text'},
			{label: 'Etapa', property: 'Nombre_Etapa', type: 'text'},
			{label: 'Registro', property: 'Nombre_Tipo_Registro', type: 'text'},
			{label: 'Documento', property: 'Abreviatura_doc', type: 'text'},
			{label: 'Num_doc', property: 'Num_doc', type: 'text'},
			{label: 'Fecha_doc', property: 'Fecha_doc', type: 'text'},
			{label: 'F.F', property: 'Fuente_financ', type: 'text'},
			{label: 'Clasif.', property: 'Clasificador', type: 'text'},
			{label: 'Certif.', property: 'nCertificado', type: 'money'},
			{label: 'Compr.', property: 'nCertificado', type: 'money'},
			{label: 'Ruc', property: 'Ruc', type: 'money'},
			{label: 'Nombre_Persona', property: 'Nombre_Persona', type: 'text'},
			{label: 'Glosa', property: 'Glosa', type: 'text'},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nCertificado',
			'nCompromiso',
			// SALDO CERTIFICADO FALTA
		]);


		const agrupadoSecfunc = groupBy(resultadosGenerales, (obj) => {
			return obj.Sec_func;
		});

		console.log(agrupadoSecfunc);
		let dataSecfunc = {
			Sec_func: '',
			cNombre_Sec_func: '',
			nCertificado: '',
			nCompromiso: '',
		};


		let pag = 0;
		let nuevoCuerpo = [];




		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoSecfunc).forEach((secSecfunc) => {
			dataSecfunc = {
				...{
					Sec_func: agrupadoSecfunc[secSecfunc][0]['Sec_func'],
					cNombre_Sec_func: agrupadoSecfunc[secSecfunc][0]['cNombre_Sec_func'],
				},
				...sumarObjetos(agrupadoSecfunc[secSecfunc], [
					'nCertificado',
					'nCompromiso',
				]),
			};

// agrupadoSecfunc[secSecfunc] = ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Fuente_Financ', true)
			const agrupadoFuenteFinanc = groupBy(agrupadoSecfunc[secSecfunc], (obj) => {
				return obj.Fuente_financ;
			});
			let dataFuenteFinanc = {
				Fuente_financ: '',
				Nombre_FuenteFinanc: '',
				nCertificado: '',
				nCompromiso: '',
			};

			let pag3 = 0;
			ordenarArray(Object.keys(agrupadoFuenteFinanc), true).forEach((secFuenteFinanc) => {
				dataFuenteFinanc = {
					...{
						Fuente_financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_financ'],
						Nombre_FuenteFinanc: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_FuenteFinanc'],
					},
					...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
						'nCertificado',
						'nCompromiso',
					]),
				};

				const tablaGenerada = objThis.generarDesdeMantenimiento({
					titulos: camposPrincipal,
					contenido: ordenarPorPropiedad(agrupadoFuenteFinanc[secFuenteFinanc], 'Clasificador'),
					// numeracion: true,
					idxResto: [6],
					margin: [0, 0, 0, 5],
					separado: true,
				});


				if (pag3 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([

							generarColSpan(imprimirCelda('META PRESUPUESTAL: ' + dataSecfunc.Sec_func + ' - ' + dataSecfunc.cNombre_Sec_func, null, {
								bold: true,
								// fontSize: 8,
								style: 'thSmall',
								alignment: 'center'
							}), 18),

					]);
				}

				nuevoCuerpo = nuevoCuerpo.concat([
						generarColSpan(imprimirCelda('FUENTE FINANCIAMIENTO ' + dataFuenteFinanc.Fuente_financ + ' - ' + dataFuenteFinanc.Nombre_FuenteFinanc, null, {
							bold: true,
							// fontSize: 8,
							style: 'thSmall',
							alignment: 'center'
						}), 18),

				]);

				nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
				pag3++;
				nuevoCuerpo = nuevoCuerpo.concat([
					generarColSpan(imprimirCelda(dataFuenteFinanc.Nombre_FuenteFinanc + ' - SUB TOTAL S/.:' , null, {
						bold: true,
						// fontSize: 8,
						style: 'thSmall',
						alignment: 'right'
					}), 13).concat(
						imprimirCelda(dataFuenteFinanc.nCertificado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(dataFuenteFinanc.nCompromiso, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						generarColSpan(imprimirCelda('' , null, {
							bold: true,
							// fontSize: 8,
							style: 'thSmall',
							alignment: 'right'
						}), 3),

					),
				]);
			});


			pag++;
			nuevoCuerpo = nuevoCuerpo.concat([
				generarColSpan(imprimirCelda(dataSecfunc.cNombre_Sec_func + ' - SUB TOTAL S/.', null, {
					bold: true,
					// fontSize: 8,
					style: 'thSmall',
					alignment: 'right'
				}), 13).concat(
					imprimirCelda(dataSecfunc.nCertificado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					imprimirCelda(dataSecfunc.nCompromiso, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					generarColSpan(imprimirCelda('' , null, {
						bold: true,
						// fontSize: 8,
						style: 'thSmall',
						alignment: 'right'
					}), 3),
				),
			]);
		});


		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				// fontSize: 8,
				style: 'thSmall',
				alignment: 'right'
			}), 13).concat(
				imprimirCelda(sumasTotales.nCertificado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCompromiso, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda((sumasTotales.nCertificado - sumasTotales.nCompromiso), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				generarColSpan(imprimirCelda('' , null, {
					bold: true,
					// fontSize: 8,
					style: 'thSmall',
					alignment: 'right'
				}), 2),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 20}, {idx: 1, valor: 30}, {idx: 2, valor: 20}]),
				body: nuevoCuerpo,
			}
		});
		// console.log(JSON.stringify(cuerpoPdf));
		// console.log(cuerpoPdf);

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}

export function certificadoCompromisoAnualSaldo(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.Certificado_CompromisoAnual_Saldo'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.Certificado_CompromisoAnual_Saldo'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 120, 40, 40],
			// orientacion: 'landscape',
			// tamanioHoja: 'A3',
			// tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE CERTIFICADO, COMPROMISO ANUAL Y SALDO', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 12, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Sec_func',
			'Fuente_Financ',
		]);
		console.log(groups);
		// return false;

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: '', type: 'text' , transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [true, false, false, false],
					});
				}},
			{label: 'SEC.FUNC..', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'FTE.', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, true, false],
					});
				}},
			{label: 'CERTIFICADO', property: 'Certificado', type: 'text'},
			{label: 'CLASIF.', property: 'Clasificador', type: 'text'},
			{label: 'NOMBRE', property: 'Descripcion_Clasificador', type: 'text'},
			{label: 'CERTIFICADO', property: 'nEjecucion', type: 'money'},
			{label: 'COMPROMISO ANUAL.', property: 'nCompromisoAnual', type: 'money'},
			{label: 'SALDO CERTIFICADO', property: '', type: 'money', transformar(row): any {
					return row.nEjecucion - row.nCompromisoAnual;
				}},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nEjecucion',
			'nCompromisoAnual',
			// SALDO CERTIFICADO FALTA
		]);


		const agrupadoSecfunc = groupBy(resultadosGenerales, (obj) => {
			return obj.Sec_func;
		});

		console.log(agrupadoSecfunc);
		let dataSecfunc = {
			Ano_eje: '',
			Sec_func: '',
			cNombre_Sec_func: '',
			nEjecucion: 0,
			nCompromisoAnual: 0,
		};


		let pag = 0;
		let nuevoCuerpo = [];




		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoSecfunc).forEach((secSecfunc) => {
			dataSecfunc = {
				...{
					Ano_eje: agrupadoSecfunc[secSecfunc][0]['Ano_eje'],
					Sec_func: agrupadoSecfunc[secSecfunc][0]['Sec_func'],
					cNombre_Sec_func: agrupadoSecfunc[secSecfunc][0]['cNombre_Sec_func'],
				},
				...sumarObjetos(agrupadoSecfunc[secSecfunc], [
					'nEjecucion',
					'nCompromisoAnual',
				]),
			};

// agrupadoSecfunc[secSecfunc] = ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Fuente_Financ', true)
			const agrupadoFuenteFinanc = groupBy(agrupadoSecfunc[secSecfunc], (obj) => {
				return obj.Fuente_Financ;
			});
			let dataFuenteFinanc = {
				Fuente_Financ: '',
				Nombre_FuenteFinanc: '',
				nEjecucion: 0,
				nCompromisoAnual: 0,
			};

			let pag3 = 0;
			ordenarArray(Object.keys(agrupadoFuenteFinanc), true).forEach((secFuenteFinanc) => {
				dataFuenteFinanc = {
					...{
						Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_Financ'],
						Nombre_FuenteFinanc: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_FuenteFinanc'],
					},
					...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
						'nEjecucion',
						'nCompromisoAnual',
					]),
				};

				const tablaGenerada = objThis.generarDesdeMantenimiento({
					titulos: camposPrincipal,
					contenido: ordenarPorPropiedad(agrupadoFuenteFinanc[secFuenteFinanc], 'Clasificador'),
					// numeracion: true,
					idxResto: [6],
					margin: [0, 0, 0, 5],
					separado: true,
				});


				if (pag3 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([
						[
							imprimirCelda(dataSecfunc.Ano_eje, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center',
							}),
							imprimirCelda(dataSecfunc.Sec_func, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center',
							}),
						].concat(
							generarColSpan(imprimirCelda(dataSecfunc.cNombre_Sec_func, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'left'
							}), 4).concat(
								imprimirCelda(dataSecfunc.nEjecucion, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.nCompromisoAnual, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								imprimirCelda((dataSecfunc.nEjecucion - dataSecfunc.nCompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							),
						)
					]);
				}

				nuevoCuerpo = nuevoCuerpo.concat([

					generarColSpan(imprimirCelda('', null, {
						bold: true,
						style: 'thSmall',
						alignment: 'left'
					}), 2).concat(
						// ].concat(
						imprimirCelda( dataFuenteFinanc.Fuente_Financ, null, {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						generarColSpan(imprimirCelda(dataFuenteFinanc.Nombre_FuenteFinanc, null, {
							bold: true,
							style: 'thSmall',
							alignment: 'left'
						}), 3),
						imprimirCelda(dataFuenteFinanc.nEjecucion, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataFuenteFinanc.nCompromisoAnual, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda((dataFuenteFinanc.nEjecucion - dataFuenteFinanc.nCompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					),

				]);

				nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
				pag3++;
			});


			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				// fontSize: 8,
				style: 'thSmall',
				alignment: 'right'
			}), 6).concat(
				imprimirCelda(sumasTotales.nEjecucion, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nCompromisoAnual, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda((sumasTotales.nEjecucion - sumasTotales.nCompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 20}, {idx: 1, valor: 20}, {idx: 2, valor: 30}, ]),
				body: nuevoCuerpo,
			}
		});
		// console.log(JSON.stringify(cuerpoPdf));
		// console.log(cuerpoPdf);

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}

export function marcoPresupuestalVsCertificadoVsCompromisoAnual(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.MarcoPresupuestalVsCertificadoVsCompromisoAnual'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.MarcoPresupuestalVsCertificadoVsCompromisoAnual'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');
		console.log(resultadosGenerales);

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('MARCO PRESUPUESTAL, CERTIFICADO Y PRESUPUESTO ANUAL - ' + resultadosGenerales[0]['Ano_eje'], null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Sec_func',
			'Fuente_Financ',
			'cGeneCodigo',
			'Clasificador',
		]);
		console.log(groups);
		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: '', type: 'text'},
			{label: 'SEC.FUNC', property: '', type: 'text'},
			{label: 'FTE.', property: '', type: 'text'},
			{label: 'GEN.', property: '', type: 'text'},
			{label: 'CLASIF./CERTIFICADO', property: '', type: 'text'},
			{label: 'Func/Prog Func/SubProg Func/Act Proy/Comp/Meta/Final/NOMBRE.', property: '', type: 'text'},
			{label: 'PIM', property: '', type: 'text'},
			{label: 'TOTAL CERTIFICADO', property: '', type: 'text'},
			{label: 'COMPROMISO ANUAL', property: '', type: 'money'},
			{label: 'SALDO PIM', property: '', type: 'money'},
			{label: 'SALDO CERTIFICADO', property: '', type: 'money'},
		];
		const camposPrincipalDetalle: ColumnaTabla<any>[] = [
			{label: 'Nº CERTIFICADO', property: 'Certificado', type: 'text'},
			{label: 'CERTIFICADO', property: 'Monto', type: 'money'},
			{label: 'COMPROMISO', property: 'CompromisoAnual', type: 'money'},
			{label: 'SALDO.', property: '', type: 'money', transformar(row): any {
				return (row.Monto - row.CompromisoAnual);
				}},
		];
// {Sp_Siaf_SEL_Certificado_CompromisoAnual_SaldoXcId_Clasificador;1.Monto}-{Sp_Siaf_SEL_Certificado_CompromisoAnual_SaldoXcId_Clasificador;1.CompromisoAnual}
		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'CompromisoAnual',

		]);


		const agrupadoSecfunc = groupBy(resultadosGenerales, (obj) => {
			return obj.Sec_func;
		});

		console.log(agrupadoSecfunc);
		let dataSecfunc = {
			Ano_eje: '',
			Sec_func: '',
			Act_proy: '',
			Componente: '',
			Funcion: '',
			Programa: '',
			Sub_programa: '',
			Meta: '',
			Finalidad: '',
			cNombre_Sec_func: '',

			nPim: 0,
			nCertificado: 0,
			CompromisoAnual: 0,
		};


		let pag = 0;
		let nuevoCuerpo = [];


		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoSecfunc).forEach((secSecfunc) => {
			dataSecfunc = {
				...{
					Ano_eje: agrupadoSecfunc[secSecfunc][0]['Ano_eje'],
					Sec_func: agrupadoSecfunc[secSecfunc][0]['Sec_func'],
					Act_proy: agrupadoSecfunc[secSecfunc][0]['Act_proy'],
					Componente: agrupadoSecfunc[secSecfunc][0]['Componente'],
					Funcion: agrupadoSecfunc[secSecfunc][0]['Funcion'],
					Programa: agrupadoSecfunc[secSecfunc][0]['Programa'],
					Sub_programa: agrupadoSecfunc[secSecfunc][0]['Sub_programa'],
					Meta: agrupadoSecfunc[secSecfunc][0]['Meta'],
					Finalidad: agrupadoSecfunc[secSecfunc][0]['Finalidad'],
					cNombre_Sec_func: agrupadoSecfunc[secSecfunc][0]['cNombre_Sec_func'],
				},
				...sumarObjetos(agrupadoSecfunc[secSecfunc], [
					'nPim',
					'nCertificado',
					'CompromisoAnual',
				]),
			};

			const agrupadoFuenteFinanc = groupBy(agrupadoSecfunc[secSecfunc], (obj) => {
				return obj.Fuente_Financ;
			});

			let dataFuenteFinanc = {
				Fuente_Financ: '',
				Nombre_Fuente_Financ: '',
				nPim: 0,
				nCertificado: 0,
				CompromisoAnual: 0,
			};

			let pag2 = 0;
			Object.keys(agrupadoFuenteFinanc).forEach((secFuenteFinanc) => {
				dataFuenteFinanc = {
					...{
						Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_Financ'],
						Nombre_Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_Fuente_Financ'],
					},
					...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
						'nPim',
						'nCertificado',
						'CompromisoAnual',
					]),
				};

				if (pag2 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([
						[
							imprimirCelda(dataSecfunc.Ano_eje, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center',
							}),
							imprimirCelda(dataSecfunc.Sec_func, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center',
							}),
						].concat(
							generarColSpan(imprimirCelda( dataSecfunc.Sec_func + '.' + dataSecfunc.Act_proy + '.'+ dataSecfunc.Componente +
								'-' + dataSecfunc.Funcion + '.' + dataSecfunc.Programa + '.' +  dataSecfunc.Sub_programa + '-' + dataSecfunc.Meta +
								'.' + dataSecfunc.Finalidad + ' ' + dataSecfunc.cNombre_Sec_func
								, null, {
								bold: true,
								// fontSize: 8,
								style: 'thSmall',
								alignment: 'left'
							}), 4).concat(
								imprimirCelda(dataSecfunc.nPim, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.nCertificado, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.CompromisoAnual, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda((dataSecfunc.nPim - dataSecfunc.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								imprimirCelda((dataSecfunc.nCertificado - dataSecfunc.CompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							),
						)
					]);
				}
				const agrupadocGeneCodigo = groupBy(agrupadoFuenteFinanc[secFuenteFinanc], (obj) => {
					return obj.cGeneCodigo;
				});
				let datacGeneCodigo: any = {};

				let pag3 = 0;
				ordenarArray(Object.keys(agrupadocGeneCodigo), true).forEach((seccGeneCodigo) => {
					datacGeneCodigo = {
						...{
							cGeneCodigo: agrupadocGeneCodigo[seccGeneCodigo][0]['cGeneCodigo'],
							cGeneNombre: agrupadocGeneCodigo[seccGeneCodigo][0]['cGeneNombre'],
						},
						...sumarObjetos(agrupadocGeneCodigo[seccGeneCodigo], [
							'nPim',
							'nCertificado',
							'CompromisoAnual',
						]),
					};

					if (pag3 == 0) {
						nuevoCuerpo = nuevoCuerpo.concat([
							// [
							// 	imprimirCelda('', null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'left',
							// 	}),
							// 	imprimirCelda('', null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'left',
							// 	}),
							// 	imprimirCelda(dataFuenteFinanc.Fuente_Financ, null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'left',
							// 	}),
							// ].concat(
								generarColSpan(imprimirCelda('', null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}), 2).concat(
									imprimirCelda(dataFuenteFinanc.Fuente_Financ, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'center'
									}),
									generarColSpan(imprimirCelda(dataFuenteFinanc.Nombre_Fuente_Financ, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'left'
									}), 3),
									imprimirCelda(dataFuenteFinanc.nPim, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(dataFuenteFinanc.nCertificado, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(dataFuenteFinanc.CompromisoAnual, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda((dataFuenteFinanc.nPim - dataFuenteFinanc.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
									imprimirCelda((dataFuenteFinanc.nCertificado - dataFuenteFinanc.CompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								),
							// ),
						]);

						nuevoCuerpo = nuevoCuerpo.concat([
							// [
							// 	imprimirCelda('', null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'left',
							// 	}),
							// 	imprimirCelda('', null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'left',
							// 	}),
							// 	imprimirCelda('', null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'left',
							// 	}),
							// 	imprimirCelda(datacGeneCodigo.cGeneCodigo, null, {
							// 		bold: true,
							// 		style: 'thSmall',
							// 		alignment: 'right',
							// 		// rowSpan: tablaGenerada['cuerpo'].length + 1,
							// 	}),
							// ].concat(
								generarColSpan(imprimirCelda('', null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}), 3).concat(
									imprimirCelda(datacGeneCodigo.cGeneCodigo, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'right',
										// rowSpan: tablaGenerada['cuerpo'].length + 1,
									}),
									generarColSpan(imprimirCelda(datacGeneCodigo.cGeneNombre, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'left'
									}), 2),
									imprimirCelda(datacGeneCodigo.nPim, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(datacGeneCodigo.nCertificado, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(datacGeneCodigo.CompromisoAnual, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda((datacGeneCodigo.nPim - datacGeneCodigo.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
									imprimirCelda((datacGeneCodigo.nCertificado - datacGeneCodigo.CompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								),
							// ),
						]);
					}

					agrupadocGeneCodigo[seccGeneCodigo].forEach(item => {

						const tablaDetalle = objThis.generarDesdeMantenimiento({
							titulos: camposPrincipalDetalle,
							contenido: ordenarPorPropiedad(item.tablaDetalle, 'Clasificador'),
							// numeracion: true,
							idxResto: [0],
							margin: [0, 0, 0, 0],
						});

						nuevoCuerpo = nuevoCuerpo.concat([
							generarColSpan(imprimirCelda('', null, {
								bold: true,
								style: 'thSmall',
								// border: [false, false, false, false],
								alignment: 'left'
							}), 4).concat(
								// ].concat(
								imprimirCelda( item.Clasificador, null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}),
								imprimirCelda( item.Nombre_Clasificador, null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}),
								imprimirCelda(item.nPim, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(item.nCertificado, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(item.CompromisoAnual, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda((item.nPim - item.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								imprimirCelda((item.nCertificado - item.CompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							),

						]);
						nuevoCuerpo = nuevoCuerpo.concat([
							generarColSpan(imprimirCelda('', null, {
								bold: true,
								style: 'tdSmall',
								border: [true, true, false, true],
								alignment: 'left'
							}), 4).concat(
								// ].concat(
								generarColSpan(tablaDetalle, 2).concat(
									generarColSpan(imprimirCelda('', null, {
										bold: true,
										style: 'tdSmall',
										border: [false, true, true, true],
										alignment: 'left'
									}), 5)
								),
							),
						]);

					});


/*

					console.log(agrupadocGeneCodigo[seccGeneCodigo]);
					const agrupadoClasificador = groupBy(agrupadocGeneCodigo[seccGeneCodigo], (obj) => {
						return obj.Clasificador;
					});

					console.warn(agrupadoClasificador);
					let dataClasificador = {
						Clasificador: '',
						Nombre_Clasificador: '',
						nPim: 0,
						nCertificado: 0,
						CompromisoAnual: 0,
					};

					let pag4 = 0;
					ordenarArray(Object.keys(agrupadoClasificador), true).forEach((secClasificador) => {
						dataClasificador = {
							...{
								Clasificador: agrupadoClasificador[secClasificador][0]['Clasificador'],
								Nombre_Clasificador: agrupadoClasificador[secClasificador][0]['Nombre_Clasificador'],
							},
							...sumarObjetos(agrupadoClasificador[secClasificador], [
								'nPim',
								'nCertificado',
								'CompromisoAnual',
							]),
						};

						const tablaGenerada = objThis.generarDesdeMantenimiento({
							titulos: camposPrincipal,
							contenido: ordenarPorPropiedad(agrupadoClasificador[secClasificador], 'Clasificador'),
							// numeracion: true,
							idxResto: [6],
							margin: [0, 0, 0, 5],
							separado: true,
						});


						if (pag4 == 0) {
							nuevoCuerpo = nuevoCuerpo.concat([
								[
									imprimirCelda('', null, {
										bold: true,
										style: 'thSmall',
										alignment: 'left',
									}),
									imprimirCelda('', null, {
										bold: true,
										style: 'thSmall',
										alignment: 'left',
									}),
									imprimirCelda('', null, {
										bold: true,
										style: 'thSmall',
										alignment: 'left',
									}),
									imprimirCelda(datacGeneCodigo.cGeneCodigo, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'right',
										// rowSpan: tablaGenerada['cuerpo'].length + 1,
									}),
								].concat(
									generarColSpan(imprimirCelda(datacGeneCodigo.cGeneNombre, null, {
										bold: true,
										fontSize: 8,
										style: 'thSmall',
										alignment: 'left'
									}), 2).concat(
										imprimirCelda(datacGeneCodigo.nPim, 'money', {
											bold: true,
											style: 'thSmall',
											alignment: 'left'
										}),
										imprimirCelda(datacGeneCodigo.nCertificado, 'money', {
											bold: true,
											style: 'thSmall',
											alignment: 'right'
										}),
										imprimirCelda(datacGeneCodigo.CompromisoAnual, 'money', {
											bold: true,
											style: 'thSmall',
											alignment: 'right'
										}),
										imprimirCelda('', 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
										imprimirCelda('', 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
									),
								)
							]);
						}

						nuevoCuerpo = nuevoCuerpo.concat([

							generarColSpan(imprimirCelda('', null, {
								bold: true,
								style: 'thSmall',
								alignment: 'left'
							}), 4).concat(
								// ].concat(
								imprimirCelda( dataClasificador.Clasificador, null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}),
								imprimirCelda( dataClasificador.Nombre_Clasificador, null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}),
								imprimirCelda(dataClasificador.nPim, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataClasificador.nCertificado, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataClasificador.CompromisoAnual, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda('', 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								imprimirCelda('', 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							),


						]);
						//camposPrincipalDetalle

						// nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
						// otra tabla
						pag4++;

					});
				*/
					pag3++;
				});
				pag2++;
			});
			pag++;
		});

		// console.log(JSON.stringify(cuerpoPdf));

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				style: 'thSmall',
				alignment: 'right'
			}), 6).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.CompromisoAnual, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda((sumasTotales.nPim - sumasTotales.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda((sumasTotales.nCertificado - sumasTotales.CompromisoAnual), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				// dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 50}, {idx: 1, valor: 30}, {idx: 2, valor: 30}, ]),
				body: nuevoCuerpo,
			}
		});
		console.log(JSON.stringify(cuerpoPdf));

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}




