import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {OpcionSeleccionada} from '@JVSoft/interfaces/global';
import {ModeloCheck} from '@JVSoft/services/formularios-globales.service';
import {generarArbol} from '@JVSoft/services/funciones-globales.service';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {Observable} from 'rxjs';

@Component({
    selector: 'general-lista-arbol',
    templateUrl: './lista-arbol.component.html',
    styleUrls: ['./lista-arbol.component.scss'],
})
export class ListaArbolComponent implements OnInit {
    constructor() {

    }


    @Input() objThis;
    @Input() lista;
    @Input() listaSuscription: Observable<any>;
    @Input() nombreColeccion;
    @Input() checkbox = false;
    @Input() expandirRecursivo = false;
    @Input() checkboxSeleccionados = [];
    @Input() campoId;
    @Input() campoIdPadre;
    @Input() campoStr;
    @Input() strHijoContainer = 'hijos';
    @Input() menuContextual = undefined;
    @Input() classSeleccionado = ['bg-primary-activo', 'text-primary', 'dark:!bg-primary-dark-activo'];
    @Input() classAnulado = ['line-through', 'text-red', 'italic'];
    @Input() campoAnulado = null;
    @Input() templateTxtData: TemplateRef<any>;
    @Input() condicionMostrar: (item) => {};

    @Output() seleccionado = new EventEmitter<any>();
    @Output() listaCheck = new EventEmitter<any>();
    @Output() listaCheckObj = new EventEmitter<any>();

    chkLista: ModeloCheck;
    listaMuestraArbol;
    treeControl = new NestedTreeControl(node => node[this.strHijoContainer]);

    @Input() condicionesClaseFila = item => [];

    hasChild = (_: number, node) => !!node[this.strHijoContainer] && node[this.strHijoContainer].length > 0;

    ngOnInit(): void {
        this.chkLista = new ModeloCheck();
        if (this.checkbox && this.lista) {
            this.chkLista.agregarControles(this.lista, this.campoId);
            this.checkboxSeleccionados.forEach(chkSel => {
                this.chkLista.setState(chkSel[this.campoId], true);
            });
            this.emitirModeloCheck();
        }

        if (this.listaSuscription) {
            this.listaSuscription.subscribe(res => {
                if (res) {
                    console.log(res);
                    const listaAs = generarArbol(res, this.campoId, this.campoIdPadre, null, this.strHijoContainer);
                    this.listaMuestraArbol = [];
                    setTimeout(() => {
                        this.listaMuestraArbol = listaAs;
                    }, 1);
                    if (this.checkbox) {
                        this.chkLista.agregarControles(res, this.campoId);
                        this.checkboxSeleccionados.forEach(chkSel => {
                            this.chkLista.setState(chkSel[this.campoId], true);
                        });
                        this.emitirModeloCheck();
                    }
                }
            });
        }
        else {
            this.listaMuestraArbol = generarArbol(this.lista, this.campoId, this.campoIdPadre, null, this.strHijoContainer);
        }
    }

    opcMenu(v: OpcionSeleccionada) {
        this.seleccionado.emit(v);
    }

    emitirModeloCheck() {
        this.listaCheck.emit(this.chkLista.generarLista());
        this.listaCheckObj.emit(this.chkLista.generarLista('object'));
    }

    cambiarPadre(checkActual, estado) {
        if (checkActual[this.campoIdPadre]) {
            const padreAct = dataEnLista(this.lista, this.campoId, checkActual[this.campoIdPadre]);
            this.chkLista.setState(checkActual[this.campoIdPadre], (this.hijosActivos('checked', padreAct) || this.hijosActivos('indeterminate', padreAct)));
            if (padreAct) {
                this.cambiarPadre(padreAct, estado);
            }
        }
    }

    cambiarCheck(checkActual, estado) {
        console.log(checkActual, estado);
        if (this.hasChild(0, checkActual)) {
            this.treeControl.getChildren(checkActual).forEach(vas => {
                this.cambiarCheck(vas, estado);
            });
        }
        this.cambiarPadre(checkActual, estado);
        this.chkLista.setState(checkActual[this.campoId], estado);
    }

    hijosActivos(tipo: 'checked' | 'indeterminate', checkActual) {
        let totalHijos = 0;
        let cantActivo = 0;
        if (this.hasChild(0, checkActual)) {
            this.treeControl.getChildren(checkActual).forEach(vas => {
                if (this.chkLista.getState(vas[this.campoId] + '')) {
                    cantActivo++;
                }
                totalHijos++;
            });
        }

        switch (tipo) {
            case 'checked':
                return (cantActivo == totalHijos);
            case 'indeterminate':
                if (cantActivo == totalHijos) {
                    this.chkLista.setState(checkActual[this.campoId], true);
                }
                return ((cantActivo > 0) && (cantActivo != totalHijos));
        }
        return false;
    }

    classFila(item) {
        let claseFinal = [];
        if (this.campoAnulado && (item[this.campoAnulado] == 1)) {
            claseFinal = claseFinal.concat(this.classAnulado);
        }
        if ((this.objThis['seleccionados'][this.nombreColeccion] == item)) {
            claseFinal = claseFinal.concat(this.classSeleccionado);
        }
        claseFinal = claseFinal.concat(this.condicionesClaseFila(item));
        item.claseFinal = claseFinal;
        return claseFinal;
    }
}
