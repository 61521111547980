import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MenuItem} from '../interfaces/menu-item.interface';
import {trackById} from '@vex/utils/track-by';
import {PopoverRef} from '@vex/components/popover/popover-ref';
import {QueryService} from "@servicios/query.service";
import {SessionService} from "../../../../../app/session/services/session.service";
import {UsuarioService} from "@servicios/usuario.service";
import {ServidorService} from '@servicios/servidor.service';
import {EntidadService} from '@servicios/entidad.service';
import {Router} from '@angular/router';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
      /*
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/apps/social'
    },
    {
      id: '2',
      icon: 'mat:move_to_inbox',
      label: 'My Inbox',
      description: 'Messages & Latest News',
      colorClass: 'text-primary',
      route: '/apps/chat'
    },
    {
      id: '3',
      icon: 'mat:list_alt',
      label: 'My Projects',
      description: 'Tasks & Active Projects',
      colorClass: 'text-amber',
      route: '/apps/scrumboard'
    },
    {
      id: '4',
      icon: 'mat:table_chart',
      label: 'Billing Information',
      description: 'Pricing & Current Plan',
      colorClass: 'text-purple',
      route: '/pages/pricing'
    }
    */
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(
      private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
      private queryService: QueryService,
      public sessionService: SessionService,
      public entidadService: EntidadService,
      public usuarioService: UsuarioService,
      public servidorService: ServidorService,
      private router: Router,
  ) { }

  ngOnInit() {
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  guardarDatosCache(event) {
    console.log(event);
    this.queryService.setDataSession({
      dataUsuarioEnCache: event,
    }).then();
  }

  close() {
    this.sessionService.logout().then(() => {
      this.popoverRef.close();
      this.servidorService.limpiarIntervalos();
      if (this.entidadService.idEntidadFija){
        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 500)

      }
    });
  }
}
