import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Subject, takeUntil} from 'rxjs';

@Directive({
    selector: '[matRowKeyboardSelection]'
})
export class MatRowKeyboardSelectionDirective implements OnInit, OnDestroy {

    private rows: HTMLElement[];
    private renderedData: any[];

    @Input('matRowKeyboardSelection') tabla: MatTable<any>; // Entrada: Referencia a la tabla
    @Input() rowModel: any; // Entrada: Modelo de fila actual
    @Output() seleccionarSiguiente = new EventEmitter(); // Salida: Evento al seleccionar siguiente

    private unsubscriber$ = new Subject();

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        // Asignar tabindex si no está definido
        if (this.el.nativeElement.tabIndex < 0) {
            this.el.nativeElement.tabIndex = 0;
        }

        // Obtener la fuente de datos de la tabla
        const dataSource = this.tabla.dataSource as MatTableDataSource<any>;

        // Suscribirse a los cambios en los datos
        dataSource.connect().pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
            this.renderedData = data;
            this.rows = Array.from(this.getTableRows());
        });
    }

    ngOnDestroy(): void {
        // Finalizar la suscripción al destruir la directiva
        this.unsubscriber$.next(null);
        this.unsubscriber$.complete();
    }

    @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent): void {
        var element = event.target as HTMLElement;
        if (element.tagName === "INPUT") {
            event.stopPropagation();
        }
        else {
            const currentIndex = this.renderedData.findIndex(row => row === this.rowModel);
            const eRef = event.target as HTMLElement;
            this.rows = this.rows.filter(elem => elem.hasAttribute('id'));
            const arrayFilas = Array.from(this.rows);

            const fElem = arrayFilas.filter(elem => elem.id == eRef.id).pop();
            const cElemIdx = arrayFilas.findIndex(elem => elem == fElem);

            let newRow: HTMLElement | undefined;

            // Controlar las teclas presionadas
            switch (event.key) {
                case 'ArrowDown':
                    newRow = this.rows[cElemIdx + 1];
                    break;
                case 'ArrowUp':
                    newRow = this.rows[cElemIdx - 1];
                    break;
                case 'Enter':
                case ' ':
                    this.seleccionarSiguiente.next(this.renderedData[currentIndex]);
                    event.preventDefault();
                    break;
            }

            // Enfocar la nueva fila seleccionada
            if (newRow) {
                newRow.classList.add('estaFocus');
                newRow.focus();
            }
        }
    }

    private getTableRows(): NodeListOf<HTMLElement> {
        let el = this.el.nativeElement;

        // Recorrer los elementos padres hasta encontrar la tabla
        while (el && el.parentNode) {
            el = el.parentNode;

            // Verificar si es una tabla de material
            if (el.tagName && el.tagName.toLowerCase() === 'mat-table' || el.hasAttribute('mat-table')) {
                return el.querySelectorAll('mat-row, tr[mat-row]');
            }
        }

        return null;
    }
}
