import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BotonDialog} from '../../interfaces/global';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'global-dialog-flotante, [globalDialogFlotante]',
    templateUrl: './dialog-flotante.component.html',
    styleUrls: ['./dialog-flotante.component.scss'],
    host: {
        class: 'global-dialog-flotante',
    }
})
export class DialogFlotanteComponent implements OnInit {
    @Input() cssClases: object = {};

    private _sinTitulo: boolean | '';
    get sinTitulo(): boolean { return this._sinTitulo || false; }
    @Input() set sinTitulo(val: boolean | '') { this._sinTitulo = val == ''; }
    private _sinBarraDeAccion: boolean | '';
    get sinBarraDeAccion(): boolean { return this._sinBarraDeAccion || false; }
    @Input() set sinBarraDeAccion(val: boolean | '') { this._sinBarraDeAccion = val == ''; }

    private _btnCerrar: BotonDialog;
    get btnCerrar(): BotonDialog { return this._btnCerrar; }

    @Input() set btnCerrar(val: BotonDialog | boolean | string | '') {
        const btnCerrar = 'text-gray-700 border-gray-700';
        if (typeof val != 'boolean') {
            if (typeof val == 'string' && val != '') {
                this._btnCerrar = {label: val, class: btnCerrar};
            }
            else {
                this._btnCerrar = val || {label: 'CERRAR', class: btnCerrar};
            }
        }
        else if (val) {
            this._btnCerrar = {label: 'CERRAR', class: btnCerrar};
        }
    }

    private _btnCerrarBarra: boolean;
    get btnCerrarBarra(): boolean { return this._btnCerrarBarra; }
    @Input() set btnCerrarBarra(val: boolean | ''){ this._btnCerrarBarra = val || true; }

    private _btnGuardar: BotonDialog;
    get btnGuardar(): BotonDialog { return this._btnGuardar; }

    @Input() set btnGuardar(val: BotonDialog | boolean | string | '') {
        // flex items-center justify-center bg-primary text-primary-contrast font-bold uppercase text-xs px-2 py-1 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
        const claseGuardar = 'text-blue-700 border-blue-700';
        if (typeof val != 'boolean') {
            if (typeof val == 'string' && val != '') {
                this._btnGuardar = {label: val, class: claseGuardar};
            }
            else {
                this._btnGuardar = val || {label: 'GUARDAR', class: claseGuardar};
            }
        }
        else if (val) {
            this._btnGuardar = {label: 'GUARDAR', class: claseGuardar};
        }
    }

    @Input() matDialogRefActual: MatDialogRef<any>;
    @Output() btnGuardarClick = new EventEmitter<any>();
    @Output() btnCerrarClick = new EventEmitter<any>();

    constructor(
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
    }

    guardarDialogo() {
        this.btnGuardarClick.emit(true);
    }

    cerrarDialogo() {
        this.btnCerrarClick.emit(true);
        if (this.matDialogRefActual) {
            this.matDialogRefActual.close('Cerrado GDLG');
        }
    }

}
