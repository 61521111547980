import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {AbstractControl, FormControl, NgControl} from '@angular/forms';
import {mensajesErrorFormControl} from '../services/funciones-globales.service';

@Directive({
  selector: '[globalFormControlError]'
})
export class FormControlErrorDirective implements OnInit {

	@Input() globalFormControlError: FormControl | AbstractControl;

	constructor(private eleRef: ElementRef) { }

	ngOnInit() {
		console.log(this.eleRef);
		this.globalFormControlError.markAsTouched();
		this.globalFormControlError.valueChanges.subscribe(a => {
			console.log(a);
			const msgError = mensajesErrorFormControl(this.globalFormControlError);
			console.warn(msgError);
			this.eleRef.nativeElement.innerText = msgError;
		});

		// console.log(this.eleRef.nativeElement.parentElement);
		// console.warn(this.eleRef.nativeElement.parentNode.parentNode.parentNode);
		// console.log(this.control);
		// this.control.control.markAsTouched();
		// this.control.valueChanges.subscribe((a) => {
		// 	console.log(a);
		// 	console.log(this.control.control.errors);
		// 	const msgError = mensajesErrorFormControl(this.control.control);
		// 	console.warn(msgError);
		// 	// this.eleRef.nativeElement.innerText = msgError;
		// });
		// console.log('Changes');
		// // this.disableElement(this.eleRef.nativeElement);
	}


	// private disableElement(element: any) {
	// 	if (this.globalElementosDeshabilitados) {
	// 		if (!element.hasAttribute(DISABLED)) {
	// 			this.renderer.setAttribute(element, APP_DISABLED, '');
	// 			this.renderer.setAttribute(element, DISABLED, 'true');
	//
	// 			// disabling anchor tab keyboard event
	// 			if (element.tagName.toLowerCase() === TAG_ANCHOR) {
	// 				this.renderer.setAttribute(element, TAB_INDEX, '-1');
	// 			}
	// 		}
	// 	} else {
	// 		if (element.hasAttribute(APP_DISABLED)) {
	// 			if (element.getAttribute('disabled') !== '') {
	// 				element.removeAttribute(DISABLED);
	// 			}
	// 			element.removeAttribute(APP_DISABLED);
	// 			if (element.tagName.toLowerCase() === TAG_ANCHOR) {
	// 				element.removeAttribute(TAB_INDEX);
	// 			}
	// 		}
	// 	}
	// 	if (element.children) {
	// 		for (let ele of element.children) {
	// 			this.disableElement(ele);
	// 		}
	// 	}
	// }
}
