<div globalDialogFlotante btnCerrar *ngIf="this.seleccionados.pedidos">
    <ng-container titulo>PEDIDO Nº <span class="font-bold">{{ (this.seleccionados.pedidos['iPedidoNumero']) }}</span></ng-container>

    <div cuerpo class="dataDetalle flex flex-col gap-2">
        <div class="flex-1 flex flex-col sm:flex-row items-center gap-2">
            <fieldset class="flex-1 appearance-none contenedorCampoConBorde relative">
                <legend>Establecimiento</legend>
                <input type="text" [value]="seleccionados.pedidos.cEstablecimientoNombreLargo" [readonly]="true">
            </fieldset>
            <fieldset class="flex-1 appearance-none contenedorCampoConBorde relative">
                <legend>Dependencia</legend>
                <input type="text" [value]="seleccionados.pedidos.cDepenNombre" [readonly]="true">
            </fieldset>
            <fieldset class="sm:flex-none flex-grow contenedorCampoConBorde relative">
                <legend>Tipo de Producto</legend>
                <input type="text" [value]="seleccionados.pedidos.cTipoProductoNombre" [readonly]="true">
            </fieldset>
            <fieldset class=" sm:flex-none flex-grow contenedorCampoConBorde relative">
                <legend>Clase Pedido</legend>
                <input type="text" [value]="seleccionados.pedidos.cClasePedidoNombre" [readonly]="true">
            </fieldset>
<!--            <fieldset class="flex-none sm:flex-grow contenedorCampoConBorde relative">-->
<!--                <legend>Fecha de Emisión</legend>-->
<!--                <input type="text" [value]="seleccionados.pedidos.cEstablecimientoNombreLargo | date: 'dd/MM/yyyy'" [readonly]="true">-->
<!--            </fieldset>-->
        </div>
        <div class="flex-1 flex flex-col sm:flex-row items-center gap-2">
            <fieldset class="sm:flex-none flex-grow contenedorCampoConBorde relative">
                <legend>Fecha de Emisión</legend>
                <input type="text" [value]="seleccionados.pedidos.dPedidoEmision | date: 'dd/MM/yyyy'" [readonly]="true">
            </fieldset>
            <fieldset class="flex-1 appearance-none contenedorCampoConBorde relative">
                <legend>Solicitante</legend>
                <input type="text" [value]="seleccionados.pedidos.cPersNombreLargo" [readonly]="true">
            </fieldset>
            <fieldset class="flex-1 appearance-none contenedorCampoConBorde relative">
                <legend>Referencia</legend>
                <input type="text" [value]="seleccionados.pedidos.cPedidoReferencia" [readonly]="true">
            </fieldset>
        </div>
        <div class="flex-1 flex flex-col sm:flex-row items-center gap-2">
            <fieldset class="sm:flex-none flex-grow contenedorCampoConBorde relative">
                <legend>Año</legend>
                <input type="text" [value]="seleccionados.pedidos.iYearId" [readonly]="true">
            </fieldset>
            <fieldset class="sm:flex-none flex-grow contenedorCampoConBorde relative">
                <legend>Mes</legend>
                <input type="text" [value]="seleccionados.pedidos.cMonthNombre" [readonly]="true">
            </fieldset>
            <fieldset class="flex-1 appearance-none contenedorCampoConBorde relative">
                <legend>Tarea</legend>
                <input type="text" [value]="seleccionados.pedidos.cTareaCodigo + ' - ' + seleccionados.pedidos.cTareaNombre" [readonly]="true">
            </fieldset>
        </div>
        <div class="flex-1 flex flex-col sm:flex-row items-center gap-2">
            <fieldset class="flex-1 contenedorCampoConBorde relative">
                <legend>Descripción de la Orden</legend>
                <textarea [value]="seleccionados.pedidos.cPedidoObs" [readonly]="true" autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
            </fieldset>
        </div>
        <div class="flex-1 flex flex-row items-center gap-2">
            <general-tabla-mantenimiento-nuevo
                class="flex-grow mat-elevation-z4"
                [objThis]="this"
                [botonesCRUD]="(seleccionados.pedidos.bPedidoCerrado == 1) ? '' : 'CUD'"
                [dataSuscription]="dataPrincipal$"
                nombreColeccion="pedidos_detalles"
                [idTabla]="['iPedidoDetId']"
                [columnasTabla]="columnasPrincipal"
                (opcionSelecionada)="opcMenu($event)"
                [paginador]="false"
                [exportarExcel]="false"
                (accionRecargar)="cargarLista()"
            >
            </general-tabla-mantenimiento-nuevo>
        </div>

    </div>

    <ng-container pieDerecha *ngIf="datosDialogRecibidos.templateDetallePieDerecho">
        <ng-container [ngTemplateOutlet]="datosDialogRecibidos?.templateDetallePieDerecho" [ngTemplateOutletContext]="{item: seleccionados.pedidos, dialog: dialogActualRef}"></ng-container>
    </ng-container>
</div>

<!--TEMPLATES-->
<ng-template #dialogRegistro>
    <form globalDialogFlotante btnGuardar btnCerrar="CANCELAR"
          (ngSubmit)="opcMenu({ seccion: 'pedidos_detalles', tipo: 'guardar' })" [formGroup]="frmRegistro"
          [matDialogRefActual]="dialogRef[0]"
    >
        <ng-container titulo>
            {{ (frmRegistro.get('iPedidoDetId').value ? 'Editar' : 'Nuevo') }} ::<small> Detalle</small>
        </ng-container>

        <div cuerpo class="w-full flex flex-col items-center sm:gap-1 text-xs">
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Producto</mat-label>
                    <input formControlName="iProductoIdDesc" name="iProductoIdDesc" placeholder="Clic para Buscar..." autocomplete="off" type="text" [readonly]="true" matInput
                        (click)="opcMenu({seccion: 'pedidos_detalles', tipo: (esPedidoAlmacen() ? 'buscar_producto_almacen' : 'buscar_producto')}); $event.preventDefault();"
                        [globalMatAutofocus]="true"
                    >
                    <div matSuffix class="flex items-center justify-start" *ngIf="frmRegistro.get('iProductoIdDesc').enabled">
                        <span *ngIf="frmRegistro.get('iProductoIdDesc2').value">&nbsp; {{ frmRegistro.get('iProductoIdDesc2').value }}</span>
                        <button matRipple type="button"
                            class="boton-circular-gris"
                            (click)="(frmRegistro.get('iProductoIdDesc').value ? frmRegistro.patchValue({iProductoIdDesc: '', iProductoId: '', iEncGasDetIdDesc: '', iEncGasDetId: ''}) : opcMenu({seccion: 'pedidos_detalles', tipo: (esPedidoAlmacen() ? 'buscar_producto_almacen' : 'buscar_producto')})); $event.preventDefault();"
                        >
                            <mat-icon [svgIcon]="(frmRegistro.get('iProductoIdDesc').value ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
                        </button>
                    </div>
                    <!--<div matSuffix
                        fxLayout="row wrap"
                        fxLayoutAlign="start center"
                        *ngIf="frmRegistro.get('iProductoIdDesc').enabled"
                    >
                        <span *ngIf="frmRegistro.get('iProductoIdDesc2').value">&nbsp; {{ frmRegistro.get('iProductoIdDesc2').value }}</span>
                        <button mat-icon-button
                            *ngIf="frmRegistro.get('iProductoIdDesc').value"
                            fxLayout="row wrap"
                            fxLayoutAlign="center center"
                            (click)="frmRegistro.patchValue({iProductoIdDesc: '', iProductoId: '', iEncGasDetIdDesc: '', iEncGasDetId: ''}); $event.preventDefault();"
                        >
                            <mat-icon svgIcon="roundCancel" class="icon-xs" *ngIf="datosDialogRecibidos.iDestinoPedidoId != 2"></mat-icon>
                        </button>
                        <button mat-icon-button
                            *ngIf="!frmRegistro.get('iProductoIdDesc').value"
                            fxLayout="row wrap"
                            fxLayoutAlign="center center"
                            (click)="opcMenu({seccion: 'pedidos_detalles', tipo: (esPedidoAlmacen() ? 'buscar_producto_almacen' : 'buscar_producto')}); $event.preventDefault();"
                        >
                            <mat-icon svgIcon="roundSearch" class="icon-xs"></mat-icon>
                        </button>
                    </div>-->
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('iProductoIdDesc')) }}</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-auto sm:flex-1 sm:max-w-[120px]">
                    <mat-label>Cantidad</mat-label>
                    <input type="number" class="text-right" [class.text-red-500]="esPedidoAlmacen() && frmRegistro.get('nPedidoDetCantidad').value > frmRegistro.get('nStock_movimientos_productos_pedidos').value" matInput formControlName="nPedidoDetCantidad">
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('nPedidoDetCantidad')) }}</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-auto sm:flex-1 sm:max-w-[120px]" *ngIf="esPedidoAlmacen() && esDeAbastecimiento">
                    <mat-label>Stock Actual</mat-label>
                    <input type="number" class="text-right" matInput formControlName="nStock_movimientos_productos_pedidos" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <mat-form-field class="flex-1 w-full">
                    <mat-label>Especificaciones</mat-label>
                    <textarea formControlName="cPedidoDetObs" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
                    <mat-error>{{ mensajesErrorFormControl(frmRegistro.get('cPedidoDetObs')) }}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #dlgBuscarProductosAlmacen>
    <div globalDialogFlotante btnCerrar [matDialogRefActual]="dialogRef[2]">
        <ng-container titulo>
            Listado de Productos en Almacen :: <small> Seleccione </small>
        </ng-container>
        <div cuerpo class="w-full flex flex-col items-center sm:gap-1 text-xs">
            <general-tabla-mantenimiento-nuevo
                class="flex-1 w-full"
                [opciones]="opcionesFiltroAlmacen"
                (resultados)="filtroResultadosAlmacen($event)"
                [defaultTab]="1"


                [objThis]="this"
                nombreColeccion="movimientos_productos_detalles_pedidos"
                [dataSuscription]="dataProductosAlmacen$"
                botonesCRUD=""
                [columnasTabla]="columnasProductosAlmacen"
                (opcionSelecionada)="opcMenu($event)"
                (accionRecargar)="filtroResultadosAlmacen($event)"
            >

            </general-tabla-mantenimiento-nuevo>
        </div>
        <ng-container pieDerecha>
            <button matRipple class="boton-dialog-flotante boton-dialog-flotante-indigo"  type="submit"
                [disabled]="!seleccionados.movimientos_productos_detalles_pedidos"
                (click)="opcMenu({seccion: 'movimientos_productos_detalles_pedidos', tipo:'seleccionar'})"
            >
                <mat-icon class="icon-xs" svgIcon="mat:add"></mat-icon>
                <span>AÑADIR SELECCIONADO</span>
            </button>
        </ng-container>
    </div>
</ng-template>
