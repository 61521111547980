<ng-container *ngIf="formPrincipal.get(this.idSeleccion).value">
    <div @stagger class="flex items-center justify-between w-full">
        <img @scaleIn [src]="servidorService.photoThumbURL + formPrincipal.get(this.idSeleccion).value + '?ancho=75&alto=75'"
             alt="Foto" class="avatar flex-grow-0 w-6 h-6"/>
        <div @fadeInRight class="flex-grow truncate h-auto">
            <p class="body-2 m-0 leading-snug text-2xs font-bold">{{ formPrincipal.get('cPersTrabajadorNombre').value }}</p>
<!--            <p class="text-secondary text-2xs italic m-0 caption leading-none">{{formPrincipal.get('cTipoIdentSigla').value}} {{formPrincipal.get('cPersTrabajadorDocumento').value}}</p>-->
            <p class="text-secondary text-2xs italic m-0 caption leading-none">
                <span class="text-red-700">{{formPrincipal.get('cDepenNombre').value}}</span> /
                <span class="text-blue-700">{{formPrincipal.get('cCargoNombre').value}}</span>
<!--                {{formPrincipal.get('cDepenNombre').value}} / {{formPrincipal.get('cCargoNombre').value}}-->
            </p>
        </div>
        <button (click)="formPrincipal.reset(); value = null;" *ngIf="!readonly && !disabled" color="primary" mat-icon-button
                type="button" class="flex-grow-0 w-6 h-6">
            <mat-icon svgIcon="mat:delete" class="icon-xs"></mat-icon>
        </button>

    </div>
</ng-container>

<ng-container [formGroup]="formPrincipal">
    <div class="flex items-center justify-between" [class.hidden]="formPrincipal.get(this.idSeleccion).value">
        <input matInput type="text" formControlName="cCampoBusqueda" [placeholder]="shouldLabelFloat ? placeholder : ''"
               class="flex-1"
               [hidden]="formPrincipal.get(this.idSeleccion).value"
               (keydown.enter)="filtroResultados(); $event.preventDefault()"
               [ngStyle]="{ opacity: disabled ? 0.5 : 1 }" autocomplete="off" name="buscar"
               [ngClass]="{ 'form-input border-2 mt-1 w-full text-2xs font-thin bg-white text-black': sinContenedor}"
        >

        <button matRipple (click)="!clickInControl ? filtroResultados() : '';"
                class="boton-circular-gris"
                *ngIf="!formPrincipal.get(this.idSeleccion).value"
                [disabled]="disabled"
                type="button">
            <mat-icon svgIcon="mat:search" class="icon-xs"></mat-icon>
        </button>
    </div>

</ng-container>


<ng-template #dialogResultados>
    <div globalDialogFlotante btnCerrar [matDialogRefActual]="dialogRefSeleccion">
        <ng-container titulo>
            Resultados de Búsqueda
        </ng-container>
        <div cuerpo class="flex flex-col">

            <div class="flex flex-col sm:flex-row gap-2">
                <general-tabla-mantenimiento-nuevo
                    leyenda="Seleccionar Trabajador"
                    [objThis]="this"
                    nombreColeccion="trabajadores"
                    ctrlBusqueda="query"
                    [ctrlBusquedaValue]="formPrincipal.get('cCampoBusqueda').value"
                    botonesCRUD=""
                    [idTabla]="['iPrestadorId']"
                    [dataSuscription]="dataServidorSuscripcion['rhh.trabajadores']"
                    [columnasTabla]="columnasPrincipal"
                    [exportarExcel]="false"
                    (opcionSelecionada)="opcMenu($event)"

                    (opcBusqueda)="filtroResultados($event)"
                    [botonRecargar]="true"
                    (accionRecargar)="cargarLista();"
                >
                </general-tabla-mantenimiento-nuevo>
            </div>

        </div>
        <ng-container pieDerecha class="pb-2">
            <button matRipple type="submit"
                    class="boton-dialog-flotante text-blue-800 border-blue-800"
                    [disabled]="!seleccionados.trabajadores"
                    (click)="opcMenu({ item: seleccionados.trabajadores, seccion: 'trabajadores', tipo: 'seleccionar' })"
            >
                <mat-icon class="icon-xs" svgIcon="mat:fact_check"></mat-icon>
                <span>SELECCIONAR</span>
            </button>
        </ng-container>
    </div>

</ng-template>
