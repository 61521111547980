import {PdfLogisticaService} from '@pdf/pdf-logistica.service';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {numberToWords, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {zeroFill} from '@JVSoft/pipes/zero-fill.pipe';

import * as moment from 'moment';
import {formatDate} from '@angular/common';

export function pecosa(objThis: PdfLogisticaService, idsCompras) {
    objThis.queryService.cargar(objThis, ['log.salidas_detalles_presentacion'], {cCodigoCadena: idsCompras}).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.salidas_detalles_presentacion'];

        Object.keys(dataRecibido).forEach((idxCompra) => {
            const dataGeneral = dataRecibido[idxCompra][0];

            const celdasFooter = [];

            const localLocale = moment(dataGeneral.dSalidaEmision, 'YYYY-MM-DD');
            moment.locale('es');
            localLocale.locale(false);
            // alert(localLocale.format('LLLL'));

            celdasFooter.push(
                [
                    imprimirCelda('\n\n\n\n\n\n\n', null, {style: 'tdSmall'}),
                    imprimirCelda('', null, {style: 'tdSmall'}),
                    imprimirCelda('', null, {style: 'tdSmall'}),
                    imprimirCelda('', null, {style: 'tdSmall'})
                ],
                [
                    imprimirCelda('SOLICITANTE', null, {style: 'thSmall'}),
                    imprimirCelda('ABASTECIMIENTO', null, {style: 'thSmall'}),
                    imprimirCelda('ALMACEN', null, {style: 'thSmall'}),
                    imprimirCelda('RECIBI CONFORME\nNº DNI: _________________________', null, {style: 'thSmall'}),
                ]
            );
            console.log(dataGeneral);
            const mDeAgua = {};
            if (dataGeneral.bSalidaAnuladoRegistro == '1' || dataGeneral.bSalidaCerrado != '1') {
                mDeAgua['color'] = '#a80707';
                mDeAgua['text'] = (dataGeneral.bSalidaAnuladoRegistro == '1' ? 'ANULADO' : (dataGeneral.bSalidaCerrado == '1' ? undefined : 'BORRADOR'));
            }

            objThis.reiniciarOpciones({
                marcaDeAgua: mDeAgua,
                // marcaDeAgua: mDeAgua,
                margenesHoja: [40, 80, 40, 120],
                tamanioFuenteExtra: 1,
                orientacion: 'landscape',
                defaultSizeHeaderTable: [150, '*', 150],
                defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    // margin: [0, 10, 5, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                // imprimirCelda('PAG.', null, {fontSize: 7, alignment: 'center'}),
                                imprimirCelda(' Pag. ##pagina## de ##total_paginas##', null, {colSpan: 2, fontSize: 7, alignment: 'center'}),
                                {},
                            ],
                            [
                                imprimirCelda('NÚMERO', null, {fontSize: 8, alignment: 'center'}),
                                imprimirCelda('FECHA', null, {fontSize: 9, alignment: 'center'}),
                            ],
                            [
                                imprimirCelda(zeroFill(dataGeneral.iSalidaNumero, 4), null, {
                                    fontSize: 12, bold: true, alignment: 'center',
                                }),
                                imprimirCelda(localLocale, 'fec', {
                                    fontSize: 12,
                                    bold: true,
                                    alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable: {
                    margin: [0, 6, 0, 0],
                    alignment: 'center',
                    stack: [
                        imprimirCelda(dataGeneral.cTipoMovProdNombre, null, {fontSize: 14, bold: true}),
                        imprimirCelda(dataGeneral.cTipoMovProdSigla, null, {fontSize: 15, bold: true}),
                    ],
                },

                footerTablePrepend: {
                    margin: [70, 0, 70, 10],
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: celdasFooter,
                    },
                    layout: {
                    },
                },
                footerTableLeftStackPrepen: [
                    'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                ],
            });
            const cuerpoPdf = [];

            cuerpoPdf.push({
                columns: [
                    /*{
                        margin: [0, 0, 0, 10],
                        table: {
                            widths: ['auto', '*'],
                            headerRows: 0,
                            body: [
                                [
                                    imprimirCelda('ENTREGA', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersEntregaNombreLargo ?? '') + ' (' + (dataGeneral.cPersEntregaDocumento ?? '') + ')', null, {
                                        italics: true,
                                        border: [false, true, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('CARGO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoEntregaNombre ?? ''), null, {
                                        italics: true,
                                        border: [false, false, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('AGENCIA / OFICINA', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda(': ' + (dataGeneral.cEstablecimientoEntregaNombreLargo ?? '') + ' / ' + (dataGeneral.cDepenEntregaNombre ?? ''), null, {
                                        italics: true,
                                        border: [false, false, true, true],
                                    }),
                                ],
                            ],
                        },
                    },*/
                    {
                        margin: [0, 0, 0, 10],
                        table: {
                            widths: ['auto', '*'],
                            headerRows: 0,
                            body: [
                                [
                                    imprimirCelda('SOLICITANTE', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersNombreLargo ?? '') + ' (' + (dataGeneral.cPersDocumento ?? '') + ')', null, {
                                        italics: true, border: [false, true, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('CARGO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('ESTABLECIMIENTO / DEPENDENCIA', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cEstablecimientoNombreLargo ?? '') + ' / ' + (dataGeneral.cDepenNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, true],
                                    }),
                                ],
                                [
                                    imprimirCelda('', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda('[' + dataGeneral.cTareaCodigo + '] ' + dataGeneral.cTareaNombre, null, {
                                        italics: true, bold: true, alignment: 'right', border: [false, false, true, true],
                                    }),
                                ],
                                [
                                    imprimirCelda('SOLICITO ENTREGAR A', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersRecibeNombreLargo ?? '') + ' (' + (dataGeneral.cPersRecibeDocumento ?? '') + ')', null, {
                                        italics: true, border: [false, true, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('CARGO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('CON DESTINO A ', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda(': ' + (dataGeneral.cEstablecimientoRecibeNombreLargo ?? '') + ' / ' + (dataGeneral.cDepenRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, true],
                                    }),
                                ],
                                [
                                    imprimirCelda('PEDIDO(S)', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda(': ' + (dataGeneral.cPedidoNumero ?? '') , null, {
                                        italics: true, border: [false, true, true, true],
                                    }),
                                ],
                            ],
                        },
                    },
                ]
            });






            const tablaTitulos = [
                [
                    imprimirCelda('Nº', null, {
                        style: 'thSmall', rowSpan: 2, alignment: 'center',
                    }),
                    imprimirCelda('ARTICULOS SOLICITADOS', null, {
                        style: 'thSmall', colSpan: 11, alignment: 'center',
                    }),
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    imprimirCelda('ORDEN DE DESPACHO', null, {
                        style: 'thSmall', colSpan: 3, alignment: 'center',
                    }),
                    {},
                    {},
                ],
                [
                    {},
                    imprimirCelda('CÓDIGO', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('U/M', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('MARCA', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('MODELO', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('SERIE', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('COLOR', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('FECHA INTERNAMIENTO', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('REFERENCIA', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('PROCEDENCIA', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('COMPROBANTE', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('CANTIDAD', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('P.U.', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('TOTAL', null, {style: 'thSmall', alignment: 'center', }),
                ],
            ];


            const camposPrincipal: ColumnaTabla<any>[] = [
                {label: 'CÓDIGO', property: 'cCodigoProducto', type: 'text'},
                {label: 'DESCRIPCIÓN', property: 'cProductoNombreObs', type: 'text'},
                {label: 'U/M', property: 'cPresentacionNombre', type: 'text'},
                {label: 'MARCA.', property: 'cMovProdDetMarca', type: 'text'},
                {label: 'MODELO', property: 'cMovProdDetModelo', type: 'text'},
                {label: 'SERIE', property: 'cMovProdDetSerieNumero', type: 'text'},
                {label: 'COLOR', property: 'cMovProdDetColor', type: 'text'},
                {label: 'FECHA INTERNAMIENTO', property: 'dMovProdInternamiento', type: 'date'},
                {label: 'REFERENCIA', property: 'cMovProdReferencia', type: 'text'},
                {label: 'PROCEDENCIA', property: 'cMovProdProcedencia', type: 'text',},
                {label: 'COMPROBANTE', property: 'cMovProdDocSerieNumero', type: 'text',},
                {label: 'CANTIDAD', property: 'nSalidaDetCantidad', type: 'number'},
                {label: 'P. UNIT', property: 'nMovProdDetPrecioUnitario', type: 'money'},
                {label: 'SUB TOTAL', property: 'nSalidaDetSubTotal', type: 'money'},
            ];

            const sumasTotales = sumarObjetos(dataRecibido[idxCompra], ['nMovProdDetPrecioUnitario', 'nSalidaDetSubTotal']);
            const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: dataRecibido[idxCompra],
                numeracion: true,
                idxResto: [1],
                margin: [0, 5, 0, 5],
                separado: true,
            });
            // cuerpoPdf.push(tablaGeneradaSoloTitulos);

            cuerpoPdf.push(
                {
                    margin: [0, 0, 0, 10],
                    table: {
                        dontBreakRows: true,
                        headerRows: 2,
                        // widths: objThis.anchoCols(tablaGeneradaSoloTitulos['cuerpo'], [2]),
                        widths: objThis.anchoCols(tablaTitulos, [2]),
                        body: tablaTitulos.concat(tablaGeneradaSoloTitulos['cuerpo']),
                    },
                },
                imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true})
            );

            cuerpoPdf.push({
                columns: [
                    {width: '*', text: ''},
                    {
                        width: 'auto',
                        table: {
                            body: [
                                [
                                    imprimirCelda('FORMULARIO UTILIZADO HASTA EL REGLÓN \n' + 'Nº (' + zeroFill(tablaGeneradaSoloTitulos['cuerpo'].length ?? 0, 2) + ') ' + numberToWords(tablaGeneradaSoloTitulos['cuerpo'].length ?? 0).toUpperCase() + ' INCLUSIVE', null, {
                                        bold: true,
                                        style: 'tdSmall',
                                        fontSize: 9,
                                    })
                                ],
                            ],
                        },
                    },
                    {width: '*', text: ''},
                    {
                        width: 'auto',
                        table: {
                            body: [
                                [
                                    imprimirCelda('TOTAL GENERAL:', null, {
                                        bold: true,
                                        style: 'thSmall',
                                        alignment: 'right',
                                        fontSize: 9,
                                    }),
                                    imprimirCelda(sumasTotales['nSalidaDetSubTotal'], 'money', {
                                        bold: true,
                                        style: 'tdSmall',
                                        fontSize: 9,
                                    })
                                ]
                            ],
                        },
                    },
                    {width: '*', text: ''},
                ],
            });

            if (dataGeneral.cSalidaObs) {
                cuerpoPdf.push(imprimirCelda('Observacion:\n ' + dataGeneral.cSalidaObs ?? '', null, {italics: true, margin: [30, 0, 0, 10]}));
            }



            objThis.finalizarCreacionPdf(cuerpoPdf);
        });
    });
}

export function compra(objThis: PdfLogisticaService, idsCompras: string) {
    objThis.queryService.cargar(objThis, ['log.compras_detalles_presentacion'], {cCodigoCadena: idsCompras})
        .then((valRetorno: object) => {
            const dataRecibido: any[] = valRetorno['log.compras_detalles_presentacion'];
            const cuerpoPdf = [];
            Object.keys(dataRecibido).forEach((idxCompra, indiceCompra) => {
                const dataGeneral = dataRecibido[idxCompra][0];
                const dataTotales = dataGeneral['totales'];
                const dataCuentasProveedor = dataGeneral['cuentasProveedor'];
                const dataDocumentos = dataGeneral['documentos'];
                const esOrdenCompra = dataGeneral.iTipoDocId == 38;


                const dataExtraFinal = [];
                const dataExtraFinalObs = [];
                if (dataGeneral.cCompraObs) {
                    dataExtraFinal.push([{text: '\nOBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true}]);
                    dataExtraFinalObs.push([{text: '\nOBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true}]);
                }
                const docARchivo = [];
                dataDocumentos.filter((documento) => {
                    if (docARchivo.length == 0) {
                        dataExtraFinal.push({text: 'DOCUMENTOS: ', bold: true});
                        // docARchivo.push({text: 'DOCUMENTOS: ', bold: true});
                    }
                    const textFinal = [
                        {text: documento['cTipoDocNombre']},
                        {
                            text: ' ' + (documento['cCompraDocSerie'] ? (documento['cCompraDocSerie'] + ' - ') : '') + (documento['cCompraDocNumero'] ?? ''),
                            color: '#06024f',
                            bold: true,
                        },
                        {text: ', EMISIÓN: ' + formatDate(documento['dCompraDocEmision'], 'dd/MM/yyyy', 'es-PE')},
                    ];
                    if (documento['dCompraDocVencimiento']) {
                        textFinal.push({text: ', VENCIMIENTO: ' + formatDate(documento['dCompraDocVencimiento'], 'dd/MM/yyyy', 'es-PE')});
                    }
                    if (documento['cCompraDocObs']) {
                        textFinal.push({text: ', OBS: ' + (documento['cCompraDocObs'] ?? '')});
                    }
                    // docARchivo.push({text: textFinal}, {text: '     '});
                    docARchivo.push({text: textFinal});

                    dataExtraFinal.push({
                        text: textFinal,
                    });
                });

                const dataTelefono: string[] = [];
                if (dataGeneral.cPersProveedorConTelefonoMovil) {
                    dataTelefono.push(dataGeneral.cPersProveedorConTelefonoMovil);
                }
                if (dataGeneral.cPersProveedorConTelefonoFijo) {
                    dataTelefono.push(dataGeneral.cPersProveedorConTelefonoFijo);
                }

                const strCtasProveedorArray = [];
                console.log(dataCuentasProveedor);
                dataCuentasProveedor.filter((cuenta) => {
                    if (dataGeneral.iTipoMonedaId == cuenta.iTipoMonedaId) {
                        strCtasProveedorArray.push(
                            {text: cuenta['cBancoNombre'] + ' - ' + cuenta['cTipoCuentaNombre'] + ' - '},
                            {text: ' ' + cuenta['cPersCuentaNumero'], color: '#06024f', bold: true},
                            {text: ' (' + cuenta['cTipoMonedaNombre'] + ')\n'}
                        );
                    }
                });
                if (strCtasProveedorArray.length > 0 ) {
                    strCtasProveedorArray.unshift({text: 'TRANSFERIR A:\n', bold: true});
                }

                const dataGeneralTabla = [
                    [
                        {
                            border: [true, true, false, true],
                            stack: [imprimirCelda('EMISION', null, {bold: true, noWrap: true})],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [imprimirCelda('DOCUMENTO', null, {bold: true, noWrap: true})],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [imprimirCelda('RAZÓN SOCIAL / NOMBRE', null, {bold: true, noWrap: true})],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [imprimirCelda('TELÉFONO', null, {bold: true, noWrap: true})],
                        },
                        {
                            border: [false, true, true, true],
                            stack: [imprimirCelda('DIRECCIÓN', null, {bold: true, noWrap: true})],
                        },
                    ],
                    [
                        {
                            border: [true, true, false, true],
                            stack: [
                                imprimirCelda(dataGeneral.dCompraEmision, 'fec', {
                                    italics: true,
                                    alignment: 'left',
                                }),
                            ],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [
                                imprimirCelda(dataGeneral.cTipoIdentProveedorSigla + ' ' + dataGeneral.cPersProveedorDocumento, null, {
                                    italics: true,
                                }),
                            ],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [
                                imprimirCelda(dataGeneral.cPersProveedorNombreLargo, null, {
                                    bold: true,
                                    fontSize: 9,
                                    // maxHeight: 20,
                                }),
                            ],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [
                                imprimirCelda(dataTelefono.join(' / '), null, {
                                    italics: true,
                                }),
                            ],
                        },
                        {
                            border: [false, true, true, true],
                            stack: [
                                imprimirCelda(dataGeneral.cPersProveedorDomicilio, null, {
                                    italics: true,
                                    maxHeight: 20,
                                }),
                            ],
                        },
                    ],
                    [
                        {
                            border: [true, true, false, true],
                            stack: [imprimirCelda('PLAZO ENTREGA', null, {bold: true, noWrap: true})],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [
                                imprimirCelda(esOrdenCompra ? 'ESTABLECIMIENTO' : '', null, {
                                    bold: true,
                                    noWrap: true,
                                }),
                            ],
                        },
                        {
                            border: [false, true, false, true],
                            colSpan: 2,
                            stack: [imprimirCelda(esOrdenCompra ? 'ALMACEN' : '', null, {bold: true, noWrap: true})],
                        },
                        {},
                        {
                            border: [false, true, true, true],
                            stack: [imprimirCelda('FORMA DE PAGO', null, {bold: true, noWrap: true})],
                        },
                    ],
                    [
                        {
                            border: [true, true, false, true],
                            stack: [
                                // imprimirCelda(dataGeneral.nCompraPlazoEntrega, 'cant', {fontSize: 7, italics: true}),
                                imprimirCelda(dataGeneral.nCompraPlazoEntrega * 1 + ' días', null, {
                                    italics: true,
                                }),
                            ],
                        },
                        {
                            border: [false, true, false, true],
                            stack: [
                                imprimirCelda(esOrdenCompra ? dataGeneral.cEstablecimientoNombreLargo : '', null, {
                                    italics: true,
                                }),
                            ],
                        },
                        {
                            border: [false, true, false, true],
                            colSpan: 2,
                            stack: [
                                imprimirCelda(esOrdenCompra ? dataGeneral.cAlmacenNombre : '', null, {
                                    italics: true,
                                }),
                            ],
                        },
                        {},
                        {
                            border: [false, true, true, true],
                            stack: [imprimirCelda(dataGeneral.cFormaPagoNombre, null, {fontSize: 7, italics: true})],
                        },
                    ],
                    [
                        {
                            // border: [true, true, false, false],
                            colSpan: 5,
                            text: [
                                imprimirCelda('REFERENCIA: ', null, {bold: true, noWrap: true}),
                                imprimirCelda(dataGeneral.cCompraReferencia, null, {bold: true, italics: false, fontSize: 9})
                            ],
                        },
                        {},
                        {},
                        {},
                        {},
                    ],
                    /*                [
                                        {
                                            // border: [true, true, false, false],
                                            colSpan: 5,
                                            stack: [imprimirCelda(dataGeneral.cCompraReferencia, null, {bold: true, italics: false, fontSize: 9})],
                                        },
                                        {},
                                        {},
                                        {},
                                        {},
                                    ],*/
                    [
                        {
                            border: [true, true, false, true],
                            colSpan: 2,
                            stack: [imprimirCelda('DOCUMENTOS', null, {bold: true, noWrap: true})],
                        },
                        {},
                        {
                            border: [false, true, true, true],
                            colSpan: 3,
                            // stack: [imprimirCelda('DOCUMENTOS', null, {bold: true, noWrap: true})],
                            stack: [imprimirCelda('[' + dataGeneral.cTareaCodigo + '] ' + dataGeneral.cTareaNombre, null, {fontSize: 7, alignment: 'right', bold: true, italics: true})],
                        },
                        {},
                        {},
                    ],
                    [
                        {
                            // border: [true, true, false, false],
                            colSpan: 5,
                            stack: docARchivo,
                        },
                        {},
                        {},
                        {},
                        {},
                    ]
                ];

                const tablaGeneral = {
                    margin: [-5, 0, -5, 5],
                    table: {
                        // widths: [130, '*'],
                        widths: objThis.anchoCols(dataGeneralTabla, [4] /*, [{idx: 0, valor: 'auto'}, {idx: 1, valor: 110}, ]*/),
                        body: dataGeneralTabla,
                    }
                };




                const mDeAgua = {};
                if (dataGeneral.bCompraAnuladoRegistro == '1' || dataGeneral.bCompraCerrado != '1') {
                    mDeAgua['color'] = '#a80707';
                    mDeAgua['text'] = (dataGeneral.bCompraAnuladoRegistro == '1' ? 'ANULADO' : (dataGeneral.bCompraCerrado == '1' ? undefined : 'BORRADOR'));
                }
                const numerosDocumentos = [];
                const fnDocNum = (concepto, numero) => {
                    return [imprimirCelda(concepto, null, {fontSize: 7, bold: true /*, alignment: 'center'*/}), imprimirCelda(numero, null, {fontSize: 8, alignment: 'center'})];
                };
                numerosDocumentos.push(fnDocNum('PÁGINA', '##pagina##/##total_paginas##'));
                if (dataGeneral.cPedidoNumero) {
                    numerosDocumentos.push(fnDocNum('Nº PEDIDO', dataGeneral.cPedidoNumero));
                }
                if (dataGeneral.cCotizaNumero) {
                    numerosDocumentos.push(fnDocNum('Nº COTIZACIÓN', dataGeneral.cCotizaNumero));
                }
                if (dataGeneral.cCuaComNumero) {
                    numerosDocumentos.push(fnDocNum('Nº CUA. COM.', dataGeneral.cCuaComNumero));
                }

                const posInicioFooter = 355 - (dataCuentasProveedor.length * 8);
                const anchoColumnaTotal = 40;


                const aDat = [];
                dataTotales.filter((resultado, idxRes) => {
                    aDat.push([
                        {
                            columns: [
                                {width: '*', text: resultado.cResultadoNombre},
                                {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                            ],
                            style: 'tdSmall',
                            noWrap: true,
                            bold: (idxRes == dataTotales.length - 1),
                        },
                        imprimirCelda(resultado.nResultadoImporte, 'mon', {
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                            bold: (idxRes == dataTotales.length - 1),
                        }),
                    ]);
                });

                objThis.reiniciarOpciones({
                    marcaDeAgua: mDeAgua,
                    margenesHoja: [20, 90, 20, 300],
                    // margenesHoja: [20, 90, 20, 40],
                    // margenesHoja: [20, 188 + (docARchivo.length * 10), 20, 280],
                    tamanioFuenteExtra: 3,
                    customDefTableHeader: [
                        {
                            margin: [5, 20, 5, 0],
                            table: {
                                widths: [150, '*', 150],
                                headerRows: 0,
                                body: [
                                    [
                                        objThis.logotipoHeader(dataGeneral),
                                        {
                                            margin: [0, 0, 0, 0],
                                            alignment: 'center',
                                            stack: [
                                                imprimirCelda(dataGeneral.cTipoDocNombre + '\nNº ' + dataGeneral.iCompraNumero + ' - ' + dataGeneral.iCompraYear, null, {
                                                    fontSize: 20,
                                                    font: 'TimesNewRoman',
                                                    bold: true,
                                                }),
                                            ],
                                        },
                                        {
                                            margin: [30, 0, 10, 0],
                                            table: {
                                                widths: ['auto', '*'],
                                                body: numerosDocumentos,
                                            },
                                        },
                                    ],
                                ],
                            },
                            layout: {
                                defaultBorder: false,
                            },
                        },
                        // {
                        //     margin: [20, 0, 20, 0],
                        //     table: {
                        //         // widths: [130, '*'],
                        //         widths: objThis.anchoCols(dataGeneralTabla, [4] /*, [{idx: 0, valor: 'auto'}, {idx: 1, valor: 110}, ]*/),
                        //         body: dataGeneralTabla,
                        //     },
                        // },
                    ],
                    footerTablePrepend: {
                        margin: [20, 0, 20, 0],
                        table: {
                            // widths: Array(camposPrincipal.length - 1).fill('*').concat([35]),
                            widths: ['*', '*', '*', 80, anchoColumnaTotal],
                            body: [
                                [
                                    imprimirCelda(strCtasProveedorArray, null, {
                                        style: 'tdSmall',
                                        colSpan: 3,
                                        border: [true, false, false, false],
                                    }),
                                    {},
                                    {},
                                    imprimirCelda('', null, {style: 'tdSmall', border: [false, false, false, false]}),
                                    imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                                ],
                                [
                                    imprimirCelda('JEFE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {style: 'thSmall'}),
                                    imprimirCelda('ASISTENTE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {style: 'thSmall'}),
                                    imprimirCelda('SUBGERENTE ADMINISTRATIVO', null, {style: 'thSmall'}),
                                    {
                                        colSpan: 2, rowSpan: 2,
                                        margin: [-5, -3, -5, 0],
                                        table: {
                                            widths: ['*', anchoColumnaTotal],
                                            body: aDat,
                                        },
                                        style: 'tdSmall',
                                        relativePosition: {x: 0, y: 0 },
                                        border: [true, true, false, false],
                                    },
                                    {},
                                ],
                                [
                                    imprimirCelda('\n\n\n\n\n\nFirma y Sello', null, {
                                        style: 'tdSmall',
                                        alignment: 'center',
                                        border: [true, false, true, true],
                                    }),
                                    imprimirCelda('\n\n\n\n\n\nFirma y Sello', null, {
                                        style: 'tdSmall',
                                        alignment: 'center',
                                        border: [true, false, true, true],
                                    }),
                                    imprimirCelda('\n\n\n\n\n\nFirma y Sello', null, {
                                        style: 'tdSmall',
                                        alignment: 'center',
                                        border: [true, false, true, true],
                                    }),
                                    {},
                                    {},
                                ],
                                [
                                    {
                                        style: 'tdSmall',
                                        colSpan: 5,
                                        border: [false, false, false, false],
                                        margin: [-5, 5, -5, 0],
                                        table: {
                                            widths: ['*'],
                                            body: [
                                                [{
                                                    text: [
                                                        {text: 'SON: ', bold: true},
                                                        {
                                                            text: dataGeneral.cCompraValorItemTotal_Letra + dataGeneral.cTipoMonedaNombre,
                                                            italics: true,
                                                        },
                                                    ],
                                                }]
                                            ],
                                        },
                                    },
                                    {},
                                    {},
                                    {},
                                    {},
                                ],
                                [
                                    {
                                        style: 'tdSmall',
                                        colSpan: 5,
                                        border: [false, false, false, false],
                                        margin: [-5, 2, -5, 0],
                                        table: {
                                            widths: [380, 1, '*'],
                                            body: [
                                                [
                                                    {
                                                        stack: [
                                                            imprimirCelda('FACTURAR A NOMBRE DE:', null, {bold: true}),
                                                            imprimirCelda(dataGeneral.cEntNombreCorto ? dataGeneral.cEntNombreCorto : dataGeneral.cEntNombreLargo),
                                                            {
                                                                columns: [
                                                                    {
                                                                        width: 50,
                                                                        stack: [
                                                                            imprimirCelda('RUC: ', null, {bold: true}),
                                                                            imprimirCelda('DIRECCIÓN: ', null, {bold: true}),
                                                                            imprimirCelda('TELÉFONO: ', null, {bold: true}),
                                                                        ],
                                                                    },
                                                                    {
                                                                        width: '*',
                                                                        stack: [
                                                                            imprimirCelda(dataGeneral.cEntRuc),
                                                                            imprimirCelda(dataGeneral.cEntDomicilioCorto ? dataGeneral.cEntDomicilioCorto : dataGeneral.cEntDomicilioLargo),
                                                                            imprimirCelda(dataGeneral.cEntTelefono),
                                                                        ],
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                    imprimirCelda('', null, {border: [true, false, true, false]}),
                                                    imprimirCelda('Recibido:' + '\n\n\n\n\n\n\n\n\n\n\n' + 'Fecha:', null, {bold: true}),
                                                ],
                                            ],
                                        },
                                    },
                                    {},
                                    {},
                                    {},
                                    {},
                                ],
                            ],
                        },
                        // absolutePosition: {x: 20, y: posInicioFooter + 200 - 20},
                    },
                    footerTableLeftStackPrepen: [
                        'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                    ],
                    // pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                    //     console.log('NODO', currentNode);
                    //     if (currentNode.startPosition && currentNode.startPosition.pageNumber == 1 && currentNode.startPosition.top > 500) {
                    //         console.log('SALTO DE PAGINA');
                    //         return true;
                    //     }
                    //     // console.log(currentNode);
                    //     return false;
                    // }
                });






                const camposComunesInicio: ColumnaTabla<any>[] = [
                    {label: 'CÓDIGO', property: 'cCodigoProducto', type: 'text'},
                    {label: 'DESCRIPCIÓN', property: 'cProductoNombreObs', type: 'text'},
                    {label: 'U/M', property: 'cPresentacionNombre', type: 'text'},
                ];
                const camposComunesFin: ColumnaTabla<any>[] = [
                    {label: 'TOTAL', property: 'nCompraDetValorItemNeto', type: 'number', format: '0.2-2'},
                ];

                let camposPrincipal: ColumnaTabla<any>[] = camposComunesInicio.concat(camposComunesFin);
                if (esOrdenCompra) {
                    let campoUnitario: ColumnaTabla<any>[] = [
                        // {label: 'CANT.', property: 'nCompraDetCantidad', type: 'number', format: '0.2-2'},
                        {label: 'PRECIO', property: 'nCompraDetPrecioUnitario', type: 'number', format: '0.2-2'},
                    ];
                    if (dataGeneral.bCompraDetTributoIncluidoPrecioUnitario == 1) {
                        campoUnitario = [
                            // {label: 'CANT.', property: 'nCompraDetCantidad', type: 'number', format: '0.2-2'},
                            {label: 'V. UNIT.', property: 'nCompraDetValorUnitarioBruto', type: 'number', format: '0.2-2'},
                            {label: 'PRECIO', property: 'nCompraDetPrecioUnitario', type: 'number', format: '0.2-2'},
                        ];
                    }
                    camposPrincipal = camposComunesInicio.concat([
                        {label: 'MARCA.', property: 'cCompraDetMarca', type: 'text'},
                        // {label: 'MODELO', property: 'cCompraDetModelo', type: 'text'},
                        // {label: 'COLOR', property: 'cCompraDetColor', type: 'text'},
                        {label: 'CANT.', property: 'nCompraDetCantidad', type: 'number', format: '0.2-2'},
                    ]).concat(campoUnitario).concat(camposComunesFin);
                }
                else {
                    camposPrincipal = camposComunesInicio.concat([
                        {label: 'CANT.', property: 'nCompraDetCantidad', type: 'number', format: '0.2-2'},
                        {label: 'PRECIO', property: 'nCompraDetValorItemNeto', type: 'number', format: '0.2-2'},
                    ]).concat(camposComunesFin);
                }
                console.warn(generarColSpan({...tablaGeneral, ...{
                        // colSpan: 11,
                        border: [false, false, false, true],
                    }}, camposPrincipal.length + 1));

                console.warn(Array(camposPrincipal.length).fill({}));
                const tablaTitulos = [
                    // [
                    //     {...tablaGeneral, ...{
                    //             colSpan: 11,
                    //             border: [false, false, false, true],
                    //         }},
                    //     {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
                    // ],
                    generarColSpan({...tablaGeneral, ...{
                            // colSpan: 11,
                            border: [false, false, false, true],
                        }}, camposPrincipal.length + 1)
                ];
                console.warn(tablaTitulos);
                /*const camposPrincipal: ColumnaTabla<any>[] = [
                    {label: 'CÓDIGO', property: 'cCodigoProducto', type: 'text'},
                    {label: 'DESCRIPCIÓN', property: 'cProductoNombreObs', type: 'text'},
                    {label: 'U/M', property: 'cPresentacionNombre', type: 'text'},
                    {label: 'MARCA.', property: 'cCompraDetMarca', type: 'text'},
                    {label: 'MODELO', property: 'cCompraDetModelo', type: 'text'},
                    // {label: 'SERIE', property: 'cMovProdDetSerieNumero', type: 'text'},
                    {label: 'COLOR', property: 'cCompraDetColor', type: 'text'},

                    {label: 'CANT.', property: 'nCompraDetCantidad', type: 'number', format: '0.2-2'},
                    {label: 'V. UNIT.', property: 'nCompraDetValorUnitarioBruto', type: 'number', format: '0.2-2'},
                    // {label: 'PRECIO', property: 'nCompraDetPrecioUnitario', type: 'money'},
                    {label: 'PRECIO', property: 'nCompraDetValorItemNeto', type: 'number', format: '0.2-2'},
                    {label: 'TOTAL', property: 'nCompraDetValorItemNeto', type: 'number', format: '0.2-2'},
                ];*/



                const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
                    titulos: camposPrincipal,
                    contenido: dataRecibido[idxCompra],
                    numeracion: true,
                    resaltarNumeracion: false,
                    idxResto: [1],
                    margin: [0, 5, 0, 5],
                    separado: true,
                });
                // cuerpoPdf.push(tablaGeneradaSoloTitulos);

                console.log(tablaGeneradaSoloTitulos['titulos']);
                tablaGeneradaSoloTitulos['titulos'][0][0] = {
                    style: 'thSmall',
                    stack: [
                        {text: 'Nº'},
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0.5,
                                    y1: 0,
                                    x2: 0.5,
                                    y2: posInicioFooter,
                                },
                            ],
                            relativePosition: {x: -5, y: 0},
                            alignment: 'left',
                        },
                    ],
                };
                tablaGeneradaSoloTitulos['titulos'][0][camposPrincipal.length] = {
                    style: 'thSmall',
                    stack: [
                        {text: 'TOTAL'},
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0.5,
                                    y1: 0,
                                    x2: 0.5,
                                    y2: posInicioFooter,
                                },
                            ],
                            relativePosition: {x: -5, y: 0},
                            alignment: 'left',
                        },
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: posInicioFooter,
                                },
                            ],
                            relativePosition: {x: 5, y: 0},
                            alignment: 'right',
                        },
                    ],
                };

                const parteAnulado = [];
                if (dataGeneral.cCompraAnuladoMotivo) {
                    parteAnulado.push(
                        generarColSpan( {
                            text: [
                                {text: 'MOTIVO DE ANULACIÓN: ', bold: true},
                                {text: dataGeneral.cCompraAnuladoMotivo, italics: true},
                            ],
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            color: '#680101',
                        }, camposPrincipal.length, [imprimirCelda('', null, {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                        })]),
                    );
                }

                cuerpoPdf.push(
                    {
                        // margin: [0, 0, -2, 10],
                        table: {
                            // dontBreakRows: true,
                            headerRows: 2,
                            // widths: objThis.anchoCols(tablaGeneradaSoloTitulos['cuerpo'], [2]),
                            widths: objThis.anchoCols(tablaGeneradaSoloTitulos['titulos'], [2], [{idx: 0, valor: 10}, {idx: camposPrincipal.length , valor: anchoColumnaTotal}]),
                            body: tablaTitulos.concat(tablaGeneradaSoloTitulos['titulos']).concat(tablaGeneradaSoloTitulos['cuerpo'].filter((fila, idx) => {
                                if (idx > 0 && (idx % 20) == 0) {
                                    console.log('ES MULTIPLO');
                                    console.log(fila);
                                    // fila[0]['pageBreak'] = 'after';
                                    //pageBreak: 'before',
                                }
                                return true;
                            })).concat([

                                generarColSpan( {
                                    columns: [
                                        {stack: dataExtraFinalObs, alignment: 'justify'},
                                        // {}
                                    ],
                                    border: [false, false, false, false],
                                    style: 'tdSmall',
                                    color: '#001d47',
                                }, camposPrincipal.length, [imprimirCelda('', null, {
                                    style: 'tdSmall',
                                    border: [false, false, false, false],
                                })]),
                            ]).concat(parteAnulado),
                        },
                        layout: {
                            /*
                            hLineWidth: (i, node) => {
                                console.log('NODOTABLA', node);
                                return (i === 4 || i === node.table.body.length) ? 2 : 1;
                            },


                            paddingBottom: (rowIndex: number, node: any, colIndex: number) => {
                                const DEFAULT_PADDING = 2;

                                // Calculate padding for the last element of the table.
                                if (rowIndex === node.table.body.length - 1) {
                                    const currentPosition = node.positions[node.positions.length - 1];
                                    const totalPageHeight = currentPosition.pageInnerHeight;
                                    const currentHeight = currentPosition.top;
                                    const paddingBottom = totalPageHeight - currentHeight;
                                    return paddingBottom;
                                } else {
                                    return DEFAULT_PADDING;
                                }
                            },

                            fillColor: (rowIndex: number, node: any, colIndex: number) => {
                                const DEFAULT_PADDING = 2;

                                // Calculate padding for the last element of the table.
                                if (rowIndex === node.table.body.length - 1) {
                                    return '#f6e57d';
                                } else {
                                    return null;
                                }
                            }
                            */


                            // vLineWidth: function (i, node) {
                            //     return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                            // },
                            // hLineColor: function (i, node) {
                            //     return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            // },
                            // vLineColor: function (i, node) {
                            //     return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            // },
                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            // paddingLeft: function(i, node) { return 4; },
                            // paddingRight: function(i, node) { return 4; },
                            // paddingTop: function(i, node) { return 2; },
                            // paddingBottom: function(i, node) { return 2; },
                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                        }
                    },
                    // imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true})
                );


/*                cuerpoPdf.push({
                    // margin: [-5, 0, -50, 5],
                    table: {
                        // widths: Array(camposPrincipal.length - 1).fill('*').concat([35]),
                        widths: ['*', '*', '*', 80, anchoColumnaTotal],
                        body: [
                            [
                                imprimirCelda(strCtasProveedorArray, null, {
                                    style: 'tdSmall',
                                    colSpan: 3,
                                    border: [true, false, false, false],
                                }),
                                {},
                                {},
                                imprimirCelda('', null, {style: 'tdSmall', border: [false, false, false, false]}),
                                imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            ],
                            [
                                imprimirCelda('JEFE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {style: 'thSmall'}),
                                imprimirCelda('ASISTENTE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {style: 'thSmall'}),
                                imprimirCelda('SUBGERENTE ADMINISTRATIVO', null, {style: 'thSmall'}),
                                {
                                    colSpan: 2, rowSpan: 2,
                                    margin: [-5, -3, -5, 0],
                                    table: {
                                        dontBreakRows: true,
                                        headerRows: 2,
                                        widths: ['*', anchoColumnaTotal],
                                        body: aDat,
                                    },
                                    style: 'tdSmall',
                                    border: [true, true, false, false],
                                },
                                {},
                            ],
                            [
                                imprimirCelda('\n\n\n\n\n\nFirma y Sello', null, {
                                    style: 'tdSmall',
                                    alignment: 'center',
                                    border: [true, false, true, true],
                                }),
                                imprimirCelda('\n\n\n\n\n\nFirma y Sello', null, {
                                    style: 'tdSmall',
                                    alignment: 'center',
                                    border: [true, false, true, true],
                                }),
                                imprimirCelda('\n\n\n\n\n\nFirma y Sello', null, {
                                    style: 'tdSmall',
                                    alignment: 'center',
                                    border: [true, false, true, true],
                                }),
                                {},
                                {},
                            ],
                            [
                                {
                                    style: 'tdSmall',
                                    colSpan: 5,
                                    border: [false, false, false, false],
                                    margin: [-5, 5, -5, 0],
                                    table: {
                                        widths: ['*'],
                                        body: [
                                            [{
                                                text: [
                                                    {text: 'SON: ', bold: true},
                                                    {
                                                        text: dataGeneral.cCompraValorItemTotal_Letra + dataGeneral.cTipoMonedaNombre,
                                                        italics: true,
                                                    },
                                                ],
                                            }]
                                        ],
                                    },
                                },
                                {},
                                {},
                                {},
                                {},
                            ],
                            [
                                {
                                    style: 'tdSmall',
                                    colSpan: 5,
                                    border: [false, false, false, false],
                                    margin: [-5, 2, -5, 0],
                                    table: {
                                        widths: [380, 1, '*'],
                                        body: [
                                            [
                                                {
                                                    stack: [
                                                        imprimirCelda('FACTURAR A NOMBRE DE:', null, {bold: true}),
                                                        imprimirCelda(dataGeneral.cEntNombreCorto ? dataGeneral.cEntNombreCorto : dataGeneral.cEntNombreLargo),
                                                        {
                                                            columns: [
                                                                {
                                                                    width: 50,
                                                                    stack: [
                                                                        imprimirCelda('RUC: ', null, {bold: true}),
                                                                        imprimirCelda('DIRECCIÓN: ', null, {bold: true}),
                                                                        imprimirCelda('TELÉFONO: ', null, {bold: true}),
                                                                    ],
                                                                },
                                                                {
                                                                    width: '*',
                                                                    stack: [
                                                                        imprimirCelda(dataGeneral.cEntRuc),
                                                                        imprimirCelda(dataGeneral.cEntDomicilioCorto ? dataGeneral.cEntDomicilioCorto : dataGeneral.cEntDomicilioLargo),
                                                                        imprimirCelda(dataGeneral.cEntTelefono),
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                                imprimirCelda('', null, {border: [true, false, true, false]}),
                                                imprimirCelda('Recibido:' + '\n\n\n\n\n\n\n\n\n\n\n' + 'Fecha:', null, {bold: true}),
                                            ],
                                        ],
                                    },
                                },
                                {},
                                {},
                                {},
                                {},
                            ],
                        ],
                    },
                    absolutePosition: {x: 20, y: posInicioFooter + 200 - 20},
                })*/;









            });

            objThis.finalizarCreacionPdf(cuerpoPdf);
        });
}

export function compra2(objThis: PdfLogisticaService, idsCompras: string) {
    objThis.queryService.cargar(objThis, ['log.compras_detalles_presentacion'], {cCodigoCadena: idsCompras}).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.compras_detalles_presentacion'];
        let cuerpoPdf = [];
        Object.keys(dataRecibido).forEach((idxCompra, indiceCompra) => {

            const dataGeneral = dataRecibido[idxCompra][0];
            const dataTotales = dataRecibido[idxCompra][0]['totales'];
            const dataCuentasProveedor = dataRecibido[idxCompra][0]['cuentasProveedor'];
            const dataDocumentos = dataRecibido[idxCompra][0]['documentos'];
            const esOrdenCompra = dataGeneral.iTipoDocId == 38;



            const dataExtraFinal = [];
            const dataExtraFinalObs = [];
            if (dataGeneral.cCompraObs) {
                dataExtraFinal.push([{text: '\nOBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true}]);
                dataExtraFinalObs.push([{text: '\nOBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true}]);
            }
            const docARchivo = [];
            dataDocumentos.filter((documento) => {
                if (docARchivo.length == 0) {
                    dataExtraFinal.push({text: 'DOCUMENTOS: ', bold: true});
                    // docARchivo.push({text: 'DOCUMENTOS: ', bold: true});
                }
                const textFinal = [
                    {text: documento['cTipoDocNombre']},
                    {
                        text: ' ' + (documento['cCompraDocSerie'] ? (documento['cCompraDocSerie'] + ' - ') : '') + (documento['cCompraDocNumero'] ?? ''),
                        color: '#06024f',
                        bold: true,
                    },
                    {text: ', EMISIÓN: ' + formatDate(documento['dCompraDocEmision'], 'dd/MM/yyyy', 'es-PE')},
                ];
                if (documento['dCompraDocVencimiento']) {
                    textFinal.push({text: ', VENCIMIENTO: ' + formatDate(documento['dCompraDocVencimiento'], 'dd/MM/yyyy', 'es-PE')});
                }
                if (documento['cCompraDocObs']) {
                    textFinal.push({text: ', OBS: ' + (documento['cCompraDocObs'] ?? '')});
                }
                // docARchivo.push({text: textFinal}, {text: '     '});
                docARchivo.push({text: textFinal});

                dataExtraFinal.push({
                    text: textFinal,
                });
            });

            console.log(docARchivo);
            const dataTelefono: string[] = [];
            if (dataGeneral.cPersProveedorConTelefonoMovil) {
                dataTelefono.push(dataGeneral.cPersProveedorConTelefonoMovil);
            }
            if (dataGeneral.cPersProveedorConTelefonoFijo) {
                dataTelefono.push(dataGeneral.cPersProveedorConTelefonoFijo);
            }

            const dataGeneralTabla = [
                [
                    {
                        border: [true, true, false, true],
                        stack: [imprimirCelda('EMISION', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [imprimirCelda('DOCUMENTO', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [imprimirCelda('RAZÓN SOCIAL / NOMBRE', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [imprimirCelda('TELÉFONO', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, true, true],
                        stack: [imprimirCelda('DIRECCIÓN', null, {bold: true, noWrap: true})],
                    },
                ],
                [
                    {
                        border: [true, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.dCompraEmision, 'fec', {
                                italics: true,
                                alignment: 'left',
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.cTipoIdentProveedorSigla + ' ' + dataGeneral.cPersProveedorDocumento, null, {
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.cPersProveedorNombreLargo, null, {
                                bold: true,
                                fontSize: 9,
                                // maxHeight: 20,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(dataTelefono.join(' / '), null, {
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, true, true],
                        stack: [
                            imprimirCelda(dataGeneral.cPersProveedorDomicilio, null, {
                                italics: true,
                                maxHeight: 20,
                            }),
                        ],
                    },
                ],
                [
                    {
                        border: [true, true, false, true],
                        stack: [imprimirCelda('PLAZO ENTREGA', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(esOrdenCompra ? 'ESTABLECIMIENTO' : '', null, {
                                bold: true,
                                noWrap: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        colSpan: 2,
                        stack: [imprimirCelda(esOrdenCompra ? 'ALMACEN' : '', null, {bold: true, noWrap: true})],
                    },
                    {},
                    {
                        border: [false, true, true, true],
                        stack: [imprimirCelda('FORMA DE PAGO', null, {bold: true, noWrap: true})],
                    },
                ],
                [
                    {
                        border: [true, true, false, true],
                        stack: [
                            // imprimirCelda(dataGeneral.nCompraPlazoEntrega, 'cant', {fontSize: 7, italics: true}),
                            imprimirCelda(dataGeneral.nCompraPlazoEntrega * 1 + ' días', null, {
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(esOrdenCompra ? dataGeneral.cEstablecimientoNombreLargo : '', null, {
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        colSpan: 2,
                        stack: [
                            imprimirCelda(esOrdenCompra ? dataGeneral.cAlmacenNombre : '', null, {
                                italics: true,
                            }),
                        ],
                    },
                    {},
                    {
                        border: [false, true, true, true],
                        stack: [imprimirCelda(dataGeneral.cFormaPagoNombre, null, {fontSize: 7, italics: true})],
                    },
                ],
                [
                    {
                        // border: [true, true, false, false],
                        colSpan: 5,
                        text: [
                            imprimirCelda('REFERENCIA: ', null, {bold: true, noWrap: true}),
                            imprimirCelda(dataGeneral.cCompraReferencia, null, {bold: true, italics: false, fontSize: 9})
                        ],
                    },
                    {},
                    {},
                    {},
                    {},
                ],
/*                [
                    {
                        // border: [true, true, false, false],
                        colSpan: 5,
                        stack: [imprimirCelda(dataGeneral.cCompraReferencia, null, {bold: true, italics: false, fontSize: 9})],
                    },
                    {},
                    {},
                    {},
                    {},
                ],*/
                [
                    {
                        // border: [true, true, false, false],
                        colSpan: 5,
                        stack: [imprimirCelda('DOCUMENTOS', null, {bold: true, noWrap: true})],
                    },
                    {},
                    {},
                    {},
                    {},
                ],
                [
                    {
                        // border: [true, true, false, false],
                        colSpan: 5,
                        stack: docARchivo,
                    },
                    {},
                    {},
                    {},
                    {},
                ]
            ];

            let anchoColumnasDetalle = [10, 55, 61, 101, 100, 35, 35, 34, 40];
            if (esOrdenCompra) {
                anchoColumnasDetalle = [10, 55, 39, 57, 36, 35, 35, 35, 35, 35, 34, 40];
            }
            // imprimirCelda('Nº', null, {style: 'thSmall'});
            // imprimirCelda('CÓDIGO', null, {style: 'thSmall'});
            // imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', colSpan: 3});
            // imprimirCelda('CANTIDAD', null, {style: 'thSmall'});
            // imprimirCelda('PRECIO', null, {style: 'thSmall'});
            // imprimirCelda('TOTAL', null, {style: 'thSmall'});
            let celdasFooter = [];

            const strCtasProveedorArray = [];
            dataCuentasProveedor.filter((cuenta) => {
                strCtasProveedorArray.push(
                    {text: cuenta['cBancoNombre'] + ' - ' + cuenta['cTipoCuentaNombre'] + ' - '},
                    {text: ' ' + cuenta['cPersCuentaNumero'], color: '#06024f', bold: true},
                    {text: ' (' + cuenta['cTipoMonedaNombre'] + ')\n'}
                );
            });
            if (strCtasProveedorArray.length > 0 ) {
                strCtasProveedorArray.unshift({text: 'TRANSFERIR A:\n', bold: true});
            }
            dataTotales.filter((resultado, idxRes) => {
                console.log(resultado);
                if (idxRes == 0) {
                    if (esOrdenCompra) {
                        celdasFooter.push(
                            [
                                imprimirCelda(strCtasProveedorArray, null, {
                                    style: 'tdSmall',
                                    colSpan: 11,
                                    border: [true, false, true, false],
                                }),
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            ],
                            [
                                imprimirCelda('JEFE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {
                                    style: 'thSmall',
                                    colSpan: 3,
                                }),
                                {},
                                {},
                                imprimirCelda('ASISTENTE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {
                                    style: 'thSmall',
                                    colSpan: 2,
                                }),
                                {},
                                imprimirCelda('SUBGERENTE ADMINISTRATIVO', null, {style: 'thSmall', colSpan: 3}),
                                {},
                                {},

                                {
                                    columns: [
                                        {width: '*', text: resultado.cResultadoNombre},
                                        {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                                    ],
                                    colSpan: 3,
                                    style: 'tdSmall',
                                    noWrap: true,
                                    bold: true,
                                },
                                // imprimirCelda(resultado.cResultadoNombre, null, {
                                //     colSpan: 2,
                                //     style: 'tdSmall',
                                //     bold: true,
                                // }),
                                imprimirCelda(''),
                                imprimirCelda(''),
                                imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                    style: 'tdSmall',
                                    alignment: 'right',
                                    italics: true,
                                }),
                            ]
                        );
                    } else {
                        celdasFooter.push(
                            [
                                imprimirCelda(strCtasProveedorArray, null, {
                                    style: 'tdSmall',
                                    colSpan: 8,
                                    border: [true, false, true, false],
                                }),
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            ],
                            [
                                imprimirCelda('JEFE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {style: 'thSmall', colSpan: 3}),
                                {},
                                {},
                                imprimirCelda('ASISTENTE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {style: 'thSmall'}),
                                imprimirCelda('SUBGERENTE ADMINISTRATIVO', null, {style: 'thSmall'}),

                                {
                                    columns: [
                                        {width: '*', text: resultado.cResultadoNombre},
                                        {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                                    ],
                                    colSpan: 3,
                                    style: 'tdSmall',
                                    noWrap: true,
                                    bold: true,
                                },
                                // imprimirCelda(resultado.cResultadoNombre, null, {
                                //     colSpan: 2,
                                //     style: 'tdSmall',
                                //     bold: true,
                                // }),
                                imprimirCelda(''),
                                imprimirCelda(''),
                                imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                    style: 'tdSmall',
                                    alignment: 'right',
                                    italics: true,
                                }),
                            ]
                        );
                    }
                } else if (idxRes == dataTotales.length - 1) {
                    if (esOrdenCompra) {
                        celdasFooter.push([
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                colSpan: 2,
                                border: [true, false, true, true],
                            }),
                            {},
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                colSpan: 3,
                                border: [true, false, true, true],
                            }),
                            {},
                            {},

                            {
                                columns: [
                                    {width: '*', text: resultado.cResultadoNombre},
                                    {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                                ],
                                colSpan: 3,
                                fontSize: 9,
                                style: 'tdSmall',
                                noWrap: true,
                                bold: true,
                            },
                            /*imprimirCelda(resultado.cResultadoNombre, null, {
                                colSpan: 2,
                                fontSize: 9,
                                style: 'tdSmall',
                                noWrap: true,
                                bold: true,
                            }),*/
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                noWrap: true,
                                fontSize: 9,
                                alignment: 'right',
                                bold: true,
                            }),
                        ]);
                    } else {
                        celdasFooter.push([
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                            }),
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                            }),

                            {
                                columns: [
                                    {width: '*', text: resultado.cResultadoNombre},
                                    {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                                ],
                                colSpan: 3,
                                style: 'tdSmall',
                                noWrap: true,
                                bold: true,
                            },
                            // imprimirCelda(resultado.cResultadoNombre, null, {
                            //     colSpan: 2,
                            //     style: 'tdSmall',
                            //     noWrap: true,
                            //     bold: true,
                            // }),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                noWrap: true,
                                fontSize: 9,
                                alignment: 'right',
                                bold: true,
                            }),
                        ]);
                    }
                } else {
                    if (esOrdenCompra) {
                        celdasFooter.push([
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 2,
                            }),
                            {},
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 3,
                            }),
                            {},
                            {},

                            {
                                columns: [
                                    {width: '*', text: resultado.cResultadoNombre},
                                    {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                                ],
                                colSpan: 3,
                                style: 'tdSmall',
                                noWrap: true,
                                bold: true,
                            },
                            // imprimirCelda(resultado.cResultadoNombre, null, {
                            //     colSpan: 2,
                            //     style: 'tdSmall',
                            //     bold: true,
                            // }),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                alignment: 'right',
                                italics: true,
                            }),
                        ]);
                    } else {
                        celdasFooter.push([
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),

                            {
                                columns: [
                                    {width: '*', text: resultado.cResultadoNombre},
                                    {width: 'auto', text: dataGeneral.cTipoMonedaSimbolo, alignment: 'right'},
                                ],
                                colSpan: 3,
                                style: 'tdSmall',
                                noWrap: true,
                                bold: true,
                            },
                            // imprimirCelda(resultado.cResultadoNombre, null, {
                            //     colSpan: 2,
                            //     style: 'tdSmall',
                            //     bold: true,
                            // }),
                            imprimirCelda(''),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                alignment: 'right',
                                italics: true,
                            }),
                        ]);
                    }
                }
            });
            if (esOrdenCompra) {
                celdasFooter.push(
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 12,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            colSpan: 12,
                            text: [
                                {text: 'SON: ', bold: true},
                                {
                                    text: dataGeneral.cCompraValorItemTotal_Letra + dataGeneral.cTipoMonedaNombre,
                                    italics: true,
                                },
                            ],
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 12,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            colSpan: 12,
                            margin: [-5, 0, -5, 0],
                            table: {
                                widths: [380, 1, '*'],
                                body: [
                                    [
                                        {
                                            stack: [
                                                imprimirCelda('FACTURAR A NOMBRE DE:', null, {bold: true}),
                                                imprimirCelda(dataGeneral.cEntNombreCorto ? dataGeneral.cEntNombreCorto : dataGeneral.cEntNombreLargo),
                                                {
                                                    columns: [
                                                        {
                                                            width: 50,
                                                            stack: [
                                                                imprimirCelda('RUC: ', null, {bold: true}),
                                                                imprimirCelda('DIRECCIÓN: ', null, {bold: true}),
                                                                imprimirCelda('TELÉFONO: ', null, {bold: true}),
                                                            ],
                                                        },
                                                        {
                                                            width: '*',
                                                            stack: [
                                                                imprimirCelda(dataGeneral.cEntRuc),
                                                                imprimirCelda(dataGeneral.cEntDomicilioCorto ? dataGeneral.cEntDomicilioCorto : dataGeneral.cEntDomicilioLargo),
                                                                imprimirCelda(dataGeneral.cEntTelefono),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        imprimirCelda('', null, {border: [true, false, true, false]}),
                                        imprimirCelda('Recibido:' + '\n\n\n\n\n\n\n\n\n\n\n' + 'Fecha:', null, {bold: true}),
                                    ],
                                ],
                            },
                            // layout: 'noBorders',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ]
                );
            } else {
                celdasFooter.push(
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 9,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            colSpan: 9,
                            text: [
                                {text: 'SON: ', bold: true},
                                {
                                    text: dataGeneral.cCompraValorItemTotal_Letra + dataGeneral.cTipoMonedaNombre,
                                    italics: true,
                                },
                            ],
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 9,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            colSpan: 9,
                            margin: [-5, 0, -5, 0],
                            table: {
                                widths: [380, 1, '*'],
                                body: [
                                    [
                                        {
                                            stack: [
                                                imprimirCelda('FACTURAR A NOMBRE DE:', null, {bold: true}),
                                                imprimirCelda(dataGeneral.cEntNombreCorto ? dataGeneral.cEntNombreCorto : dataGeneral.cEntNombreLargo),
                                                {
                                                    columns: [
                                                        {
                                                            width: 50,
                                                            stack: [
                                                                imprimirCelda('RUC: ', null, {bold: true}),
                                                                imprimirCelda('DIRECCIÓN: ', null, {bold: true}),
                                                                imprimirCelda('TELÉFONO: ', null, {bold: true}),
                                                            ],
                                                        },
                                                        {
                                                            width: '*',
                                                            stack: [
                                                                imprimirCelda(dataGeneral.cEntRuc),
                                                                imprimirCelda(dataGeneral.cEntDomicilioCorto ? dataGeneral.cEntDomicilioCorto : dataGeneral.cEntDomicilioLargo),
                                                                imprimirCelda(dataGeneral.cEntTelefono),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        imprimirCelda('', null, {border: [true, false, true, false]}),
                                        imprimirCelda('Recibido:' + '\n\n\n\n\n\n\n\n\n\n\n' + 'Fecha:', null, {bold: true}),
                                    ],
                                ],
                            },
                            // layout: 'noBorders',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ]
                );
            }

            const numerosDocumentos = [];
            const fnDocNum = (concepto, numero) => {
                return [imprimirCelda(concepto, null, {fontSize: 7, bold: true /*, alignment: 'center'*/}), imprimirCelda(numero, null, {fontSize: 8, alignment: 'center'})];
            };
            numerosDocumentos.push(fnDocNum('PÁGINA', '##pagina##/##total_paginas##'));
            if (dataGeneral.cPedidoNumero) {
                numerosDocumentos.push(fnDocNum('Nº PEDIDO', dataGeneral.cPedidoNumero));
            }
            if (dataGeneral.cCotizaNumero) {
                numerosDocumentos.push(fnDocNum('Nº COTIZACIÓN', dataGeneral.cCotizaNumero));
            }
            if (dataGeneral.cCuaComNumero) {
                numerosDocumentos.push(fnDocNum('Nº CUA. COM.', dataGeneral.cCuaComNumero));
            }

            const mDeAgua = {};
            if (dataGeneral.bCompraAnuladoRegistro == '1' || dataGeneral.bCompraCerrado != '1') {
                mDeAgua['color'] = '#a80707';
                mDeAgua['text'] = (dataGeneral.bCompraAnuladoRegistro == '1' ? 'ANULADO' : (dataGeneral.bCompraCerrado == '1' ? undefined : 'BORRADOR'));
            }

            const tablaGeneral = {
                margin: [-5, 0, -5, 5],
                table: {
                    // widths: [130, '*'],
                    widths: objThis.anchoCols(dataGeneralTabla, [4] /*, [{idx: 0, valor: 'auto'}, {idx: 1, valor: 110}, ]*/),
                    body: dataGeneralTabla,
                }
            };

            let celdasHeaderDetalle = [
                [
                    {...tablaGeneral, ...{
                            colSpan: 9,
                            border: [false, false, false, true],
                        }},
                    {}, {}, {}, {}, {}, {}, {}, {},
                ],
                [
                    // imprimirCelda('Nº', null, { style: 'thSmall' }),
                    {
                        style: 'thSmall',
                        stack: [
                            {text: 'Nº'},
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: 0.5,
                                        y1: 0,
                                        x2: 0.5,
                                        y2: 370,
                                    },
                                ],
                                relativePosition: {x: -5, y: 0},
                                alignment: 'left',
                            },
                        ],
                    },
                    imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                    imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', colSpan: 3}),
                    {},
                    {},
                    imprimirCelda('U.MED.', null, { style: 'thSmall' }),
                    imprimirCelda('CANT.', null, {style: 'thSmall'}),
                    imprimirCelda('PRECIO', null, {style: 'thSmall'}),
                    // imprimirCelda('TOTAL', null, { style: 'thSmall' }),
                    {
                        style: 'thSmall',
                        stack: [
                            {text: 'TOTAL'},
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: -1.5,
                                        y1: 0,
                                        x2: -1.5,
                                        y2: 370,
                                    },
                                    {
                                        type: 'line',
                                        x1: -50.5,
                                        y1: 0,
                                        x2: -50.5,
                                        y2: 370,
                                    },
                                ],
                                relativePosition: {x: 6, y: 0},
                                alignment: 'right',
                            },
                        ],
                    },
                ],
            ];
            if (esOrdenCompra) {
                celdasHeaderDetalle = [
                    [
                        {...tablaGeneral, ...{
                                colSpan: 12,
                                border: [false, false, false, true],
                            }},
                        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
                    ],
                    [
                        // imprimirCelda('Nº', null, { style: 'thSmall' }),
                        {
                            style: 'thSmall',
                            stack: [
                                {text: 'Nº'},
                                {
                                    canvas: [
                                        {
                                            type: 'line',
                                            x1: 0.5,
                                            y1: 0,
                                            x2: 0.5,
                                            y2: 370,
                                        },
                                    ],
                                    relativePosition: {x: -5, y: 0},
                                    // absolutePosition: {x: 20, y: 0},
                                    alignment: 'left',
                                },
                            ],
                        },
                        imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                        imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', colSpan: 3}),
                        {},
                        {},
                        imprimirCelda('U.MED.', null, { style: 'thSmall' }),
                        imprimirCelda('MARCA', null, {style: 'thSmall'}),
                        imprimirCelda('MODELO', null, {style: 'thSmall'}),
                        imprimirCelda('COLOR', null, {style: 'thSmall'}),
                        imprimirCelda('CANT.', null, {style: 'thSmall'}),
                        imprimirCelda('PRECIO', null, {style: 'thSmall'}),
                        // imprimirCelda('TOTAL', null, { style: 'thSmall' }),
                        {
                            style: 'thSmall',
                            stack: [
                                {text: 'TOTAL'},
                                {
                                    canvas: [
                                        {
                                            type: 'line',
                                            x1: -1.5,
                                            y1: 0,
                                            x2: -1.5,
                                            y2: 370,
                                        },
                                        {
                                            type: 'line',
                                            x1: -50.5,
                                            y1: 0,
                                            x2: -50.5,
                                            y2: 370,
                                        },
                                    ],
                                    relativePosition: {x: 6, y: 0},
                                    alignment: 'right',
                                },
                            ],
                        },
                    ],
                ];

                if (dataGeneral.bCompraDetTributoIncluidoPrecioUnitario == 1) {
                    // 7
                    celdasHeaderDetalle[1][7] = {...celdasHeaderDetalle[1][7], ...{text: 'CANT'}};
                    celdasHeaderDetalle[1][8] = {...celdasHeaderDetalle[1][8], ...{text: 'V. UNIT.'}};
                }
            }
            const celdasBodyDetalle = [];

            dataRecibido[idxCompra].filter((dataProd, idxProd) => {
                let bordesCelda;
                bordesCelda = [true, true, true, true];

                if (esOrdenCompra) {
                    const celdasCompra = [
                        imprimirCelda(idxProd + 1, 'number', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCodigoProducto, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cProductoNombreObs, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                            colSpan: 3,
                        }),
                        {},
                        {},

                        imprimirCelda(dataProd.cPresentacionNombre, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),

                        imprimirCelda(dataProd.cCompraDetMarca, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCompraDetModelo, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCompraDetColor, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),

                        imprimirCelda(dataProd.nCompraDetCantidad, 'cant', {
                            border: bordesCelda,
                            format: '1.2-2',
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetPrecioUnitario, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetValorItemNeto, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                    ];

                    if (dataGeneral.bCompraDetTributoIncluidoPrecioUnitario == 1) {
                        // 7
                        celdasCompra[7] = imprimirCelda(dataProd.nCompraDetCantidad, 'cant', {
                            border: bordesCelda,
                            format: '1.2-2',
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        });
                        celdasCompra[8] = imprimirCelda(dataProd.nCompraDetValorUnitarioBruto, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        });
                    }
                    celdasBodyDetalle.push(celdasCompra);
                } else {
                    celdasBodyDetalle.push([
                        imprimirCelda(idxProd + 1, 'number', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCodigoProducto, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cProductoNombreObs, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                            colSpan: 3,
                        }),
                        {},
                        {},
                        imprimirCelda(dataProd.cPresentacionNombre, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetCantidad, 'cant', {
                            border: bordesCelda,
                            format: '1.2-2',
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetPrecioUnitario, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetValorItemNeto, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                    ]);
                }
            });

            /*
            const strDocumentosArray = [];
            if (dataGeneral.cCompraObs) {
                strDocumentosArray.push({text: 'OBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true});
            }
            dataDocumentos.filter((documento) => {
                if (strDocumentosArray.length == 0) {
                    strDocumentosArray.push({text: '\nDOCUMENTOS:\n ', bold: true});
                }
                strDocumentosArray.push(
                    {text: documento['cTipoDocNombre']},
                    {
                        text: ' ' + documento['cCompraDocSerie'] + ' - ' + documento['cCompraDocNumero'],
                        color: '#06024f',
                        bold: true,
                    },
                    {text: ' (' + formatDate(documento['dCompraDocEmision'], 'dd/MM/yyyy', 'es-PE') + ')\n'}
                );
            });
            */

            if (esOrdenCompra) {
                celdasBodyDetalle.push([
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                    {
                        // stack: dataExtraFinalObs,
                        columns: [
                            {stack: dataExtraFinalObs, alignment: 'justify'},
                            {}
                        ],
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        color: '#001d47',
                        colSpan: 10,
                    },
                    {},
                    {},
                    {},

                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                ]);
            } else {
                celdasBodyDetalle.push([
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                    {
                        stack: dataExtraFinalObs,
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        color: '#001d47',
                        colSpan: 7,
                    },

                    {},
                    {},
                    {},

                    {},
                    {},
                    {},
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                ]);
            }

            if (dataGeneral.cCompraAnuladoMotivo) {
                if (esOrdenCompra) {
                    celdasBodyDetalle.push([
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                        {
                            text: [
                                {text: 'MOTIVO DE ANULACIÓN: ', bold: true},
                                {text: dataGeneral.cCompraAnuladoMotivo, italics: true},
                            ],
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            color: '#680101',
                            colSpan: 7,
                        },
                        {},
                        {},
                        {},
                        {},

                        {},
                        {},
                        {},
                        {},
                        {},
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                    ]);
                } else {
                    celdasBodyDetalle.push([
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                        {
                            text: [
                                {text: 'MOTIVO DE ANULACIÓN: ', bold: true},
                                {text: dataGeneral.cCompraAnuladoMotivo, italics: true},
                            ],
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            color: '#680101',
                            colSpan: 7,
                        },

                        {},
                        {},
                        {},
                        {},

                        {},
                        {},
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                    ]);
                }
            }

            const tablaHeaderPedido = {
                margin: [0, 0, 0, 10],
                table: {
                    // widths: ['*'],
                    headerRows: 2,
                    widths: anchoColumnasDetalle,
                    body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                },
            };
            cuerpoPdf.push(tablaHeaderPedido);

            objThis.reiniciarOpciones({
                marcaDeAgua: mDeAgua,
                // margenesHoja: [20, 178, 20, 280],
                margenesHoja: [20, 90, 20, 310],
                // margenesHoja: [20, 188 + (docARchivo.length * 10), 20, 280],
                tamanioFuenteExtra: 3,
                customDefTableHeader: [
                    {
                        margin: [5, 20, 5, 0],
                        table: {
                            widths: [150, '*', 150],
                            headerRows: 0,
                            body: [
                                [
                                    objThis.logotipoHeader(dataGeneral),
                                    {
                                        margin: [0, 0, 0, 0],
                                        alignment: 'center',
                                        stack: [
                                            imprimirCelda(dataGeneral.cTipoDocNombre + '\nNº ' + dataGeneral.iCompraNumero + ' - ' + dataGeneral.iCompraYear, null, {
                                                fontSize: 20,
                                                font: 'TimesNewRoman',
                                                bold: true,
                                            }),
                                        ],
                                    },
                                    {
                                        margin: [30, 0, 10, 0],
                                        table: {
                                            widths: ['auto', '*'],
                                            body: numerosDocumentos,
                                        },
                                    },
                                ],
                            ],
                        },
                        layout: {
                            defaultBorder: false,
                        },
                    },
                    // {
                    //     margin: [20, 0, 20, 0],
                    //     table: {
                    //         // widths: [130, '*'],
                    //         widths: objThis.anchoCols(dataGeneralTabla, [4] /*, [{idx: 0, valor: 'auto'}, {idx: 1, valor: 110}, ]*/),
                    //         body: dataGeneralTabla,
                    //     },
                    // },
                ],
                footerTablePrepend: {
                    margin: [20, 0, 20, 0],
                    table: {
                        widths: anchoColumnasDetalle,
                        // widths: objThis.anchoCols(celdasHeaderDetalleBase, [2]),
                        body: celdasFooter,
                    },
                    // absolutePosition: {x: 20, y: 0},
                },
                footerTableLeftStackPrepen: [
                    'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                ],
            });
            // console.log(JSON.stringify(cuerpoPdf));
            if ((Object.keys(dataRecibido)).length == 1 ) {
                objThis.finalizarCreacionPdf(cuerpoPdf);
            }
            celdasFooter = [];
            // cuerpoPdf = [];
            if (indiceCompra < (Object.keys(dataRecibido)).length - 1) {
                cuerpoPdf.push({
                    text: '',
                    pageBreak: 'before',
                });
            }
        });
        console.warn(dataRecibido);
        if ((Object.keys(dataRecibido)).length > 1 ) {
            objThis.finalizarCreacionPdf(cuerpoPdf);
        }
    });
}
