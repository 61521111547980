import {Component, EventEmitter, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '@servicios/query.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ColumnaTabla, OpcionSeleccionada} from '@JVSoft/interfaces/global';
import {changeSelect, mensajesErrorFormControl, mostrarValorEnBusqueda, objectPropertiesBoolean} from '@JVSoft/services/funciones-globales.service';

interface DialogParams {
	thisPadre: any;
	botonesCrud: string;
	tipo: 'credenciales' | 'personas';
	titulo: any;
	nombreColeccion: string;
	data: any;
	filtroCampos: boolean;
	columnasTabla?: ColumnaTabla<any>[];
	columnasTablaAdd?: ColumnaTabla<any>[];
}

@Component({
	selector: 'vex-credenciales',
	templateUrl: './credenciales.component.html',
	styleUrls: ['./credenciales.component.scss'],
})
export class CredencialesComponent implements OnInit {

	mostrarValorEnBusqueda = mostrarValorEnBusqueda;
	mensajesErrorFormControl = mensajesErrorFormControl;

	columnasCredenciales: ColumnaTabla<any>[] = [
		{label: '#', property: 'numeracion_automatica', type: 'custom'},
		{label: 'Documento', property: 'cPersDocumento', type: 'text', visible: false},
		{label: 'Nombre', property: 'cPersNombreLargo', type: 'text', cssClasses: ['font-bold']},
	];

	dataServidor = {
		seg: {
			credenciales_entidades: null,
		}
	};

	seleccionados = {
		credenciales: null,
	};
	filtrados = {};
	frmRegistro: FormGroup;

	dialogRefAdic: MatDialogRef<any>[] = [null, null, null];

	onSubmitReason = new EventEmitter();
	opcMenuEmitter = new EventEmitter();
	@ViewChild('dialogRegCredenciales', {static: true}) dialogRegCredencialesRef: TemplateRef<any>;
	constructor(
		@Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: DialogParams,
		private queryService: QueryService,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<CredencialesComponent>,
		private fb: FormBuilder,
	) {
		this.seleccionados[datosDialogRecibidos.nombreColeccion] = null;
	}


	ngOnInit(): void {
		console.log(this.datosDialogRecibidos);
		this.crearFormularios();
		this.cargaInicial();
		if (this.datosDialogRecibidos) {
			if (this.esPorCredenciales()) {
				this.queryService.cargar(this, ['seg.credenciales_entidades']).then();
			}
			this.datosDialogRecibidos.columnasTablaAdd.forEach(col => {
				this.columnasCredenciales.push(col);
			});
		}
		changeSelect(this, this.frmRegistro.get('iCredId'), 'seg.credenciales_entidades', ['iCredId', 'cPersNombreLargo']);
	}
	cargaInicial() {
		this.queryService.cargar(this, ['seg.credenciales_entidades']).then();
	}

	esPorCredenciales() {
		return this.datosDialogRecibidos.tipo == 'credenciales';
	}
	esPorPersonas() {
		return this.datosDialogRecibidos.tipo == 'personas';
	}

	crearFormularios() {
		this.frmRegistro = this.fb.group({
			iPersId: [''],
			iCredId: [''],
		});
	}
	opcMenu(v: OpcionSeleccionada) {
		let accionEncontrada = true;
		console.log(v);
		const dialogOpc: MatDialogConfig = {
			panelClass: 'dialogMantenimientoNuevo',
			disableClose: true,
			data: {},
		};
		switch (v.seccion) {
			case this.datosDialogRecibidos.nombreColeccion:
				dialogOpc.minWidth = '300px';
				switch (v.tipo) {
					case 'ver':
						if (this.seleccionados[this.datosDialogRecibidos.nombreColeccion] == v.item) {
							this.seleccionados[this.datosDialogRecibidos.nombreColeccion] = null;
						}
						else {
							this.seleccionados[this.datosDialogRecibidos.nombreColeccion] = v.item;
						}
						break;
					case 'nuevo':
						this.dialogRefAdic[0] = this.dialog.open(this.dialogRegCredencialesRef, dialogOpc);
						break;
					case 'editar':
						objectPropertiesBoolean(this.seleccionados[this.datosDialogRecibidos.nombreColeccion], 'boolean');
						this.frmRegistro.patchValue(this.seleccionados[this.datosDialogRecibidos.nombreColeccion]);
						this.dialogRef = this.dialog.open(this.dialogRegCredencialesRef, dialogOpc);
						break;
					default:
						accionEncontrada = false;
						break;
				}
				break;
			default:
				accionEncontrada = false;
				break;
		}

		if (!accionEncontrada) {
			v.datosExtra = {
				...{
					frmRegistro: this.frmRegistro.value,
					dialogRef: this.dialogRefAdic[0],
				},
				...v.datosExtra,
			};
			this.opcMenuEmitter.emit(v);
		}
	}
}
