<general-tabla-mantenimiento-nuevo [dataSource]="dataSource" [columnasTabla]="columnasTabla"
                                   [opciones]="(barraFiltro ? opcionesFiltro : [])"
                                   (resultados)="resultados.emit($event)"
                                   prefijoConsulta="tram"
                                   [defaultTab]="3"
								   [asignarValores]="asignarValoresFiltro"
                                   [busquedaAutomatica]="busquedaAutomatica"

                                   [objThis]="objThis || this"
                                   [nombreColeccion]="nombreColeccion"
                                   [data]="dataSource"
                                   [dataSuscription]="dataSuscription"
                                   [botonesCRUD]="botonesCRUD"
                                   [paginador]="paginador"
                                   [filtroCampos]="true"
	[idTabla]="['iTramId', 'iTramMovId']"
	[botonesMenu]="botonesPrincipal"
                                   (opcionSelecionada)="opcionSelecionada.emit($event)"
                                   [leyenda]="leyenda"
                                   [exportarExcel]="exportarExcel"

                                   [condicionesClaseFila]="condicionesClaseFila"
	(accionRecargar)="accionRecargar.emit($event)"
	(dataFiltro)="dataFiltro.emit($event)"
>
	<ng-container derechaFiltro>
		<ng-content select="[derechaFiltro]"></ng-content>
	</ng-container>
	<ng-container botonesFiltro>
		<ng-content select="[botonesFiltro]"></ng-content>
	</ng-container>
	<ng-container tableDefinitions>
		<ng-content select="[tableDefinitions]"></ng-content>
		<ng-container matColumnDef="tipoTramiteMovimiento">

			<th *matHeaderCellDef class="uppercase" mat-header-cell >Tipo</th>
			<td *matCellDef="let row" mat-cell class="text-center">
				<ng-container [ngTemplateOutlet]="iconoTipoTramite" [ngTemplateOutletContext]="{ tipo: row.iTipoTramId || row.iTipoMovId }"></ng-container>
				<ng-template #iconoTipoTramite let-tipo="tipo">
					<mat-icon *ngIf="tipo == 1" class="text-cyan" style="height: unset; width: unset;" svgIcon="roundHomeWork" size="18px"></mat-icon>
					<mat-icon *ngIf="tipo == 2" class="text-green" style="height: unset; width: unset;" svgIcon="roundPublic" size="18px"></mat-icon>
				</ng-template>
				{{row.iTramMovSemaforoPlazoResolver}}
				<mat-icon *ngIf="[1, 2, 3].includes(row.iTramMovSemaforoPlazoResolver * 1)" [ngClass]="(filtroSemaforo | dataEnLista: 'value':(row.iTramMovSemaforoPlazoResolver * 1))?.class" svgIcon="roundCircle" ></mat-icon>
				<mat-icon *ngIf="row.iTipoPrioId == 2" [matTooltip]="row.cTipoPrioNombre" svgIcon="fa5sExclamationTriangle" class="animate-pulse flex text-red-600"></mat-icon>

<!--				<div *ngIf="[1, 2, 3].includes(row.iTramMovSemaforoPlazoResolver * 1)" style="position: absolute;" class="rounded-full h-3 w-3 m-1" [ngClass]="(filtroSemaforo | dataEnLista: 'value':(row.iTramMovSemaforoPlazoResolver * 1))?.class"></div>-->
				<span class="cdk-visually-hidden">{{ row.iTipoTramId || row.iTipoMovId }}</span>
			</td>

		</ng-container>
		<ng-container matColumnDef="tiempoTranscurrido">

			<th *matHeaderCellDef class="uppercase" mat-header-cell >Tiempo Transcurrido</th>
			<td *matCellDef="let row" mat-cell class="font-bold text-center">
				<span *ngIf="row.dtTramMovFechaHoraRecibido && row.dtTramMovFechaHoraEnvio">{{ row.dtTramMovFechaHoraRecibido | dateDiffString: row.dtTramMovFechaHoraEnvio }}</span>
				<span *ngIf="!row.dtTramMovFechaHoraRecibido && row.dtTramMovFechaHoraEnvio">{{ fechaActual | dateDiffString: row.dtTramMovFechaHoraEnvio }}</span>
			</td>

		</ng-container>
		<ng-container matColumnDef="cDepenNombreEmisor_lista">

			<th *matHeaderCellDef class="uppercase" mat-header-cell >Emisor</th>
			<td *matCellDef="let row" mat-cell>
				<p style="font: unset; letter-spacing: unset">{{ row.cDespachoEmisorNombreLargo }}</p>
				<small class="uppercase" *ngIf="row.iTipoTramId || row.iTipoMovId; let tipo; ">
					<span *ngIf="tipo == 1 && row.cPersFirmaEmisorNombre">{{row.cPersFirmaEmisorNombre}}</span>
					<span *ngIf="tipo == 2 && row.cPersEmisorNombre">{{row.cPersEmisorNombre}}  </span>
<!--					<span *ngIf="tipo == 2 && row.cDespachoEmisorEnvioNombreCorto"> - {{row.cDespachoEmisorEnvioNombreCorto}}  </span>-->
<!--					<span *ngIf="tipo == 2 && row.cPersNaturalEmisorNombre" class="capitalize">2 2 {{row.cPersNaturalEmisorNombre.toLowerCase()}}</span>-->
				</small>
			</td>

		</ng-container>
		<ng-container matColumnDef="DocumentoFilas">

			<th *matHeaderCellDef class="uppercase" mat-header-cell >Documento</th>
			<td *matCellDef="let row" mat-cell>
				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Doc', desc: row.cTramDocumentoTramite, post_desc: post_desc }" >
					<ng-template #post_desc>
						<span *ngIf="row.bTramMovCopia == 1" class="rounded ml-1 px-1 font-medium bg-red text-white" style="font-size: 70%;">Copia</span>
					</ng-template>
				</ng-container>

				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Ref', desc: row.cTramDocumentoReferencia }" ></ng-container>
				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Fec', desc: (row.dtTramFechaHoraDocumento ? (row.dtTramFechaHoraDocumento | date: 'dd/MM/yyyy HH:mm') : null) }" ></ng-container>
				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Fol', desc: row.iTramMovFolios }" ></ng-container>
				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Adj', desc: (row.cTramAdjuntoFisico || row.cTramMovAdjuntoFisico | jsonParse).join(', ') }" ></ng-container>

				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Acc', desc: (row.iTipoDocId == 31 ? row.cTramMovObsEnvio : null) }" ></ng-container>
				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Archiv. ', desc: (row.cTramMovObsArchivado ? row.cTramMovObsArchivado + ' (' + (row.dtTramMovFechaHoraArchivado | date: 'dd/MM/yyyy HH:mm') + ')' : null), cssClass: 'text-yellow-600 test-xs' }" ></ng-container>
<!--				<ng-container [ngTemplateOutlet]="labelDesc" [ngTemplateOutletContext]="{ row: row, label: 'Obs', desc: ((row.cTramMovObsRecibido && row.iObsId != 31) ? row.cTramMovObsRecibido : null), cssClass: ['text-red'] }" ></ng-container>-->

			</td>

		</ng-container>
		<ng-container matColumnDef="cTramReceptorNombreLargo">

			<th *matHeaderCellDef class="uppercase" mat-header-cell >Destino</th>
			<td *matCellDef="let row" mat-cell>
                <p class="text-2xs leading-tight">{{ row.cTramReceptorNombreLargo }}</p>
                <ng-container *ngIf="row.iMotTramMovId == 6">
                    <p class="text-2xs italic text-justify text-orange-600 leading-tight">"{{ row.cTramMovObsEnvio }}"</p>
                    <div class="flex items-center justify-between text-2xs italic">
                        <mat-icon [inline]="true" [matTooltip]="row.cTramMovObsEnvio" svgIcon="roundForwardToInbox" class="icon-xs text-red-600 flex-none"></mat-icon>
                        <span class="flex-1 italic">{{ (row.cPersConCorreoElectronicoReceptorNombre ?? row.cPersConCorreoElectronicoNombre) }}</span>
                        <span class="text-2xs text-red-900"> {{ row.dtTramMovFechaHoraEnvio | date:'dd/MM/yyyy HH:mm'}}</span>
                    </div>
                </ng-container>

			</td>

		</ng-container>
		<ng-container matColumnDef="cTramMovEstadoTramite">

			<th *matHeaderCellDef class="uppercase" mat-header-cell >Estado</th>
			<td *matCellDef="let row" mat-cell class="text-center">
				<div class="grid w-full justify-items-center">
					<div class="flex -space-x-2" *ngIf="estiloBoton(row); let eBoton;">
						<ng-container [ngTemplateOutlet]="circulosEstado" [ngTemplateOutletContext]="{eBoton}"></ng-container>
						<ng-container [ngTemplateOutlet]="circulosEstado"
							*ngIf="row.cTramMovObsRecibido"
							[ngTemplateOutletContext]="{eBoton: { iniciales: 'Obs', label: 'Observado ' + (row.cTramMovObsRecibido ? ' - ' + row.cTramMovObsRecibido : ''), cssClases: {contenedor: 'bg-red-700 text-red-100'}}}"
						></ng-container>
						<div class="relative inline-flex items-center justify-center "
							*ngIf="row.iTramCantidadRechazosEnviados > 0"
							(click)="opcionSelecionada.emit({seccion: nombreColeccion, tipo: 'ver_detalle_rechazado', item: row}); $event.stopPropagation();"
						>
							<ng-container [ngTemplateOutlet]="circulosEstadoSinContenedor"
								[ngTemplateOutletContext]="{eBoton: { iniciales: 'Rec', label: 'Rechazado', cssClases: {contenedor: 'bg-red-700 text-red-100'}, postIcon: {icon: 'fa5sExclamationTriangle'}}}"
							></ng-container>
						</div>
						<div class="relative inline-flex items-center justify-center "
							*ngIf="row.iTramCantidadRechazosRecibidos > 0"
							(click)="opcionSelecionada.emit({seccion: nombreColeccion, tipo: 'ver_detalle_rechazado', item: row}); $event.stopPropagation();"
						>
							<ng-container [ngTemplateOutlet]="circulosEstadoSinContenedor"
								[ngTemplateOutletContext]="{eBoton: { iniciales: 'Rec', label: 'Rechazado luego de ser recibido', cssClases: {contenedor: 'bg-red-700 text-red-100'}, postIcon: {icon: 'fa5sExclamationTriangle'}}}"
							></ng-container>
						</div>
						<div class="relative inline-flex items-center justify-center "
							*ngIf="row.iTramMovCantidadNotificacionesRealizadas > 0"
							(click)="opcionSelecionada.emit({seccion: nombreColeccion, tipo: 'notificaciones', item: row}); $event.stopPropagation();"
						>
							<ng-container [ngTemplateOutlet]="circulosEstadoSinContenedor"
								[ngTemplateOutletContext]="{eBoton: { iniciales: 'Not', label: 'Notificado', cssClases: {contenedor: 'bg-amber-400 text-amber-900'}, postIcon: {icon: 'fa5sExclamationTriangle', classes: 'text-red-600'}}}"
							></ng-container>
						</div>

					</div>
				</div>
			</td>

		</ng-container>
	</ng-container>
</general-tabla-mantenimiento-nuevo>

<ng-template #labelDesc let-row="row" let-label="label" let-desc="desc" let-salto="salto"
             let-cssClass="cssClass"
             let-cssLabelClass="cssLabelClass"
             let-cssDescClass="cssDescClass"

             let-pre_label="pre_label"
             let-post_label="post_label"
             let-pre_desc="pre_desc"
             let-post_desc="post_desc"
>
	<p style="font: unset; letter-spacing: unset" [ngClass]="cssClass" *ngIf="desc && desc != 'null'">
		<ng-container [ngTemplateOutlet]="pre_label"></ng-container>
		<span [ngClass]="cssLabelClass" class="font-bold">{{ label }}: </span>
		<ng-container [ngTemplateOutlet]="post_label"></ng-container>
		<ng-container [ngTemplateOutlet]="pre_desc"></ng-container>
		<span [ngClass]="cssDescClass" class="italic">{{ desc }}</span>
		<ng-container [ngTemplateOutlet]="post_desc"></ng-container>
	</p>
</ng-template>


<ng-template #circulosEstado let-eBoton="eBoton">
	<div class="relative inline-flex items-center justify-center ">
		<ng-container [ngTemplateOutlet]="circulosEstadoSinContenedor" [ngTemplateOutletContext]="{eBoton}"></ng-container>
	</div>
</ng-template>

<ng-template #circulosEstadoSinContenedor let-eBoton="eBoton">
	<div class="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden rounded-full shadow-lg"
		[ngClass]="eBoton.cssClases?.contenedor"
		[matTooltip]="eBoton?.label + (eBoton.tooltip ? ' - ' + eBoton.tooltip : '')"
	>
		<span class="" [ngClass]="eBoton.cssClases?.texto">{{eBoton.iniciales}}</span>
	</div>
	<mat-icon *ngIf="eBoton.preIcon"
		[svgIcon]="eBoton.preIcon.icon"
		[ngClass]="eBoton.preIcon.classes"
		class="bg-card absolute -top-1 -left-1 rounded-full icon-xs"></mat-icon>
	<mat-icon *ngIf="eBoton.postIcon"
		[svgIcon]="eBoton.postIcon.icon"
		[ngClass]="eBoton.postIcon.classes"
		class="bg-card absolute -bottom-1 -left-1 rounded-full icon-xs"></mat-icon>
</ng-template>
