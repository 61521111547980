import {AfterViewInit, Component, EventEmitter, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {QueryService} from '@servicios/query.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {stagger40ms} from '../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../@vex/animations/fade-in-up.animation';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FuncionesGeneralesService} from '../../general/services/funciones-generales.service';
import {BreadcrumbsService} from '../../general/services/breadcrumbs.service';
import {fadeInRight400ms} from '@vex/animations/fade-in-right.animation';
import {scaleIn400ms} from '@vex/animations/scale-in.animation';
import {EntidadService} from '@servicios/entidad.service';
import {ActivatedRoute, Router} from '@angular/router';
import {mostrarValorEnBusqueda} from '@JVSoft/services/funciones-globales.service';
import {BehaviorSubject} from 'rxjs';
import {PrincipalService} from './principal.service';
import {DespachoService} from '@servicios/despacho.service';
import {TabSeccion} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';
import {FiltroBusquedaService} from '@standalone/components/filtro-busqueda/filtro-busqueda.service';

@UntilDestroy()
@Component({
    selector: 'jvsoft-principal',
    templateUrl: './principal.component.html',
    styleUrls: ['./principal.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
        scaleIn400ms,
        fadeInRight400ms
    ],
})
export class PrincipalComponent implements OnInit, AfterViewInit {
    mostrarValorEnBusqueda = mostrarValorEnBusqueda;
    @ViewChild('headerTemplateDinamico', { static: true, read: ViewContainerRef }) headerTemplateDinamico!: ViewContainerRef;
    @ViewChild('headerTemplateCentroDinamico', { static: true, read: ViewContainerRef }) headerTemplateCentroDinamico!: ViewContainerRef;




    opciones: TabSeccion[] = [
        {
            label: 'Todos',
            params: []
        }
    ];

    _opciones = new BehaviorSubject<any>([
        {
            label: 'Todos',
            params: []
        }
    ]);
    opciones$ = this._opciones.asObservable();
    _parametros = new BehaviorSubject<any>([]);
    parametros$ = this._parametros.asObservable();
    _headerTemplates = new BehaviorSubject<any>(null);
    headerTemplates$ = this._headerTemplates.asObservable();

    _botonesCentro = new BehaviorSubject<any>([]);
    botonesCentro$ = this._botonesCentro.asObservable();

    formularioFiltro = {};


    _dataFiltro = new BehaviorSubject<any>([]);
    dataFiltro$ = this._dataFiltro.asObservable();

    cargaCompleta = false;
    cargaCompletaBotones = false;
    isLoading = false;

    resultados = new EventEmitter<any>();

    dataServidor = {
        seg: {
            credenciales_dependencias: null
        }
    };
    seleccionados = {
    };
    filtrados = {};

    frmFiltro: FormGroup;

    dialogRef: MatDialogRef<any>;
    @ViewChild('dialogRegistro', { static: true }) dialogRegistroRef: TemplateRef<any>;
    botonesMenu;

    constructor(
        public principalService: PrincipalService,
        private entidadService: EntidadService,
        private despachoService: DespachoService,
        private router: Router,
        private dialog: MatDialog,
        private queryService: QueryService,
        private fb: FormBuilder,
        public funcionesGeneralesService: FuncionesGeneralesService,
        public breadCrumbService: BreadcrumbsService,

        private route: ActivatedRoute,
        private viewContainerRef: ViewContainerRef,


        private filtroBusquedaService: FiltroBusquedaService,
    ) {

        this.dataFiltro$.pipe(untilDestroyed(this)).subscribe(vv => {
            if (vv && Object.keys(vv).length != 0) {
                const dataSesion = {};
                const valoresNoEnviar: any[] = ['*primero*'];

                for (const [key, value] of Object.entries(vv)) {
                    if (valoresNoEnviar.includes(value)) {
                        vv[key] = '';
                    }
                }
                this.queryService.setDataSession(vv).then();
            }

        });

        this.parametros$.pipe(untilDestroyed(this)).subscribe(val => {
            if (val && val.length > 0) {
                setTimeout(() => {
                    this.cargaCompleta = true;
                }, 100);
            }
        });
    }

    ngOnInit(): void {
        this.crearFormularios();


        this.route.data.subscribe((rutaData) => {
            // this.opciones = rutaData['opcionesFiltro'];
        });


        this.headerTemplates$.pipe(untilDestroyed(this)).subscribe(headerTemplate => {
            if (headerTemplate) {
                this.loadComponent(headerTemplate);
                if (headerTemplate.botonesCentro) {
                    this._botonesCentro.next(headerTemplate.botonesCentro);
                    setTimeout(() => {
                        this.cargaCompletaBotones = true;
                    }, 100);
                }
            }
        });

    }
    ngAfterViewInit() {
        // this.cargaCompleta = true;
    }

    private loadComponent(componentToLoad: { bajo?: any, centro?: any }) {
        if (this.headerTemplateDinamico && componentToLoad.bajo) {
            this.headerTemplateDinamico.clear();
            this.headerTemplateDinamico.createComponent(componentToLoad.bajo);
        }
        if (this.headerTemplateCentroDinamico && componentToLoad.centro) {
            this.headerTemplateCentroDinamico.clear();
            this.headerTemplateCentroDinamico.createComponent(componentToLoad.centro);
        }
    }

    cambioDataFiltroPorParametros(event) {
        this.filtroBusquedaService._dataFiltroPrincipal.next(event);
        if (event.iDespachoId && event.iDespachoId != this.despachoService.despachoActual.iDespachoId) {
            this.despachoService.setDespachoActualById(event.iDespachoId)
        }
    }

    crearFormularios() {
        this.frmFiltro = this.fb.group({
            iDepenId: ['']
        });
    }

    protected readonly event = event;
}
