<general-tabla-mantenimiento-nuevo
	#tabMenContex_menus
	[objThis]="objThis"
	botonesCRUD=""
	nombreColeccion="menus"
	[data]="dataServidor.seg.menus"
	[filtroCampos]="false"
	[esTabla]="false"
	[listaMenu]="botonesMantenimiento.menus.principal"
	(opcionSelecionada)="opcMenu($event)"
>
	<ng-container cuerpo>
		<general-lista-arbol
			class="w-full"
			*ngIf="dataServidor.seg.menus"
			[objThis]="objThis"
			[menuContextual]="tabMenContex_menus"
			[lista]="dataServidor.seg.menus"
			[checkbox]="checkbox"
			[checkboxSeleccionados]="checkboxSeleccionados"
			(seleccionado)="opcMenu($event)"
			(listaCheck)="listaCheck.emit($event)"
			[templateTxtData]="defTextoListaArbol"
			nombreColeccion="menus"
			campoId="iMenuId"
			campoIdPadre="iMenuPadreId"
		></general-lista-arbol>
		<ng-template #defTextoListaArbol let-data="data">
			<small>{{data.cMenuNombre}} {{ (data.cMenuNombrePersonalizado ? (' [' + data.cMenuNombrePersonalizado + ']') : '') }}</small>
		</ng-template>

	</ng-container>

</general-tabla-mantenimiento-nuevo>
