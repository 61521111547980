import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataServidor} from '@JVSoft/interfaces/global';
import {BehaviorSubject} from 'rxjs';
import {QueryGeneralService} from '../../../general/services/query-general.service';
import * as moment from 'moment/moment';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {FormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {TextFieldModule} from '@angular/cdk/text-field';
import {mostrarValorEnBusqueda} from '@JVSoft/services/funciones-globales.service';
import {FiltroBusquedaService} from './filtro-busqueda.service';
import {CampoFiltro, CampoFiltroChange, DataFiltroAplicado, TabFiltroSeccion, TabSeccion} from './filtro-busqueda.interface';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {MatTableModule} from '@angular/material/table';

@Component({
    selector: 'jvs-filtro-busqueda',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatTabsModule,
        TextFieldModule,
        JVSoftModule,
        MatTableModule,
    ],
    templateUrl: './filtro-busqueda.component.html',
    styleUrls: ['./filtro-busqueda.component.scss']
})
export class FiltroBusquedaComponent implements OnInit {
    mostrarValorEnBusqueda = mostrarValorEnBusqueda;
    fechaActual = new Date();

    @Input() objThis;
    @Input() containerClass = '';
    @Input() prefijoConsulta = 'grl';
    @Input() busquedaAutomatica = false;
    @Input() mostrarBotonFiltro = true;
    @Output() resultados = new EventEmitter<any>();
    @Output() dataFiltro = new EventEmitter<any>();
    public dataServidor = {};
    dataServidorSuscripcion: DataServidor = {};
    public _dataOpciones: BehaviorSubject<TabSeccion[]> = new BehaviorSubject<TabSeccion[]>([]);
    dataOpciones$ = this._dataOpciones.asObservable();
    public _dataOpcionesNuevo: BehaviorSubject<TabFiltroSeccion[]> = new BehaviorSubject<TabFiltroSeccion[]>([]);
    dataOpcionesNuevo$ = this._dataOpcionesNuevo.asObservable();
    filtrados = {};
    cargaCompleta = false;
    isLoading = false;
    formularioFiltro = {opcion: 0};

    constructor(
        private queryService: QueryGeneralService,
        protected filtroBusquedaService: FiltroBusquedaService,
    ) {
    }

    @Input() set defaultTab(val: number) {
        this.formularioFiltro['opcion'] = val ?? 0;
    };

    _nombreColeccion;

    get nombreColeccion() {
        return this._nombreColeccion;
    }

    @Input() set nombreColeccion(val) {
        this.filtroBusquedaService.crearFiltro(val);
        this._nombreColeccion = val;
    };

    get opciones() {
        return this._dataOpciones.getValue();
    }

    @Input() set opciones(val: TabSeccion[]) {
        const nOpc = this.reorganizarOpcionesNuevas(val);
        this._dataOpcionesNuevo.next(nOpc);
        this._dataOpciones.next(val);
    };

    get opcionesNuevas(): TabFiltroSeccion[] {
        return this._dataOpcionesNuevo.getValue();
    }

    @Input() set opcionesNuevas(val: TabFiltroSeccion[]) {
        this._dataOpcionesNuevo.next(val);
        // this._dataOpciones.next(val);
    };

    get filtroResultante() {


        const datosFiltro = {};
        const datosFiltroConLabel: DataFiltroAplicado[] = [];
        datosFiltro['opcion'] = this.formularioFiltro['opcion'];
        const dataRet = this.opcionesNuevas[this.formularioFiltro['opcion']];
        dataRet.campos.forEach(valParams => {
            const dataFiltroAplicado: DataFiltroAplicado = {};
            dataFiltroAplicado.label = valParams.label;
            dataFiltroAplicado.campo = valParams.campo;

            if (valParams.dateRange) {
                const campoFechaIni = valParams.dateRange.campo[0];
                const campoFechaFin = valParams.dateRange.campo[1];

                const fIni = this.formularioFiltro[campoFechaIni];
                const fFin = this.formularioFiltro[campoFechaFin];


                if (fIni) datosFiltro[campoFechaIni] = moment(fIni).format('YYYYMMDD');
                if (fFin) datosFiltro[campoFechaFin] = moment(fFin).format('YYYYMMDD');

                if (fIni && fFin) {
                    dataFiltroAplicado.value = `${moment(fIni).format('DD/MM/YYYY')} - ${moment(fFin).format('DD/MM/YYYY')}`;
                }

                const valDataRange = valParams.dateRange.value.map(itm => (itm == '*fechaActual*' ? this.fechaActual : itm));
                datosFiltro[valParams.dateRange.campo[0]] = valDataRange[0];
                datosFiltro[valParams.dateRange.campo[1]] = valDataRange[1];
            }
            else if (['date'].includes(valParams.control)) {
                datosFiltro[valParams.campo] = this.formularioFiltro[valParams.campo] ? moment(this.formularioFiltro[valParams.campo]).format('YYYYMMDD'):'';
            }
            else {
                datosFiltro[valParams.campo] = this.formularioFiltro[valParams.campo];
            }
            if (!dataFiltroAplicado.value) dataFiltroAplicado.value = datosFiltro[valParams.campo];

            if (valParams.select || valParams.autocomplete) {
                const selectOrAutocomplete = valParams.select ? valParams.select:valParams.autocomplete;

                const list = this.itemsSelectNuevo(valParams);
                const exist = dataEnLista(list, selectOrAutocomplete.campoValor ?? valParams.campo, dataFiltroAplicado.value);
                if (exist) {
                    dataFiltroAplicado.list = list;
                    dataFiltroAplicado.valueObject = exist;
                    dataFiltroAplicado.valueString = this.campoTextEval(valParams, exist);
                    // dataFiltroAplicado.valueString = Array.isArray(selectOrAutocomplete.camposText) ? valParams.text.map(x => exist[x]).join(' ') : exist[valParams.text.toString()] ?? '';
                }
            }
            datosFiltroConLabel.push(dataFiltroAplicado);
        });
        return { datosFiltro, datosFiltroConLabel };
    }


    reorganizarOpcionesNuevas(val: TabSeccion[]): TabFiltroSeccion[] {
        return val.map(tab => {
            const dParams = tab.params.map(param => {
                let camChange: CampoFiltroChange = {
                    filter: undefined,
                    filterFields: undefined,
                };

                if (param.change) {
                    if (typeof param.change === 'boolean') {
                        camChange.filter = param.change;
                    }
                    else if (typeof param.change === 'string') {
                        camChange.filter = true;
                        camChange.filterFields = [param.change];
                    }
                    else {
                        camChange.filter = true;
                        camChange.filterFields = param.change;
                    }
                }
                const dParamRet: CampoFiltro = {
                    control: param?.control,
                    campo: '',
                    label: param?.label,
                    value: param?.val,
                    keyUpEnter: param?.keyUpEnter,
                    cssClass: param?.class,
                    modificadorDatos: param?.modificadorDatos,
                    change: camChange,
                    reqParams: {
                        params: param?.dataReqParams,
                        fields: param?.dataChangeReqParams,
                    }
                }
                if (typeof param.campo == 'string') {
                    dParamRet.campo = param.campo;
                }
                else {
                    if (dParamRet.control == 'date_range') {
                        dParamRet.dateRange = {
                            campo: [param.campo[0], param.campo[1]],
                            value: ['', ''],
                        }
                    }
                    // console.warn('FALTA CONFIGURAR param.campo cuando es ARRAY', param);
                }
                if (Array.isArray(param.data)) {
                    console.warn('FALTA CONFIGURAR param.data cuando es ARRAY', param);
                }

                let paramsSelectAutocomplete = {
                    campoValor: param?.campoId,
                    textoCuandoNulo: param?.dataNull,
                    customData: param?.customData,
                    dataCuandoVacio: param?.dataCuandoVacio,
                    data: (!!param?.data ? (typeof param.data == 'string' ? param.data : undefined) : undefined),
                    camposText: (!!param.text ? (typeof param.text == 'string' ? [param.text] : param.text) : undefined),
                    optionTemplate: param?.optionTemplate,
                    cssClass: param?.cssClass,
                };

                switch (dParamRet.control) {
                    case "date":
                        break;
                    case "select":
                        dParamRet.select = {
                            ...paramsSelectAutocomplete,
                        }
                        break;
                    case "autocomplete":
                        dParamRet.autocomplete = {
                            ...paramsSelectAutocomplete,
                            ...{
                                controlType: param?.tipoControl,
                                class: (!!param.autocompleteClass ? (typeof param.autocompleteClass == 'string' ? param.autocompleteClass : param.autocompleteClass.join(' ')) : undefined),
                                searchFields: (!!param.autocompleteTextoBuscar ? (typeof param.autocompleteTextoBuscar == 'string' ? [param.autocompleteTextoBuscar] : param.autocompleteTextoBuscar) : undefined),
                            },
                        }
                        break;
                }

                return dParamRet;
            });
            return {
                label: tab.label,
                campos: dParams
            };
        });
    }

    ngOnInit() {
        // SI NO HAY OPCIONES SE PONE ESTO POR DEFECTO, ES NECESARIO ESPECIFICAR CRITERIO
        if (this.opciones.length <= 0) {
            console.warn('FALTA ESPECIFICAR OPCIONES FILTRO');
        }

        this.cargarDatosFiltroNuevo().then(() => {
            this.cambiarModelo();
            if (this.busquedaAutomatica) {
                this.filtrarData();
            }
        });

    }

    cargarDatosFiltroNuevo() {
        const llamadasJuntas = [];
        let llamadasJuntasParams = {};


        this.opcionesNuevas.forEach(val => {
            val.campos.forEach((valParams) => {
                if (valParams.modificadorDatos) {
                    valParams = valParams.modificadorDatos(valParams);
                }
                if (valParams.dateRange) {
                    const valDataRange = valParams.dateRange.value.map(itm => (itm == '*fechaActual*' ? this.fechaActual : itm));
                    this.formularioFiltro[valParams.dateRange.campo[0]] = valDataRange[0];
                    this.formularioFiltro[valParams.dateRange.campo[1]] = valDataRange[1];
                }
                else {
                    this.formularioFiltro[valParams.campo] = valParams.value == '*fechaActual*' ? this.fechaActual : (valParams.value ?? '');
                }
                if (valParams.select || valParams.autocomplete) {
                    const selectOrAutocomplete = valParams.select ? valParams.select : valParams.autocomplete;
                    if (selectOrAutocomplete.customData) {
                        this.dataServidor[selectOrAutocomplete.data] = selectOrAutocomplete.customData;
                        if (this.dataServidorSuscripcion) {
                            if (!this.dataServidorSuscripcion[selectOrAutocomplete.data]) {
                                this.dataServidorSuscripcion[selectOrAutocomplete.data] = new BehaviorSubject<any>(null);
                            }
                            this.dataServidorSuscripcion[selectOrAutocomplete.data].next(selectOrAutocomplete.customData);
                        }
                    } else {
                        llamadasJuntas.push(selectOrAutocomplete.data);
                    }

                    if (valParams?.reqParams?.fields) {
                        llamadasJuntasParams = {...llamadasJuntasParams, ...valParams.reqParams.fields};
                    }
                    // this.formularioFiltro[valParams.campo] = asignarValor(valParams.value, valParams);
                }
                // else {
                //     this.formularioFiltro[valParams.campo] = asignarValor(valParams.value, valParams);
                // }
            });
        });

        llamadasJuntas.forEach(req => {
            const pReq = req.split('.');
            this.dataServidor[pReq[0]] = {};
        });
        if (llamadasJuntas.length > 0) {
            return this.queryService.cargar(this, llamadasJuntas, llamadasJuntasParams, false, this.prefijoConsulta).then((dRet) => {
                this.cargaCompleta = true;

                const cargarSeleccionInicial = {};

                this.opcionesNuevas.forEach(val => {
                    val.campos.forEach(valParams => {


                        if (valParams.select || valParams.autocomplete) {
                            const selectOrAutocomplete = valParams.select ? valParams.select:valParams.autocomplete;
                            let dataOrigenServidor = this.dataServidorSuscripcion[selectOrAutocomplete.data].getValue();

                            if (selectOrAutocomplete.dataCuandoVacio && (dataOrigenServidor == null || dataOrigenServidor.length == 0)) {
                                this.dataServidorSuscripcion[selectOrAutocomplete.data].next(selectOrAutocomplete.dataCuandoVacio);
                                dataOrigenServidor = selectOrAutocomplete.dataCuandoVacio;
                            }

                            if (Array.isArray(dataOrigenServidor) && dataOrigenServidor.length > 0) {
                                if (valParams.value == '*primero*') {
                                    if (selectOrAutocomplete.campoValor == '*objeto*') {
                                        this.formularioFiltro[valParams.campo] = dataOrigenServidor[0];
                                    }
                                    else {
                                        this.formularioFiltro[valParams.campo] = dataOrigenServidor[0][selectOrAutocomplete.campoValor ?? valParams.campo];
                                    }
                                }
                            }

                            if (valParams.autocomplete) {
                                this.filtrados[valParams.campo] = dataOrigenServidor;
                            }
                            cargarSeleccionInicial[valParams.campo] = valParams;
                        }
                    });
                });

                Object.values(cargarSeleccionInicial).forEach(valP => {
                    // @ts-ignore
                    this.cambiarSeleccionNuevo(valP);
                });
            });

        }
        return new Promise<any>((resolve, reject) => {
            resolve(true);
        });
    }

    itemsSelectNuevo(controlData: CampoFiltro) {
        if (controlData.select || controlData.autocomplete) {
            const selectOrAutocomplete = controlData.select ? controlData.select:controlData.autocomplete;
            if (this.dataServidorSuscripcion[selectOrAutocomplete.data].getValue()) {
                return this.dataServidorSuscripcion[selectOrAutocomplete.data].getValue();
            }
        }
        return [];
    }

    filtrarData() {
        if (this.opcionesNuevas.length > 0) {
            const objRetorno = this.filtroResultante;
            if (this.objThis) {
                this.objThis['detalleFiltro'] = objRetorno.datosFiltroConLabel;
            }
            this.dataFiltro.emit(this.formularioFiltro);
            this.resultados.emit(objRetorno.datosFiltro);
            // this.cambiarModelo();
            return objRetorno.datosFiltro;
        }
    }

    cambiarNuevo(controlData: CampoFiltro, txtBuscar?) {
        if (controlData.autocomplete) {
            if (!this.formularioFiltro[controlData.campo]) {
                this.filtrados[controlData.campo] = this.dataServidorSuscripcion[controlData.autocomplete.data].getValue();
            }
            else {
                const accionPosterior = () => {
                    this.filtrados[controlData.campo] = this.itemsSelectNuevo(controlData).filter(algo => {
                        let nTextVerif = this.concatenarCadenasArray(controlData.autocomplete.camposText, algo);
                        if (controlData.autocomplete.searchFields) {
                            const nTextVerifExtra = this.concatenarCadenasArray(controlData.autocomplete.searchFields, algo);
                            nTextVerif = nTextVerif.concat(nTextVerifExtra);
                        }
                        const textBuscar = this.formularioFiltro[controlData.campo] && typeof this.formularioFiltro[controlData.campo] == 'string' ? this.formularioFiltro[controlData.campo].toLowerCase():null;
                        if (nTextVerif) {
                            return nTextVerif.toLowerCase().includes(textBuscar) ? algo : null;
                        }
                        return null;
                    });
                };
                if (controlData.autocomplete.campotxtBuscar && txtBuscar && txtBuscar.length > (controlData.autocomplete.campotxtBuscarLength ??  3)) {
                    const dE = {};
                    dE[controlData.autocomplete.campotxtBuscar ?? 'txtBuscar'] = txtBuscar;
                    this.queryService.cargar(this, [controlData.autocomplete.data], dE, false, this.prefijoConsulta).then(() => {

                    });
                }
                else {
                    accionPosterior();
                }
            }
        }
    }

    cambiarModelo() {
        if (this.nombreColeccion) {
            // setTimeout(() => {
                const objRetorno = this.filtroResultante;
                this.filtroBusquedaService.setValue(this.nombreColeccion, objRetorno);
            // },10);
        }
    }

    cambiarSeleccionNuevo(controlData: CampoFiltro) {
        if (controlData.change) {
            if (controlData.change?.filterFields && controlData.change?.filterFields.length > 0) {
                controlData.change?.filterFields.forEach(changeItem => {
                    const matchingItem = this.opcionesNuevas.find(item => item.campos.some(valParams => valParams.campo === changeItem));
                    if (matchingItem) {
                        matchingItem.campos.forEach(param => {
                            if (param.campo === changeItem) {
                                const pAdd: any = {};
                                if (param.reqParams.fields) {
                                    param.reqParams.fields.forEach(val => {
                                        pAdd[val] = this.formularioFiltro[val];
                                    });
                                } else {
                                    pAdd[controlData.campo] = this.formularioFiltro[controlData.campo];
                                }

                                if (param.reqParams.params && typeof param.reqParams.params === 'object') {
                                    Object.keys(param.reqParams.params).forEach(key => {
                                        pAdd[key] = param.reqParams.params[key];
                                    });
                                }

                                this.formularioFiltro[changeItem] = null;
                                if (this.dataServidorSuscripcion[changeItem]) {
                                    this.dataServidorSuscripcion[changeItem].next(null);
                                }

                                if (!!param?.select?.data || !!param?.autocomplete?.data) {
                                    const reqQuery = param?.select?.data ?? param?.autocomplete?.data;
                                    this.queryService.cargar(this, [reqQuery], pAdd, false, this.prefijoConsulta).then((dRet) => {
                                        this.formularioFiltro[changeItem] = param.value ?? null;
                                        this.filtrados[param.campo] = dRet[reqQuery];
                                    });
                                }
                            }
                        });
                    }
                });
            }
            else if (controlData.change?.filter) {
                console.warn('CONFIGURAR CUANDO SE TIENE CAMBIAR COMO BOOLEAN', controlData);
                setTimeout(() => {
                    this.filtrarData();
                    // this.aplicarFiltro();
                }, 1);
            }
        }
    }

    campoValorEval(campoFiltro: CampoFiltro, itemSelect) {
        if (campoFiltro?.select?.campoValor == '*objeto*' || campoFiltro?.autocomplete?.campoValor == '*objeto*') {
            return itemSelect;
        }
        const reqQuery = campoFiltro?.select?.campoValor ?? campoFiltro?.autocomplete?.campoValor;
        if (reqQuery && itemSelect[reqQuery]) {
            return itemSelect[reqQuery];
        }
        return itemSelect[campoFiltro.campo];
    }

    campoTextEval(campoFiltro: CampoFiltro, itemSelect) {
        if (campoFiltro.select || campoFiltro.autocomplete) {
            const selectOrAutocomplete = campoFiltro.select ? campoFiltro.select:campoFiltro.autocomplete;

            if (selectOrAutocomplete?.camposText) {
                const dat = [];
                selectOrAutocomplete.camposText.forEach(campo => {
                    dat.push(itemSelect[campo]);
                });
                return dat.join(' ');
            }
            return itemSelect[campoFiltro.campo];
        }
        return '';
    }

    concatenarCadenasArray(array, objData) {
        if (typeof array == 'string') {
            return objData[array];
        }
        const nTxtArray = [];
        array.forEach(campo => {
            if (campo.charAt(0) == '*') {
                nTxtArray.push(campo.replace('*', ''));
            }
            else {
                nTxtArray.push(objData[campo]);
            }
        });
        return nTxtArray.join(' ');
    }
}

