import {ConfigEnvironment} from '../environment.template';

export const environment:ConfigEnvironment = {
    production: true,
    // sinMarcaJvSoft: true,
    //iEntId: 4,

    api: {
        principal: {
            backend: '//api2.jvsoft.pe:82',
            backendApi: '//api2.jvsoft.pe:82/api',
        },
    },
}
