import twotonePhone from '@iconify/icons-ic/twotone-phone';
import twotoneMail from '@iconify/icons-ic/twotone-mail';
import twotoneMap from '@iconify/icons-ic/twotone-map';
import twotoneSearch from '@iconify/icons-ic/twotone-search';
import twotoneAdd from '@iconify/icons-ic/twotone-add';
import twotoneFilterList from '@iconify/icons-ic/twotone-filter-list';
import twotoneMoreVert from '@iconify/icons-ic/twotone-more-vert';
import twotoneVisibility from '@iconify/icons-ic/twotone-visibility';
import twotoneVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import twotoneListAlt from '@iconify/icons-ic/twotone-list-alt';
import twotoneCheck from '@iconify/icons-ic/twotone-check';
import twotoneCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import twotoneMenu from '@iconify/icons-ic/twotone-menu';
import twotoneDescription from '@iconify/icons-ic/twotone-description';
import twotoneDoneAll from '@iconify/icons-ic/twotone-done-all';
import twotoneMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import twotoneEdit from '@iconify/icons-ic/twotone-edit';
import twotoneDelete from '@iconify/icons-ic/twotone-delete';
import twotoneChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import twotoneSettings from '@iconify/icons-ic/twotone-settings';
import twotoneClose from '@iconify/icons-ic/twotone-close';
import twotoneInbox from '@iconify/icons-ic/twotone-inbox';
import twotoneFace from '@iconify/icons-ic/twotone-face';
import twotonePending from '@iconify/icons-ic/twotone-pending';
import twotoneArchive from '@iconify/icons-ic/twotone-archive';
import twotonePassword from '@iconify/icons-ic/twotone-password';
import twotoneMenuBook from '@iconify/icons-ic/twotone-menu-book';
import twotoneTimeline from '@iconify/icons-ic/twotone-timeline';
import twotoneVpnKey from '@iconify/icons-ic/twotone-vpn-key';

export const twotone = {
    twotonePhone,
    twotoneMail,
    twotoneMap,
    twotoneSearch,
    twotoneAdd,
    twotoneFilterList,
    twotoneMoreVert,
    twotoneVisibility,
    twotoneVisibilityOff,
    twotoneListAlt,
    twotoneCheck,
    twotoneCheckCircle,
    twotoneMenu,
    twotoneDescription,
    twotoneDoneAll,
    twotoneMoreHoriz,
    twotoneEdit,
    twotoneDelete,
    twotoneChevronRight,
    twotoneSettings,
    twotoneClose,
    twotoneInbox,
    twotoneFace,
    twotonePending,
    twotoneArchive,
    twotonePassword,
    twotoneMenuBook,
    twotoneTimeline,
    twotoneVpnKey,
};
