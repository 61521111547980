import {ColumnaTabla} from '@JVSoft/interfaces/global';

export function establecerEstilosPorDefecto(objThis: any, columnasTabla: ColumnaTabla<any>[]): ColumnaTabla<any>[] {
    const estilos: ColumnaTabla<any>[] = estilosPorDefectoColumnas(objThis);

    return columnasTabla.map(col => {
        if (col.type === 'estiloEstablecido') {
            const estiloDefecto = estilos.find(item => item.propertyTemplate === col.propertyTemplate && item.type !== 'estiloEstablecido');
            return estiloDefecto ? { ...estiloDefecto, ...col, type: estiloDefecto.type } : col;
        }
        return col;
    });
}

function estilosPorDefectoColumnas(objThis: any): ColumnaTabla<any>[] {
    return [
        {
            label: 'Fecha', propertyTemplate: 'campoFechaHora', type: 'date', format: 'dd/MM/yyyy HH:mm',
            cssClasses: ['font-mono', 'text-red-900', 'text-center'],
        },
        {
            label: 'Fecha', propertyTemplate: 'campoFecha', type: 'date', format: 'dd/MM/yyyy',
            cssClasses: ['font-mono', 'text-red-900', 'text-center'],
        },
        {
            label: 'Moneda', propertyTemplate: 'campoMoneda', type: 'number', format: '0.2-2',
            cssClasses: ['font-mono', 'text-right'],
        },
        // Otras definiciones de estilos...
    ];
}
