import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {TabEnterFocusService} from './tab-enter-focus.service';

@Directive({
	selector: '[globalTabEnterFocusIndex]'
})
export class TabEnterFocusDirective implements OnInit {
	constructor(private el: ElementRef, private tabService: TabEnterFocusService) {
	}

	private _index: number;

	get index() {
		return this._index;
	}

	@Input('globalTabEnterFocusIndex')
	set index(i: any) {
		this._index = parseInt(i, 10);
	}

	@HostListener('keydown', ['$event'])
	onInput(e: any) {
		if (e.which === 13) {
			e.preventDefault();
			console.log('index', this.index + 1);
			this.tabService.selectedInput.next(this.index + 1);
		}
	}

	ngOnInit() {
		this.tabService.selectedInput.subscribe((i) => {
			console.warn(this.index, this.el);
			if (i === this.index) {
				this.el.nativeElement.focus();
			}
		});
	}
}
