import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonaComponent} from './components/persona/persona.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSortModule} from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PersonaContactosComponent} from './components/persona-contactos/persona-contactos.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ProductoComponent} from './components/producto/producto.component';
import {FormControlsModule} from './form-controls/form-controls.module';
import {MenuComponent} from './components/menu/menu.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatTableExporterModule} from 'mat-table-exporter';
import {MatTableDefaultColumnsDefsComponent} from './components/mat-table-default-columns-defs/mat-table-default-columns-defs.component';
import {TablaMantenimientoNuevoComponent} from './components/tabla-mantenimiento-nuevo/tabla-mantenimiento-nuevo.component';
import {MatTableDefaultColumnsDefs2Component} from './components/mat-table-default-columns-defs2/mat-table-default-columns-defs2.component';
import {ListaIconosComponent} from './components/lista-iconos/lista-iconos.component';
import {MatBadgeModule} from '@angular/material/badge';
import {getSpanishPaginatorIntl} from '@JVSoft/translations/spanish-paginator-intl';
import {ListaArbolComponent} from './components/lista-arbol/lista-arbol.component';
import {ListaArbolCheckboxComponent} from './components/lista-arbol-checkbox/lista-arbol-checkbox.component';
import {RelojServidorComponent} from './components/reloj-servidor/reloj-servidor.component';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {CredencialesComponent} from './components/credenciales/credenciales.component';
import {TablaMantenimientoNuevoMenuComponent} from './components/tabla-mantenimiento-nuevo-menu/tabla-mantenimiento-nuevo-menu.component';
import {MensajeAlertaComponent} from './components/mensaje-alerta/mensaje-alerta.component';
import {RouterLinkWithHref} from '@angular/router';
import {NavigationItemModule} from '@vex/components/navigation-item/navigation-item.module';
import {ToolbarNotificationsModule} from '@vex/layout/toolbar/toolbar-notifications/toolbar-notifications.module';
import {NavigationModule} from '@vex/layout/navigation/navigation.module';
import {MatRippleModule} from '@angular/material/core';
import {MatBuscarPersonaComponent} from './form-controls/mat-buscar-persona/mat-buscar-persona.component';
import {MatBuscarTrabajadorComponent} from './form-controls/mat-buscar-trabajador/mat-buscar-trabajador.component';
import {MatSelectYearComponent} from './form-controls/mat-select-year/mat-select-year.component';
import {JerarquiasTabsTablasComponent} from './components/jerarquias-tabs-tablas/jerarquias-tabs-tablas.component';
import {FiltroBusquedaComponent} from '@standalone/components/filtro-busqueda/filtro-busqueda.component';


@NgModule({
    declarations: [
        PersonaComponent,
        PersonaContactosComponent,
        ProductoComponent,
        MenuComponent,
        MatTableDefaultColumnsDefsComponent,
        TablaMantenimientoNuevoComponent,
        MatTableDefaultColumnsDefs2Component,
        ListaIconosComponent,
        ListaArbolComponent,
        ListaArbolCheckboxComponent,
        RelojServidorComponent,
        CredencialesComponent,
        TablaMantenimientoNuevoMenuComponent,
        MensajeAlertaComponent,
        MatBuscarPersonaComponent,
        MatBuscarTrabajadorComponent,
        MatSelectYearComponent,
        JerarquiasTabsTablasComponent,
    ],
    exports: [
        PersonaComponent,
        MenuComponent,
        MatTableDefaultColumnsDefsComponent,
        TablaMantenimientoNuevoComponent,
        ListaIconosComponent,
        ListaArbolComponent,
        ListaArbolCheckboxComponent,
        RelojServidorComponent,
        MensajeAlertaComponent,
        MatBuscarPersonaComponent,
        MatBuscarTrabajadorComponent,
        MatSelectYearComponent,
        JerarquiasTabsTablasComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        MatDividerModule,
        DragDropModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSortModule,
        MatDatepickerModule,
        MatTabsModule,
        MatAutocompleteModule,
        JVSoftModule,
        FormControlsModule,
        MatTreeModule,
        MatTableExporterModule,
        MatBadgeModule,
        RouterLinkWithHref,
        NavigationItemModule,
        ToolbarNotificationsModule,
        NavigationModule,
        MatRippleModule,
        FiltroBusquedaComponent,
    ],
    providers: [
        {provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl()},
    ],
})
export class GeneralModule {
}
