import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '../../../services/query.service';
import {FuncionesGlobalesService} from '@JVSoft/services/funciones-globales.service';
import {ServidorService} from '@servicios/servidor.service';

@Component({
	selector: 'general-persona',
	templateUrl: './persona.component.html',
	styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {

	static id = 100;

	dataServidor = {
		tipos_personas: null,
		tipos_Identificaciones: null,
		tipos_Identificaciones_personas_naturales: null,
		tipos_Identificaciones_personas_juridicas: null,
		tipos_estados_civiles: null,
	};
    iPersIdVisible : boolean = true;
    cPersDocumentoVisible : boolean = false;
	frmRegistro: FormGroup;
	mode: 'create' | 'update' = 'create';
	tituloElemento = 'Proveedores';
	onSubmitReason = new EventEmitter();

    dataCompPersona: any = {
        labelBusquedaPersona: null,
        placeholderBusquedaPersona: null,
        tipoPersonaBusquedaPersona: null,
    }

	constructor(
		@Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
		public funcionesGlobalesService: FuncionesGlobalesService,
		private queryService: QueryService,
		private dialogRef: MatDialogRef<PersonaComponent>,
		private fb: FormBuilder,
		public servidorService: ServidorService,
	) {
	}

	ngOnInit() {
		console.log(this.datosDialogRecibidos);
		this.crearFormularios();

		if (this.datosDialogRecibidos) {
			if (this.datosDialogRecibidos.persona) {
				this.frmRegistro.patchValue(this.datosDialogRecibidos.persona);
				if (this.datosDialogRecibidos.persona.iPersId) {
					this.mode = 'update';
				}
			}
			if (this.datosDialogRecibidos.tipos_personas) {
				this.dataServidor.tipos_personas = this.datosDialogRecibidos.tipos_personas;
			} else  {
				this.queryService.cargar(this, ['tipos_personas'], {}, false, 'grl').then();
			}
			if (this.datosDialogRecibidos.tipos_Identificaciones) {
				this.dataServidor.tipos_Identificaciones = this.datosDialogRecibidos.tipos_Identificaciones;
			}
			if (this.datosDialogRecibidos.tituloElemento) {
				this.tituloElemento = this.datosDialogRecibidos.tituloElemento;
			}
		} else {
			this.datosDialogRecibidos = {};
		}
		this.cargaInicial();
	}

	crearFormularios() {
		this.frmRegistro = this.fb.group({
			cPersDocumento: ['', [Validators.required]],

			iTipoPersId: ['' , [Validators.required, Validators.pattern('^[0-9]*$')]],
			iTipoIdentId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],

			cPersPaterno: [''],
			cPersMaterno: [''],
			cPersNombre: [''],
			cPersSexo: [''],
			dPersNacimiento: [''],
			iTipoEstCivId: [''],
			cPersFotografia: [''],


			cPersRazonSocialNombre: [''],
			cPersRazonSocialCorto: [''],
			cPersRazonSocialSigla: [''],

			cPersDomicilio: [''],

			iPersId: [''],
		});

	}

	cargaInicial() {
		const solicitudes = [
			'tipos_Identificaciones_personas_naturales',
			'tipos_Identificaciones_personas_juridicas',
			'tipos_estados_civiles',
		];
		this.queryService.cargar(this, solicitudes, {}, false, 'grl').then((valRet) => {
			this.cambiarTipoPersona();
		});
	}
    cambiarTipoIdentificacion(){
        let iTipoIdentId = this.frmRegistro.get('iTipoIdentId').value
        switch(iTipoIdentId){
            case '1':
            case '2':
                this.iPersIdVisible = true
                this.cPersDocumentoVisible = false
            break;
            case '3':
            case '4':
                this.iPersIdVisible = false
                this.cPersDocumentoVisible = true
            break;
        }
        // if(this.frmRegistro.get('iTipoIdentId').value == 4){
        //     this.frmRegistro.get('iPersId').setValue('')
        // } else {
        //     this.frmRegistro.get('cPersDocumento').setValue('');
        // }
    }

	cambiarTipoPersona(){
        this.frmRegistro.get('iPersId').setValue('')
		if (this.frmRegistro.get('iTipoPersId').value == 1) {


            this.dataCompPersona.labelBusquedaPersona = 'Persona Natural';
            this.dataCompPersona.placeholderBusquedaPersona = 'DNI o nombre';
            this.dataCompPersona.tipoPersonaBusquedaPersona = 1;

			this.dataServidor.tipos_Identificaciones = this.dataServidor.tipos_Identificaciones_personas_naturales;
			this.frmRegistro.get('iTipoIdentId').setValue(this.dataServidor.tipos_Identificaciones_personas_naturales[0].iTipoIdentId);

			this.frmRegistro.get('cPersPaterno').setValidators([Validators.required]);
			this.frmRegistro.get('cPersMaterno').setValidators([Validators.required]);
			this.frmRegistro.get('cPersNombre').setValidators([Validators.required]);
			this.frmRegistro.get('cPersSexo').setValidators([Validators.required]);
			this.frmRegistro.get('dPersNacimiento').setValidators([Validators.required]);
			this.frmRegistro.get('iTipoEstCivId').setValidators([Validators.required]);

			this.frmRegistro.get('cPersRazonSocialNombre').setValidators([]);
			this.frmRegistro.get('cPersDomicilio').setValidators([]);
		}
		if (this.frmRegistro.get('iTipoPersId').value == 2) {
            this.dataCompPersona.labelBusquedaPersona = 'Persona Jurídica';
            this.dataCompPersona.placeholderBusquedaPersona = 'Ruc o Razón social';
            this.dataCompPersona.tipoPersonaBusquedaPersona = 2;

			this.dataServidor.tipos_Identificaciones = this.dataServidor.tipos_Identificaciones_personas_juridicas;
			this.frmRegistro.get('iTipoIdentId').setValue(this.dataServidor.tipos_Identificaciones_personas_juridicas[0].iTipoIdentId);

			this.frmRegistro.get('cPersPaterno').setValidators([]);
			this.frmRegistro.get('cPersMaterno').setValidators([]);
			this.frmRegistro.get('cPersNombre').setValidators([]);
			this.frmRegistro.get('cPersSexo').setValidators([]);
			this.frmRegistro.get('dPersNacimiento').setValidators([]);
			this.frmRegistro.get('iTipoEstCivId').setValidators([]);

			this.frmRegistro.get('cPersRazonSocialNombre').setValidators([Validators.required]);
			this.frmRegistro.get('cPersDomicilio').setValidators([]);
		}
		this.frmRegistro.get('cPersPaterno').updateValueAndValidity();
		this.frmRegistro.get('cPersMaterno').updateValueAndValidity();
		this.frmRegistro.get('cPersNombre').updateValueAndValidity();
		this.frmRegistro.get('cPersSexo').updateValueAndValidity();
		this.frmRegistro.get('dPersNacimiento').updateValueAndValidity();
		this.frmRegistro.get('iTipoEstCivId').updateValueAndValidity();

		this.frmRegistro.get('cPersRazonSocialNombre').updateValueAndValidity();
		this.frmRegistro.get('cPersDomicilio').updateValueAndValidity();
	}

    datosPersona(datos){
        if (this.frmRegistro.get('iTipoPersId').value == 1){
            console.log(datos)
            this.frmRegistro.get('cPersDocumento').setValue(datos.cPersDocumento);
            this.frmRegistro.get('cPersPaterno').setValue(datos.cPersPaterno);
            this.frmRegistro.get('cPersMaterno').setValue(datos.cPersMaterno);
            this.frmRegistro.get('cPersNombre').setValue(datos.cPersNombre);
            this.frmRegistro.get('cPersSexo').setValue(datos.cPersSexo);
            this.frmRegistro.get('dPersNacimiento').setValue(datos.dPersNacimiento);
            this.frmRegistro.get('iTipoEstCivId').setValue(datos.iTipoEstCivId);
            this.frmRegistro.get('cPersDomicilio').setValue(datos.cPersDomicilio);
        }else{
            this.frmRegistro.get('cPersDocumento').setValue(datos.cPersDocumento);
            this.frmRegistro.get('cPersDomicilio').setValue(datos.cPersDomicilio);
            this.frmRegistro.get('cPersRazonSocialCorto').setValue(datos.cPersRazonSocialCorto);
            this.frmRegistro.get('cPersRazonSocialNombre').setValue(datos.cPersRazonSocialNombre);
        }
    }

	establecerObligatorios() {

	}

	save() {
		this.frmRegistro.updateValueAndValidity();
		if (this.frmRegistro.valid){
			this.onSubmitReason.emit(this.frmRegistro);
		} else {
			this.funcionesGlobalesService.mensajeToast('error', 'Datos Faltantes', 'Por favor complete los campos faltantes.');
		}
	}

	createCustomer() {
		this.dialogRef.close(this.frmRegistro.value);
	}

	updateCustomer() {
		this.dialogRef.close(this.frmRegistro.value);
	}

	isCreateMode() {
		return this.mode === 'create';
	}

	isUpdateMode() {
		return this.mode === 'update';
	}
}
