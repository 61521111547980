<!--<div class="overflow-y-auto flex-auto h-[calc(100vh-200px)] ">-->

<div class="flex flex-col items-start justify-center gap-2 h-[calc(100vh-200px)] w-full">
    <div class="p-6 flex flex-wrap gap-6 container justify-center items-center">


        <ng-container *ngFor="let itm of (_dataModulos.asObservable() | async)">

            <div *ngIf="itm.iMenuEstado == 1"
                [routerLink]="[itm.cMenuEnlace]" [queryParamsHandling]="'merge'" [queryParams]="{mod: itm.cMenuEnlace}"
                class=" cursor-pointer  hover:bg-primary hover:text-primary-contrast transition duration-400 ease-out-swift relative !w-[180px] !h-[180px] card overflow-hidden flex flex-col items-center justify-center gap-2"
                matRipple
            >
                <div class="p-4 text-center"
                >
                    <!--<img src="http://localhost:8000/img/logos/4/logo-icono.png" class="avatar h-24 w-24 mx-auto" />-->
                    <mat-icon [svgIcon]="itm.cMenuIcono" class="icon-2xl"></mat-icon>

                    <h2 class="title mb-1 mt-3">{{ itm.cMenuNombre }} {{ (itm.cMenuNombrePersonalizado ? (' [' + itm.cMenuNombrePersonalizado + ']') : '') }}</h2>
                    <!--                <div class="body-2 text-secondary flex items-center justify-center">
                                        <mat-icon
                                            class="ltr:mr-1 rtl:ml-1 icon-xs"
                                            svgIcon="mat:business"></mat-icon>
                                        <span>AAA</span>
                                    </div>

                                    <div class="body-2 text-secondary flex items-center justify-center">
                                        <mat-icon
                                            class="ltr:mr-1 rtl:ml-1 icon-xs"
                                            svgIcon="mat:phone"></mat-icon>
                                        <span>BBB</span>
                                    </div>-->

                    <!--                <button
                                        class="absolute top-2 right-2"
                                        mat-icon-button
                                        type="button">
                                        <mat-icon
                                            class="text-amber-600"
                                            svgIcon="mat:star"></mat-icon>
                                        <mat-icon svgIcon="mat:star_border"></mat-icon>
                                    </button>-->
                </div>

                <!--            <div class="bg-app-bar p-2 flex items-center justify-around">
                                <button class="text-secondary" mat-icon-button type="button">
                                    <mat-icon svgIcon="mat:phone"></mat-icon>
                                </button>

                                <button class="text-secondary" mat-icon-button type="button">
                                    <mat-icon svgIcon="mat:mail"></mat-icon>
                                </button>

                                <button class="text-secondary" mat-icon-button type="button">
                                    <mat-icon svgIcon="mat:chat"></mat-icon>
                                </button>
                            </div>-->
            </div>

        </ng-container>


    </div>
</div>
