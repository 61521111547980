import fa5sMapMarkedAlt from '@iconify/icons-fa-solid/map-marked-alt';
import fa5sMale from '@iconify/icons-fa-solid/male';
import fa5sFemale from '@iconify/icons-fa-solid/female';
import fa5sCheckSquare from '@iconify/icons-fa-solid/check-square';
import fa5sSquare from '@iconify/icons-fa-solid/square';
import fa5sCaretDown from '@iconify/icons-fa-solid/caret-down';
import fa5sBoxOpen from '@iconify/icons-fa-solid/box-open';
import fa5sExchangeAlt from '@iconify/icons-fa-solid/exchange-alt';
import fa5sLock from '@iconify/icons-fa-solid/lock';
import fa5sLockOpen from '@iconify/icons-fa-solid/lock-open';
import fa5sUserTie from '@iconify/icons-fa-solid/user-tie';
import fa5sEye from '@iconify/icons-fa-solid/eye';
import fa5sFile from '@iconify/icons-fa-solid/file';
import fa5sFileAlt from '@iconify/icons-fa-solid/file-alt';
import fa5sReply from '@iconify/icons-fa-solid/reply';
import fa5sShare from '@iconify/icons-fa-solid/share';
import fa5sExclamationTriangle from '@iconify/icons-fa-solid/exclamation-triangle';
import fa5sCaretRight from '@iconify/icons-fa-solid/caret-right';
import fa5sFileSignature from '@iconify/icons-fa-solid/file-signature';

export const solid = {
    fa5sMapMarkedAlt,
    fa5sMale,
    fa5sFemale,
    fa5sCheckSquare,
    fa5sSquare,
    fa5sCaretDown,
    fa5sBoxOpen,
    fa5sExchangeAlt,
    fa5sLock,
    fa5sLockOpen,
    fa5sUserTie,
    fa5sEye,
    fa5sFile,
    fa5sFileAlt,
    fa5sReply,
    fa5sShare,
    fa5sExclamationTriangle,
    fa5sCaretRight,
    fa5sFileSignature,
};
