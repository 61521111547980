<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">Notificaciones</div>
      <div class="dropdown-subheading">Tienes {{ (notificacionesService.notificaciones$ | async)?.length }} notificaciones.</div>
    </div>

<!--    <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">-->
<!--      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>-->
<!--    </button>-->
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of (notificacionesService.notificaciones$ | async)"
       [class.read]="notification.leido"
       [routerLink]="notification.link"
       (click)="!notification.link ? notification.onClick(notification, dialog) : false"
       class="notification flex items-center"
       matRipple>
      <mat-icon *ngIf="notification.icono"
              [ngClass]="notification.classIcono"
                [svgIcon]="notification.icono"
                class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label text-xs uppercase">{{ notification.titulo }}</div>
        <div class="notification-label text-xxs flex items-center">
          <div class="flex-1">{{ notification.subTitulo }}</div>
          <div class="flex-none text-right text-indigo-900">
            {{ notification.fecha | relativeDateTime }}
          </div>
        </div>
        <ng-container *ngIf="notification.innerHTML; else sinInnerHTML">
          <div class="notification-description" [innerHTML]="notification.innerHTML(notification)"></div>
        </ng-container>
        <ng-template #sinInnerHTML>
          <div class="notification-description">{{ notification.descripcion }}</div>
        </ng-template>
      </div>
<!--      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>-->
    </a>
  </div>

  <!--<div class="dropdown-footer flex items-center justify-center">
    <button color="primary" mat-button type="button">MARK ALL AS READ</button>
  </div>-->
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:clear_all"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>
