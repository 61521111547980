import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbsService {
	// private _breadCrumbGenerado = new BehaviorSubject<string[]>([]);
	// breadCrumb$ = this._breadCrumbGenerado.asObservable();
	// private _tituloGenerado = new BehaviorSubject('');
	// tituloGenerado$ = this._tituloGenerado.asObservable();

	terminado = false;

	constructor(
		private router: Router,
	) {

		/*
		let nTitulo;
		this.router.events.subscribe(event => {
			// console.warn(event);
			if (this.terminado) {
				this._breadCrumbGenerado.next([]);
			}
			if (event instanceof ActivationEnd) {
				// console.log(event);
				if (event.snapshot.routeConfig.path != ''){
					nTitulo = event.snapshot.routeConfig.data?.titulo || event.snapshot.routeConfig.path;
					nTitulo = nTitulo.replace('-', ' ');
					nTitulo = nTitulo.charAt(0).toUpperCase() + nTitulo.slice(1);
					this._breadCrumbGenerado.next( this._breadCrumbGenerado.getValue().concat([nTitulo.toString()]) );
				}
			}
			this.terminado = (event instanceof NavigationEnd);
		});
		this.breadCrumb$.subscribe(valAct => {
			if (valAct.length > 0) {
				this._tituloGenerado.next(valAct[0]);
			}
		});
		*/
	}
}
