import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface DataFiltroBusquedaActual {
    [key: string]: BehaviorSubject<any>;
}

@Injectable({
    providedIn: 'root',
})

export class FiltroBusquedaService {
    dFiltroBusqueda: DataFiltroBusquedaActual = {};

    _dataFiltroPrincipal = new BehaviorSubject(null);
    dataFiltroPrincipal$ = this._dataFiltroPrincipal.asObservable();
    get datosFiltroHeader(): { datosFiltro?: any, datosFiltroConLabel?: any } {
        if (this.dFiltroBusqueda.filtro_principal) {
            return this.getValue('filtro_principal');
        }
        return null;
    }
    constructor() {

    }
    setValue(idFiltro, val) {
        this.dFiltroBusqueda[idFiltro].next(val);
    }
    getValueEspera(idFiltro): Promise<{ datosFiltro: any, datosFiltroConLabel: any }> {
        return new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                if (this.dFiltroBusqueda[idFiltro]) {
                    resolve(this.dFiltroBusqueda[idFiltro].getValue());
                } else {
                    reject({ datosFiltro: null, datosFiltroConLabel: null });
                }
            }, 15); // 5000 milisegundos = 5 segundos
        });
    }
    getValue(idFiltro): { datosFiltro: any, datosFiltroConLabel: any } {
        if (this.dFiltroBusqueda[idFiltro]){
            return this.dFiltroBusqueda[idFiltro].getValue();
        }
    }
    crearFiltro(idFiltro) {
        this.dFiltroBusqueda[idFiltro] = new BehaviorSubject<any>(null);
    }
}
