import {Component, OnInit} from '@angular/core';
import {appIconsAll} from '@servicios/icons';

@Component({
	selector: 'general-lista-iconos',
	templateUrl: './lista-iconos.component.html',
	styleUrls: ['./lista-iconos.component.scss']
})
export class ListaIconosComponent implements OnInit {
	constructor(
	) {
	}

	ngOnInit(): void {
	}

	protected readonly appIconsAll = appIconsAll;
}
