import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UsuarioService} from '@servicios/usuario.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DerechosResolverGuard implements Resolve<any> {
    constructor(
        private usuarioService: UsuarioService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
        if ((route.routeConfig.path == state.url.split('/').slice(-1).toString()) && route.data['iMenuId']) {
            return this.usuarioService.verificarDerechosMenu(route.data['iMenuId']).pipe(
                map(data => {
                    console.warn(data);
                    const lstDerechos = data;
                    if (Array.isArray(lstDerechos) && lstDerechos.length > 0) {
                        const dRet = {};
                        lstDerechos.forEach(dDerecho => {
                            dRet[dDerecho['cDerechoCodigo']] = dDerecho;
                        });
                        // console.log('DERECHOS ACTUALES: ', dRet);
                        return dRet;
                    }
                    console.log('NO CONTROLA DERECHOS');
                    return [];
                }),
            );
        }
        return undefined;
    }
    resolve22(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        if ((route.routeConfig.path == state.url.split('/').slice(-1).toString()) && route.data['iMenuId']) {
            const va = this.usuarioService.verificarPermisoMenu(route.data['iMenuId']);
            if (va) {
                // console.log(va);
                const lstDerechos: any[] = va['derechos'];
                if (lstDerechos.length > 0) {
                    const dRet = {};
                    lstDerechos.forEach(dDerecho => {
                        dRet[dDerecho['cDerechoCodigo']] = dDerecho;
                    });
                    // console.log('DERECHOS ACTUALES: ', dRet);
                    return dRet;
                }
                console.log('NO CONTROLA DERECHOS');
                return [];
            }
        }
        return undefined;
    }

}
