import {Injectable} from '@angular/core';
import {PdfService} from '@servicios/pdf.service';
import {groupBy, nestGroupsBy, ordenarPorPropiedad, sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {formatDate} from '@angular/common';
import {certificadoCompromisoAnualSaldo, certificadoSecuenciaMetaFaseA4, comprobantesDePagoSiaf, comprobantesPago, consultaComprobantePago, consultaExpedientesColumnaTotalFase, consultaExpedientesGiradosDocumentoCompromiso, CVSMPClasificadorA4, CVSMPFteFtoSecFuncA4, ECMVSMPClasificadorA4, ECMVSMPSecFuncA4, ECVSFG_ActProySecFunc, ECVSFGActProy, ECVSFGAnoejeActProySecFunc, ECVSFGAnoejeActProySecFuncFuenteFinanc, ECVSFGAnoejeActProySecFuncFuenteFinancCOSTO, ECVSFGAnoejeSecFuncFuenteFinancCOSTO, ECVSFGFuentefinanc, ECVSFGFuncionSecFunc, ECVSFGSecFunc, ECVSFGSecFuncFuenteFinanc, ExpedienteDocumento, ExpedienteDocumentoFase, ExpedienteDocumentoFaseCicloFaseB, ExpedienteDocumentoNota, expedienteSecuenciaDocumentoMetaClasificadorMontoFases, expedienteSecuenciaDocumentoMetaFase, ExpedienteSecuenciaFase, ExpedienteSecuenciaFaseNota, fasesExpediente, marcoPresupuestalVsCertificadoVsCompromisoAnual} from './pdf-presupuesto';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {imprimirCelda} from '@JVSoft/services/pdf-make.global.service';

export interface ReportePdfPresupuesto {
    iReportId: number;
    codigo: string;
    text: string;
    value: string;
    reqPath?: string;
    idCheckbox?: string[];
    fnReporte?: string;
    fnParams?: string[];

    esVisible?(itemPadre: any, formValues: any): boolean;
}

export interface CombosPdfPresupuesto {
    label: string;
    formControlName: string;
    idCombo: string;
    labelCombo: string;
    labelTodos?: string;
    data: string | any[];
    dependeDe?: string[];

    esVisible?(itemPadre: any, formValues: any): boolean;
}

export interface OpcionReportePdfPresupuesto {
    text: string;
    opciones: ReportePdfPresupuesto[];
    combos?: CombosPdfPresupuesto[];

    fnModificarTitulo?(itemPadre: any, item: any, formValues: any): string;
}

export interface DataEnviarPdfPresupuesto {
    cAno_eje: string;
    cCodigoCadena: string;
    cCiclo?: string;
    cFase?: string;
    cTipo_operacion?: string;
}


@Injectable({
    providedIn: 'root',
})
export class PdfPresupuestoService extends PdfService {
    opcionesReporte: OpcionReportePdfPresupuesto[] = [
        {
            text: 'Comprobante de Pago',
            opciones: [
                {
                    iReportId: 5,
                    codigo: 'MP01',
                    text: 'Comprobantes de pago',
                    fnReporte: 'consultaComprobantePago',
                    reqPath: 'siaf.Consulta_ExpedientesGirados',
                    value: 'Rpt_Siaf_SEL_Consulta_ComprobantePagoXcCodigoCadena'
                },
                {
                    iReportId: 6,
                    codigo: 'MP02',
                    text: 'Expediente y fases de gasto - Totales (C,D,G,P,R)',
                    fnReporte: 'consultaExpedientesColumnaTotalFase',
                    reqPath: 'siaf.Consulta_Expedientes_ColumnaTotalFase',
                    value: 'Rpt_Siaf_SEL_Consulta_Expedientes_ColumnaTotalFaseXcCodigoCadena'
                },
                {
                    iReportId: 7,
                    codigo: 'MP03',
                    text: 'comprobantes de pago y documento compromiso',
                    fnReporte: 'consultaExpedientesGiradosDocumentoCompromiso',
                    reqPath: 'siaf.Consulta_ExpedientesGirados_DocumentoCompromiso',
                    value: 'Rpt_Siaf_SEL_Consulta_ExpedientesGirados_DocumentoCompromisoXcCodigoCadena'
                },
            ]
        },
        {
            text: 'Detallado de expedientes',
            opciones: [
                {
                    iReportId: 8,
                    codigo: 'MP04',
                    text: 'Registrados por sus fases',
                    fnReporte: 'expedienteSecuenciaDocumentoMetaFase',
                    fnParams: ['cCiclo', 'cFase', 'cTipo_operacion'],
                    reqPath: 'siaf.Expediente_Secuencia_Documento_Meta_Fase',
                    value: 'Rpt_Siaf_SEL_Expediente_Secuencia_Documento_Meta_FaseXcCodigoCadena'
                },
                {
                    iReportId: 9,
                    codigo: 'MP05',
                    text: 'Registrados por sus fases por meses',
                    fnReporte: 'expedienteSecuenciaDocumentoMetaClasificadorMontoFases',
                    reqPath: 'siaf.Expediente_Secuencia_Documento_Meta_Clasificador_Monto_Fases',
                    value: 'Rpt_Siaf_SEL_Expediente_Secuencia_Documento_Meta_Clasificador_Monto_FasesXcCodigoCadena'
                },
                {
                    iReportId: 51,
                    codigo: 'MP06',
                    text: 'Gasto presupuestal vs Tipo Operación X Fases X Sec.Func.',
                    fnReporte: 'consultaExpedientesGiradosDocumentoCompromiso',
                    fnParams: ['cCiclo', 'cFase', 'cTipo_operacion'],
                    reqPath: 'siaf.Consulta_ExpedientesGirados_DocumentoCompromiso',
                    value: 'Rpt_Siaf_SEL_Expediente_Secuencia_Documento_Meta_Fase_A2XcCodigoCadena'
                },
            ],
            combos: [
                {
                    label: 'Ciclo',
                    formControlName: 'cCiclo',
                    data: 'siaf.ciclo',
                    idCombo: 'Codigo',
                    labelCombo: 'Descripcion'
                },
                {
                    label: 'Fases',
                    formControlName: 'cFase',
                    data: 'siaf.fase',
                    idCombo: 'Codigo',
                    labelCombo: 'Descripcion',
                    labelTodos: 'Todas las Fases',
                    dependeDe: ['cCiclo']
                },
                {
                    label: 'T.Operación',
                    formControlName: 'cTipo_operacion',
                    data: 'siaf.Tipo_Operacion',
                    idCombo: 'Tipo_operacion',
                    labelCombo: 'cNombre_TipoOperacion',
                    labelTodos: 'Todas los T.O.',
                    dependeDe: ['cCiclo']
                },
            ]
        },
        {
            text: 'Clasificadores por fases',
            opciones: [
                {
                    iReportId: 10,
                    codigo: 'MP07',
                    text: 'gasto presupuestal vs Fases X Act. Proy.,Sec.Func. y Clasificador',
                    fnReporte: 'ECVSFGAnoejeActProySecFuncFuenteFinancCOSTO',
                    idCheckbox: ['Act_proy'],
                    fnParams: ['cCiclo', 'cFase'],
                    reqPath: 'siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ_COSTO',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ_COSTOXcCodigoCadena'
                },
                {
                    iReportId: 11,
                    codigo: 'MP09',
                    text: 'gasto presupuestal vs Fases X Sec.Func. y Clasificador',
                    fnReporte: 'ECVSFGAnoejeSecFuncFuenteFinancCOSTO',
                    idCheckbox: ['Sec_func'],
                    fnParams: ['cCiclo', 'cFase'],
                    reqPath: 'siaf.ECVSFG_Ano_eje_Sec_Func_Fuente_Financ_COSTO',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Ano_eje_Sec_Func_Fuente_Financ_COSTOXcCodigoCadena'
                },
            ],
            combos: [
                {
                    label: 'Ciclo',
                    formControlName: 'cCiclo',
                    data: 'siaf.ciclo',
                    idCombo: 'Codigo',
                    labelCombo: 'Descripcion'
                },
                {
                    label: 'Por T.O.',
                    formControlName: 'cFase',
                    data: 'siaf.fase',
                    idCombo: 'Codigo',
                    labelCombo: 'Descripcion',
                    labelTodos: 'Todas las Fases'
                },
            ]
        },
        {
            text: 'Totalizados mensualmente por certificado y fases de gasto',
            fnModificarTitulo: (itemPadre, item, formValues) => {
                let nText = item.text;
                const fcCertificado = dataEnLista(itemPadre.combos, 'formControlName', 'certificadoFase');
                if (fcCertificado && formValues.certificadoFase) {
                    const dataOpcion = dataEnLista(fcCertificado.data, 'Codigo', formValues.certificadoFase);
                    if (dataOpcion) {
                        nText = item.text.replace(/<strong>(.*)<\/strong>/g, `<strong>${dataOpcion.Descripcion}</strong>`);
                    }
                }
                return nText;
            },
            opciones: [
                {
                    iReportId: 12,
                    codigo: 'MP10',
                    text: 'Reporte Marco Vs <strong></strong> X Sec. Func',
                    fnReporte: 'CVSMPFteFtoSecFuncA4',
                    idCheckbox: ['Sec_func'],
                    reqPath: 'siaf.CVSMP_FteFto_Sec_Func',
                    value: 'Rpt_Siaf_SEL_CVSMP_FteFto_Sec_Func_A4XcCodigoCadena',
                    esVisible(itemPadre: any, formValues: any): boolean {
                        // es CER
                        this.text = itemPadre.fnModificarTitulo(itemPadre, this, formValues);
                        return (formValues.certificadoFase && formValues.certificadoFase == 'CER');
                    }
                },
                {
                    iReportId: 13,
                    codigo: 'MP11',
                    text: 'Reporte Marco Vs <strong></strong> X Sec. Func X Clasificador.',
                    fnReporte: 'CVSMPClasificadorA4',
                    idCheckbox: ['Sec_func'],
                    reqPath: 'siaf.CertificadoVsMarcoPresupuestal',
                    value: 'Rpt_Siaf_SEL_CVSMP_Clasificador_A4XcCodigoCadena',
                    esVisible(itemPadre: any, formValues: any): boolean {
                        // es CER
                        this.text = itemPadre.fnModificarTitulo(itemPadre, this, formValues);
                        return (formValues.certificadoFase && formValues.certificadoFase == 'CER');
                    }
                },
                {
                    iReportId: 14,
                    codigo: 'MP12',
                    text: 'Reporte Marco Vs <strong></strong> X Sec. Func',
                    fnReporte: 'ECMVSMPSecFuncA4',
                    fnParams: ['certificadoFase'],
                    reqPath: 'siaf.CertificadoVsMarcoPresupuestal',
                    value: 'Rpt_Siaf_SEL_ECMVSMP_SecFunc_A4XcCodigoCadena',
                    esVisible(itemPadre: any, formValues: any): boolean {
                        // no es CER
                        this.text = itemPadre.fnModificarTitulo(itemPadre, this, formValues);
                        return (formValues.certificadoFase && formValues.certificadoFase != 'CER');
                    }
                },
                {
                    iReportId: 15,
                    codigo: 'MP13',
                    text: 'Reporte Marco Vs <strong></strong> X Sec. Func X Clasificador.',
                    fnReporte: 'ECMVSMPClasificadorA4',
                    fnParams: ['certificadoFase'],
                    reqPath: 'siaf.ECMVSMP_Clasificador',
                    value: 'Rpt_Siaf_SEL_ECMVSMP_Clasificador_A4XcCodigoCadena',
                    esVisible(itemPadre: any, formValues: any): boolean {
                        // no es CER
                        this.text = itemPadre.fnModificarTitulo(itemPadre, this, formValues);
                        return (formValues.certificadoFase && formValues.certificadoFase != 'CER');
                    }
                },
            ],
            combos: [
                {
                    label: 'Certificado y fases de gasto', formControlName: 'certificadoFase', data: [
                        {Codigo: 'CER', Descripcion: 'Certificado'},
                        {Codigo: 'C', Descripcion: 'Compromiso'},
                        {Codigo: 'D', Descripcion: 'Devengado'},
                        {Codigo: 'G', Descripcion: 'Girado'},
                        {Codigo: 'P', Descripcion: 'Pagado'},
                    ], idCombo: 'Codigo', labelCombo: 'Descripcion'
                },
            ]
        },
        {
            text: 'Presupuestales',
            opciones: [
                {
                    iReportId: 16,
                    codigo: 'MP14',
                    text: 'Gasto Presupuestal VS Fases X Sec.Func.',
                    fnReporte: 'ECVSFGSecFunc',
                    reqPath: 'siaf.ECVSFG_Sec_Func',
                    value: 'Rpt_Siaf_SEL_ECVSFG_SecFuncXcCodigoCadena'
                },
                {
                    iReportId: 17,
                    codigo: 'MP15',
                    text: 'Gasto Presupuestal VS Fases X Sec.Func. y Fte.Fto.',
                    fnReporte: 'ECVSFGSecFuncFuenteFinanc',
                    reqPath: 'siaf.ECVSFG_Sec_Func_Fuente_Financ',
                    value: 'Rpt_Siaf_SEL_ECVSFG_SecFunc_Fuente_FinancXcCodigoCadena'
                },
                {
                    iReportId: 18,
                    codigo: 'MP16',
                    text: 'Gasto Presupuestal VS Fases X Act. Proy.',
                    fnReporte: 'ECVSFGActProy',
                    idCheckbox: ['Act_proy'],
                    reqPath: 'siaf.ECVSFG_Act_Proy',
                    value: 'Rpt_Siaf_SEL_ECVSFG_ActProyXcCodigoCadena'
                },
                {
                    iReportId: 19,
                    codigo: 'MP17',
                    text: 'Gasto Presupuestal VS Fases X Act. Proy., Año y Sec. Func.',
                    fnReporte: 'ECVSFG_ActProySecFunc',
                    idCheckbox: ['Act_proy'],
                    reqPath: 'siaf.ECVSFG_Act_Proy_Sec_Func',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Act_Proy_Sec_FuncXcCodigoCadena'
                },
                {
                    iReportId: 20,
                    codigo: 'MP18',
                    text: 'Gasto Presupuestal VS Fases X Act. Proy. y Sec. Func.',
                    fnReporte: 'ECVSFGAnoejeActProySecFunc',
                    idCheckbox: ['Act_proy'],
                    reqPath: 'siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_FuncXcCodigoCadena'
                },
                {
                    iReportId: 21,
                    codigo: 'MP19',
                    text: 'Gasto Presupuestal VS Fases X Act. Proy. y Sec. Func. y Clasificadores',
                    fnReporte: 'ECVSFGAnoejeActProySecFuncFuenteFinanc',
                    idCheckbox: ['Act_proy'],
                    reqPath: 'siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_FinancXcCodigoCadena'
                },
                {
                    iReportId: 22,
                    codigo: 'MP20',
                    text: 'Gasto Presupuestal VS Fases X Función',
                    fnReporte: 'ECVSFGFuncionSecFunc',
                    reqPath: 'siaf.ECVSFG_Funcion_Sec_Func',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Funcion_Sec_FuncXcCodigoCadena'
                },
                {
                    iReportId: 23,
                    codigo: 'MP21',
                    text: 'Gasto Presupuestal X Sec. Func. y rubro',
                    fnReporte: 'ECVSFGFuentefinanc',
                    reqPath: 'siaf.ECVSFG_Fuente_financ',
                    value: 'Rpt_Siaf_SEL_ECVSFG_Fuente_financXcCodigoCadena'
                },
            ],
        },
        {
            text: 'Certificaciones',
            opciones: [
                {
                    iReportId: 24,
                    codigo: 'MP22',
                    text: 'Certificaciones Registradas',
                    fnReporte: 'certificadoSecuenciaMetaFaseA4',
                    reqPath: 'siaf.Certificado_Secuencia_Meta_Fase',
                    value: 'Rpt_Siaf_SEL_Certificado_Secuencia_Meta_Fase_A4XcCodigoCadena'
                },
                {
                    iReportId: 25,
                    codigo: 'MP23',
                    text: 'Marco Presupuestal, Certificado y Compromiso Anual',
                    fnReporte: 'marcoPresupuestalVsCertificadoVsCompromisoAnual',
                    idCheckbox: ['Sec_func'],
                    reqPath: 'siaf.MarcoPresupuestalVsCertificadoVsCompromisoAnual',
                    value: 'Rpt_Siaf_SEL_MarcoPresupuestalVsCertificadoVsCompromisoAnualXcCodigoCadena'
                },
                {
                    iReportId: 26,
                    codigo: 'MP24',
                    text: 'Certificado, Compromiso Anual y Saldo',
                    fnReporte: 'certificadoCompromisoAnualSaldo',
                    reqPath: 'siaf.Certificado_CompromisoAnual_Saldo',
                    value: 'Rpt_Siaf_SEL_Certificado_CompromisoAnual_SaldoXcCodigoCadena'
                },
            ],
        },
    ];

    desplazamientoBienes(idsEnviar) {
        this.queryService.cargar(this, ['desplazamientos_bienes_detalles_presentacion'], {cCodigoCadena: idsEnviar}).then((valRetorno) => {
            console.log(valRetorno);
            const resultadosGenerales = valRetorno['desplazamientos_bienes_detalles_presentacion'];
            const datosGenerales = resultadosGenerales[0];


            this.reiniciarOpciones({
                margenesHoja: [40, 80, 40, 40],
                orientacion: 'landscape',
                tamanioFuenteExtra: 1,
                customDefTableHeader: this.headerTableEntidad([
                    this.imprimirCelda('CARGO PERSONAL POR ASIGNACIÓN DE BIENES EN USO', null, {
                        fontSize: 13,
                        bold: true
                    }),
                    this.imprimirCelda('', null, {fontSize: 8, bold: true}),
                ]),
            });


            const groups = nestGroupsBy(resultadosGenerales, ['iDesplazaBienId']);
            console.log(groups);

            const cuerpoPdf = [];
            let pag = 0;
            Object.values(groups).forEach((secGrupo: any[]) => {

                if (pag > 0) {
                    cuerpoPdf.push({
                        text: '',
                        pageBreak: 'before',
                    });
                }


                const numerosDocumentos = [];
                const fnDocNum = (concepto, numero) => {
                    return [
                        this.imprimirCelda(concepto, null, {fontSize: 7, bold: true/*, alignment: 'center'*/}),
                        this.imprimirCelda(numero, null, {fontSize: 8, alignment: 'center'}),
                    ];
                };
                // numerosDocumentos.push(fnDocNum('PÁGINA', '##pagina##/##total_paginas##'));
                numerosDocumentos.push(fnDocNum('NÚMERO', [secGrupo[0]['iDesplazaBienNumero'], secGrupo[0]['iDesplazaBienYear']].join(' - ')));
                numerosDocumentos.push(fnDocNum('FECHA', formatDate(secGrupo[0]['dDesplazaBienEmision'], 'dd/MM/yyyy', 'es-PE')));

                cuerpoPdf.push(
                    {
                        margin: [0, 10, 30, 0],
                        table: {
                            widths: ['auto', '*'],
                            body: numerosDocumentos,
                        },
                        absolutePosition: {x: 700, y: 25}
                    }
                );


                const tablaTitulos = [
                    [
                        this.imprimirCelda('DATOS A QUIEN SE ASIGNA', null, {
                            style: 'thSmall',
                            colSpan: 4,
                            alignment: 'center'
                        }),
                        {},
                        {},
                        {},
                        this.imprimirCelda('DATOS DE LA UBICACIÓN', null, {
                            style: 'thSmall',
                            colSpan: 2,
                            alignment: 'center'
                        }),
                        {},
                    ],
                    [
                        this.imprimirCelda('TRABAJADOR', null, {style: 'thSmall', alignment: 'left'}),
                        this.imprimirCelda(secGrupo[0]['cPersRecibeDocumento'] + ' - ' + secGrupo[0]['cPersRecibeNombreLargo'], null, {
                            style: 'tdSmall',
                            colSpan: 3
                        }),
                        {},
                        {},
                        this.imprimirCelda('ESTABLECIMIENTO', null, {style: 'thSmall', alignment: 'left'}),
                        this.imprimirCelda(secGrupo[0]['cEstablecimientoRecibeNombreLargo'], null, {style: 'tdSmall'}),
                    ],
                    [
                        this.imprimirCelda('PISO', null, {style: 'thSmall', alignment: 'left'}),
                        this.imprimirCelda(secGrupo[0]['cPisoNombre'], null, {style: 'tdSmall', colSpan: 3}),
                        {},
                        {},
                        this.imprimirCelda('DEPENDENCIA', null, {style: 'thSmall', alignment: 'left'}),
                        this.imprimirCelda(secGrupo[0]['cDepenRecibeNombre'], null, {style: 'tdSmall'}),
                    ],
                    [
                        this.imprimirCelda('', null, {style: 'thSmall', alignment: 'left'}),
                        this.imprimirCelda('', null, {style: 'tdSmall', colSpan: 3}),
                        {},
                        {},
                        this.imprimirCelda('UBICACION FISICA', null, {style: 'thSmall'}),
                        this.imprimirCelda(secGrupo[0]['cUbicaFisicaNombre'], null, {style: 'tdSmall'}),
                    ],
                ];
                cuerpoPdf.push(
                    {
                        margin: [0, 0, 0, 10],
                        table: {
                            headerRows: 1,
                            widths: this.anchoCols(tablaTitulos, [1]),
                            body: tablaTitulos,
                        },
                    }
                );


                const camposPrincipal: ColumnaTabla<any>[] = [
                    {label: 'Código Interno', property: 'cBienCodigoInterno', type: 'text'},
                    {label: 'Código', property: 'cCodigoBien', type: 'text'},
                    {label: 'Nombre', property: 'cBienNombreLargo', type: 'text'},
                    {label: 'Estado', property: 'cEstConBienNombre', type: 'text'},
                    {label: 'Marca', property: 'cBienMarca', type: 'text'},
                    {label: 'Modelo', property: 'cBienModelo', type: 'text'},
                    {label: 'Tipo', property: 'cBienTipo', type: 'text'},
                    {label: 'Color', property: 'cBienColor', type: 'text'},
                    {label: 'Serie', property: 'cBienSerie', type: 'text'},
                    {label: 'Longitud', property: 'cBienLongitud', type: 'text'},
                    {label: 'Altura', property: 'cBienAltura', type: 'text'},
                    {label: 'Ancho', property: 'cBienAncho', type: 'text'},
                    {label: 'Características', property: 'cBienCaracteristicas', type: 'text'},
                    {label: 'Observación', property: 'cDesplazaBienDetObs', type: 'text'},
                ];
                console.log(secGrupo);

                cuerpoPdf.push(this.generarDesdeMantenimiento({
                    titulos: camposPrincipal,
                    contenido: secGrupo,
                    numeracion: true,
                    idxResto: [3],
                    margin: [0, 0, 0, 5],
                    // contenido: retDet['siaf.Secuencia_Documento_Fase']
                }));

                /*
                                const tablaVistos = {
                                    margin: [0, 30, 0, 0],
                                    alignment: 'center',
                                    table: {
                                        widths: ['*', '*'],
                                        body: [
                                            [
                                                this.imprimirCelda('_______________________________________\n FIRMA Y SELLO DEL COTIZADOR', null, {
                                                    alignment: 'center',
                                                    bold: false
                                                }),
                                                this.imprimirCelda('_______________________________________\n FIRMA Y SELLO DEL PROVEEDOR', null, {
                                                    alignment: 'center',
                                                    bold: false
                                                }),
                                            ]
                                        ]
                                    },
                                    layout: 'noBorders',
                                };
                                cuerpoPdf.push(tablaVistos);*/

                cuerpoPdf.push({
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                this.imprimirCelda('\n\n\n\n' + 'Firma y Sello', null, {
                                    style: 'tdSmall',
                                    alignment: 'center'
                                }),
                                this.imprimirCelda('\n\n\n\n' + 'Firma y Sello', null, {
                                    style: 'tdSmall',
                                    alignment: 'center'
                                }),
                                this.imprimirCelda('\n\n\n\n' + 'Firma y Sello', null, {
                                    style: 'tdSmall',
                                    alignment: 'center'
                                }),
                            ],
                            [
                                this.imprimirCelda('JEFE DE CONTROL PATRIMONIAL', null, {style: 'thSmall'}),
                                this.imprimirCelda('JEFE DE DEPENDENCIA', null, {style: 'thSmall'}),
                                this.imprimirCelda('TRABAJADOR RESPONSABLE DE LOS BIENES', null, {style: 'thSmall'}),
                            ],
                            [
                                this.imprimirCelda(
                                    'NOTA: El usuario es responsable del buen uso de los bienes detallados en la ' +
                                    'presente y en caso de pérdidas, extravíos o deterioro, estos serán repuestos por el usuario, ' +
                                    'cualquier movimiento de los bienes asignados dentro o fuera de la entidad, deberá ser ' +
                                    'comunicado al encargado de Control Patrimonial BAJO RESPONSABILIDAD.', null, {
                                        colSpan: 3,
                                        border: [false, true, false, false],
                                        style: 'tdSmall'
                                    }),
                                this.imprimirCelda('', null, {style: 'thSmall'}),
                                this.imprimirCelda('', null, {style: 'thSmall'}),
                            ],
                        ],
                    },
                    absolutePosition: {x: 50, y: 480}
                });
                pag++;
            });


            this.finalizarCreacionPdf(cuerpoPdf);

        });


        // this.gptPDF.listaRegular('GASTOS POR ' + (this.tipo == 0 ? 'TAREA' : 'META') + '\nGASTOS SIAF', this.columnasPrincipal, dataImprimir, [
        // 	{ campo: 'detalle', titulos: this.columnasPrincipalDetalle, label: {text: 'DETALLE', bold: true, fontSize: 10} },
        // ]);
    }

    certificados(dataRec) {

        this.queryService.cargar(this, ['bud.certificados_detalles_presentacion'], {
            cCodigoCadena: dataRec,
        }).then((valRetorno) => {
            console.log(valRetorno);
            let resultadosGenerales = valRetorno['bud.certificados_detalles_presentacion'];

            resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'iCertificadoId');

            const agrupadoCertificadoId = groupBy(resultadosGenerales, (obj) => {
                return obj.iCertificadoId;
            });

            let cuerpoPdf = [];
            let pag = 0;
            Object.keys(agrupadoCertificadoId).forEach((secCertificadoId) => {
                if (pag > 0) {
                    cuerpoPdf.push({
                        text: '',
                        pageBreak: 'before',
                    });
                }

                const dataGeneral = agrupadoCertificadoId[secCertificadoId][0];

                let dataTipo: {
                    verbo: string,
                    titulo: string,
                    iTipoModiCertId?: number,
                    cTipoModiCertNombre?: string,
                };
                switch (dataGeneral['iTipoModiCertId'] * 1) {
                    case 1:
                        dataTipo = {verbo: 'certificar', titulo: 'CERTIFICACIÓN DE CRÉDITO PRESUPUESTARIO'};
                        break;
                    case 2:
                        dataTipo = {verbo: 'ampliar', titulo: 'AMPLIACIÓN DE CERTIFICACIÓN DE CRÉDITO PRESUPUESTARIO'};
                        break;
                    case 3:
                        dataTipo = {verbo: 'rebajar', titulo: 'REBAJA DE CERTIFICACIÓN DE CRÉDITO PRESUPUESTARIO'};
                        break;
                }
                dataTipo = {
                    ...dataTipo,
                    ...{
                        iTipoModiCertId: dataGeneral['iTipoModiCertId'],
                        cTipoModiCertNombre: dataGeneral['cTipoModiCertNombre'],
                    }
                };

                const camposPrincipal: ColumnaTabla<any>[] = [
                    {label: 'CLASIFICADOR DE GASTO', property: 'cEspeDetCodigo', type: 'text'},
                    {label: 'NOMBRE DEL CLASIFICADOR', property: 'cEspeDetNombre', type: 'text'},
                    // {label: 'MONTO DE ', property: 'nCertificadoDetImporte', type: 'money'},
                    {label: 'MONTO A ' + dataTipo.verbo.toUpperCase(), property: 'nCertificadoDetImporte', type: 'money'},
                ];

                this.reiniciarOpciones({
                    margenesHoja: [70, 120, 70, 40],
                    tamanioFuenteExtra: 1,
                    customDefTableHeader: [
                        {
                            margin: [70, 15, 70, 0],
                            // margin: [15, 15, 15, 0],
                            table: {
                                widths: [100, '*', 150],
                                headerRows: 0,
                                body: [
                                    [
                                        this.logotipoHeader(),
                                        {
                                            margin: [0, 30, 0, 0],
                                            alignment: 'center',
                                            stack: [
                                                this.imprimirCelda('SOLICITUD', null, {fontSize: 9, bold: true}),
                                                this.imprimirCelda(dataTipo.titulo.toUpperCase(), null, {
                                                    fontSize: 8,
                                                    bold: true
                                                }),
                                                this.imprimirCelda('N°: ' + dataGeneral['iCertificadoNumero'] + ' - ' + dataGeneral['iCertificadoYear'], null, {fontSize: 8, bold: true}),
                                                this.imprimirCelda('EJERCICIO FISCAL' + '-' + dataGeneral['iCertificadoYear'], null, {fontSize: 8, bold: true}),
                                                this.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
                                            ]
                                        },
                                        {
                                            margin: [0, 0, 0, 0],
                                            table: {
                                                widths: ['auto', '*', 50],
                                                headerRows: 0,
                                                body: [
                                                    [
                                                        imprimirCelda('RECEPCION', null, {
                                                            colSpan: 3,
                                                            alignment: 'center',
                                                            style: 'tdSmall',
                                                            bold: true
                                                        }),
                                                        {},
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('Fecha', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(new Date(), 'fec', {style: 'tdSmall', italic: true}),
                                                        imprimirCelda('', null, {style: 'tdSmall', rowSpan: 5}),
                                                    ],
                                                    [

                                                        imprimirCelda('N° ' + (dataTipo.iTipoModiCertId == 1 ? 'RCP' : 'SAF'), null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda('', null, {style: 'tdSmall', italic: true, alignment: 'center'}),
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('FTE.FTO', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(dataGeneral['cFteFto'], null, {style: 'tdSmall', italic: true, alignment: 'center'}),
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('RUBRO', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(dataGeneral['cRubro'], null, {style: 'tdSmall', italic: true, alignment: 'center'}),
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('T.R.', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(dataGeneral['cTipo_recurso'], null, {style: 'tdSmall', italic: true, alignment: 'center'}),
                                                        {},
                                                    ],
                                                    [
                                                        imprimirCelda('', null, {
                                                            border: [false, false, false, false],
                                                            margin: [0, -7, 0, 0]
                                                        }),
                                                        imprimirCelda('', null, {
                                                            border: [false, false, false, false],
                                                            margin: [0, -7, 0, 0]
                                                        }),
                                                        imprimirCelda('', null, {
                                                            border: [false, false, false, false],
                                                            margin: [0, -7, 0, 0]
                                                        }),
                                                    ],
                                                    [
                                                        imprimirCelda('', null, {
                                                            style: 'tdSmall',
                                                            bold: true,
                                                            border: [false, false, false, false],
                                                        }),
                                                        imprimirCelda('CERTIFICADO N°', null, {style: 'tdSmall', italic: true}),
                                                        imprimirCelda(dataGeneral['cCertificadoSiaf'], null, {
                                                            style: 'tdSmall',
                                                            bold: true,
                                                            fontSize: 10,
                                                            alignment: 'center'
                                                        }),
                                                    ],

                                                ]
                                            },
                                            layout: {
                                                defaultBorder: true,
                                            }
                                        },
                                    ],
                                ]
                            },
                            layout: {
                                defaultBorder: false,
                            }
                        },
                    ],

                });

                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['auto', 'auto', '*'],
                        headerRows: 0,
                        body: [
                            [
                                imprimirCelda('DEPARTAMENTO', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(dataGeneral['cDptoCodigo'] + ' ' + dataGeneral['cDptoNombre'], null, {style: 'tdSmall', fontSize: 7}),
                            ],
                            [
                                imprimirCelda('PROVINCIA', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(dataGeneral['cPrvnCodigo'] + ' ' + dataGeneral['cPrvnNombre'], null, {style: 'tdSmall', fontSize: 7}),
                            ],
                            [
                                imprimirCelda('DISTRITO', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(dataGeneral['cDsttCodigo'] + ' ' + dataGeneral['cDsttNombre'], null, {style: 'tdSmall', fontSize: 7}),
                            ],
                            [
                                imprimirCelda('PLIEGO', null, {style: 'tdSmall', bold: true, fontSize: 7}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(dataGeneral['cPliego'], null, {
                                    style: 'tdSmall',
                                    fontSize: 7
                                }),
                            ],

                        ]
                    },
                    layout: {
                        hLineWidth(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 0;
                        },
                        vLineWidth(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                        },
                        hLineColor(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                });
                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['auto', 'auto', 'auto', '*'],
                        headerRows: 0,
                        body: [
                            [
                                imprimirCelda('SEC.FUNC/META', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cSecFuncCodigo'], null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(dataGeneral['cSecFuncNombre'], null, {style: 'tdSmall'}),
                            ],
                            [
                                imprimirCelda('TIPO DE COSTO', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall'}),
                                imprimirCelda(dataGeneral['cClaseTareaCodigo'], null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(dataGeneral['cClaseTareaNombre'], null, {style: 'tdSmall', fontSize: 7}),
                            ],
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                    }
                });

                const tablaGenerada = this.generarDesdeMantenimiento({
                    titulos: camposPrincipal,
                    // contenido: ordenarPorPropiedad(agrupadoNotaId[secNotaId][0], 'cSecFuncCodigo'),
                    contenido: agrupadoCertificadoId[secCertificadoId],
                    numeracion: true,
                    idxResto: [2],
                    // margin: [0, 0, 0, 5],
                    margin: [50, 30, 50, 40],
                    separado: true,
                });

                const sumaObj = sumarObjetos(agrupadoCertificadoId[secCertificadoId], [
                    'nCertificadoDetImporte',
                ]);

                cuerpoPdf.push({
                    margin: [0, 5, 0, 10],
                    table: {
                        dontBreakRows: true,
                        headerRows: 2,
                        widths: this.anchoCols(tablaGenerada['cuerpo'], [1]),
                        body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
                            [
                                imprimirCelda('TOTAL', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 3}),
                                {}, {},
                                imprimirCelda(sumaObj['nCertificadoDetImporte'], 'money', {bold: true, style: 'thSmall',  alignment: 'right'}),
                            ],
                        ]),
                    }
                });

                const detalleTablaSustento: {heights?: number[], body?: any[]} = {
                    heights: [50],
                    body: [
                        [
                            imprimirCelda('SUSTENTO Y/O JUSTIFICACION DE LA SOLICITUD:\n' + (dataGeneral['cCertificadoObs'] == null ? '' : dataGeneral['cCertificadoObs']), null, {
                                style: 'tdSmall',
                                fontSize: 7,
                                bold: true
                            }),
                        ]
                    ]
                };
                if (dataTipo.iTipoModiCertId == 1) {
                    detalleTablaSustento.heights.push(30);
                    detalleTablaSustento.body.push([
                        imprimirCelda('* Solo para el caso de variacion de montos; llenado por la Oficina administrativa correspondiente', null, {style: 'tdSmall', fontSize: 7}),
                    ]);
                }


                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['*'],
                        headerRows: 0,
                        heights: detalleTablaSustento.heights,
                        body: detalleTablaSustento.body
                    },
                    layout: {
                        hLineWidth(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : (dataTipo.iTipoModiCertId == 1 ? 2 : 0);
                        },
                        vLineWidth(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : (dataTipo.iTipoModiCertId == 1 ? 2 : 0);
                        },
                        hLineColor(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : (dataTipo.iTipoModiCertId == 1 ? 'black' : 'gray');
                        },
                        vLineColor(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                });

                if (dataTipo.iTipoModiCertId != 1) {
                    cuerpoPdf.push({
                        margin: [0, 0, 0, 15],
                        table: {
                            // widths: ['*'],
                            headerRows: 0,
                            body: [
                                [
                                    imprimirCelda((dataTipo.iTipoModiCertId == 2 ? '* El llenado del formato es obligatorio\n' : '') +
                                        '* Solo para el caso de variacion de montos; llenado por la Oficina administrativa correspondiente', null, {
                                        style: 'tdSmall',
                                        bold: true
                                    }),
                                ],
                            ]
                        },
                        layout: {
                            defaultBorder: false,
                        }
                    });
                }

                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['*'],
                        headerRows: 0,
                        heights: [70],
                        body: [
                            [
                                imprimirCelda('* LAS FIRMAS SE VERIFICARAN SEGÚN CADA UNIDAD ORGANICA', null, {
                                    style: 'tdSmall',
                                    bold: true
                                }),

                                // this.imprimirCelda('\n\n\n\n_______________________________________\n ELABORADOR \n RESIDENTE RESPONSABLE\n\n', 'texto', {
                                //     alignment: 'center',
                                //     bold: false
                                // }),
                                // this.imprimirCelda('\n\n\n\n_______________________________________\n REVISADO YA APROBADO POR \n SUPERVISOR/INSPECTOR\n\n', 'texto', {
                                //     alignment: 'center',
                                //     bold: false
                                // }),
                                // this.imprimirCelda('\n\n\n\n_______________________________________\n REVISADO Y CONFORME POR \n JEFE INMEDIATO\n\n', 'texto', {
                                //     alignment: 'center',
                                //     bold: false
                                // }),

                            ],
                        ]
                    },
                    layout: {
                        hLineWidth(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 0;
                        },
                        vLineWidth(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                        },
                        hLineColor(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                });


                if (Object.keys(agrupadoCertificadoId).length > 1){
                    this.finalizarCreacionPdf(cuerpoPdf, 'download');
                    cuerpoPdf = [];
                }
                else {
                    this.finalizarCreacionPdf(cuerpoPdf);
                }






                pag++;
            });

            // this.finalizarCreacionPdf(cuerpoPdf);
        });
    }

    rap(dataRec){
        this.queryService.cargar(this, [
            'rap_detalles_presentacion',
            'tareas_fftred',
            'tipos_documentos_presentacion',
        ], {iMetaId: dataRec} ).then((retDet) => {
            console.log(retDet);

            const resultadosGenerales = retDet['rap_detalles_presentacion'];
            const dataTareas = retDet['tareas_fftred'];
            const dataTipoDocumentos = retDet['tipos_documentos_presentacion'];

            this.reiniciarOpciones({
                margenesHoja: [40, 130, 40, 40],
                // orientacion: 'landscape',
                tamanioFuenteExtra: 1,
                customDefTableHeader: [
                    {
                        margin: [5, 5, 5, 0],
                        table: {
                            widths: [150, '*', 150],
                            headerRows: 0,
                            body: [
                                [
                                    this.logotipoHeader(),
                                    {
                                        margin: [0, 40, 0, 0],
                                        alignment: 'center',
                                        stack: [
                                            this.imprimirCelda('RAP - COMPROMISOS 2022', null, {
                                                fontSize: 13,
                                                bold: true
                                            }),
                                            this.imprimirCelda('POR TIPO DE COSTO Y CLASIFICADOR', null, {fontSize: 12, bold: true}),

                                        ]
                                    },
                                    {},
                                ],
                            ]
                        },
                        layout: {
                            defaultBorder: false,
                        }
                    },
                    {
                        margin: [40, 10, 40, 0],
                        table: {
                            widths: ['auto', '*'],
                            headerRows: 0,
                            body: [
                                [
                                    imprimirCelda('FTE.FTO.', null, {
                                        style: 'tdSmall',
                                        fontSize: 7,
                                        bold: true,
                                    }),
                                    imprimirCelda('5.18.P - REGALIAS MINERAS', null, {
                                        style: 'tdSmall',
                                        fontSize: 7,
                                        italic: true,
                                    }),
                                ],
                                [
                                    imprimirCelda('META', null, {
                                        style: 'tdSmall',
                                        fontSize: 7,
                                        bold: true,
                                    }),
                                    imprimirCelda(' 0154 - 3999999 - MANTENIMIENTO PERIÓDICO DE LOS PRINCIPALES CAMINOS', null, {
                                        style: 'tdSmall',
                                        fontSize: 7,
                                        italic: true,
                                    }),
                                ],
                            ]
                        },

                    },
                ],
            });

            const cuerpoPdf = [];
            const camposPrincipal: ColumnaTabla<any>[] = [
                {label: 'N° RAP', property: 'iRegAfecPresupDetId', type: 'text'},
                {label: 'FECHA', property: 'dRegAfecPresupFechaDocumento', type: 'date'},
                {label: 'N° SIAF', property: 'iNroExpedienteSiaf', type: 'text'},
                {label: 'COD.', property: 'cTipoRecursoCodigo', type: 'text'},
                {label: 'N° DOC', property: 'cRegAfecPresupDocumentoNumero', type: 'text'},
                {label: 'PROVEEDOR/REFERENCIA', property: 'cNombrePersona', type: 'text'},
                {label: 'S.T.C.', property: 'cTipoTareaCodigo', type: 'text'},
                {label: 'ESP. GASTO', property: 'cEspeDetCodigo', type: 'text'},
                {label: 'IMPORTE', property: 'nRegAfecPresupDetImporte', type: 'number'},
            ];

            const tablaGenerada = this.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: ordenarPorPropiedad(resultadosGenerales, 'iMetaId'),
                numeracion: true,
                idxResto: [6],
                margin: [0, 0, 0, 5],
                separado: true,
            });
            const sumaObj = sumarObjetos(resultadosGenerales, [
                'nRegAfecPresupDetImporte',
            ]);

            // cuerpoPdf.push();

            cuerpoPdf.push({
                margin: [0, 5, 0, 10],
                table: {
                    dontBreakRows: true,
                    headerRows: 1,
                    widths: this.anchoCols(tablaGenerada['cuerpo'], [6]),
                    body:
                        tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
                        [
                            imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 8}),
                            {}, {}, {}, {}, {}, {}, {},
                            imprimirCelda('SUB TOTAL S/', null, {bold: true, style: 'thSmall'}),
                            imprimirCelda(sumaObj['nRegAfecPresupDetImporte'], 'money', {bold: true, style: 'thSmall'}),
                        ],
                    ]),
                }
            });

            const groups = nestGroupsBy(dataTareas, [
                'cClaseTareaNombre',
                'cTipoTareaNombre',
                'cTipoRecursoNombre',
            ]);

            console.log(groups);
            const listaTareasColumnas: ColumnaTabla<any>[] = [
                { label: '.', property: 'nivel1', type: 'text', transformarDirecto(row): any {
                    return imprimirCelda('', null, {border: [true, false, false, false]});
                    }
                },
                { label: '.', property: 'nivel2', type: 'text', transformarDirecto(row): any {
                    return imprimirCelda('', null, {border: [false, false, false, false]});
                    } },
                { label: '.', property: 'nivel3', type: 'text', transformarDirecto(row): any {
                    return imprimirCelda('', null, {border: [false, false, false, false]});
                    } },
                { label: 'DESCRIPCIÓN', property: 'cEspeDetCodigo', type: 'text' },
                { label: 'PRESUPUESTO', property: 'nPresupuesto', type: 'money' },
                { label: 'EJECUTADO', property: 'nEjecutado', type: 'money'},
                { label: 'SALDO', property: 'nSaldoPresupuesto', type: 'money'},
            ];

            let cuerpoGrupos = [];
            let sTablaClaseTarea = {
                nPresupuesto: 0,
                nEjecutado: 0,
                nSaldoPresupuesto: 0,
            };
            let idxGrupos = [0, 0, 0];
            Object.keys(groups).forEach(cClaseTareaNombre => {
                const sTablaTipoTarea = {
                    nPresupuesto: 0,
                    nEjecutado: 0,
                    nSaldoPresupuesto: 0,
                };
                Object.keys(groups[cClaseTareaNombre]).forEach(cTipoTareaNombre => {
                    const sTablaTipoRecursoAlt = {
                        nPresupuesto: 0,
                        nEjecutado: 0,
                        nSaldoPresupuesto: 0,
                    };
                    Object.keys(groups[cClaseTareaNombre][cTipoTareaNombre]).forEach(cTipoRecursoNombre => {
                        const sTablaTipoRecurso = sumarObjetos(groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre], [
                            'nPresupuesto',
                            'nEjecutado',
                            'nSaldoPresupuesto',
                        ]);
                        sTablaTipoRecursoAlt.nPresupuesto = sTablaTipoRecurso.nPresupuesto;
                        sTablaTipoRecursoAlt.nEjecutado = sTablaTipoRecurso.nEjecutado;
                        sTablaTipoRecursoAlt.nSaldoPresupuesto = sTablaTipoRecurso.nSaldoPresupuesto;
                        groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre]['sumas'] = sTablaTipoRecursoAlt;
                        // }
                        idxGrupos[2] ++;
                    });

                    sTablaTipoTarea.nPresupuesto += sTablaTipoRecursoAlt.nPresupuesto;
                    sTablaTipoTarea.nEjecutado += sTablaTipoRecursoAlt.nEjecutado;
                    sTablaTipoTarea.nSaldoPresupuesto += sTablaTipoRecursoAlt.nSaldoPresupuesto;
                    groups[cClaseTareaNombre][cTipoTareaNombre]['sumas'] = sTablaTipoTarea;
                    idxGrupos[1] ++;
                });
                sTablaClaseTarea.nPresupuesto += sTablaTipoTarea.nPresupuesto;
                sTablaClaseTarea.nEjecutado += sTablaTipoTarea.nEjecutado;
                sTablaClaseTarea.nSaldoPresupuesto += sTablaTipoTarea.nSaldoPresupuesto;
                groups[cClaseTareaNombre]['sumas'] = sTablaClaseTarea;
                sTablaClaseTarea = {
                    nPresupuesto: 0,
                    nEjecutado: 0,
                    nSaldoPresupuesto: 0,
                };
                idxGrupos[0] ++;
            });


            idxGrupos = [0, 0, 0];
            Object.keys(groups).filter(dat => dat != 'sumas').forEach(cClaseTareaNombre => {
                if (idxGrupos[0] == 0) {
                    cuerpoGrupos.push([
                        imprimirCelda('DESCRIPCIÓN', null, {bold: true, style: 'thSmall', colSpan: 4}),
                        {},
                        {},
                        {},
                        imprimirCelda('PRESUPUESTO', null, {bold: true, style: 'thSmall'}),
                        imprimirCelda('EJECUTADO', null, {bold: true, style: 'thSmall'}),
                        imprimirCelda('SALDO', null, {bold: true, style: 'thSmall'}),
                    ]);
                }
                cuerpoGrupos.push([
                    imprimirCelda(cClaseTareaNombre, null, {bold: true, alignment: 'left', style: 'thSmall', colSpan: 4}),
                    {},
                    {},
                    {},
                    imprimirCelda(groups[cClaseTareaNombre]['sumas'].nPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                    imprimirCelda(groups[cClaseTareaNombre]['sumas'].nEjecutado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                    imprimirCelda(groups[cClaseTareaNombre]['sumas'].nSaldoPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                ]);
                Object.keys(groups[cClaseTareaNombre]).filter(dat => dat != 'sumas').forEach(cTipoTareaNombre => {
                    cuerpoGrupos.push([
                        imprimirCelda('', null, {border: [true, false, false, false]}),
                        imprimirCelda(cTipoTareaNombre, null, {bold: true, alignment: 'left', style: 'thSmall', colSpan: 3}),
                        {},
                        {},
                        imprimirCelda(groups[cClaseTareaNombre][cTipoTareaNombre]['sumas'].nPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                        imprimirCelda(groups[cClaseTareaNombre][cTipoTareaNombre]['sumas'].nEjecutado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                        imprimirCelda(groups[cClaseTareaNombre][cTipoTareaNombre]['sumas'].nSaldoPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                    ]);
                    Object.keys(groups[cClaseTareaNombre][cTipoTareaNombre]).filter(dat => dat != 'sumas').forEach(cTipoRecursoNombre => {
                        console.log(groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre]);
                        const tablaTipoRecurso = this.generarDesdeMantenimiento({
                            titulos: listaTareasColumnas,
                            contenido: groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre],
                            separado: true,
                        });

                        cuerpoGrupos.push([
                            imprimirCelda('', null, {border: [true, false, false, false]}),
                            imprimirCelda('', null, {border: [false, false, false, false]}),
                            imprimirCelda(cTipoRecursoNombre, null, {bold: true, alignment: 'left', style: 'thSmall', colSpan: 2}),
                            {},
                            imprimirCelda(groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre]['sumas'].nPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                            imprimirCelda(groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre]['sumas'].nEjecutado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                            imprimirCelda(groups[cClaseTareaNombre][cTipoTareaNombre][cTipoRecursoNombre]['sumas'].nSaldoPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                        ]);
                        cuerpoGrupos = cuerpoGrupos.concat(tablaTipoRecurso['cuerpo']);
                        idxGrupos[2] ++;
                    });
                    idxGrupos[1] ++;
                });
                idxGrupos[0] ++;
            });

            const sTotalesDeTotales = sumarObjetos(dataTareas, [
                'nPresupuesto',
                'nEjecutado',
                'nSaldoPresupuesto',
            ]);
            cuerpoGrupos.push([
                imprimirCelda('TOTAL', null, {bold: true, alignment: 'right', style: 'thSmall', colSpan: 4}),
                {},
                {},
                {},
                imprimirCelda(sTotalesDeTotales.nPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                imprimirCelda(sTotalesDeTotales.nEjecutado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                imprimirCelda(sTotalesDeTotales.nSaldoPresupuesto, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
            ]);


            const listaTipoDocumentosColumnas: ColumnaTabla<any>[] = [
                { label: 'COD', property: 'iTipoDocId', type: 'text' },
                { label: 'TIPO DOCUMENTO', property: 'cTipoDocNombreLargo', type: 'text' },
            ];

            // fin
            cuerpoPdf.push({
                table: {
                    margin: 0,
                    widths: ['*', 'auto'],
                    body: [
                        [
                            [
                                this.generarDesdeMantenimiento({
                                    titulos: listaTipoDocumentosColumnas,
                                    contenido: dataTipoDocumentos,
                                    idxResto: [1],
                                    margin: [0, 0, 60, 5],
                                }),
                            ],
                            [
                                {
                                    margin: [0, 0, 0, 0],
                                    table: {
                                        dontBreakRows: true,
                                        headerRows: 1,
                                        // headerRows: 4,
                                        // widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 30}, {idx: 1, valor: 30}, {idx: 2, valor: 30},{idx: 3, valor: 30}, ]),
                                        body: cuerpoGrupos,
                                    },
                                }
                                // this.generarDesdeMantenimiento({
                                //     titulos: listaTareasColumnas,
                                //     contenido: dataTareas,
                                //     idxResto: [1],
                                //     margin: [0, 0, 0, 5],
                                // }),
                            ],

                        ],
                    ],
                },
                layout: 'noBorders',
            });

            this.finalizarCreacionPdf(cuerpoPdf);

        });
    }
    notas(dataRec) {
        this.queryService.cargar(this, ['bud.notas_detalles_presentacion'], {
            cCodigoCadena: dataRec,
        }).then((valRetorno) => {
            console.log(valRetorno);
            let resultadosGenerales = valRetorno['bud.notas_detalles_presentacion'];
            resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'iNotaNumero');


            const camposPrincipal: ColumnaTabla<any>[] = [
                { label: 'Cod.\n Sec_Fun', property: 'cNotaDetSecFuncCodigo', type: 'text' },
                { label: 'CLASIFICADOR DE GASTO', property: 'cEspeDetCodigo', type: 'text' },
                { label: 'NOMBRE', property: 'cEspeDetNombre', type: 'text' },
                { label: 'PTO INICIAL', property: 'nNotaDetPtoInicial', type: 'money' },
                { label: 'ANULACION\n( - )', property: 'nNotaDetAnulacion', type: 'money' },
                { label: 'HABILITACION\n( + )', property: 'nNotaDetCredito', type: 'money' },
                { label: 'PTO FINAL', property: 'nNotaDetPtoFinal', type: 'money' },
            ];


            const agrupadoNotaId = groupBy(resultadosGenerales, (obj) => {
                return obj.iNotaId;
            });

            let cuerpoPdf = [];
            Object.keys(agrupadoNotaId).forEach((secNotaId) => {

                this.reiniciarOpciones({
                    margenesHoja: [70, 120, 70, 40],
                    tamanioFuenteExtra: 1,
                    customDefTableHeader: [
                        {
                            margin: [70, 15, 70, 0],
                            // margin: [15, 15, 15, 0],
                            table: {
                                widths: [100, '*', 150],
                                headerRows: 0,
                                body: [
                                    [
                                        this.logotipoHeader(),
                                        {
                                            margin: [0, 30, 0, 0],
                                            alignment: 'center',
                                            stack: [
                                                this.imprimirCelda('N°: ' + agrupadoNotaId[secNotaId][0]['iNotaNumero'] + ' - ' + agrupadoNotaId[secNotaId][0]['iNotaYear'], null, {
                                                    fontSize: 13,
                                                    bold: true
                                                }),
                                                this.imprimirCelda('SOLICITUD DE MODIFICACION PRESUPUESTARIA', null, {
                                                    fontSize: 8,
                                                    bold: true
                                                }),
                                                this.imprimirCelda(agrupadoNotaId[secNotaId][0]['cTipoModiPresNombre'], null, {
                                                    fontSize: 8,
                                                    bold: true
                                                }),
                                                this.imprimirCelda('AÑO FISCAL ' + agrupadoNotaId[secNotaId][0]['iNotaYear'], null, {
                                                    fontSize: 8,
                                                    bold: true
                                                }),
                                            ]
                                        },
                                        {
                                            margin: [0, 0, 0, 0],
                                            table: {
                                                widths: ['auto', '*', 50],
                                                headerRows: 0,
                                                body: [
                                                    [

                                                        imprimirCelda('RECEPCION / REGISTRO', null, {
                                                            colSpan: 3,
                                                            alignment: 'center',
                                                            style: 'tdSmall',
                                                            bold: true
                                                        }),
                                                        {},
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('Fecha', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(new Date(), 'fec', {style: 'tdSmall', italic: true}),
                                                        imprimirCelda('', null, {style: 'tdSmall', rowSpan: 5}),
                                                    ],
                                                    [

                                                        imprimirCelda('N° RMP', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda('RPM', null, {style: 'tdSmall', italic: true}),
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('FTE. FTO.', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(agrupadoNotaId[secNotaId][0]['cFteFto'] ?? '', null, {
                                                            style: 'tdSmall',
                                                            italic: true
                                                        }),
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('RUBRO', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(agrupadoNotaId[secNotaId][0]['cRubro'] ?? '', null, {
                                                            style: 'tdSmall',
                                                            italic: true
                                                        }),
                                                        {},
                                                    ],
                                                    [

                                                        imprimirCelda('T.R.', null, {style: 'tdSmall', bold: true}),
                                                        imprimirCelda(agrupadoNotaId[secNotaId][0]['cTipo_recurso'] ?? '', null, {
                                                            style: 'tdSmall',
                                                            italic: true
                                                        }),
                                                        {},
                                                    ],
                                                ]
                                            },
                                            layout: {
                                                defaultBorder: true,
                                            }
                                        },
                                    ],
                                ]
                            },
                            layout: {
                                defaultBorder: false,
                            }
                        },
                    ],

                });
                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['auto', 'auto', '*'],
                        headerRows: 0,
                        body: [
                            [
                                imprimirCelda('DEPARTAMENTO', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cDptoCodigo'] + ' ' + agrupadoNotaId[secNotaId][0]['cDptoNombre'], null, {
                                    style: 'tdSmall',
                                    fontSize: 7
                                }),
                            ],
                            [
                                imprimirCelda('PROVINCIA', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cPrvnCodigo'] + ' ' + agrupadoNotaId[secNotaId][0]['cPrvnNombre'], null, {
                                    style: 'tdSmall',
                                    fontSize: 7
                                }),
                            ],
                            [
                                imprimirCelda('PLIEGO', null, {style: 'tdSmall', bold: true, fontSize: 7}),
                                imprimirCelda(':', null, {style: 'tdSmall', fontSize: 7}),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cPliego'], null, {style: 'tdSmall', fontSize: 7}),
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 0;
                        },
                        vLineWidth(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                        },
                        hLineColor(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                });
                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['auto', 'auto', 'auto', '*'],
                        headerRows: 0,
                        body: [
                            [
                                imprimirCelda('SEC. FUNCIONAL', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall'}),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cSecFuncCodigo'], null, {
                                    style: 'tdSmall',
                                    fontSize: 7,
                                    bold: true
                                }),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cSecFuncNombre'], null, {style: 'tdSmall'}),
                            ],
                            [
                                imprimirCelda('TIPO DE COSTO', null, {style: 'tdSmall', fontSize: 7, bold: true}),
                                imprimirCelda(':', null, {style: 'tdSmall'}),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cClaseTareaCodigo'], null, {
                                    style: 'tdSmall',
                                    fontSize: 7,
                                    bold: true
                                }),
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cClaseTareaNombre'], null, {
                                    style: 'tdSmall',
                                    fontSize: 7
                                }),
                            ],
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                    }
                });

                const tablaGenerada = this.generarDesdeMantenimiento({
                    titulos: camposPrincipal,
                    // contenido: ordenarPorPropiedad(agrupadoNotaId[secNotaId][0], 'cSecFuncCodigo'),
                    contenido: agrupadoNotaId[secNotaId],
                    numeracion: true,
                    idxResto: [2],
                    // margin: [0, 0, 0, 5],
                    margin: [50, 30, 50, 40],
                    separado: true,
                });

                const sumaObj = sumarObjetos(agrupadoNotaId[secNotaId], [
                    'nNotaDetPtoInicial',
                    'nNotaDetAnulacion',
                    'nNotaDetCredito',
                    'nNotaDetPtoFinal',
                ]);

                cuerpoPdf.push({
                    margin: [0, 5, 0, 10],
                    table: {
                        dontBreakRows: true,
                        headerRows: 2,
                        widths: this.anchoCols(tablaGenerada['cuerpo'], [1]),
                        body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
                            [
                                imprimirCelda('TOTAL', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 4}),
                                {}, {}, {},
                                imprimirCelda(sumaObj['nNotaDetPtoInicial'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                                imprimirCelda(sumaObj['nNotaDetAnulacion'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                                imprimirCelda(sumaObj['nNotaDetCredito'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                                imprimirCelda(sumaObj['nNotaDetPtoFinal'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
                            ],
                        ]),
                    }
                });


                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['*'],
                        headerRows: 0,
                        heights: [50],
                        body: [
                            [
                                imprimirCelda('SUSTENTO Y/O JUSTIFICACION DE LA SOLICITUD:', null, {
                                    style: 'tdSmall',
                                    fontSize: 7,
                                    bold: true
                                }),

                            ],
                            [
                                imprimirCelda(agrupadoNotaId[secNotaId][0]['cNotaObs'], null, {style: 'tdSmall', fontSize: 7}),
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 0;
                        },
                        vLineWidth(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 0;
                        },
                        hLineColor(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                });

                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        // widths: ['*'],
                        headerRows: 0,
                        body: [
                            [
                                imprimirCelda('Recomendaciones para la elaboración de la nota de modificación presupuestaria:', null, {
                                    style: 'tdSmall',
                                    fontSize: 8,
                                    bold: true
                                }),
                            ],
                            [
                                {
                                    ul: [
                                        'En caso de que las Sec_ Fun sean distintas, debera de contar con el conocimiento y autorización de los responsables de las Sec_Func',
                                        'Los montos solo deben ser positivos y enteros (sin decimales)',
                                        'Pto inicial deben de ser a la fecha de solicitud',
                                        'Total Pto Inicial igual a Total Pto Final',
                                        'Total Anulación igual a Total Habilitación',
                                        'Indicar en Tipo de Gastos en Proyectos; Costo Directo, Costo Indirecto (DT, GS, GL y GA)',
                                        'El numero comprende el codigo de obra y/o codigo de accion de inversion, según corresponda seguido del numero correlativo'
                                    ]
                                },
                            ],
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                    }
                });

                cuerpoPdf.push({
                    margin: [0, 0, 0, 15],
                    table: {
                        widths: ['*'],
                        headerRows: 0,
                        heights: [70],
                        body: [
                            [
                                {},
                                // this.imprimirCelda('\n\n\n\n_______________________________________\n ELABORADOR \n RESIDENTE RESPONSABLE\n\n', 'texto', {
                                //     alignment: 'center',
                                //     bold: false
                                // }),
                                // this.imprimirCelda('\n\n\n\n_______________________________________\n REVISADO YA APROBADO POR \n SUPERVISOR/INSPECTOR\n\n', 'texto', {
                                //     alignment: 'center',
                                //     bold: false
                                // }),
                                // this.imprimirCelda('\n\n\n\n_______________________________________\n REVISADO Y CONFORME POR \n JEFE INMEDIATO\n\n', 'texto', {
                                //     alignment: 'center',
                                //     bold: false
                                // }),

                            ],
                        ]
                    },
                    layout: {
                        hLineWidth(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 0;
                        },
                        vLineWidth(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                        },
                        hLineColor(i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor(i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                });
                // this.finalizarCreacionPdf(cuerpoPdf, 'download');

                if (Object.keys(agrupadoNotaId).length > 1){
                    this.finalizarCreacionPdf(cuerpoPdf, 'download');
                    cuerpoPdf = [];
                }
                else {
                    this.finalizarCreacionPdf(cuerpoPdf);
                }

            });


        });
    }

    listaExpedientesDGConceptos(dataRec, tituloFormato) {


        /*this.reiniciarOpciones({
            margenesHoja: [40, 80, 40, 40],
            orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: [
                {
                    margin: [5, 5, 5, 0],
                    table: {
                        widths: [150, '*', 150],
                        headerRows: 0,
                        body: [
                            [
                                this.logotipoHeader(),
                                {
                                    margin: [0, 40, 0, 0],
                                    alignment: 'center',
                                    stack: [
                                        this.imprimirCelda('', null, {
                                            fontSize: 13,
                                            bold: true
                                        }),
                                    ]
                                },
                                {
                                    // margin: [30, 10, 10, 0],
                                    // table: {
                                    // 	widths: ['auto', '*'],
                                    // 	body: numerosDocumentos,
                                    // }
                                },
                            ],
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
            ],
        });*/

        const data = dataRec['data'];
        const dataCabecera = dataRec['cabecera'];
        const dataAuditoria = dataRec['auditoria'];
        const dataTotales = dataRec['totales'];
        const dataGeneral = dataRec['data'][0];

        if (!dataGeneral) {
            console.warn('No se encontraron datos.');
            return false;
        }
        // this.cabecera = this.datosCabecera(dataCabecera)

        let tituloReporte;

        switch (dataCabecera.iOpcionFiltro) {
            case '1':
                tituloReporte = 'POR DEVENGAR - ' + dataCabecera.cAno_eje;
                break;
            case '2':
                tituloReporte = 'POR GIRAR - ' + dataCabecera.cAno_eje;
                break;
            default:
                tituloReporte = 'No es un fitro valido';
                break;
        }

        this.reiniciarOpciones({
            margenesHoja: [40, 90, 40, 40],
            orientacion: 'landscape',
            tamanioFuenteExtra: 1,
            customDefTableHeader: this.headerTableEntidad([
                this.imprimirCelda('REPORTE - EXPEDIENTES', null, {fontSize: 13, bold: true}),
                this.imprimirCelda(tituloReporte, null, {fontSize: 10, bold: true}),
            ]),
        });
        const cuerpoPdf = [];


        const cuerpoTabla = [];
        cuerpoTabla.push(
            [
                {text: 'N°', style: 'thSmall'},
                {text: 'AÑO', style: 'thSmall'},
                {text: 'EXPEDIENTE', style: 'thSmall'},
                {text: 'T.O.', style: 'thSmall'},
                {text: 'DES.T.O.', style: 'thSmall'},
                {text: 'M.C.', style: 'thSmall'},
                {text: 'NOMBRE', style: 'thSmall'},
                {text: 'C', style: 'thSmall'},
                {text: 'NRO.C', style: 'thSmall'},
                {text: 'FECHA C.', style: 'thSmall'},
                {text: 'CICLO', style: 'thSmall'},
                {text: 'F.FTO.', style: 'thSmall'},
                {text: 'T.R.', style: 'thSmall'},
                {text: 'META', style: 'thSmall'},
                {text: 'COMPROMISO', style: 'thSmall'},
                {text: 'DEVENGADO', style: 'thSmall'},
                {text: 'GIRADO', style: 'thSmall'},
                {text: 'PAGADO', style: 'thSmall'},
                {text: 'RENDIDO', style: 'thSmall'},
            ]
        );
        data.filter((val, idx) => {
            cuerpoTabla.push(
                [

                    this.imprimirCelda((idx + 1), null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Ano_eje, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Expediente, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Tipo_operacion, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.cNombre_Tipo_Operacion, null, {style: 'tdSmall', alignment: 'left'}),
                    this.imprimirCelda(val.Modalidad_compra, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Proveedor_Nombre, null, {style: 'tdSmall', alignment: 'left'}),
                    this.imprimirCelda(val.Nombre_doc_Compromiso, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Numero_doc_Compromiso, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Fecha_doc_Compromiso, 'fec'),
                    this.imprimirCelda(val.Ciclo, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Fuente_financ, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Tipo_recurso, null, {style: 'tdSmall', alignment: 'center'}),
                    this.imprimirCelda(val.Sec_func, null, {style: 'tdSmall', alignment: 'center'}),

                    this.imprimirCelda(val.nCompromiso, 'mon', {style: 'tdSmall', alignment: 'right'}),
                    this.imprimirCelda(val.nDevengado, 'mon', {style: 'tdSmall', alignment: 'right'}),
                    this.imprimirCelda(val.nGirado, 'mon', {style: 'tdSmall', alignment: 'right'}),
                    this.imprimirCelda(val.nPagado, 'mon', {style: 'tdSmall', alignment: 'right'}),
                    this.imprimirCelda(val.nRendido, 'mon', {style: 'tdSmall', alignment: 'right'}),

                ],
            );
            // dataCalculo.nNETO_PAGAR += 2 * 1;
        });
        cuerpoTabla.push(
            [
                this.imprimirCelda('Total:', null, {style: 'thSmall', alignment: 'right', colSpan: 14}),
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                this.imprimirCelda(dataTotales.checknCompromiso, 'mon', {style: 'tdSmall', alignment: 'right'}),
                this.imprimirCelda(dataTotales.checknDevengado, 'mon', {style: 'tdSmall', alignment: 'right'}),
                this.imprimirCelda(dataTotales.checknGirado, 'mon', {style: 'tdSmall', alignment: 'right'}),
                this.imprimirCelda(dataTotales.checknPagado, 'mon', {style: 'tdSmall', alignment: 'right'}),
                this.imprimirCelda(dataTotales.checknRendido, 'mon', {style: 'tdSmall', alignment: 'right'})
            ]
        );


        cuerpoPdf.push({
            margin: [0, 10, 0, 10],
            table: {
                widths: ['*'],
                body: [
                    [
                        {
                            // margin: [5, 0, 5, 0],
                            table: {
                                headerRows: 1,
                                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                                body: cuerpoTabla
                            },
                            // layout: 'noBorders',
                        },
                    ]
                ]
            },
            layout: 'noBorders',
            // absolutePosition: {x: 10, y: 75}
        });

        cuerpoPdf.push({
            columns: [
                {},
                // {text: 'Moquegua, ' + new Date().toLocaleDateString('es-PE', { year: 'numeric', month: 'long', day: 'numeric' }) },
            ],
            auditoria: dataAuditoria
        });

        this.finalizarCreacionPdf(cuerpoPdf, 'modal');

    }

    // POR PERSONA
    ExpedienteDocumento(dataRec) {
        ExpedienteDocumento(this, dataRec);
    }

    ExpedienteSecuenciaFase(dataRec) {
        ExpedienteSecuenciaFase(this, dataRec);
    }

    ExpedienteDocumentoNota(dataRec) {
        ExpedienteDocumentoNota(this, dataRec);
    }

    ExpedienteSecuenciaFaseNota(dataRec) {
        ExpedienteSecuenciaFaseNota(this, dataRec);
    }

    // POR EXPEDIENTE

    comprobantesPago(dataReq) {
        comprobantesPago(this, dataReq);
    }
    comprobantesDePagoSiaf(dataReq) {
        comprobantesDePagoSiaf(this, dataReq);
    }

    fasesExpediente(dataReq) {
        fasesExpediente(this, dataReq);
    }

    ExpedienteDocumentoFase(dataRec) {
        ExpedienteDocumentoFase(this, dataRec);
    }

    ExpedienteDocumentoFaseCicloFaseB(dataRec) {
        ExpedienteDocumentoFaseCicloFaseB(this, dataRec);
    }

    // REPORTES / POR METAS PRESUPUESTALES
    consultaComprobantePago(dataEnviar: DataEnviarPdfPresupuesto) {
        consultaComprobantePago(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    consultaExpedientesColumnaTotalFase(dataEnviar: DataEnviarPdfPresupuesto) {
        consultaExpedientesColumnaTotalFase(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    consultaExpedientesGiradosDocumentoCompromiso(dataEnviar: DataEnviarPdfPresupuesto) {
        consultaExpedientesGiradosDocumentoCompromiso(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    expedienteSecuenciaDocumentoMetaFase(dataEnviar: DataEnviarPdfPresupuesto) {
        expedienteSecuenciaDocumentoMetaFase(this, dataEnviar);
    }

    // percy
    // Totalizados mensualmente por certificado y fases de gasto INICIO
    CVSMPFteFtoSecFuncA4(dataEnviar: DataEnviarPdfPresupuesto) {
        CVSMPFteFtoSecFuncA4(this, dataEnviar);
    }

    ECMVSMPSecFuncA4(dataEnviar: DataEnviarPdfPresupuesto) {
        ECMVSMPSecFuncA4(this, dataEnviar);
    }

    ECMVSMPClasificadorA4(dataEnviar: DataEnviarPdfPresupuesto) {
        ECMVSMPClasificadorA4(this, dataEnviar);
    }

    CVSMPClasificadorA4(dataEnviar: DataEnviarPdfPresupuesto) {
        CVSMPClasificadorA4(this, dataEnviar);
    }

    // Totalizados mensualmente por certificado y fases de gasto FIN
    // Clasificadores por fases INICIO
    ECVSFGAnoejeActProySecFuncFuenteFinancCOSTO(dataEnviar: DataEnviarPdfPresupuesto) {
        ECVSFGAnoejeActProySecFuncFuenteFinancCOSTO(this, dataEnviar);
    }

    ECVSFGAnoejeSecFuncFuenteFinancCOSTO(dataEnviar: DataEnviarPdfPresupuesto) {
        ECVSFGAnoejeSecFuncFuenteFinancCOSTO(this, dataEnviar);
    }

    // Clasificadores por fases FIN
    // presupuestales Inicio
    ECVSFGSecFunc(dataEnviar: DataEnviarPdfPresupuesto) {
        ECVSFGSecFunc(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    ECVSFGSecFuncFuenteFinanc(dataEnviar: DataEnviarPdfPresupuesto) {
        ECVSFGSecFuncFuenteFinanc(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    ECVSFGActProy(dataEnviar: DataEnviarPdfPresupuesto) { // 3
        ECVSFGActProy(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    ECVSFG_ActProySecFunc(dataEnviar: DataEnviarPdfPresupuesto) {    // 4
        ECVSFG_ActProySecFunc(this, dataEnviar);
    }

    ECVSFGAnoejeActProySecFunc(dataEnviar: DataEnviarPdfPresupuesto) {  // 5
        ECVSFGAnoejeActProySecFunc(this, dataEnviar);
    }

    ECVSFGAnoejeActProySecFuncFuenteFinanc(dataEnviar: DataEnviarPdfPresupuesto) {  // 6
        ECVSFGAnoejeActProySecFuncFuenteFinanc(this, dataEnviar);
    }

    ECVSFGFuncionSecFunc(dataEnviar: DataEnviarPdfPresupuesto) {
        ECVSFGFuncionSecFunc(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    ECVSFGFuentefinanc(dataEnviar: DataEnviarPdfPresupuesto) {
        ECVSFGFuentefinanc(this, dataEnviar.cAno_eje, dataEnviar.cCodigoCadena);
    }

    // presupuestal Fin
    // certificaciones
    certificadoCompromisoAnualSaldo(dataEnviar: DataEnviarPdfPresupuesto) {
        certificadoCompromisoAnualSaldo(this, dataEnviar);
    }

    certificadoSecuenciaMetaFaseA4(dataEnviar: DataEnviarPdfPresupuesto) {
        certificadoSecuenciaMetaFaseA4(this, dataEnviar);
    }

    marcoPresupuestalVsCertificadoVsCompromisoAnual(dataEnviar: DataEnviarPdfPresupuesto) {
        marcoPresupuestalVsCertificadoVsCompromisoAnual(this, dataEnviar);
    }

    // percy

    expedienteSecuenciaDocumentoMetaClasificadorMontoFases(dataEnviar: DataEnviarPdfPresupuesto) {
        expedienteSecuenciaDocumentoMetaClasificadorMontoFases(this, dataEnviar);
    }


}
