import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
	selector: '[globalFormFocus], [globalFocusNext]'
})
export class FormFocusDirective implements AfterViewInit {
	focusables = ['input', 'mat-select', 'textarea'];

	constructor(private element: ElementRef) {
	}

	ngAfterViewInit() {
		console.warn('ESTA carga');
		const input = this.element.nativeElement.querySelector(
			this.focusables.join(',')
		);
		if (input) {
			input.focus();
		}
	}

	@HostListener('submit')
	submit() {
		console.warn('ESTA ENVIANDO');
		const input = this.element.nativeElement.querySelector(
			this.focusables.map(x => `${x}.ng-invalid`).join(',')
		);
		if (input) {
			input.focus();
		}
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (event.key == 'Enter' && event.srcElement['localName'] != 'textarea') {
			event.preventDefault();
			console.warn('ENTER');
			console.log(event.srcElement['id'], event.srcElement['localName'], event);

			const input = this.element.nativeElement.querySelector(
				'#' + event.srcElement['id']
			);
			console.warn(this.element);
			console.warn(input);
		}
		if (event.shiftKey) {
			const key = event.key;
			if (key === 'F') {
				event.preventDefault();
				// this.focusService.focus('mySearchBox');
			}
		}
	}
}
