import {Component, HostBinding, Input} from '@angular/core';
import {ConfigServiceMod} from '../../config/config.service';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ColorSchemeName} from '@vex/config/colorSchemeName';
import {VexConfigMod} from '@vexMod/config/vex-config.interface';
import {LayoutService} from '@vex/services/layout.service';
import {NavigationService} from '@vex/services/navigation.service';

@Component({
  selector: 'vex-mod-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
    ColorSchemeName = ColorSchemeName;

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

    config$: Observable<VexConfigMod> = this.configService.config$;
    colorScheme$: Observable<ColorSchemeName> = this.config$.pipe(
        map(config => config.style.colorScheme)
    );

  megaMenuOpen$: Observable<boolean> = of(false);

  constructor(
      private layoutService: LayoutService,
              public configService: ConfigServiceMod,
              private navigationService: NavigationService,
  ) { }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }



}
