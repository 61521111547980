import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SessionService} from '../services/session.service';
import {catchError, map} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {ColorVariable, colorVariables} from '../../../@vex/components/config-panel/color-variables';
import {mensajesDeError} from '@JVSoft/services/funciones-globales.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	colorVariables = colorVariables;

	constructor(
		private sessionService: SessionService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.sessionService.isAuthenticated().pipe(map((response: any) => {
			if (response.cCredUsuario) {
				return true;
			}
			this.router.navigate(['/sesion/login']);
			return false;
		}), catchError((error) => {
			console.log(error);
			mensajesDeError(error);
			this.router.navigate(['/sesion/login']);
			return of(false);
		}));
	}


	selectColor(color: ColorVariable) {
		// this.selectedColor = color;
		if (this.document) {
			this.document.documentElement.style.setProperty('--color-primary', color.default.replace('rgb(', '').replace(')', ''));
			this.document.documentElement.style.setProperty('--color-primary-contrast', color.contrast.replace('rgb(', '').replace(')', ''));
		}
	}


}
