import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {FocusService} from './focus.service';

@Directive({
	selector: '[globalNextFocus], [nextFocus]'
})
export class NextFocusDirective {

	private _frmActual: Element;

	constructor(
		private el: ElementRef,
		private tabService: FocusService,
	) {
	}

	private _nextControl: string;

	get nextControl() {
		return this._nextControl;
	}

	@Input('nextFocus')
	set nextControl(i: any) {
		console.log(i);
		this._nextControl = i;
	}

	@HostListener('keydown', ['$event'])
	onInput(e: any) {
		// console.log(e, this.el);
		switch (e.key) {
			case 'Enter':
				const noMoverEn = [
					'mat-select',
				];


				e.preventDefault();

				// this._frmActual = this.findParentBySelector(this.el.nativeElement, 'form');
                this._frmActual = this.findParentBySelector(this.el.nativeElement, '.focusSwap');
				const frmControlsVisibles = this._frmActual.querySelectorAll('input');
				// const frmControlsVisibles = this._frmActual.querySelectorAll('[formControlName]');
				console.log(frmControlsVisibles);
				let establecerFoco = false;
				// @ts-ignore
				for (const v of frmControlsVisibles) {
					if (establecerFoco) {
						// console.warn(v.getAttribute('formControlName'), this.el.nativeElement.getAttribute('formControlName'), 'FOCO');
						const matOptions = document.querySelector('#' + this.el.nativeElement['id'] + '-panel');
						console.warn(v, this.el.nativeElement['localName'], matOptions);
						if (!noMoverEn.includes(this.el.nativeElement['localName'])) {
							v.focus();
						} else {
							if (matOptions) {
								console.log('FOCO A ', v);
								v.focus();
							} else {
								console.log('No hay MAT OPC');
							}
						}
						// v.focus();
						establecerFoco = false;
						break;
					} else {
						console.warn(v.getAttribute('formControlName'), this.el.nativeElement.getAttribute('formControlName'));
						if (v.getAttribute('formControlName') == this.el.nativeElement.getAttribute('formControlName')) {
							establecerFoco = true;
						}
					}
					// console.log(v);
					// console.log(v.getAttribute('formControlName'));
				}
				if (establecerFoco) {
					// console.log('Focus al submit');
					const botonSubmit = this._frmActual.querySelector('button[type=\'submit\']');
					if (botonSubmit) {
						// @ts-ignore
						botonSubmit.focus();
					}
				}
				// const element = document.querySelector('[formControlName=\'' + this.nextControl + '\']');
				// console.dir(element);
				// element.focus();
				// console.warn(this.el);
				// console.warn(this._frmActual);
				// console.warn(frmControlsVisibles);

				// const element = document.querySelector('input');
				// // element.onkeydown = (elmk) => alert(elmk.key);
				// console.log(this.el);
				// this.el.nativeElement.dispatchEvent(new KeyboardEvent('keydown', {
				// 	...e,
				// 	...{
				// 		key: 'Tab',
				// 		keyCode: 9, // example values.
				// 		code: 'Tab', // put everything you need in this object.
				// 		altKey: false,
				// 		bubbles: true,
				// 		cancelable: true,
				// 		composed: true,
				// 		ctrlKey: false,
				// 		detail: 0,
				// 		isComposing: false,
				// 		location: 0,
				// 		metaKey: false,
				// 	}
				// }));

				break;
		}
		// if (e.which === 13) {
		// 	e.preventDefault();
		// 	const element = document.querySelector('input');
		// 	element.onkeydown = (elmk) => alert(elmk.key);
		// 	element.dispatchEvent(new KeyboardEvent('keydown', {key: 'A'}));
		// 	console.log('index', this.nextControl + 1);
		// 	this.tabService.selectedInput.next(this.nextControl);
		// }
	}

	ngOnInits() {
		if (!this._frmActual) {
			// this._frmActual = this.findParentBySelector(this.el.nativeElement, 'form');
			this._frmActual = this.findParentBySelector(this.el.nativeElement, '.focusSwap');
			console.warn(this._frmActual);
			if (this._frmActual) {
				console.warn(this._frmActual.querySelectorAll('input'));
				console.warn(this._frmActual.querySelectorAll('mat-form-field'));
				console.warn(this._frmActual['elements']);
				this._frmActual['elements'].forEach(v => {
					console.log(v);
					console.log(v.getAttribute('formControlName'));
				});
				// for (const element of this._frmActual['elements']) {
				// 	console.log(element);
				// 	console.log(element['formControlName']);
				// }
			}
		}
		console.warn(this.el);
		console.warn(this.el.nativeElement.querySelector('[formControlName]'));
		// console.warn(this.el.nativeElement.querySelectorAll('form'));
		// console.warn(this.findParentBySelector(this.el.nativeElement, 'form'));
		this.tabService.selectedInput.subscribe((i) => {
			// console.warn(this.nextControl, this.el);
			if (i === this.nextControl) {
				console.warn(i, this.el);
				this.el.nativeElement.focus();
			}
		});
	}

	collectionHas(a, b) { // helper function (see below)
		for (let i = 0, len = a.length; i < len; i++) {
			if (a[i] == b) {
				return true;
			}
		}
		return false;
	}

	findParentBySelector(elm, selector) {
		// console.log(elm);
		// return elm;

		const all = document.querySelectorAll(selector);
		let cur = elm.parentNode;
		while (cur && !this.collectionHas(all, cur)) { // keep going up until you find a match
			cur = cur.parentNode; // go up
		}
		return cur; // will return null if not found
	}

}
