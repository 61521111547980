import {Injectable} from '@angular/core';

import {QueryGeneralService} from '../general/services/query-general.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {FuncionesGlobalesService} from '@JVSoft/services/funciones-globales.service';
import {NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {getAllParams} from '../../@vex/utils/check-router-childs-data';
import {Title} from '@angular/platform-browser';
import {ConfiguracionWebService} from '@servicios/configuracion-web.service';
import {QueryLoadingProgressBarService} from '@servicios/query-loading-progress-bar.service';

@Injectable({
	providedIn: 'root'
})
export class QueryService extends QueryGeneralService {

	hasActiveMail$ = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd),
		map(() => getAllParams(this.router.routerState.root.snapshot)),
		// map(params => params.has('mailId')),
		distinctUntilChanged()
	);


	constructor(
		http: HttpClient, toastr: ToastrService, funcionesGlobalesService: FuncionesGlobalesService, configuracionWebService: ConfiguracionWebService, queryLoadingProgressBarService: QueryLoadingProgressBarService,
		public router: Router,
		private titleService: Title,
	) {
		super(http, toastr, funcionesGlobalesService, configuracionWebService, queryLoadingProgressBarService);
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
			const activeRoute = routeChange['url'];
			const partes = activeRoute.split('/');

			let sufijo;
			let prefijo;
			// console.log(partes);
			switch (partes[1]) {
				case 'tramite':
					prefijo = 'tram';
					break;
				case 'asistencia':
					prefijo = 'rhh';
					break;
				case 'patrimonio':
					prefijo = 'log';
					break;
				case 'presupuesto':
					prefijo = 'bud';
					break;
				case 'sisma':
					prefijo = 'sma';
					break;
				default:
					prefijo = 'grl';
					break;
			}

			this.titleService.setTitle(activeRoute.toUpperCase().split('/').join(' :: '));

			this.prefijo = prefijo + (sufijo ? ('/' + sufijo) : '');
		});
		/*		console.warn(trackById);

		 this.router.events.pipe(
		 filter(event => event instanceof NavigationEnd),
		 map(() => {
		 const as = getAllParams(this.router.routerState.root.snapshot);
		 console.warn(as);
		 return as;
		 }),
		 // map(params => params.has('mailId')),
		 distinctUntilChanged()
		 ).subscribe(me => console.warn);

		 this.router.events.pipe(
		 filter(event => event instanceof NavigationEnd),
		 map(() => {
		 console.log(this.router.routerState.root.snapshot);
		 const as = checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled);
		 console.warn(as);
		 return as;
		 }),
		 // map(params => params.has('mailId')),
		 distinctUntilChanged()
		 ).subscribe(me => console.warn);*/

	}
}
