import {Injectable} from '@angular/core';
import {QueryService} from '@servicios/query.service';
import {EntidadService} from '@servicios/entidad.service';
import {DataServidor} from '@JVSoft/interfaces/global';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExternosService {
    dataServidorSuscripcion: DataServidor = {};
    get horarioAtencionMesaDePartes(): {
        iResult: number,
        cMensaje: string,
        cTurnoHoraIngreso: string,
        cTurnoHoraSalida: string,
    } | null {
        if (this.dataServidorSuscripcion['tram.horario_atencion_mesapartesvirtual'].getValue()){
            return this.dataServidorSuscripcion['tram.horario_atencion_mesapartesvirtual'].getValue()[0];
        }
        return null;
    }

    _mostrarReloj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    mostrarReloj$ = this._mostrarReloj.asObservable();
    constructor(
        private queryService: QueryService,
        private entidadService: EntidadService,
    ) {
    }

    verHorarioAtencionMesaDePartes() {
        return this.queryService.cargar(this, [
            'tram.horario_atencion_mesapartesvirtual',
        ], {iEntId: this.entidadService.entidadActual?.iEntId}, true).then();
    }
}
