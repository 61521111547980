import roundFileCopy from '@iconify/icons-ic/round-file-copy';
import roundCloudSync from '@iconify/icons-ic/round-cloud-sync';
import roundMenuBook from '@iconify/icons-ic/round-menu-book';
import roundForwardToInbox from '@iconify/icons-ic/round-forward-to-inbox';
import roundInfo from '@iconify/icons-ic/round-info';
import roundError from '@iconify/icons-ic/round-error';
import roundDone from '@iconify/icons-ic/round-done';
import roundCheckCircle from '@iconify/icons-ic/round-check-circle';
import roundWarning from '@iconify/icons-ic/round-warning';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import roundTimeline from '@iconify/icons-ic/round-timeline';
import roundLocalShipping from '@iconify/icons-ic/round-local-shipping';
import roundCompare from '@iconify/icons-ic/round-compare';
import roundBook from '@iconify/icons-ic/round-book';
import roundBarcode from '@iconify/icons-ic/round-barcode';
import roundPlayArrow from '@iconify/icons-ic/round-play-arrow';
import roundCurrencyExchange from '@iconify/icons-ic/round-currency-exchange';
import roundNotInterested from '@iconify/icons-ic/round-not-interested';
import roundUpdate from '@iconify/icons-ic/round-update';
import roundLock from '@iconify/icons-ic/round-lock';
import roundLockOpen from '@iconify/icons-ic/round-lock-open';

import roundExpandMore from '@iconify/icons-ic/round-expand-more';
import roundFilterAlt from '@iconify/icons-ic/round-filter-alt';
import roundSearch from '@iconify/icons-ic/round-search';
import roundSummarize from '@iconify/icons-ic/round-summarize';
import roundAdd from '@iconify/icons-ic/round-add';
import roundGroupAdd from '@iconify/icons-ic/round-group-add';
import roundEdit from '@iconify/icons-ic/round-edit';
import roundDelete from '@iconify/icons-ic/round-delete';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import roundClose from '@iconify/icons-ic/round-close';
import roundSave from '@iconify/icons-ic/round-save';
import roundCancel from '@iconify/icons-ic/round-cancel';
import roundPrint from '@iconify/icons-ic/round-print';
import roundFactCheck from '@iconify/icons-ic/round-fact-check';
import roundExpandLess from '@iconify/icons-ic/round-expand-less';
import roundDownload from '@iconify/icons-ic/round-download';
import roundLocalAtm from '@iconify/icons-ic/round-local-atm';
import roundChecklist from '@iconify/icons-ic/round-checklist';
import roundRefresh from '@iconify/icons-ic/round-refresh';
import roundPhone from '@iconify/icons-ic/round-phone';
import roundMail from '@iconify/icons-ic/round-mail';
import roundPeople from '@iconify/icons-ic/round-people';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundRestartAlt from '@iconify/icons-ic/round-restart-alt';
import roundUpload from '@iconify/icons-ic/round-upload';
import roundAutorenew from '@iconify/icons-ic/round-autorenew';
import roundArrowRight from '@iconify/icons-ic/round-arrow-right';
import roundArrowLeft from '@iconify/icons-ic/round-arrow-left';
import roundFastForward from '@iconify/icons-ic/round-fast-forward';
import roundFastRewind from '@iconify/icons-ic/round-fast-rewind';
import roundBackspace from '@iconify/icons-ic/round-backspace';
import roundImportExport from '@iconify/icons-ic/round-import-export';
import roundFindInPage from '@iconify/icons-ic/round-find-in-page';
import roundDomainAdd from '@iconify/icons-ic/round-domain-add';
import roundPersonAdd from '@iconify/icons-ic/round-person-add';
import roundPaid from '@iconify/icons-ic/round-paid';
import roundBallot from '@iconify/icons-ic/round-ballot';
import roundGavel from '@iconify/icons-ic/round-gavel';
import roundListAlt from '@iconify/icons-ic/round-list-alt';
import roundWorkspacePremium from '@iconify/icons-ic/round-workspace-premium';
import roundAppRegistration from '@iconify/icons-ic/round-app-registration';
import roundToggleOn from '@iconify/icons-ic/round-toggle-on';
import roundToggleOff from '@iconify/icons-ic/round-toggle-off';
import roundEngineering from '@iconify/icons-ic/round-engineering';
import roundRocketLaunch from '@iconify/icons-ic/round-rocket-launch';
import roundSend from '@iconify/icons-ic/round-send';
import roundVerified from '@iconify/icons-ic/round-verified';
import roundThumbDownAlt from '@iconify/icons-ic/round-thumb-down-alt';
import roundPlaylistAdd from '@iconify/icons-ic/round-playlist-add';
import roundOpenWith from '@iconify/icons-ic/round-open-with';
import roundUnfoldMore from '@iconify/icons-ic/round-unfold-more';
import roundUnfoldLess from '@iconify/icons-ic/round-unfold-less';
import roundHistory from '@iconify/icons-ic/round-history';
import roundMeetingRoom from '@iconify/icons-ic/round-meeting-room';
import roundLooksOne from '@iconify/icons-ic/round-looks-one';
import roundPayments from '@iconify/icons-ic/round-payments';
import roundSchedule from '@iconify/icons-ic/round-schedule';
import roundAdminPanelSettings from '@iconify/icons-ic/round-admin-panel-settings';
import roundRequestQuote from '@iconify/icons-ic/round-request-quote';
import roundShoppingCart from '@iconify/icons-ic/round-shopping-cart';
import roundChevronRight from '@iconify/icons-ic/round-chevron-right';
import roundChevronLeft from '@iconify/icons-ic/round-chevron-left';
import roundKeyboardDoubleArrowRight from '@iconify/icons-ic/round-keyboard-double-arrow-right';
import roundKeyboardDoubleArrowLeft from '@iconify/icons-ic/round-keyboard-double-arrow-left';
import roundDeleteSweep from '@iconify/icons-ic/round-delete-sweep';
import roundHomeWork from '@iconify/icons-ic/round-home-work';
import roundPublic from '@iconify/icons-ic/round-public';
import roundDeleteOutline from '@iconify/icons-ic/round-delete-outline';
import roundLocalPolice from '@iconify/icons-ic/round-local-police';
import roundBlock from '@iconify/icons-ic/round-block';
import roundReply from '@iconify/icons-ic/round-reply';
import roundShare from '@iconify/icons-ic/round-share';
import roundVisibility from '@iconify/icons-ic/round-visibility';
import roundTimer from '@iconify/icons-ic/round-timer';
import roundTimerOff from '@iconify/icons-ic/round-timer-off';
import roundPrivacyTip from '@iconify/icons-ic/round-privacy-tip';
import roundVerifiedUser from '@iconify/icons-ic/round-verified-user';
import roundArchive from '@iconify/icons-ic/round-archive';
import roundUnarchive from '@iconify/icons-ic/round-unarchive';
import roundCloudDownload from '@iconify/icons-ic/round-cloud-download';
import roundLoop from '@iconify/icons-ic/round-loop';
import roundCircle from '@iconify/icons-ic/round-circle';
import roundList from '@iconify/icons-ic/round-list';
import roundSupervisorAccount from '@iconify/icons-ic/round-supervisor-account';
import roundHelp from '@iconify/icons-ic/round-help';
import roundHelpOutline from '@iconify/icons-ic/round-help-outline';
import roundPending from '@iconify/icons-ic/round-pending';
import roundPendingActions from '@iconify/icons-ic/round-pending-actions';
import roundAttachment from '@iconify/icons-ic/round-attachment';
import roundCheckBox from '@iconify/icons-ic/round-check-box';
import roundMarkunread from '@iconify/icons-ic/round-markunread';
import roundQrCode from '@iconify/icons-ic/round-qr-code';
import roundFolder from '@iconify/icons-ic/round-folder';
import roundPreview from '@iconify/icons-ic/round-preview';

export const round = {
    roundFileCopy,
    roundCloudSync,
    roundMenuBook,
    roundForwardToInbox,
    roundInfo,
    roundError,
    roundDone,
    roundCheckCircle,
    roundWarning,
    roundVpnKey,
    roundLocalShipping,
    roundTimeline,
    roundCompare,
    roundBook,
    roundBarcode,
    roundPlayArrow,
    roundCurrencyExchange,
    roundNotInterested,
    roundUpdate,
    roundLock,
    roundLockOpen,


    roundExpandMore,
    roundFilterAlt,
    roundSearch,
    roundSummarize,
    roundAdd,
    roundGroupAdd,
    roundEdit,
    roundDelete,
    roundFilterList,
    roundClose,
    roundSave,
    roundCancel,
    roundPrint,
    roundFactCheck,
    roundExpandLess,
    roundDownload,
    roundLocalAtm,
    roundChecklist,
    roundRefresh,
    roundPhone,
    roundMail,
    roundPeople,
    roundReceipt,
    roundRestartAlt,
    roundUpload,
    roundAutorenew,
    roundArrowRight,
    roundArrowLeft,
    roundFastForward,
    roundFastRewind,
    roundBackspace,
    roundImportExport,
    roundFindInPage,
    roundDomainAdd,
    roundPersonAdd,
    roundPaid,
    roundBallot,
    roundGavel,
    roundListAlt,
    roundWorkspacePremium,
    roundAppRegistration,
    roundToggleOn,
    roundToggleOff,
    roundEngineering,
    roundRocketLaunch,
    roundSend,
    roundVerified,
    roundThumbDownAlt,
    roundPlaylistAdd,
    roundOpenWith,
    roundUnfoldMore,
    roundUnfoldLess,
    roundHistory,
    roundMeetingRoom,
    roundLooksOne,
    roundPayments,
    roundSchedule,
    roundAdminPanelSettings,
    roundRequestQuote,
    roundShoppingCart,
    roundChevronRight,
    roundChevronLeft,
    roundKeyboardDoubleArrowRight,
    roundKeyboardDoubleArrowLeft,
    roundDeleteSweep,


    roundHomeWork,
    roundPublic,

    roundDeleteOutline,
    roundLocalPolice,
    roundBlock,
    roundReply,
    roundShare,
    roundVisibility,
    roundTimer,
    roundTimerOff,
    roundPrivacyTip,
    roundVerifiedUser,
    roundArchive,
    roundUnarchive,
    roundCloudDownload,
    roundLoop,
    roundCircle,
    roundList,
    roundSupervisorAccount,
    roundHelp,
    roundHelpOutline,
    roundPending,
    roundPendingActions,
    roundAttachment,

    roundCheckBox,
    roundMarkunread,
    roundQrCode,
    roundFolder,
    roundPreview,
};
