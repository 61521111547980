import {Injectable} from '@angular/core';
import {BotonMantenimiento, ColumnaTabla} from '@JVSoft/interfaces/global';


@Injectable({
    providedIn: 'root',
})

export class TablaMantenimientoService {

    get templateBotonesComunes(): BotonMantenimiento[] {
        return [
            {icono: 'mat:add', tipo: 'nuevo', class: 'text-white !bg-green', sinCondicion: true},
            {icono: 'mat:edit', tipo: 'editar', class: 'text-white !bg-amber'},
            {icono: 'mat:delete', tipo: 'eliminar', class: 'text-white !bg-red'},
            {icono: 'mat:delete', tipo: 'eliminar_fila', class: 'text-white !bg-red'},
            {icono: 'mat:print', tipo: 'imprimir', class: '!bg-blue-100 text-blue-700'},
            {icono: 'roundDownload', tipo: 'descargar', class: '!bg-blue-100 text-blue-700'},
            {icono: 'roundFolder', tipo: 'ver_archivos', class: '!bg-green-100 text-green-700'},
            {icono: 'fa5FilePdf', tipo: 'exportar_pdf', label: 'Exportar a PDF', class: 'bg-pdf-light text-pdf'},
            {icono: 'fa5FileExcel', tipo: 'exportar_excel', label: 'Exportar a Excel', class: 'bg-excel-light text-excel'},
            {icono: 'icSave', tipo: 'guardar', class: '!text-indigo-100 !bg-indigo-600'},
            {icono: 'icCancel', tipo: 'cancelar', class: '!text-cyan-100 !bg-cyan-600'},
            {
                icono: 'roundFactCheck', tipo: 'seleccionar',
                class: 'text-blue-100 bg-blue-700 dark:text-blue-100 dark:bg-blue-700'
            },
            {icono: 'roundListAlt', tipo: 'detalle', class: 'text-blue-100 bg-blue-700 dark:text-blue-100 dark:bg-blue-700'}
        ];
    }

    get templateColumnasTabla(): ColumnaTabla<any>[] {
        return [
            {label: '#', labelLista: 'Numeración', property: 'numeracion_automatica', type: 'numeracion_automatica'},
        ];
    }

}
