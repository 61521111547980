import {Injectable} from '@angular/core';
import {changeSelect, FuncionesGlobalesService} from '@JVSoft/services/funciones-globales.service';
import {ToastrService} from 'ngx-toastr';
import {AbstractControl} from '@angular/forms';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FuncionesGeneralesService extends FuncionesGlobalesService {


	constructor(toastr: ToastrService) {
		super(toastr);
	}

	recargarItemTableDS(objThis, campoDS, fnCargarTodos, fnCargarIndividual, dataGuardado, idTabla) {
		recargarItemTableDS(objThis, campoDS, fnCargarTodos, fnCargarIndividual, dataGuardado, idTabla);
	}

	changeSelect(control, formControl: AbstractControl, tipo, campoBuscar){
		changeSelect(control, formControl, tipo, campoBuscar);
	}

	changeSelectApi(control, queryService, formControl: AbstractControl, tipo, dataExtra= {}, dataExtraVariable = null, minLength = 1){
		formControl.valueChanges
			.pipe(
				debounceTime(500),
				tap((value) => {
					if (typeof value != 'number') {
						/*
						console.log(control['filtrados'][tipo]);
						return control['filtrados'][tipo];
						*/
						control['filtrados'][tipo] = [];
					}
					control['isLoading'] = true;
				}),

				switchMap(value => {
					if (!value || value.length <= minLength){
						return [];
					}
					if (typeof value == 'number') {
						const vRet = {};
						vRet[tipo] = control['filtrados'][tipo];
						return of(vRet);
					}
					const dataExtraVariableData = {};
					if (dataExtraVariable){
						for (const objData of dataExtraVariable) {
							dataExtraVariableData[objData.campo] = objData.ctrlValue.value;
						}
					}
					return queryService.getDataMethod('GET', tipo, {...dataExtra, ...dataExtraVariableData, ...{txtBuscar: value}})
						.pipe(
							finalize(() => {
								control['isLoading'] = false;
							}),
						);

				})
			)
			.subscribe(data => {
				if (data[tipo] == undefined) {
					control['filtrados'][tipo] = [];
				} else {
					control['filtrados'][tipo] = data[tipo];
				}

			});
	}

}

export function recargarItemTableDS(objThis, campoDS, fnCargarTodos, fnCargarIndividual, dataGuardado, idTabla) {
	const partesCampoDS = campoDS.split('.');
	const pReg = partesCampoDS[partesCampoDS.length - 1];

	if (objThis.dataServidor[pReg]) {
		const dataActual = objThis.dataServidor[pReg].data || objThis.dataServidor[pReg];
		const idxEnDS = (objThis.seleccionados[pReg]) ? dataActual.findIndex(item => item[idTabla] == objThis.seleccionados[pReg][idTabla]) : null;
		objThis[fnCargarIndividual](dataGuardado['data'][idTabla]).then((dataGuardadoIndiv) => {
			console.log(dataGuardadoIndiv);
			let dataActualizarEnDS;
			if (idxEnDS != null) {
				console.log('ENCONTRO IDX',idxEnDS);
				dataActualizarEnDS = {...objThis.seleccionados[pReg], ...dataGuardadoIndiv[campoDS][0]};
				dataActual[idxEnDS] = dataActualizarEnDS;
				// objThis.dataServidor[pReg].data = dataActual;
				if (objThis.dataServidor[pReg].data) {
					objThis.dataServidor[pReg].data = dataActual;
				}
				else {
					objThis.dataServidor[pReg] = null;
					objThis.dataServidor[pReg] = dataActual;
				}
				console.log(dataActual);
				objThis.seleccionados[pReg] = dataActual[idxEnDS];
			} else {
				console.log('NO ENCONTRO IDX')
				objThis.seleccionados[pReg] = null;
				objThis[fnCargarTodos]();
			}
		});
	} else {
		objThis[fnCargarTodos]();
	}
}


export function recargarItemTable(objThis, dataColeccion: string, fnCargarTodos: () => void, fnCargarIndividual: () => Promise<any>, dataGuardado: any, idTabla: string) {
	const partesCampoDS = dataColeccion.split('.');
	const pReg = partesCampoDS[partesCampoDS.length - 1];

	let coleccion;
	if (partesCampoDS.length > 1) {
		if (objThis.dataServidor[partesCampoDS[0]] && objThis.dataServidor[partesCampoDS[0]][partesCampoDS[1]]){
			coleccion = objThis.dataServidor[partesCampoDS[0]][partesCampoDS[1]];
		}
	}
	else {
		coleccion = objThis.dataServidor[partesCampoDS[0]];
	}
	console.log('REVISANDO');
	console.log(partesCampoDS);
	console.log(coleccion);
	if (coleccion) {
		const dataActualId = coleccion.findIndex(item => item[idTabla] == objThis.seleccionados[pReg][idTabla]);
		console.log(dataActualId);
		if (dataActualId > 0) {
			fnCargarIndividual().then(dataGuardadoIndiv => {
				coleccion[dataActualId] = {...objThis.seleccionados[pReg], ...dataGuardadoIndiv[dataColeccion][0]};
				return coleccion;
			});
		}
		// return coleccion;
	}
	else {
		fnCargarTodos();
	}
	// return null;
}
