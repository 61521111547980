import {Component, EventEmitter, Inject, Input, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '@servicios/query.service';
import {ColumnaTabla, OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {PdfTramiteService} from '@pdf/pdf-tramite.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {establecerEstilosTramites} from '@tramite/tramite.functions';
import {DespachoService} from '@servicios/despacho.service';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {DateDiffStringPipe} from '@JVSoft/pipes/date-diff-string.pipe';
import {TramiteDestinatariosFormularioComponent} from '@tramite/tramite-partials/tramite-destinatarios-formulario/tramite-destinatarios-formulario.component';
import {Observable, Subject} from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'vex-tramite-seguimiento',
	templateUrl: './tramite-seguimiento.component.html',
	styleUrls: ['./tramite-seguimiento.component.scss']
})
export class TramiteSeguimientoComponent implements OnInit {
	@Input() esDialog = true;
	@Input() iTramId;

	datosRecibidos: {tramite?: any, esDialog?: boolean} = {};

	get datoRecibidoIdTramite() {
		if (this.iTramId){
			return this.iTramId;
		}
		return this.datosRecibidos?.tramite?.iTramId;
	}
	get datoRecibidoEsDialog() {
		if (this.datosRecibidos && this.datosRecibidos.esDialog == undefined) {
			return true;
		}
		return this.datosRecibidos?.esDialog;
	}

	dataServidor = {
		tram: {
			tramites_referencias_seguimiento: null,
			tramites_notificaciones_NoSubsanados: null,
			tramites_avisos: null,
		},
	};
	seleccionados = {
		tramites_referencias_seguimiento: null,
	};

	filtrados = {};

	columnasTabla: ColumnaTabla<any>[] = [
		{label: '#', property: 'numeracion_automatica', type: 'numeracion_automatica'},
		{label: 'T', labelLista: 'Tipo', property: 'tipoTramiteMovimientoDefault', type: 'estiloEstablecido'},
		{
			label: 'Registro',
			property: 'iTramNumRegistroYear',
			type: 'text',
			textArray: ['iTramNumRegistroYear', '*-', 'iTramYearRegistro'],
			visible: true,
			sticky: true,
			cssClasses: ['font-bold', 'font-mono', 'text-center']
		},
		{
			label: 'Estado',
			property: 'cTramMovEstadoTramite',
			type: 'custom',
			visible: true,
		},
		{
			label: 'Fecha',
			property: 'fech_hora',
			type: 'text_rows',
			text_rows: [
				{ label: 'Creado', value: 'dtTramFechaHoraRegistro', valueType: 'date', salto: true, valueFormat: 'dd/MM/yyyy HH:mm', siCampoNulo: ['iTramMovId'], cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'], cssClasses: ['whitespace-nowrap'] },
				{ label: 'Enviado', value: 'dtTramMovFechaHoraEnvio', valueType: 'date', salto: true, valueFormat: 'dd/MM/yyyy HH:mm', cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'], cssClasses: ['whitespace-nowrap'] },
				{ label: 'Recibido', value: 'dtTramMovFechaHoraRecibido', valueType: 'date', salto: true, valueFormat: 'dd/MM/yyyy HH:mm', cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'], cssClasses: ['whitespace-nowrap'] },
			],
			visible: true,
			cssClasses: ['font-mono', 'text-xs']
		},
		{
			label: 'Movimiento',
			property: 'emisor_firma',
			type: 'text_rows',
			text_rows: [
				{ label: 'Creado por', value: 'cDespachoEmisorNombreLargo', siCampoNulo: ['iTramMovId'], cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'] },
				{ label: 'Emisor', value: 'cDespachoEmisorEnvioNombreLargo', cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'] },
				{ label: 'Destino', value: 'cDespachoReceptorNombreLargo', cssLabelClasses: ['font-bold'], cssValueClasses: ['italic'] },
			],
			visible: true,
		},
		{label: 'Documento', property: 'DocumentoFilasDefault', type: 'estiloEstablecido'},
		{label: 'Asunto', property: 'cTramAsuntoDocumentoDefault', type: 'estiloEstablecido'},
		{
			label: 'Transcurrido',
			property: 'tiempoTranscurrido',
			type: 'custom',
			visible: true,
		},
		{
			label: 'Transcurrido<br>Origen', property: 'tiempoTranscurridoDesdeOrigen', type: 'text',
			cssClasses: ['font-mono', 'text-red-900'],
			transformar: (row) => {
				// dtTramFechaHoraRegistro
				if (this.primeraDependencia.dtTramMovFechaHoraEnvio) {
					if (row.dtTramMovFechaHoraRecibido) {
						return new DateDiffStringPipe().transform(row.dtTramMovFechaHoraRecibido, this.primeraDependencia.dtTramMovFechaHoraEnvio);
					}
					else {
						return new DateDiffStringPipe().transform(new Date(), this.primeraDependencia.dtTramMovFechaHoraEnvio);
					}
				}
				return '';
			}
		},
		// {
		// 	label: 'Transcurrido', property: 'tiempoTranscurridoDesdeOrigen', type: 'estiloEstablecido', visible: true,
		// },

	];
	columnasTablaNotificaciones: ColumnaTabla<any>[] = [
		{
			label: 'Documento',
			property: 'cTramDocumentoTramite',
			type: 'text',
			visible: true,
		},
		{
			label: 'Plazo',
			property: 'iTramMovPlazoResolverDias',
			type: 'text',
			visible: true,
		},
		{
			label: 'F. Notificación',
			property: 'dtTramNotificaFechaHoraNotificado',
			type: 'date',
			format: 'dd/MM/yyyy HH:mm',
			visible: true,
		},
		{
			label: 'F. Subsanado',
			property: 'dtTramNotificaFechaHoraSubsanado',
			type: 'date',
			format: 'dd/MM/yyyy HH:mm',
			visible: true,
		},
	];
	botonesMantenimiento: TablaMantenimientoSeccionBotones;

	mode: 'create' | 'update' | 'view' = 'create';
	frmRegistro: FormGroup;
	onSubmitReason = new EventEmitter();

	@ViewChild('dlgNotificaciones', {static: true}) dlgNotificacionesRef: TemplateRef<any>;

	fechaActual = new Date();
	get tramitePrincipal() {
		return dataEnLista(this.dataServidor.tram.tramites_referencias_seguimiento, 'bTabTraRefPrincipal', true);
	}
	get ultimaDependencia() {
		if (this.dataServidor.tram.tramites_referencias_seguimiento) {
			return this.dataServidor.tram.tramites_referencias_seguimiento[this.dataServidor.tram.tramites_referencias_seguimiento.length - 1];
		}
		return null;
	}
	get primeraDependencia() {
		if (this.dataServidor.tram.tramites_referencias_seguimiento) {
			return this.dataServidor.tram.tramites_referencias_seguimiento[0];
		}
		return null;
	}
	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
		@Optional() private dialogRef: MatDialogRef<TramiteSeguimientoComponent>,
		private queryService: QueryService,
		public despachoService: DespachoService,
		private dialog: MatDialog,
		public pdfTramiteService: PdfTramiteService,
	) {
		this.columnasTabla = establecerEstilosTramites(this, this.columnasTabla);
		if (!dialogRef) {
			console.log('AAAAAAAAAA');
			this.datosRecibidos.esDialog = false;
		}
	}

	ngOnInit() {
		console.warn(this.datosDialogRecibidos);

		this.botonesMantenimiento = {
			tramites_referencias_seguimiento: {
				izquierda: [
					{
						tipo: 'editar', esVisible: (item: any) => {
							return this.despachoService.despachoActualPuedeEditar;
						}
					},
					// {tipo: 'iniciar_tramite', label: 'Iniciar Trámite', class: 'text-primary', icono: 'roundPlayArrow', sinCondicion: true},
					{
						tipo: 'exportar_pdf', class: 'text-red-800', icono: 'fa5FilePdf', sinCondicion: true, esVisible: (item: any) => {
							return this.datoRecibidoEsDialog;
						}
					},
				],
			}
		};
		if (this.datosDialogRecibidos) {
			this.datosRecibidos = this.datosDialogRecibidos;
		}

		if (this.datosRecibidos && (this.datosRecibidos['tramite'] || this.iTramId)) {
			this.cargaInicial();
		}
	}

	cargaInicial() {
		const solicitudes = [
			'tram.tramites_referencias_seguimiento',
		];
		console.warn(this.esDialog);
		if (!this.esDialog) {
			solicitudes.push('tram.tramites_avisos');
		}
		this.queryService.cargar(this, solicitudes, {
			iTramId: this.datoRecibidoIdTramite,
		}, !this.datoRecibidoEsDialog).then();
	}

	opcMenu(v: OpcionSeleccionada) {
		console.log(v);
		const dialogOpc: MatDialogConfig = {
			panelClass: 'dialogMantenimientoNuevo',
			disableClose: true,
		};
		switch (v.seccion) {
			case 'tramites_referencias_seguimiento':
				// dialogOpc.minWidth = '500px';
				switch (v.tipo) {
					case 'ver':
						if (v.item.iTramMovCantidadNotificacionesNoSubsanados > 0) {
							this.dataServidor.tram.tramites_notificaciones_NoSubsanados = null;
							this.queryService.cargar(this, ['tramites_notificaciones_NoSubsanados'], {
								iTramNotificaTramMovId: v.item.iTramMovId,
								iDespachoNotificaId: this.despachoService._despachoActual.getValue().iDespachoId,
							}, false, 'tram').then(() => {
								if (this.dataServidor.tram.tramites_notificaciones_NoSubsanados.length > 0) {
									this.dialogRef[0] = this.dialog.open(this.dlgNotificacionesRef, dialogOpc);
								}
							});
						}
						else {
							if (this.seleccionados.tramites_referencias_seguimiento == v.item) {
								this.seleccionados.tramites_referencias_seguimiento = null;
							}
							else {
								this.seleccionados.tramites_referencias_seguimiento = v.item;
							}
						}
						break;
					case 'editar':
						dialogOpc.data = {
							editarAdmin: true,
							tramite: this.seleccionados.tramites_referencias_seguimiento,
							formAccion: 'actualizar_envio',
							seccion: 'destinatarios',
						};
						this.dialogRef[1] = this.abrirDialog(TramiteDestinatariosFormularioComponent, dialogOpc)
						.subscribe((resp: OpcionSeleccionada) => {
							resp.seccion = 'tramites_referencias_seguimiento';
							this.opcMenu(resp);
						});
						break;
					case 'actualizar_envio':

						const frmRegistro: FormGroup = v.datosExtra.frmRegistro;
						const dialog: MatDialogRef<any> = v.datosExtra.dialog;
						this.queryService.guardarDatosAsync('mantenimiento#tramites_movimientos', frmRegistro.value, 'tram', 'toastr')
						.then((dataGuardado) => {
							if (dataGuardado) {
								if (dialog) {
									dialog.close();
								}
							}
						});

						break;
					case 'exportar_pdf':
						this.pdfTramiteService.seguimiento( this.dataServidor.tram.tramites_referencias_seguimiento );
						break;
				}
				break;
		}
		this.onSubmitReason.emit(v);
	}

	abrirDialog(componente, dialogOpc: MatDialogConfig): Observable<any> {
		const sujeto = new Subject();
		const dialogAct = this.dialog.open(componente, dialogOpc);
		// @ts-ignore
		dialogAct.componentInstance.onSubmitReason.subscribe(dat => {
			sujeto.next(dat);
		});
		return sujeto.asObservable();
	}
}
