import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServidorService} from '@servicios/servidor.service';

@Component({
	selector: 'general-reloj-servidor',
	templateUrl: './reloj-servidor.component.html',
	styleUrls: ['./reloj-servidor.component.scss']
})
export class RelojServidorComponent implements OnInit {


	@Input() classFecha = 'text-red-700';
	@Input() classHora = 'text-red-900';
	@Input() salto = true;
	@Input() separador = ' ';
	@Input() estilosHora = {};
	@Input() estilosFecha = {};
	@Input() origen: 'db'|'web' = 'db';

	@Output() terminado = new EventEmitter<any>();
	constructor(
		public servidorService: ServidorService,
	) { }

	ngOnInit(): void {
		if (this.origen != 'db') {
			// this.servidorService.actualizarDatosFechaHora(this.origen).then();
		}
	}
}
