<div
    class="bg-{{colorAlerta}}-50 border border-{{colorAlerta}}-400 rounded text-{{colorAlerta}}-800 text-sm p-1 flex items-start"
>
    <div>
        <mat-icon class="icon-xs" [svgIcon]="icono" [inline]="true"></mat-icon>
    </div>
    <div class="w-full">
        <p *ngIf="textoTitulo"><span class="font-bold">{{textoTitulo}}:</span></p>
        <p>
            <ng-content></ng-content>
        </p>
        <button
            *ngIf="botonAcepto"
            class="border-{{colorAlerta}}-400 bg-white hover:bg-gray-50 px-4 py-2 mt-4 border rounded font-bold"
            (click)="accionAcepto.emit(true)"
        >
            Lo entiendo
        </button>
    </div>
    <div *ngIf="botonCerrar">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    </div>
</div>
