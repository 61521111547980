import {Injectable} from '@angular/core';
import {imprimirCelda, OpcionesPdfMake, PdfMakeGlobalService} from '@JVSoft/services/pdf-make.global.service';
import {MatDialog} from '@angular/material/dialog';
import {QueryService} from './query.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {formatCurrency, formatDate, formatNumber} from '@angular/common';
import {ZeroFillPipe} from '@JVSoft/pipes/zero-fill.pipe';
import {UsuarioService} from '@servicios/usuario.service';
import {sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {FiltroBusquedaService} from '@standalone/components/filtro-busqueda/filtro-busqueda.service';
import {EntidadService} from '@servicios/entidad.service';
import {DataFiltroAplicado} from '@standalone/components/filtro-busqueda/filtro-busqueda.interface';

export interface TablaMantenimiento {
    titulos: ColumnaTabla<any>[];
    filasTitulos?: number;
    contenido?: any;
    numeracion?: boolean;
    dontBreakRows?: boolean;
    resaltarNumeracion?: boolean;
    separado?: boolean;
    idxResto?: number[];
    margin?: number[];
    detalle?: TablaMantenimientoDetalle[];
    sinTitulos?: boolean;
    sinBordes?: boolean;
    appendData?: any[];
}

export interface TablaMantenimientoPrincipal extends TablaMantenimiento {
    nombreColeccion?: string;
    titulo?: string;
    margenTitulo?: number[];
    detalleFiltro?: DataFiltroAplicado[];
    orientacion?: 'landscape' | 'portrait';
}

export interface TablaMantenimientoDetalle extends TablaMantenimiento {
    campo: string;
    label?: string | object;
}

@Injectable({
    providedIn: 'root',
})
export class PdfService extends PdfMakeGlobalService {
    public datosUsuarioiEntId;
    public datosEntidadActual;

    public get dataEntidadActual(){
        return this.entidadService._entidadActual.getValue();
    }

    constructor(
        dialog: MatDialog,
        public queryService: QueryService,
        public usuarioService: UsuarioService,
        public entidadService: EntidadService,
        public filtroBusquedaService: FiltroBusquedaService,
    ) {
        super(dialog);
        // console.log(this.entidadService._entidadActual.getValue());

        this.usuarioService.data$.subscribe(data => {
            // console.log(data);
            this.cargarImagenes();
            let cPersNombreLargo = '';
            let cPersDocumento = '';
            if (this.datosUsuarioiEntId['data_persona']) {
                cPersNombreLargo = this.datosUsuarioiEntId['data_persona']['cPersNombreLargo'];
                cPersDocumento = this.datosUsuarioiEntId['data_persona']['cPersDocumento'];
            }
            this.opcionesPDFInicial = {
                ...this.opcionesPDFInicial,
                ...{
                    defaultMarginHeaderTable: [15, 15, 15, 0],
                    footerUsuario: cPersNombreLargo,
                    footerDocumento: cPersDocumento,
                },
            };
        });
    }

    logotipoHeader(dataGeneral = null, datosExtra: { width?: number, height?: number, fit?: number[], fontSize?: number } = {}) {
        if (!dataGeneral && this.datosEntidadActual) {
            dataGeneral = this.datosEntidadActual;
        }

        const stackLogo = [];
        const dataImage = {
            ...{image: 'logo_completo', fit: [100, 100]},
            ...datosExtra,
        };
        if (datosExtra.width || datosExtra.height) {
            dataImage['fit'] = undefined;
        }
        stackLogo.push(dataImage);

        const pushStackLogo = (text, fontSize = (datosExtra.fontSize ?? 5), preText = '') => {
            if (text) {
                stackLogo.push({text: preText + text, fontSize});
            }
        };
        pushStackLogo(dataGeneral.cEntRuc, datosExtra.fontSize ?? 6, 'RUC: ');
        pushStackLogo(dataGeneral.cEntDomicilioCorto);
        pushStackLogo(dataGeneral.cEntTelefono);
        pushStackLogo(dataGeneral.cEntWeb);


        return {
            alignment: 'center',
            stack: stackLogo,
        };
        return {
            // fillColor: '#ff0000',
            // margin: [5, 5, 0, 0],
            alignment: 'center',
            stack: [
                {image: 'logo_completo', fit: [100, 100]},
                {text: 'RUC: ' + dataGeneral.cEntRuc, fontSize: 6},
                {text: dataGeneral.cEntDomicilioCorto, fontSize: 5},
                {text: dataGeneral.cEntTelefono, fontSize: 5},
                {text: dataGeneral.cEntWeb, fontSize: 5},
            ],
        };
    }

    async cargarImagenes() {
        this.datosUsuarioiEntId = this.usuarioService._data.getValue();
        if (this.datosUsuarioiEntId && this.datosUsuarioiEntId['entidadActual']) {
            this.datosEntidadActual = this.dataEntidadActual;
            if (this.datosEntidadActual && this.datosEntidadActual['cEntConfigWeb']) {
                // console.log(this.datosEntidadActual['cEntConfigWeb']);
                const configImpresion = this.datosEntidadActual['cEntConfigWeb']['impresion'];
                // console.log(configImpresion);
                if (configImpresion) {
                    // console.log(this.opcionesPDFInicial);
                    this.opcionesPDFInicial = {
                        ...this.opcionesPDFInicial,
                        ...configImpresion['configuracion'],
                        ... {
                            sinMarcaEmpresa: this.entidadService.sinMarcaJvSoft || !!configImpresion['configuracion']['sinMarcaEmpresa'],
                        }
                    };
                    // console.log(this.opcionesPDFInicial);
                }
            }
        }
        // console.log(this.datosUsuarioiEntId);
        this.imagenes = {
            ...this.imagenes,
            ...{
                // logo_completo: await this.getBase64ImageFromURL(environment.urlArchivos + '/img/logos/' + this.datosUsuarioiEntId['iEntId'] + '/logo-impresion.png'),
                // logo_completo: await this.getBase64ImageFromURL(environment.backend + '/img/logos/' + this.datosUsuarioiEntId['iEntId'] + '/logo-impresion.png'),
                // logo_completo: await this.getBase64ImageFromURL('http://archivos.jvsoft.pe/img/logos/1/logo-impresion.png'),
                logo_completo: this.dataEntidadActual['cEntLogoImpresion'],
            },
        };
    }

    verificarColumnaTablaMantenimiento(titulo: ColumnaTabla<any>) {
        return titulo.visible !== false && !titulo.ocultarReporte && (!titulo.reporte || !titulo.reporte.ocultar);
    }

    generarDesdeMantenimiento(tabla: TablaMantenimientoPrincipal) {
        const celdasHeaderDetalle = [];
        const tHead = [];

        if (tabla.numeracion) {
            tHead.push(
                this.imprimirCelda('Nº', null, {style: 'thSmall'}),
            );
        }
        const titUsar = tabla.titulos.filter(d => {
            if (d.visible == undefined) {
                d.visible = true;
            }
            return d.visible && this.verificarColumnaTablaMantenimiento(d);
        });

        titUsar.forEach(dT => {
            tHead.push(this.imprimirCelda(dT.label.replace('<br>', ' '), null, {style: 'thSmall'}));
        });
        if (!tabla.sinTitulos) {
            celdasHeaderDetalle.push(tHead);
        }

        const celdasBodyDetalle = [];

        tabla.contenido.filter((dataProd, idxProd) => {
            const tBody = [];
            if (tabla.numeracion) {
                tBody.push(
                    this.imprimirCelda(idxProd + 1, 'number', {
                        style: (tabla.resaltarNumeracion == false ? 'tdSmall' : 'thSmall'), alignment: 'center',
                        rowSpan: ((tabla.detalle && tabla.detalle.length > 0) ? 2 : 1),
                    }),
                );
            }
            titUsar.forEach((dT: ColumnaTabla<any>) => {
                let add;
                if (dT.transformarDirecto) {
                    add = dT.transformarDirecto(dataProd);
                }
                else {
                    let txtImprimir = dataProd[dT.property];
                    if (dT.transformar) {
                        txtImprimir = dT.transformar(dataProd);
                    }
                    if (dT.reportType) {
                        dT.type = dT.reportType;
                    }

                    switch (dT.type) {
                        case 'date':
                            add = this.imprimirCelda(txtImprimir == '' ||  txtImprimir == null ? '' : formatDate(txtImprimir, (dT.format ? dT.format : 'dd/MM/yyyy'), 'es-PE') , null, {
                                style: 'tdSmall',
                                alignment: (dT.format ? 'right' : 'center'),
                            });
                            break;
                        case 'number':
                            add = this.imprimirCelda(formatNumber(txtImprimir, 'es-PE', (dT.format ? dT.format : '0.0-2')), null, {
                                style: 'tdSmall',
                                alignment: (dT.format ? 'right' : 'center'),
                            });
                            if (dT.zeroFill) {
                                add = this.imprimirCelda(new ZeroFillPipe().transform(txtImprimir, dT.zeroFill), null, {
                                    style: 'tdSmall',
                                    alignment: 'right',
                                });
                            }
                            break;
                        case 'money':
                            add = this.imprimirCelda(formatCurrency(txtImprimir, 'es-PE', 'S/'), null, {
                                style: 'tdSmall',
                                alignment: 'right',
                            });
                            break;
                        default:
                            add = this.imprimirCelda(txtImprimir, null, {style: 'tdSmall'});
                            break;
                    }
                    if (dT.cssClasses) {
                        dT.cssClasses.forEach(clase => {
                            switch (clase) {
                                case 'text-center':
                                    add = {...add, alignment: 'center'};
                                    break;
                                case 'text-left':
                                    add = {...add, alignment: 'left'};
                                    break;
                                case 'text-right':
                                    add = {...add, alignment: 'right'};
                                    break;
                            }
                        });
                    }
                }
                tBody.push(add);
            });
            celdasBodyDetalle.push(tBody);

            if (tabla.detalle && tabla.detalle.length > 0) {
                const tBody2 = [];
                const lineasBody2 = [];
                if (tabla.numeracion) {
                    tBody2.push({});
                }

                titUsar.forEach(dT => {
                    tBody2.push({text: dT.property});
                });
                tabla.detalle.forEach(campoDet => {
                    // console.log(campoDet);
                    if (campoDet.label) {
                        lineasBody2.push(JSON.parse(JSON.stringify(campoDet.label)));
                    }
                    if (dataProd[campoDet.campo]) {
                        lineasBody2.push(
                            // this.generarDesdeMantenimiento({
                            // 	titulo: '',
                            // 	titulos: campoDet.titulos,
                            // 	contenido: dataProd[campoDet.campo],
                            // 	detalle: campoDet.detalle,
                            // 	idxResto: campoDet.idxResto,
                            // 	sinTitulos: campoDet.sinTitulos,
                            // });
                            this.generarDesdeMantenimiento({
                                ...campoDet,
                                ...{
                                    contenido: dataProd[campoDet.campo],
                                },
                            }),
                            // this.generarDesdeMantenimiento(campoDet.titulos, dataProd[campoDet.campo], campoDet.detalle)
                        );
                    }
                });
                tBody2[(tabla.numeracion ? 1 : 0)] = {
                    colSpan: titUsar.length,
                    style: 'tdSmall',
                    stack: lineasBody2,
                };
                // console.log(JSON.stringify(tBody2));
                celdasBodyDetalle.push(tBody2);
            }
        });

        const tablaTitulos = celdasHeaderDetalle;

        const tablaHeaderPedido = {
            margin: tabla.margin ?? [0, 5, 0, 10],
            table: {
                dontBreakRows: true,
                headerRows: tabla.filasTitulos ?? 1,
                // widths: ['*'],
                widths: this.anchoCols((celdasBodyDetalle.length > 0 ? celdasBodyDetalle : celdasHeaderDetalle), tabla.idxResto ?? []),
                body: celdasHeaderDetalle.concat(celdasBodyDetalle),
            },
        };
        if (tabla.sinBordes) {
            tablaHeaderPedido['layout'] = 'noBorders';
        }

        if (tabla.separado) {
            return {
                titulos: tablaTitulos,
                cuerpo: celdasBodyDetalle,
                completo: tablaHeaderPedido,
            };
        }
        // console.log(JSON.stringify(tablaHeaderPedido));
        return tablaHeaderPedido;
    }

    detalleFiltro(detalleFiltro: DataFiltroAplicado[]) {
        const textFiltro = [];
        if (detalleFiltro.length > 0) {
            textFiltro.push({ text: 'FILTRADO POR:\n', bold: true });
        }
        detalleFiltro.forEach((itmFiltro, idx) => {
            if (itmFiltro.value) {
                console.warn(itmFiltro.value);
                /*                    if (itmFiltro.value.includes('~VAR~')) {
                                        const arraY = [
                                            {ubicacionBusqueda: '~VAR~%', label: 'Empieza por: '},
                                            {ubicacionBusqueda: '%~VAR~%', label: 'Contiene: '},
                                            {ubicacionBusqueda: '%~VAR~', label: 'Termina con: '},
                                        ];
                                        itmFiltro.value = arraY.find(itm => itm.ubicacionBusqueda == itmFiltro.valueObject).label;
                                    }*/
                textFiltro.push({
                    text: [
                        {text: itmFiltro.label, bold: true},
                        {text: ': ', bold: true},
                        {text: itmFiltro.valueString ?? itmFiltro.value, italics: true},
                    ],
                });

                textFiltro.push({text: ' / '});
            }
        });
        textFiltro.splice(-1);
        return {
            text : textFiltro,
        };
    }

    listaRegular(
        tabla: TablaMantenimientoPrincipal,
        reiniciarOpciones: OpcionesPdfMake = {},
        accion: 'download' | 'open' | 'print' | 'otro' | 'modal' = 'open',
        callback = null
    ) {
        const cuerpoPdf = [];

        this.reiniciarOpciones({
            ...{
                margenesHoja: [50, 100, 50, 40],
                orientacion: tabla.orientacion ?? 'landscape',
                tamanioFuenteExtra: 2,
                customDefTableHeader: this.headerTableEntidad([
                    this.imprimirCelda(tabla.titulo, null, {fontSize: 13, bold: true}),
                ], [100, '*', 100], tabla.margenTitulo ?? [0, 40, 0, 0]),
            },
            ...reiniciarOpciones,
        });

        tabla = {...tabla, ...{separado: true}};

        const camposTotales = tabla.titulos.filter(titulo => titulo.visible !== false && titulo.reporte && titulo.reporte.totalizar)
            .map(titulo => titulo.property);

        const sumaObj = sumarObjetos(tabla.contenido, camposTotales);

        const tablaGenerada = this.generarDesdeMantenimiento(tabla);


        const filaTotales = [];

        if (tabla.numeracion) {
            filaTotales.push(
                imprimirCelda('', null, {border: [false, false, false, false]}),
            );
        }
        tabla.titulos.filter(titulo => this.verificarColumnaTablaMantenimiento(titulo)).forEach(titulo => {
                if (titulo.reporte && titulo.reporte.totalizar) {
                    camposTotales.push(titulo.property);
                    filaTotales.push(
                        imprimirCelda(sumaObj[titulo.property] ?? 0, 'mon', {
                            bold: true, style: ['thSmall'], alignment: 'right',
                        }),
                    );
                }
                else {
                    filaTotales.push(
                        imprimirCelda('', null, {border: [false, false, false, false]}),
                    );
                }
                // if (tabla.camposTotales.includes(titulo.property)){
                //     filaTotales.push(
                //         imprimirCelda(sumaObj[titulo.property], 'mon', {bold: true, alignment: 'right'}),
                //     )
                // } else {
                //     filaTotales.push(
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //     )
                // }
            },
        );
        let filaTabla = tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']);

        if (camposTotales && camposTotales.length > 0) {
            filaTabla = filaTabla.concat([filaTotales]);
        }

        if (tabla.nombreColeccion || tabla.detalleFiltro) {
            if (!reiniciarOpciones.footerTablePrepend) {
                cuerpoPdf.push(this.detalleFiltro(tabla.detalleFiltro ?? this.filtroBusquedaService.getValue(tabla.nombreColeccion).datosFiltroConLabel));
            }
        }

        let anchos = filaTabla.length > 0 ? this.anchoCols(filaTabla, tabla.idxResto) : undefined;

        console.log(tabla.dontBreakRows)
        cuerpoPdf.push({
            margin: [0, 5, 0, 10],
            table: {
                dontBreakRows: tabla.dontBreakRows === undefined ? true : tabla.dontBreakRows,
                headerRows: tabla.filasTitulos ?? 1,
                widths: anchos,
                body: filaTabla,
                // body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
                //     [
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda('', null, {border:[false, false, false, false]}),
                //         imprimirCelda(sumaObj['nCompraDocMonto'], 'mon', {bold: true, alignment: 'right'}),
                //     ],
                // ]),
            },
        });
        if (tabla.appendData) {
            cuerpoPdf.push(tabla.appendData);
        }


        this.finalizarCreacionPdf(cuerpoPdf,accion,callback);
        
    }

    headerTableEntidad(filasTitulo = [], anchoColumnas = [100, '*', 100], margenTitulo = [0, 40, 0, 0]) {
        return [
            {
                margin: [15, 15, 15, 0],
                table: {
                    widths: anchoColumnas,
                    headerRows: 0,
                    body: [
                        [

                            this.logotipoHeader(),
                            {
                                margin: margenTitulo,
                                alignment: 'center',
                                stack: filasTitulo,
                            },
                            {},
                        ],
                    ],
                },
                layout: {
                    defaultBorder: false,
                },
            },
        ];
    }
}
