import {AfterViewInit, Component, ElementRef, EventEmitter, Host, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, SkipSelf, TemplateRef, ViewChild} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlContainer, ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import {FocusMonitor} from '@angular/cdk/a11y';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ErrorStateMatcher} from '@angular/material/core';
import {QueryGeneralService} from '../../services/query-general.service';
import {ColumnaTabla, DataServidor, OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {MatInput} from '@angular/material/input';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {esNumero} from '@JVSoft/services/funciones-globales.service';
import {fadeInUp400ms} from '../../../../@vex/animations/fade-in-up.animation';
import {fadeInRight400ms} from '../../../../@vex/animations/fade-in-right.animation';
import {scaleIn400ms} from '../../../../@vex/animations/scale-in.animation';
import {stagger40ms} from '../../../../@vex/animations/stagger.animation';
import {ServidorService} from '@servicios/servidor.service';

@Component({
  selector: 'general-mat-buscar-trabajador',
  templateUrl: './mat-buscar-trabajador.component.html',
  styleUrls: ['./mat-buscar-trabajador.component.scss'],
    animations: [
        fadeInUp400ms,
        fadeInRight400ms,
        scaleIn400ms,
        stagger40ms
    ],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MatBuscarTrabajadorComponent,
        },
    ],
})
export class MatBuscarTrabajadorComponent implements OnInit, AfterViewInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
    get sinContenedor(): boolean { return this._sinContenedor; }
    @Input() set sinContenedor(val: boolean) { this._sinContenedor = val || true; }

    @Input()
    set value(value: any) {
        // this.formPrincipal.patchValue(value);
        this._value = value;
        this.stateChanges.next();
    }

    get value() {
        return this._value;
        // return this.formPrincipal.value;
    }

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    get placeholder() {
        return this._placeholder;
    }

    get empty(): boolean {
        return !this._value && !this.formPrincipal.get(this.idSeleccion).value;
        // return !this.value.iPersId && !this.value.cCampoBusqueda;
    }

    @HostBinding()
    get shouldLabelFloat(): boolean {
        // return false;
        // return this.seleccionarTipo;
        return this.focused || !this.empty || this.formPrincipal.get('cCampoBusqueda').value;
    }

    get errorState(): boolean {
        return this.errorStateMatcher.isErrorState(this.ngControl?.control, null);
    }



    columnasPrincipal: ColumnaTabla<any>[] = [
        {label: '#', property: 'numeracion_automatica', type: 'custom'},
        {label: 'Num.Doc.', property: 'cPersTrabajadorDocumento', type: 'text'},
        {label: 'Trabajador', property: 'cPersTrabajadorNombre', type: 'text'},
        {label: 'Establecimiento', property: 'cEstablecimientoNombreLargo', type: 'text'},
        {label: 'Dependencia', property: 'cDepenNombre', type: 'text'},
        {label: 'Cargo', property: 'cCargoNombre', type: 'text'},
    ];

    _dataPrincipal = new BehaviorSubject<any>(null);
    dataPrincipal$ = this._dataPrincipal.asObservable();
    botonesMenu: TablaMantenimientoSeccionBotones = {};

    opcionesFiltroBusquedaActual = {};

    constructor(
        private focusMonitor: FocusMonitor,
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        @Optional() @Self() public ngControl: NgControl,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private errorStateMatcher: ErrorStateMatcher,
        private queryGeneralService: QueryGeneralService,
        public servidorService: ServidorService,
    ) {
        if (ngControl != null) {
            ngControl.valueAccessor = this;
        }
        if (this.controlContainer) {
            console.log(controlContainer);
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }
    static nextId = 0;
    @Input() seleccionarTipo = true;
    @Input() readonly = false;
    @Input() clickInControl = false;
    @Input() idSeleccion: 'iPersId' | 'iPrestadorId' | string;
    @Input() set formControlName(val: string) {
        if (['iPersId', 'iPrestadorId'].includes(val)) {
            this.idSeleccion = val;
        }
    }


    private _sinContenedor: boolean;

    @Output() resultados = new EventEmitter<any>();
    @Output() textoEscrito = new EventEmitter<any>();
    @Output() dataSeleccionado = new EventEmitter<any>();

    @ViewChild('dialogResultados', {static: true}) dialogResultadosRef: TemplateRef<any>;

    dataServidor: {
        grl: {
            personas: null,
        },
        rhh: {
            trabajadores: null,
        },
    };
    dataServidorSuscripcion: DataServidor = {};

    seleccionados = {
        trabajadores: null,
    };

    infoSeleccionado = null;

    dialogRefSeleccion: MatDialogRef<any>;
    dialogOpc: MatDialogConfig = {
        panelClass: 'dialogMantenimientoNuevo',
        disableClose: true,
        data: {}
    };
    @ViewChild(MatInput, {read: ElementRef, static: true})
    input: ElementRef;

    private _value: any;

    private _placeholder: string;

    focused: boolean;

    @HostBinding()
    readonly id = `general-mat-buscar-trabajador-id-${MatBuscarTrabajadorComponent.nextId++}`;

    @HostBinding('attr.aria-describedby')
    userAriaDescribedBy = '';

    @Input()
    required: boolean;
    @Input()
    disabled: boolean;

    controlType = 'general-mat-trabajador-persona';
    stateChanges = new Subject<void>();

    onChange: (value: any) => {};
    onTouched: () => {};

    formPrincipal: FormGroup = this.fb.group({
        tipoPersona: [''],
        cCampoBusqueda: [''],

        iPersId: [''],
        iPrestadorId: [''],
        cPersTrabajadorNombre: [''],
        cTipoIdentSigla: [''],
        cPersTrabajadorDocumento: [''],
        cDepenNombre: [''],
        cCargoNombre: [''],
    });

    readonly autofilled: boolean;

    // readonly stateChanges = new Subject<void>();
    //
    // readonly autofilled: boolean;
    //
    //
    // readonly ngControl: NgControl | AbstractControlDirective | null = null;
    //
    //

    ngOnInit(): void {
        this.botonesMenu.trabajadores = {
            principal: [
                {tipo: 'seleccionar', ignorarDerecho: true},
            ]
        };
        this.focusMonitor.monitor(this.input).subscribe((focused) => {
            this.focused = !!focused;
            this.stateChanges.next();
        });
        this.focusMonitor.monitor(this.input).pipe(take(1)).subscribe((focused) => {
            this.onTouched();
        });
        this.formPrincipal.valueChanges.subscribe((value) => {
            console.log(value);
            if (value && value[this.idSeleccion]) {
                this.onChange(value[this.idSeleccion]);
            }
            else {
                if (value[this.idSeleccion] === null) {
                    this.onChange('');
                }
            }
            // this.onChange(value);
        });
        console.log(this._value);
    }
    ngAfterViewInit() {
        if (this.sinContenedor) {
            this.input.nativeElement.classList.remove('mat-input-element');
        }
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.input);
        this.stateChanges.complete();
    }

    onContainerClick(event: MouseEvent): void {
        if (this.clickInControl && !this.disabled) {
            this.formPrincipal.reset({
                cCampoBusqueda: this.formPrincipal.get('cCampoBusqueda').value,
            });
            this.filtroResultados();
        }
        this.focusMonitor.focusVia(this.input, 'program');
    }

    setDescribedByIds(ids: string[]): void {
        this.userAriaDescribedBy = ids.join(' ');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        console.log(obj);
        this.value = obj;

        if (obj && esNumero(obj) && !this.formPrincipal.get(this.idSeleccion).value) {
            this.formPrincipal.get(this.idSeleccion).setValue(obj);
            this.cargarTrabajador().then(dR => {
                if (dR && dR['rhh.trabajadores'] && dR['rhh.trabajadores'][0]) {
                    this.seleccionarPersona(dR['rhh.trabajadores'][0]);
                }
            });
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.formPrincipal.disable();
        this.stateChanges.next();
    }

    esMismoCampo() {
        return this.formPrincipal.controls[this.ngControl.name];
        // return this.formPrincipal.get(this.ngControl.name);
    }

    filtroResultados(event = {}) {
        console.log(event);
        this.opcionesFiltroBusquedaActual = event;
        this.cargarLista();
    }


    cargarLista() {
        console.log(this.dialogRefSeleccion);
        this.cargarTrabajador().then((dRet) => {
            if (!this.dialogRefSeleccion || [2].includes(this.dialogRefSeleccion.getState())) {
                this.dialogRefSeleccion = this.dialog.open(this.dialogResultadosRef, this.dialogOpc);
            }
        });
    }
    cargarTrabajador() {
        this.seleccionados.trabajadores = null;
        return this.queryGeneralService.cargar(this, ['rhh.trabajadores'], {...this.formPrincipal.value, ...this.opcionesFiltroBusquedaActual});
    }

    seleccionarPersona(info) {
        this.formPrincipal.patchValue({
            ...info
        });
        this.infoSeleccionado = info;
        this.dataSeleccionado.emit(info);
        if (this.dialogRefSeleccion) {
            this.dialogRefSeleccion.close('CERRADO');
        }
    }


    opcMenu(v: OpcionSeleccionada) {
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            data: {
                tituloElemento: 'Bancos',
            }
        };
        switch (v.seccion) {
            case 'trabajadores':
                dialogOpc.minWidth = '350px';
                switch (v.tipo) {
                    case 'ver':
                        if (this.seleccionados.trabajadores == v.item) {
                            this.seleccionados.trabajadores = null;
                        } else {
                            this.seleccionados.trabajadores = v.item;
                        }
                        break;
                    case 'seleccionar':
                        // this.exportarData.emit(v.item);
                        this.seleccionarPersona(v.item);
                        break;
                }
                break;
        }
    }
}
