import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dataEnLista',
})
export class DataEnListaPipe implements PipeTransform {

    transform(coleccion: object[], idBuscar: string | string[], dato): any {
        if (coleccion) {
            if (Array.isArray(idBuscar)) {
                return coleccion.find(item => {
                    const arrCoincide = [];
                    idBuscar.forEach((campo, idx) => {
                        arrCoincide.push((item[campo] == dato[idx]));
                    });
                    return arrCoincide.every(e => e === true);
                });
            }
            return coleccion.find(item => item[idBuscar] == dato);
        }
        return null;
    }

    // opcSelec(coleccion, idBuscar, formControl) {
    // 	if (coleccion) {
    // 		if (formControl.value == '') {
    // 			return {cModuloNombre: '', cModuloPadreNombre: 'Todos', iModuloId: ''};
    // 		}
    // 		return coleccion.find(item => item[idBuscar] == formControl.value);
    // 	}
    // 	return null;
    // }

}

export function dataEnLista(coleccion: object[], idBuscar: string | string[], dato): any {
    return new DataEnListaPipe().transform(coleccion, idBuscar, dato);
}
