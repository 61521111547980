<ng-container [formGroup]="formPrincipal">
    <select formControlName="iYearId" class="border-primary border-solid border rounded mr-1 bg-gray-100 text-gray-700" *ngIf="sinContenedor">
        <option *ngFor="let item of (dataServidorSuscripcion[prefijoData.reqPath] | async)" [value]="item[prefijoData.campoId]">{{ item[prefijoData.campoValue] }}</option>
    </select>
    <mat-select class="w-auto ml-2 mr-1" panelClass="panel-select" *ngIf="!sinContenedor"
        formControlName="iYearId"
    >
        <mat-option *ngFor="let item of (dataServidorSuscripcion[prefijoData.reqPath] | async)" [value]="item[prefijoData.campoId] + ''">
            <span class="text-2xs">{{ item[prefijoData.campoValue] }}</span>
        </mat-option>

    </mat-select>
</ng-container>
