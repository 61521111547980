import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {JVSoftRoutes} from './interfaces/importantes';
import {AuthGuard} from './session/guards/auth.guard';
import {Error404Component} from './errors/error404/error404.component';
import {ExternosLayoutComponent} from './layouts/externos-layout/externos-layout.component';
import {authMenuModulosGuard} from './guards/auth-menu-modulos.guard';

const routes: JVSoftRoutes = [
    {
        path: '',
        children: [
            {
                path: '',
                loadChildren: () => import('./modulos/modulos.module').then(m => m.ModulosModule)
            }
        ],
        canActivate: [
            // AuthGuard,
            authMenuModulosGuard,
        ]
    },
    {
        path: 'sesion',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule),
    },
    {
        path: 'super-admin',
        children: [
            {
                path: '',
                loadChildren: () => import('./super-administrador/super-administrador.module').then(m => m.SuperAdministradorModule)
            }
        ],
        canActivate: [AuthGuard]
    },
    {
        path: 'e',
        component: ExternosLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./externos/externos.module').then(m => m.ExternosModule),
            }
        ],
    },
    {path: '**', component: Error404Component},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {
}
