import {
    groupBy,
    nestGroupsBy,
    ordenarArray,
    ordenarPorPropiedad,
    sumarObjetos
} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {DataEnviarPdfPresupuesto, PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';

export function ECVSFGSecFunc(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Sec_Func'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Sec_Func'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTOS POR SEC. FUNC', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + anioEjec, null, {fontSize: 12, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: 'Ano_eje', type: 'text'},
			{label: 'ACT.PROY.', property: 'Act_proy', type: 'text'},
			{label: 'SEC.FUNC.', property: 'Sec_func', type: 'text'},
			{label: 'NOMBRE', property: 'cNombre_Sec_func', type: 'text'},
			{label: 'PIA', property: 'nPia', type: 'money'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO.', property: 'nCertificado', type: 'money'},
			{label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
			{label: '% COM', property: 'nPorcCompromiso', type: 'number'},
			{label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: '% DEV', property: 'nPorcDevengado', type: 'number'},
			{label: 'GIRADO', property: 'nGirado', type: 'money'},
			{label: '% GIR.', property: 'nPorcGirado', type: 'number'},
			{label: 'PAGADO.', property: 'nPagado', type: 'money'},
			{label: '% PAG.', property: 'nPorcPagado', type: 'number'},
			{label: 'SALDO COMPR.', property: 'nSaldoCompromiso', type: 'money'},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
			numeracion: true,
			idxResto: [5],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPia',
			'nPim',
			'nCertificado',
			'nCompromiso',
			'nPorcCompromiso',
			'nDevengado',
			'nPorcDevengado',
			'nGirado',
			'nPorcGirado',
			'nPagado',
			'nPorcPagado',
			'nSaldoCompromiso',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
					[
						imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 5}),
						{}, {}, {}, {},
						imprimirCelda(sumaObj['nPia'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCompromiso'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nCompromiso'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nDevengado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nGirado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nGirado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nPagado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nPagado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nSaldoCompromiso'], 'money', {bold: true, style: 'thSmall'}),
					],
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function ECVSFGSecFuncFuenteFinanc(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Sec_Func_Fuente_Financ'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Sec_Func_Fuente_Financ'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTOS POR SEC. FUNC. y FTE.FTO.', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + anioEjec, null, {fontSize: 12, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
        const camposPrincipal: ColumnaTabla<any>[] = [
            {label: 'AÑO', property: 'Ano_eje', type: 'text'},
            {label: 'SEC.FUNC.', property: 'Sec_func', type: 'text'},
            {label: 'FTE.', property: 'Fuente_Financ', type: 'text'},
            {label: 'NOMBRE', property: 'Nombre_Fuente_Financ', type: 'text'},
            {label: 'PIA', property: 'nPia', type: 'money'},
            {label: 'PIM', property: 'nPim', type: 'money'},
            {label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
            {label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
            {label: '% COM', property: 'nPorcCompromiso', type: 'number'},
            {label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
            {label: '% DEV', property: 'nPorcDevengado', type: 'number'},
            {label: 'GIRADO.', property: 'nGirado', type: 'money'},
            {label: '% GIR', property: 'nPorcGirado', type: 'number'},
            {label: 'PAGADO', property: 'nPagado', type: 'money'},
            {label: '% PAG', property: 'nPorcPagado', type: 'number'},
            {label: 'SALDO COMPR.', property: 'nSaldoCompromiso', type: 'money'},
        ];

        const tablaGenerada = objThis.generarDesdeMantenimiento({
            titulos: camposPrincipal,
            contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
            numeracion: true,
            idxResto: [9],
            margin: [0, 0, 0, 5],
            separado: true,
        });
        const sumaObj = sumarObjetos(resultadosGenerales, [
            'nPia',
            'nPim',
            'nCertificado',
            'nCompromiso',
            'nPorcCompromiso',
            'nDevengado',
            'nPorcDevengado',
            'nGirado',
            'nPorcGirado',
            'nPagado',
            'nPorcPagado',
            'nSaldoCompromiso',
        ]);

        cuerpoPdf.push({
            margin: [0, 5, 0, 10],
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
                body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
                    [
                        // generarColSpan(imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right'}),  5),
                        imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 5}),
                        {}, {}, {}, {},
                        imprimirCelda(sumaObj['nPia'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda(sumaObj['nCertificado'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda(sumaObj['nCompromiso'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda((sumaObj['nCompromiso'] * 100) / sumaObj['nPim'], 'mon', {
                            bold: true,
                            style: 'thSmall'
                        }),
                        imprimirCelda(sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda((sumaObj['nDevengado'] * 100) / sumaObj['nPim'], 'mon', {
                            bold: true,
                            style: 'thSmall'
                        }),
                        imprimirCelda(sumaObj['nGirado'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda((sumaObj['nGirado'] * 100) / sumaObj['nPim'], 'mon', {
                            bold: true,
                            style: 'thSmall'
                        }),
                        imprimirCelda(sumaObj['nPagado'], 'money', {bold: true, style: 'thSmall'}),
                        imprimirCelda((sumaObj['nPagado'] * 100) / sumaObj['nPim'], 'mon', {
                            bold: true,
                            style: 'thSmall'
                        }),
                        imprimirCelda(sumaObj['nSaldoCompromiso'], 'money', {bold: true, style: 'thSmall'}),
                    ],
                ]),
            }
        });

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function ECVSFGActProy(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Act_Proy'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Act_Proy'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTOS POR ACT.PROY.', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + anioEjec, null, {fontSize: 12, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: 'Ano_eje', type: 'text'},
			{label: 'FUNC.', property: 'cFuncion', type: 'text'},
			{label: 'ACT/PROY.', property: 'Act_Proy', type: 'text'},
			{label: 'NOMBRE', property: 'cNombre_Act_proy', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: '% CER', property: 'nPorcCertificado', type: 'number'},
			{label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
			{label: '% COM', property: 'nPorcCompromiso', type: 'number'},
			{label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: '% DEV', property: 'nPorcDevengado', type: 'number'},
			{label: 'GIRADO.', property: 'nGirado', type: 'money'},
			{label: '% GIR', property: 'nPorcGirado', type: 'number'},
			{label: 'PAGADO', property: 'nPagado', type: 'money'},
			{label: '% PAG', property: 'nPorcPagado', type: 'number'},
			{label: 'SALDO COMPR.', property: 'nSaldoCompromiso', type: 'money'},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
			numeracion: true,
			idxResto: [9],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nPorcCertificado',
			'nCompromiso',
			'nPorcCompromiso',
			'nDevengado',
			'nPorcDevengado',
			'nGirado',
			'nPorcGirado',
			'nPagado',
			'nPorcPagado',
			'nSaldoCompromiso',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				widths: objThis.anchoCols(tablaGenerada['cuerpo'], [9]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
					[
						imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 5}),
						{}, {}, {}, {},
						imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda(sumaObj['nCertificado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nCertificado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nCompromiso'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nCompromiso'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nDevengado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nGirado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nGirado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nPagado'], 'money', {bold: true, style: 'thSmall'}),
						imprimirCelda((sumaObj['nPagado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall'
						}),
						imprimirCelda(sumaObj['nSaldoCompromiso'], 'money', {bold: true, style: 'thSmall'}),
					],
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function ECVSFG_ActProySecFunc(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Act_Proy_Sec_Func'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Act_Proy_Sec_Func'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			// orientacion: 'landscape',
			// tamanioHoja: 'A3',
			// tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTO POR ACT.PROY. Y SEC. FUNC. ', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('TODOS LOS EJERCICIOS', null, {fontSize: 7, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});


		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'ACT.PROY', property: 'Act_Proy', type: 'text'/*, transformarDirecto(row): any {
				return imprimirCelda(' ', null ,{
					border: [true, false, true, false],
				});
				}*/},
			{label: 'AÑO', property: 'Ano_eje', type: 'text'},
			{label: 'SEC.FUNC', property: 'Sec_func', type: 'text'},
			{label: 'NOMBRE', property: 'cNombre_Finalidad', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
			{label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: 'GIRADO.', property: 'nGirado', type: 'money'},
			{label: 'PAGADO.', property: 'nPagado', type: 'money'},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nCompromiso',
			'nDevengado',
			'nGirado',
			'nPagado',
		]);


		const agrupadoActProy = groupBy(resultadosGenerales, (obj) => {
			return obj.Act_Proy;
		});

		let dataActProy = {
			Act_Proy: '',
			cNombre_Act_proy: '',
			nPim: '',
			nCertificado: '',
			nCompromiso: '',
			nDevengado: '',
			nGirado: '',
			nPagado: '',
		};

		let pag = 0;
		let nuevoCuerpo = [];
		Object.keys(agrupadoActProy).forEach((secActProy) => {
			dataActProy = {
				...{
					Act_Proy: agrupadoActProy[secActProy][0]['Act_Proy'],
					cNombre_Act_proy: agrupadoActProy[secActProy][0]['cNombre_Act_proy'],
				},
				...sumarObjetos(agrupadoActProy[secActProy], [
					'nPim',
					'nCertificado',
					'nCompromiso',
					'nDevengado',
					'nGirado',
					'nPagado',
				]),
			};


			const tablaGenerada = objThis.generarDesdeMantenimiento({
				titulos: camposPrincipal,
				contenido: ordenarPorPropiedad(agrupadoActProy[secActProy], 'ActProy'),
				// numeracion: true,
				idxResto: [6],
				margin: [0, 0, 0, 5],
				separado: true,
			});

			if (nuevoCuerpo.length == 0) {
				nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['titulos']);
			}

			nuevoCuerpo = nuevoCuerpo.concat([
				[
					imprimirCelda(dataActProy.Act_Proy, null, {
						bold: true,
						style: 'thSmall',
						alignment: 'center',
						rowSpan: tablaGenerada['cuerpo'].length + 1,
					}),
				].concat(
					generarColSpan(imprimirCelda(dataActProy.cNombre_Act_proy, null, {
						bold: true,
						// fontSize: 8,
						style: 'thSmall',
						alignment: 'left'
					}), 3).concat(
						imprimirCelda(dataActProy.nPim, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nCertificado, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nCompromiso, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nDevengado, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(dataActProy.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					),
				)
			]).concat(tablaGenerada['cuerpo']);

			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				// fontSize: 8,
				style: 'thSmall',
				alignment: 'right'
			}), 4).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCompromiso, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nDevengado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 30}, {idx: 1, valor: 20}, {idx: 2, valor: 25}, ]),
				body: nuevoCuerpo,
			}
		});
		// console.log(JSON.stringify(cuerpoPdf));
		// console.log(cuerpoPdf);

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}

export function ECVSFGAnoejeActProySecFunc(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			// orientacion: 'landscape',
			// tamanioHoja: 'A3',
			// tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTO POR ACT.PROY. Y SEC. FUNC. ', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + resultadosGenerales[0]['Ano_eje'],  null, {fontSize: 13, bold: true}),
				objThis.imprimirCelda('TODOS LOS EJERCICIOS', null, {fontSize: 7, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});


		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: 'Ano_eje', type: 'text'},
			{label: 'ACT.PROY', property: 'Act_Proy', type: 'text'/*, transformarDirecto(row): any {
				return imprimirCelda(' ', null ,{
					border: [true, false, true, false],
				});
				}*/},
			{label: 'SEC.FUNC', property: 'Sec_func', type: 'text'},
			{label: 'NOMBRE', property: 'cNombre_Finalidad', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
			{label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: 'GIRADO.', property: 'nGirado', type: 'money'},
			{label: 'PAGADO.', property: 'nPagado', type: 'money'},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nCompromiso',
			'nDevengado',
			'nGirado',
			'nPagado',
		]);


		const agrupadoActProy = groupBy(resultadosGenerales, (obj) => {
			return obj.Act_Proy;
		});

		let dataActProy = {
			Ano_eje: '',
			cNombre_Act_proy: '',
			nPim: '',
			nCertificado: '',
			nCompromiso: '',
			nDevengado: '',
			nGirado: '',
			nPagado: '',
		};

		let pag = 0;
		let nuevoCuerpo = [];
		Object.keys(agrupadoActProy).forEach((secActProy) => {
			dataActProy = {
				...{
					Ano_eje: agrupadoActProy[secActProy][0]['Ano_eje'],
					cNombre_Act_proy: agrupadoActProy[secActProy][0]['cNombre_Act_proy'],
				},
				...sumarObjetos(agrupadoActProy[secActProy], [
					'nPim',
					'nCertificado',
					'nCompromiso',
					'nDevengado',
					'nGirado',
					'nPagado',
				]),
			};


			const tablaGenerada = objThis.generarDesdeMantenimiento({
				titulos: camposPrincipal,
				contenido: ordenarPorPropiedad(agrupadoActProy[secActProy], 'ActProy'),
				// numeracion: true,
				idxResto: [6],
				margin: [0, 0, 0, 5],
				separado: true,
			});

			if (nuevoCuerpo.length == 0) {
				nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['titulos']);
			}

			nuevoCuerpo = nuevoCuerpo.concat([
				[
					imprimirCelda(dataActProy.Ano_eje, null, {
						bold: true,
						style: 'thSmall',
						alignment: 'center',
						rowSpan: tablaGenerada['cuerpo'].length + 1,
					}),
				].concat(
					generarColSpan(imprimirCelda(dataActProy.cNombre_Act_proy, null, {
						bold: true,
						style: 'thSmall',
						alignment: 'left'
					}), 3).concat(
						imprimirCelda(dataActProy.nPim, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nCertificado, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nCompromiso, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nDevengado, 'money', {
							bold: true,
							style: 'thSmall',
							alignment: 'right'
						}),
						imprimirCelda(dataActProy.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(dataActProy.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					),
				)
			]).concat(tablaGenerada['cuerpo']);
			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				style: 'thSmall',
				alignment: 'right'
			}), 4).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCompromiso, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nDevengado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 30}, {idx: 1, valor: 20}, {idx: 2, valor: 20}, ]),
				body: nuevoCuerpo,
			}
		});
		// console.log(JSON.stringify(cuerpoPdf));
		// console.log(cuerpoPdf);

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}


export function ECVSFGAnoejeActProySecFuncFuenteFinanc(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Act_Proy');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 130, 40, 40],
			// orientacion: 'landscape',
			// tamanioHoja: 'A3',
			// tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTO. ', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Actividad Proyecto, Secuencia Funcional, Fuente Financ. y Clasificador.', null, {fontSize: 8, bold: true}),
				objThis.imprimirCelda('EJERCICIO DEL ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 11, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Act_Proy',
			'Sec_func',
			'Fuente_Financ',
		]);
		console.log(groups);
		// return false;

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [true, false, false, false],
					});
				}},
			{label: 'ACT.PROY', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'SEC.FUNC..', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'FTE.', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, true, false],
					});
				}},
			{label: 'CLASIF.', property: 'Clasificador', type: 'text'},
			{label: 'NOMBRE', property: 'Nombre_Clasificador', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
			{label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: 'GIRADO.', property: 'nGirado', type: 'money'},
			{label: 'PAGADO.', property: 'nPagado', type: 'money'},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nCompromiso',
			'nDevengado',
			'nGirado',
			'nPagado',
		]);


		const agrupadoActProy = groupBy(resultadosGenerales, (obj) => {
			return obj.Act_Proy;
		});

		console.log(agrupadoActProy);
		let dataActProy = {
			Ano_eje: '',
			Act_Proy: '',
			cNombre_Act_proy: '',
			nPim: '',
			nCertificado: '',
			nCompromiso: '',
			nDevengado: '',
			nGirado: '',
			nPagado: '',
		};


		let pag = 0;
		let nuevoCuerpo = [];

		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoActProy).forEach((secActProy) => {
			dataActProy = {
				...{
					Ano_eje: agrupadoActProy[secActProy][0]['Ano_eje'],
					Act_Proy: agrupadoActProy[secActProy][0]['Act_Proy'],
					cNombre_Act_proy: agrupadoActProy[secActProy][0]['cNombre_Act_proy'],
				},
				...sumarObjetos(agrupadoActProy[secActProy], [
					'nPim',
					'nCertificado',
					'nCompromiso',
					'nDevengado',
					'nGirado',
					'nPagado',
				]),
			};

			const agrupadoSecfunc = groupBy(agrupadoActProy[secActProy], (obj) => {
				return obj.Sec_func;
			});

			let dataSecfunc = {
				Sec_func: '',
				cNombre_Finalidad: '',
				nPim: '',
				nCertificado: '',
				nCompromiso: '',
				nDevengado: '',
				nGirado: '',
				nPagado: '',
			};

			let pag2 = 0;
			Object.keys(agrupadoSecfunc).forEach((secSecfunc) => {
				dataSecfunc = {
					...{
						Sec_func: agrupadoSecfunc[secSecfunc][0]['Sec_func'],
						cNombre_Finalidad: agrupadoSecfunc[secSecfunc][0]['cNombre_Finalidad'],
					},
					...sumarObjetos(agrupadoSecfunc[secSecfunc], [
						'nPim',
						'nCertificado',
						'nCompromiso',
						'nDevengado',
						'nGirado',
						'nPagado',
					]),
				};

				if (pag2 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([
						[
							imprimirCelda(dataActProy.Ano_eje, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center',
							}),
							imprimirCelda(dataActProy.Act_Proy, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center',
								// rowSpan: tablaGenerada['cuerpo'].length + 1,
							}),
						].concat(
							generarColSpan(imprimirCelda(dataActProy.cNombre_Act_proy, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'left'
							}), 4).concat(
								imprimirCelda(dataActProy.nPim, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nCertificado, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nCompromiso, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nDevengado, 'money', {
									bold: true,
									style: 'thSmall',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								imprimirCelda(dataActProy.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							),
						)
					]);
				}
				// agrupadoSecfunc[secSecfunc] = ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Fuente_Financ', true)
				const agrupadoFuenteFinanc = groupBy(agrupadoSecfunc[secSecfunc], (obj) => {
					return obj.Fuente_Financ;
				});
				let dataFuenteFinanc = {
					Fuente_Financ: '',
					Nombre_Fuente_Financ: '',
					nPim: '',
					nCertificado: '',
					nCompromiso: '',
					nDevengado: '',
					nGirado: '',
					nPagado: '',
				};

				let pag3 = 0;
				ordenarArray(Object.keys(agrupadoFuenteFinanc), true).forEach((secFuenteFinanc) => {
					dataFuenteFinanc = {
						...{
							Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_Financ'],
							Nombre_Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_Fuente_Financ'],
						},
						...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
							'nPim',
							'nCertificado',
							'nCompromiso',
							'nDevengado',
							'nGirado',
							'nPagado',
						]),
					};

					const tablaGenerada = objThis.generarDesdeMantenimiento({
						titulos: camposPrincipal,
						contenido: ordenarPorPropiedad(agrupadoFuenteFinanc[secFuenteFinanc], 'Clasificador'),
						// numeracion: true,
						idxResto: [6],
						margin: [0, 0, 0, 5],
						separado: true,
					});


					if (pag3 == 0) {
						nuevoCuerpo = nuevoCuerpo.concat([
								generarColSpan(imprimirCelda('', null, {
									bold: true,
									style: 'thSmall',
									alignment: 'left'
								}), 2).concat(
									imprimirCelda(dataSecfunc.Sec_func, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'center',
										// rowSpan: tablaGenerada['cuerpo'].length + 1,
									}),
									generarColSpan(imprimirCelda(dataSecfunc.cNombre_Finalidad, null, {
										bold: true,
										style: 'thSmall',
										alignment: 'left'
									}), 3),
									imprimirCelda(dataSecfunc.nPim, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(dataSecfunc.nCertificado, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(dataSecfunc.nCompromiso, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(dataSecfunc.nDevengado, 'money', {
										bold: true,
										style: 'thSmall',
										alignment: 'right'
									}),
									imprimirCelda(dataSecfunc.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
									imprimirCelda(dataSecfunc.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
								),
							// )
						]);
					}

					nuevoCuerpo = nuevoCuerpo.concat([

						generarColSpan(imprimirCelda('', null, {
							bold: true,
							style: 'thSmall',
							alignment: 'left'
						}), 3).concat(
							// ].concat(
							imprimirCelda( dataFuenteFinanc.Fuente_Financ, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'center'
							}),
							generarColSpan(imprimirCelda(dataFuenteFinanc.Nombre_Fuente_Financ, null, {
								bold: true,
								style: 'thSmall',
								alignment: 'left'
							}), 2),
							imprimirCelda(dataFuenteFinanc.nPim, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataFuenteFinanc.nCertificado, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataFuenteFinanc.nCompromiso, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataFuenteFinanc.nDevengado, 'money', {
								bold: true,
								style: 'thSmall',
								alignment: 'right'
							}),
							imprimirCelda(dataFuenteFinanc.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
							imprimirCelda(dataActProy.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						),

					]);

					nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
					pag3++;
				});
				pag2++;
			});
			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				style: 'thSmall',
				alignment: 'right'
			}), 6).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'left'
				}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCompromiso, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nDevengado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nGirado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda(sumasTotales.nPagado, 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 30}, {idx: 1, valor: 30}, {idx: 2, valor: 30},{idx: 3, valor: 30}, ]),
				body: nuevoCuerpo,
			}
		});
		// console.log(JSON.stringify(cuerpoPdf));
		// console.log(cuerpoPdf);

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}

export function ECVSFGFuncionSecFunc(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Funcion_Sec_Func'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Funcion_Sec_Func'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 110, 40, 40],
			// orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTOS POR FUNCIÓN', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('EJERCICIO DEL ' + anioEjec, null, {fontSize: 8, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: 'Ano_ejec', type: 'text'},
			{label: 'FUNC.', property: 'Funcion', type: 'text'},
			{label: 'NOMBRE', property: 'Nombre_Funcion', type: 'text'},
			{label: 'PIA', property: 'nPia', type: 'money'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'COMPROMISO', property: 'nCompromiso', type: 'money'},
			{label: '% COM', property: 'nPorcCompromiso', type: 'number'},
			{label: 'DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: '% DEV', property: 'nPorcDevengado', type: 'number'},
			{label: 'GIRADO.', property: 'nGirado', type: 'money'},
			{label: '% GIR', property: 'nPorcGirado', type: 'number'},
			{label: 'PAGADO', property: 'nPagado', type: 'money'},
			{label: '% PAG', property: 'nPorcPagado', type: 'number'},
			{label: 'SALDO COMPR.', property: 'nSaldoCompromiso', type: 'money'},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
			numeracion: true,
			idxResto: [4],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPia',
			'nPim',
			'nCompromiso',
			'nPorcCompromiso',
			'nDevengado',
			'nPorcDevengado',
			'nGirado',
			'nPorcGirado',
			'nPagado',
			'nPorcPagado',
			'nSaldoCompromiso',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// widths: objThis.anchoCols(tablaGenerada['cuerpo'], [3]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
					[
						// generarColSpan(imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right'}),  5),
						imprimirCelda('', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 4}),
						{}, {}, {},
						imprimirCelda(sumaObj['nPia'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(sumaObj['nCompromiso'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda((sumaObj['nCompromiso'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall',
                            alignment: 'center'
						}),
						imprimirCelda(sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda((sumaObj['nDevengado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall',
                            alignment: 'center'
						}),
						imprimirCelda(sumaObj['nGirado'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda((sumaObj['nGirado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall',
                            alignment: 'center'
						}),
						imprimirCelda(sumaObj['nPagado'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda((sumaObj['nPagado'] * 100) / sumaObj['nPim'], 'mon', {
							bold: true,
							style: 'thSmall',
                            alignment: 'center'
						}),
						imprimirCelda(sumaObj['nSaldoCompromiso'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
					],
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}

export function ECVSFGFuentefinanc(objThis: PdfPresupuestoService, anioEjec, idsEnviar) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Fuente_financ'], {
		cCodigoCadena: idsEnviar,
		cAno_eje: anioEjec,
	}).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Fuente_financ'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Sec_func');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 80, 40, 40],
			// orientacion: 'landscape',
			tamanioFuenteExtra: 1,
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE GASTO PRESUPUESTAL POR RUBRO', null, {fontSize: 13, bold: true}),
				objThis.imprimirCelda('', null, {fontSize: 8, bold: true}),
			]),
		});

		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: '\nRUBRO.', property: 'Fuente_financ', type: 'text'},
			{label: '\nDENOMINACIÓN.', property: 'Nombre_Fuente_financ', type: 'text'},
			{label: '\nPIA', property: 'nPia', type: 'money'},
			{label: '\nPIM', property: 'nPim', type: 'money'},
			{label: 'EJECUTADO DEVENGADO', property: 'nDevengado', type: 'money'},
			{label: '\nSALDO', property: 'nSaldoDevengado_Pim', type: 'money', transformar(row): any {
					return row.nPim - row.nDevengado;
				}},
			{label: '% PIA', property: 'nPorcDevengado_Pia', type: 'number'},
			{label: '% PIM', property: 'nPorcDevengado_Pim', type: 'number'},
		];

		const tablaGenerada = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: ordenarPorPropiedad(resultadosGenerales, 'Sec_func'),
			numeracion: true,
			// idxResto: [8],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		const sumaObj = sumarObjetos(resultadosGenerales, [
			'nPia',
			'nPim',
			'nDevengado',
			'nSaldoDevengado_Pim',
			'nPorcDevengado_Pia',
			'nPorcDevengado_Pim',
		]);

		cuerpoPdf.push({
			margin: [0, 5, 0, 10],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				widths: objThis.anchoCols(tablaGenerada['cuerpo'], [2]),
				body: tablaGenerada['titulos'].concat(tablaGenerada['cuerpo']).concat([
					[
						imprimirCelda('TOTAL S/', null, {bold: true, style: 'thSmall', alignment: 'right', colSpan: 3}),
						{}, {},
						imprimirCelda(sumaObj['nPia'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(sumaObj['nPim'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda(sumaObj['nPim'] - sumaObj['nDevengado'], 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
						imprimirCelda('', null, {bold: true, style: 'tdSmall', alignment: 'right', border: [false, false, false, false], colSpan: 2}),
						{},

					],
				]),
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);

	});
}
