import {Component, Input, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ColumnaTabla, DataServidor, OpcionSeleccionada, TablaMantenimientoSeccionBotones} from '@JVSoft/interfaces/global';
import {QueryService} from '@servicios/query.service';
import {AbstractControl, FormBuilder} from '@angular/forms';
import {PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';
import {MatDialogConfig} from '@angular/material/dialog';

export interface ParamTabTabla {
    label: string;
    nombreColeccion: string;
    src: string;
    srcParams?: {
        campo: string,
        valor: AbstractControl<any, any> | string,
    }[];
    columnasTabla: ColumnaTabla<any>[];
    cargando?: boolean;
    _data?: BehaviorSubject<any>;
    iTareaIdActual?: number;
    idTabla?: string[];
    filaExtraTemplate?: TemplateRef<any>;
    detalle?: ParamTabTabla[];
}

@Component({
    selector: 'general-jerarquias-tabs-tablas',
    templateUrl: './jerarquias-tabs-tablas.component.html',
    styleUrls: ['./jerarquias-tabs-tablas.component.scss']
})
export class JerarquiasTabsTablasComponent {
    @Input() objThis;
    @Input() listaTabs: ParamTabTabla[] = [];
    @Input() indexTab: number = 0;


    dataServidorSuscripcion: DataServidor = {};
    dataServidor = {
        log: {
            vales: null,
        },
    };
    seleccionados = {
        pedidos: null,
        ordenes: null,
        expedientes_gastos: null,
    };
    filtrados = {};

    mostrarLista = true;
    cargandoContenido = false;

    get itemListaTab(): ParamTabTabla {
        return this.listaTabs[this.indexTab];
    }

    get tabCargado() {
        if (!this.itemListaTab) {
            return false;
        }
        return !this.cargandoContenido && this.itemListaTab._data.getValue();
    }


    botonesMantenimiento: TablaMantenimientoSeccionBotones;

    constructor(
        private queryService: QueryService,
        private fb: FormBuilder,
        private pdfPresupuesto: PdfPresupuestoService,
    ) {
    }

    accionPreviaCambiarTab() {
        this.cargandoContenido = true;
    }

    cambiarTab(forzarCarga = false) {
        console.log('DATA CARGANDO')
        // if (this.mostrarLista) {
        if (this.itemListaTab) {
            if (forzarCarga) {
                this.itemListaTab['iTareaIdActual'] = null;
            }
            console.log('DATA TATA');
            const dEnviarExtra = {};
            switch (this.itemListaTab.nombreColeccion) {
                case 'pedidos':
                    // this.queryService.cargar(this, [this.itemListaTab.src], this.filtroSeleccion).then(dRet => {
                    //     // this._dataPrincipal.next(dRet[this.itemListaTab.src])
                    //     this.itemListaTab._data.next(dRet[this.itemListaTab.src])
                    // });
                    break;
                case 'ordenes':
                    break;
                case 'expedientes_gastos':
                    // dEnviarExtra['cCodigoTipoOperacion'] = this.frmFiltro.get('cCodigoTipoOperacion').value;
                    break;
            }
            // if (this.itemListaTab.)
            this.queryService.cargar(this, [this.itemListaTab.src], {/*...this.frmFiltro.value,*/ ...dEnviarExtra}).then(dRet => {
                this.itemListaTab._data.next(dRet[this.itemListaTab.src]);
                this.itemListaTab.cargando = false;
                this.cargandoContenido = false;
            });

        }


        // }
    }

    cargarDetalle(item, seleccionado, inicial = false) {
        console.log(item);
        const srcEnviar = [];
        let dataEnviar = {};

        this.itemListaTab.detalle.forEach(det => {
            srcEnviar.push(det.src);
            if (det.srcParams) {
                det.srcParams.forEach(datDet => {
                    if (typeof datDet.valor == 'string') {
                        dataEnviar[datDet.campo] = item[datDet.valor];
                    }
                    else {
                        dataEnviar[datDet.campo] = datDet.valor.value;
                    }
                });
            }
        });


        return this.queryService.cargar(this, srcEnviar, dataEnviar).then(res => {
            this.itemListaTab.detalle.forEach(det => {
                item[det.nombreColeccion] = res[det.src];
            });

        });
    }

    opcMenu(v: OpcionSeleccionada) {
        console.log(v);
        const dialogOpc: MatDialogConfig = {
            panelClass: 'dialogMantenimientoNuevo',
            disableClose: true,
            data: {},
        };

        let lista;
        const idCompuesto = (value, partesId: string[], separador = '|') => {
            const strId = [];
            partesId.forEach(pId => {
                strId.push(value[pId]);
            });
            return strId.join(separador);
        };

        switch (v.seccion) {
            case 'pedidos':
                switch (v.tipo) {
                    case 'ver':
                        /*
                        if (this.seleccionados.pedidos == v.item) {
                            this.seleccionados.pedidos = null;
                        }
                        else {
                            this.seleccionados.pedidos = v.item;
                        }
                        */
                        break;
                }
                break;
        }
    }

}
