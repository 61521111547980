<ng-container [formGroup]="frmPrincipal">
    <div class="flex items-center justify-between w-full">

        <mat-select formControlName="iYearId" class="flex-auto select" [ngStyle]="{ opacity: (disabled ? 0.5 : 1) }" [disabled]="disabled"
            (selectionChange)="cambiarYear($event.value)"
        >
            <mat-option [value]="item.iYearId" *ngFor="let item of dataServidorSuscripcion['grl.years'] | async">
                <span class="text-sm">{{ item.iYearId }}</span>
            </mat-option>
        </mat-select>

         <mat-divider [vertical]="true" class="flex-none divider"></mat-divider>
        <textarea matInput #txtFiltro
        
            type="text" class="grow" [placeholder]="shouldLabelFloat ? placeholder : ''"  autocomplete="off"
            [ngStyle]="{ opacity: disabled ? 0.5 : 1 }" name="buscar"
            [ngClass]="{ 'form-input border-2 mt-1 w-full text-2xs font-thin bg-white text-black': sinContenedor}"
            cdkTextareaAutosize cdkAutosizeMinRows="1"
            [class.mat-buscar-tarea-input-active]="showPanel$ | async"
            cdkOverlayOrigin #trigger="cdkOverlayOrigin"
        ></textarea>


        <div matSuffix class="flex items-center justify-start">
            <button matRipple type="button"
                class="boton-circular-gris"
                (click)="(frmPrincipal.get('iTareaId').value ? limpiarTarea() : null); $event.preventDefault();"
            >
                <mat-icon [svgIcon]="(frmPrincipal.get('iTareaId').value ? 'roundCancel' : 'roundSearch')" class="icon-xs"></mat-icon>
            </button>
        </div>

    </div>
</ng-container>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="(showPanel$ | async) && !frmPrincipal.get('iTareaId').value"
>
    <section class="mat-buscar-tarea-panel mat-elevation-z5 bg-white" cdkMenu>
        <button class="mat-buscar-tarea-panel-item" cdkMenuItem
            (click)="seleccionarTarea(item)"
            *ngFor="let item of (dataServidorSuscripcion['bud.tareas'] | async) | filtro: {texto: txtFiltro.value, campo: ['cTareaCodigo', 'cTareaNombre']}; let i = index"
        >
            <small style="text-align: left !important;"><span class="text-red-700" style="text-align: left !important;">[{{ item.cTareaCodigo }}]</span>&nbsp;{{ item.cTareaNombre }}</small>
        </button>
    </section>
</ng-template>


