import {Component, Inject, OnInit} from '@angular/core';
import {QueryService} from '@servicios/query.service';
import {map} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {ExternosService} from '../../externos/services/externos.service';
import {EntidadService} from '@servicios/entidad.service';
import {ConfigServiceMod} from '@vexMod/config/config.service';
import {VexConfigMod} from '@vexMod/config/vex-config.interface';
import {SharedService} from '@JVSoft/services/shared.service';

@Component({
  selector: 'vex-externos-layout',
  templateUrl: './externos-layout.component.html',
  styleUrls: ['./externos-layout.component.scss']
})
export class ExternosLayoutComponent implements OnInit{
	imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
	imageTitle$ = this.configService.config$.pipe(map((config: VexConfigMod) => config.sidenav.imageTitle));
  constructor(
	  private entidadService: EntidadService,
	  private configService: ConfigServiceMod,
	  private queryService: QueryService,
	  public externosService: ExternosService,
	  @Inject(DOCUMENT) private document: Document,
	  private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
	  if (this.sharedService._dataRutaActual.getValue()) {
		  this.externosService._mostrarReloj.next(!!!this.sharedService._dataRutaActual.getValue()['sinReloj'])
	  }
  }
	estiloFondo() {

		let estilotxt = '';
		if (this.entidadService.loginBackground) {
			estilotxt += 'background-image: url(' + this.entidadService.loginBackground + '); ';
			estilotxt += 'background-size: cover; ';
			estilotxt += 'background-position: center; ';
			estilotxt += 'background-repeat: no-repeat; ';
			// estilotxt += 'opacity: 0.8; ';
		}
		// console.log(estilotxt);
		return estilotxt;
	}

}
