import {Injectable} from '@angular/core';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {AbstractControl, FormGroup, Validators} from '@angular/forms';
import swal, {SweetAlertOptions, SweetAlertResult} from 'sweetalert2';
import {debounceTime, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import {isObservable, of} from 'rxjs';
import {environment} from '@ENV';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ReactiveFormConfig} from '@rxweb/reactive-form-validators';
import {MatDialogRef} from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Buffer} from "buffer";


@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class FuncionesGlobalesService {

    constructor(
        protected toastr: ToastrService,
    ) {
    }

    mensajeToast(tipo: 'success' | 'error' | 'info' | 'warning', titulo, mensaje, opciones?: Partial<IndividualConfig>) {
        opciones = {
            ...opciones,
            ...{
                enableHtml: true,
                progressBar: true,
            }
        };
        this.toastr[tipo](mensaje, titulo, opciones);
    }

    mensajeAlerta(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, opciones?: SweetAlertOptions) {
        return mensajeAlerta(tipo, titulo, mensaje, opciones);
    }

    mensajeTimer(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, milisegundos = 2000, showLoading = true, opciones?: SweetAlertOptions) {
        return mensajeTimer(tipo, titulo, mensaje, milisegundos, showLoading, opciones);
    }

    mensajeConfirmacion(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, opciones?: SweetAlertOptions) {
        return mensajeConfirmacion(tipo, titulo, mensaje, opciones);
    }

    capitalizarTexto(texto) {
        return capitalizarTexto(texto);
    }


    mensajesErrorFormControl(control: AbstractControl): string {
        return mensajesErrorFormControl(control);
    }

    mensajesDeError(error) {
        mensajesDeError(error);
    }

    mostrarValorEnBusqueda(campos, idxSel) {
        return mostrarValorEnBusqueda(campos, idxSel);
    }


    formatearFecha(val) {
        return formatearFecha(val);
    }

    cambiosChips(formControl: AbstractControl, accion: 'add' | 'remove', valor) {
        cambiosChips(formControl, accion, valor);
    }
}

export function capitalizarTexto(texto) {
    texto = texto.replace(/_/g, ' ');
    texto = texto.replace(/-/g, ' ');
    const textoCortado = texto.split(' ');

    const nText = [];
    textoCortado.forEach(textActual => {
        nText.push(textActual.charAt(0).toUpperCase() + textActual.slice(1));
    });
    return nText.join(' ');
}

export function ordenarArray(array: any[], numeros = false, sentido: 'ASC' | 'DESC' = 'ASC'): any[] {
    if (numeros) {
        if (sentido != 'ASC') {
            return array.sort((a: any, b: any) => b - a);
        }
        return array.sort((a: any, b: any) => a - b);
    }
    return array.sort((a, b) => (a > b) ? 1:((b > a) ? -1:0));

}

export function ordenarPorPropiedad(objData, propiedad: string, numeros = false) {
    if (numeros) {
        return objData.sort((a, b) => a[propiedad] - b[propiedad]);
    }
    return objData.sort((a, b) => (a[propiedad] > b[propiedad]) ? 1:((b[propiedad] > a[propiedad]) ? -1:0));
}

export function ordenarPorPropiedades<T>(arr: T[], options: {
    propiedades: string[],
    direcciones?: ('asc' | 'desc' | '')[],
}) {
    const {propiedades, direcciones = []} = options;
    const orden = direcciones.map(d => d === 'desc' ? -1:1);
    return arr.sort((a, b) => {
        return propiedades.reduce((acc, propiedad, index) => {
            if (acc === 0) {
                const aValue = a[propiedad];
                const bValue = b[propiedad];
                if (aValue < bValue) {
                    return orden[index] * -1;
                }
                else if (aValue > bValue) {
                    return orden[index] * 1;
                }
                else {
                    return 0;
                }
            }
            return acc;
        }, 0);
    });
}

export function isNumberRound(number: any, decimal): number {
    let x;
    if (esNumero(number)) {
        x = parseFloat(number).toFixed(decimal);
    }
    else {
        x = number;
    }
    return x;
}

export function markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
        control.updateValueAndValidity({onlySelf: true});
        // control.updateValueAndValidity();
        control.markAsTouched();

        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
}

export function getInitials(texto: string, glue: string = '') {
    const initials = texto.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)?.slice(0) ?? [];

    return initials.join(glue) ?? '';
}

export function mensajeToast(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, opciones?: SweetAlertOptions) {
    opciones = {
        ...{
            heightAuto: false,
            title: titulo,
            html: mensaje,
            icon: tipo,
            confirmButtonText: 'Aceptar',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        },
        ...opciones
    };
    return swal.fire(opciones);
}

export function mensajeMostrarValidacion(mensaje) {
    return swal.showValidationMessage(mensaje);
}

export function mensajeAlerta(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, opciones?: SweetAlertOptions) {
    opciones = {
        ...{
            heightAuto: false,
            title: titulo,
            html: mensaje,
            icon: tipo,
            confirmButtonText: 'Aceptar',
            // customClass: {
            // 	confirmButton: 'btn btn-lg btn-outline-success mx-2',
            // 	cancelButton: 'btn btn-lg btn-outline-dark mx-2'
            // },
            // buttonsStyling: false
        },
        ...opciones
    };
    return swal.fire(opciones);
}

export function mensajeTimer(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, milisegundos = 3000, showLoading = true, opciones?: SweetAlertOptions) {
    let timerInterval;
    opciones = {
        ...{
            heightAuto: false,
            title: titulo,
            html: mensaje + '<br> Se cerrará en <strong> X </strong> segundos.',
            icon: tipo,
            timer: milisegundos,
            showCancelButton: false,
            showConfirmButton: false,
            willOpen: () => {
                if (showLoading) {
                    swal.showLoading();
                }
                timerInterval = setInterval(() => {
                    const impr = Math.ceil((swal.getTimerLeft() / 1000));
                    if (swal.getHtmlContainer()) {
                        swal.getHtmlContainer().querySelector('strong').textContent = String(impr);
                    }
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        },
        ...opciones
    };
    return swal.fire(opciones);
}

// @ts-ignore
export function mensajeConfirmacion(tipo: 'success' | 'error' | 'info' | 'warning' | 'question', titulo, mensaje, opciones?: SweetAlertOptions): Promise<SweetAlertResult<Awaited<any>>> {
    opciones = {
        ...{
            heightAuto: false,
            title: titulo,
            html: mensaje,
            icon: tipo,
            showCancelButton: true,
            // confirmButtonColor: '#3f51b5',
            // cancelButtonColor: '#ffffff',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            // customClass: {
            // 	confirmButton: 'btn btn-lg btn-outline-success mx-2',
            // 	cancelButton: 'btn btn-lg btn-outline-dark mx-2'
            // },
            // buttonsStyling: false
        },
        ...opciones
    };
    return swal.fire(opciones);
}

export function mensajesErrorFormControl(control: AbstractControl): string {

    ReactiveFormConfig.set({
        // RxwebValidators
        validationMessage: {
            required: 'Es requerido',
            numeric: 'Debe ser numérico valido',
            // minLength: 'minimum length is {{1}}',
            // maxLength: 'allowed max length is {{1}}',
        },
    });
    let x: any = '';
    if (control) {
        // if (control.invalid) {
        // 	console.warn(control);
        // }
        if (control.touched && control.hasError('required')) {
            x += '\n- Es requerido \n';
        }
        if (control.touched && control.hasError('min')) {
            x += '- Valor mínimo ' + control.errors.min.min + '\n';
        }
        if (control.touched && control.hasError('minValue')) {
            x += '- Debe ser positivo\n';
        }
        if (control.touched && control.hasError('minlength')) {
            x += '- Mínimo ' + control.errors.minlength.requiredLength + ' caracteres.\n';
        }
        if (control.touched && control.hasError('maxlength')) {
            x += '- Caracteres ' + control.errors.maxlength.actualLength + '/' + control.errors.maxlength.requiredLength + '\n';
        }
        // if (control.touched && control.hasError('maxlength')) { x = 'Máximo ' + control.errors.maxlength.requiredLength + ' caracteres.\n'; }
        if (control.touched && control.hasError('email')) {
            x += '- No se cumple con el formato de Correo Electrónico\n';
        }
        if (control.touched && control.hasError('isNumeric')) {
            x += '- Debe seleccionar una opción\n';
        }
        if (control.touched && control.hasError('hasNumber')) {
            x += '- Se requiere al menos un numero\n';
        }
        if (control.touched && control.hasError('hasCapitalCase')) {
            x += '- Se requiere al menos una mayúscula\n';
        }
        if (control.touched && control.hasError('hasSmallCase')) {
            x += '- Se requiere al menos una minúscula\n';
        }
        if (control.touched && control.hasError('hasSpecialCharacters')) {
            x += '- Se requiere al menos un carácter especial\n';
        }
        if (control.touched && control.hasError('NoPassswordMatch')) {
            x += '- La contraseña no coincide\n';
        }
        if (control.touched && control.hasError('itemSelected')) {
            x += '- Debe seleccionar una opción de la lista\n';
        }
        if (control.touched && control.hasError('inputMask')) {
            x += '- El formato de ingresado no es válido\n';
        }

        // if (control.touched && control.hasError('numeric')) { x = 'El campo debe ser un número'; }
        if (x == '' && control.errors) {
            const errores = Object.values(control.errors);
            if (errores.length > 0) {
                x = errores[0]['message'] ?? '';
            }

            // Object.values(control.errors).forEach(error => {
            //     x += '- ' + error.message;
            // });
        }
        // console.log(control.errors);
        return x;
        // etc.
        // console.log(control.errors);
    }
    return '';
}
export function changeSelectData(objThis: any, dataFiltro: {
    formControl: AbstractControl,
    data: any[],
    campoBuscar: string | string[],
    variableResultado: string,
}) {
    objThis['filtrados'][dataFiltro.variableResultado] = dataFiltro.formControl.valueChanges.pipe(untilDestroyed(objThis)).pipe(
        startWith(''),
        map(value => {

            const varN = dataFiltro.data;
            if (varN) {
                if (value) {
                    return varN.map(x => x).filter(dat => {
                        if (Array.isArray(dataFiltro.campoBuscar)) {
                            let encontrado = false;

                            for (const vCampo of dataFiltro.campoBuscar) {
                                // console.log(vCampo, value, dat[vCampo]);
                                if (isNaN(Number(value))) {
                                    // NO ES NUMERO
                                    if (value && dat[vCampo] && dat[vCampo].toLowerCase().includes(value?.toString().toLowerCase())) {
                                        encontrado = true;
                                        break;
                                    }
                                }
                                else {
                                    if (value && dat[vCampo] && dat[vCampo].toString().includes(value?.toString())) {
                                        encontrado = true;
                                        break;
                                    }
                                }
                            }
                            return encontrado;
                        }
                        else {
                            if (isNaN(Number(value))) {
                                return dat[dataFiltro.campoBuscar].toLowerCase().includes(value?.toString().toLowerCase());
                            }
                            else {
                                return dat[dataFiltro.campoBuscar].toString().includes(value?.toString());
                            }
                        }
                    });
                }
                return varN;
            }
            return false;
        })
    );
}
export function changeSelect(control, formControl: AbstractControl, tipo, campoBuscar, campoFiltro = null) {
    // console.log(formControl);
    // const formGroup = formControl.parent.controls;
    // console.warn( Object.keys(formGroup).find(name => formControl === formGroup[name]) || null );
    if (!campoFiltro) {
        campoFiltro = tipo;
    }
    control['filtrados'][campoFiltro] = formControl.valueChanges.pipe(untilDestroyed(control)).pipe(
        startWith(''),
        map(value => {
            console.warn(value);

            const partes = tipo.split('.');

            const varN = (partes.length > 1) ? control['dataServidor'][partes[0]][partes[1]]:control['dataServidor'][tipo];
            if (varN) {
                if (value) {
                    return varN.map(x => x).filter(dat => {
                        if (Array.isArray(campoBuscar)) {
                            let encontrado = false;

                            for (const vCampo of campoBuscar) {
                                // console.log(vCampo, value, dat[vCampo]);
                                if (isNaN(Number(value))) {
                                    // NO ES NUMERO
                                    if (value && dat[vCampo] && dat[vCampo].toLowerCase().includes(value?.toString().toLowerCase())) {
                                        encontrado = true;
                                        break;
                                    }
                                }
                                else {
                                    if (value && dat[vCampo] && dat[vCampo].toString().includes(value?.toString())) {
                                        encontrado = true;
                                        break;
                                    }
                                }
                            }
                            return encontrado;
                        }
                        else {
                            if (isNaN(Number(value))) {
                                return dat[campoBuscar].toLowerCase().includes(value?.toString().toLowerCase());
                            }
                            else {
                                return dat[campoBuscar].toString().includes(value?.toString());
                            }
                        }
                    });
                }
                return varN;
            }
            return false;
        })
    );
}

export function changeSelectApi(control, queryService, formControl: AbstractControl, tipo, dataExtra = {}, dataExtraVariable = null, minLength = 1, anonimo = false) {
    formControl.valueChanges.pipe(
        debounceTime(500),
        tap((value) => {
            control['filtrados'][tipo + 'tmp'] = isObservable(control['filtrados'][tipo]) ? []:control['filtrados'][tipo];
            if (control['filtrados'][tipo] != control['filtrados'][tipo + 'tmp']) {
                control['filtrados'][tipo] = [];
            }
            control['isLoading'] = true;
        }),

        switchMap(value => {
            const formGroup = formControl.parent.controls;
            const nombreControl = Object.keys(formGroup).find(name => formControl === formGroup[name]) || null;
            if (nombreControl && control['filtrados'][tipo + 'tmp'] && control['filtrados'][tipo + 'tmp'].length > 0) {
                const busquedaActual = control['filtrados'][tipo + 'tmp'].findIndex(item => item[nombreControl] == value);
                if (busquedaActual >= 0) {
                    const vRet = {};
                    vRet[tipo] = control['filtrados'][tipo + 'tmp'];
                    control['isLoading'] = false;
                    return of(vRet);
                }
            }
            if (!value || value.length < minLength) {
                return [];
            }

            const dataExtraVariableData = {};
            if (dataExtraVariable) {
                for (const objData of dataExtraVariable) {
                    dataExtraVariableData[objData.campo] = objData.ctrlValue.value;
                }
            }
            return queryService.getDataMethod('GET', tipo, {...dataExtra, ...dataExtraVariableData, ...{txtBuscar: value}}, anonimo).pipe(
                finalize(() => {
                    control['isLoading'] = false;
                }),
            );

        })
    ).subscribe(data => {
        if (data[tipo] == undefined) {
            control['filtrados'][tipo] = [];
        }
        else {
            control['filtrados'][tipo] = data[tipo];
        }
    });
}

export function mensajesDeError(error: HttpErrorResponse, toast = false) {
    let msg;

    if (error.error && error.error instanceof ArrayBuffer) {
        const msgArrayBuffer = (arrayBuffer: ArrayBuffer): string => {
            try {
                const mensajeError = JSON.parse(new TextDecoder("utf-8").decode(arrayBuffer));
                return mensajeError.message || 'Error desconocido';
            } catch (parseError) {
                console.error('Error al analizar la respuesta JSON:', parseError);
                return 'Error al analizar la respuesta JSON';
            }
        }
        msg = msgArrayBuffer(error.error);
    }
    else {

        switch (error.status) {
            case 0:
                // msg = error.message;
                msg = 'El servidor no responde, verifica tu conexion a la red';
                console.log(error);
                break;
            case 401:
                if (error.error && typeof error.error == 'object') {
                    if (error.error.message) {
                        msg = error.error.message;
                    }
                    else if (error.error.error) {
                        msg = error.error.error;
                    }
                    else {
                        msg = JSON.stringify(error.error);
                        console.log(error);
                    }
                }
                else if (error.error && typeof error.error == 'string') {
                    msg = error.error;
                }
                else {
                    msg = 'Acceso no autorizado';
                    console.log(error);
                }
                break;
            case 422:
                let strEr = '';

                console.log(typeof error.error.errors);
                console.log(error.error.errors);
                if (error.error.errors) {
                    Object.keys(error.error.errors).forEach(o => {
                        strEr += '<li>' + error.error.errors[o][0] + '</li>';
                    });
                    msg = (error.error.message ?? '') + '<ul>' + strEr + '</ul>';
                }
                else if (error.error.error) {
                    msg = error.error.msg;
                }
                break;
            case 429:
            case 400:
            case 500:
            case 503:
                msg = error.error.message;
                break;
            case 504:
                msg = 'No se puede conectar al servidor. Comprueba tu conexion a la red - ' + error.statusText;
                break;
            default:
                msg = 'Error de Sistema';
                console.log(error);
                break;
        }
    }
    if (!msg) {
        msg = error.statusText ?? 'Error inesperado o datos inexistentes.';
    }

    let tituloFinal = 'Error!';
    let mensajeFinal;

    if (msg.includes('Hmac::doVerify')) {
        console.log(error, msg);
        return;
    }
    else if (msg == 'Server Error') {
        console.log(error, msg);
        return;
    }
    else if (msg.includes('[IMSSP]')) {
        if (!environment.production) {
            tituloFinal = 'Error DEBUG - Localhost!';
            mensajeFinal = msg;
        }
        else {
            mensajeFinal = 'Error en consulta de registros.';
        }
    }
    else if (msg.includes('Tiempo de espera de la')) {
        if (!environment.production) {
            tituloFinal = 'Error TIMEOUT!';
            mensajeFinal = msg;
        }
        else {
            mensajeFinal = 'Hubo un error en la solicitud de datos, por favor actualice (SHIFT + F5)';
        }
    }
    else {
        mensajeFinal = msg;
    }
    if (toast) {
        mensajeToast('error', tituloFinal, mensajeFinal);
    }
    else {
        mensajeAlerta('error', tituloFinal, mensajeFinal);
    }

}

export function mostrarValorEnBusqueda(campos, idxSel) {
    const impDataMostrar = () => {
        let vD;
        if (campos.campoId == '*object*' || campos.campoId == '*objeto*') {
            console.log(campos);
            vD = campos.lista.find(x => JSON.stringify(x).trim() == JSON.stringify(idxSel).trim());
            console.log(vD);
        }
        else {
            vD = campos.lista.find(x => x[campos.campoId] == idxSel);
        }
        if (!vD && campos.opcExtra) {
            console.log('eval ', campos.opcExtra);
            if (campos.campoId == '*object*' || campos.campoId == '*objeto*') {
                console.log(campos);
                vD = campos.opcExtra.find(x => JSON.stringify(x).trim() == JSON.stringify(idxSel).trim());
                console.log(vD);
            }
            else {
                vD = campos.opcExtra.find(x => x[campos.campoId] == idxSel);
            }
        }
        if (vD) {
            let txtFinal = '';
            if (Array.isArray(campos.campoValue)) {
                campos.campoValue.forEach((vCampo, idx) => {
                    txtFinal += (vD[vCampo] ?? '');
                    if (idx < campos.campoValue.length - 1) {
                        txtFinal += ' - ';
                    }
                });
            }
            else {
                txtFinal = vD[campos.campoValue] ?? '';
            }
            return txtFinal.trim();
        }
        else {
            console.log('ASSSSS ----- SSSS ');
        }
    };
    switch (campos.tipo) {
        case 'cadena':
            if (campos.lista?.length > 0) {
                return impDataMostrar();
            }
            break;
        default:
            if (idxSel && idxSel > 0 && campos.lista?.length > 0) {
                return impDataMostrar();
            }
            else if (idxSel && typeof idxSel == 'object') {
                return impDataMostrar();
            }
            break;
    }
    return '';
}

export function formatearFecha(val, hora = '00:00:00') {
    if (val) {
        if (val.length <= 10) {
            val = val + ' ' + hora;
        }
        return new Date(val);
    }
    return val;
}
export function formatearFechaCadena(fecha: string): Date {
    const year = parseInt(fecha.substring(0, 4), 10);
    const month = parseInt(fecha.substring(4, 6), 10) - 1;
    const day = parseInt(fecha.substring(6, 8), 10);
  
    return new Date(year, month, day);
  }
export function toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        console.log(value);
        if (typeof value == 'object') {
            value.filter(a => {
                console.log(a);
                if (a.file) {
                    formData.append(key + '[]', a['file']);
                }
                else {
                    formData.append(key + '[]', a);
                }
                // formData.append(key + '_desc[]', JSON.stringify(a));
            });
        }
        else {
            formData.append(key, value);
        }
        // console.log(typeof value);
        // console.log(value);
        // console.log(value.length);
    }

    return formData;
}

export function cambiosChips(formControl: AbstractControl, accion: 'add' | 'remove', valor) {
    console.log(formControl);
    switch (accion) {
        case 'add':
            const val = (valor || '').trim();
            if (val) {
                if (!formControl.value) {
                    formControl.setValue([]);
                }
                formControl.value.push(valor);
            }
            break;
        case 'remove':
            const index = formControl.value.indexOf(valor);
            if (index >= 0) {
                formControl.value.splice(index, 1);
            }
            break;
    }
}

export function objectPropertiesBoolean(formFields: object, retorno: 'boolean' | 'bit' = 'boolean') {
    Object.keys(formFields).forEach(control => {
        const valRetorno = (ctrl) => {
            switch (retorno) {
                case 'boolean':
                    formFields[ctrl] = formFields[ctrl] == 1;
                    break;
                case 'bit':
                    formFields[ctrl] = formFields[ctrl] ? 1:0;
                    break;
            }
        };
        if (control.charAt(0) == 'b' || (control.charAt(0) == 'i' && control.indexOf('Estado') !== -1)) {
            valRetorno(control);
        }
    });
    return formFields;
}

export function objectPropertiesDate(formFields: object) {
    Object.keys(formFields).filter(control => !!formFields[control]).forEach(control => {
        if (/dt[a-zA-Z]+/.test(control)) {
            formFields[control] = formatDate(formFields[control], 'yyyy-MM-dd HH:mm', 'es-PE');
        }
        else if (control.charAt(0) == 'd') {
            formFields[control] = formatearFecha(formFields[control]);
        }
    });
    return formFields;
}

export function generarArbol(lista: any[], campoId: string, campoIdPadre: string, idPadre = null, strChildren = 'hijos') {
    const node2 = [];
    lista.filter(n => n[campoIdPadre] === idPadre).forEach(n => {
        n[strChildren] = generarArbol(lista, campoId, campoIdPadre, n[campoId], strChildren);
        node2.push(n);
    });
    return node2;
}

export function groupBy<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    key: K | ((obj: T) => string)
): Record<string, T[]> {
    const keyFn = key instanceof Function ? key:(obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
        const value = keyFn(obj);
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {} as Record<string, T[]>);
}

export function propiedadMasRepetida(
    arr: object[],
    key: string,
) {
    const map = new Map();

    for (const o of arr) {
        const item = o[key];
        map.set(item, (map.get(item) ?? 0) + 1);
    }

    const result = [...map].sort(([, a], [, b]) => b - a)[0]?.[0];
    if (typeof result === 'undefined') {
        throw new Error('Array is empty');
    }
    return result;
}

export function nestGroupsBy(arr, properties) {
    const fnGroupBy = (conversions, property2) => {
        return conversions.reduce((acc, obj) => {
            const key = obj[property2];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    };
    properties = Array.from(properties);
    if (properties.length === 1) {
        return fnGroupBy(arr, properties[0]);
    }
    const property = properties.shift();
    const grouped = fnGroupBy(arr, property);
    Object.keys(grouped).forEach(key => {
        grouped[key] = nestGroupsBy(grouped[key], Array.from(properties));
    });
    // for (let key in grouped) {
    // 	grouped[key] = nestGroupsBy(grouped[key], Array.from(properties));
    // }
    return grouped;
}

export function retornarUltimoOrden(data: any, campo: string): number {
    let dataFor: any = [];
    let x: any;
    let ordenMayor = 0;
    dataFor = data;
    // tslint:disable-next-line:forin
    let orden;
    // tslint:disable-next-line:forin
    for (x in dataFor) {
        // tslint:disable-next-line:no-unused-expression
        orden = data[x][campo] * 1;
        if (orden * 1 > ordenMayor) {
            ordenMayor = orden;
        }
    }
    return ordenMayor + 1;
}

export function formatearRutaDescarga(path: string, inicio: number, fin: number): string {
    const array = path.split('/');
    let result = '';
    if (array.length != (inicio + fin)) {
        for (let i = inicio; i < array.length - fin; i++) {
            if (i != array.length - 1) {
                result += array[i] + '/';
            }
            else {
                result += array[i];
            }
        }
        return result;
    }
    return 'incompatible';
}

export function getDataArchivoFromPath(value: string, conTimeStamp = false): {
    nombre: string, extension?: string, d: string, fechaSubida: any
} {
    if (typeof value == 'string') {
        const partesPath = value.split('/');
        const nombreCompleto = partesPath[partesPath.length - 1];
        let timeStamp;
        let nombreSinTimeStamp = nombreCompleto;
        const partesNombre = nombreCompleto.split('-');
        if (partesNombre.length > 1) {
            timeStamp = partesNombre[0];
            partesNombre.shift();
            nombreSinTimeStamp = partesNombre.join('-');
        }
        if (conTimeStamp) {
            return {
                nombre: nombreCompleto,
                extension: nombreCompleto.split('.').pop(),
                d: timeStamp,
                fechaSubida: timeStamp ? new Date(timeStamp * 1000):'',
            };
        }
        return {
            nombre: nombreSinTimeStamp,
            extension: nombreSinTimeStamp.split('.').pop(),
            d: timeStamp,
            fechaSubida: timeStamp ? new Date(timeStamp * 1000):'',
        };
    }
    return {
        nombre: '',
        extension: '',
        d: '',
        fechaSubida: '',
    };
}

export function esNumero(value) {
    return !isNaN(Number(value));
}

export function sumarObjetos(arrayObjetos: any[], campos: string[]) {
    return arrayObjetos.reduce((accumulator, item) => {
        campos.forEach(campo => {
            if (esNumero(item[campo])) {
                accumulator[campo] = (accumulator[campo] ?? 0) + ((item[campo] * 1) ?? 0);
            }
        });
        return accumulator;
    }, {});

    /*let sumas = {};
    campos.forEach(val => {
        sumas[val] =
    });
    return arrayObjetos.reduce((a, b) => {
        if (esNumero(b[campo])) {
            return a + ((b[campo] * 1) || 0);
        }
        return a;
    }, 0);*/
}

export function establecerQuitarRequired(formulario: FormGroup, establecer = [], quitar = []) {
    establecer.forEach(control => {
        if (!formulario.get(control).hasValidator(Validators.required)) {
            formulario.get(control).addValidators([Validators.required]);
            formulario.get(control).updateValueAndValidity();
        }
    });
    quitar.forEach(control => {
        if (formulario.get(control).hasValidator(Validators.required)) {
            formulario.get(control).removeValidators([Validators.required]);
            formulario.get(control).updateValueAndValidity();
        }
    });
}

export function finalizarGuardadoMantenimiento(objThis, dataGuardado, nombreColeccion, campoId, dialogRef: MatDialogRef<any> = null): Promise<any> {
    if (dataGuardado && dataGuardado['data']) {
        if (dialogRef) {
            dialogRef.close();
        }
        const partesNombreColeccion = nombreColeccion.split('.');
        const objSeleccionado = objThis['seleccionados'][partesNombreColeccion[partesNombreColeccion.length - 1]];

        const dataServidor = objThis['dataServidor'];
        const [parte1, parte2] = partesNombreColeccion;

        /*if (!objSeleccionado || objSeleccionado[campoId] != dataGuardado['data'][campoId]) {
            return fnCargarLista();
        }*/
        if (dataGuardado['data'][campoId]) {
            let coleccion;
            if (partesNombreColeccion.length == 1 && dataServidor?.[parte1]) {
                coleccion = objThis['dataServidor'][parte1];
            }
            else if (dataServidor?.[parte1]?.[parte2]) {
                coleccion = objThis['dataServidor'][parte1][parte2];
            }
            if (coleccion == null) {
                coleccion = [];
                if (objThis['dataServidorSuscripcion'] && objThis['dataServidorSuscripcion'][nombreColeccion] && objThis['dataServidorSuscripcion'][nombreColeccion].getValue()) {
                    coleccion = objThis['dataServidorSuscripcion'][nombreColeccion].getValue();
                }
            }

            const dEnviar = {};
            // dEnviar[campoId] = objSeleccionado[campoId];
            dEnviar[campoId] = dataGuardado['data'][campoId];
            // return of('MAsssL');
            return objThis.queryService.getDataMethod('GET', nombreColeccion, dEnviar).toPromise().then(daIndiv => {
                if (dataGuardado['data'][campoId]) {
                    const idxDataActual = coleccion.findIndex(item => item[campoId] == dataGuardado['data'][campoId]);
                    if (idxDataActual >= 0) {
                        coleccion[idxDataActual] = {
                            ...coleccion[idxDataActual],
                            ...daIndiv[nombreColeccion][0]
                        };
                        objThis['seleccionados'][partesNombreColeccion[partesNombreColeccion.length - 1]] = coleccion[idxDataActual];
                    }
                    else {
                        coleccion.unshift(daIndiv[nombreColeccion][0]);
                        objThis['seleccionados'][partesNombreColeccion[partesNombreColeccion.length - 1]] = coleccion[0];
                    }
                }
                /*
				else if (objSeleccionado && objSeleccionado[campoId] == dataGuardado['data'][campoId]) {
					const idxDataActual = coleccion.findIndex(item => item[campoId] == objSeleccionado[campoId]);
					if (idxDataActual >= 0) {
						coleccion[idxDataActual] = {
							...objSeleccionado,
							...daIndiv[nombreColeccion][0]
						};
						objThis['seleccionados'][partesNombreColeccion[partesNombreColeccion.length - 1]] = coleccion[idxDataActual];
					}
				}
				else {
					if (coleccion == null) {
						coleccion = [];
					}
					coleccion.unshift(daIndiv[nombreColeccion][0]);
					objThis['seleccionados'][partesNombreColeccion[partesNombreColeccion.length - 1]] = coleccion[0];
				}
                */
                return coleccion;
            });
        }
        else if (dataGuardado['data']['iResult']) {

            objThis['seleccionados'][partesNombreColeccion[partesNombreColeccion.length - 1]] = null;
            let lstNuevo;
            if (partesNombreColeccion.length == 1 && dataServidor?.[parte1]) {
                lstNuevo = objThis['dataServidor'][parte1] = objThis['dataServidor'][parte1].filter(item => item[campoId] != objSeleccionado[campoId]);
            }
            else {
                if (objSeleccionado) {
                    if (dataServidor?.[parte1]?.[parte2]) {
                        lstNuevo = objThis['dataServidor'][parte1][parte2] = objThis['dataServidor'][parte1][parte2].filter(item => item[campoId] != objSeleccionado[campoId]);
                    }
                    else if (objThis['dataServidorSuscripcion'] && objThis['dataServidorSuscripcion'][nombreColeccion] && objThis['dataServidorSuscripcion'][nombreColeccion].getValue()) {
                        const dValor = objThis['dataServidorSuscripcion'][nombreColeccion].getValue();
                        lstNuevo = dValor.filter(item => item[campoId] != objSeleccionado[campoId]);
                        objThis['dataServidorSuscripcion'][nombreColeccion].next(lstNuevo);
                    }
                }
            }
            return new Promise<any>((resolve, reject) => {
                resolve(lstNuevo);
            });
        }
    }
    else if (!dataGuardado) {
        return new Promise<any>((resolve, reject) => {
            reject('no existen datos de retorno');
        });
    }
    return new Promise<any>((resolve, reject) => {
        resolve('');
    });
}

export function verificarRUC(ruc: string) {
    const f = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    const rucArray = ruc.split('');
    const nArray = f.map((item, idx) => {
        return item * parseFloat(rucArray[idx]);
    });
    const suma = nArray.reduce((a, b) => a + b, 0);
    const residuo = suma % 11;
    const residuo2 = 11 - residuo;
    // @residuo=CONVERT(Integer,Right(CONVERT(VarChar,@residuo),1))
    const residuo3 = residuo2.toString().charAt(residuo2.toString().length - 1);
    const ultimoCaracter = ruc.toString().charAt(ruc.toString().length - 1);
    if (residuo3 == ultimoCaracter) {
        return true;
    }
    mensajeAlerta('error', 'Datos No válidos', ' El número de RUC no es válido');
    return false;
}


export function numberToWords(num: number): string {
    const ones = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const tens = ['', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const teens = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const hundreds = ['', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];

    if (num == 0) {
        return 'cero';
    }

    if (num < 0) {
        return 'menos ' + numberToWords(Math.abs(num));
    }

    let words = '';

    if (Math.floor(num / 1000000) > 0) {
        words += numberToWords(Math.floor(num / 1000000)) + ' millón';
        if (num % 1000000 === 0) {
            words += 'es';
        }
        else {
            words += ' ';
        }
        num %= 1000000;
    }

    if (Math.floor(num / 1000) > 0) {
        words += numberToWords(Math.floor(num / 1000)) + ' mil ';
        num %= 1000;
    }

    if (Math.floor(num / 100) > 0) {
        words += hundreds[Math.floor(num / 100)] + ' ';
        num %= 100;
        if (num === 0) {
            words += 'ciento';
        }
    }

    if (num > 0) {
        if (words != '') {
            words += ' ';
        }

        if (num < 10) {
            words += ones[num] + ' ';
        }
        else if (num < 20) {
            words += teens[num - 10] + ' ';
        }
        else if (num < 30) {
            words += 'veinti' + ones[num % 10] + ' ';
        }
        else if (num < 100) {
            words += tens[Math.floor(num / 10)] + ' ';
            num %= 10;
            if (num > 0) {
                words += ones[num] + ' ';
            }
        }
        else {
            words += ones[num] + ' ';
        }
    }

    if (words.endsWith('mil ')) {
        words = words.slice(0, -4) + ' mil ';
    }

    return words.trim();
}

export function seleccionarTextoInput(event): void {
    (event.target as HTMLInputElement).select();
}

export function esURL(texto) {
    const urlPattern = /^(http|https):\/\/([\w.]+\/?)\S*$/;
    return urlPattern.test(texto);
}

export function operacionesHoras(tiempo1: string, tiempo2: string, operacion: string): string {
    const date1 = new Date(`2000-01-01 ${tiempo1}`);
    const date2 = new Date(`2000-01-01 ${tiempo2}`);
    if (operacion == 'sumar') {
        const sumHours = date1.getHours() + date2.getHours();
        const sumMinutes = date1.getMinutes() + date2.getMinutes();

        // Ajustar si hay más de 60 minutos
        const adjustedHours = sumHours + Math.floor(sumMinutes / 60);
        const adjustedMinutes = sumMinutes % 60;

        return `${adjustedHours < 10 ? `0${adjustedHours}`:`${adjustedHours}`}:${adjustedMinutes < 10 ? `0${adjustedMinutes}`:`${adjustedMinutes}`}`;

    }
    else if (operacion == 'restar') {
        const diffInMilliseconds = date2.getTime() - date1.getTime();
        const diffInMinutes = diffInMilliseconds / (1000 * 60);

        // Convertir la diferencia de minutos a formato de tiempo (HH:mm)
        const diffHours = Math.floor(diffInMinutes / 60);
        const diffMinutes = Math.floor(diffInMinutes % 60);

        return `${diffHours < 10 ? `0${diffHours}`:`${diffHours}`}:${diffMinutes < 10 ? `0${diffMinutes}`:`${diffMinutes}`}`;

    }
}

export function validarHoras(tiempo1: string, tiempo2: string): boolean {
    const hora1 = new Date(`2000-01-01T${tiempo1}`);
    const hora2 = new Date(`2000-01-01T${tiempo2}`);

    if (hora1 < hora2) {
        return true;
    }
    else {
        return false;
    }
}

export function cortarTextoConPuntos(str, longitud) {
    if (str.length > longitud) {
        return str.substring(0, longitud) + '...';
    }
    else {
        return str;
    }
}

export function convertirBytes(valor: number, unidadSalida: string | null = null, incluirUnidad: boolean = true): string | number | null {
    const unidades: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const indiceEntrada: number = unidadSalida ? unidades.indexOf(unidadSalida) : 0;

    if (indiceEntrada === -1) {
        // Unidad de entrada no válida
        return null;
    }

    let indiceActual: number = indiceEntrada;
    let resultado: number = valor;

    let cont = 0;

    while (cont < 6 && (indiceActual < unidades.length - 1) && (resultado >= 1024)) {
        resultado /= 1024;
        indiceActual++;
    }
    if (incluirUnidad) {
        return `${resultado.toFixed(2)} ${unidades[indiceActual]}`;
    }

    return resultado;
}

export function b64Encode(val) {
    return Buffer.from(val, 'binary').toString('base64');
}
export function b64Decode(val) {
    return Buffer.from(val, 'base64').toString('binary');
}

export function rellenarString(str: string, longitud: number, caracterRelleno: string, insertarAlInicio: boolean = false): string {
    if (str.length >= longitud) {
        return str;
    } else {
        const cantidadRelleno = longitud - str.length;
        const relleno = caracterRelleno.repeat(cantidadRelleno);
        return insertarAlInicio ? relleno + str : str + relleno;
    }
}
