import {PdfLogisticaService} from '@pdf/pdf-logistica.service';
import {imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {sumarObjetos} from '@JVSoft/services/funciones-globales.service';
import {zeroFill} from '@JVSoft/pipes/zero-fill.pipe';

import * as moment from 'moment';
import {formatDate} from '@angular/common';

export function pecosa(objThis: PdfLogisticaService, idsCompras) {
    objThis.queryService.cargar(objThis, ['log.salidas_detalles_presentacion'], {cCodigoCadena: idsCompras}).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.salidas_detalles_presentacion'];

        Object.keys(dataRecibido).forEach((idxCompra) => {
            const dataGeneral = dataRecibido[idxCompra][0];

            const celdasFooter = [];

            const localLocale = moment(dataGeneral.dSalidaEmision, 'YYYY-MM-DD');
            moment.locale('es');
            localLocale.locale(false);
            // alert(localLocale.format('LLLL'));

            celdasFooter.push(
                [
                    imprimirCelda('\n\n\n\n\n\n\n', null, {style: 'tdSmall'}),
                    imprimirCelda('', null, {style: 'tdSmall'}),
                    imprimirCelda('', null, {style: 'tdSmall'}),
                    imprimirCelda('', null, {style: 'tdSmall'})
                ],
                [
                    imprimirCelda('SOLICITANTE', null, {style: 'thSmall'}),
                    imprimirCelda('RECIBÍ CONFORME', null, {style: 'thSmall'}),
                    imprimirCelda('ENTREGUÉ CONFORME', null, {style: 'thSmall'}),
                    imprimirCelda('V.B. CONFORMIDAD', null, {style: 'thSmall'}),
                ]
            );
            console.log(dataGeneral);

            objThis.reiniciarOpciones({
                // marcaDeAgua: mDeAgua,
                margenesHoja: [40, 80, 40, 120],
                tamanioFuenteExtra: 1,
                orientacion: 'landscape',
                defaultSizeHeaderTable: [150, '*', 150],
                defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    // margin: [0, 10, 5, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                // imprimirCelda('PAG.', null, {fontSize: 7, alignment: 'center'}),
                                imprimirCelda(' Pag. ##pagina## de ##total_paginas##', null, {colSpan: 2, fontSize: 7, alignment: 'center'}),
                                {},
                            ],
                            [
                                imprimirCelda('NÚMERO', null, {fontSize: 8, alignment: 'center'}),
                                imprimirCelda('FECHA', null, {fontSize: 9, alignment: 'center'}),
                            ],
                            [
                                imprimirCelda(zeroFill(dataGeneral.iSalidaNumero, 4), null, {
                                    fontSize: 12, bold: true, alignment: 'center',
                                }),
                                imprimirCelda(new Date(), 'fec', {
                                    fontSize: 12,
                                    bold: true,
                                    alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable: {
                    margin: [0, 10, 0, 0],
                    alignment: 'center',
                    stack: [
                        imprimirCelda(dataGeneral.cTipoMovProdNombre, null, {fontSize: 14, bold: true}),
                    ],
                },

                footerTablePrepend: {
                    margin: [70, 0, 70, 10],
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: celdasFooter,
                    },
                    layout: {
                    },
                },
            });
            const cuerpoPdf = [];

            cuerpoPdf.push({
                columns: [
                    {
                        margin: [0, 0, 0, 10],
                        table: {
                            widths: ['auto', '*'],
                            headerRows: 0,
                            body: [
                                [
                                    imprimirCelda('ENTREGA', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersEntregaNombreLargo ?? '') + ' (' + (dataGeneral.cPersEntregaDocumento ?? '') + ')', null, {
                                        italics: true,
                                        border: [false, true, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('CARGO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoEntregaNombre ?? ''), null, {
                                        italics: true,
                                        border: [false, false, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('AGENCIA / OFICINA', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda(': ' + (dataGeneral.cEstablecimientoEntregaNombreLargo ?? '') + ' / ' + (dataGeneral.cDepenEntregaNombre ?? ''), null, {
                                        italics: true,
                                        border: [false, false, true, true],
                                    }),
                                ],
                            ],
                        },
                    },
                    {
                        margin: [10, 0, 0, 0],
                        table: {
                            widths: ['auto', '*'],
                            headerRows: 0,
                            body: [
                                [
                                    imprimirCelda('RECIBE', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersRecibeNombreLargo ?? '') + ' (' + (dataGeneral.cPersRecibeDocumento ?? '') + ')', null, {
                                        italics: true, border: [false, true, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('CARGO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('AGENCIA / OFICINA', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda(': ' + (dataGeneral.cEstablecimientoRecibeNombreLargo ?? '') + ' / ' + (dataGeneral.cDepenRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, true],
                                    }),
                                ],
                            ],
                        },
                    },
                ]
            });






            const tablaTitulos = [
                [
                    imprimirCelda('Nº', null, {
                        style: 'thSmall', rowSpan: 2, alignment: 'center',
                    }),
                    imprimirCelda('ARTICULOS SOLICITADOS', null, {
                        style: 'thSmall', colSpan: 7, alignment: 'center',
                    }),
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    imprimirCelda('ORDEN DE DESPACHO', null, {
                        style: 'thSmall', colSpan: 3, alignment: 'center',
                    }),
                    {},
                    {},
                ],
                [
                    {},
                    imprimirCelda('CÓDIGO', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('U/M', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('MARCA', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('MODELO', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('COLOR', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('PROCEDENCIA', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('CANTIDAD', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('P.U.', null, {style: 'thSmall', alignment: 'center', }),
                    imprimirCelda('TOTAL', null, {style: 'thSmall', alignment: 'center', }),
                ],
            ];


            const camposPrincipal: ColumnaTabla<any>[] = [
                {label: 'CÓDIGO', property: 'cCodigoProducto', type: 'text'},
                {label: 'DESCRIPCIÓN', property: 'cProductoNombreObs', type: 'text'},
                {label: 'U/M', property: 'cMovProdDetMarca', type: 'text'},
                {label: 'MARCA.', property: 'cMovProdDetMarca', type: 'text'},
                {label: 'MODELO', property: 'cMovProdDetModelo', type: 'text'},
                {label: 'COLOR', property: 'cMovProdDetColor', type: 'text'},
                {
                    label: 'PROCEDENCIA',
                    property: 'cOrigenDocumento',
                    type: 'text',
                },
                {label: 'CANTIDAD', property: 'nSalidaDetCantidad', type: 'number'},
                {label: 'P. UNIT', property: 'nMovProdDetPrecioUnitario', type: 'money'},
                {label: 'SUB TOTAL', property: 'nSalidaDetSubTota', type: 'money'},
            ];

            const sumasTotales = sumarObjetos(dataRecibido[idxCompra], ['nMovProdDetPrecioUnitario', 'nSalidaDetSubTota']);
            const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: dataRecibido[idxCompra],
                numeracion: true,
                idxResto: [1],
                margin: [0, 5, 0, 5],
                separado: true,
            });
            // cuerpoPdf.push(tablaGeneradaSoloTitulos);

            cuerpoPdf.push(
                {
                    margin: [0, 0, 0, 10],
                    table: {
                        dontBreakRows: true,
                        headerRows: 2,
                        // widths: objThis.anchoCols(tablaGeneradaSoloTitulos['cuerpo'], [2]),
                        widths: objThis.anchoCols(tablaTitulos, [2]),
                        body: tablaTitulos.concat(tablaGeneradaSoloTitulos['cuerpo']),
                    },
                },
                imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true})
            );

            cuerpoPdf.push({
                columns: [
                    {width: '*', text: ''},
                    {
                        width: 'auto',
                        table: {
                            body: [
                                [
                                    imprimirCelda('TOTAL GENERAL:', null, {
                                        bold: true,
                                        style: 'thSmall',
                                        alignment: 'right',
                                        fontSize: 9,
                                    }),
                                    imprimirCelda(sumasTotales['nSalidaDetSubTota'], 'money', {
                                        bold: true,
                                        style: 'tdSmall',
                                        fontSize: 9,
                                    })
                                ]
                            ],
                        },
                    },
                    {width: '*', text: ''},
                ],
            });

            if (dataGeneral.cSalidaObs) {
                cuerpoPdf.push(imprimirCelda('Observacion:\n ' + dataGeneral.cSalidaObs ?? '', null, {italics: true, margin: [30, 0, 0, 10]}));
            }



            objThis.finalizarCreacionPdf(cuerpoPdf);
        });
    });
}
export function pecosaAnt(objThis: PdfLogisticaService, idsCompras) {
    objThis.queryService.cargar(objThis, ['log.salidas_detalles_presentacion'], {cCodigoCadena: idsCompras}).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.salidas_detalles_presentacion'];

        Object.keys(dataRecibido).forEach((idxCompra) => {
            const dataGeneral = dataRecibido[idxCompra][0];

            const celdasFooter = [];

            const localLocale = moment(dataGeneral.dSalidaEmision, 'YYYY-MM-DD');
            moment.locale('es');
            localLocale.locale(false);
            // alert(localLocale.format('LLLL'));

            celdasFooter.push(
                [imprimirCelda('\n\n\n\n\n\n\n', null, {style: 'tdSmall'}), imprimirCelda('', null, {style: 'tdSmall'}), imprimirCelda('', null, {style: 'tdSmall'})],
                [imprimirCelda('RECIBÍ CONFORME', null, {style: 'thSmall'}), imprimirCelda('ENTREGUÉ CONFORME', null, {style: 'thSmall'}), imprimirCelda('Vº Bº CONFORMIDAD', null, {style: 'thSmall'})]
            );

            objThis.reiniciarOpciones({
                margenesHoja: [40, 80, 40, 170],
                // orientacion: 'landscape',
                // tamanioHoja: 'A3',
                tamanioFuenteExtra: 2,
                customDefTableHeader: [
                    {
                        margin: [70, 15, 40, 0],
                        // margin: [15, 15, 15, 0],
                        table: {
                            widths: [100, '*', 30, 50],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        ...objThis.logotipoHeader(),
                                        ...{
                                            rowSpan: 2,
                                        },
                                    },
                                    imprimirCelda('PECOSA', null, {fontSize: 9, bold: true, alignment: 'center'}),
                                    imprimirCelda('\n\nNº: ', null, {
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'center',
                                        rowSpan: 2,
                                        border: [true, true, false, true],
                                    }),
                                    imprimirCelda('\n\n' + zeroFill(dataGeneral.iSalidaNumero, 4) + '-' + dataGeneral.iSalidaYear, null, {
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'center',
                                        rowSpan: 2,
                                        border: [false, true, true, true],
                                    }),
                                ],
                                [
                                    {},
                                    imprimirCelda('\nACTA DE ENTREGA DE BIENES Y/O SUMINISTROS', null, {
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'center',
                                    }),
                                    {},
                                    {},
                                ],
                            ],
                        },
                        layout: {
                            // defaultBorder: false,
                        },
                    },
                ],

                footerTablePrepend: {
                    margin: [70, 0, 80, 20],
                    table: {
                        widths: [150, 150, 150],
                        // widths: ['*', '*', '*'],
                        // widths: this.anchoCols(celdasHeaderDetalleBase, [2]),
                        body: celdasFooter,
                    },
                    layout: {
                        // defaultBorder: false,
                    },
                    // absolutePosition: {x: 20, y: 0},
                },
            });

            const cuerpoPdf = [];

            cuerpoPdf.push({
                margin: [20, 0, 0, 0],
                table: {
                    widths: [80, 10, '*'],
                    headerRows: 0,
                    body: [
                        [
                            imprimirCelda('ENTREGA', null, {bold: true}),
                            imprimirCelda(':', null, {bold: true}),
                            // imprimirCelda(objThis.datosUsuarioiEntId['data_persona']['cPersNombreLargo'], null, {italics: true, border: [false, false, false, true]}),
                            imprimirCelda(dataGeneral.cPersEntregaNombreLargo + ' (' + dataGeneral.cPersEntregaDocumento + ')', null, {
                                italics: true,
                                border: [false, false, false, true],
                            }),
                        ],
                        [
                            imprimirCelda('CARGO', null, {bold: true}),
                            imprimirCelda(':', null, {bold: true}),
                            imprimirCelda(dataGeneral.cCargoEntregaNombre, null, {
                                italics: true,
                                border: [false, false, false, true],
                            }),
                        ],
                        [
                            imprimirCelda('AGENCIA / OFICINA', null, {bold: true}),
                            imprimirCelda(':', null, {bold: true}),
                            // imprimirCelda('', null, {italics: true, border: [false, false, false, true]}),
                            imprimirCelda(dataGeneral.cEstablecimientoEntregaNombreLargo + ' / ' + dataGeneral.cDepenEntregaNombre, null, {
                                italics: true,
                                border: [false, false, false, true],
                            }),
                        ],
                        [imprimirCelda('', null, {bold: true}), {}, {}],
                        [
                            imprimirCelda('RECIBE', null, {bold: true}),
                            imprimirCelda(':', null, {bold: true}),
                            imprimirCelda(dataGeneral.cPersRecibeNombreLargo + ' (' + dataGeneral.cPersRecibeDocumento + ')', null, {
                                italics: true,
                                border: [false, false, false, true],
                            }),
                        ],
                        [
                            imprimirCelda('CARGO', null, {bold: true}),
                            imprimirCelda(':', null, {bold: true}),
                            imprimirCelda(dataGeneral.cCargoRecibeNombre, null, {
                                italics: true,
                                border: [false, false, false, true],
                            }),
                        ],
                        [
                            imprimirCelda('AGENCIA / OFICINA', null, {bold: true}),
                            imprimirCelda(':', null, {bold: true}),
                            imprimirCelda(dataGeneral.cEstablecimientoRecibeNombreLargo + ' / ' + dataGeneral.cDepenRecibeNombre, null, {
                                italics: true,
                                border: [false, false, false, true],
                            }),
                        ],
                    ],
                },
                layout: {
                    defaultBorder: false,
                },
            });

            const camposPrincipal: ColumnaTabla<any>[] = [
                // {label: 'DESCRIPCION', property: 'Ano_eje', type: 'text'},
                {label: 'CARACTERÍSTICAS', property: 'cProductoNombreObs', type: 'text'},
                {label: 'MARCA.', property: 'cMovProdDetMarca', type: 'text'},
                {label: 'MODELO', property: 'cMovProdDetModelo', type: 'text'},
                {label: 'COLOR', property: 'cMovProdDetColor', type: 'text'},
                {
                    label: 'ESTADO',
                    property: 'Cod_doc',
                    type: 'text',
                    transformarDirecto(row): any {
                        return 'NUEVO';
                    },
                },
                {label: 'CANTIDAD', property: 'nSalidaDetCantidad', type: 'number'},
                {label: 'P. UNIT', property: 'nMovProdDetPrecioUnitario', type: 'money'},
                {label: 'SUB TOTAL', property: 'nSalidaDetSubTotal', type: 'money'},
            ];

            const sumasTotales = sumarObjetos(dataRecibido[idxCompra], ['nMovProdDetPrecioUnitario', 'nSalidaDetSubTotal']);
            const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
                titulos: camposPrincipal,
                contenido: dataRecibido[idxCompra],
                numeracion: true,
                idxResto: [1],
                margin: [0, 5, 0, 5],
                separado: true,
            });
            // cuerpoPdf.push(tablaGeneradaSoloTitulos);

            cuerpoPdf.push(
                {
                    margin: [30, 20, 0, 10],
                    table: {
                        dontBreakRows: true,
                        headerRows: 1,
                        widths: objThis.anchoCols(tablaGeneradaSoloTitulos['cuerpo'], [1]),
                        body: tablaGeneradaSoloTitulos['titulos'].concat(tablaGeneradaSoloTitulos['cuerpo']).concat([
                            [
                                imprimirCelda('Total', null, {
                                    bold: true,
                                    style: 'thSmall',
                                    alignment: 'right',
                                    colSpan: 7,
                                }),
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                imprimirCelda(sumasTotales['nMovProdDetPrecioUnitario'], 'money', {
                                    bold: true,
                                    style: 'thSmall',
                                }),
                                imprimirCelda(sumasTotales['nSalidaDetSubTotal'], 'money', {
                                    bold: true,
                                    style: 'thSmall',
                                }),
                            ],
                        ]),
                    },
                },
                imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true})
            );
            if (dataGeneral.cSalidaObs) {
                cuerpoPdf.push(imprimirCelda('Observacion:\n ' + dataGeneral.cSalidaObs ?? '', null, {italics: true, margin: [30, 0, 0, 10]}));
            }

            objThis.finalizarCreacionPdf(cuerpoPdf);
        });

        // const cuerpoPdf = [];
    });
}

export function compra(objThis: PdfLogisticaService, idsCompras: string) {
    objThis.queryService.cargar(objThis, ['log.compras_detalles_presentacion'], {cCodigoCadena: idsCompras}).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.compras_detalles_presentacion'];
        Object.keys(dataRecibido).forEach((idxCompra) => {
            const cuerpoPdf = [];

            const dataGeneral = dataRecibido[idxCompra][0];
            const dataTotales = dataRecibido[idxCompra][0]['totales'];
            const dataCuentasProveedor = dataRecibido[idxCompra][0]['cuentasProveedor'];
            const dataDocumentos = dataRecibido[idxCompra][0]['documentos'];
            const esOrdenCompra = dataGeneral.iTipoDocId == 38;

            const dataGeneralTabla = [
                [
                    {
                        border: [true, true, false, true],
                        stack: [imprimirCelda('EMISION', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [imprimirCelda('DOC. PROV.', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [imprimirCelda('RAZÓN SOCIAL', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [imprimirCelda('TELÉFONO', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, true, true],
                        stack: [imprimirCelda('DIRECCIÓN', null, {bold: true, noWrap: true})],
                    },
                ],
                [
                    {
                        border: [true, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.dCompraEmision, 'fec', {
                                fontSize: 7,
                                italics: true,
                                alignment: 'left',
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.cTipoIdentProveedorSigla + ' ' + dataGeneral.cPersProveedorDocumento, null, {
                                fontSize: 7,
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.cPersProveedorNombreLargo, null, {
                                fontSize: 7,
                                italics: true,
                                maxHeight: 20,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(dataGeneral.cPersProveedorConTelefonoMovil, null, {
                                fontSize: 7,
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, true, true],
                        stack: [
                            imprimirCelda(dataGeneral.cPersProveedorDomicilio, null, {
                                fontSize: 7,
                                italics: true,
                                maxHeight: 20,
                            }),
                        ],
                    },
                ],
                [
                    {
                        border: [true, true, false, true],
                        stack: [imprimirCelda('PLAZO ENTREGA', null, {bold: true, noWrap: true})],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(esOrdenCompra ? 'ESTABLECIMIENTO' : '', null, {
                                bold: true,
                                noWrap: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        colSpan: 2,
                        stack: [imprimirCelda(esOrdenCompra ? 'ALMACEN' : '', null, {bold: true, noWrap: true})],
                    },
                    {},
                    {
                        border: [false, true, true, true],
                        stack: [imprimirCelda('FORMA DE PAGO', null, {bold: true, noWrap: true})],
                    },
                ],
                [
                    {
                        border: [true, true, false, true],
                        stack: [
                            // imprimirCelda(dataGeneral.nCompraPlazoEntrega, 'cant', {fontSize: 7, italics: true}),
                            imprimirCelda(dataGeneral.nCompraPlazoEntrega * 1 + ' días', null, {
                                fontSize: 7,
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        stack: [
                            imprimirCelda(esOrdenCompra ? dataGeneral.cEstablecimientoNombreLargo : '', null, {
                                fontSize: 7,
                                italics: true,
                            }),
                        ],
                    },
                    {
                        border: [false, true, false, true],
                        colSpan: 2,
                        stack: [
                            imprimirCelda(esOrdenCompra ? dataGeneral.cAlmacenNombre : '', null, {
                                fontSize: 7,
                                italics: true,
                            }),
                        ],
                    },
                    {},
                    {
                        border: [false, true, true, true],
                        stack: [imprimirCelda(dataGeneral.cFormaPagoNombre, null, {fontSize: 7, italics: true})],
                    },
                ],
                [
                    {
                        // border: [true, true, false, false],
                        colSpan: 5,
                        stack: [imprimirCelda('REFERENCIA', null, {bold: true, noWrap: true})],
                    },
                    {},
                    {},
                    {},
                    {},
                ],
                [
                    {
                        // border: [true, true, false, false],
                        colSpan: 5,
                        stack: [imprimirCelda(dataGeneral.cCompraReferencia, null, {fontSize: 7, italics: true})],
                    },
                    {},
                    {},
                    {},
                    {},
                ],
            ];

            let anchoColumnasDetalle = [10, 55, 61, 126, 126, 35, 34, 35];
            if (esOrdenCompra) {
                anchoColumnasDetalle = [10, 55, 61, 84, 36, 35, 35, 35, 35, 34, 35];
            }
            imprimirCelda('Nº', null, {style: 'thSmall'});
            imprimirCelda('CÓDIGO', null, {style: 'thSmall'});
            imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', colSpan: 3});
            imprimirCelda('CANTIDAD', null, {style: 'thSmall'});
            imprimirCelda('PRECIO', null, {style: 'thSmall'});
            imprimirCelda('TOTAL', null, {style: 'thSmall'});
            const celdasFooter = [];

            const strCtasProveedorArray = [];
            dataCuentasProveedor.filter((cuenta) => {
                strCtasProveedorArray.push(
                    {text: cuenta['cBancoNombre'] + ' - ' + cuenta['cTipoCuentaNombre'] + ' - '},
                    {text: ' ' + cuenta['cPersCuentaNumero'], color: '#06024f', bold: true},
                    {text: ' (' + cuenta['cTipoMonedaNombre'] + ')\n'}
                );
            });
            dataTotales.filter((resultado, idxRes) => {
                if (idxRes == 0) {
                    if (esOrdenCompra) {
                        celdasFooter.push(
                            [
                                imprimirCelda(strCtasProveedorArray, null, {
                                    style: 'tdSmall',
                                    colSpan: 10,
                                    border: [true, false, true, false],
                                }),
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            ],
                            [
                                imprimirCelda('JEFE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {
                                    style: 'thSmall',
                                    colSpan: 3,
                                }),
                                {},
                                {},
                                imprimirCelda('ASISTENTE DE ABASTECIMIENTO Y CONTROL PATRIMONIAL', null, {
                                    style: 'thSmall',
                                    colSpan: 2,
                                }),
                                {},
                                imprimirCelda('SUBGERENTE ADMINISTRATIVO', null, {style: 'thSmall', colSpan: 3}),
                                {},
                                {},

                                imprimirCelda(resultado.cResultadoNombre, null, {
                                    colSpan: 2,
                                    style: 'tdSmall',
                                    bold: true,
                                }),
                                imprimirCelda(''),
                                imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                    style: 'tdSmall',
                                    alignment: 'right',
                                    italics: true,
                                }),
                            ]
                        );
                    } else {
                        celdasFooter.push(
                            [
                                imprimirCelda(strCtasProveedorArray, null, {
                                    style: 'tdSmall',
                                    colSpan: 7,
                                    border: [true, false, true, false],
                                }),
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            ],
                            [
                                imprimirCelda('Jefe Área Adquisiciones', null, {style: 'thSmall', colSpan: 3}),
                                {},
                                {},
                                imprimirCelda('Jefe Dpto. Suministros', null, {style: 'thSmall'}),
                                imprimirCelda('Gerente Administrativo', null, {style: 'thSmall'}),

                                imprimirCelda(resultado.cResultadoNombre, null, {
                                    colSpan: 2,
                                    style: 'tdSmall',
                                    bold: true,
                                }),
                                imprimirCelda(''),
                                imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                    style: 'tdSmall',
                                    alignment: 'right',
                                    italics: true,
                                }),
                            ]
                        );
                    }
                } else if (idxRes == dataTotales.length - 1) {
                    if (esOrdenCompra) {
                        celdasFooter.push([
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                colSpan: 2,
                                border: [true, false, true, true],
                            }),
                            {},
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                colSpan: 3,
                                border: [true, false, true, true],
                            }),
                            {},
                            {},

                            imprimirCelda(resultado.cResultadoNombre, null, {
                                colSpan: 2,
                                style: 'tdSmall',
                                bold: true,
                            }),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                alignment: 'right',
                                italics: true,
                            }),
                        ]);
                    } else {
                        celdasFooter.push([
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                            }),
                            imprimirCelda('\n\n\n\nFirma y Sello', null, {
                                style: 'tdSmall',
                                alignment: 'center',
                                border: [true, false, true, true],
                            }),

                            imprimirCelda(resultado.cResultadoNombre, null, {
                                colSpan: 2,
                                style: 'tdSmall',
                                bold: true,
                            }),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                alignment: 'right',
                                italics: true,
                            }),
                        ]);
                    }
                } else {
                    if (esOrdenCompra) {
                        celdasFooter.push([
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 2,
                            }),
                            {},
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 3,
                            }),
                            {},
                            {},

                            imprimirCelda(resultado.cResultadoNombre, null, {
                                colSpan: 2,
                                style: 'tdSmall',
                                bold: true,
                            }),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                alignment: 'right',
                                italics: true,
                            }),
                        ]);
                    } else {
                        celdasFooter.push([
                            imprimirCelda('', null, {
                                style: 'tdSmall',
                                border: [true, false, true, false],
                                colSpan: 3,
                            }),
                            {},
                            {},
                            imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),
                            imprimirCelda('', null, {style: 'tdSmall', border: [true, false, true, false]}),

                            imprimirCelda(resultado.cResultadoNombre, null, {
                                colSpan: 2,
                                style: 'tdSmall',
                                bold: true,
                            }),
                            imprimirCelda(''),
                            imprimirCelda(resultado.nResultadoImporte, 'mon', {
                                style: 'tdSmall',
                                alignment: 'right',
                                italics: true,
                            }),
                        ]);
                    }
                }
            });
            if (esOrdenCompra) {
                celdasFooter.push(
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 11,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            colSpan: 11,
                            text: [
                                {text: 'SON: ', bold: true},
                                {
                                    text: dataGeneral.cCompraValorItemTotal_Letra + dataGeneral.cTipoMonedaNombre,
                                    italics: true,
                                },
                            ],
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 11,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            colSpan: 11,
                            margin: [-5, 0, -5, 0],
                            table: {
                                widths: [380, 1, '*'],
                                body: [
                                    [
                                        {
                                            stack: [
                                                imprimirCelda('FACTURAR A NOMBRE DE:', null, {bold: true}),
                                                imprimirCelda(dataGeneral.cEntNombreCorto ? dataGeneral.cEntNombreCorto : dataGeneral.cEntNombreLargo),
                                                {
                                                    columns: [
                                                        {
                                                            width: 50,
                                                            stack: [
                                                                imprimirCelda('RUC: ', null, {bold: true}),
                                                                imprimirCelda('DIRECCIÓN: ', null, {bold: true}),
                                                                imprimirCelda('TELÉFONO: ', null, {bold: true}),
                                                            ],
                                                        },
                                                        {
                                                            width: '*',
                                                            stack: [
                                                                imprimirCelda(dataGeneral.cEntRuc),
                                                                imprimirCelda(dataGeneral.cEntDomicilioCorto ? dataGeneral.cEntDomicilioCorto : dataGeneral.cEntDomicilioLargo),
                                                                imprimirCelda(dataGeneral.cEntTelefono),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        imprimirCelda('', null, {border: [true, false, true, false]}),
                                        imprimirCelda('Recibido:' + '\n\n\n\n\n\n\n\n' + 'Fecha:', null, {bold: true}),
                                    ],
                                ],
                            },
                            // layout: 'noBorders',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ]
                );
            } else {
                celdasFooter.push(
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 8,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            colSpan: 8,
                            text: [
                                {text: 'SON: ', bold: true},
                                {
                                    text: dataGeneral.cCompraValorItemTotal_Letra + dataGeneral.cTipoMonedaNombre,
                                    italics: true,
                                },
                            ],
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            fontSize: 4,
                            colSpan: 8,
                            text: '',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            style: 'tdSmall',
                            border: [false, false, false, false],
                            colSpan: 8,
                            margin: [-5, 0, -5, 0],
                            table: {
                                widths: [380, 1, '*'],
                                body: [
                                    [
                                        {
                                            stack: [
                                                imprimirCelda('FACTURAR A NOMBRE DE:', null, {bold: true}),
                                                imprimirCelda(dataGeneral.cEntNombreCorto ? dataGeneral.cEntNombreCorto : dataGeneral.cEntNombreLargo),
                                                {
                                                    columns: [
                                                        {
                                                            width: 50,
                                                            stack: [
                                                                imprimirCelda('RUC: ', null, {bold: true}),
                                                                imprimirCelda('DIRECCIÓN: ', null, {bold: true}),
                                                                imprimirCelda('TELÉFONO: ', null, {bold: true}),
                                                            ],
                                                        },
                                                        {
                                                            width: '*',
                                                            stack: [
                                                                imprimirCelda(dataGeneral.cEntRuc),
                                                                imprimirCelda(dataGeneral.cEntDomicilioCorto ? dataGeneral.cEntDomicilioCorto : dataGeneral.cEntDomicilioLargo),
                                                                imprimirCelda(dataGeneral.cEntTelefono),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        imprimirCelda('', null, {border: [true, false, true, false]}),
                                        imprimirCelda('Recibido:' + '\n\n\n\n' + 'Fecha:', null, {bold: true}),
                                    ],
                                ],
                            },
                            // layout: 'noBorders',
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ]
                );
            }

            const numerosDocumentos = [];
            const fnDocNum = (concepto, numero) => {
                return [imprimirCelda(concepto, null, {fontSize: 7, bold: true /*, alignment: 'center'*/}), imprimirCelda(numero, null, {fontSize: 8, alignment: 'center'})];
            };
            numerosDocumentos.push(fnDocNum('PÁGINA', '##pagina##/##total_paginas##'));
            if (dataGeneral.cPedidoNumero) {
                numerosDocumentos.push(fnDocNum('Nº PEDIDO', dataGeneral.cPedidoNumero));
            }
            if (dataGeneral.cCotizaNumero) {
                numerosDocumentos.push(fnDocNum('Nº COTIZACIÓN', dataGeneral.cCotizaNumero));
            }
            if (dataGeneral.cCuaComNumero) {
                numerosDocumentos.push(fnDocNum('Nº CUA. COM.', dataGeneral.cCuaComNumero));
            }

            const mDeAgua = {};
            if (dataGeneral.bCompraAnuladoRegistro == '1') {
                mDeAgua['color'] = '#a80707';
                mDeAgua['text'] = 'ANULADO';
            }

            objThis.reiniciarOpciones({
                marcaDeAgua: mDeAgua,
                margenesHoja: [20, 178, 20, 280],
                tamanioFuenteExtra: 2,
                customDefTableHeader: [
                    {
                        margin: [5, 20, 5, 0],
                        table: {
                            widths: [150, '*', 150],
                            headerRows: 0,
                            body: [
                                [
                                    objThis.logotipoHeader(dataGeneral),
                                    {
                                        margin: [0, 40, 0, 0],
                                        alignment: 'center',
                                        stack: [
                                            imprimirCelda(dataGeneral.cTipoDocNombre + ' Nº ' + dataGeneral.iCompraNumero + ' - ' + dataGeneral.iCompraYear, null, {
                                                fontSize: 13,
                                                bold: true,
                                            }),
                                        ],
                                    },
                                    {
                                        margin: [30, 10, 10, 0],
                                        table: {
                                            widths: ['auto', '*'],
                                            body: numerosDocumentos,
                                        },
                                    },
                                ],
                            ],
                        },
                        layout: {
                            defaultBorder: false,
                        },
                    },
                    {
                        margin: [20, 0, 20, 0],
                        table: {
                            // widths: [130, '*'],
                            widths: objThis.anchoCols(dataGeneralTabla, [4] /*, [{idx: 0, valor: 'auto'}, {idx: 1, valor: 110}, ]*/),
                            body: dataGeneralTabla,
                        },
                    },
                ],
                footerTablePrepend: {
                    margin: [20, 0, 20, 0],
                    table: {
                        widths: anchoColumnasDetalle,
                        // widths: objThis.anchoCols(celdasHeaderDetalleBase, [2]),
                        body: celdasFooter,
                    },
                    // absolutePosition: {x: 20, y: 0},
                },
            });

            let celdasHeaderDetalle = [
                [
                    // imprimirCelda('Nº', null, { style: 'thSmall' }),
                    {
                        style: 'thSmall',
                        stack: [
                            {text: 'Nº'},
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: 0.5,
                                        y1: 0,
                                        x2: 0.5,
                                        y2: 465,
                                    },
                                ],
                                absolutePosition: {x: 0, y: 0},
                                alignment: 'left',
                            },
                        ],
                    },
                    imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                    imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', colSpan: 3}),
                    {},
                    {},
                    // imprimirCelda('UNIDAD', null, { style: 'thSmall' }),
                    imprimirCelda('CANTIDAD', null, {style: 'thSmall'}),
                    imprimirCelda('PRECIO', null, {style: 'thSmall'}),
                    // imprimirCelda('TOTAL', null, { style: 'thSmall' }),
                    {
                        style: 'thSmall',
                        stack: [
                            {text: 'TOTAL'},
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: -1,
                                        y1: 0,
                                        x2: -1,
                                        y2: 465,
                                    },
                                    {
                                        type: 'line',
                                        x1: -45,
                                        y1: 0,
                                        x2: -45,
                                        y2: 465,
                                    },
                                ],
                                absolutePosition: {x: 0, y: 0},
                                alignment: 'right',
                            },
                        ],
                    },
                ],
            ];
            if (esOrdenCompra) {
                celdasHeaderDetalle = [
                    [
                        // imprimirCelda('Nº', null, { style: 'thSmall' }),
                        {
                            style: 'thSmall',
                            stack: [
                                {text: 'Nº'},
                                {
                                    canvas: [
                                        {
                                            type: 'line',
                                            x1: 0.5,
                                            y1: 0,
                                            x2: 0.5,
                                            y2: 465,
                                        },
                                    ],
                                    absolutePosition: {x: 0, y: 0},
                                    alignment: 'left',
                                },
                            ],
                        },
                        imprimirCelda('CÓDIGO', null, {style: 'thSmall'}),
                        imprimirCelda('DESCRIPCIÓN', null, {style: 'thSmall', colSpan: 3}),
                        {},
                        {},
                        // imprimirCelda('UNIDAD', null, { style: 'thSmall' }),
                        imprimirCelda('MARCA', null, {style: 'thSmall'}),
                        imprimirCelda('MODELO', null, {style: 'thSmall'}),
                        imprimirCelda('COLOR', null, {style: 'thSmall'}),
                        imprimirCelda('CANTIDAD', null, {style: 'thSmall'}),
                        imprimirCelda('PRECIO', null, {style: 'thSmall'}),
                        // imprimirCelda('TOTAL', null, { style: 'thSmall' }),
                        {
                            style: 'thSmall',
                            stack: [
                                {text: 'TOTAL'},
                                {
                                    canvas: [
                                        {
                                            type: 'line',
                                            x1: -1,
                                            y1: 0,
                                            x2: -1,
                                            y2: 465,
                                        },
                                        {
                                            type: 'line',
                                            x1: -45,
                                            y1: 0,
                                            x2: -45,
                                            y2: 465,
                                        },
                                    ],
                                    absolutePosition: {x: 0, y: 0},
                                    alignment: 'right',
                                },
                            ],
                        },
                    ],
                ];
            }
            const celdasBodyDetalle = [];

            dataRecibido[idxCompra].filter((dataProd, idxProd) => {
                let bordesCelda;
                bordesCelda = [true, true, true, true];

                if (esOrdenCompra) {
                    celdasBodyDetalle.push([
                        imprimirCelda(idxProd + 1, 'number', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCodigoProducto, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cProductoNombreObs, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                            colSpan: 3,
                        }),
                        {},
                        {},

                        imprimirCelda(dataProd.cCompraDetMarca, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCompraDetModelo, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCompraDetColor, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),

                        imprimirCelda(dataProd.nCompraDetCantidad, 'cant', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetPrecioUnitario, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetValorItemNeto, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                    ]);
                } else {
                    celdasBodyDetalle.push([
                        imprimirCelda(idxProd + 1, 'number', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cCodigoProducto, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.cProductoNombreObs, null, {
                            border: bordesCelda,
                            style: 'tdSmall',
                            italics: true,
                            colSpan: 3,
                        }),
                        {},
                        {},

                        imprimirCelda(dataProd.nCompraDetCantidad, 'cant', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'center',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetPrecioUnitario, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                        imprimirCelda(dataProd.nCompraDetValorItemNeto, 'mon', {
                            border: bordesCelda,
                            style: 'tdSmall',
                            alignment: 'right',
                            italics: true,
                        }),
                    ]);
                }
            });

            const dataExtraFinal = [];
            if (dataGeneral.cCompraObs) {
                dataExtraFinal.push([{text: 'OBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true}]);
            }
            const docARchivo = [];
            dataDocumentos.filter((documento) => {
                if (docARchivo.length == 0) {
                    dataExtraFinal.push({text: 'DOCUMENTOS: ', bold: true});
                }
                const textFinal = [
                    {text: documento['cTipoDocNombre']},
                    {
                        text: ' ' + documento['cCompraDocSerie'] + ' - ' + documento['cCompraDocNumero'],
                        color: '#06024f',
                        bold: true,
                    },
                    {text: ', EMISIÓN: ' + formatDate(documento['dCompraDocEmision'], 'dd/MM/yyyy', 'es-PE')},
                ];
                if (documento['dCompraDocVencimiento']) {
                    textFinal.push({text: ', VENCIMIENTO: ' + formatDate(documento['dCompraDocVencimiento'], 'dd/MM/yyyy', 'es-PE')});
                }
                if (documento['cCompraDocObs']) {
                    textFinal.push({text: ', OBS: ' + (documento['cCompraDocObs'] ?? '')});
                }

                dataExtraFinal.push({
                    text: textFinal,
                });
            });

            /*
            const strDocumentosArray = [];
            if (dataGeneral.cCompraObs) {
                strDocumentosArray.push({text: 'OBSERVACIÓN: ', bold: true}, {text: dataGeneral.cCompraObs, italics: true});
            }
            dataDocumentos.filter((documento) => {
                if (strDocumentosArray.length == 0) {
                    strDocumentosArray.push({text: '\nDOCUMENTOS:\n ', bold: true});
                }
                strDocumentosArray.push(
                    {text: documento['cTipoDocNombre']},
                    {
                        text: ' ' + documento['cCompraDocSerie'] + ' - ' + documento['cCompraDocNumero'],
                        color: '#06024f',
                        bold: true,
                    },
                    {text: ' (' + formatDate(documento['dCompraDocEmision'], 'dd/MM/yyyy', 'es-PE') + ')\n'}
                );
            });
            */

            if (esOrdenCompra) {
                celdasBodyDetalle.push([
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                    {
                        stack: dataExtraFinal,
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        color: '#001d47',
                        colSpan: 9,
                    },
                    {},
                    {},
                    {},

                    {},
                    {},
                    {},
                    {},
                    {},
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                ]);
            } else {
                celdasBodyDetalle.push([
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                    {
                        stack: dataExtraFinal,
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        color: '#001d47',
                        colSpan: 6,
                    },

                    {},
                    {},
                    {},

                    {},
                    {},
                    imprimirCelda('', null, {
                        border: [false, false, false, false],
                        style: 'tdSmall',
                        italics: true,
                    }),
                ]);
            }

            if (dataGeneral.cCompraAnuladoMotivo) {
                if (esOrdenCompra) {
                    celdasBodyDetalle.push([
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                        {
                            text: [
                                {text: 'MOTIVO DE ANULACIÓN: ', bold: true},
                                {text: dataGeneral.cCompraAnuladoMotivo, italics: true},
                            ],
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            color: '#680101',
                            colSpan: 6,
                        },
                        {},
                        {},
                        {},

                        {},
                        {},
                        {},
                        {},
                        {},
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                    ]);
                } else {
                    celdasBodyDetalle.push([
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                        {
                            text: [
                                {text: 'MOTIVO DE ANULACIÓN: ', bold: true},
                                {text: dataGeneral.cCompraAnuladoMotivo, italics: true},
                            ],
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            color: '#680101',
                            colSpan: 6,
                        },

                        {},
                        {},
                        {},

                        {},
                        {},
                        imprimirCelda('', null, {
                            border: [false, false, false, false],
                            style: 'tdSmall',
                            italics: true,
                        }),
                    ]);
                }
            }

            const tablaHeaderPedido = {
                margin: [0, 0, 0, 10],
                table: {
                    // widths: ['*'],
                    headerRows: 1,
                    widths: anchoColumnasDetalle,
                    body: celdasHeaderDetalle.concat(celdasBodyDetalle),
                },
            };
            cuerpoPdf.push(tablaHeaderPedido);

            objThis.finalizarCreacionPdf(cuerpoPdf);
        });
    });
}



export function movimientosProductos(objThis: PdfLogisticaService, idsCadena) {
    objThis.queryService.cargar(objThis, ['log.movimientos_productos_detalles'], {cCodigoCadena: idsCadena}).then((valRetorno: object) => {
        const dataRecibido: any[] = valRetorno['log.movimientos_productos_detalles'];

        Object.keys(dataRecibido).forEach((idCadena) => {
            const dataGeneral = dataRecibido[idCadena][0];

            const localLocale = moment(dataGeneral.dMovProdMovimiento, 'YYYY-MM-DD');
            moment.locale('es');
            localLocale.locale(false);

            objThis.reiniciarOpciones({
                // marcaDeAgua: mDeAgua,
                margenesHoja: [40, 80, 40, 40],
                tamanioFuenteExtra: 1,
                orientacion: 'landscape',
                defaultSizeHeaderTable: [150, '*', 150],
                defaultLeftCellHeaderTable: objThis.logotipoHeader(dataGeneral),
                defaultRightCellHeaderTable: {
                    // margin: [0, 10, 5, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                // imprimirCelda('PAG.', null, {fontSize: 7, alignment: 'center'}),
                                imprimirCelda(' Pag. ##pagina## de ##total_paginas##', null, {colSpan: 2, fontSize: 7, alignment: 'center'}),
                                {},
                            ],
                            [
                                imprimirCelda('NÚMERO', null, {fontSize: 8, alignment: 'center'}),
                                imprimirCelda('FECHA', null, {fontSize: 9, alignment: 'center'}),
                            ],
                            [
                                imprimirCelda(zeroFill(dataGeneral.iMovProdNumero, 4), null, {
                                    fontSize: 12, bold: true, alignment: 'center',
                                }),
                                imprimirCelda(dataGeneral.dMovProdMovimiento, 'fec', {
                                    fontSize: 12,
                                    bold: true,
                                    alignment: 'center',
                                }),
                            ],
                        ],
                    },
                },
                defaultCenterCellHeaderTable: {
                    margin: [0, 10, 0, 0],
                    alignment: 'center',
                    stack: [
                        imprimirCelda('ACTA DE CONFORMIDAD DE BIENES', null, {fontSize: 12, bold: true}),
                        imprimirCelda(dataGeneral.cTipoMovProdNombre ?? '', null, {fontSize: 13, bold: true}),
                        imprimirCelda('MOTIVO: ' + dataGeneral.cMotMovProdNombre ?? '', null, {fontSize: 11, bold: true}),
                    ],
                },
                footerTableLeftStackPrepen: [
                    'Reporte creado por ' + (dataGeneral['cPersCreadoNombreLargo'] ?? '') + ' (' + (dataGeneral['cPersCreadoDocumento'] ?? '') + ')',
                ],
            });
            const cuerpoPdf = [];

            cuerpoPdf.push({
                columns: [
                    {
                        margin: [0, 0, 0, 10],
                        table: {
                            widths: ['auto', '*', 'auto', '*'],
                            headerRows: 0,
                            body: [
                                /*
                                [
                                    imprimirCelda('CONCEPTO', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersRecibeNombreLargo ?? ''), null, {
                                        colSpan: 3,
                                        italics: true, border: [false, true, true, false],
                                    }),
                                    {},
                                    {},
                                ],
                                [
                                    imprimirCelda('TIPO DE PROCESO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, false, false],
                                    }),
                                    imprimirCelda('NRO PROCESO1', null, {bold: true, border: [false, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, false],
                                    }),
                                ],
                                 */
                                [
                                    imprimirCelda('NRO RUC PROVEEDOR', null, {bold: true, border: [true, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersDocumento ?? ''), null, {
                                        italics: true, border: [false, true, false, false],
                                    }),
                                    imprimirCelda('PROVEEDOR', null, {bold: true, border: [false, true, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cPersNombreLargo ?? ''), null, {
                                        italics: true, border: [false, true, true, false],
                                    }),
                                ],
                                /*
                                [
                                    imprimirCelda('NRO CONTRATO', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, false, false],
                                    }),
                                    imprimirCelda('NRO O/C', null, {bold: true, border: [false, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        italics: true, border: [false, false, true, false],
                                    }),
                                ],
                                 */
                                [
                                    imprimirCelda(dataGeneral.cTipoDocNombre ?? '', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cMovProdDocSerie ?? '') + '-' + (dataGeneral.iMovProdDocNumero ? zeroFill(dataGeneral.iMovProdDocNumero, 6) : ''), null, {
                                        italics: true, border: [false, false, false, false],
                                    }),
                                    imprimirCelda('FECHA DOCUMENTO', null, {bold: true, border: [false, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.dMovProdDocEmision ? formatDate(dataGeneral.dMovProdDocEmision, 'dd/MM/yyyy', 'es-PE') : ''), null, {
                                        italics: true, border: [false, false, true, false],
                                    }),
                                ],
                                [
                                    imprimirCelda('ALMACEN', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cAlmacenNombre ?? ''), null, {
                                        colSpan: 3,
                                        italics: true, border: [false, false, true, false],
                                    }),
                                    {},
                                    {},
                                ],
                                /*
                                [
                                    imprimirCelda('NRO DOC REF', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.cCargoRecibeNombre ?? ''), null, {
                                        colSpan: 3,
                                        italics: true, border: [false, false, true, false],
                                    }),
                                    {},
                                    {},
                                ],

                                 */
                                [
                                    imprimirCelda('FECHA CONFORMIDAD', null, {bold: true, border: [true, false, false, false]}),
                                    imprimirCelda(': ' + (dataGeneral.dMovProdMovimiento ? formatDate(dataGeneral.dMovProdMovimiento, 'dd/MM/yyyy', 'es-PE') : ''), null, {
                                        colSpan: 3,
                                        italics: true, border: [false, false, true, false],
                                    }),
                                    {},
                                    {},
                                ],

                                [
                                    imprimirCelda('REFERENCIA', null, {bold: true, border: [true, false, false, true]}),
                                    imprimirCelda(': ' + (dataGeneral.cMovProdReferencia ?? ''), null, {
                                        colSpan: 3,
                                        italics: true, border: [false, false, true, true],
                                    }),
                                    {},
                                    {},
                                ],


                            ],
                        },
                    },
                ]
            });

            const camposPrincipal2: ColumnaTabla<any>[] = [
                {label: 'CÓDIGO', property: 'cCodigoProducto', type: 'text'},
                {label: 'DESCRIPCIÓN', property: 'cProductoNombreObs', type: 'text'},
                {label: 'U/M', property: 'cPresentacionNombre', type: 'text'},
                {label: 'MARCA.', property: 'cMovProdDetMarca', type: 'text'},
                {label: 'MODELO', property: 'cMovProdDetModelo', type: 'text'},
                {label: 'SERIE', property: 'cMovProdDetSerieNumero', type: 'text'},
                {label: 'COLOR', property: 'cMovProdDetColor', type: 'text'},
                {label: 'CANTIDAD', property: 'nMovProdDetCantidad', type: 'number'},
                {label: 'P. UNIT', property: 'nMovProdDetPrecioUnitario', type: 'money'},
                {label: 'SUB TOTAL', property: 'nMovProdDetSubTotal', type: 'money'},
            ];


            cuerpoPdf.push(objThis.generarDesdeMantenimiento({
                titulos: camposPrincipal2,
                contenido: dataRecibido[idCadena],
                numeracion: true,
                idxResto: [2],
                margin: [0, 5, 0, 5],
                // separado: true,
            }));


            if (dataGeneral.cMovProdObs) {
                cuerpoPdf.push(imprimirCelda('Observacion:\n ' + dataGeneral.cMovProdObs ?? '', null, {italics: true, margin: [30, 0, 0, 10]}));
            }

            cuerpoPdf.push(
                imprimirCelda('Luego de verificar los bienes, proceden a firmar en señal de conformidad.'),
                imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true}),
            );
            cuerpoPdf.push({
                margin: [0, 50, 0, 0],
                columns: [
                    {},
                    {},
                    // {
                    //     alignment: 'center',
                    //     stack: [
                    //         '_____________________________________',
                    //         'Recibí Conforme',
                    //     ]
                    // },
                    {},
                    {
                        alignment: 'center',
                        stack: [
                            '_____________________________________',
                            'ALMACEN',
                        ]
                    },
                    {},
                ],
            });



            objThis.finalizarCreacionPdf(cuerpoPdf);
        });
    });
}

export function kardex(objThis: PdfLogisticaService, dataRec) {

    console.log(dataRec);


    const dProducto = dataRec['producto'];
    // const dTitulos = dataRec['titulos'];
    const dTitulos: ColumnaTabla<any>[] = [
        {label: '#', property: 'numeracion_automatica', type: 'custom', ocultarReporte: true},
        { label: 'Fecha', property: 'dFecha', type: 'date', cssClasses: ['font-bold'] },
        {label: 'Documento', property: 'cDocumento', type: 'text', cssClasses: ['underline text-blue-600 hover:text-rose-900 visited:text-rose-700']},
        { label: 'Proveedor<br>Solicitante', property: 'cPersNombreLargo', type: 'text',  },



        { label: 'Cantidad', property: 'nIngreso', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        { label: 'Costo<br>Unitario', property: 'nCostoUnitarioIngreso', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        { label: 'Costo<br>Total', property: 'nCostoTotalIngreso', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },

        { label: 'Cantidad', property: 'nSalida', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        { label: 'Costo<br>Unitario', property: 'nCostoUnitarioSalida', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        { label: 'Costo<br>Total', property: 'nCostoTotalSalida', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },

        { label: 'Stock', property: 'nStock', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        { label: 'Costo<br>Unitario', property: 'nCostoPromedioUnitario', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        { label: 'Costo<br>Total', property: 'nCostoTotal', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },

        // { label: 'Valor<br>Unitario', property: 'nValorUnitario', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
        // { label: 'Valor<br>Total', property: 'nValorTotal', type: 'number', format: '1.2-2', cssClasses: ['text-right'] },
    ];
    const dData = dataRec['data'];

    const cuerpoPdf = [];

    objThis.reiniciarOpciones({
        margenesHoja: [50, 100, 50, 40],
        orientacion: 'landscape',
        tamanioFuenteExtra: 2,
        customDefTableHeader: objThis.headerTableEntidad([
            imprimirCelda('KARDEX DE PRODUCTO\n' + '[' + dProducto['cCodigoProducto'] + '] ' + dProducto['cProductoNombre'] + '\n(' + dProducto['cPresentacionNombre'] + ')', null, {fontSize: 13, bold: true}),
        ], [100, '*', 100], [0, 20, 0, 0]),
    });

    const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
        titulos: dTitulos,
        contenido: dData,
        numeracion: true,
        idxResto: [1],
        margin: [0, 5, 0, 5],
        separado: true,
    });



    const tablaTitulos = [
        [
            imprimirCelda('\nNº', null, {
                style: 'thSmall', rowSpan: 2, alignment: 'center',
            }),
            imprimirCelda('\nFecha', null, {
                style: 'thSmall', rowSpan: 2, alignment: 'center',
            }),
            imprimirCelda('\nDocumento', null, {
                style: 'thSmall', rowSpan: 2, alignment: 'center',
            }),
            imprimirCelda('\nProveedor', null, {
                style: 'thSmall', rowSpan: 2, alignment: 'center',
            }),
            imprimirCelda('Entradas', null, {
                style: 'thSmall', colSpan: 3, alignment: 'center',
            }),
            {},
            {},
            imprimirCelda('Salidas', null, {
                style: 'thSmall', colSpan: 3, alignment: 'center',
            }),
            {},
            {},
            imprimirCelda('Saldo Final', null, {
                style: 'thSmall', colSpan: 3, alignment: 'center',
            }),
            {},
            {},
        ],
    ];

    cuerpoPdf.push(
        {
            margin: [0, 0, 0, 10],
            table: {
                dontBreakRows: true,
                headerRows: 2,
                // widths: objThis.anchoCols(tablaGeneradaSoloTitulos['cuerpo'], [2]),
                widths: objThis.anchoCols(tablaTitulos, [3]),
                body: tablaTitulos.concat(tablaGeneradaSoloTitulos['titulos']).concat(tablaGeneradaSoloTitulos['cuerpo']),
            },
        },
        // imprimirCelda('Moquegua, ' + localLocale.format('LL'), null, {alignment: 'right', bold: true})
    );
    cuerpoPdf.push({
        columns: [
            {width: '*', text: ''},
            {
                width: 'auto',
                table: {
                    body: [
                        [
                            imprimirCelda('STOCK', null, {
                                bold: true,
                                style: 'thSmall',
                                alignment: 'right',
                                fontSize: 9,
                            }),
                            imprimirCelda(dataRec['nStock_productos'], 'cant', {
                                bold: true,
                                style: 'tdSmall',
                                fontSize: 9,
                            })
                        ],
                        [
                            imprimirCelda('COSTO TOTAL', null, {
                                bold: true,
                                style: 'thSmall',
                                alignment: 'right',
                                fontSize: 9,
                            }),
                            imprimirCelda(dataRec['nCostoTotal'], 'mon', {
                                bold: true,
                                style: 'tdSmall',
                                fontSize: 9,
                            })
                        ]
                    ],
                },
            },
            {width: '*', text: ''},
        ],
    });

    // cuerpoPdf.push(tablaGeneradaSoloTitulos);
    objThis.finalizarCreacionPdf(cuerpoPdf);

}
