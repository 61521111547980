import {ColumnaTabla, IconsIcon} from '@JVSoft/interfaces/global';
import {dataEnLista} from '@JVSoft/pipes/data-en-lista.pipe';
import {establecerEstilosPorDefecto} from '../modulos.functions';

export function estilosPorDefectoLogistica(objThis, property: string | null = null): ColumnaTabla<any>[] | ColumnaTabla<any> {
    const estilos: ColumnaTabla<any>[] = [
        {
            label: 'Órden', property: 'cCompraNumero', type: 'text', cssClasses: ['text-center'],
            textCss: 'logistica-numero-documento span-dialog-flotante-blue-light',
            // click: (row) => {
            //     objThis.pdfLogisticaService.compra(row['iCompraId'].toString());
            // },
        },
        {
            label: 'Orden Servicio', property: 'cCompraAlquilerNumero', type: 'text', cssClasses: ['text-center'],
            textCss: 'logistica-numero-documento boton-dialog-flotante-blue-light',
            click: (row) => {
                objThis.pdfLogisticaService.compra(row['iCompraDetAlquilerId'].toString());
            },
        },
        {
            label: 'Cuadro Comparativo', property: 'cCuaComNumero', type: 'text', cssClasses: ['text-center'],
            textCss: 'logistica-numero-documento span-dialog-flotante-cyan-light',
        },
        {
            label: 'Pedido', property: 'cPedidoNumero', type: 'text', cssClasses: ['max-w-xs'],
            text: {separador: ',', cssContenedor: 'flex flex-wrap gap-1'},
            textCss: 'flex-1 font-bold logistica-numero-documento-numero boton-dialog-flotante-lime-light',
            transformar(row): any {
                return row['cPedidoNumero'] != '-' ? row['cPedidoNumero'] : '' ;
            },
            // innerHTML(row): string { return valoresConComas(this, row); },
            click: (row) => {
                if (row['cPedidoId']) {
                    objThis.pdfLogisticaService.pedido(row['iPedidoId'].toString());
                }
            },
        },
        {
            label: 'Salida', property: 'cSalidaNumero', type: 'text', cssClasses: ['max-w-xs'],
            text: {separador: ',', cssContenedor: 'flex flex-wrap gap-1'},
            textCss: 'flex-1 logistica-numero-documento-numero span-dialog-flotante-red-light',
            // innerHTML(row): string { return valoresConComas(this, row); }
        },
        {
            label: 'Cotización', property: 'cCotizaNumero', type: 'text', cssClasses: ['text-center'],
            textCss: 'logistica-numero-documento span-dialog-flotante-indigo-light',
        },
        {
            label: 'Vale', property: 'cValeNumero', type: 'text', cssClasses: ['max-w-xs'],
            text: {separador: ',', cssContenedor: 'flex flex-wrap gap-1'},
            textCss: 'flex-1 font-bold logistica-numero-documento-numero span-dialog-flotante-amber-light',
            transformar(row): any {
                return row['cValeNumero'] != '-' ? row['cValeNumero'] : '' ;
            },
            // innerHTML(row): string { return valoresConComas(this, row); },
            // click: (row) => {
            //     if (row['cValeId']) {
            //         objThis.pdfAlmacenService.vale({cCodigoCadena: row['cValeId'].toString()});
            //     }
            // },
        },
        // MISMO QUE EL ANTERIOR
        // {
        //     label: 'Órden', property: 'cCompraNumero', type: 'text', cssClasses: ['text-center'],
        //     textCss: 'w-full text-blue-900 bg-blue-100 border border-blue-900 font-bold rounded-md text-center text-2xs px-1 whitespace-nowrap',
        // },

        // {
        //     label: 'Cotización', property: 'cCotizaNumero', type: 'text', cssClasses: ['text-center'],
        //     textCss: 'w-full text-green-900 bg-green-100 border border-green-900 font-bold rounded-md text-center text-2xs px-1 whitespace-nowrap',
        // },
        {
            label: 'Nº Órden', property: 'iCompraNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento boton-dialog-flotante-blue-light',
            click: (row) => {
                objThis.pdfLogisticaService.compra(row['iCompraId'].toString());
            },
        },
        {
            label: 'Nº Cuadro<br>Comparativo', property: 'iCuaComNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento boton-dialog-flotante-cyan-light',
            click: (row) => {
                objThis.pdfLogisticaService.cuadro_comparativo(row['iCuaComId'].toString());
            },
        },
        {
            label: 'Nº Pedido', property: 'iPedidoNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'flex-1 font-bold  logistica-numero-documento boton-dialog-flotante-lime-light',
            transformar(row): any {
                return row['iPedidoNumero'] != '-' ? row['iPedidoNumero'] : '' ;
            },
            click: (row) => {
                objThis.pdfAlmacenService.pedidoVale({cCodigoCadena: row['iPedidoId'].toString()});
            },
        },
        {
            label: 'Nº PECOSA', property: 'iSalidaNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento boton-dialog-flotante-red-light',
            click: (row) => {
                objThis.pdfLogisticaService.pecosa(row['iSalidaId'].toString());
            },
        },
        {
            label: 'Nº Cotización', property: 'iCotizaNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento boton-dialog-flotante-indigo-light',
            click: (row) => {
                objThis.pdfLogisticaService.cotizacion(row['iCotizaId'].toString());
            },
        },
        {
            label: 'Nº Vale', property: 'iValeNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'flex-1 font-bold  logistica-numero-documento span-dialog-flotante-amber-light',
            transformar(row): any {
                return row['iValeNumero'] != '-' ? row['iValeNumero'] : '' ;
            },
            // click: (row) => {
            //     objThis.pdfAlmacenService.vale({cCodigoCadena: row['iValeId'].toString()});
            // },
        },
        {
            label: 'Nº Valorización', property: 'iValorizaValeNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento span-dialog-flotante-green-light',
        },
        {
            label: 'Nº Valorización', property: 'iValorizaParDiaVehNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento boton-dialog-flotante-green-light',
            transformar(row): any {
                return row['iValorizaParDiaVehNumero'] != '-' ? row['iValorizaParDiaVehNumero'] : '' ;
            },
            click: (row) => {
                objThis.pdfAlmacenService.valorizacionParteDiario({cCodigoCadena: row['iValorizaParDiaVehId'].toString()});
            },
        },
        {
            label: 'Nº Parte Diario', property: 'iParDiaVehNumero', type: 'text', sortType: 'number', cssClasses: ['font-bold', 'text-center'], zeroFill: 4,
            textCss: 'logistica-numero-documento boton-dialog-flotante-yellow-light',
            transformar(row): any {
                return row['iParDiaVehNumero'] != '-' ? row['iParDiaVehNumero'] : '' ;
            },
            click: (row) => {
                objThis.pdfAlmacenService.parteDiario({cCodigoCadena: row['iParDiaVehId'].toString()});
            },
        },
    ];
    if (property) {
        return dataEnLista(estilos, 'property', property);
    }
    return estilos;
}


export function establecerEstilosLogistica(objThis: any, columnasTabla: ColumnaTabla<any>[]): ColumnaTabla<any>[] {
    columnasTabla = establecerEstilosPorDefecto(objThis, columnasTabla);
    // @ts-ignore
    const estilos: ColumnaTabla<any>[] = estilosPorDefectoLogistica(objThis);

    return columnasTabla.map(col => {
        // const existe = dataEnLista(estilos, 'property', col.property);
        const existe = estilos.find(item => item.property == col.property);
        if (existe && col.type === 'estiloEstablecido') {
            // CONDICION ESTILOS EXTRA
           /* if (existe.click) {
                const claseString = (Array.isArray(existe.textCss) ? existe.textCss.join(' ') : existe.textCss);
                const matches = claseString.match(/span-dialog-flotante-(.[^\s]+)-light/);
                if (matches) {
                    existe.textCss = claseString + ' !font-black';
                }
            }*/
            return existe;
        }
        return col;
    });
}

export function estiloEstadoRegistro(datos: {campoCerrado?: string, campoAnulado?: string, campoConformidad?: string, campoMensajeAnulado?: string}): ColumnaTabla<any> {
    const itemsData: IconsIcon[] = [];
    if (datos.campoCerrado) {
        itemsData.push(
            {
                icon: 'fa5sLockOpen', contentCss: ' text-blue-700',
                esVisible(row): boolean { return (row[datos.campoCerrado] == null || row[datos.campoCerrado] == 0) && (row[datos.campoAnulado] == null || row[datos.campoAnulado] == 0) && row[datos.campoConformidad] != 1; }
            },
            {
                icon: 'fa5sLock', contentCss: ' text-red-700',
                esVisible(row): boolean { return row[datos.campoCerrado] == 1 && (row[datos.campoAnulado] == null || row[datos.campoAnulado] == 0) && row[datos.campoConformidad] != 1; }
            },
        );
    }
    if (datos.campoAnulado) {
        itemsData.push(
            {
                icon: 'roundNotInterested', contentCss: ' text-red-700', toolTipField: datos.campoMensajeAnulado ?? undefined,
                esVisible(row): boolean { return row[datos.campoAnulado] == 1 && row[datos.campoConformidad] != 1; },
            },
        );
    }
    if (datos.campoConformidad) {
        itemsData.push(
            {
                icon: 'roundCheckCircle', contentCss: ' text-green-700',
                esVisible(row): boolean { return row[datos.campoConformidad] == 1; }
            },
        );
    }
    return {
        label: 'E', labelLista: 'Estado', property: 'Estado', type: 'icons',
        iconsDef: {
            soloIcono: true,
            iconSize: '16px',
            items: itemsData
        },
        transformar(row): any {
            if (row[datos.campoAnulado] == 1) {
                return 'ANULADO';
            }
            if (row[datos.campoCerrado] == 1) {
                return 'CERRADO';
            }
            if (row[datos.campoConformidad] == 1) {
                return 'CONFORMIDAD';
            }
        }
    };
}
