<div @stagger class="px-6 py-4" fxLayout="column" fxLayoutGap="16px" *ngIf="inputValue && infoSeleccionado">
	<div fxLayout="row" fxLayoutAlign="start center">
		<img @scaleIn [src]="servidorService.photoThumbURL + infoSeleccionado.iPersId + '?ancho=150&alto=150'" alt="Foto" class="avatar ltr:mr-3 rtl:ml-3" fxFlex="none"/>
		<div @fadeInRight fxFlex="auto">
			<h4 class="body-2 m-0 leading-snug">{{ infoSeleccionado.cPersNombreLargo }}</h4>
			<h5 class="text-secondary m-0 caption leading-none">{{infoSeleccionado.cTipoIdentSigla}} {{infoSeleccionado.cPersDocumento}}</h5>
		</div>
		<button (click)="limpiarControl()" [disabled]="isDisabled" color="primary" mat-icon-button type="button">
			<mat-icon svgIcon="twotoneDelete"></mat-icon>
		</button>
	</div>
</div>

<mat-form-field [formGroup]="frmBusqueda" *ngIf="!inputValue" style="display: inline !important;" [appearance]="appearance">
	<mat-label>{{(label ? label : 'Persona')}}</mat-label>


    <div matPrefix
         fxLayout="row wrap"
         fxLayoutAlign="start center"
    >
        <button mat-icon-button *ngIf="registro" color="primary" type="button" fxLayout="row" fxLayoutAlign="space-around center"
                (click)="abrirModal({accion: 'nuevo'}); $event.preventDefault();"
        >
            <mat-icon class="icon-xs" svgIcon="roundPersonAdd"></mat-icon>
        </button>
    </div>

	<input matInput formControlName="cCampoBusqueda"
           [disabled]="isDisabled"
	       (keydown.enter)="abrirModal({})"
	       [placeholder]="placeholder"
	       autocomplete="off"
	>

	<div matSuffix
	     fxLayout="row wrap"
	     fxLayoutAlign="start center"
         *ngIf="!isDisabled"
	>
		<button *ngIf="btnLabel" mat-flat-button (click)="abrirModal({})"
		        fxLayout="row wrap"
		        fxLayoutAlign="start center"
		>
			<mat-icon svgIcon="twotoneSearch" class="icon-xs" fxFlex="auto"></mat-icon> <span fxFlex="auto">{{(btnLabel ? '&nbsp;' + btnLabel : '')}}</span>
		</button>
		<button *ngIf="!btnLabel" mat-icon-button (click)="abrirModal({})"
		        fxLayout="row wrap"
		        fxLayoutAlign="center center"
		>
			<mat-icon svgIcon="twotoneSearch" class="icon-xs"></mat-icon>
		</button>


<!--        <button mat-icon-button *ngIf="registro" color="primary" type="button" fxLayout="row" fxLayoutAlign="space-around center"
                (click)="abrirModal({accion: 'nuevo'}); $event.preventDefault();"
        >
            <mat-icon class="icon-xs" svgIcon="roundPersonAdd"></mat-icon>
&lt;!&ndash;            <span>Nuevo</span>&ndash;&gt;
        </button>-->
	</div>



</mat-form-field>

<ng-template #dialogResultados>
	<div globalDialogFlotante btnCerrar [matDialogRefActual]="dialogRefSeleccion">
		<ng-container titulo>
			{{ (label ? label : 'Persona') }}
		</ng-container>
		<div cuerpo fxLayout="column">
			<div class="mt-1" fxLayout="column" fxLayoutGap="2px">
				<div @stagger fxLayout="column" fxLayoutGap="4px">
					<ng-container *ngFor="let info of dataServidor.personas; let i = index">
						<div fxLayout="row" fxLayoutAlign="start center"
							 class="cursor-pointer"
							 *ngIf="!idxTipoIdentidadNoPermitidos.includes(info.iTipoIdentId)"
							 (click)="seleccionarPersona(info);"
						>
							<img @scaleIn [src]="servidorService.photoThumbURL + info.iPersId + '?ancho=150&alto=150'" alt="Foto" class="avatar ltr:mr-3 rtl:ml-3" fxFlex="none"/>
							<div @fadeInRight fxFlex="auto">
								<h4 class="body-2 m-0 leading-snug">{{ info.cPersNombreLargo }}</h4>
								<h5 class="text-secondary m-0 caption leading-none">{{info.cTipoIdentSigla}} {{info.cPersDocumento}}</h5>
							</div>
						</div>
					</ng-container>
				</div>
				<div @stagger fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="4px" fxLayoutGap.gt-xs="10px" class="text-center" *ngIf="dataServidor.personas.length < 1">
					NO SE ENCONTRARON RESULTADOS
				</div>
			</div>
		</div>
	</div>
</ng-template>
