import * as XLSX from 'xlsx';

const getFileName = (name: string) => {
    const timeSpan = new Date().toISOString();
    const sheetName = name || 'ExportResult';
    const fileName = `${sheetName}-${timeSpan}`;
    return {
        sheetName,
        fileName
    };
};
export class TableUtil {
    static exportTableToExcelV2(targetTableElm: HTMLElement, name?: string) {
        const { sheetName, fileName } = getFileName(name);
        console.log(targetTableElm);
        const wb = XLSX.utils.table_to_book(targetTableElm, {
            sheet: sheetName
        } as XLSX.Table2SheetOpts);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
    static exportTableToExcel(tableId: string, name?: string) {


        const { sheetName, fileName } = getFileName(name);
        const targetTableElm = document.getElementById(tableId);
        console.log(targetTableElm);
        const wb = XLSX.utils.table_to_book(targetTableElm, {
            sheet: sheetName
        } as XLSX.Table2SheetOpts);
        // XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    static exportArrayToExcel(arr: any[], name?: string) {
        const { sheetName, fileName } = getFileName(name);

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(arr, {dateNF: 'dd/MM/yyyy'});
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    static getWorkBook(htmlTableId: string, data: any[]) {
        const targetTableElm = document.getElementById(htmlTableId);
        const tableToSheet = XLSX.utils.table_to_sheet(targetTableElm, {raw: true});
        const jsonToSheet = XLSX.utils.json_to_sheet(data);
        const sheetToJson = XLSX.utils.sheet_to_json(tableToSheet);
        return {tableToSheet, jsonToSheet, sheetToJson};
    }
}
