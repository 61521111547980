import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {UsuarioService} from '../services/usuario.service';
import {FuncionesGeneralesService} from '../general/services/funciones-generales.service';

@Injectable({
	providedIn: 'root'
})
export class AccesosGuard implements CanActivate, CanActivateChild {
	constructor(
		private usuarioService: UsuarioService,
		private funcionesGenerales: FuncionesGeneralesService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return true;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if (childRoute.data.iMenuId) {
			// console.log(childRoute.data.iMenuId);
			const va = this.usuarioService.verificarPermisoMenu( childRoute.data.iMenuId );
			// console.log(va);
			if (va) {
				return true;
			}
			else {
				this.funcionesGenerales.mensajeToast('error', 'Acceso no autorizado', 'Área restringida por el administrador (' + childRoute.url + ').');
				return this.router.createUrlTree(
					['/dashboard', { message: 'Acceso restringido' }]
				);
			}
			console.warn(va);
			// return va;
			// return !!va['iMenuId'] || this.urlInicial();
		}
		// this.router.navigate(['/dashboard']);
		return true;
	}
	urlInicial() {
		this.funcionesGenerales.mensajeToast('error', 'Acceso no autorizado', 'Área restringida por el administrador');
		return this.router.createUrlTree(
			['/dashboard', { message: 'Acceso restringido' }]
		);
	}

	listaAccesosSinJerarquia(accesos: any[]) {
		// accesos.forEach()
	}

}
