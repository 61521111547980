<div globalDialogFlotante btnCerrar>

	<ng-container titulo>
		{{ persona.cPersNombreLargo }} :: <small>Datos de contacto</small>
	</ng-container>
	<div cuerpo fxLayout="column">

		<general-tabla-mantenimiento-nuevo
			[objThis]="this"
			nombreColeccion="personas_contactos"
			[data]="dataServidor.grl.personas_contactos"
			[columnasTabla]="columns"
			(opcionSelecionada)="opcMenu($event)"
		>

		</general-tabla-mantenimiento-nuevo>

	</div>

</div>

<!--TEMPLATES-->

<ng-template #dialogRegistro>
	<form globalDialogFlotante btnGuardar
		  (ngSubmit)="opcMenu({ seccion: 'personas_contactos', tipo: 'guardar' })" [formGroup]="frmRegistro"
		  btnCerrar="CANCELAR" [cssClases]="{btnGuardar: 'text-primary-contrast'}" [matDialogRefActual]="dialogRef"
	>

		<ng-container titulo>
			<span class="m-0" fxFlex="auto" *ngIf="!frmRegistro.get('iPersConId').value">{{ persona.cPersNombreLargo }} :: <small>Nuevo Contacto</small></span>
			<span class="m-0" fxFlex="auto" *ngIf="frmRegistro.get('iPersConId').value">{{ persona.cPersNombreLargo }} :: <small>{{ seleccionados.personas_contactos.cTipoConNombre }}</small></span>
		</ng-container>
		<div cuerpo fxLayout="column">

			<mat-form-field fxFlex="grow" *ngIf="!frmRegistro.get('iPersConId').value">
				<mat-label>Tipo</mat-label>
				<mat-select formControlName="iTipoConId">
					<mat-option [value]="item.iTipoConId" *ngFor="let item of dataServidor.grl.tipos_contactos">{{ item.cTipoConNombre }}</mat-option>
				</mat-select>
				<mat-error>{{ funcionesGeneralesService.mensajesErrorFormControl(frmRegistro.get('iTipoConId')) }}</mat-error>
			</mat-form-field>

			<mat-form-field fxFlex="grow">
				<mat-label>Detalle</mat-label>
				<input cdkFocusInitial formControlName="cPersConNombre" placeholder="+51 999 9999, tu@correo.com" matInput>
				<mat-error>{{ funcionesGeneralesService.mensajesErrorFormControl(frmRegistro.get('cPersConNombre')) }}</mat-error>
			</mat-form-field>

			<div fxFlex="grow">
				<mat-checkbox class="caption" formControlName="bPersConPrincipal" color="primary">Contacto principal</mat-checkbox>
			</div>

		</div>
	</form>


</ng-template>


