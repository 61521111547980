import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionService} from '../services/session.service';
import {tap} from 'rxjs/operators';
import {UsuarioService} from '@servicios/usuario.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(
	  public sessionService: SessionService,
	  public usuarioService: UsuarioService,
  ) {
  }

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			tap(evt => {
				if (evt instanceof HttpResponse) {
					if ((evt.url.indexOf('api/auth/user') !== -1 || evt.url.indexOf('api/user') !== -1) && evt.body) {
						this.usuarioService._data.next(evt.body);
					}
				}
			})
		);
	}
}
