import {appIcons} from '../../general/services/iconify-icons.service';
import {iconsFontAwesome} from '@servicios/icons/font-awesome-5';
import {iconsGoogleMaterial} from '@servicios/icons/google-material';
import {iconsVSCode} from '@servicios/icons/vscode';

export * from './font-awesome-5';
export * from './google-material';

export const appIconsAll = {
    ...appIcons,
    ...iconsFontAwesome,
    ...iconsGoogleMaterial,
    ...iconsVSCode,
}
