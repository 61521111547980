<ng-container *ngIf="yet">
    <button mat-button [matMenuTriggerFor]="matmenus.first">
        <ng-content></ng-content>
    </button>
</ng-container>

<ng-container *ngFor="let menu of submenus">
    <mat-menu>
        <ng-container *ngFor="let item of menu">
            <button mat-menu-item *ngIf="item.subMenu!=-1 && yet"
                    [matMenuTriggerFor]="getMenu(item.subMenu)">
                <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                <span>{{item.label}}</span>
            </button>
            <button mat-menu-item *ngIf="item.subMenu==-1" (click)="onClick(item.action)">
                <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                <span>{{item.label}} </span>
            </button>
        </ng-container>
    </mat-menu>
</ng-container>
