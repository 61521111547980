<div class="card overflow-hidden">
	<div class="h-20 relative overflow-hidden">
		<img class="w-full h-full object-cover" src="assets/img/demo/landscape.jpg">
		<div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>

		<img class="avatar h-24 w-24 absolute top-6 ltr:left-4 rtl:right-4 sm:hidden"
			 [src]="usuarioService.photoThumbURL + '?ancho=150&alto=150'"
		>
	</div>

	<div class="z-10 relative -mt-20 px-gutter flex items-center">
		<!--			<img [src]="dataService.photoThumbURL + sessionService.dataUsuario.getValue()['data_persona']['iPersId']" class="avatar">-->
		<img @scaleIn
			 class="avatar h-20 w-20 flex-none align-start hidden sm:block border-3 border-white"
			 [src]="usuarioService.photoThumbURL"
		>

		<div class="h-fit max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
			<div class="h-16 flex items-center">
				<h1 @fadeInRight class="headline text-contrast-white m-0 bg-primary text-primary-contrast px-2 rounded-sm">
					BIENVENIDO {{ usuarioService.dataUsuarioPersona.cPersNombreLargo }}</h1>
			</div>
		</div>
	</div>
    <div class="h-fit relative overflow-hidden">
        <img class="w-full h-fit -mt-20 object-cover" src="assets/img/demo/4853433.jpg">
        <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-fit z-0 grid content-center text-center">

            <h1 class="text-red-900">BIENVENIDO AL SISTEMA</h1>
            <h3>SISTEMA INFORMÁTICO PARA LA GESTIÓN DE INFORMACIÓN</h3>
            <vex-modulos *ngIf="entidadService.idEntidadFija"></vex-modulos>
        </div>

    </div>
</div>


