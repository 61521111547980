import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BuscarPersonaComponent} from './buscar-persona/buscar-persona.component';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {FileUploadDirective} from './file-upload/file-upload.directive';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FileUploadInputComponent} from './file-upload-input/file-upload-input.component';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {MatLineModule, MatRippleModule} from '@angular/material/core';
import {MatBuscarTareaComponent} from './mat-buscar-tarea/mat-buscar-tarea.component';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {CdkMenu, CdkMenuItem} from '@angular/cdk/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
	declarations: [
		BuscarPersonaComponent,
        MatBuscarTareaComponent,
		FileUploadComponent,
		FileUploadDirective,
  FileUploadInputComponent,
	],
	exports: [
		BuscarPersonaComponent,
        MatBuscarTareaComponent,
		FileUploadComponent,
		FileUploadInputComponent,
	],
    imports: [
        CommonModule,
        FlexModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        MatListModule,
        MatProgressBarModule,
        JVSoftModule,
        MatLineModule,
        MatRippleModule,
        MatSelectModule,
        MatAutocompleteModule,
        CdkConnectedOverlay,
        CdkOverlayOrigin,
        CdkMenu,
        CdkMenuItem,
        MatTooltipModule,
        MatCheckboxModule,
        MatMenuModule,
    ],
})
export class FormControlsModule { }
