<ng-container *ngIf="!datosDialogRecibidos?.esDialog" [ngTemplateOutlet]="cuerpoPrincipal"></ng-container>

<div *ngIf="datosDialogRecibidos?.esDialog">
    <div globalDialogFlotante>
        <ng-container titulo>
            Listado de Productos :: <small> Seleccione </small>
        </ng-container>
        <div cuerpo class="w-full flex flex-col items-center sm:gap-2">
            <div class="flex-1 w-full flex flex-col sm:flex-row items-center flex-wrap sm:gap-2">
                <ng-container *ngTemplateOutlet="cuerpoPrincipal; context:{}"></ng-container>
            </div>
        </div>
        <ng-container pieDerecha>
            <button matRipple class="boton-dialog-flotante boton-dialog-flotante-indigo" type="submit"
                [disabled]="!seleccionados.productos"
                (click)="enviarSeleccion()"
            >
                <mat-icon class="icon-xs" svgIcon="twotoneAdd"></mat-icon>
                <span>AÑADIR SELECCIONADO</span>
            </button>
            <button matRipple mat-dialog-close type="button" class="boton-dialog-flotante boton-dialog-flotante-gray">
                <mat-icon class="icon-xs" svgIcon="icCancel"></mat-icon>
                <span>CANCELAR</span>
            </button>
        </ng-container>
    </div>

</div>

<ng-template #cuerpoPrincipal>
    <!--	<jvs-filtro-busqueda [opciones]="opcionesFiltro" [asignarValores]="datosDialogRecibidos?.filtroBusquedaDefault" (resultados)="cargarLista($event)" prefijoConsulta="log"></jvs-filtro-busqueda>-->

    <general-tabla-mantenimiento-nuevo
        class="flex-1 w-full"
        [opciones]="opcionesFiltro"
        [asignarValores]="datosDialogRecibidos?.filtroBusquedaDefault"
        (resultados)="filtroResultados($event)"


        [objThis]="this"
        nombreColeccion="productos"
        [dataSuscription]="dataPrincipal$"
        [botonesCRUD]="(datosDialogRecibidos?.esDialog ? '' : 'CUD')"
        [botonesMenu]="botonesMantenimiento.productos"
        [filtroCampos]="true"
        [columnasTabla]="columnasProductos"
        (opcionSelecionada)="opcMenu($event)"
        (accionRecargar)="filtroResultados($event)"
    >

    </general-tabla-mantenimiento-nuevo>
    <!--
        <general-tabla-mantenimiento-nuevo
            [opciones]="opcionesFiltro" [asignarValores]="datosDialogRecibidos?.filtroBusquedaDefault" prefijoConsulta="log"
            (resultados)="filtroResultados($event)"

            [objThis]="this"
            nombreColeccion="productos"
            [data]="dataServidor.log.productos"
            [botonesCRUD]="(datosDialogRecibidos?.esDialog ? '' : 'CUD')"
            [filtroCampos]="true"
            [columnasTabla]="columnasProductos"
            (opcionSelecionada)="opcMenu($event)"
        >

        </general-tabla-mantenimiento-nuevo>
        -->
</ng-template>


<!-- TEMPLATES -->

<ng-template #dlgTributos>
    <div globalDialogFlotante btnCerrar>
        <ng-container titulo>Tributos</ng-container>
        <div cuerpo class="flex flex-col">

            <general-tabla-mantenimiento-nuevo
                [objThis]="this"
                ctrlBusqueda="simple"
                [dataSuscription]="dataTributos$"
                nombreColeccion="productos_tributos"
                [idTabla]="['iProdTribId']"
                [columnasTabla]="columnasTributos"
                (opcionSelecionada)="opcMenu($event)"
                (accionRecargar)="cargarProductosTibutos(frmFiltro.get('iAmbTribId').value)"
            >

                <ng-container botonesFiltro [formGroup]="frmFiltro">

                    <select formControlName="iAmbTribId" class="border-primary border-solid border rounded mr-1">
                        <option *ngFor="let item of dataServidor.acc.ambitos_tributos"
                            [value]="item.iAmbTribId">{{ item.cAmbTribNombre }}</option>
                    </select>
                </ng-container>
            </general-tabla-mantenimiento-nuevo>

        </div>
    </div>
</ng-template>

<!--TEMPLATES-->
<ng-template #dlgRegistroProductosTributos>
    <form globalDialogFlotante btnCerrar btnGuardar [matDialogRefActual]="dialogRef[1]"
        (ngSubmit)="opcMenu({item: undefined ,seccion: 'productos_tributos', tipo: 'guardar' })"
        [formGroup]="frmRegistroTributos"
    >
        <ng-container titulo>
            {{ (frmRegistroTributos.get('iProdTribId').value ? 'Editar' : 'Nuevo') }} ::<small>Tributos</small>
        </ng-container>

        <div cuerpo class="flex flex-col gap-0.5">
            <mat-form-field *ngIf="!frmRegistroTributos.get('iProdTribId').value">
                <mat-label>Tributo</mat-label>
                <mat-select formControlName="iTribId">
                    <ng-container *ngFor="let item of dataServidor.acc.tributos">
                        <mat-option [value]="item.iTribId">{{ item.cTribNombre }}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-error>{{ mensajesErrorFormControl(frmRegistroTributos.get('iTribId')) }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="frmRegistroTributos.get('iProdTribId').value">
                <mat-label>Orden</mat-label>
                <input type="number" formControlName="iProdTribOrden" matInput autocomplete="off">
                <mat-error>{{ mensajesErrorFormControl(frmRegistroTributos.get('iProdTribOrden')) }}</mat-error>
            </mat-form-field>
        </div>
    </form>
</ng-template>
