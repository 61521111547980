import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JVSoftModule} from '@JVSoft/j-v-soft.module';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {UsuarioService} from '@servicios/usuario.service';
import {EntidadService} from '@servicios/entidad.service';
import {ServidorService} from '@servicios/servidor.service';
import {stagger40ms} from '@vex/animations/stagger.animation';

@Component({
    selector: 'general-seleccionar-entidad',
    standalone: true,
    imports: [CommonModule, JVSoftModule, MatIconModule, MatRippleModule],
    templateUrl: './seleccionar-entidad.component.html',
    styleUrls: ['./seleccionar-entidad.component.scss'],
    animations: [
        stagger40ms
    ],
})
export class SeleccionarEntidadComponent {

    get entidadesUsuario () {
        return this.usuarioService._entidades.getValue();
    }
    constructor(
        private usuarioService: UsuarioService,
        private entidadService: EntidadService,
        public servidorService: ServidorService,
    ) {

    }

    seleccionarEntidad(iEntId) {
        this.entidadService.setEntidadActualById(iEntId).then(() => {
            window.location.reload();
        });
    }

}
