import {UntilDestroy} from '@ngneat/until-destroy';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {QueryService} from '@servicios/query.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})

export class DespachoService {

    _despachos = new BehaviorSubject<any>([]);
    despachos$ = this._despachos.asObservable();

    _despachoActual = new BehaviorSubject(null);
    despachoActual$ = this._despachoActual.asObservable();

    get tienePermisoDeEdicion() {
        const cantDesp = this._despachos.getValue().find(itm => itm.bDespachoEditar == 1)
        return cantDesp;
    }
    get despachoActualPuedeEditar(): boolean {
        return !!this.despachoActual && this.despachoActual.bDespachoEditar == 1;
    }

    get despachoActualEsMesaVirtual(): boolean {
        return !!this.despachoActual && this.despachoActual.iTipoDespachoId == 3;
    }

    get despachoActual() {
        return this._despachoActual.getValue() ?? null;
    }
    get despachoActualId() {
        if (this.despachoActual) {
            return this.despachoActual['iDespachoId'];
        }
        return null
    }


    constructor(
        private queryService: QueryService,
    ) {
    }
    setDespachoActualById(iDespachoId: number, guardar = true) {
        const depSel = this._despachos.getValue().find(d => d.iDespachoId == iDespachoId);
        if (this._despachoActual.getValue() != depSel) {
            this._despachoActual.next(depSel);
        }
        if (guardar && depSel) {
            this.queryService.setDataSession({iDespachoId: depSel.iDespachoId}).then();
        }
    }
}
