import fileTypeExcel from '@iconify-icons/vscode-icons/file-type-excel';
import fileTypePdf from '@iconify-icons/vscode-icons/file-type-pdf2';

/** @deprecated Variable restringida */
const reemplazos = {
}
export const iconsVSCode = {
    fileTypeExcel,
    fileTypePdf,
};
