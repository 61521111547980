import {
    groupBy,
    nestGroupsBy,
    ordenarArray,
    ordenarPorPropiedad,
    sumarObjetos
} from '@JVSoft/services/funciones-globales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {generarColSpan, imprimirCelda} from '@JVSoft/services/pdf-make.global.service';
import {DataEnviarPdfPresupuesto, PdfPresupuestoService} from '@pdf/pdf-presupuesto.service';


export function ECVSFGAnoejeActProySecFuncFuenteFinancCOSTO(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {

	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ_COSTO'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_Financ_COSTO'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Act_Proy');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTO ', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Actividad Proyecto, Secuencia Funcional, Fuente Financ. y Clasificador.', null, {fontSize: 7, bold: true}),
				objThis.imprimirCelda('EJERCICIO DEL ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 12, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Act_proy',
			'cTipCostSigla',
			'Fuente_Financ',
			'Sec_func',
		]);
		console.log(groups);
		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [true, false, false, false],
					});
				}},
			{label: 'ACT.PROY', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'T.C', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'FTE.', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'META', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'FINAL.', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, true, false],
					});
				}},
			{label: 'CLASIF.', property: 'Clasificador', type: 'text'},
			{label: 'NOMBRE', property: 'Nombre_Clasificador', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: resultadosGenerales[0]['cNombre_Fase'] ?? '[FASE]', property: 'nEjecutado', type: 'money'},
			{label: 'SALDO PIM', property: '', type: 'money', transformar(row): any {
					return row.nPim - row.nCertificado;
				}},
			{label: 'SALDO CERTIFICADO.', property: '', type: 'money', transformar(row): any {
					return row.nCertificado - row.nEjecutado;
				}},
		];

		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nEjecutado',

		]);


		const agrupadoActProy = groupBy(resultadosGenerales, (obj) => {
			return obj.Act_proy;
		});

		console.log(agrupadoActProy);
		let dataActProy = {
			Ano_eje: '',
			Act_proy: '',
			cNombre_Act_proy: '',
			nPim: 0,
			nCertificado: 0,
			nEjecutado: 0,
		};


		let pag = 0;
		let nuevoCuerpo = [];


		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoActProy).forEach((secActProy) => {
			dataActProy = {
				...{
					Ano_eje: agrupadoActProy[secActProy][0]['Ano_eje'],
					Act_proy: agrupadoActProy[secActProy][0]['Act_proy'],
					cNombre_Act_proy: agrupadoActProy[secActProy][0]['cNombre_Act_proy'],
				},
				...sumarObjetos(agrupadoActProy[secActProy], [
					'nPim',
					'nCertificado',
					'nEjecutado',
				]),
			};

			const agrupadocTipCostSigla = groupBy(agrupadoActProy[secActProy], (obj) => {
				return obj.cTipCostSigla;
			});

			let datacTipCostSigla = {
				cTipCostSigla: '',
				cTipCostNombre: '',
				nPim: 0,
				nCertificado: 0,
				nEjecutado: 0,
			};

			let pag2 = 0;
			Object.keys(agrupadocTipCostSigla).forEach((seccTipCostSigla) => {
				datacTipCostSigla = {
					...{
						cTipCostSigla: agrupadocTipCostSigla[seccTipCostSigla][0]['cTipCostSigla'],
						cTipCostNombre: agrupadocTipCostSigla[seccTipCostSigla][0]['cTipCostNombre'],
					},
					...sumarObjetos(agrupadocTipCostSigla[seccTipCostSigla], [
						'nPim',
						'nCertificado',
						'nEjecutado',
					]),
				};

				if (pag2 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([
						[
							imprimirCelda(dataActProy.Ano_eje, null, {
								bold: true,
								style: 'tdSmall', color: '#b02930',
								alignment: 'center',
							}),
							imprimirCelda(dataActProy.Act_proy, null, {
								bold: true,
								style: 'tdSmall', color: '#b02930',
								alignment: 'center',
								// rowSpan: tablaGenerada['cuerpo'].length + 1,
							}),
						].concat(
							generarColSpan(imprimirCelda(dataActProy.cNombre_Act_proy, null, {
								bold: true,
								style: 'tdSmall', color: '#b02930',
								alignment: 'left'
							}), 6).concat(
								imprimirCelda(dataActProy.nPim, 'money', {
									bold: true,
									style: 'tdSmall', color: '#b02930',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nCertificado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#b02930',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nEjecutado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#b02930',
									alignment: 'right'
								}),
								imprimirCelda((dataActProy.nPim - dataActProy.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#b02930', alignment: 'right'}),
								imprimirCelda((dataActProy.nCertificado - dataActProy.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#b02930', alignment: 'right'}),
							),
						)
					]);
				}

				// agrupadoSecfunc[secSecfunc] = ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Fuente_Financ', true)
				const agrupadoFuenteFinanc = groupBy(agrupadocTipCostSigla[seccTipCostSigla], (obj) => {
					return obj.Fuente_Financ;
				});
				let dataFuenteFinanc = {
					Fuente_Financ: '',
					Nombre_Fuente_Financ: '',
					nPim: 0,
					nCertificado: 0,
					nEjecutado: 0,
				};

				let pag3 = 0;
				ordenarArray(Object.keys(agrupadoFuenteFinanc), true).forEach((secFuenteFinanc) => {
					dataFuenteFinanc = {
						...{
							Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_Financ'],
							Nombre_Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_Fuente_Financ'],
						},
						...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
							'nPim',
							'nCertificado',
							'nEjecutado',
						]),
					};

					if (pag3 == 0) {
						nuevoCuerpo = nuevoCuerpo.concat([
								generarColSpan(imprimirCelda('', null, {
									bold: true,
									style: 'tdSmall', color: '#ff781e',
									alignment: 'left'
								}), 2).concat(
									imprimirCelda(datacTipCostSigla.cTipCostSigla, 'texto', {
										bold: true,
										style: 'tdSmall', color: '#ff781e',
										alignment: 'center',
										// rowSpan: tablaGenerada['cuerpo'].length + 1,
									}),
									generarColSpan(imprimirCelda(datacTipCostSigla.cTipCostNombre, 'texto', {
										bold: true,
										style: 'tdSmall', color: '#ff781e',
										alignment: 'left'
									}), 5),
									imprimirCelda(datacTipCostSigla.nPim, 'money', {
										bold: true,
										style: 'tdSmall', color: '#ff781e',
										alignment: 'right'
									}),
									imprimirCelda(datacTipCostSigla.nCertificado, 'money', {
										bold: true,
										style: 'tdSmall', color: '#ff781e',
										alignment: 'right'
									}),
									imprimirCelda(datacTipCostSigla.nEjecutado, 'money', {
										bold: true,
										style: 'tdSmall', color: '#ff781e',
										alignment: 'right'
									}),
									imprimirCelda((datacTipCostSigla.nPim - datacTipCostSigla.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#ff781e', alignment: 'right'}),
									imprimirCelda((datacTipCostSigla.nCertificado - datacTipCostSigla.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#ff781e', alignment: 'right'}),
								),
							// )
						]);
					}
					const agrupadoSecfunc = groupBy(agrupadoFuenteFinanc[secFuenteFinanc], (obj) => {
						return obj.Sec_func;
					});
					let dataSecfunc = {
						Sec_func: '',
						Finalidad: '',
						cNombre_Finalidad: '',
						nPim: 0,
						nCertificado: 0,
						nEjecutado: 0,
					};

					let pag4 = 0;
					ordenarArray(Object.keys(agrupadoSecfunc), true).forEach((secSecfunc) => {
						dataSecfunc = {
							...{
								Sec_func: agrupadoSecfunc[secSecfunc][0]['Sec_func'],
								Finalidad: agrupadoSecfunc[secSecfunc][0]['Finalidad'],
								cNombre_Finalidad: agrupadoSecfunc[secSecfunc][0]['cNombre_Finalidad'],
							},
							...sumarObjetos(agrupadoSecfunc[secSecfunc], [
								'nPim',
								'nCertificado',
								'nEjecutado',
							]),
						};

						const tablaGenerada = objThis.generarDesdeMantenimiento({
							titulos: camposPrincipal,
							contenido: ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Sec_func'),
							// numeracion: true,
							idxResto: [6],
							margin: [0, 0, 0, 5],
							separado: true,
						});


						if (pag4 == 0) {
							nuevoCuerpo = nuevoCuerpo.concat([
									generarColSpan(imprimirCelda('', null, {
										bold: true,
										style: 'tdSmall', color: '#0f5b18',
										alignment: 'left'
									}), 3).concat(
										imprimirCelda(dataFuenteFinanc.Fuente_Financ, null, {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'center',
											// rowSpan: tablaGenerada['cuerpo'].length + 1,
										}),
										generarColSpan(imprimirCelda(dataFuenteFinanc.Nombre_Fuente_Financ, null, {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'left'
										}), 4),
										imprimirCelda(dataFuenteFinanc.nPim, 'money', {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right'
										}),
										imprimirCelda(dataFuenteFinanc.nCertificado, 'money', {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right'
										}),
										imprimirCelda(dataFuenteFinanc.nEjecutado, 'money', {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right'
										}),
										imprimirCelda((dataFuenteFinanc.nPim - dataFuenteFinanc.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#0f5b18', alignment: 'right'}),
										imprimirCelda((dataFuenteFinanc.nCertificado - dataFuenteFinanc.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#0f5b18', alignment: 'right'}),
									),
								// )
							]);
						}

						nuevoCuerpo = nuevoCuerpo.concat([

							generarColSpan(imprimirCelda('', null, {
								bold: true,
								style: 'tdSmall', color: '#1c28b0',
								alignment: 'left'
							}), 4).concat(
								// ].concat(
								imprimirCelda( dataSecfunc.Sec_func, null, {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'center'
								}),
								imprimirCelda( dataSecfunc.Finalidad, null, {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'center'
								}),
								generarColSpan(imprimirCelda(dataSecfunc.cNombre_Finalidad, null, {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'left'
								}), 2),
								imprimirCelda(dataSecfunc.nPim, 'money', {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.nCertificado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.nEjecutado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'right'
								}),
								imprimirCelda((dataSecfunc.nPim - dataSecfunc.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#1c28b0', alignment: 'right'}),
								imprimirCelda((dataSecfunc.nCertificado - dataSecfunc.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#1c28b0', alignment: 'right'}),
							),


						]);

						nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
						pag4++;

					});
					pag3++;
				});
				pag2++;
			});
			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				style: 'thSmall',
				alignment: 'right'
			}), 8).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nEjecutado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda((sumasTotales.nPim - sumasTotales.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda((sumasTotales.nCertificado - sumasTotales.nEjecutado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 30}, {idx: 1, valor: 30}, {idx: 2, valor: 30} ]),
				body: nuevoCuerpo,
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}

export function ECVSFGAnoejeSecFuncFuenteFinancCOSTO(objThis: PdfPresupuestoService, dataEnviar: DataEnviarPdfPresupuesto) {
	objThis.queryService.cargar(objThis, ['siaf.ECVSFG_Ano_eje_Sec_Func_Fuente_Financ_COSTO'], dataEnviar).then((valRetorno) => {
		console.log(valRetorno);
		let resultadosGenerales = valRetorno['siaf.ECVSFG_Ano_eje_Sec_Func_Fuente_Financ_COSTO'];
		resultadosGenerales = ordenarPorPropiedad(resultadosGenerales, 'Act_Proy');

		objThis.reiniciarOpciones({
			margenesHoja: [40, 100, 40, 40],
			orientacion: 'landscape',
			customDefTableHeader: objThis.headerTableEntidad([
				objThis.imprimirCelda('REPORTE DE EJECUCIÓN PRESUPUESTAL VS FASES DE GASTO ', null, {
					fontSize: 13,
					bold: true
				}),
				objThis.imprimirCelda('Actividad Proyecto, Secuencia Funcional, Fuente Financ. y Clasificador.', null, {fontSize: 8, bold: true}),
				objThis.imprimirCelda('EJERCICIO DEL ' + resultadosGenerales[0]['Ano_eje'], null, {fontSize: 11, bold: true}),
				objThis.imprimirCelda('(EN NUEVOS SOLES)', null, {fontSize: 7, bold: true}),
			]),
		});

		const groups = nestGroupsBy(resultadosGenerales, [
			'Act_proy',
			'cTipCostSigla',
			'Fuente_Financ',
			'Sec_func',
		]);
		console.log(groups);
		const cuerpoPdf = [];
		const camposPrincipal: ColumnaTabla<any>[] = [
			{label: 'AÑO', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [true, false, false, false],
					});
				}},
			{label: 'ACT.PROY', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'T.C', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'FTE.', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'META', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, false, false],
					});
				}},
			{label: 'FINAL.', property: '', type: 'text', transformarDirecto(row): any {
					return imprimirCelda(' ', null ,{
						border: [false, false, true, false],
					});
				}},
			{label: 'CLASIF.', property: 'Clasificador', type: 'text'},
			{label: 'NOMBRE', property: 'Nombre_Clasificador', type: 'text'},
			{label: 'PIM', property: 'nPim', type: 'money'},
			{label: 'CERTIFICADO', property: 'nCertificado', type: 'money'},
			{label: resultadosGenerales[0]['cNombre_Fase'] ?? '[FASE]', property: 'nEjecutado', type: 'money'},
			{label: 'SALDO PIM', property: '', type: 'money', transformar(row): any {
				return row.nPim - row.nCertificado;
				}},
			{label: 'SALDO CERTIFICADO.', property: '', type: 'money', transformar(row): any {
					return row.nCertificado - row.nEjecutado;
				}},
		];
//{Sp_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_FinancXcCodigoCadena;1.nPim}-{Sp_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_FinancXcCodigoCadena;1.nCertificado}
		//{Sp_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_FinancXcCodigoCadena;1.nCertificado}-{Sp_Siaf_SEL_ECVSFG_Ano_eje_Act_Proy_Sec_Func_Fuente_FinancXcCodigoCadena;1.nEjecutado}
		const sumasTotales = sumarObjetos(resultadosGenerales, [
			'nPim',
			'nCertificado',
			'nEjecutado',

		]);

		const agrupadoActProy = groupBy(resultadosGenerales, (obj) => {
			return obj.Act_proy;
		});

		console.log(agrupadoActProy);
		let dataActProy = {
			Ano_eje: '',
			Act_proy: '',
			cNombre_Act_proy: '',
			nPim: 0,
			nCertificado: 0,
			nEjecutado: 0,
		};


		let pag = 0;
		let nuevoCuerpo = [];


		const tablaGeneradaSoloTitulos = objThis.generarDesdeMantenimiento({
			titulos: camposPrincipal,
			contenido: [],
			// numeracion: true,
			idxResto: [6],
			margin: [0, 0, 0, 5],
			separado: true,
		});
		if (nuevoCuerpo.length == 0) {
			nuevoCuerpo = nuevoCuerpo.concat(tablaGeneradaSoloTitulos['titulos']);
		}

		Object.keys(agrupadoActProy).forEach((secActProy) => {
			dataActProy = {
				...{
					Ano_eje: agrupadoActProy[secActProy][0]['Ano_eje'],
					Act_proy: agrupadoActProy[secActProy][0]['Act_proy'],
					cNombre_Act_proy: agrupadoActProy[secActProy][0]['cNombre_Act_proy'],
				},
				...sumarObjetos(agrupadoActProy[secActProy], [
					'nPim',
					'nCertificado',
					'nEjecutado',
				]),
			};

			const agrupadocTipCostSigla = groupBy(agrupadoActProy[secActProy], (obj) => {
				return obj.cTipCostSigla;
			});

			let datacTipCostSigla = {
				cTipCostSigla: '',
				cTipCostNombre: '',
				nPim: 0,
				nCertificado: 0,
				nEjecutado: 0,
			};

			let pag2 = 0;
			Object.keys(agrupadocTipCostSigla).forEach((seccTipCostSigla) => {
				datacTipCostSigla = {
					...{
						cTipCostSigla: agrupadocTipCostSigla[seccTipCostSigla][0]['cTipCostSigla'],
						cTipCostNombre: agrupadocTipCostSigla[seccTipCostSigla][0]['cTipCostNombre'],
					},
					...sumarObjetos(agrupadocTipCostSigla[seccTipCostSigla], [
						'nPim',
						'nCertificado',
						'nEjecutado',
					]),
				};

				if (pag2 == 0) {
					nuevoCuerpo = nuevoCuerpo.concat([
						[
							imprimirCelda(dataActProy.Ano_eje, null, {
								bold: true,
								style: 'tdSmall', color: '#b01113',
								alignment: 'center',
							}),
							imprimirCelda(dataActProy.Act_proy, null, {
								bold: true,
								style: 'tdSmall', color: '#b01113',
								alignment: 'left',
								// rowSpan: tablaGenerada['cuerpo'].length + 1,
							}),
						].concat(
							generarColSpan(imprimirCelda(dataActProy.cNombre_Act_proy, null, {
								bold: true,
								style: 'tdSmall', color: '#b01113',
								alignment: 'left'
							}), 6).concat(
								imprimirCelda(dataActProy.nPim, 'money', {
									bold: true,
									style: 'tdSmall', color: '#b01113',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nCertificado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#b01113',
									alignment: 'right'
								}),
								imprimirCelda(dataActProy.nEjecutado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#b01113',
									alignment: 'right'
								}),
								imprimirCelda((dataActProy.nPim - dataActProy.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#b01113', alignment: 'right'}),
								imprimirCelda((dataActProy.nCertificado - dataActProy.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#b01113', alignment: 'right'}),
							),
						)
					]);
				}

				const agrupadoFuenteFinanc = groupBy(agrupadocTipCostSigla[seccTipCostSigla], (obj) => {
					return obj.Fuente_Financ;
				});
				let dataFuenteFinanc: any = {};

				let pag3 = 0;
				ordenarArray(Object.keys(agrupadoFuenteFinanc), true).forEach((secFuenteFinanc) => {
					dataFuenteFinanc = {
						...{
							Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Fuente_Financ'],
							Nombre_Fuente_Financ: agrupadoFuenteFinanc[secFuenteFinanc][0]['Nombre_Fuente_Financ'],
						},
						...sumarObjetos(agrupadoFuenteFinanc[secFuenteFinanc], [
							'nPim',
							'nCertificado',
							'nEjecutado',
						]),
					};

					if (pag3 == 0) {
						nuevoCuerpo = nuevoCuerpo.concat([
								generarColSpan(imprimirCelda('', null, {
									bold: true,
									style: 'tdSmall', color: '#ff8d13',
									alignment: 'left'
								}), 2).concat(
									imprimirCelda(datacTipCostSigla.cTipCostSigla, null, {
										bold: true,
										style: 'tdSmall', color: '#ff8d13',
										alignment: 'left',
										// rowSpan: tablaGenerada['cuerpo'].length + 1,
									}),
									generarColSpan(imprimirCelda(datacTipCostSigla.cTipCostNombre, null, {
										bold: true,
										style: 'tdSmall', color: '#ff8d13',
										alignment: 'left'
									}), 5),
									imprimirCelda(datacTipCostSigla.nPim, 'money', {
										bold: true,
										style: 'tdSmall', color: '#ff8d13',
										alignment: 'right'
									}),
									imprimirCelda(datacTipCostSigla.nCertificado, 'money', {
										bold: true,
										style: 'tdSmall', color: '#ff8d13',
										alignment: 'right'
									}),
									imprimirCelda(datacTipCostSigla.nEjecutado, 'money', {
										bold: true,
										style: 'tdSmall', color: '#ff8d13',
										alignment: 'right'
									}),
									imprimirCelda((datacTipCostSigla.nPim - datacTipCostSigla.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#ff8d13', alignment: 'right'}),
									imprimirCelda((datacTipCostSigla.nCertificado - datacTipCostSigla.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#ff8d13', alignment: 'right'}),
								),
							// )
						]);
					}
					const agrupadoSecfunc = groupBy(agrupadoFuenteFinanc[secFuenteFinanc], (obj) => {
						return obj.Sec_func;
					});
					let dataSecfunc = {
						Sec_func: '',
						Finalidad: '',
						cNombre_Finalidad: '',
						nPim: 0,
						nCertificado: 0,
						nEjecutado: 0,
					};

					let pag4 = 0;
					ordenarArray(Object.keys(agrupadoSecfunc), true).forEach((secSecfunc) => {
						dataSecfunc = {
							...{
								Sec_func: agrupadoSecfunc[secSecfunc][0]['Sec_func'],
								Finalidad: agrupadoSecfunc[secSecfunc][0]['Finalidad'],
								cNombre_Finalidad: agrupadoSecfunc[secSecfunc][0]['cNombre_Finalidad'],
							},
							...sumarObjetos(agrupadoSecfunc[secSecfunc], [
								'nPim',
								'nCertificado',
								'nEjecutado',
							]),
						};

						const tablaGenerada = objThis.generarDesdeMantenimiento({
							titulos: camposPrincipal,
							contenido: ordenarPorPropiedad(agrupadoSecfunc[secSecfunc], 'Sec_func'),
							// numeracion: true,
							idxResto: [6],
							margin: [0, 0, 0, 5],
							separado: true,
						});


						if (pag4 == 0) {
							nuevoCuerpo = nuevoCuerpo.concat([

									generarColSpan(imprimirCelda('', null, {
										bold: true,
										style: 'tdSmall', color: '#0f5b18',
										alignment: 'left'
									}), 3).concat(
										imprimirCelda(dataFuenteFinanc.Fuente_Financ, null, {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right',
											// rowSpan: tablaGenerada['cuerpo'].length + 1,
										}),
										generarColSpan(imprimirCelda(dataFuenteFinanc.Nombre_Fuente_Financ, null, {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'left'
										}), 4),
										imprimirCelda(dataFuenteFinanc.nPim, 'money', {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right'
										}),
										imprimirCelda(dataFuenteFinanc.nCertificado, 'money', {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right'
										}),
										imprimirCelda(dataFuenteFinanc.nEjecutado, 'money', {
											bold: true,
											style: 'tdSmall', color: '#0f5b18',
											alignment: 'right'
										}),
										imprimirCelda((dataFuenteFinanc.nPim - dataFuenteFinanc.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#0f5b18', alignment: 'right'}),
										imprimirCelda((dataFuenteFinanc.nCertificado - dataFuenteFinanc.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#0f5b18', alignment: 'right'}),
									),
								// )
							]);
						}

						nuevoCuerpo = nuevoCuerpo.concat([

							generarColSpan(imprimirCelda('', null, {
								bold: true,
								style: 'tdSmall', color: '#1c28b0',
								alignment: 'left'
							}), 4).concat(
								// ].concat(
								imprimirCelda( dataSecfunc.Sec_func, null, {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'left'
								}),
								imprimirCelda( dataSecfunc.Finalidad, null, {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'left'
								}),
								generarColSpan(imprimirCelda(dataSecfunc.cNombre_Finalidad, null, {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'left'
								}), 2),
								imprimirCelda(dataSecfunc.nPim, 'money', {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.nCertificado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'right'
								}),
								imprimirCelda(dataSecfunc.nEjecutado, 'money', {
									bold: true,
									style: 'tdSmall', color: '#1c28b0',
									alignment: 'right'
								}),
								imprimirCelda((dataSecfunc.nPim - dataSecfunc.nCertificado), 'money', {bold: true, style: 'tdSmall', color: '#1c28b0', alignment: 'right'}),
								imprimirCelda((dataSecfunc.nCertificado - dataSecfunc.nEjecutado), 'money', {bold: true, style: 'tdSmall', color: '#1c28b0', alignment: 'right'}),
							),


						]);

						nuevoCuerpo = nuevoCuerpo.concat(tablaGenerada['cuerpo']);
						pag4++;

					});
					pag3++;
				});
				pag2++;
			});
			pag++;
		});

		nuevoCuerpo = nuevoCuerpo.concat([
			generarColSpan(imprimirCelda('TOTAL S/.', null, {
				bold: true,
				style: 'thSmall',
				alignment: 'right'
			}), 8).concat(
				imprimirCelda(sumasTotales.nPim, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nCertificado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda(sumasTotales.nEjecutado, 'money', {
					bold: true,
					style: 'thSmall',
					alignment: 'right'
				}),
				imprimirCelda((sumasTotales.nPim - sumasTotales.nCertificado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
				imprimirCelda((sumasTotales.nCertificado - sumasTotales.nEjecutado), 'money', {bold: true, style: 'thSmall', alignment: 'right'}),
			),
		]);

		cuerpoPdf.push({
			margin: [0, 0, 0, 0],
			table: {
				dontBreakRows: true,
				headerRows: 1,
				// headerRows: 4,
				widths: objThis.anchoCols([Object.keys(camposPrincipal)], [3], [{idx: 0, valor: 30}, {idx: 1, valor: 30}, {idx: 2, valor: 30}, ]),
				body: nuevoCuerpo,
			}
		});

		objThis.finalizarCreacionPdf(cuerpoPdf);
	});
}
