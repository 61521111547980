import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class FormDataInterceptor implements HttpInterceptor {

	solicitandoNotificacion = false;

	constructor(
	) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // this.startLoading();
        if (req.method === 'POST' || req.method === 'PUT') {
            this.shiftDates(req.body);
        }
        return next.handle(req);
    }

    shiftDates(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (value instanceof Date) {
                if (/d[a-zA-Z]+/.test(key)) {
                    body[key] = moment(value).format('YYYY-MM-DD');
                }
                else {
                    body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
                        , value.getSeconds()));
               }
            }
            else if (/dt[a-zA-Z]+/.test(key)) {
                if (/[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}/.test(value)) {
                    body[key] = new Date(value + 'Z');
                }
                else if (/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(value) || /[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}/.test(value)) {
                    body[key] = new Date(value + ':00.000Z');
                }
                else {
                    console.warn('FECHA INVALIDA AL ENVIAR (FormInter) ', key, value);
                }
            }
            else if (typeof value === 'object') {
                this.shiftDates(value);
            }
        }
    }
}
