import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarNotificationsComponent} from './toolbar-notifications.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatRippleModule} from '@angular/material/core';
import {ToolbarNotificationsDropdownComponent} from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import {PopoverModule} from "@vex/components/popover/popover.module";
import {MatBadgeModule} from "@angular/material/badge";
import {RelativeDateTimeModule} from '@vexMod/pipes/relative-date-time/relative-date-time.module';
import {MatTableModule} from '@angular/material/table';


@NgModule({
  declarations: [ToolbarNotificationsComponent, ToolbarNotificationsDropdownComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        PopoverModule,
        MatTabsModule,
        MatMenuModule,
        RelativeDateTimeModule,
        RouterModule,
        MatRippleModule,
        MatBadgeModule,
        MatTableModule,
    ],
  exports: [ToolbarNotificationsComponent]
})
export class ToolbarNotificationsModule {
}
