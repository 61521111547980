import {Component} from '@angular/core';
import {ProgressSpinnerConfig, ProgressSpinnerService} from '@JVSoft/components/progress-spinner/progress-spinner.service';

@Component({
  selector: 'general-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent {
  get configProgressSpiiner(): ProgressSpinnerConfig {
    return this.progresSpinnerService._config.getValue();
  }
  constructor(
      public progresSpinnerService: ProgressSpinnerService,
  ) {
  }
}
