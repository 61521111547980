import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BotonMantenimiento} from '@JVSoft/interfaces/global';
import {MatMenu} from '@angular/material/menu';
import {FuncionesGlobalesService} from '@JVSoft/services/funciones-globales.service';

@Component({
  selector: 'general-tabla-mantenimiento-nuevo-menu',
  templateUrl: './tabla-mantenimiento-nuevo-menu.component.html',
  styleUrls: ['./tabla-mantenimiento-nuevo-menu.component.scss']
})
export class TablaMantenimientoNuevoMenuComponent implements OnInit {
  @Input() objThis;
  @Input() nombreColeccion;
  @Input() item;
  @Input() derechosActuales;
  @Input() modalPrincipal;
  @Input() botonDisabled = (btn: BotonMantenimiento, item) => [];

  @Output() opcionSelecionada = new EventEmitter();

  @ViewChild('menu', {static: true}) public menu: MatMenu;
  @Input() subItems: BotonMantenimiento[];

  @Input() botonTemplate: TemplateRef<any>;

  constructor(
      public funcionesGlobalesService: FuncionesGlobalesService,
  ) {
  }

  ngOnInit(): void {
  }

  opcMenu(item, objMenu, modal = null, retorno = false) {
    const opAdic = {
      seccion: this.nombreColeccion,
      modal: (objMenu.modal ? objMenu.modal : modal),
      item,
    };

    objMenu = {...objMenu, ...opAdic};
    if (retorno) {
      return objMenu;
    }
    this.opcionSelecionada.emit(objMenu);
  }

}
