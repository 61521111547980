import {Component, EventEmitter, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {QueryService} from '../../../services/query.service';
import {fadeInUp400ms} from '../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../@vex/animations/stagger.animation';
import {FuncionesGeneralesService} from '../../services/funciones-generales.service';
import {ColumnaTabla} from '@JVSoft/interfaces/global';
import {objectPropertiesBoolean} from '@JVSoft/services/funciones-globales.service';

@Component({
  selector: 'general-persona-contactos',
  templateUrl: './persona-contactos.component.html',
  styleUrls: ['./persona-contactos.component.scss'],
	animations: [
		fadeInUp400ms,
		stagger40ms
	],
})
export class PersonaContactosComponent  implements OnInit {
	persona;

	dataServidor = {
		grl: {
			personas_contactos: null,
			tipos_contactos: null,
		}
	};
	seleccionados = {
		personas_contactos: null,
	};

	dialogRef: MatDialogRef<any>;
	frmRegistro: FormGroup;

	columns: ColumnaTabla<any>[] = [
		// { label: 'Image', property: 'image', type: 'image', visible: true },

		// { label: 'Sexo', property: 'cPersSexo', type: 'icon', visible: true, opcSwitchIcon: { casos: [{case: 'M', icon: 'fa5sMale', cssClasses: ['text-cyan']}, {case: 'F', icon: 'fa5sFemale', cssClasses: ['text-pink']} ], default: null } },
		{ label: 'Tipo', property: 'cTipoConNombre', type: 'text', visible: true },
		{ label: 'Detalle', property: 'cPersConNombre', type: 'text', visible: true, cssClasses: ['font-bold'] },
		{ label: 'Principal', property: 'bPersConPrincipal', type: 'icon', visible: true, cssClasses: ['text-center'], opcSwitchIcon: {
			casos: [
				{case: '1', icon: 'fa5sCheckSquare'},
				{case: '0', icon: 'fa5sSquare'},
				],
				default: null
		}
		},

	];

	menuPersona;
    opcMenuEmitter = new EventEmitter();
	@ViewChild('dialogRegistro', {static: true}) dialogRegistroRef: TemplateRef<any>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public datosDialogRecibidos: any,
		private dialog: MatDialog,
		private queryService: QueryService,
		private fb: FormBuilder,

		public funcionesGeneralesService: FuncionesGeneralesService,
	) {
	}

	ngOnInit(): void {
		this.dataDialogInicial();
		this.menuPersona = {
			principal: [
				// {icono: 'contactCard20Regular', tipo: 'contactos'}
			],
		};

		this.cargaInicial();
		this.crearFormularios();
	}

	dataDialogInicial() {
		console.log(this.datosDialogRecibidos);
		if (this.datosDialogRecibidos) {
			if (this.datosDialogRecibidos.persona) {
				this.persona = this.datosDialogRecibidos.persona;
			}
		} else {
			this.datosDialogRecibidos = {};
		}
	}

	crearFormularios() {
		this.frmRegistro = this.fb.group({
			iPersId: [this.persona.iPersId],
			iTipoConId: [''],
			cPersConNombre: [''],
			bPersConPrincipal: [true],

			iPersConId: [''],
		});
	}

	cargaInicial() {
		this.queryService.cargar(this, ['grl.tipos_contactos']).then();
		this.cargarPersonaContactos();
	}

	cargarPersonaContactos(idTabla = false){
		if (idTabla) {
			return this.queryService.getDataMethod('GET', 'grl.personas_contactos', { iPersConId: idTabla }).toPromise();
		} else {
			this.queryService.cargar(this, ['grl.personas_contactos'], { iPersId: this.persona.iPersId }).then();
		}

	}

	opcMenu(v){
        let accionEncontrada = true;
		console.log(v);
		const dialogOpc: MatDialogConfig = {
			panelClass: 'dialogMantenimientoNuevo',
			disableClose: true,
			data: {}
		};
		switch (v.seccion) {
			case 'personas_contactos':
				// dialogOpc.data.tipos_personas = this.dataServidor.tipos_personas;
				switch (v.tipo) {
					case 'ver':
						if (this.seleccionados.personas_contactos == v.item) {
							this.seleccionados.personas_contactos = null;
						} else {
							this.seleccionados.personas_contactos = v.item;
						}
						break;
					case 'nuevo':
                        this.crearFormularios();
						// this.seleccionados.personas_contactos = null;
						// this.frmRegistro.reset({
						// 	iPersId: this.persona.iPersId,
						// });
						this.dialogRef = this.dialog.open(this.dialogRegistroRef, dialogOpc);
						break;
					case 'editar':
                        objectPropertiesBoolean(this.seleccionados.personas_contactos, 'boolean');
                        this.frmRegistro.patchValue(this.seleccionados.personas_contactos);

						// this.frmRegistro.patchValue({
						// 	...this.seleccionados.personas_contactos,
						// 	...{ bPersConPrincipal: (this.seleccionados.personas_contactos.bPersConPrincipal == 1) }
						// });
						this.dialogRef = this.dialog.open(this.dialogRegistroRef, dialogOpc);
						break;
					case 'cancelar':
						this.dialogRef.close();
						break;
					case 'guardar':
						const idTabla = 'iPersConId';
						this.queryService.enviarFormulario('mantenimiento#personas_contactos', this.frmRegistro, false, 'grl').then((dataGuardado) => {

							if (dataGuardado && dataGuardado['data'][idTabla]) {
								this.dialogRef.close();
								this.cargarPersonaContactos();
                                this.opcMenuEmitter.emit('');
								// this.funcionesGeneralesService.recargarItemTableDS(this, 'personas_contactos', 'cargarPersonaContactos', 'cargarPersonaContactos', dataGuardado, idTabla);
								/*
								const dataActual = this.dataServidor.personas_contactos.data;
								const idxEnDS = (this.seleccionados.personas_contactos) ? dataActual.findIndex(item => item[idTabla] == this.seleccionados.personas_contactos[idTabla]) : null;
								this.cargarPersonaContactos(dataGuardado['data'][idTabla]).then((dataPersGuardado) => {
									let dataActualizarEnDS;
									if (idxEnDS != null) {
										dataActualizarEnDS = {...this.seleccionados.personas_contactos, ...dataPersGuardado['personas_contactos'][0]};
										dataActual[idxEnDS] = dataActualizarEnDS;
										this.dataServidor.personas_contactos.data = dataActual;
										this.seleccionados.personas_contactos = dataActual[idxEnDS];
									} else {
										this.seleccionados.personas_contactos = null;
										this.cargarPersonaContactos();
									}
								});
								*/
							}
						});
						break;
					case 'eliminar':
						this.queryService.eliminarDatosAsync('mantenimiento#personas_contactos', {id: this.seleccionados.personas_contactos.iPersConId}, null, 'grl').then((dElm) => {
							console.log(dElm);
							if (dElm) {
								this.seleccionados.personas_contactos = null;
								this.cargarPersonaContactos();
                                this.opcMenuEmitter.emit('');
							}
						});
						break;
				}
				break;
		}


	}
}
